import { ref } from 'vue';

export type ToastPosition = 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left' | 'top-center' | 'bottom-center';
export type ToastAnimation = 'slide' | 'fade' | 'scale' | 'stagger';

export interface Toast {
    id: number;
    title: string;
    message: string;
    type: 'success' | 'error' | 'warning' | 'info';
    timeout: number;
    position?: ToastPosition;
    animation?: ToastAnimation;
}

const toasts = ref<Toast[]>([]);
let nextId = 1;

export function useToast() {
    const show = (toast: Omit<Toast, 'id'>) => {
        const id = nextId++;
        const newToast = {
            position: 'top-right' as ToastPosition,
            animation: 'slide' as ToastAnimation,
            ...toast,
            id
        };
        
        toasts.value.push(newToast);

        if (toast.timeout) {
            setTimeout(() => {
                remove(id);
            }, toast.timeout);
        }
    };

    const remove = (id: number) => {
        const index = toasts.value.findIndex(t => t.id === id);
        if (index > -1) {
            toasts.value.splice(index, 1);
        }
    };

    return {
        toasts,
        show,
        remove
    };
} 