import { XMLParser, XMLBuilder } from 'fast-xml-parser';
import requests from '@/services/requests';
import response from '@/services/response';

const estado = {
  lecturas: [],
};

const getters = {
  lecturas: state => state.lecturas,
};

const actions = {
  async GetLecturas({ rootState, commit }) {
    const data = await requests.indobox.getLecturas(rootState.auth.username, 'ES');

    if (data && data.status !== 200) {
      return false;
    }

    const postData = response.mimetikaLecturas(data);

    const p = new XMLParser({
      numberParseOptions: {
        leadingZeros: false,
      }
    });

    const newData = p.parse('<Lecturas>' + postData + '</Lecturas>');

    return newData.Lecturas?.oListaDatos?.clsLecturaVR;
  },
  async GetIndocenter({ rootState, commit }) {
    const data = await requests.indobox.getIndocenter(rootState.indo.profile.AreaVenta.CLIENTESAP, 'ES');

    if (data && data.status !== 200) {
      return false;
    }

    const postData = response.indocenterLecturas(data);

    const p = new XMLParser({
      numberParseOptions: {
        leadingZeros: false,
      }
    });

    const newData = p.parse(postData);

    return newData.oListaDatos?.clsLecturaINDOCenter;
  },
};

const mutations = {
  setLecturas(state, lecturas) {
    state.lecturas = lecturas;
  },
};

export default {
  state: estado,
  getters,
  actions,
  mutations,
};
