import requests from '@/services/requests';
import response from '@/services/response';

const estado = {
  marcas: null,
  monturas: null,
  modelos: null,
};

const getters = {
  segundasGafas: (state) => state.segundasGafas,
};

const actions = {
  async GetSegundasGafas({ commit, rootState }) {
    const data = await requests.segundasGafas(rootState.auth.token, rootState.indo.profile.AreaVenta);
    let postData = response.segundasGafas(data);

    if (postData.returnValue !== 0) {
      throw new Error(postData.MensajeErrorSAP);
    }

    const sortedMarcas = postData.oClaseBAPI.listaZGAFAS.sort((a, b) => a.MARCA.localeCompare(b.MARCA));
    await commit('setSegundasGafas', sortedMarcas);

    return sortedMarcas;
  },

  async pedirExistencia({ commit, rootState }, ean11) {
    const data = await requests.pedirExistencia(rootState.auth.token, rootState.indo.profile.AreaVenta, ean11);
    let postData = response.pedirExistencia(data);

    if (postData.returnValue !== 0) {
      throw new Error(postData.MensajeErrorSAP);
    }

    return parseInt(postData.sExistencia);
  },

};

const mutations = {
  setSegundasGafas(state, data) {
    state.monturas = data;
  },
};

export default {
  state: estado,
  getters,
  actions,
  mutations,
};
