import { miraSiHayCirilicos, isEmpty } from './strings';

const validarCondicionesIniciales = (profile, pedido, areaCliente, review, caracteristicasLente) => {
  //----------------------------------------------------------------------------------------------------------------------------------------
  // START Validacions para todos los pedidos (Normales o Enviados a Revisión)
  //----------------------------------------------------------------------------------------------------------------------------------------

  //JH.2024-05-22: VALIDAR: Si pide BigExact y no lleva lectura DNEye Scanner

  let boolFocoMaterialBigExact = false;
  const errores = [];

  const ojoInDerecho = { ...pedido.listOJOIN.clsZES_OJO_INPUT.filter((ojo) => ojo.ZOJO === 'D')[0] };
  const ojoInIzquierdo = { ...pedido.listOJOIN.clsZES_OJO_INPUT.filter((ojo) => ojo.ZOJO === 'I')[0] };
  /** TODO: Victor.2024-11-06: Ligado a CNXT y aun no recibo datos
  if (ojoInDerecho.FOCO_MATERIAL === "MA" || ojoInIzquierdo.FOCO_MATERIAL === "MA") boolFocoMaterialBigExact = true; //Monofocal BIG EXACT
  if (ojoInDerecho.FOCO_MATERIAL === "PA" || ojoInIzquierdo.FOCO_MATERIAL === "PA") boolFocoMaterialBigExact = true; //Progresivo BIG EXACT
  if (ojoInDerecho.FOCO_MATERIAL === "PD" || ojoInIzquierdo.FOCO_MATERIAL === "PD") boolFocoMaterialBigExact = true; //Ocupac. BIG EXACT
  if (boolFocoMaterialBigExact && !Modulos.BigExact.Dispositivo.includes("DNEYE")) {
      alert("Para lentes BIG EXACT debe seleccionar una lectura de DNEye Scanner");
      return false;
  }*/

  //JH.2024-07-01: VALIDAR: Si es cliente Wells la Referencia del Pedido debe contener almenos 8 caracteres seguidos y numéricos
  if (profile.AreaVenta.datosCliente.JERARQUIA_SUPERIOR.toUpperCase() === "WELLS") {
      if (!/\d{8}/.test(pedido.listCABIN.clsZES_CAB_INPUT[0].REF_CLIENT)) {
          errores.push('En el campo Referencia se debe indicar el pedido de Sonae');
      }
  }

  //JH.2024-10-30: VALIDAR: Si Tipo de Montura <> Taladrada debe seleccionar una Orientación
  //Tipos Montura: I=Taladrada | N=Ranurada | A=Acetato | M=Metal
  if (pedido.listCABFRM.clsZES_CAB_FORMA[0].TIPO_MONTURA && pedido.listCABFRM.clsZES_CAB_FORMA[0].TIPO_MONTURA !== "I") {
      if (!pedido.listCABFRM.clsZES_CAB_FORMA[0].ORIENTACION) {
          errores.push("Debe seleccionar una Orientación del bisel");
      }
  }

  //JH.2024-10-30: VALIDAR: Si es pedido Rodenstock y ha seleccionado orientación de bisel "Centrado"
  if (areaCliente.texto_organizacion.toUpperCase() === "RODENSTOCK") {
      if (pedido.listCABFRM.clsZES_CAB_FORMA[0].ORIENTACION === "03") {
          errores.push("Las lentes Rodenstock no admiten la Orientación del bisel: Centrado");
      }
  }

  //----------------------------------------------------------------------------------------------------------------------------------------
  // END Validacions para todos los pedidos (Normales o Enviados a Revisión)
  //----------------------------------------------------------------------------------------------------------------------------------------

  //----------------------------------------------------------------------------------------------------------------------------------------
  // START Validacions para pedidos Normales (No enviados a Revisión)
  //----------------------------------------------------------------------------------------------------------------------------------------
  if (!review) {
      //Mira si al afegir una montura ha posat el codi
      /** Victor.2024-11-06:  Eliminado por petición de cliente
      if (Model.Mode !== "INDONETFFS") {
          if (pedido.CABIN.SERVIR_GAFA === "X" && pedido.CABFRM.ORIGEN_DATOS === "B" && !pedido.MonturaAdicional.EAN11) {
              alert("No ha seleccionado ningun código para la montura pedida");
          }
      }*/

      if (!ojoInDerecho.JER_EDI_INET.trim() && !ojoInIzquierdo.DIAMETRO.trim() &&
          !ojoInIzquierdo.JER_EDI_INET.trim() && !ojoInIzquierdo.DIAMETRO.trim()) {
          errores.push("Debe introducir datos de producto y diámetro");
      }

      //Utilidades.MantenerAutenticacionINDOBOX()
      /** Victor.2024-11-06: Eliminado por petición de cliente
      if (Model.Mode === "INDONETFFS" && !pedido.CABIN.REF_CLIENT) {
          alert(Resources.Strings.aviso_falta_referencia);
          return false;
      }*/

      //Mirar si els dos ulls tenen el mateix index quan hi ha coloració
      /** Victor.2024-11-06: Eliminado por petición de cliente
      if (bMiraSiColorYDistintoIndice() === true) {
          alert(Resources.Strings.aviso_color_nd_diferentes);
          return false;
      } */

      //Avisos de falta diámetro
      if (ojoInDerecho.DIAMETRO === "" &&
          (ojoInDerecho.ESFERA ||
           ojoInDerecho.CILINDRO ||
           ojoInDerecho.ADICION)) {
          errores.push('Debe escoger un diámetro.');
      }

      if (ojoInIzquierdo.DIAMETRO === "" &&
          (ojoInIzquierdo.ESFERA ||
           ojoInIzquierdo.CILINDRO ||
           ojoInIzquierdo.ADICION)) {
          errores.push('Debe escoger un diámetro.');
      }

      //Si hi ha caracters cirílics no deixa enviar
      if (miraSiHayCirilicos(pedido.listCABIN.clsZES_CAB_INPUT[0].REF_CLIENT)) {
          errores.push('Este carácter no es válido. Por favor use el alfabeto occidental.');
      }

      //Si és precalibrat i no hi ha tipus montura que avisi
      if (pedido.PRECALIBRADO === "F" && !pedido.listCABFRM.clsZES_CAB_FORMA[0].TIPO_MONTURA) {
          errores.push('Debe especificar el tipo de montura o el tipo de bisel. Pinche en el botón Siguiente, dentro de selección de espesores.');
      }

      //Si és precalibrat i no hi ha tipus de bisell que avisi
      if (pedido.PRECALIBRADO === "F" && !pedido.listCABFRM.clsZES_CAB_FORMA[0].BISEL) {
          errores.push('Debe especificar el tipo de montura o el tipo de bisel. Pinche en el botón Siguiente, dentro de selección de espesores.');
      }

      //Si és montaje, diferent de segona gafa i no hi ha tipus montura que avisi
      if (pedido.listCABIN.clsZES_CAB_INPUT[0].MONTAJE === "X" &&
          pedido.listCABFRM.clsZES_CAB_FORMA[0].ORIGEN_DATOS !== "G" &&
          !pedido.listCABFRM.clsZES_CAB_FORMA[0].TIPO_MONTURA) {
          errores.push('Debes completar los datos de la montura con el botón Poner Datos');
      }

      //Si és montaje, diferent de segona gafa i no hi ha tipus de bisell que avisi
      if (pedido.listCABIN.clsZES_CAB_INPUT[0].MONTAJE === "X" &&
          pedido.listCABFRM.clsZES_CAB_FORMA[0].ORIGEN_DATOS !== "G" &&
          !pedido.listCABFRM.clsZES_CAB_FORMA[0].BISEL) {
          errores.push('Debes completar los datos de la montura con el botón Poner Datos');
      }

      //Si és montaje, diferent de segona gafa i no hi ha orientació de bisell que avisi
      if (pedido.listCABIN.clsZES_CAB_INPUT[0].MONTAJE === "X" &&
          pedido.listCABFRM.clsZES_CAB_FORMA[0].ORIGEN_DATOS !== "G" &&
          !pedido.listCABFRM.clsZES_CAB_FORMA[0].ORIENTACION) {
          errores.push('Debes completar los datos de la montura con el botón Poner Datos');
      }

      //PasoSegundaReceta
      /** TODO: Victor.2024-11-06: Por saber que es PasoSegundaReceta
      if (Model.PasoSegundaReceta !== "E" && Model.PasoSegundaReceta !== "1") {
          Model.PasoSegundaReceta = "1";
      } */

      //rellenar_datos_Tipo_altura
      /** TODO: Victor.2024-11-06: Por saber que es rellenar_datos_Tipo_altura
      if (rellenar_datos_Tipo_altura("Enviar el Pedido") === false) {
          return false;
      } */

      //Mirar si té forma i demanen bisellat o mod.espessors i no te altura ni dist.np
      errores.push(...miraSiFaltanDatosMontaje(pedido));

      //Posem a pinyo fix el diametre 80 per el maxima.
      if (miraSiMaximaAPartirJergaSinOjo(caracteristicasLente)) {
        pedido.listOJOIN.clsZES_OJO_INPUT.filter((o) => o.ZOJO === 'D')[0].DIAMETRO = "80";
      }
      if (miraSiMaximaAPartirJergaSinOjo(caracteristicasLente)) {
        pedido.listOJOIN.clsZES_OJO_INPUT.filter((o) => o.ZOJO === 'I')[0].DIAMETRO = "80";
      }

      //Per igualar el precalibrat des de indonet i des de SAP
      //Enviem sempre esp_borde="-1" quan precalibrado="F"
      /** Victor.2024-11-06: Eliminado por petición de cliente
      let sEspPrevioOD = "";
      let sEspPrevioOI = "";
      if (pedido.PRECALIBRADO === "F") {
          sEspPrevioOD = ojoInDerecho.ESP_BORDE;
          sEspPrevioOI = ojoInIzquierdo.ESP_BORDE;
          if (Model.Mode !== "INDONETFFS") {
              ojoInDerecho.ESP_BORDE = "-1";
              ojoInIzquierdo.ESP_BORDE = "-1";
          }
      }*/

      //busca les bases si es FFS i està activat el Base Automática
      /** Victor.2024-11-06: COmentado a peticion del cliente
      if (bBaseAutomatica) {
          try {
              let CodigoRetornoBases = eErrores.enumERROR;
              let sOjo = "D";
              CodigoRetornoBases = BuscaBaseAutomatica(sOjo);
              if (CodigoRetornoBases !== eErrores.enumOK) {
                  alert(Resources.Strings.consulta_bases_con_error);
                  return false;
              }
          } catch (ex) {
              return false;
          }
      }*/

      //Mira si hi han dades de prisma
      if (ojoInDerecho.DIOP_PR1 !== "") {
          if (ojoInDerecho.GRAD_PR1 === "" && ojoInDerecho.BASE_PR1 === "") {
              errores.push('No se ha especificado el eje o la base del prisma');
          }
      }
      if (ojoInIzquierdo.DIOP_PR1 !== "") {
          if (ojoInIzquierdo.GRAD_PR1 === "" && ojoInIzquierdo.BASE_PR1 === "") {
              errores.push('No se ha especificado el eje o la base del prisma');
          }
      }
      if (ojoInDerecho.DIOP_PR2 !== "") {
          if (ojoInDerecho.GRAD_PR2 === "" && ojoInDerecho.BASE_PR2 === "") {
              errores.push('No se ha especificado el eje o la base del prisma');
          }
      }
      if (ojoInIzquierdo.DIOP_PR2 !== "") {
          if (ojoInIzquierdo.GRAD_PR2 === "" && ojoInIzquierdo.BASE_PR2 === "") {
              errores.push('No se ha especificado el eje o la base del prisma');
          }
      }

      //Si te demanat isotipo i no te montura avisa
      if ((ojoInDerecho.ISOTIPO === "X" || ojoInIzquierdo.ISOTIPO === "X") &&
          miraSiTieneFormas(pedido) === false) {
          errores.push('En pedidos sin forma o con forma básica no se puede solicitar isotipo. Desactive isotipo');
      }

      //Ha demanat Forma: Básicas
      if (pedido.listCABFRM.clsZES_CAB_FORMA[0].ORIGEN_DATOS === "S") {
          //No ha sel·leccionat cap de les Formas Básicas
          if (pedido.listCABFRM.clsZES_CAB_FORMA[0].FORMA_BASICA === "0") {
              errores.push('Debes especificar la forma básica');
          }
          //JH.2024-10-01: No ha posat els Datos de Montaje
          if (!pedido.listCABFRM.clsZES_CAB_FORMA[0].CALIBRE ||
              !pedido.listCABFRM.clsZES_CAB_FORMA[0].PUENTE ||
              !pedido.listCABFRM.clsZES_CAB_FORMA[0].ALT_BOX ||
              !pedido.listCABFRM.clsZES_CAB_FORMA[0].DIST_NP_OD ||
              !pedido.listCABFRM.clsZES_CAB_FORMA[0].DIST_NP_OI ||
              !pedido.listCABFRM.clsZES_CAB_FORMA[0].ALTURA_OD ||
              !pedido.listCABFRM.clsZES_CAB_FORMA[0].ALTURA_OI) {
              errores.push('Debes completar todos los datos de montaje');
          }
      }

      //JH.2023-07-11: Si es Rodenstock, no permite Indices deferentes en cada ojo
      //NdV: 2024-11-06: Jordi, a OnStop no es diferencia entre ull dret i esquerra a la hora de demenar el indice
      if (ojoInDerecho.IND_REF !== "" && ojoInIzquierdo.IND_REF !== "") {
        if (areaCliente.texto_organizacion.toUpperCase() === "RODENSTOCK") {
              if (ojoInDerecho.IND_REF !== ojoInIzquierdo.IND_REF) {
                  errores.push("El Índice debe ser el mismo para ambos ojos");
              }
          }
      }

      //JH:2023-11-16: Sólo permitimos Puente en blanco o con un valor mayor que cero
      let boolPuenteValido = true;
      if (pedido.listCABFRM.clsZES_CAB_FORMA[0].PUENTE !== "") {
          if (!isNaN(parseFloat(pedido.listCABFRM.clsZES_CAB_FORMA[0].PUENTE)) && parseFloat(pedido.listCABFRM.clsZES_CAB_FORMA[0].PUENTE) > 0) {
              boolPuenteValido = true;
          } else {
              boolPuenteValido = false;
          }
      }
      if (!boolPuenteValido) {
          errores.push("El valor del Puente no es válido");
      }

      //JH.2023-12-01: VALIDAR: Si se pide biselado y se envían menos de 600 puntos de radios con valor mayor que cero
      //if (pedido.CABFRM.BISEL) {
      //    for (const forma of pedido.listFORMAS) {
      //        if (forma.TIPOPUNTOS === "R") {
      //            const strPuntosSinPuntoInicial = forma.PUNTOS.substring(4); //Quitamos los primeros 4 números que son el punto inicial
      //            const strPuntosSinCeros = strPuntosSinPuntoInicial.trimEnd("0"); //Quitamos los puntos con valor cero del final
      //            const iPuntosCount = strPuntosSinCeros.length / 2; //Obtenemos el Len y lo dividimos por 2, ya que cada 2 caracteres hexadecimales son el punto diferencial del anterior
      //            const totalPuntos = iPuntosCount + 1; //Le sumamos uno por el punto inicial que hemos quitado al principio
      //            if (totalPuntos < 600) { //Si el total de puntos es menor que 600
      //                alert("No se aceptan biselados con menos de 600 puntos en la forma");
      //                return false;
      //            }
      //        }
      //    }
      //}
  }

  //----------------------------------------------------------------------------------------------------------------------------------------
  // END Validacions para pedidos Normales (No enviados a Revisión)
  //----------------------------------------------------------------------------------------------------------------------------------------

  //Si pasa todas las validaciones -> Return True
  return errores;
}


const miraSiFaltanDatosMontaje = (pedido) => {
  const errores = [];
  // Si es precalibrado "C", retornamos true directamente
  if (pedido.PRECALIBRADO === "C") {
      return errores;
  }

  // Comprobamos si tiene bisel, mod_espesores, precalibrado o es maxima
  if ((!isEmpty(pedido.listCABIN.clsZES_CAB_INPUT[0].BISEL) ||
       !isEmpty(pedido.listCABIN.clsZES_CAB_INPUT[0].MOD_ESPESORES) ||
       !isEmpty(pedido.PRECALIBRADO)) ||
      pedido.bEsMaxima) {

      // Comprobamos ojo derecho
      if (recetaValida(ojoInDerecho) &&
          (isEmpty(pedido.listCABFRM.clsZES_CAB_FORMA[0].ALTURA_OD) || isEmpty(pedido.listCABFRM.clsZES_CAB_FORMA[0].DIST_NP_OD))) {
          errores.push('Debes completar todos los datos de montaje');
      }

      // Comprobamos ojo izquierdo
      if (recetaValida(ojoInIzquierdo) &&
          (isEmpty(pedido.listCABFRM.clsZES_CAB_FORMA[0].ALTURA_OI) || isEmpty(pedido.listCABFRM.clsZES_CAB_FORMA[0].DIST_NP_OI))) {
          errores.push('Debes completar todos los datos de montaje');
      }
  }

  return errores;
}

const miraSiMaximaAPartirJergaSinOjo = (lentes) => {
  const sFoco = lentes?.FocoMaterial;
  const sPersonal = lentes?.Personal;

  return (sFoco === "PX" || sFoco === "MX") && sPersonal !== "AE";
}

const miraSiMaximaAPartirJerga = (ojo, lentes) => {
  // NO Actualitza el pedido. Només és per mirar si ho és
  // Canviat model per me2018-09-12

  let sFoco = "";
  let sPersonal = "";

  sFoco = lentes?.FocoMaterial;
  sPersonal = lentes?.Personal;

  if ((sFoco === "PX" || ojo.FOCO_MATERIAL === "PX" ||
        sFoco === "MX" || ojo.FOCO_MATERIAL === "MX") &&
      sPersonal !== "AE") {
      return true;
  }

  return false;
}

const miraSiTieneFormas = (pedido) => {
  // Si no tiene modelo ni calibre, retorna false
  if (isEmpty(pedido.listCABFRM.clsZES_CAB_FORMA[0].MODELO) && isEmpty(pedido.listCABFRM.clsZES_CAB_FORMA[0].CALIBRE)) {
      //And String.IsNullOrEmpty(pedido.FormaROD) Then
      return false;
  }

  // Si es modo INDONETFFS y tiene formas, actualiza PRECALIBRADO
  /** Victor.2024-11-06:  Eliminado por petición de cliente
  if (Model.Mode === "INDONETFFS") {
      pedido.PRECALIBRADO = "F";
  }*/

  return true;
}

const rellenar_datos_Tipo_altura = (profile, pedido) => {
  // Si viene de IndoCenter, retornamos true directamente
  if (pedido.bVieneDeIndoCenter) {
      return true;
  }

  // Si ambas alturas son 0, retornamos true
  if (Number(pedido.listCABFRM.clsZES_CAB_FORMA[0].ALTURA_OD) === 0 && Number(pedido.listCABFRM.clsZES_CAB_FORMA[0].DIST_NP_OI) === 0) {
      return true;
  }

  // Si el tipo de altura no coincide
  if (profile.DatosConfig.DETALLES.TIPO_ALT !== pedido.listCABFRM.clsZES_CAB_FORMA[0].TIPO_ALTURA) {
      let sConfiguracion, sPedido;

      // Get Config Cliente
      if (profile.DatosConfig.DETALLES.TIPO_ALT === "B") {
          sConfiguracion = "Boxing";
      } else if (profile.DatosConfig.DETALLES.TIPO_ALT === "A") {
          sConfiguracion = "Aro";
      } else {
          sConfiguracion = "Desconocido";
      }

      // Get Config Pedido
      if (pedido.listCABFRM.clsZES_CAB_FORMA[0].TIPO_ALTURA === "B") {
          sPedido = "Boxing";
      } else if (pedido.listCABFRM.clsZES_CAB_FORMA[0].TIPO_ALTURA === "A") {
          sPedido = "Aro";
      } else {
          sPedido = "Desconocido";
      }

      const sCadena = `El tipo de Altura de Montaje del Pedido no coincide con el de la Configuración de la Óptica:

                  Óptica: ${sConfiguracion}
                  Pedido: ${sPedido}

                  ¿Desea actualizar la configuración del Pedido?

                  [Si] para cambiar la Altura de Montaje del pedido a ${sConfiguracion}.
                  [No] para mantener la Altura de Montaje del Pedido a ${sPedido}.`;

      // En JavaScript usaremos confirm en lugar de MsgBox
      const confirmResult = confirm(sCadena);

      if (confirmResult) {
          pedido.listCABFRM.clsZES_CAB_FORMA[0].TIPO_ALTURA = profile.DatosConfig.DETALLES.TIPO_ALT;
          return false;
      }
  }

  return true;
}

const enviarARevisarPedido = (pedido, ojoInDerecho, ojoInIzquierdo, lentes) => {
  // Comprobar si es maxima y ajustar diámetro
  if (miraSiMaximaAPartirJerga(ojoInDerecho, lentes)) {
    pedido.listOJOIN.clsZES_OJO_INPUT.filter((o) => o.ZOJO === 'D')[0].DIAMETRO = "80";
  }
  if (miraSiMaximaAPartirJerga(ojoInIzquierdo, lentes)) {
    pedido.listOJOIN.clsZES_OJO_INPUT.filter((o) => o.ZOJO === 'I')[0].DIAMETRO = "80";
  }

  rellenar_datos_cliente(pedido);
  rellenar_datos_Secundarios(pedido);

  // Si te demanat isotipo i no te montura avisa
  if ((pedido.listOJOIN.clsZES_OJO_INPUT.filter((o) => o.ZOJO === 'D')[0].ISOTIPO === "X" ||
       pedido.listOJOIN.clsZES_OJO_INPUT.filter((o) => o.ZOJO === 'I')[0].ISOTIPO === "X") &&
      !miraSiTieneFormas(pedido)) {
      return ['En pedidos sin forma o con forma básica no se puede solicitar isotipo. Desactive isotipo'];
  }

  // ValidarPedido --------------------------------------------------------
  // NdV: 2024-11-13: Comentado porque ya se esta haciendo en en envio del pedido
  // if (!ValidarCondicionesIniciales()) {
  //     return false;
  // }
  // / ValidarPedido ------------------------------------------------------

  // JH.2023-09-05: To TEST: Cambiar a MODO_FUNCIONAM = "A" para guardar en PA sin realizar ninguna validación.
  pedido.MODO_FUNCIONAM = "B";
  return true;
}

const enviarPedido = (pedido, tipoEnvio = '') => {

  // Per igualar el precalibrat des de indonet i des de SAP
  // Enviem sempre esp_borde="-1" quan precalibrado="F"

  if (pedido.PRECALIBRADO === "F") {
    pedido.listOJOIN.clsZES_OJO_INPUT.filter((o) => o.ZOJO === 'D')[0].ESP_BORDE = "-1";
    pedido.listOJOIN.clsZES_OJO_INPUT.filter((o) => o.ZOJO === 'I')[0].ESP_BORDE = "-1";
  }


  rellenar_datos_cliente(pedido);
  rellenar_datos_Secundarios(pedido);

  if (isEmpty(tipoEnvio)) {
      pedido.MODO_FUNCIONAM = "V";
  } else {
      if (pedido.listCABOUT.clsZES_CAB_OUTPUT.length === 0) {
        pedido.listCABOUT.clsZES_CAB_OUTPUT = [{ IDENT_RAPIDO: "" }];
      }
      pedido.listCABOUT.clsZES_CAB_OUTPUT[0].IDENT_RAPIDO = "";
      pedido.MODO_FUNCIONAM = tipoEnvio;
  }

}

const rellenar_datos_cliente = (pedido, sClienteReceta = '') => {
  // pedido.CLIENTE = Model.Cliente.CLIENTE;
  pedido.listCABIN.clsZES_CAB_INPUT[0].AUGRU = "INET";
  const sTempKUNNR = pedido.listCABIN.clsZES_CAB_INPUT[0].KUNNR;

  // If Model.DatosConfig.CONFIGURACION IsNot Nothing Then
  //     pedido.CABIN.KUNNR = Model.DatosConfig.CONFIGURACION.KUNNR
  // End If

  if (isEmpty(pedido.listCABIN.clsZES_CAB_INPUT[0].KUNNR)) {
      pedido.listCABIN.clsZES_CAB_INPUT[0].KUNNR = pedido.CLIENTESAP;
  }

  if (pedido.bVieneDeReceta) {
      pedido.CLIENTE = sTempKUNNR;
      pedido.listCABIN.clsZES_CAB_INPUT[0].KUNNR = sTempKUNNR;
  }

  if (!isEmpty(sClienteReceta)) {
      pedido.CLIENTE = sClienteReceta;
      pedido.listCABIN.clsZES_CAB_INPUT[0].KUNNR = sClienteReceta;
  }
}

const rellenar_datos_Secundarios = (pedido) => {
  // Si hemos elegido una forma de un biselado remoto, tenemos que volver a setear los puntos por si
  // hay un redimensionado

  // NdV: 2024-11-13: Comentado la adquisiciond e montura. En este punto ya esta informada
  //                  en el caso de haber sido rellenada.
  // pedido.listCABFRM.clsZES_CAB_FORMA[0].CLAVEMONTURA = DAO.MonturasDAO.getClaveMontura(
  //     pedido.CABFRM.MARCA,
  //     pedido.CABFRM.MODELO,
  //     pedido.CABFRM.CALIBRE,
  //     pedido.CABFRM.ORIGEN_DATOS,
  //     pedido.CABFRM.PUENTEORIG
  // );

  if (pedido.listCABFRM.clsZES_CAB_FORMA[0].ORIGEN_DATOS === "B") {
      // NdV: 2024-11-13: Comentado porque ya se esta haciendo en
      //                  la lectura del biselado
      // popularListaLecturas(_lecturasTrazador);

      pedido.listFORMAS.clsZFORMAS.forEach(f => {
          f.ORIGEN = pedido.listCABFRM.clsZES_CAB_FORMA[0].ORIGEN_DATOS;
          f.TIPO_ALTUR = pedido.listCABFRM.clsZES_CAB_FORMA[0].TIPO_ALTURA ?? '';
          f.TIPO_MONTU = pedido.listCABFRM.clsZES_CAB_FORMA[0].TIPO_MONTURA ?? '';

          if (f.LENTE.trim().toUpperCase() === "D") {
              f.ALTURA = pedido.listCABFRM.clsZES_CAB_FORMA[0].ALTURA_OD;
              f.DIST_NP = pedido.listCABFRM.clsZES_CAB_FORMA[0].DIST_NP_OD;
          } else {
              f.ALTURA = pedido.listCABFRM.clsZES_CAB_FORMA[0].ALTURA_OI;
              f.DIST_NP = pedido.listCABFRM.clsZES_CAB_FORMA[0].DIST_NP_OI;
          }
      });
  }
}

/**
 * Revisa si es valido el ojo
 *
 * @param {object} ojo con ESFERA, CILINDRO y ADICION
 * @returns {boolean}
 */
const recetaValida = (ojo) => {
  return !isEmpty(ojo.ESFERA) || !isEmpty(ojo.CILINDRO) || !isEmpty(ojo.ADICION);
}

export { validarCondicionesIniciales, rellenar_datos_Tipo_altura, recetaValida, miraSiMaximaAPartirJerga, enviarARevisarPedido, enviarPedido };
