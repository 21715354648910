<?xml version="1.0" encoding="utf-8"?>
<root>
  <!-- 
    Microsoft ResX Schema 
    
    Version 2.0
    
    The primary goals of this format is to allow a simple XML format 
    that is mostly human readable. The generation and parsing of the 
    various data types are done through the TypeConverter classes 
    associated with the data types.
    
    Example:
    
    ... ado.net/XML headers & schema ...
    <resheader name="resmimetype">text/microsoft-resx</resheader>
    <resheader name="version">2.0</resheader>
    <resheader name="reader">System.Resources.ResXResourceReader, System.Windows.Forms, ...</resheader>
    <resheader name="writer">System.Resources.ResXResourceWriter, System.Windows.Forms, ...</resheader>
    <data name="Name1"><value>this is my long string</value><comment>this is a comment</comment></data>
    <data name="Color1" type="System.Drawing.Color, System.Drawing">Blue</data>
    <data name="Bitmap1" mimetype="application/x-microsoft.net.object.binary.base64">
        <value>[base64 mime encoded serialized .NET Framework object]</value>
    </data>
    <data name="Icon1" type="System.Drawing.Icon, System.Drawing" mimetype="application/x-microsoft.net.object.bytearray.base64">
        <value>[base64 mime encoded string representing a byte array form of the .NET Framework object]</value>
        <comment>This is a comment</comment>
    </data>
                
    There are any number of "resheader" rows that contain simple 
    name/value pairs.
    
    Each data row contains a name, and value. The row also contains a 
    type or mimetype. Type corresponds to a .NET class that support 
    text/value conversion through the TypeConverter architecture. 
    Classes that don't support this are serialized and stored with the 
    mimetype set.
    
    The mimetype is used for serialized objects, and tells the 
    ResXResourceReader how to depersist the object. This is currently not 
    extensible. For a given mimetype the value must be set accordingly:
    
    Note - application/x-microsoft.net.object.binary.base64 is the format 
    that the ResXResourceWriter will generate, however the reader can 
    read any of the formats listed below.
    
    mimetype: application/x-microsoft.net.object.binary.base64
    value   : The object must be serialized with 
            : System.Runtime.Serialization.Formatters.Binary.BinaryFormatter
            : and then encoded with base64 encoding.
    
    mimetype: application/x-microsoft.net.object.soap.base64
    value   : The object must be serialized with 
            : System.Runtime.Serialization.Formatters.Soap.SoapFormatter
            : and then encoded with base64 encoding.

    mimetype: application/x-microsoft.net.object.bytearray.base64
    value   : The object must be serialized into a byte array 
            : using a System.ComponentModel.TypeConverter
            : and then encoded with base64 encoding.
    -->
  <xsd:schema id="root" xmlns="" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:msdata="urn:schemas-microsoft-com:xml-msdata">
    <xsd:import namespace="http://www.w3.org/XML/1998/namespace" />
    <xsd:element name="root" msdata:IsDataSet="true">
      <xsd:complexType>
        <xsd:choice maxOccurs="unbounded">
          <xsd:element name="metadata">
            <xsd:complexType>
              <xsd:sequence>
                <xsd:element name="value" type="xsd:string" minOccurs="0" />
              </xsd:sequence>
              <xsd:attribute name="name" use="required" type="xsd:string" />
              <xsd:attribute name="type" type="xsd:string" />
              <xsd:attribute name="mimetype" type="xsd:string" />
              <xsd:attribute ref="xml:space" />
            </xsd:complexType>
          </xsd:element>
          <xsd:element name="assembly">
            <xsd:complexType>
              <xsd:attribute name="alias" type="xsd:string" />
              <xsd:attribute name="name" type="xsd:string" />
            </xsd:complexType>
          </xsd:element>
          <xsd:element name="data">
            <xsd:complexType>
              <xsd:sequence>
                <xsd:element name="value" type="xsd:string" minOccurs="0" msdata:Ordinal="1" />
                <xsd:element name="comment" type="xsd:string" minOccurs="0" msdata:Ordinal="2" />
              </xsd:sequence>
              <xsd:attribute name="name" type="xsd:string" use="required" msdata:Ordinal="1" />
              <xsd:attribute name="type" type="xsd:string" msdata:Ordinal="3" />
              <xsd:attribute name="mimetype" type="xsd:string" msdata:Ordinal="4" />
              <xsd:attribute ref="xml:space" />
            </xsd:complexType>
          </xsd:element>
          <xsd:element name="resheader">
            <xsd:complexType>
              <xsd:sequence>
                <xsd:element name="value" type="xsd:string" minOccurs="0" msdata:Ordinal="1" />
              </xsd:sequence>
              <xsd:attribute name="name" type="xsd:string" use="required" />
            </xsd:complexType>
          </xsd:element>
        </xsd:choice>
      </xsd:complexType>
    </xsd:element>
  </xsd:schema>
  <resheader name="resmimetype">
    <value>text/microsoft-resx</value>
  </resheader>
  <resheader name="version">
    <value>2.0</value>
  </resheader>
  <resheader name="reader">
    <value>System.Resources.ResXResourceReader, System.Windows.Forms, Version=2.0.0.0, Culture=neutral, PublicKeyToken=b77a5c561934e089</value>
  </resheader>
  <resheader name="writer">
    <value>System.Resources.ResXResourceWriter, System.Windows.Forms, Version=2.0.0.0, Culture=neutral, PublicKeyToken=b77a5c561934e089</value>
  </resheader>
  <data name="a" xml:space="preserve">
    <value>а</value>
    <comment>Para ver si cambia 2</comment>
  </data>
  <data name="abertura" xml:space="preserve">
    <value>Ширина проема</value>
  </data>
  <data name="acceso_indonet" xml:space="preserve">
    <value>Доступ</value>
    <comment>Login screen</comment>
  </data>
  <data name="aceptar" xml:space="preserve">
    <value>ОК</value>
  </data>
  <data name="acetato" xml:space="preserve">
    <value>Пластик</value>
    <comment>type of frame</comment>
  </data>
  <data name="actualizar" xml:space="preserve">
    <value>Обновить</value>
  </data>
  <data name="adicion" xml:space="preserve">
    <value>Add</value>
    <comment>Home screen, list of last orders</comment>
  </data>
  <data name="adicion_abrv" xml:space="preserve">
    <value>Add</value>
    <comment>Order list header</comment>
  </data>
  <data name="advertencia" xml:space="preserve">
    <value>Внимание</value>
  </data>
  <data name="agente_trazador" xml:space="preserve">
    <value>Действющий трейсер </value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="agente_trazador_desconectado" xml:space="preserve">
    <value>Соединение с трейсером нарушено. Пожалуйста, проверьте статус</value>
  </data>
  <data name="altura" xml:space="preserve">
    <value>Высота</value>
    <comment>Technical data</comment>
  </data>
  <data name="altura_boxing" xml:space="preserve">
    <value>Высота проема</value>
  </data>
  <data name="altura_de_montaje" xml:space="preserve">
    <value>Уст.высота</value>
  </data>
  <data name="altura_montaje" xml:space="preserve">
    <value>Уст.высота</value>
    <comment>Additional data</comment>
  </data>
  <data name="angulo" xml:space="preserve">
    <value>Угол </value>
    <comment>Technical data</comment>
  </data>
  <data name="angulo_de_envolvencia" xml:space="preserve">
    <value>Угол изгиба оправы</value>
    <comment>Additional data</comment>
  </data>
  <data name="asunto" xml:space="preserve">
    <value>Вопрос </value>
  </data>
  <data name="atras" xml:space="preserve">
    <value>Назад</value>
  </data>
  <data name="automatico13_23" xml:space="preserve">
    <value>Автоматический (1/3-2/3)</value>
    <comment>Bevel direction</comment>
  </data>
  <data name="avisos" xml:space="preserve">
    <value>Предупреждение</value>
    <comment>Order screen</comment>
  </data>
  <data name="aviso_cancelar_pedido" xml:space="preserve">
    <value>Вы хотите отменить заказ? Все данные будут утеряны. </value>
    <comment>Warnings</comment>
  </data>
  <data name="aviso_desactivar_solo_stock" xml:space="preserve">
    <value>Если Вы хотите выбрать этот вариант, деактивируйте окно "Только склад"</value>
  </data>
  <data name="base" xml:space="preserve">
    <value>База</value>
    <comment>Technical data</comment>
  </data>
  <data name="base_de_datos_de_marcas" xml:space="preserve">
    <value>База данных брендов </value>
    <comment>Shape ComboBox </comment>
  </data>
  <data name="base_de_datos_de_mis_biselados" xml:space="preserve">
    <value>Фацет не выполнять</value>
    <comment>Shape ComboBox </comment>
  </data>
  <data name="base_especial" xml:space="preserve">
    <value>Спец.база</value>
    <comment>Supplements</comment>
  </data>
  <data name="base_interior" xml:space="preserve">
    <value>Внутр.база</value>
  </data>
  <data name="basicas" xml:space="preserve">
    <value>Основные </value>
    <comment>Shape ComboBox </comment>
  </data>
  <data name="bisel" xml:space="preserve">
    <value>Стандартный фацет</value>
    <comment>bevel type</comment>
  </data>
  <data name="biselador_remoto" xml:space="preserve">
    <value>Дистанционный фацет</value>
    <comment>Shape ComboBox </comment>
  </data>
  <data name="biselador_remoto_guardar" xml:space="preserve">
    <value>Сохранить</value>
  </data>
  <data name="biselador_remoto_iniciar" xml:space="preserve">
    <value>Начать</value>
  </data>
  <data name="biselador_remoto_popup_titulo" xml:space="preserve">
    <value>Выбор действющего трейсера</value>
  </data>
  <data name="biselar_lente" xml:space="preserve">
    <value>Фацет </value>
  </data>
  <data name="biselar_sin_pulir" xml:space="preserve">
    <value>Фацет без полировки</value>
  </data>
  <data name="borrar_recetas" xml:space="preserve">
    <value>Удалить</value>
  </data>
  <data name="buscar" xml:space="preserve">
    <value>Поиск</value>
  </data>
  <data name="calibracion_correctamente" xml:space="preserve">
    <value>Калибровка была выполнена правильно</value>
  </data>
  <data name="calibracion_monitor" xml:space="preserve">
    <value>Калибровка монитора</value>
  </data>
  <data name="calibre" xml:space="preserve">
    <value>Размер boxing</value>
    <comment>Additional data</comment>
  </data>
  <data name="calibre_completo" xml:space="preserve">
    <value>Полный калибр</value>
  </data>
  <data name="cancelar" xml:space="preserve">
    <value>Отменить</value>
  </data>
  <data name="cancelar_pedido" xml:space="preserve">
    <value>Отменить заказ </value>
    <comment>Order action button panel</comment>
  </data>
  <data name="centrado12_12" xml:space="preserve">
    <value>Фацет 1/2-1/2</value>
    <comment>Bevel direction</comment>
  </data>
  <data name="chile" xml:space="preserve">
    <value>Чили</value>
  </data>
  <data name="cilindro" xml:space="preserve">
    <value>Цилиндр </value>
    <comment>Home screen, list of last orders</comment>
  </data>
  <data name="cilindro_abrv" xml:space="preserve">
    <value>Cyl</value>
    <comment>Order list header</comment>
  </data>
  <data name="clave_acceso" xml:space="preserve">
    <value>Пароль</value>
    <comment>Login screen</comment>
  </data>
  <data name="cliente" xml:space="preserve">
    <value>Клиент</value>
  </data>
  <data name="codigo_cliente" xml:space="preserve">
    <value>Код клиента </value>
    <comment>Login screen</comment>
  </data>
  <data name="codigo_de_retorno" xml:space="preserve">
    <value>Код возврата: </value>
  </data>
  <data name="color" xml:space="preserve">
    <value>Цвет </value>
    <comment>Order screen</comment>
  </data>
  <data name="coloracion" xml:space="preserve">
    <value>Окрашивание </value>
    <comment>Order screen</comment>
  </data>
  <data name="condiciones" xml:space="preserve">
    <value>Условия</value>
  </data>
  <data name="condiciones_1" xml:space="preserve">
    <value>INDO OPTICAL, S.L.U. (CIF:B-66232976), зарегестрированная по адресу Avda. Alcalde Barnils, 72 - 08174 Sant Cugat del Vallés - Barcelona, и занесенная в Торговый Реестр Барселоны в Том № 44215, Фолиант 212, Страница B-449860, является ответственной за использование данных, получение информации и хранение файла "клиенты", и, тем самым уведомляет подписчика, что все предоставленные им данные будут включены в файл "клиенты". Накопленные данные позволяют организовать обмен информацией в коммерческих целях между INDO и Вашим Оптическим магазином по Интернету и, таким образом, дает Вам право пользоваться системой INDONET</value>
  </data>
  <data name="condiciones_2" xml:space="preserve">
    <value>Принятие условий пользования обязательно, и любой отказ предоставить ответ на запрошенные данные ведет к прекращению доступа в систему INDONET</value>
  </data>
  <data name="condiciones_3" xml:space="preserve">
    <value>Абонент имеет право на доступ, исправление, аннулирование и возражение в соответствии с действующим законодательством. С этой целью абонент должен направить свой запрос через контактный формуляр  IndoBox: www.indobox-indo.com</value>
  </data>
  <data name="condiciones_4" xml:space="preserve">
    <value>Подписчик, будучи держателем данных, добровольно соглашается на их обработку, как описано выше.</value>
  </data>
  <data name="conexion_ssl" xml:space="preserve">
    <value>SSL СВЯЗЬ</value>
  </data>
  <data name="configuracion" xml:space="preserve">
    <value>Настройка</value>
    <comment>Toolbar</comment>
  </data>
  <data name="configuracion_activado" xml:space="preserve">
    <value>Активировано</value>
  </data>
  <data name="configuracion_altura_montaje" xml:space="preserve">
    <value>Высота сборки</value>
  </data>
  <data name="configuracion_aro" xml:space="preserve">
    <value>Ring</value>
  </data>
  <data name="configuracion_boxing" xml:space="preserve">
    <value>Boxing</value>
  </data>
  <data name="configuracion_catalogo_lentes" xml:space="preserve">
    <value>Каталог линз INDO</value>
  </data>
  <data name="configuracion_clave_actual" xml:space="preserve">
    <value>Пароль</value>
  </data>
  <data name="configuracion_confirmar_clave" xml:space="preserve">
    <value>Подтвердить пароль</value>
  </data>
  <data name="configuracion_desactivado" xml:space="preserve">
    <value>Деактивировано</value>
  </data>
  <data name="configuracion_descargas_automaticas" xml:space="preserve">
    <value>Автоматические загрузки</value>
  </data>
  <data name="configuracion_dias" xml:space="preserve">
    <value>дни</value>
  </data>
  <data name="configuracion_enlaces_relacionados" xml:space="preserve">
    <value>Полезные ссылки</value>
  </data>
  <data name="configuracion_espesores" xml:space="preserve">
    <value>Установка толщины</value>
  </data>
  <data name="configuracion_espesor_borde" xml:space="preserve">
    <value>Толщина края</value>
  </data>
  <data name="configuracion_guardada_correctamente" xml:space="preserve">
    <value>Настройка сохранена правильно</value>
  </data>
  <data name="configuracion_jerga" xml:space="preserve">
    <value>Жаргон</value>
  </data>
  <data name="configuracion_listado_pedidos" xml:space="preserve">
    <value>Лист заказов</value>
  </data>
  <data name="configuracion_modificar_clave" xml:space="preserve">
    <value>Изменить пароль</value>
  </data>
  <data name="configuracion_mostrar_pedidos" xml:space="preserve">
    <value>Показать последние заказы</value>
  </data>
  <data name="configuracion_mostrar_precios" xml:space="preserve">
    <value>Показать цены</value>
  </data>
  <data name="configuracion_nombre_comercial" xml:space="preserve">
    <value>Коммерческое название</value>
  </data>
  <data name="configuracion_nombre_lentes" xml:space="preserve">
    <value>Название линз</value>
  </data>
  <data name="configuracion_nombre_lentes_help" xml:space="preserve">
    <value>Изменения будут показаны при следующей идентификации</value>
  </data>
  <data name="configuracion_nueva_clave" xml:space="preserve">
    <value>Новый пароль</value>
  </data>
  <data name="configuracion_precalibrado_cotas" xml:space="preserve">
    <value>Ручная прекалибровка</value>
  </data>
  <data name="configuracion_precios_no" xml:space="preserve">
    <value>Нет</value>
  </data>
  <data name="configuracion_precios_pvo" xml:space="preserve">
    <value>Оптовая цена</value>
  </data>
  <data name="configuracion_precios_pvp" xml:space="preserve">
    <value>Розничная цена</value>
  </data>
  <data name="configuracion_precios_si" xml:space="preserve">
    <value>Да</value>
  </data>
  <data name="configuracion_pred_indo" xml:space="preserve">
    <value>Indo Pred.</value>
  </data>
  <data name="configuracion_propio" xml:space="preserve">
    <value>Собственный</value>
  </data>
  <data name="configuracion_punto_taladro" xml:space="preserve">
    <value>Точка сверления</value>
  </data>
  <data name="configuracion_ranura_metal" xml:space="preserve">
    <value>Фацет для металической лески</value>
  </data>
  <data name="configuracion_ranura_nylon" xml:space="preserve">
    <value>Фацет для нейлоновой лески</value>
  </data>
  <data name="configuracion_restablecer_valores_predeterminados" xml:space="preserve">
    <value>Восстановить заданные параметры</value>
  </data>
  <data name="configuracion_tooltip_espesores" xml:space="preserve">
    <value>Толщина больше, чем рекомендованная. Фацет не будет выполнен</value>
  </data>
  <data name="configuracion_tooltip_numstepper" xml:space="preserve">
    <value>Список ”Лист заказов" находится на главной странице. Период заказов от 1 до 7 дней.</value>
  </data>
  <data name="configuracion_URL_catalogo" xml:space="preserve">
    <value>http://www.indo.es/lentes/catalogue2011</value>
  </data>
  <data name="confirmar_borrar_receta" xml:space="preserve">
    <value>Подтверждаете удаление сохраненного выбранного рецепта?</value>
  </data>
  <data name="confirmar_borrar_recetas" xml:space="preserve">
    <value>Подтверждаете удаление {0} сохраненных выбранных рецептов?</value>
  </data>
  <data name="confirmar_envio_pedidos_1" xml:space="preserve">
    <value>Подтверждаете отправку следующих заказов? #</value>
  </data>
  <data name="confirmar_envio_pedidos_2" xml:space="preserve">
    <value>Все заказы с ошибками будут оставлены как "Для пересмотра INDO"</value>
  </data>
  <data name="confirmar_envio_receta" xml:space="preserve">
    <value>Подтверждаете отправку сохраненного выбранного рецепта?</value>
  </data>
  <data name="confirmar_envio_recetas" xml:space="preserve">
    <value>Подтверждаете отправку {0} сохраненных выбранных рецептов?</value>
  </data>
  <data name="confirmar_envio_recetas_2" xml:space="preserve">
    <value>Все заказы с ошибками будут оставлены как "Для пересмотра INDO"</value>
  </data>
  <data name="confirma_biselado_espesor" xml:space="preserve">
    <value>Подтверждение фацета и толщины</value>
  </data>
  <data name="confirma_destino_mercancia" xml:space="preserve">
    <value>Подтвердить получателя товаров</value>
  </data>
  <data name="contacta_con_indo" xml:space="preserve">
    <value>Обратитесь</value>
    <comment>Login screen</comment>
  </data>
  <data name="continuar" xml:space="preserve">
    <value>Продолжить</value>
  </data>
  <data name="correo_solicitud_acceso" xml:space="preserve">
    <value>Адрес для запроса доступа</value>
  </data>
  <data name="cota_inferior" xml:space="preserve">
    <value>Нижнее измерение</value>
  </data>
  <data name="cota_superior" xml:space="preserve">
    <value>Верхнее измерение</value>
  </data>
  <data name="datosoptica_aviso_logotipo" xml:space="preserve">
    <value>Файл для логотипа не предоставлен. {0} Будет использован логотип по умолчанию </value>
  </data>
  <data name="datosoptica_codigo_postal" xml:space="preserve">
    <value>Почтовый индекс:</value>
  </data>
  <data name="datosoptica_comunicaciones" xml:space="preserve">
    <value>Я хочу получать сообщения от INDO</value>
  </data>
  <data name="datosoptica_corregir_datos" xml:space="preserve">
    <value>Если Ваши данные являются неверными, зайдите в контактный формуляр IndoBox:</value>
  </data>
  <data name="datosoptica_direccion" xml:space="preserve">
    <value>Адрес:</value>
  </data>
  <data name="datosoptica_email" xml:space="preserve">
    <value>E-mail :</value>
  </data>
  <data name="datosoptica_enlace_condiciones" xml:space="preserve">
    <value>Прочитайте условия конфиденциальности и защиты данных</value>
  </data>
  <data name="datosoptica_fax" xml:space="preserve">
    <value>Факс:</value>
  </data>
  <data name="datosoptica_guardados_correctamente" xml:space="preserve">
    <value>Оптические данные сохранены правильно</value>
  </data>
  <data name="datosoptica_logotipo_help" xml:space="preserve">
    <value>Новый логотип будет показан при следующей идентификации</value>
  </data>
  <data name="datosoptica_logotipo_optica" xml:space="preserve">
    <value>Логотип оптики</value>
  </data>
  <data name="datosoptica_nif" xml:space="preserve">
    <value>ИНН:</value>
  </data>
  <data name="datosoptica_nombre" xml:space="preserve">
    <value>Имя:</value>
  </data>
  <data name="datosoptica_poblacion" xml:space="preserve">
    <value>Город:</value>
  </data>
  <data name="datosoptica_telefono" xml:space="preserve">
    <value>Телефон:</value>
  </data>
  <data name="datos_adicionales" xml:space="preserve">
    <value>Метрич.данные</value>
    <comment>Basic data</comment>
  </data>
  <data name="datos_basicos" xml:space="preserve">
    <value>Осн.данные</value>
    <comment>Order screen</comment>
  </data>
  <data name="datos_optica" xml:space="preserve">
    <value>Оптич.данные</value>
    <comment>Main navigation bar</comment>
  </data>
  <data name="datos_tecnicos" xml:space="preserve">
    <value>Тех.данные</value>
  </data>
  <data name="descentramiento_nasal" xml:space="preserve">
    <value>Децентрация</value>
    <comment>Supplements</comment>
  </data>
  <data name="descripcion_calibracion" xml:space="preserve">
    <value>Введите диаметр предыдущей окружности в миллиметрах в координате X</value>
  </data>
  <data name="destinatario" xml:space="preserve">
    <value>Получатель</value>
  </data>
  <data name="detalle_estado_pedido" xml:space="preserve">
    <value>Статус заказа</value>
  </data>
  <data name="diametro" xml:space="preserve">
    <value>Диаметр </value>
    <comment>Eye basic data view</comment>
  </data>
  <data name="dioptrias" xml:space="preserve">
    <value>Диоптрии </value>
    <comment>Supplements</comment>
  </data>
  <data name="distancia" xml:space="preserve">
    <value>Расстояние </value>
    <comment>Additional data</comment>
  </data>
  <data name="distancia_trabajo" xml:space="preserve">
    <value>Рабочее расстояние </value>
    <comment>Additional data</comment>
  </data>
  <data name="dnp" xml:space="preserve">
    <value>NPD</value>
    <comment>Additional data</comment>
  </data>
  <data name="dnp_de_cerca" xml:space="preserve">
    <value>NPD для близи</value>
  </data>
  <data name="duplicar_receta" xml:space="preserve">
    <value>Скопировать заказ</value>
  </data>
  <data name="d_esp" xml:space="preserve">
    <value>Спец.диаметр</value>
  </data>
  <data name="d_especial" xml:space="preserve">
    <value>Спец.диаметр</value>
    <comment>Eye basic data view</comment>
  </data>
  <data name="eje" xml:space="preserve">
    <value>Ось </value>
    <comment>Home screen, list of last orders</comment>
  </data>
  <data name="eje_abrv" xml:space="preserve">
    <value>Ax</value>
    <comment>Order list header</comment>
  </data>
  <data name="el_valor_maximo_es" xml:space="preserve">
    <value>Макс.величина</value>
    <comment>Confirmations</comment>
  </data>
  <data name="email" xml:space="preserve">
    <value>E-mail</value>
  </data>
  <data name="entrar" xml:space="preserve">
    <value>Войти </value>
    <comment>Login screen</comment>
  </data>
  <data name="enviar" xml:space="preserve">
    <value>Отправить </value>
    <comment>Order action button panel</comment>
  </data>
  <data name="enviar_pedido" xml:space="preserve">
    <value>Отправить заказ</value>
  </data>
  <data name="enviar_sugerencias" xml:space="preserve">
    <value>Отправить предложения</value>
  </data>
  <data name="enviar_y_revision" xml:space="preserve">
    <value>Отправить для проверки </value>
    <comment>Order action button panel</comment>
  </data>
  <data name="envio_recetas" xml:space="preserve">
    <value>Отправить рецепт</value>
  </data>
  <data name="envio_recetas_falta_destinatario" xml:space="preserve">
    <value>Выбрать получателя</value>
  </data>
  <data name="envio_recetas_ningun_destinatario" xml:space="preserve">
    <value>Получатель не был найден</value>
  </data>
  <data name="error_abriendo_aplicacion" xml:space="preserve">
    <value>Ошибка при открытии нужного приложения</value>
  </data>
  <data name="error_abriendo_base_de_datos" xml:space="preserve">
    <value>Ошибка при открытии базы данных </value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_actualizacion_pedido" xml:space="preserve">
    <value>Ошибка при обновлении заказа</value>
  </data>
  <data name="error_actualizando_fecha_oferta" xml:space="preserve">
    <value>Ошибка при обновлении предложения в базе данных </value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_actualizando_oferta" xml:space="preserve">
    <value>Ошибка при обновлении предложения в базе данных </value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_actualizando_pedido" xml:space="preserve">
    <value>***&gt; Невозможно обновить заказ </value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_agente_trazador" xml:space="preserve">
    <value>Ошибка в работе трейсера</value>
  </data>
  <data name="error_autenticar_usuario" xml:space="preserve">
    <value>Невозможно идентифицировать пользователя </value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_base_de_datos" xml:space="preserve">
    <value>Ошибка базы данных</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_base_de_datos_no_abierta" xml:space="preserve">
    <value>База данных должна быть открытой </value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_borrando_recetas" xml:space="preserve">
    <value>Ошибка при удалении рецепта </value>
  </data>
  <data name="error_busqueda_inicial_pedidos" xml:space="preserve">
    <value>Ошибка при поиске начальных заказов</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_busqueda_pedidos" xml:space="preserve">
    <value>Ошибка в поиске заказов</value>
  </data>
  <data name="error_busqueda_recetas" xml:space="preserve">
    <value>Ошибка в поиске рецепта</value>
  </data>
  <data name="error_calibracion" xml:space="preserve">
    <value>Ошибка при калибровке</value>
  </data>
  <data name="error_cambiando_password" xml:space="preserve">
    <value>Невозможно изменить пароль </value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_caracteres_diferentes_clave_acceso" xml:space="preserve">
    <value>Ошибка в пароле</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_caracteres_no_permitidos_clave_acceso" xml:space="preserve">
    <value>В пароле не разрешены буквы</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_cargando_destinatarios" xml:space="preserve">
    <value>Ошибка при загрузке получателя</value>
  </data>
  <data name="error_claves_no_coinciden" xml:space="preserve">
    <value>Пароли не совпадают </value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_clave_acceso_no_permitida" xml:space="preserve">
    <value>Пароль не разрешен </value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_clave_incorrecta" xml:space="preserve">
    <value>Указанный пароль неверен </value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_conexion_agente_trazador" xml:space="preserve">
    <value>Нет связи с действующим трейсером </value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_conexion_agente_trazador_elegido" xml:space="preserve">
    <value>Ошибка связи с выбранным трейсером</value>
  </data>
  <data name="error_conexion_agente_trazador_insertado" xml:space="preserve">
    <value>Ошибка связи с введённым трейсером</value>
  </data>
  <data name="error_conexion_servicio" xml:space="preserve">
    <value>Отсуствует связь с сервером</value>
  </data>
  <data name="error_desconocido" xml:space="preserve">
    <value>Неизвестная ошибка</value>
  </data>
  <data name="error_email_direccion_invalida" xml:space="preserve">
    <value>Неверно указан адрес электронной почты </value>
    <comment>Errors</comment>
  </data>
  <data name="error_email_falta_asunto" xml:space="preserve">
    <value>Необходимо указать предмет </value>
    <comment>Errors</comment>
  </data>
  <data name="error_email_falta_cuerpo" xml:space="preserve">
    <value>Необходимо составить сообщение </value>
    <comment>Errors</comment>
  </data>
  <data name="error_email_falta_direccion" xml:space="preserve">
    <value>Необходимо ввести адрес электронной почты </value>
    <comment>Errors</comment>
  </data>
  <data name="error_enviando_correo" xml:space="preserve">
    <value>Невозможно отправить почту</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_enviando_lista_recetas" xml:space="preserve">
    <value>Ошибка при отправке листа рецептов</value>
  </data>
  <data name="error_enviando_mail" xml:space="preserve">
    <value>При отправлении почты произошла ошибка </value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_excepcion_autenticando_usuario" xml:space="preserve">
    <value>Ошибка при идентификации пользователя</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_excepcion_copando_clase_pedidos" xml:space="preserve">
    <value>Ошибка при копировании класса заказа</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_excepcion_enviando_correo" xml:space="preserve">
    <value>Ошибка при отправке почты </value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_excepcion_enviando_pedido" xml:space="preserve">
    <value>Ошибка при отправке заказа </value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_excepcion_enviando_pedido_para_comparacion" xml:space="preserve">
    <value>Ошибка при отправке заказа для сравнения</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_excepcion_obtenido_el_area_venta" xml:space="preserve">
    <value>Ошибка при получении региона продаж </value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_excepcion_obteniendo_pedidos_busqueda" xml:space="preserve">
    <value>Ошибка при получении листа заказов </value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_excepcion_obteniendo_pedidos_inicial" xml:space="preserve">
    <value>Ошибка при получении листа заказов </value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_excepcion_producida" xml:space="preserve">
    <value>Произошла ошибка </value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_excepcion_recuperando_oferta" xml:space="preserve">
    <value>Ошибка при восстановлении предложения</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_exceso_numero_intentos_permitidos" xml:space="preserve">
    <value>Было превышено число разрешенных попыток </value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_grabando_pedido" xml:space="preserve">
    <value>***&gt; Невозможно сохранить заказ </value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_guardando_datos_configuracion" xml:space="preserve">
    <value>Ошибка при сохранении данных настройки </value>
  </data>
  <data name="error_guardando_datos_optica" xml:space="preserve">
    <value>Ошибка при сохранении оптических данных </value>
  </data>
  <data name="error_guardando_forma" xml:space="preserve">
    <value>Ошибка при сохранении формы </value>
  </data>
  <data name="error_guardando_oferta" xml:space="preserve">
    <value>Ошибка при сохраненнии предложения </value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_iniciando_aplicacion" xml:space="preserve">
    <value>Ошибка при запуске приложения </value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_introducir_nueva_clave" xml:space="preserve">
    <value>Введите новый пароль</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_lecturas_agentes" xml:space="preserve">
    <value>Ошибка во время подключения {0} к трейсеру {1} </value>
  </data>
  <data name="error_leyendo_fichero_base_de_datos" xml:space="preserve">
    <value>Ошибка во время чтения файла базы данных </value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_login" xml:space="preserve">
    <value>Ошибка при введении логина </value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_no_se_han_obtenido_datos_configuracion_cliente" xml:space="preserve">
    <value>Данные конфигурации клиента не были получены </value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_no_se_ha_obtenido_el_area_venta" xml:space="preserve">
    <value>Невозможно получении области продаж </value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_no_se_ha_podido_recuperar_lista_pedidos" xml:space="preserve">
    <value>***&gt; Не возможно восстановить лист заказов </value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_no_se_ha_podido_recuperar_oferta" xml:space="preserve">
    <value>***&gt; Предложение не может быть восстановлено </value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_obteniendo_los_datos_configuracion_del_cliente" xml:space="preserve">
    <value>Произошла ошибка при получении данных установки </value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_proceso_login" xml:space="preserve">
    <value>Произошла ошибка при введении логина</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_recuperando_monturas" xml:space="preserve">
    <value>Ошибка при восстановлении списка оправ</value>
  </data>
  <data name="error_recuperando_pedido" xml:space="preserve">
    <value>***&gt; Не возможно восстановить заказ </value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_recuperando_receta" xml:space="preserve">
    <value>***&gt; Не возможно восстановить рецепт</value>
  </data>
  <data name="error_recuperando_recetas" xml:space="preserve">
    <value>Ошибка при восстановлении рецептов</value>
  </data>
  <data name="error_reintentos_excedidos" xml:space="preserve">
    <value>Превышено количество повторных попыток </value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_servicio_cambio_de_password" xml:space="preserve">
    <value>Ошибка в сервисе "Изменение пароля" </value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_servicio_envio_correo" xml:space="preserve">
    <value>Ошибка в сервисе "Отправить почту"</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_servicio_login" xml:space="preserve">
    <value>Ошибка в сервисе "Логин" </value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_signo_interrogacion_no_permitido_clave_acceso" xml:space="preserve">
    <value>Знак вопроса не возможен в пароле</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_tipo_de_base_de_datos_desconocida" xml:space="preserve">
    <value>Неизвестный вид базы данных </value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_usuario_bloqueado" xml:space="preserve">
    <value>Пользователь заблокирован</value>
  </data>
  <data name="error_usuario_no_existe" xml:space="preserve">
    <value>Пользователь не существует</value>
  </data>
  <data name="error_usuario_ya_existe" xml:space="preserve">
    <value>Пользователь уже существует</value>
  </data>
  <data name="esfera" xml:space="preserve">
    <value>Сфера </value>
    <comment>Home screen, list of last orders</comment>
  </data>
  <data name="esfera_abrv" xml:space="preserve">
    <value>Sph</value>
    <comment>Order list header</comment>
  </data>
  <data name="espana" xml:space="preserve">
    <value>Испания</value>
  </data>
  <data name="espesor" xml:space="preserve">
    <value>Толщина </value>
    <comment>Technical data</comment>
  </data>
  <data name="espesores" xml:space="preserve">
    <value>Толщины</value>
  </data>
  <data name="espesor_borde_esp" xml:space="preserve">
    <value>Спец.толщина края</value>
  </data>
  <data name="espesor_borde_especial" xml:space="preserve">
    <value>Спец.толщина края</value>
  </data>
  <data name="espesor_centro" xml:space="preserve">
    <value>Толщина по центру </value>
    <comment>Technical data</comment>
  </data>
  <data name="espesor_max" xml:space="preserve">
    <value>Макс.толщина</value>
  </data>
  <data name="espesor_maximo" xml:space="preserve">
    <value>Макс.толщина</value>
    <comment>Technical data</comment>
  </data>
  <data name="espesor_min" xml:space="preserve">
    <value>Мин.толщина</value>
  </data>
  <data name="espesor_minimo" xml:space="preserve">
    <value>Мин.толщина</value>
    <comment>Technical data</comment>
  </data>
  <data name="espesor_taladro_nasal" xml:space="preserve">
    <value>Толщина в точке сверления с носовой стороны</value>
    <comment>Technical data</comment>
  </data>
  <data name="espesor_taladro_temporal" xml:space="preserve">
    <value>Толщина в точке сверления с височной стороны</value>
    <comment>Technical data</comment>
  </data>
  <data name="estado" xml:space="preserve">
    <value>Состояние </value>
    <comment>Home screen, list of last orders</comment>
  </data>
  <data name="estadoOD" xml:space="preserve">
    <value>Статус OD</value>
  </data>
  <data name="estadoOI" xml:space="preserve">
    <value>Статус OS</value>
  </data>
  <data name="estado_pedido_grabados" xml:space="preserve">
    <value>Гравировка</value>
  </data>
  <data name="estado_pedido_pendientes" xml:space="preserve">
    <value>Ожидание восстановления</value>
  </data>
  <data name="estado_pedido_todos" xml:space="preserve">
    <value>Все</value>
  </data>
  <data name="es_anterior_a" xml:space="preserve">
    <value>предыдущий перед</value>
  </data>
  <data name="es_necesario_cerrar_la_aplicacion" xml:space="preserve">
    <value>Приложение было установлено правильно. {0} При нажатии "Принимаю", подождите, чтобы окно закрылось, а затем щелкните дважды на иконке Indonet на Вашем рабочем столе</value>
  </data>
  <data name="expedido" xml:space="preserve">
    <value>Отправлен</value>
  </data>
  <data name="explorar" xml:space="preserve">
    <value>Исследовать</value>
  </data>
  <data name="fecha" xml:space="preserve">
    <value>Дата </value>
    <comment>Home screen, list of last orders</comment>
  </data>
  <data name="fecha_hi_menor_low" xml:space="preserve">
    <value>Данные "до" не могут быть раньше, чем "от"</value>
  </data>
  <data name="fecha_hi_nula" xml:space="preserve">
    <value>Данные "до" не могут быть незаполнены</value>
  </data>
  <data name="fecha_hora_grabacion" xml:space="preserve">
    <value>Дата и время сохранения</value>
  </data>
  <data name="fecha_low_nula" xml:space="preserve">
    <value>Данные "от" не могут быть незаполнена</value>
  </data>
  <data name="fecha_prevista_envio" xml:space="preserve">
    <value>Планируемая дата отправки</value>
  </data>
  <data name="fecha_rango" xml:space="preserve">
    <value>Дата (интервал в 7 дней)</value>
  </data>
  <data name="finalizado" xml:space="preserve">
    <value>Законченный</value>
  </data>
  <data name="foco_y_material" xml:space="preserve">
    <value>Фокус и материал</value>
    <comment>Eye basic data view</comment>
  </data>
  <data name="forma" xml:space="preserve">
    <value>Форма</value>
  </data>
  <data name="formas_leidas" xml:space="preserve">
    <value>Формы прочитаны</value>
  </data>
  <data name="formas_y_espesores" xml:space="preserve">
    <value>Толщина и форма</value>
    <comment>Order screen</comment>
  </data>
  <data name="forma_escogida" xml:space="preserve">
    <value>Выбранная форма</value>
  </data>
  <data name="forma_guardada_OK" xml:space="preserve">
    <value>Форма сохранена верно</value>
  </data>
  <data name="fotocromatico" xml:space="preserve">
    <value>Фотохромная технология</value>
    <comment>Order screen</comment>
  </data>
  <data name="francia" xml:space="preserve">
    <value>Франция</value>
  </data>
  <data name="frontal" xml:space="preserve">
    <value>Передняя</value>
    <comment>Bevel direction</comment>
  </data>
  <data name="ganancia_espesor" xml:space="preserve">
    <value>Получение толщины </value>
    <comment>Technical data</comment>
  </data>
  <data name="ganancia_peso" xml:space="preserve">
    <value>Получение веса </value>
    <comment>Technical data</comment>
  </data>
  <data name="grados" xml:space="preserve">
    <value>Градус </value>
    <comment>Supplements</comment>
  </data>
  <data name="guardar_biselado" xml:space="preserve">
    <value>Сохранить скос</value>
  </data>
  <data name="guardar_receta" xml:space="preserve">
    <value>Сохранить рецепт </value>
    <comment>Order action button panel</comment>
  </data>
  <data name="hora" xml:space="preserve">
    <value>Время</value>
  </data>
  <data name="idioma" xml:space="preserve">
    <value>Язык </value>
    <comment>Login screen</comment>
  </data>
  <data name="imprimir" xml:space="preserve">
    <value>Печать </value>
    <comment>Order action button panel</comment>
  </data>
  <data name="imprimir_lista" xml:space="preserve">
    <value>Печать списка</value>
  </data>
  <data name="indice" xml:space="preserve">
    <value>Индекс </value>
    <comment>Eye basic data view</comment>
  </data>
  <data name="indonet" xml:space="preserve">
    <value>Indonet</value>
  </data>
  <data name="indo_en_el_mundo" xml:space="preserve">
    <value>INDO в мире</value>
  </data>
  <data name="inferior" xml:space="preserve">
    <value>Ниже</value>
  </data>
  <data name="informacion" xml:space="preserve">
    <value>Информация</value>
  </data>
  <data name="info_creacion_bbdd_local" xml:space="preserve">
    <value>***&gt; Начать создание локальной базы данных </value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="info_creacion_bbdd_local_OK" xml:space="preserve">
    <value>***&gt; Создание локальной базы данных ОК </value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="info_grabar_oferta" xml:space="preserve">
    <value>***&gt; Начать сохранение предложения в локальной базе данных </value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="info_grabar_oferta_OK" xml:space="preserve">
    <value>***&gt; Сохранение предложения в локальной базе данных ОК</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="info_pedido_procesado" xml:space="preserve">
    <value>Ваш заказ {0} был сохранен и обработан верно</value>
  </data>
  <data name="inicio" xml:space="preserve">
    <value>Главная страница </value>
    <comment>Main navigation bar</comment>
  </data>
  <data name="intervalo_de_fechas" xml:space="preserve">
    <value>Интервал дат</value>
  </data>
  <data name="italia" xml:space="preserve">
    <value>Италия</value>
  </data>
  <data name="laboratorio" xml:space="preserve">
    <value>Лаборатория</value>
  </data>
  <data name="lente" xml:space="preserve">
    <value>Линза </value>
    <comment>Home screen, list of last orders</comment>
  </data>
  <data name="login_verificar" xml:space="preserve">
    <value>ПРОВЕРИТЬ</value>
  </data>
  <data name="logout" xml:space="preserve">
    <value>Выйти из программы</value>
    <comment>Toolbar</comment>
  </data>
  <data name="log_actualizacion_oferta" xml:space="preserve">
    <value>***&gt; Начать обновление предложения </value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="log_cabecera_oferta_ok" xml:space="preserve">
    <value>***&gt; Заголовок предложения ОК </value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="log_oferta_descargada_ok" xml:space="preserve">
    <value>***&gt; Предложение загружено ОК </value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="l_taladrada" xml:space="preserve">
    <value>Плоский </value>
    <comment>Bevel type</comment>
  </data>
  <data name="marca" xml:space="preserve">
    <value>Бренд</value>
  </data>
  <data name="mensaje" xml:space="preserve">
    <value>Сообщение</value>
  </data>
  <data name="mensaje_solicitud_clave" xml:space="preserve">
    <value>Для получения пароля Вам нужно ввести правильный e-mail и Ваш код клиента INDO. {0} В кратчайшее время Вам будет выслан пароль для входа в программу Indonet. {0} Спасибо</value>
  </data>
  <data name="metal" xml:space="preserve">
    <value>Металлическая</value>
    <comment>type of frame</comment>
  </data>
  <data name="mini_bisel" xml:space="preserve">
    <value>Минифацет</value>
    <comment>Bevel type</comment>
  </data>
  <data name="mis_biselados" xml:space="preserve">
    <value>Мой фацет</value>
  </data>
  <data name="modelo" xml:space="preserve">
    <value>Модель</value>
  </data>
  <data name="modificar_espesor" xml:space="preserve">
    <value>Изменить толщину</value>
  </data>
  <data name="montaje" xml:space="preserve">
    <value>Установка</value>
  </data>
  <data name="monturas" xml:space="preserve">
    <value>Оправы </value>
    <comment>Order screen</comment>
  </data>
  <data name="mostrar_lentes_stock" xml:space="preserve">
    <value>Показывать только складские линзы </value>
    <comment>Eye basic data view</comment>
  </data>
  <data name="nasal" xml:space="preserve">
    <value>Носовой</value>
  </data>
  <data name="nd" xml:space="preserve">
    <value>Нет данных</value>
    <comment>Technical data</comment>
  </data>
  <data name="ninguna" xml:space="preserve">
    <value>Ничего</value>
  </data>
  <data name="noticias" xml:space="preserve">
    <value>Новости </value>
    <comment>Home screen</comment>
  </data>
  <data name="no_esta_bien_formado" xml:space="preserve">
    <value>Плохо сформирован </value>
    <comment>Confirmations</comment>
  </data>
  <data name="no_es_multiplo_de" xml:space="preserve">
    <value>Не кратное число </value>
    <comment>Confirmations</comment>
  </data>
  <data name="no_se_actualiza_la_oferta" xml:space="preserve">
    <value>Предложение не обновлено</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="no_se_ha_podido_enviar_la_solucitud" xml:space="preserve">
    <value>Невозможно отправить запрос, пожалуйста, попробуйте позже </value>
    <comment>popupMail</comment>
  </data>
  <data name="nueva_prev" xml:space="preserve">
    <value>Новый прогноз</value>
    <comment>Order list header</comment>
  </data>
  <data name="nueva_prevision" xml:space="preserve">
    <value>Новый прогноз</value>
    <comment>Home screen, list of last orders</comment>
  </data>
  <data name="numero_pedido" xml:space="preserve">
    <value>Номер заказа  </value>
  </data>
  <data name="n_abbe" xml:space="preserve">
    <value>Abbe</value>
    <comment>Technical data</comment>
  </data>
  <data name="n_pedido" xml:space="preserve">
    <value>Номер заказа </value>
    <comment>Home screen, list of last orders</comment>
  </data>
  <data name="observaciones" xml:space="preserve">
    <value>Наблюдения</value>
  </data>
  <data name="OD" xml:space="preserve">
    <value>OD</value>
    <comment>Right eye</comment>
  </data>
  <data name="oferta" xml:space="preserve">
    <value>Предложение</value>
  </data>
  <data name="OI" xml:space="preserve">
    <value>OS</value>
    <comment>Left Eye</comment>
  </data>
  <data name="ojo_derecho" xml:space="preserve">
    <value>Правый глаз </value>
    <comment>Send order</comment>
  </data>
  <data name="ojo_informativo" xml:space="preserve">
    <value>Не изготавливать</value>
    <comment>Eye basic data view</comment>
  </data>
  <data name="ojo_izquierdo" xml:space="preserve">
    <value>Левый глаз </value>
    <comment>Send order</comment>
  </data>
  <data name="orientacion_del_bisel" xml:space="preserve">
    <value>Направление скоса</value>
  </data>
  <data name="paso1" xml:space="preserve">
    <value>Шаг 1</value>
  </data>
  <data name="paso2" xml:space="preserve">
    <value>Шаг 2</value>
  </data>
  <data name="pedidos_al_por_mayor" xml:space="preserve">
    <value>Оптовые заказы</value>
  </data>
  <data name="pedidos_obtenidos" xml:space="preserve">
    <value>Заказ/ы получен/ы</value>
  </data>
  <data name="pedidos_realizados" xml:space="preserve">
    <value>Размещенные заказы</value>
    <comment>Main navigation bar</comment>
  </data>
  <data name="pedido_con_errores" xml:space="preserve">
    <value>Заказ с ошибками. Проверьте предостережения</value>
    <comment>Alerts</comment>
  </data>
  <data name="peso" xml:space="preserve">
    <value>Вес </value>
    <comment>Technical data</comment>
  </data>
  <data name="plano" xml:space="preserve">
    <value>Плоский </value>
    <comment>Bevel type</comment>
  </data>
  <data name="portugal" xml:space="preserve">
    <value>Португалия</value>
  </data>
  <data name="por_favor_confirma_datos_pedido" xml:space="preserve">
    <value>Пожалуйста, подтвердите детали своего заказа: </value>
    <comment>Send order</comment>
  </data>
  <data name="precalibrado_a_cotas" xml:space="preserve">
    <value>Ручная прекалибровка</value>
  </data>
  <data name="precalibrado_digital" xml:space="preserve">
    <value>Цифровая прекалибровка</value>
  </data>
  <data name="precio" xml:space="preserve">
    <value>Цены</value>
  </data>
  <data name="prevision" xml:space="preserve">
    <value>Прогноз </value>
    <comment>Home screen, list of last orders</comment>
  </data>
  <data name="prisma_1" xml:space="preserve">
    <value>Призма 1</value>
    <comment>Supplements</comment>
  </data>
  <data name="prisma_2" xml:space="preserve">
    <value>Призма 2 </value>
    <comment>Supplements</comment>
  </data>
  <data name="producto_lente" xml:space="preserve">
    <value>Продукт</value>
  </data>
  <data name="puente" xml:space="preserve">
    <value>Ширина переносья</value>
    <comment>Additional data</comment>
  </data>
  <data name="radio" xml:space="preserve">
    <value>Радиус</value>
    <comment>Technical data</comment>
  </data>
  <data name="ranurada" xml:space="preserve">
    <value>Леска</value>
    <comment>type of frame</comment>
  </data>
  <data name="ranurada_metal" xml:space="preserve">
    <value>Металическая леска </value>
    <comment>Bevel type</comment>
  </data>
  <data name="ranurada_nylon" xml:space="preserve">
    <value>Нейлоновая леска </value>
    <comment>Bevel type</comment>
  </data>
  <data name="realizar_pedido" xml:space="preserve">
    <value>Разместить заказ </value>
    <comment>Main navigation bar</comment>
  </data>
  <data name="recetas_borrado" xml:space="preserve">
    <value>Удаление рецепта</value>
  </data>
  <data name="recetas_borrar" xml:space="preserve">
    <value>Удалить рецепт</value>
  </data>
  <data name="recetas_enviar" xml:space="preserve">
    <value>Отправить рецепт</value>
  </data>
  <data name="recetas_envio" xml:space="preserve">
    <value>Отправление рецепта</value>
  </data>
  <data name="recetas_guardadas" xml:space="preserve">
    <value>Сохраненные рецепты </value>
    <comment>Main navigation bar</comment>
  </data>
  <data name="recetas_obtenidas" xml:space="preserve">
    <value>Рецепт/ы получен/ые</value>
  </data>
  <data name="receta_envio_masivo" xml:space="preserve">
    <value>Рецепт отправлен полностью</value>
  </data>
  <data name="receta_guardada" xml:space="preserve">
    <value>Рецепт был сохранен верно</value>
  </data>
  <data name="redimensionar" xml:space="preserve">
    <value>Изменить размер</value>
  </data>
  <data name="referencia" xml:space="preserve">
    <value>Клиент</value>
    <comment>Order screen</comment>
  </data>
  <data name="ref_cliente" xml:space="preserve">
    <value>Клиент</value>
    <comment>Home screen, list of last orders</comment>
  </data>
  <data name="registros" xml:space="preserve">
    <value>Записи</value>
  </data>
  <data name="resto_de_paises" xml:space="preserve">
    <value>Другие страны</value>
    <comment>Login</comment>
  </data>
  <data name="rx" xml:space="preserve">
    <value>RX</value>
    <comment>Home screen, list of last orders</comment>
  </data>
  <data name="salir" xml:space="preserve">
    <value>Выйти</value>
  </data>
  <data name="segura" xml:space="preserve">
    <value>БЕЗОПАСНЫЙ</value>
  </data>
  <data name="seguro_que_quiere_desconectarse" xml:space="preserve">
    <value>Вы уверены, что хотите выйти? </value>
    <comment>Alerts</comment>
  </data>
  <data name="seguro_que_quiere_salir" xml:space="preserve">
    <value>Вы уверены, что хотите выйти из приложения?</value>
    <comment>Alerts</comment>
  </data>
  <data name="selecciona" xml:space="preserve">
    <value>Выбрать</value>
  </data>
  <data name="selecciona_abrv" xml:space="preserve">
    <value>Выбрать</value>
  </data>
  <data name="seleccion_de_espesores" xml:space="preserve">
    <value>Выбор толщины</value>
  </data>
  <data name="seleccion_vacia_recetas" xml:space="preserve">
    <value>Не выбрано ни одного рецепта</value>
  </data>
  <data name="se_actualizo" xml:space="preserve">
    <value>Обновленный</value>
  </data>
  <data name="siguiente" xml:space="preserve">
    <value>Следующий</value>
  </data>
  <data name="simulador_de_lentes" xml:space="preserve">
    <value>Иммитатор линз</value>
  </data>
  <data name="simulador_error" xml:space="preserve">
    <value>Ошибка при запуске иммитатора линз</value>
  </data>
  <data name="simulador_no_instalado" xml:space="preserve">
    <value>Иммитатор линз не установлен</value>
  </data>
  <data name="sin_coloracion" xml:space="preserve">
    <value>Без окрашивания</value>
  </data>
  <data name="sin_fotocromatico" xml:space="preserve">
    <value>Не фотохромные</value>
  </data>
  <data name="sin_precalibrado" xml:space="preserve">
    <value>Без прекалибровки</value>
  </data>
  <data name="situacion_de_los_pedidos" xml:space="preserve">
    <value>Статус заказа</value>
  </data>
  <data name="solicitar_acceso" xml:space="preserve">
    <value>Запросить доступ</value>
  </data>
  <data name="solicitar_clave_acceso" xml:space="preserve">
    <value>Запросить код доступа</value>
  </data>
  <data name="solicitud_acceso" xml:space="preserve">
    <value>Запрос доступа</value>
  </data>
  <data name="solicitud_enviada_correctamente" xml:space="preserve">
    <value>Запрос на доступ успешно отправлен </value>
    <comment>popupMail</comment>
  </data>
  <data name="solo_stock" xml:space="preserve">
    <value>Только склад</value>
  </data>
  <data name="stock" xml:space="preserve">
    <value>Склад</value>
  </data>
  <data name="sugerencias" xml:space="preserve">
    <value>Советы</value>
    <comment>Toolbar</comment>
  </data>
  <data name="superior" xml:space="preserve">
    <value>Верхний</value>
  </data>
  <data name="suplementos" xml:space="preserve">
    <value>Дополнения </value>
    <comment>Basic data</comment>
  </data>
  <data name="taladrada" xml:space="preserve">
    <value>Винтовая</value>
    <comment>type of frame</comment>
  </data>
  <data name="talla" xml:space="preserve">
    <value>Размер</value>
  </data>
  <data name="temporal" xml:space="preserve">
    <value>Временный</value>
  </data>
  <data name="texto" xml:space="preserve">
    <value>Текст</value>
  </data>
  <data name="texto_presentacion1" xml:space="preserve">
    <value>Добро пожаловать в новое поколение онлайн-заказа</value>
  </data>
  <data name="texto_presentacion2" xml:space="preserve">
    <value>Это начало нового INDO!</value>
  </data>
  <data name="texto_presentacion3" xml:space="preserve">
    <value>Оцените самый быстрый и наглядный способ размещения заказов на оптическом рынке! Благодаря новым возможностям Вы сможете увеличить объем продаж высокотехнологичных линз.</value>
  </data>
  <data name="texto_presentacion4" xml:space="preserve">
    <value>Персонализация толщин и автоматическая оптимизация при изготовлении линз</value>
  </data>
  <data name="texto_presentacion5" xml:space="preserve">
    <value>Совместимо с большинством трейсеров на  рынке</value>
  </data>
  <data name="texto_presentacion6" xml:space="preserve">
    <value>Более подробное отслеживание Ваших заказов</value>
  </data>
  <data name="texto_presentacion7" xml:space="preserve">
    <value>Спросите у нашего представителя о наилучшем решении ваших задач</value>
  </data>
  <data name="text_info_contacto" xml:space="preserve">
    <value>Если Вам необходима дополнительная информация, свяжитесь с нами:</value>
    <comment>Login screen</comment>
  </data>
  <data name="tipo_bisel" xml:space="preserve">
    <value>Тип фацета</value>
  </data>
  <data name="tipo_de_bisel" xml:space="preserve">
    <value>Тип фацета</value>
  </data>
  <data name="tipo_de_montura" xml:space="preserve">
    <value>Тип оправы</value>
  </data>
  <data name="tipo_lente" xml:space="preserve">
    <value>Тип линзы</value>
  </data>
  <data name="tipo_montura" xml:space="preserve">
    <value>Тип оправы</value>
  </data>
  <data name="tono" xml:space="preserve">
    <value>Тон </value>
    <comment>Order screen</comment>
  </data>
  <data name="tratamientos" xml:space="preserve">
    <value>Покрытия </value>
    <comment>Eye basic data view</comment>
  </data>
  <data name="tratamiento_coloracion" xml:space="preserve">
    <value>Покрытия и окрашивание</value>
  </data>
  <data name="trazador" xml:space="preserve">
    <value>Трейсер</value>
  </data>
  <data name="trazadores" xml:space="preserve">
    <value>Трейсер</value>
  </data>
  <data name="trazadores_anadir" xml:space="preserve">
    <value>Добавить...</value>
  </data>
  <data name="trazadores_borrar" xml:space="preserve">
    <value>Удалить</value>
  </data>
  <data name="trazadores_borrar_todos" xml:space="preserve">
    <value>Удалите все</value>
  </data>
  <data name="trazadores_comprobacion" xml:space="preserve">
    <value>Проверить</value>
  </data>
  <data name="trazadores_conexion_agente_trazador_OK" xml:space="preserve">
    <value>ТРЕЙСЕР {0} СОЕДИНЕН ВЕРНО</value>
  </data>
  <data name="trazadores_direccion" xml:space="preserve">
    <value>Адрес</value>
  </data>
  <data name="trazadores_modificar" xml:space="preserve">
    <value>Изменить</value>
  </data>
  <data name="trazadores_nombre_agente" xml:space="preserve">
    <value>Имя трейсера</value>
  </data>
  <data name="trazadores_popup_anular" xml:space="preserve">
    <value>Аннулировать</value>
  </data>
  <data name="trazadores_popup_titulo" xml:space="preserve">
    <value>Выбор / Изменение трейсера</value>
  </data>
  <data name="trazadores_puerto" xml:space="preserve">
    <value>Порт</value>
  </data>
  <data name="trazadores_trazador" xml:space="preserve">
    <value>Трейсер</value>
  </data>
  <data name="trazador_busqueda_ordenadores_red" xml:space="preserve">
    <value>Выбрать раздел в сети</value>
  </data>
  <data name="tto_adicional" xml:space="preserve">
    <value>Доп.покрытие</value>
    <comment>Eye basic data view</comment>
  </data>
  <data name="tutoriales" xml:space="preserve">
    <value>Обучающие программы </value>
    <comment>Toolbar</comment>
  </data>
  <data name="t_por_ciento" xml:space="preserve">
    <value>T % </value>
    <comment>Technical data</comment>
  </data>
  <data name="ultimos_pedidos" xml:space="preserve">
    <value>Последние заказы </value>
    <comment>Home screen</comment>
  </data>
  <data name="url_indonet_direct" xml:space="preserve">
    <value>http://pedidos.direc.indo.es/ (ссылки)  </value>
    <comment>Links </comment>
  </data>
  <data name="url_indo_en_el_mundo" xml:space="preserve">
    <value>http://www.indo.es/indo-en-el-mundo/indo_enelmundo.asp (ссылки)</value>
    <comment>Links</comment>
  </data>
  <data name="url_mas_informacion" xml:space="preserve">
    <value>http://www.indo.es/lentes/lentes_lentesindo.asp (ссылки)</value>
    <comment>Links</comment>
  </data>
  <data name="url_tutoriales" xml:space="preserve">
    <value>http://www.indo.es/indonet/tutoriales.asp (ссылки)</value>
    <comment>Links</comment>
  </data>
  <data name="ventana_espera_actualizar_pedido" xml:space="preserve">
    <value>ОБНОВЛЕНИЕ ЗАКАЗА</value>
  </data>
  <data name="ventana_espera_areaventa" xml:space="preserve">
    <value>ЗАГРУЗКА РЕГИОНОВ ПРОДАЖ</value>
  </data>
  <data name="ventana_espera_borrar_recetas" xml:space="preserve">
    <value>УДАЛЕНИЕ РЕЦЕПТОВ</value>
  </data>
  <data name="ventana_espera_busqueda_pedidos" xml:space="preserve">
    <value>ПОИСК ЗАКАЗОВ</value>
  </data>
  <data name="ventana_espera_busqueda_recetas" xml:space="preserve">
    <value>ПОИСК РЕЦЕПТОВ</value>
  </data>
  <data name="ventana_espera_cambio_clave" xml:space="preserve">
    <value>СОХРАНЕНИЕ НОВОГО ПАРОЛЯ</value>
  </data>
  <data name="ventana_espera_carga_pedido" xml:space="preserve">
    <value>ЗАГРУЗКА ЗАКАЗА {0}</value>
  </data>
  <data name="ventana_espera_carga_pedidos_inicial" xml:space="preserve">
    <value>ЗАГРУЗКА СПИСКА ПОСЛЕДНИХ ЗАКАЗОВ</value>
  </data>
  <data name="ventana_espera_carga_receta" xml:space="preserve">
    <value>ЗАГРУЗКА РЕЦЕПТА {0}</value>
  </data>
  <data name="ventana_espera_configuracion" xml:space="preserve">
    <value>ЗАГРУЗКА УСТАНОВОК</value>
  </data>
  <data name="ventana_espera_destinatarios" xml:space="preserve">
    <value>ЗАГРУЗКА ПОЛУЧАТЕЛЕЙ</value>
  </data>
  <data name="ventana_espera_envio_correo" xml:space="preserve">
    <value>ОТПРАВКА ПОЧТЫ</value>
  </data>
  <data name="ventana_espera_envio_pedidos" xml:space="preserve">
    <value>ОТПРАВКА ЗАКАЗА</value>
  </data>
  <data name="ventana_espera_envio_recetas" xml:space="preserve">
    <value>ОТПРАВКА РЕЦЕПТОВ</value>
  </data>
  <data name="ventana_espera_esperar" xml:space="preserve">
    <value>Ожидание   </value>
  </data>
  <data name="ventana_espera_guardar_configuracion" xml:space="preserve">
    <value>СОХРАНЕНИЕ УСТАНОВОК</value>
  </data>
  <data name="ventana_espera_guardar_datos_optica" xml:space="preserve">
    <value>СОХРАНЕНИЕ ОПТИЧЕСКИХ ДАННЫХ</value>
  </data>
  <data name="ventana_espera_guardar_receta" xml:space="preserve">
    <value>СОХРАНЕНИЕ РЕЦЕПТА {0}</value>
  </data>
  <data name="ventana_espera_lecturas_trazador" xml:space="preserve">
    <value>СЧИТЫВАНИЕ ДАННЫХ ТРЕЙСЕРА {0} {1}</value>
  </data>
  <data name="ventana_espera_lecturas_trazadores" xml:space="preserve">
    <value>СЧИТЫВАНИЕ ДАННЫХ ТРЕЙСЕРА {0} </value>
  </data>
  <data name="ventana_espera_lectura_trazador" xml:space="preserve">
    <value>СЧИТЫВАНИЕ {0}</value>
  </data>
  <data name="ventana_espera_login" xml:space="preserve">
    <value>ИДЕНТИФИКАЦИЯ КЛИЕНТА {0}</value>
  </data>
  <data name="ventana_espera_misbiselados" xml:space="preserve">
    <value>ЗАГРУЗКА ФАЦЕТОВ</value>
  </data>
  <data name="ventana_espera_monturas" xml:space="preserve">
    <value>ЗАГРУЗКА ОПРАВ</value>
  </data>
  <data name="ventana_espera_oferta" xml:space="preserve">
    <value>ОБНОВЛЕНИЕ ПРЕДЛОЖЕНИЯ</value>
  </data>
  <data name="ventana_espera_revision_pedidos" xml:space="preserve">
    <value>ОТПРАВКА ДЛЯ ПРОВЕРКИ</value>
  </data>
  <data name="ventana_espera_verifica_conexion_trazador" xml:space="preserve">
    <value>ПРОВЕРЬТЕ СОЕДИНЕНИЕ С ТРЕЙСЕРОМ</value>
  </data>
  <data name="verifique_conexion" xml:space="preserve">
    <value>Проверьте подключение к интернету</value>
  </data>
  <data name="volver_al_listado" xml:space="preserve">
    <value>Вернуться к списку</value>
  </data>
  <data name="zona_de_identificacion" xml:space="preserve">
    <value>Зона идентификации </value>
    <comment>Toolbar</comment>
  </data>
  <data name="aviso_falta_tipo_montura" xml:space="preserve">
    <value>Выберите тип оправы. Нажмите "Далее" с разделе ВЫБОР ТОЛЩИНЫ</value>
  </data>
  <data name="aviso_caracter_no_valido" xml:space="preserve">
    <value>Пожалуйста, используйте латиницу</value>
  </data>
  <data name="aviso_cargando_oferta" xml:space="preserve">
    <value>Загрузка данных</value>
  </data>
  <data name="aviso_alternativa_producto" xml:space="preserve">
    <value>Данного продукта пока нет в наличии. В качестве альтернативы предлагаем следующий продукт по той же цене. Если вам нужно заказать только одну линзу, просьба связаться с нами.</value>
    <comment>Alternativa producto</comment>
  </data>
  <data name="aviso_lecturas_no_cargadas" xml:space="preserve">
    <value>Форма оправы не считана</value>
  </data>
  <data name="aviso_lectura_importada" xml:space="preserve">
    <value>Форма оправы {0} успешно загружена</value>
  </data>
  <data name="biselador_remoto_importar" xml:space="preserve">
    <value>Загрузить</value>
  </data>
  <data name="biselador_remoto_ultima" xml:space="preserve">
    <value>Последнее</value>
  </data>
  <data name="aviso_introducir_montura_1" xml:space="preserve">
    <value>Внимание: Для правильного оформления</value>
  </data>
  <data name="aviso_introducir_montura_2" xml:space="preserve">
    <value>заказа необходимо указать форму оправы.</value>
  </data>
  <data name="espesor_borde" xml:space="preserve">
    <value>Толщина края</value>
  </data>
  <data name="msg_Prerequisitos" xml:space="preserve">
    <value>В дальнейшем начнется автоматическая установка программ, необходимых для снимка и последующей обработки изображений с целью получения формы оправы.</value>
    <comment>IndoScan</comment>
  </data>
  <data name="aviso_reiniciar_oferta" xml:space="preserve">
    <value>Подтвердить перезагрузку каталога?</value>
  </data>
  <data name="calibre_marcas" xml:space="preserve">
    <value>Ширина проема</value>
  </data>
  <data name="reiniciar_fecha_oferta" xml:space="preserve">
    <value>Перезагрузить каталог</value>
  </data>
  <data name="configuracion_hojaPedido" xml:space="preserve">
    <value>Бланк заказа</value>
  </data>
  <data name="configuracion_link_hojaRombos" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/Indonet/Enlaces/HojaPedidos_RU.pdf</value>
  </data>
  <data name="configuracion_link_manualIndoScanIntegrado" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/Indonet/Enlaces/ManualIndoScanIntegrado_RU.pdf</value>
  </data>
  <data name="configuracion_manualIndoScanIntegrado" xml:space="preserve">
    <value>IndoScan инструкция по пользованию</value>
  </data>
  <data name="configuracion_link_manualIndoNet" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/Indonet/Enlaces/ManualIndonet_RU.pdf</value>
  </data>
  <data name="configuracion_manualIndonet" xml:space="preserve">
    <value>Indonet инструкция по пользованию</value>
  </data>
  <data name="Aceptar_Cambio_Y_Enviar" xml:space="preserve">
    <value>Подтвердить измения и отправить</value>
    <comment>Alternativa de producto</comment>
  </data>
  <data name="fecha_prevista_recepcion" xml:space="preserve">
    <value>Planned receiving date</value>
  </data>
  <data name="aviso_precalibrado_forma_cuadrada" xml:space="preserve">
    <value>В случае прекалибровки рекомендуется использовать квадратную форму проема оправы, чтобы избежать "срезания" возможных углов оправы</value>
  </data>
  <data name="parametros_proxy" xml:space="preserve">
    <value>Настройки прокси-соединения</value>
  </data>
  <data name="password" xml:space="preserve">
    <value>Пароль</value>
  </data>
  <data name="usuario" xml:space="preserve">
    <value>Пользователь</value>
  </data>
  <data name="aviso_falta_diametro" xml:space="preserve">
    <value>Необходимо выбрать диаметр</value>
  </data>
  <data name="optimizacion_freemax" xml:space="preserve">
    <value>Оптимизация FreeMax</value>
    <comment>Leyenda del precalibrado digital para MAXIMA</comment>
  </data>
  <data name="valor_fuera_de_margenes" xml:space="preserve">
    <value>{2} За пределами допустимых значений. Допустимые значения  {0} и {1}.</value>
  </data>
  <data name="recordar_mis_credenciales" xml:space="preserve">
    <value>Запомнить учетные данные</value>
  </data>
  <data name="error_grabacion_pedido" xml:space="preserve">
    <value>An exception occurred while saving your order. Please try it again.</value>
  </data>
  <data name="espesor_centro_abr" xml:space="preserve">
    <value>Толщина центра</value>
  </data>
  <data name="ganancia_en_espesor" xml:space="preserve">
    <value>Thickness gain</value>
  </data>
  <data name="ganancia_en_espesor_abr" xml:space="preserve">
    <value>Thick. gain.</value>
  </data>
  <data name="ganancia_en_peso" xml:space="preserve">
    <value>Weight gain</value>
  </data>
  <data name="ganancia_en_peso_abr" xml:space="preserve">
    <value>Weight. g.</value>
  </data>
  <data name="info_envio_pedido_revisar" xml:space="preserve">
    <value>Ваш заказ был сохранен. INDO проверит заказ</value>
  </data>
  <data name="obligatorio_seleccionar_destiantario" xml:space="preserve">
    <value>Пожалуйста, уведомите получателя</value>
  </data>
  <data name="seleccionar_todos" xml:space="preserve">
    <value>Select All</value>
  </data>
  <data name="selecciona_cliente" xml:space="preserve">
    <value>Select client</value>
  </data>
  <data name="selecciona_la_lente_deseada" xml:space="preserve">
    <value>Select desired lens</value>
  </data>
  <data name="SelEscaner" xml:space="preserve">
    <value>Select scanner</value>
    <comment>IndoScan</comment>
  </data>
  <data name="ventana_espera_cargando_clientes" xml:space="preserve">
    <value>RETRIEVING CLIENTS LIST</value>
  </data>
  <data name="ventana_espera_comparando_lentes" xml:space="preserve">
    <value>COMPARING LENSES</value>
  </data>
  <data name="ventana_espera_seleccion_lente" xml:space="preserve">
    <value>SELECTING LENS</value>
  </data>
  <data name="info_ojo_informativo" xml:space="preserve">
    <value>Informative eye will not be manufactured</value>
  </data>
  <data name="aviso_color_nd_diferentes" xml:space="preserve">
    <value>Не допускается окрашивание линз с различными показателями преломления</value>
  </data>
  <data name="aviso_actualizacion" xml:space="preserve">
    <value>Application will be updated to: </value>
    <comment>clickonce</comment>
  </data>
  <data name="aviso_apagado" xml:space="preserve">
    <value>Приложение было обновлено и будет закрыто. Пожалуйста, перезапустите INDONET</value>
    <comment>clickonce</comment>
  </data>
  <data name="mail_informacion_estado_pedidos" xml:space="preserve">
    <value>Е-МЕЙЛ С ИНФОРМАЦИЕЙ О СОСТОЯНИИ ЗАКАЗОВ</value>
    <comment>Mi buzon</comment>
  </data>
  <data name="mi_buzon" xml:space="preserve">
    <value>Мой кабинет</value>
    <comment>Mi buzon</comment>
  </data>
  <data name="texto_direcciones_email" xml:space="preserve">
    <value>Адреса, на которые хотите получать данную информацию</value>
    <comment>Mi buzon</comment>
  </data>
  <data name="texto_pedidos_en_curso" xml:space="preserve">
    <value>Текущие заказы (1 е-мейл в день)</value>
    <comment>Mi buzon</comment>
  </data>
  <data name="texto_pedidos_en_envio" xml:space="preserve">
    <value>Заказы в каждой отправке (1 мейл за отправку)</value>
    <comment>Mi buzon</comment>
  </data>
  <data name="texto_si_deseas_recibir_mails" xml:space="preserve">
    <value>Если желаете получитать е-мейлы с информацией о заказах, выберите один из следующих вариантов</value>
    <comment>Mi buzon</comment>
  </data>
  <data name="aviso_descarga_aplicacion" xml:space="preserve">
    <value>Downloading new version</value>
    <comment>ClickOnce</comment>
  </data>
  <data name="aviso_instalando_version" xml:space="preserve">
    <value>Установка обновленной версии</value>
    <comment>ClickOnce</comment>
  </data>
  <data name="password_internet" xml:space="preserve">
    <value>Internet Password</value>
    <comment>proxy</comment>
  </data>
  <data name="usuario_internet" xml:space="preserve">
    <value>Internet User</value>
    <comment>proxy</comment>
  </data>
  <data name="caracter_hiperlink" xml:space="preserve">
    <value>»</value>
  </data>
  <data name="configuracion_lentesMaxima" xml:space="preserve">
    <value>www.lentesmaxima.com</value>
  </data>
  <data name="configuracion_link_lentesMaxima" xml:space="preserve">
    <value>http://www.lentesmaxima.com</value>
  </data>
  <data name="aviso_mas_30_dias" xml:space="preserve">
    <value>Интервал дат должен быть меньше 30 дней</value>
  </data>
  <data name="graduacion_resultante" xml:space="preserve">
    <value>Окончательный рецепт</value>
  </data>
  <data name="BlankRange" xml:space="preserve">
    <value>Blank range</value>
    <comment>BlankRange</comment>
  </data>
  <data name="info_no_fabricara" xml:space="preserve">
    <value>Esta lente no se fabricará</value>
  </data>
  <data name="activar" xml:space="preserve">
    <value>Активировано</value>
  </data>
  <data name="anadir_encargo_pregunta_graduacion_cerca" xml:space="preserve">
    <value>Зрение для близи</value>
  </data>
  <data name="anadir_encargo_pregunta_graduacion_diferente" xml:space="preserve">
    <value>Рецепт с пересчетом</value>
  </data>
  <data name="anadir_encargo_pregunta_graduacion_lejos" xml:space="preserve">
    <value>Зрение для дали</value>
  </data>
  <data name="anadir_encargo_pregunta_guardar_receta" xml:space="preserve">
    <value>Сохраните и вышлите заказ позже</value>
  </data>
  <data name="anadir_encargo_pregunta_misma_graduacion" xml:space="preserve">
    <value>Рецепт тот же, как в первой оправе</value>
  </data>
  <data name="Atencion" xml:space="preserve">
    <value>Внимание!  </value>
  </data>
  <data name="aviso_atencion_urgencias" xml:space="preserve">
    <value>Внимание! По всем вопросам обращайтесь в Виста Оптикал</value>
  </data>
  <data name="aviso_descarga_aplicacion1" xml:space="preserve">
    <value>Загрузка обновленной версии</value>
  </data>
  <data name="aviso_encargo_anulado" xml:space="preserve">
    <value>Ваш заказ удален</value>
  </data>
  <data name="aviso_encargo_no_anulable" xml:space="preserve">
    <value>Невозможно удалить заказ</value>
  </data>
  <data name="aviso_escoger_alto_indice" xml:space="preserve">
    <value>С целью гарантии качества установки линз в безободковую оправу мы рекомендуем  линзы с показателем преломления 1.6 и выше</value>
  </data>
  <data name="aviso_lectura_cargada" xml:space="preserve">
    <value>Форма оправы {0} успешно загружена</value>
  </data>
  <data name="cancelar_comparacion" xml:space="preserve">
    <value>Отменить сравнение</value>
  </data>
  <data name="cancelar_comparacion1" xml:space="preserve">
    <value>Отменить сравнение</value>
  </data>
  <data name="codigo_de_barras" xml:space="preserve">
    <value>Баркод</value>
  </data>
  <data name="comparar" xml:space="preserve">
    <value>Сравнить</value>
  </data>
  <data name="comparar_lentes" xml:space="preserve">
    <value>Сравнить линзы</value>
  </data>
  <data name="comparar_otra_lente" xml:space="preserve">
    <value>Сравнить другие линзы</value>
  </data>
  <data name="comprobando_si_BBDD" xml:space="preserve">
    <value>Проверка на наличие DB</value>
  </data>
  <data name="comprobando_si_nueva_oferta" xml:space="preserve">
    <value>Проверка на наличие обновления предложения</value>
  </data>
  <data name="comprobar_disponibilidad" xml:space="preserve">
    <value>Проверка на наличие</value>
  </data>
  <data name="configuracion_fecha_oferta" xml:space="preserve">
    <value>Дата заказа</value>
  </data>
  <data name="descargando_oferta" xml:space="preserve">
    <value>Загрузка каталога</value>
  </data>
  <data name="descarga_oferta_fallo_1" xml:space="preserve">
    <value>Невозможно обновить каталог в настоящее время</value>
  </data>
  <data name="descarga_oferta_fallo_2" xml:space="preserve">
    <value>Пожалуйста, попробуйте позже выполнить "ПЕРЕЗАГРУЗИТЬ КАТАЛОГ" на странице "Оптичевки данные"</value>
  </data>
  <data name="desde_scanner" xml:space="preserve">
    <value>Со сканера</value>
  </data>
  <data name="detalle_OMA" xml:space="preserve">
    <value>Диаметр </value>
  </data>
  <data name="diam_anill" xml:space="preserve">
    <value />
  </data>
  <data name="diam_desde" xml:space="preserve">
    <value>Диаметр, начиная с </value>
  </data>
  <data name="diam_hasta" xml:space="preserve">
    <value>Диаметр до</value>
  </data>
  <data name="diam_resul" xml:space="preserve">
    <value>Окончательный диаметр</value>
  </data>
  <data name="direccion_indo" xml:space="preserve">
    <value>Avda. Alcalde Barnils, 72 - 08174 Sant Cugat del Vallés - Barcelona</value>
  </data>
  <data name="Discrepancia_muestra_leida" xml:space="preserve">
    <value>Ошибка: разница между реальным образцом и ожидаемым</value>
  </data>
  <data name="error_borrando_pedido" xml:space="preserve">
    <value>Ошибка при удалении заказа</value>
  </data>
  <data name="error_comprobar_disponibilidad_segunda_gafa" xml:space="preserve">
    <value />
  </data>
  <data name="error_envio_pedido_paso2_1" xml:space="preserve">
    <value>Ошибка соединения во время сохранения заказа. Перейдите в сохраненные заказы с целью проверить сохранение и продолжьте:</value>
  </data>
  <data name="error_envio_pedido_paso2_2" xml:space="preserve">
    <value>Если заказ сохранен, более не сохраняйте</value>
  </data>
  <data name="error_envio_pedido_paso2_3" xml:space="preserve">
    <value>Если заказ не сохранен, еще раз перешлите</value>
  </data>
  <data name="fecha_envio" xml:space="preserve">
    <value>Дата доставки</value>
  </data>
  <data name="ffname" xml:space="preserve">
    <value>Законченный</value>
  </data>
  <data name="FicherosFormas" xml:space="preserve">
    <value>Файл с формой оправы</value>
  </data>
  <data name="formas_fax_enviarFichero" xml:space="preserve">
    <value>Получить файл</value>
  </data>
  <data name="grabar_cambios" xml:space="preserve">
    <value>Сохранить изменения</value>
  </data>
  <data name="imprimir_pedidos_seleccionados" xml:space="preserve">
    <value>Распечатать выбранные заказы</value>
  </data>
  <data name="indice_desde" xml:space="preserve">
    <value>Индекс, начиная с </value>
  </data>
  <data name="indice_hasta" xml:space="preserve">
    <value>Индекс до</value>
  </data>
  <data name="infolog_actualizacion_oferta" xml:space="preserve">
    <value>***&gt; Начать обновление предложения </value>
  </data>
  <data name="infolog_cabecera_oferta_ok" xml:space="preserve">
    <value>***&gt; Начало каталога ОК </value>
  </data>
  <data name="infolog_creacion_bbdd_local" xml:space="preserve">
    <value />
  </data>
  <data name="infolog_creacion_bbdd_local_OK" xml:space="preserve">
    <value />
  </data>
  <data name="infolog_grabar_oferta" xml:space="preserve">
    <value />
  </data>
  <data name="infolog_grabar_oferta_OK" xml:space="preserve">
    <value />
  </data>
  <data name="infolog_oferta_descargada_ok" xml:space="preserve">
    <value>***&gt; Загрузить предложение ОК</value>
  </data>
  <data name="insertar" xml:space="preserve">
    <value>Заполнить</value>
  </data>
  <data name="IntentarOtraVez" xml:space="preserve">
    <value>Пожалуйста, попробуйте еще раз другой имидж или свяжитесь с INDO</value>
  </data>
  <data name="mas_informacion" xml:space="preserve">
    <value>Больше информации</value>
  </data>
  <data name="material" xml:space="preserve">
    <value>Материал</value>
  </data>
  <data name="nombre_producto" xml:space="preserve">
    <value>НАЗВАНИЕ ЛИНЗЫ</value>
  </data>
  <data name="NoPosibleObtenerForma" xml:space="preserve">
    <value>Невозможно прочитать форму шаблона </value>
  </data>
  <data name="nueva_oferta_actualizada_correctamente" xml:space="preserve">
    <value>Предложение было успешно обновлено</value>
  </data>
  <data name="oferta_actualizada" xml:space="preserve">
    <value>Обновленное предложение</value>
  </data>
  <data name="OjoDerecho" xml:space="preserve">
    <value>Правый глаз </value>
  </data>
  <data name="OjoIzquierdo" xml:space="preserve">
    <value>Левый глаз </value>
  </data>
  <data name="pedido" xml:space="preserve">
    <value>Заказ</value>
  </data>
  <data name="pedido_con_montura" xml:space="preserve">
    <value>Заказ с оправой</value>
  </data>
  <data name="precal" xml:space="preserve">
    <value>Прекалибровка</value>
  </data>
  <data name="precalibrado_digital_abr" xml:space="preserve">
    <value>Прекалибровка</value>
  </data>
  <data name="PreguntaElegirOjo" xml:space="preserve">
    <value>Пожалуйста, выберите правая или левая линза</value>
  </data>
  <data name="pregunta_borrar_pedido" xml:space="preserve">
    <value>Вы уверены, что хотите удалить заказ {0}?</value>
  </data>
  <data name="pregunta_montura_y_lentes_biseladas" xml:space="preserve">
    <value>Оправа и обточенные линзы </value>
  </data>
  <data name="pregunta_montura_y_lentes_montadas" xml:space="preserve">
    <value>Оправа и установленные линзы</value>
  </data>
  <data name="pregunta_montura_y_lentes_sin_biselar" xml:space="preserve">
    <value>Оправа и необточенные линзы (линзы к данному заказу)</value>
  </data>
  <data name="pregunta_solo_lentes_biseladas" xml:space="preserve">
    <value>только обточенные линзы </value>
  </data>
  <data name="pregunta_solo_lentes_sin_biselar" xml:space="preserve">
    <value>только необточенные линзы </value>
  </data>
  <data name="quitar_seleccion" xml:space="preserve">
    <value>Выйти из ВЫБРАТЬ</value>
  </data>
  <data name="sistema_pedidos_por_mayor" xml:space="preserve">
    <value>Инструмент on-line заказов складских линз</value>
  </data>
  <data name="toolTip_abrir_indoscan" xml:space="preserve">
    <value>Открыть INDOScan</value>
  </data>
  <data name="tooltip_boton_importar" xml:space="preserve">
    <value>Загрузить форму шаблона из файла</value>
  </data>
  <data name="tooltip_boton_iniciar" xml:space="preserve">
    <value>Показать все сохраненные данные в INDOBISEL</value>
  </data>
  <data name="tooltip_boton_ultima" xml:space="preserve">
    <value>Показать последний сохраненный в INDOBISEL шаблон </value>
  </data>
  <data name="valores_por_defecto" xml:space="preserve">
    <value>Установки по умолчанию</value>
  </data>
  <data name="ventana_espera_borrar_pedidos" xml:space="preserve">
    <value>УДАЛЕНИЕ ЗАКАЗА {0}</value>
  </data>
  <data name="ventana_espera_consulta_anulable" xml:space="preserve">
    <value>Проверьте, может ли заказ {0} быть удален</value>
  </data>
  <data name="ventana_espera_guardar_datos" xml:space="preserve">
    <value>СОХРАНЕНИЕ ДАННЫХ</value>
  </data>
  <data name="ventana_espera_obteniendo_bases" xml:space="preserve">
    <value>ВОСТАНОВЛЕНИЕ БАЗЫ</value>
  </data>
  <data name="ventana_espera_recuperar_datos" xml:space="preserve">
    <value>ВОССТАНОВЛЕНИЕ ДАННЫХ</value>
  </data>
  <data name="VolverAObtenerDeEscaner" xml:space="preserve">
    <value>Вернуться к поиску сканера</value>
  </data>
  <data name="aviso_borrar_pedido" xml:space="preserve">
    <value>delete order</value>
  </data>
  <data name="aviso_error_buscando_datos" xml:space="preserve">
    <value>Error retrieving data.</value>
  </data>
  <data name="aviso_error_enviando_mensaje_SRI" xml:space="preserve">
    <value>Error sending mail to SRI</value>
  </data>
  <data name="aviso_no_hay_incidencias" xml:space="preserve">
    <value>Incidence not found</value>
  </data>
  <data name="aviso_no_hay_tema" xml:space="preserve">
    <value>Subject not specified</value>
  </data>
  <data name="aviso_tema_no_encontrado" xml:space="preserve">
    <value>Subject not found</value>
  </data>
  <data name="email_contacto" xml:space="preserve">
    <value>Contact Email</value>
  </data>
  <data name="errores_en_formulario" xml:space="preserve">
    <value>Errors found in the form.</value>
  </data>
  <data name="ficheros_adjuntos" xml:space="preserve">
    <value>Attachments</value>
  </data>
  <data name="fin_ficheros_adjuntos" xml:space="preserve">
    <value>End of attachments</value>
  </data>
  <data name="motivos" xml:space="preserve">
    <value>Motivos</value>
  </data>
  <data name="motivo_no_definido" xml:space="preserve">
    <value>Reason not specified.</value>
  </data>
  <data name="no_hay_motivos" xml:space="preserve">
    <value>No hay motivos</value>
  </data>
  <data name="sin_ficheros_adjuntos" xml:space="preserve">
    <value>without attachments</value>
  </data>
  <data name="sin_observaciones" xml:space="preserve">
    <value>no observations</value>
  </data>
  <data name="tema" xml:space="preserve">
    <value>Subject</value>
  </data>
  <data name="mensaje_pedido_duplicado" xml:space="preserve">
    <value>ATTENTION. Possibly Duplicated Order. Entered on Date: {0}.  {1} #: {2}</value>
  </data>
  <data name="pedido_duplicado" xml:space="preserve">
    <value>DUPLICATE ORDER</value>
  </data>
  <data name="receta" xml:space="preserve">
    <value>Prescription Saved</value>
  </data>
  <data name="no_mostrar_aviso" xml:space="preserve">
    <value>Do not show this message again</value>
  </data>
  <data name="error_usuario_o_clave_no_validos" xml:space="preserve">
    <value>User or password not correct</value>
  </data>
  <data name="borrar_forma" xml:space="preserve">
    <value>Delete shape</value>
    <comment>Borrado MiBiselado</comment>
  </data>
  <data name="confirmar_borrar_forma" xml:space="preserve">
    <value>Are you sure you want to delete this shape?</value>
    <comment>Borrado MiBiselado</comment>
  </data>
  <data name="ventana_espera_borrar_forma" xml:space="preserve">
    <value>DELETING SHAPE</value>
    <comment>Borrado MiBiselado</comment>
  </data>
  <data name="pedir_montura" xml:space="preserve">
    <value>Add Frame</value>
    <comment>FormaRemota</comment>
  </data>
  <data name="anillas" xml:space="preserve">
    <value>Ring Settings</value>
    <comment>FFS</comment>
  </data>
  <data name="configuracion_taller" xml:space="preserve">
    <value>Lab Settings</value>
    <comment>FFS</comment>
  </data>
  <data name="macros_talla" xml:space="preserve">
    <value>Cutting Macros</value>
    <comment>FFS</comment>
  </data>
  <data name="marcas_laser" xml:space="preserve">
    <value>LASER Marks</value>
    <comment>FFS</comment>
  </data>
  <data name="peticiones_talla" xml:space="preserve">
    <value>Cutting requests</value>
    <comment>FFS</comment>
  </data>
  <data name="otros_datos" xml:space="preserve">
    <value>OTHER DATA</value>
  </data>
  <data name="datos_opcionales" xml:space="preserve">
    <value>Смотреть дополнительные данные</value>
  </data>
  <data name="setup_laboratorios" xml:space="preserve">
    <value>Lab Settings</value>
  </data>
  <data name="diametro_resultante_abrev" xml:space="preserve">
    <value>Diam. Res.</value>
  </data>
  <data name="espesor_taladro_minimo_abrev" xml:space="preserve">
    <value>Drill Min. Th.</value>
  </data>
  <data name="indoCenter_popup_titulo" xml:space="preserve">
    <value>IndoCenter reading selection</value>
  </data>
  <data name="nombre" xml:space="preserve">
    <value>Имя</value>
  </data>
  <data name="aviso_faltan_datos_en_prisma" xml:space="preserve">
    <value>Не указана ось или база призмы</value>
  </data>
  <data name="aviso_desactivar_isotipo" xml:space="preserve">
    <value>Orders without shape or with a basic shape cannot ask the logo. Deactivate the logo</value>
  </data>
  <data name="aviso_si_quiere_recogida_llamar_ATC" xml:space="preserve">
    <value>Should you need a pick up please call our Customer Service</value>
  </data>
  <data name="aviso_mas_365_dias" xml:space="preserve">
    <value>Интервал дат должен быть меньше 365 дней</value>
  </data>
  <data name="aviso_eliminacion_boton_igual_suplementos" xml:space="preserve">
    <value>In order to avoid mistakes we have removed the button to equalize the supplements in both eyes. Now you must enter the values you want for each eye.</value>
  </data>
  <data name="entendido" xml:space="preserve">
    <value>OK</value>
  </data>
  <data name="aviso_cambio_contraseña_indonet" xml:space="preserve">
    <value>To improve the security and confidentiality of our clients, we recommend to you to modify and to personalize your password access to this platform. You will be able to do it from the eyelash of configuration that will be opened later.</value>
  </data>
  <data name="aviso_debe_rellenar_todos_los_campos" xml:space="preserve">
    <value>You have to fill out all the fields</value>
  </data>
  <data name="aviso_nueva_clave_no_es_indonet" xml:space="preserve">
    <value>The new password cannot be « INDONET »</value>
  </data>
  <data name="notificacion" xml:space="preserve">
    <value>Notification</value>
  </data>
  <data name="aviso_eliminar_buscador_web" xml:space="preserve">
    <value>You are about to delete your data from our web search engine. By accepting it, you will stop appearing in the possible searches made by the users. Do you want to continue?</value>
  </data>
  <data name="informacion_contacto" xml:space="preserve">
    <value>Contact information</value>
  </data>
  <data name="informacion_optica" xml:space="preserve">
    <value>Optical shop information</value>
  </data>
  <data name="mensaje_entrada_buscador" xml:space="preserve">
    <value>Configure your data here to appear in the optical search of www.indo.es</value>
  </data>
  <data name="tooltip_boton_copiar" xml:space="preserve">
    <value>Copy from Monday to Saturday.</value>
  </data>
  <data name="ha_escogido_esta_devolucion" xml:space="preserve">
    <value>You have chosen this return</value>
  </data>
  <data name="configuracion_hojaPedidoIndoscan" xml:space="preserve">
    <value>IndoScan Form</value>
  </data>
  <data name="configuracion_link_hojaPedidosFax" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/Indonet/Enlaces/HojaPedidosFax_RU.pdf</value>
  </data>
  <data name="aviso_texto_no_copiar_pedido" xml:space="preserve">
    <value>IMPORTANT
The product you are requesting may have changed its offer from the date of the original order. Please check all fields and treatments to make sure the lenses you select are correct.</value>
  </data>
  <data name="texto_importante_devoluciones" xml:space="preserve">
    <value>IMPORTANT: To avoid damages to the lenses and facilitate their analysis, they should be received in an appropriate and separate packaging, without elements that could damage them such as staples, cards, etc.</value>
  </data>
  <data name="facturacion" xml:space="preserve">
    <value>Invoices and delivery notes</value>
  </data>
  <data name="no_albaran" xml:space="preserve">
    <value>Delivery Note</value>
  </data>
  <data name="no_factura" xml:space="preserve">
    <value>Invoice number</value>
  </data>
  <data name="url_ayuda_inset" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/ayudaIndonet/inset_RU.html</value>
  </data>
  <data name="ver_albaran" xml:space="preserve">
    <value>Download Delivery Note</value>
  </data>
  <data name="marca_opcion_deseada" xml:space="preserve">
    <value>Check the desired option</value>
  </data>
  <data name="url_condiciones_indomedcare_new" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/ayudaIndonet/condiciones_indomedcare_RU.txt</value>
  </data>
  <data name="url_condiciones_indonet_new" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/ayudaIndonet/condiciones_indonet_RU.txt</value>
  </data>
  <data name="degradado_alto" xml:space="preserve">
    <value>Degradado alto - 5mm</value>
  </data>
  <data name="degradado_bajo" xml:space="preserve">
    <value>Degradado bajo + 5mm</value>
  </data>
  <data name="texto_ex_bono" xml:space="preserve">
    <value>The requested treatment is excluded from bonuses and has an added cost of {0} per lens</value>
  </data>
  <data name="pedido_urgente" xml:space="preserve">
    <value>Urgent order</value>
  </data>
  <data name="solicitar_devolucion_rodenstock" xml:space="preserve">
    <value>Activar Garantía Especial Rodenstock</value>
  </data>
  <data name="aviso_pedir_devolucionRD" xml:space="preserve">
    <value>Solicitar Garantía Especial Rodenstock</value>
  </data>
  <data name="texto_activar_bonificacion_rodenstock_checkbox" xml:space="preserve">
    <value>Activar Garantía Especial Rodenstock</value>
  </data>
  <data name="seguro_actualizar_pedido" xml:space="preserve">
    <value>Этот расчет не требуется для отправки заказа и может занять некоторое время.
Вы уверены, что продолжите?</value>
  </data>
  <data name="rodenstockmanager" xml:space="preserve">
    <value>RodenstockManager</value>
  </data>
  <data name="bienvenido_a_rodenstockmanager" xml:space="preserve">
    <value>Bienvenido a Rodenstock Manager el servicio con el que podrás gestionar tu negocio de una manera más autónoma y con una mayor información a tu alcance cuando lo desees.</value>
  </data>
  <data name="url_fb_rodenstock" xml:space="preserve">
    <value>https://www.facebook.com/RodenstockES/</value>
  </data>
  <data name="url_in_rodenstock" xml:space="preserve">
    <value>https://www.instagram.com/rodenstock_es/</value>
  </data>
  <data name="url_indoloyalty" xml:space="preserve">
    <value>https://indoloyalty.com</value>
  </data>
</root>