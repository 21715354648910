<?xml version="1.0" encoding="utf-8"?>
<root>
  <!-- 
    Microsoft ResX Schema 
    
    Version 2.0
    
    The primary goals of this format is to allow a simple XML format 
    that is mostly human readable. The generation and parsing of the 
    various data types are done through the TypeConverter classes 
    associated with the data types.
    
    Example:
    
    ... ado.net/XML headers & schema ...
    <resheader name="resmimetype">text/microsoft-resx</resheader>
    <resheader name="version">2.0</resheader>
    <resheader name="reader">System.Resources.ResXResourceReader, System.Windows.Forms, ...</resheader>
    <resheader name="writer">System.Resources.ResXResourceWriter, System.Windows.Forms, ...</resheader>
    <data name="Name1"><value>this is my long string</value><comment>this is a comment</comment></data>
    <data name="Color1" type="System.Drawing.Color, System.Drawing">Blue</data>
    <data name="Bitmap1" mimetype="application/x-microsoft.net.object.binary.base64">
        <value>[base64 mime encoded serialized .NET Framework object]</value>
    </data>
    <data name="Icon1" type="System.Drawing.Icon, System.Drawing" mimetype="application/x-microsoft.net.object.bytearray.base64">
        <value>[base64 mime encoded string representing a byte array form of the .NET Framework object]</value>
        <comment>This is a comment</comment>
    </data>
                
    There are any number of "resheader" rows that contain simple 
    name/value pairs.
    
    Each data row contains a name, and value. The row also contains a 
    type or mimetype. Type corresponds to a .NET class that support 
    text/value conversion through the TypeConverter architecture. 
    Classes that don't support this are serialized and stored with the 
    mimetype set.
    
    The mimetype is used for serialized objects, and tells the 
    ResXResourceReader how to depersist the object. This is currently not 
    extensible. For a given mimetype the value must be set accordingly:
    
    Note - application/x-microsoft.net.object.binary.base64 is the format 
    that the ResXResourceWriter will generate, however the reader can 
    read any of the formats listed below.
    
    mimetype: application/x-microsoft.net.object.binary.base64
    value   : The object must be serialized with 
            : System.Runtime.Serialization.Formatters.Binary.BinaryFormatter
            : and then encoded with base64 encoding.
    
    mimetype: application/x-microsoft.net.object.soap.base64
    value   : The object must be serialized with 
            : System.Runtime.Serialization.Formatters.Soap.SoapFormatter
            : and then encoded with base64 encoding.

    mimetype: application/x-microsoft.net.object.bytearray.base64
    value   : The object must be serialized into a byte array 
            : using a System.ComponentModel.TypeConverter
            : and then encoded with base64 encoding.
    -->
  <xsd:schema id="root" xmlns="" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:msdata="urn:schemas-microsoft-com:xml-msdata">
    <xsd:import namespace="http://www.w3.org/XML/1998/namespace" />
    <xsd:element name="root" msdata:IsDataSet="true">
      <xsd:complexType>
        <xsd:choice maxOccurs="unbounded">
          <xsd:element name="metadata">
            <xsd:complexType>
              <xsd:sequence>
                <xsd:element name="value" type="xsd:string" minOccurs="0" />
              </xsd:sequence>
              <xsd:attribute name="name" use="required" type="xsd:string" />
              <xsd:attribute name="type" type="xsd:string" />
              <xsd:attribute name="mimetype" type="xsd:string" />
              <xsd:attribute ref="xml:space" />
            </xsd:complexType>
          </xsd:element>
          <xsd:element name="assembly">
            <xsd:complexType>
              <xsd:attribute name="alias" type="xsd:string" />
              <xsd:attribute name="name" type="xsd:string" />
            </xsd:complexType>
          </xsd:element>
          <xsd:element name="data">
            <xsd:complexType>
              <xsd:sequence>
                <xsd:element name="value" type="xsd:string" minOccurs="0" msdata:Ordinal="1" />
                <xsd:element name="comment" type="xsd:string" minOccurs="0" msdata:Ordinal="2" />
              </xsd:sequence>
              <xsd:attribute name="name" type="xsd:string" use="required" msdata:Ordinal="1" />
              <xsd:attribute name="type" type="xsd:string" msdata:Ordinal="3" />
              <xsd:attribute name="mimetype" type="xsd:string" msdata:Ordinal="4" />
              <xsd:attribute ref="xml:space" />
            </xsd:complexType>
          </xsd:element>
          <xsd:element name="resheader">
            <xsd:complexType>
              <xsd:sequence>
                <xsd:element name="value" type="xsd:string" minOccurs="0" msdata:Ordinal="1" />
              </xsd:sequence>
              <xsd:attribute name="name" type="xsd:string" use="required" />
            </xsd:complexType>
          </xsd:element>
        </xsd:choice>
      </xsd:complexType>
    </xsd:element>
  </xsd:schema>
  <resheader name="resmimetype">
    <value>text/microsoft-resx</value>
  </resheader>
  <resheader name="version">
    <value>2.0</value>
  </resheader>
  <resheader name="reader">
    <value>System.Resources.ResXResourceReader, System.Windows.Forms, Version=2.0.0.0, Culture=neutral, PublicKeyToken=b77a5c561934e089</value>
  </resheader>
  <resheader name="writer">
    <value>System.Resources.ResXResourceWriter, System.Windows.Forms, Version=2.0.0.0, Culture=neutral, PublicKeyToken=b77a5c561934e089</value>
  </resheader>
  <data name="a" xml:space="preserve">
    <value>a</value>
  </data>
  <data name="abertura" xml:space="preserve">
    <value>Opening</value>
  </data>
  <data name="acceso_indonet" xml:space="preserve">
    <value>Access</value>
    <comment>Login screen</comment>
  </data>
  <data name="aceptar" xml:space="preserve">
    <value>Accept</value>
  </data>
  <data name="Aceptar_Cambio_Y_Enviar" xml:space="preserve">
    <value>Accept Change and Send</value>
    <comment>Alternativa de producto</comment>
  </data>
  <data name="acetato" xml:space="preserve">
    <value>Acetate</value>
    <comment>type of frame</comment>
  </data>
  <data name="actualizando_nueva_oferta" xml:space="preserve">
    <value>Updating to the new offer...</value>
  </data>
  <data name="actualizar" xml:space="preserve">
    <value>Calculate</value>
  </data>
  <data name="adicion" xml:space="preserve">
    <value>Addition</value>
    <comment>Home screen, list of last orders</comment>
  </data>
  <data name="adicion_abrv" xml:space="preserve">
    <value>AD</value>
    <comment>Order list header</comment>
  </data>
  <data name="advertencia" xml:space="preserve">
    <value>Warning</value>
  </data>
  <data name="agente_trazador" xml:space="preserve">
    <value>Tracing agent</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="agente_trazador_desconectado" xml:space="preserve">
    <value>INDOBISEL does not seem to be working Please check your status</value>
  </data>
  <data name="altura" xml:space="preserve">
    <value>Height</value>
    <comment>Technical data</comment>
  </data>
  <data name="altura_boxing" xml:space="preserve">
    <value>Boxing height</value>
  </data>
  <data name="altura_de_montaje" xml:space="preserve">
    <value>Assembly height</value>
  </data>
  <data name="altura_montaje" xml:space="preserve">
    <value>Fitting Cross Height</value>
    <comment>Additional data</comment>
  </data>
  <data name="altura_montaje_total" xml:space="preserve">
    <value>Final Fitting Cross Height</value>
  </data>
  <data name="anadir_encargo_pregunta_enviar_revision" xml:space="preserve">
    <value>Send the order to check.</value>
    <comment>segunda gafa</comment>
  </data>
  <data name="AnalizandoImagen" xml:space="preserve">
    <value>Analyzing image to obtain shape</value>
    <comment>IndoScan</comment>
  </data>
  <data name="angulo" xml:space="preserve">
    <value>Angle</value>
    <comment>Technical data</comment>
  </data>
  <data name="angulo_de_envolvencia" xml:space="preserve">
    <value>Surrounding angle</value>
    <comment>Additional data</comment>
  </data>
  <data name="asunto" xml:space="preserve">
    <value>Subject</value>
  </data>
  <data name="atras" xml:space="preserve">
    <value>Back</value>
  </data>
  <data name="automatico13_23" xml:space="preserve">
    <value>Automatic (1/3-2/3)</value>
    <comment>Bevel direction</comment>
  </data>
  <data name="avisos" xml:space="preserve">
    <value>Warnings</value>
    <comment>Order screen</comment>
  </data>
  <data name="aviso_actualizacion" xml:space="preserve">
    <value>Application will be updated to: </value>
    <comment>clickonce</comment>
  </data>
  <data name="aviso_alternativa_producto" xml:space="preserve">
    <value>There is shortage for this material. As alternative, we offer following product at same price. If you need only one eye please contact to Customer Service:</value>
    <comment>Alternativa producto</comment>
  </data>
  <data name="aviso_apagado" xml:space="preserve">
    <value>Application has been updated. Application will be closed. You can start INDONET again by clicking the icon on the desktop.</value>
    <comment>clickonce</comment>
  </data>
  <data name="aviso_cancelar_pedido" xml:space="preserve">
    <value>Are you sure you want to cancel the order? All of the data will be lost</value>
    <comment>Warnings</comment>
  </data>
  <data name="aviso_caracter_no_valido" xml:space="preserve">
    <value>This character is invalid. Please use the Western alphabet.</value>
  </data>
  <data name="aviso_cargando_oferta" xml:space="preserve">
    <value>Loading Offer data</value>
  </data>
  <data name="aviso_color_nd_diferentes" xml:space="preserve">
    <value>Coloration is not allowed on lenses with different indexes</value>
  </data>
  <data name="aviso_color_reposicion" xml:space="preserve">
    <value>We have received your order. 
To confirm that the colour of the both lenses are the same we will contact you as soon as posible.</value>
  </data>
  <data name="aviso_desactivar_solo_stock" xml:space="preserve">
    <value>If you want this option, deactivate the Stock Only option</value>
  </data>
  <data name="aviso_descarga_aplicacion" xml:space="preserve">
    <value>Downloading new version</value>
    <comment>ClickOnce</comment>
  </data>
  <data name="aviso_falta_diametro" xml:space="preserve">
    <value>A diameter must be chosen</value>
  </data>
  <data name="aviso_falta_referencia" xml:space="preserve">
    <value>A JOB number is required</value>
    <comment>FFS</comment>
  </data>
  <data name="aviso_falta_tipo_montura" xml:space="preserve">
    <value>The type of frame and the type of Bevel has to be chosen. Click on Next button, in Thickness selection.</value>
  </data>
  <data name="aviso_instalando_version" xml:space="preserve">
    <value>Updating application</value>
    <comment>ClickOnce</comment>
  </data>
  <data name="aviso_introducir_montura_1" xml:space="preserve">
    <value>Attention: to succesfully complete the order</value>
  </data>
  <data name="aviso_introducir_montura_2" xml:space="preserve">
    <value>you must enter the frame shape.</value>
  </data>
  <data name="aviso_lecturas_no_cargadas" xml:space="preserve">
    <value>Readings Shapes has not been imported</value>
  </data>
  <data name="aviso_lectura_cargada" xml:space="preserve">
    <value>Shape {0} succesfully loaded.</value>
  </data>
  <data name="aviso_lectura_importada" xml:space="preserve">
    <value>Shape {0} succesfully imported.</value>
  </data>
  <data name="aviso_mas_30_dias" xml:space="preserve">
    <value>It is not allowed more than 60 days between dates</value>
  </data>
  <data name="aviso_precalibrado_forma_cuadrada" xml:space="preserve">
    <value>With precalibration we recommend to use squared basic shape in order to cover actual frame shape.</value>
  </data>
  <data name="aviso_reiniciar_oferta" xml:space="preserve">
    <value>Are you sure you want to reload the offer?</value>
  </data>
  <data name="base" xml:space="preserve">
    <value>Base</value>
    <comment>Technical data</comment>
  </data>
  <data name="base_de_datos_de_marcas" xml:space="preserve">
    <value>Brand database</value>
    <comment>Shape ComboBox </comment>
  </data>
  <data name="base_de_datos_de_mis_biselados" xml:space="preserve">
    <value>My Edgings</value>
    <comment>Shape ComboBox </comment>
  </data>
  <data name="base_especial" xml:space="preserve">
    <value>Special Front Curve base</value>
    <comment>Supplements</comment>
  </data>
  <data name="base_interior" xml:space="preserve">
    <value>Internal base</value>
  </data>
  <data name="basicas" xml:space="preserve">
    <value>Basic</value>
    <comment>Shape ComboBox </comment>
  </data>
  <data name="bisel" xml:space="preserve">
    <value>Normal bevel</value>
    <comment>bevel type</comment>
  </data>
  <data name="biselador_remoto" xml:space="preserve">
    <value>From Tracer</value>
    <comment>Shape ComboBox </comment>
  </data>
  <data name="biselador_remoto_guardar" xml:space="preserve">
    <value>Save</value>
  </data>
  <data name="biselador_remoto_importar" xml:space="preserve">
    <value>Import</value>
  </data>
  <data name="biselador_remoto_iniciar" xml:space="preserve">
    <value>Start</value>
  </data>
  <data name="biselador_remoto_popup_titulo" xml:space="preserve">
    <value>Tracer agent reading selection</value>
  </data>
  <data name="biselador_remoto_ultima" xml:space="preserve">
    <value>Last</value>
  </data>
  <data name="biselar_lente" xml:space="preserve">
    <value>Lens edging</value>
  </data>
  <data name="biselar_sin_pulir" xml:space="preserve">
    <value>Bevel without polishing</value>
  </data>
  <data name="borde_especial_abr" xml:space="preserve">
    <value>B. Esp</value>
  </data>
  <data name="borrar_recetas" xml:space="preserve">
    <value>Delete</value>
  </data>
  <data name="buscar" xml:space="preserve">
    <value>Search</value>
  </data>
  <data name="calibracion_correctamente" xml:space="preserve">
    <value>The calibration has been done correctly</value>
  </data>
  <data name="calibracion_monitor" xml:space="preserve">
    <value>Monitor calibration</value>
  </data>
  <data name="calibre" xml:space="preserve">
    <value>Boxing Size</value>
    <comment>Additional data</comment>
  </data>
  <data name="calibre_completo" xml:space="preserve">
    <value>Complete lens sizing</value>
  </data>
  <data name="calibre_marcas" xml:space="preserve">
    <value>Size</value>
  </data>
  <data name="cancelar" xml:space="preserve">
    <value>Cancel</value>
  </data>
  <data name="cancelar_pedido" xml:space="preserve">
    <value>Cancel order</value>
    <comment>Order action button panel</comment>
  </data>
  <data name="caracter_hiperlink" xml:space="preserve">
    <value>»</value>
  </data>
  <data name="centrado12_12" xml:space="preserve">
    <value>Centring (1/2-1/2)</value>
    <comment>Bevel direction</comment>
  </data>
  <data name="chile" xml:space="preserve">
    <value>Chile</value>
  </data>
  <data name="cilindro" xml:space="preserve">
    <value>Cylinder</value>
    <comment>Home screen, list of last orders</comment>
  </data>
  <data name="cilindro_abrv" xml:space="preserve">
    <value>CYL</value>
    <comment>Order list header</comment>
  </data>
  <data name="clave_acceso" xml:space="preserve">
    <value>Access key</value>
    <comment>Login screen</comment>
  </data>
  <data name="cliente" xml:space="preserve">
    <value>Client</value>
  </data>
  <data name="codigo_cliente" xml:space="preserve">
    <value>Client code</value>
    <comment>Login screen</comment>
  </data>
  <data name="codigo_de_barras" xml:space="preserve">
    <value>Barcode</value>
  </data>
  <data name="codigo_de_retorno" xml:space="preserve">
    <value>Return code: </value>
  </data>
  <data name="color" xml:space="preserve">
    <value>Colour</value>
    <comment>Order screen</comment>
  </data>
  <data name="coloracion" xml:space="preserve">
    <value>Tinting</value>
    <comment>Order screen</comment>
  </data>
  <data name="comparar" xml:space="preserve">
    <value>Compare</value>
  </data>
  <data name="comparar_lentes" xml:space="preserve">
    <value>Compare lenses</value>
  </data>
  <data name="comprobando_si_BBDD" xml:space="preserve">
    <value>Checking if DB exists ...</value>
  </data>
  <data name="comprobando_si_nueva_oferta" xml:space="preserve">
    <value>Checking for a new offer...</value>
  </data>
  <data name="condiciones" xml:space="preserve">
    <value>Conditions</value>
  </data>
  <data name="condiciones_1" xml:space="preserve">
    <value>INDO OPTICAL, S.L.U. with Tax Identity B-66232976, of Avda. Alcalde Barnils, 72 - 08174 Sant Cugat del Vallés - Barcelona and recorded in Barcelona Company Register in Volume 44215, Folio 212, Page B-449860, responsible for the data treatment, receiver of the information and holder of the “clients” file, hereby notifies the subscriber that the data provided will be included in the “clients” file. The data are collected to enable commercial exchange between INDO and your Optician’s by Internet and thus give the INDONET service.</value>
  </data>
  <data name="condiciones_2" xml:space="preserve">
    <value>Acceptance of the conditions of use is compulsory and any failure to reply to the data requested will prevent the service through INDONET.</value>
  </data>
  <data name="condiciones_3" xml:space="preserve">
    <value>The subscriber is informed that they may address the person responsible for treatment concerning all rights afforded them by law, and especially the rights of access, rectification, cancellation and opposition. To do this they must fill in the contact form in www.indobox-indo.com expressing their wish.</value>
  </data>
  <data name="condiciones_4" xml:space="preserve">
    <value>The subscriber, as the holder of the data provided, expressly agrees to their treatment as described.</value>
  </data>
  <data name="conexion_ssl" xml:space="preserve">
    <value>SSL CONNECTION</value>
  </data>
  <data name="conexion_ssl_segura" xml:space="preserve">
    <value>SECURE SSL CONECTION</value>
  </data>
  <data name="configuracion" xml:space="preserve">
    <value>Set up</value>
    <comment>Toolbar</comment>
  </data>
  <data name="configuracion_activado" xml:space="preserve">
    <value>Activated</value>
  </data>
  <data name="configuracion_altura_montaje" xml:space="preserve">
    <value>Fitting Cross Measure from..."</value>
  </data>
  <data name="configuracion_aro" xml:space="preserve">
    <value>Ring</value>
  </data>
  <data name="configuracion_boxing" xml:space="preserve">
    <value>Boxing</value>
  </data>
  <data name="configuracion_catalogo_lentes" xml:space="preserve">
    <value>Indo lens catalogue</value>
  </data>
  <data name="configuracion_clave_actual" xml:space="preserve">
    <value>Current Password</value>
  </data>
  <data name="configuracion_confirmar_clave" xml:space="preserve">
    <value>Confirm key</value>
  </data>
  <data name="configuracion_desactivado" xml:space="preserve">
    <value>Deactivated</value>
  </data>
  <data name="configuracion_descargas_automaticas" xml:space="preserve">
    <value>Automatic downloads</value>
  </data>
  <data name="configuracion_dias" xml:space="preserve">
    <value>days</value>
  </data>
  <data name="configuracion_enlaces_relacionados" xml:space="preserve">
    <value>Related links</value>
  </data>
  <data name="configuracion_espesores" xml:space="preserve">
    <value>Thickness setup</value>
  </data>
  <data name="configuracion_espesor_borde" xml:space="preserve">
    <value>Edge thickness</value>
  </data>
  <data name="configuracion_fecha_oferta" xml:space="preserve">
    <value>Offer date</value>
    <comment>Lectura de la fecha de la oferta en la BBDD</comment>
  </data>
  <data name="configuracion_guardada_correctamente" xml:space="preserve">
    <value>Setup saved correctly</value>
  </data>
  <data name="configuracion_hojaPedido" xml:space="preserve">
    <value>Order Form</value>
  </data>
  <data name="configuracion_jerga" xml:space="preserve">
    <value>Jargon</value>
  </data>
  <data name="configuracion_lentesMaxima" xml:space="preserve">
    <value>www.lentesmaxima.com</value>
  </data>
  <data name="configuracion_link_hojaRombos" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/Indonet/Enlaces/HojaPedidos_EN.pdf</value>
  </data>
  <data name="configuracion_link_lentesMaxima" xml:space="preserve">
    <value>http://www.lentesmaxima.com</value>
  </data>
  <data name="configuracion_link_manualIndoNet" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/Indonet/Enlaces/ManualIndonet_EN.pdf</value>
  </data>
  <data name="configuracion_link_manualIndoScanIntegrado" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/Indonet/Enlaces/ManualIndoScanIntegrado_EN.pdf</value>
  </data>
  <data name="configuracion_listado_pedidos" xml:space="preserve">
    <value>Order status report</value>
  </data>
  <data name="configuracion_manualIndonet" xml:space="preserve">
    <value>Indonet User's Manual</value>
  </data>
  <data name="configuracion_manualIndoScanIntegrado" xml:space="preserve">
    <value>IndoScan User's Manual</value>
  </data>
  <data name="configuracion_modificar_clave" xml:space="preserve">
    <value>Change Password</value>
  </data>
  <data name="configuracion_mostrar_pedidos" xml:space="preserve">
    <value>Show entered orders in the last</value>
  </data>
  <data name="configuracion_mostrar_precios" xml:space="preserve">
    <value>Show prices</value>
  </data>
  <data name="configuracion_nombre_comercial" xml:space="preserve">
    <value>Commercial name</value>
  </data>
  <data name="configuracion_nombre_lentes" xml:space="preserve">
    <value>Name of the lenses</value>
  </data>
  <data name="configuracion_nombre_lentes_help" xml:space="preserve">
    <value>The changes will be shown on the following authentication</value>
  </data>
  <data name="configuracion_nueva_clave" xml:space="preserve">
    <value>New Password</value>
  </data>
  <data name="configuracion_precalibrado_cotas" xml:space="preserve">
    <value>Measurement pre-calibration</value>
  </data>
  <data name="configuracion_precios_no" xml:space="preserve">
    <value>No</value>
  </data>
  <data name="configuracion_precios_pvo" xml:space="preserve">
    <value>ORP</value>
  </data>
  <data name="configuracion_precios_pvp" xml:space="preserve">
    <value>PRP</value>
  </data>
  <data name="configuracion_precios_si" xml:space="preserve">
    <value>Yes</value>
  </data>
  <data name="configuracion_pred_indo" xml:space="preserve">
    <value>Indo Pred.</value>
  </data>
  <data name="configuracion_propio" xml:space="preserve">
    <value>Own</value>
  </data>
  <data name="configuracion_punto_taladro" xml:space="preserve">
    <value>Drill hole point</value>
  </data>
  <data name="configuracion_ranura_metal" xml:space="preserve">
    <value>Metal groove</value>
  </data>
  <data name="configuracion_ranura_nylon" xml:space="preserve">
    <value>Nylon groove</value>
  </data>
  <data name="configuracion_restablecer_valores_predeterminados" xml:space="preserve">
    <value>Restore preset values</value>
  </data>
  <data name="configuracion_tooltip_espesores" xml:space="preserve">
    <value>The thickness is more than recommended. The lenses will not be edged</value>
  </data>
  <data name="configuracion_tooltip_numstepper" xml:space="preserve">
    <value>The 'Order situation'  list is on the 'Home' screen. The permitted range is from 1 to 7 days.</value>
  </data>
  <data name="configuracion_URL_catalogo" xml:space="preserve">
    <value>http://www.indo.es/lentes/catalogue2011</value>
  </data>
  <data name="confirmar_borrar_receta" xml:space="preserve">
    <value>Confirm the deletion of the saved, selected prescription?</value>
  </data>
  <data name="confirmar_borrar_recetas" xml:space="preserve">
    <value>Confirm the deletion of the {0} saved, selected prescriptions?</value>
  </data>
  <data name="confirmar_envio_pedidos_1" xml:space="preserve">
    <value>Confirm the sending of the following amount of orders? #</value>
  </data>
  <data name="confirmar_envio_pedidos_2" xml:space="preserve">
    <value>All orders with errors will be left as "Under revision by INDO"</value>
  </data>
  <data name="confirmar_envio_receta" xml:space="preserve">
    <value>Confirm the sending of the saved, selected prescription?</value>
  </data>
  <data name="confirmar_envio_recetas" xml:space="preserve">
    <value>Confirm the sending of the {0} saved, selected prescriptions?</value>
  </data>
  <data name="confirmar_envio_recetas_2" xml:space="preserve">
    <value>All orders with errors will be left as "Under revision by INDO"</value>
  </data>
  <data name="confirma_biselado_espesor" xml:space="preserve">
    <value>Confirmation of edging and thickness</value>
  </data>
  <data name="confirma_destino_mercancia" xml:space="preserve">
    <value>Confirm the receiver of the goods</value>
  </data>
  <data name="contacta_con_indo" xml:space="preserve">
    <value>Contact</value>
    <comment>Login screen</comment>
  </data>
  <data name="continuar" xml:space="preserve">
    <value>Continue</value>
  </data>
  <data name="correo_solicitud_acceso" xml:space="preserve">
    <value>Access request mail</value>
  </data>
  <data name="cota_inferior" xml:space="preserve">
    <value>Lower measurement</value>
  </data>
  <data name="cota_superior" xml:space="preserve">
    <value>Upper measurement</value>
  </data>
  <data name="datosoptica_aviso_logotipo" xml:space="preserve">
    <value>No file has been specified for the logo.{0}The default logo will be adopted </value>
  </data>
  <data name="datosoptica_codigo_postal" xml:space="preserve">
    <value>Postcode:</value>
  </data>
  <data name="datosoptica_comunicaciones" xml:space="preserve">
    <value>I want to receive periodical communications from Indo</value>
  </data>
  <data name="datosoptica_corregir_datos" xml:space="preserve">
    <value>If your information is not correct, enter the contact form in the INDONET top menu</value>
  </data>
  <data name="datosoptica_direccion" xml:space="preserve">
    <value>Address :</value>
  </data>
  <data name="datosoptica_email" xml:space="preserve">
    <value>E-mail :</value>
  </data>
  <data name="datosoptica_enlace_condiciones" xml:space="preserve">
    <value>Read privacy and data protection conditions</value>
  </data>
  <data name="datosoptica_fax" xml:space="preserve">
    <value>Fax:</value>
  </data>
  <data name="datosoptica_guardados_correctamente" xml:space="preserve">
    <value>Optical data saved correctly</value>
  </data>
  <data name="datosoptica_logotipo_help" xml:space="preserve">
    <value>The new logo will be shown on the next authentication</value>
  </data>
  <data name="datosoptica_logotipo_optica" xml:space="preserve">
    <value>Optical logo</value>
  </data>
  <data name="datosoptica_nif" xml:space="preserve">
    <value>Tax identity number:</value>
  </data>
  <data name="datosoptica_nombre" xml:space="preserve">
    <value>Name:</value>
  </data>
  <data name="datosoptica_poblacion" xml:space="preserve">
    <value>Town/city:</value>
  </data>
  <data name="datosoptica_telefono" xml:space="preserve">
    <value>Telephone:</value>
  </data>
  <data name="datos_adicionales" xml:space="preserve">
    <value>Mandatory data</value>
    <comment>Basic data</comment>
  </data>
  <data name="datos_basicos" xml:space="preserve">
    <value>Basic data</value>
    <comment>Order screen</comment>
  </data>
  <data name="datos_optica" xml:space="preserve">
    <value>Account Configuration</value>
    <comment>Main navigation bar</comment>
  </data>
  <data name="datos_tecnicos" xml:space="preserve">
    <value>Technical data</value>
  </data>
  <data name="descargando_oferta" xml:space="preserve">
    <value>Downloading offer</value>
  </data>
  <data name="descarga_oferta_fallo_1" xml:space="preserve">
    <value>Unable to update the offer at this time.</value>
  </data>
  <data name="descarga_oferta_fallo_2" xml:space="preserve">
    <value>Please try again later from the RELOAD OFFER button on the tab OPTICAL DATA</value>
  </data>
  <data name="descentramiento_nasal" xml:space="preserve">
    <value>off-centering</value>
    <comment>Supplements</comment>
  </data>
  <data name="descripcion_calibracion" xml:space="preserve">
    <value>Enter the actual diameter of the previous circumference in millimetres</value>
  </data>
  <data name="desde_scanner" xml:space="preserve">
    <value>From Scanner</value>
    <comment>Shape ComboBox</comment>
  </data>
  <data name="destinatario" xml:space="preserve">
    <value>Receiver</value>
  </data>
  <data name="detalle_estado_pedido" xml:space="preserve">
    <value>Order state details </value>
  </data>
  <data name="diametro" xml:space="preserve">
    <value>Diameter</value>
    <comment>Eye basic data view</comment>
  </data>
  <data name="dioptrias" xml:space="preserve">
    <value>Dioptres</value>
    <comment>Supplements</comment>
  </data>
  <data name="Discrepancia_muestra_leida" xml:space="preserve">
    <value>Error: difference between real and expected number of points</value>
  </data>
  <data name="distancia" xml:space="preserve">
    <value>Distance</value>
    <comment>Additional data</comment>
  </data>
  <data name="distancia_trabajo" xml:space="preserve">
    <value>Working distance</value>
    <comment>Additional data</comment>
  </data>
  <data name="dnp" xml:space="preserve">
    <value>NPD</value>
    <comment>Additional data</comment>
  </data>
  <data name="dnp_de_cerca" xml:space="preserve">
    <value>NPD close up</value>
  </data>
  <data name="duplicar_receta" xml:space="preserve">
    <value>Duplicate prescription</value>
  </data>
  <data name="d_esp" xml:space="preserve">
    <value>Sp. D.</value>
  </data>
  <data name="d_especial" xml:space="preserve">
    <value>Special D.</value>
    <comment>Eye basic data view</comment>
  </data>
  <data name="eje" xml:space="preserve">
    <value>Axis</value>
    <comment>Home screen, list of last orders</comment>
  </data>
  <data name="eje_abrv" xml:space="preserve">
    <value>AXIS</value>
    <comment>Order list header</comment>
  </data>
  <data name="el_valor_maximo_es" xml:space="preserve">
    <value>The maximum value is</value>
    <comment>Confirmations</comment>
  </data>
  <data name="email" xml:space="preserve">
    <value>Email</value>
  </data>
  <data name="entrar" xml:space="preserve">
    <value>ENTER</value>
    <comment>Login screen</comment>
  </data>
  <data name="enviar" xml:space="preserve">
    <value>Send</value>
    <comment>Order action button panel</comment>
  </data>
  <data name="enviar_pedido" xml:space="preserve">
    <value>Send order</value>
  </data>
  <data name="enviar_sugerencias" xml:space="preserve">
    <value>Send suggestions</value>
  </data>
  <data name="enviar_y_revision" xml:space="preserve">
    <value>Send and check</value>
    <comment>Order action button panel</comment>
  </data>
  <data name="envio_recetas" xml:space="preserve">
    <value>Send prescriptions</value>
  </data>
  <data name="envio_recetas_falta_destinatario" xml:space="preserve">
    <value>A receiver has to be chosen</value>
  </data>
  <data name="envio_recetas_ningun_destinatario" xml:space="preserve">
    <value>No receiver was found</value>
  </data>
  <data name="error_abriendo_aplicacion" xml:space="preserve">
    <value>Error opening the requested application</value>
  </data>
  <data name="error_abriendo_base_de_datos" xml:space="preserve">
    <value>An error occurred when opening the database.</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_actualizacion_pedido" xml:space="preserve">
    <value>Error in updating the order</value>
  </data>
  <data name="error_actualizando_fecha_oferta" xml:space="preserve">
    <value>An error occurred while updating the offer date in the database</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_actualizando_oferta" xml:space="preserve">
    <value>An error occurred while updating the offer</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_actualizando_pedido" xml:space="preserve">
    <value>***&gt;It was not possible to update the order.</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_agente_trazador" xml:space="preserve">
    <value>Error with the Tracer Agent</value>
  </data>
  <data name="error_autenticar_usuario" xml:space="preserve">
    <value>User authentication not possible</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_base_de_datos" xml:space="preserve">
    <value>An exception has occurred with the local database</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_base_de_datos_no_abierta" xml:space="preserve">
    <value>The database must be open</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_borrando_recetas" xml:space="preserve">
    <value>An error occurred in deleting the prescriptions</value>
  </data>
  <data name="error_busqueda_inicial_pedidos" xml:space="preserve">
    <value>An error occurred during the initial search for orders</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_busqueda_pedidos" xml:space="preserve">
    <value>Error in the search for orders</value>
  </data>
  <data name="error_busqueda_recetas" xml:space="preserve">
    <value>Error in searching for prescriptions</value>
  </data>
  <data name="error_calibracion" xml:space="preserve">
    <value>Calibration not possible</value>
  </data>
  <data name="error_cambiando_password" xml:space="preserve">
    <value>It was not possible to change the password</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_caracteres_diferentes_clave_acceso" xml:space="preserve">
    <value>Different characters in the access key</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_caracteres_no_permitidos_clave_acceso" xml:space="preserve">
    <value>Characters not allowed in the access key</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_cargando_destinatarios" xml:space="preserve">
    <value>Error loading receivers</value>
  </data>
  <data name="error_claves_no_coinciden" xml:space="preserve">
    <value>The access keys do not coincide</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_clave_acceso_no_permitida" xml:space="preserve">
    <value>Access key not allowed</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_clave_incorrecta" xml:space="preserve">
    <value>The indicated key is incorrect</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_conexion_agente_trazador" xml:space="preserve">
    <value>There is no connection with the tracer agent</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_conexion_agente_trazador_elegido" xml:space="preserve">
    <value>Connection error with the chosen tracer agent</value>
  </data>
  <data name="error_conexion_agente_trazador_insertado" xml:space="preserve">
    <value>Connection error with the inserted tracer agent</value>
  </data>
  <data name="error_conexion_servicio" xml:space="preserve">
    <value>There is no connection with the server</value>
  </data>
  <data name="error_desconocido" xml:space="preserve">
    <value>Unknown error</value>
  </data>
  <data name="error_email_direccion_invalida" xml:space="preserve">
    <value>The e-mail address is not correct</value>
    <comment>Errors</comment>
  </data>
  <data name="error_email_falta_asunto" xml:space="preserve">
    <value>The subject is compulsory</value>
    <comment>Errors</comment>
  </data>
  <data name="error_email_falta_cuerpo" xml:space="preserve">
    <value>The message is compulsory</value>
    <comment>Errors</comment>
  </data>
  <data name="error_email_falta_direccion" xml:space="preserve">
    <value>The e-mail address is compulsory</value>
    <comment>Errors</comment>
  </data>
  <data name="error_enviando_correo" xml:space="preserve">
    <value>The mail could not be sent</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_enviando_lista_recetas" xml:space="preserve">
    <value>Error sending prescription list</value>
  </data>
  <data name="error_enviando_mail" xml:space="preserve">
    <value>An error occurred while sending e-mail</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_excepcion_autenticando_usuario" xml:space="preserve">
    <value>An exception occurred while authenticating the user</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_excepcion_copando_clase_pedidos" xml:space="preserve">
    <value>An exception occurred while copying the order class</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_excepcion_enviando_correo" xml:space="preserve">
    <value>An exception occurred while sending mail</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_excepcion_enviando_pedido" xml:space="preserve">
    <value>An exception occurred while sending the order</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_excepcion_enviando_pedido_para_comparacion" xml:space="preserve">
    <value>An exception occurred while sending the order for comparison</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_excepcion_obtenido_el_area_venta" xml:space="preserve">
    <value>An exception occurred while obtaining the sales area</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_excepcion_obteniendo_pedidos_busqueda" xml:space="preserve">
    <value>An exception occurred while obtaining the order list (search)</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_excepcion_obteniendo_pedidos_inicial" xml:space="preserve">
    <value>An exception occurred while obtaining the order list (initial)</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_excepcion_producida" xml:space="preserve">
    <value>An exception has occurred</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_excepcion_recuperando_oferta" xml:space="preserve">
    <value>An exception occurred while recovering the offer</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_exceso_numero_intentos_permitidos" xml:space="preserve">
    <value>The number of attempts allowed has been exceeded</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_grabacion_pedido" xml:space="preserve">
    <value>An exception occurred while saving your order. Please try it again.</value>
  </data>
  <data name="error_grabando_pedido" xml:space="preserve">
    <value>***&gt;The order could not be saved</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_guardando_datos_configuracion" xml:space="preserve">
    <value>Error saving setup data</value>
  </data>
  <data name="error_guardando_datos_optica" xml:space="preserve">
    <value>Error saving optical data</value>
  </data>
  <data name="error_guardando_forma" xml:space="preserve">
    <value>An error occurred while saving the shape</value>
  </data>
  <data name="error_guardando_oferta" xml:space="preserve">
    <value>An error occurred in saving the offer</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_iniciando_aplicacion" xml:space="preserve">
    <value>An error occurred while starting the application</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_introducir_nueva_clave" xml:space="preserve">
    <value>Enter new access key</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_lecturas_agentes" xml:space="preserve">
    <value>An error occurred while accessing the {0} agent{1} readings</value>
  </data>
  <data name="error_leyendo_fichero_base_de_datos" xml:space="preserve">
    <value>An error occurred while reading the file from the database</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_login" xml:space="preserve">
    <value>An error occurred during the login</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_no_se_han_obtenido_datos_configuracion_cliente" xml:space="preserve">
    <value>The client configuration data were not obtained</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_no_se_ha_obtenido_el_area_venta" xml:space="preserve">
    <value>It was not possible to obtain the sales area</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_no_se_ha_podido_recuperar_lista_pedidos" xml:space="preserve">
    <value>***&gt;It was not possible to recover the list with the orders</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_no_se_ha_podido_recuperar_oferta" xml:space="preserve">
    <value>***&gt;The offer could not be recovered.</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_obteniendo_los_datos_configuracion_del_cliente" xml:space="preserve">
    <value>An exception occurred while obtaining the setup data</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_proceso_login" xml:space="preserve">
    <value>An error occurred in the login process</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_recuperando_monturas" xml:space="preserve">
    <value>Error recovering frames</value>
  </data>
  <data name="error_recuperando_pedido" xml:space="preserve">
    <value>***&gt;The order could not be recovered</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_recuperando_receta" xml:space="preserve">
    <value>***&gt;The prescription could not be recovered</value>
  </data>
  <data name="error_recuperando_recetas" xml:space="preserve">
    <value>Error recovering prescriptions</value>
  </data>
  <data name="error_reintentos_excedidos" xml:space="preserve">
    <value>Retries exceeded</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_servicio_cambio_de_password" xml:space="preserve">
    <value>The 'Change Password' service has given an error</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_servicio_envio_correo" xml:space="preserve">
    <value>The 'Send Mail' service has given an error</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_servicio_login" xml:space="preserve">
    <value>The 'Login' service has given an error</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_signo_interrogacion_no_permitido_clave_acceso" xml:space="preserve">
    <value>Question mark not allowed in the access key</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_tipo_de_base_de_datos_desconocida" xml:space="preserve">
    <value>Unknown database type</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="error_usuario_bloqueado" xml:space="preserve">
    <value>The user is blocked</value>
  </data>
  <data name="error_usuario_no_existe" xml:space="preserve">
    <value>The user does not exist</value>
  </data>
  <data name="error_usuario_ya_existe" xml:space="preserve">
    <value>The user already exists</value>
  </data>
  <data name="esfera" xml:space="preserve">
    <value>Sphere</value>
    <comment>Home screen, list of last orders</comment>
  </data>
  <data name="esfera_abrv" xml:space="preserve">
    <value>SPH</value>
    <comment>Order list header</comment>
  </data>
  <data name="espana" xml:space="preserve">
    <value>Spain</value>
  </data>
  <data name="Esperando_respuesta_del_usuario_para_actualizar_la_oferta" xml:space="preserve">
    <value>Waiting for user response to update the offer ...</value>
  </data>
  <data name="espesor" xml:space="preserve">
    <value>Thickness</value>
    <comment>Technical data</comment>
  </data>
  <data name="espesores" xml:space="preserve">
    <value>Thicknesses</value>
  </data>
  <data name="espesor_borde" xml:space="preserve">
    <value>Edge thickness</value>
  </data>
  <data name="espesor_borde_esp" xml:space="preserve">
    <value>Sp. edge thickness</value>
  </data>
  <data name="espesor_borde_especial" xml:space="preserve">
    <value>Special edge thickness</value>
  </data>
  <data name="espesor_centro" xml:space="preserve">
    <value>Centre thickness </value>
    <comment>Technical data</comment>
  </data>
  <data name="espesor_centro_abr" xml:space="preserve">
    <value>C. Thickness</value>
  </data>
  <data name="espesor_max" xml:space="preserve">
    <value>Max. thickness</value>
  </data>
  <data name="espesor_maximo" xml:space="preserve">
    <value>Maximum thickness</value>
    <comment>Technical data</comment>
  </data>
  <data name="espesor_min" xml:space="preserve">
    <value>Min. thickness</value>
  </data>
  <data name="espesor_minimo" xml:space="preserve">
    <value>Minimum thickness</value>
    <comment>Technical data</comment>
  </data>
  <data name="espesor_taladro_nasal" xml:space="preserve">
    <value>Nose drill hole thickness</value>
    <comment>Technical data</comment>
  </data>
  <data name="espesor_taladro_temporal" xml:space="preserve">
    <value>Temporal drill hole thickness</value>
    <comment>Technical data</comment>
  </data>
  <data name="estado" xml:space="preserve">
    <value>State</value>
    <comment>Home screen, list of last orders</comment>
  </data>
  <data name="estadoOD" xml:space="preserve">
    <value>RE status</value>
  </data>
  <data name="estadoOI" xml:space="preserve">
    <value>IE status</value>
  </data>
  <data name="estado_pedido_grabados" xml:space="preserve">
    <value>Engravings</value>
  </data>
  <data name="estado_pedido_pendientes" xml:space="preserve">
    <value>Pending repair</value>
  </data>
  <data name="estado_pedido_todos" xml:space="preserve">
    <value>All</value>
  </data>
  <data name="es_anterior_a" xml:space="preserve">
    <value>is previous to</value>
  </data>
  <data name="es_necesario_cerrar_la_aplicacion" xml:space="preserve">
    <value>The application has been installed correctly.{0}Pressed Accept, wait a few moments for it to close and double-click on the Indonet icon on the desktop</value>
  </data>
  <data name="expedido" xml:space="preserve">
    <value>Sent</value>
  </data>
  <data name="explorar" xml:space="preserve">
    <value>Explore</value>
  </data>
  <data name="fecha" xml:space="preserve">
    <value>Date</value>
    <comment>Home screen, list of last orders</comment>
  </data>
  <data name="fecha_hi_menor_low" xml:space="preserve">
    <value>The "until" data cannot be earlier than "from"</value>
  </data>
  <data name="fecha_hi_nula" xml:space="preserve">
    <value>The "until" date can not be nil</value>
  </data>
  <data name="fecha_hora_grabacion" xml:space="preserve">
    <value>Date and time of saving</value>
  </data>
  <data name="fecha_low_nula" xml:space="preserve">
    <value>The "from" data can not be nil</value>
  </data>
  <data name="fecha_prevista_envio" xml:space="preserve">
    <value>Planned sending date</value>
  </data>
  <data name="fecha_prevista_recepcion" xml:space="preserve">
    <value>Planned receiving date</value>
  </data>
  <data name="fecha_rango" xml:space="preserve">
    <value>Date (7 day range)</value>
  </data>
  <data name="FicherosFormas" xml:space="preserve">
    <value>Frame shape files</value>
    <comment>IndoScan</comment>
  </data>
  <data name="finalizado" xml:space="preserve">
    <value>Finished</value>
  </data>
  <data name="foco_y_material" xml:space="preserve">
    <value>Lens Type</value>
    <comment>Eye basic data view</comment>
  </data>
  <data name="forma" xml:space="preserve">
    <value>Shape</value>
  </data>
  <data name="formas_fax_enviarFichero" xml:space="preserve">
    <value>Obtain file</value>
  </data>
  <data name="formas_leidas" xml:space="preserve">
    <value>Shapes read</value>
  </data>
  <data name="formas_y_espesores" xml:space="preserve">
    <value>Shapes and Thicknesses</value>
    <comment>Order screen</comment>
  </data>
  <data name="forma_escogida" xml:space="preserve">
    <value>Chosen shape</value>
  </data>
  <data name="forma_guardada_OK" xml:space="preserve">
    <value>Shape saved correctly</value>
  </data>
  <data name="fotocromatico" xml:space="preserve">
    <value>Photo chromatic</value>
    <comment>Order screen</comment>
  </data>
  <data name="francia" xml:space="preserve">
    <value>France</value>
  </data>
  <data name="frontal" xml:space="preserve">
    <value>Front</value>
    <comment>Bevel direction</comment>
  </data>
  <data name="ganancia_en_espesor" xml:space="preserve">
    <value>Thickness gain</value>
  </data>
  <data name="ganancia_en_espesor_abr" xml:space="preserve">
    <value>Thick. gain.</value>
  </data>
  <data name="ganancia_en_peso" xml:space="preserve">
    <value>Weight gain</value>
  </data>
  <data name="ganancia_en_peso_abr" xml:space="preserve">
    <value>Weight. g.</value>
  </data>
  <data name="ganancia_espesor" xml:space="preserve">
    <value>Thickness gain</value>
    <comment>Technical data</comment>
  </data>
  <data name="ganancia_peso" xml:space="preserve">
    <value>Weight gain</value>
    <comment>Technical data</comment>
  </data>
  <data name="grados" xml:space="preserve">
    <value>Degrees</value>
    <comment>Supplements</comment>
  </data>
  <data name="graduacion_resultante" xml:space="preserve">
    <value>Graduación Resultante:</value>
  </data>
  <data name="guardar_biselado" xml:space="preserve">
    <value>Save edging</value>
  </data>
  <data name="guardar_receta" xml:space="preserve">
    <value>Save prescription</value>
    <comment>Order action button panel</comment>
  </data>
  <data name="hora" xml:space="preserve">
    <value>Time</value>
  </data>
  <data name="idioma" xml:space="preserve">
    <value>Language</value>
    <comment>Login screen</comment>
  </data>
  <data name="imprimir" xml:space="preserve">
    <value>Print</value>
    <comment>Order action button panel</comment>
  </data>
  <data name="imprimir_lista" xml:space="preserve">
    <value>Print list</value>
  </data>
  <data name="imprimir_pedidos_seleccionados" xml:space="preserve">
    <value>Print selected orders</value>
  </data>
  <data name="indice" xml:space="preserve">
    <value>Index</value>
    <comment>Eye basic data view</comment>
  </data>
  <data name="indonet" xml:space="preserve">
    <value>Indonet</value>
  </data>
  <data name="indo_en_el_mundo" xml:space="preserve">
    <value>» Indo in the world</value>
  </data>
  <data name="inferior" xml:space="preserve">
    <value>Lower Side</value>
  </data>
  <data name="informacion" xml:space="preserve">
    <value>Information</value>
  </data>
  <data name="info_creacion_bbdd_local" xml:space="preserve">
    <value>***&gt;Start the local database creation</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="info_creacion_bbdd_local_OK" xml:space="preserve">
    <value>***&gt;Local database creation OK</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="info_envio_pedido_revisar" xml:space="preserve">
    <value>Your order has been saved. Indo will check it and it will be started soon.</value>
  </data>
  <data name="info_grabar_oferta" xml:space="preserve">
    <value>***&gt;Start saving offer in local database</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="info_grabar_oferta_OK" xml:space="preserve">
    <value>***&gt;Saving offer in local database OK</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="info_ojo_informativo" xml:space="preserve">
    <value>Informative eye will not be manufactured</value>
  </data>
  <data name="info_pedido_procesado" xml:space="preserve">
    <value>Your order {0} has been saved and processed correctly</value>
  </data>
  <data name="inicio" xml:space="preserve">
    <value>Home</value>
    <comment>Main navigation bar</comment>
  </data>
  <data name="IntentarOtraVez" xml:space="preserve">
    <value>Please try again with a different image or contact INDO</value>
    <comment>IndoScan</comment>
  </data>
  <data name="intervalo_de_fechas" xml:space="preserve">
    <value>Date interval</value>
  </data>
  <data name="italia" xml:space="preserve">
    <value>Italy</value>
  </data>
  <data name="laboratorio" xml:space="preserve">
    <value>Laboratory</value>
  </data>
  <data name="lente" xml:space="preserve">
    <value>Design</value>
    <comment>Home screen, list of last orders</comment>
  </data>
  <data name="login_verificar" xml:space="preserve">
    <value>CHECK</value>
  </data>
  <data name="logout" xml:space="preserve">
    <value>Log Out</value>
    <comment>Toolbar</comment>
  </data>
  <data name="log_actualizacion_oferta" xml:space="preserve">
    <value>***&gt;Start offer update</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="log_cabecera_oferta_ok" xml:space="preserve">
    <value>***&gt;Offer header OK</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="log_oferta_descargada_ok" xml:space="preserve">
    <value>***&gt;Offer downloaded OK</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="l_taladrada" xml:space="preserve">
    <value>Flat</value>
    <comment>Bevel type</comment>
  </data>
  <data name="mail_informacion_estado_pedidos" xml:space="preserve">
    <value>E-MAIL ABOUTORDER'S STATUS</value>
    <comment>Mi buzon</comment>
  </data>
  <data name="marca" xml:space="preserve">
    <value>Brand</value>
  </data>
  <data name="mas_informacion" xml:space="preserve">
    <value>More information</value>
  </data>
  <data name="mensaje" xml:space="preserve">
    <value>Message</value>
  </data>
  <data name="mensaje_solicitud_clave" xml:space="preserve">
    <value>To have a password you only have to enter a correct e-mail and your Indo client code.{0}You will shortly receive your password to enter Indonet.{0}Thank you</value>
  </data>
  <data name="metal" xml:space="preserve">
    <value>Metal</value>
    <comment>type of frame</comment>
  </data>
  <data name="mini_bisel" xml:space="preserve">
    <value>Minibevel</value>
    <comment>Bevel type</comment>
  </data>
  <data name="mis_biselados" xml:space="preserve">
    <value>My Edgings</value>
  </data>
  <data name="mi_buzon" xml:space="preserve">
    <value>Mailbox</value>
    <comment>Mi buzon</comment>
  </data>
  <data name="modelo" xml:space="preserve">
    <value>Model</value>
  </data>
  <data name="modificar_espesor" xml:space="preserve">
    <value>Change thickness</value>
  </data>
  <data name="montaje" xml:space="preserve">
    <value>Mounting</value>
  </data>
  <data name="monturas" xml:space="preserve">
    <value>Frames</value>
    <comment>Order screen</comment>
  </data>
  <data name="mostrar_lentes_stock" xml:space="preserve">
    <value>Show only stock lenses</value>
    <comment>Eye basic data view</comment>
  </data>
  <data name="msg_Prerequisitos" xml:space="preserve">
    <value>The installation of programs to capture and process the images to obtain the shape of the lens will start automatically.</value>
    <comment>IndoScan</comment>
  </data>
  <data name="nasal" xml:space="preserve">
    <value>Nasal</value>
  </data>
  <data name="nd" xml:space="preserve">
    <value>Nd</value>
    <comment>Technical data</comment>
  </data>
  <data name="ninguna" xml:space="preserve">
    <value>None</value>
  </data>
  <data name="NoPosibleObtenerForma" xml:space="preserve">
    <value>Impossible to obtain shape from this image</value>
  </data>
  <data name="noticias" xml:space="preserve">
    <value>News</value>
    <comment>Home screen</comment>
  </data>
  <data name="no_esta_bien_formado" xml:space="preserve">
    <value>Not well formed</value>
    <comment>Confirmations</comment>
  </data>
  <data name="no_es_multiplo_de" xml:space="preserve">
    <value>Not a multiple of</value>
    <comment>Confirmations</comment>
  </data>
  <data name="no_se_actualiza_la_oferta" xml:space="preserve">
    <value>The offer is not updated</value>
    <comment>Logs &amp; errors</comment>
  </data>
  <data name="no_se_ha_podido_enviar_la_solucitud" xml:space="preserve">
    <value>The request could not be sent, please try later</value>
    <comment>popupMail</comment>
  </data>
  <data name="nueva_oferta_actualizada_correctamente" xml:space="preserve">
    <value>The new offer has been correctly updated.</value>
  </data>
  <data name="nueva_prev" xml:space="preserve">
    <value>New for.</value>
    <comment>Order list header</comment>
  </data>
  <data name="nueva_prevision" xml:space="preserve">
    <value>New forecast</value>
    <comment>Home screen, list of last orders</comment>
  </data>
  <data name="numero_pedido" xml:space="preserve">
    <value>Order number</value>
  </data>
  <data name="n_abbe" xml:space="preserve">
    <value>Abbe no.</value>
    <comment>Technical data</comment>
  </data>
  <data name="n_pedido" xml:space="preserve">
    <value>Job</value>
    <comment>Home screen, list of last orders</comment>
  </data>
  <data name="obligatorio_seleccionar_destiantario" xml:space="preserve">
    <value>Please inform destinatary</value>
  </data>
  <data name="observaciones" xml:space="preserve">
    <value>Observations</value>
  </data>
  <data name="OD" xml:space="preserve">
    <value>RE</value>
    <comment>Right eye</comment>
  </data>
  <data name="oferta" xml:space="preserve">
    <value>Offer</value>
  </data>
  <data name="oferta_actualizada" xml:space="preserve">
    <value>Offer updated</value>
  </data>
  <data name="OI" xml:space="preserve">
    <value>LE</value>
    <comment>Left Eye</comment>
  </data>
  <data name="OjoDerecho" xml:space="preserve">
    <value>Right eye</value>
  </data>
  <data name="OjoIzquierdo" xml:space="preserve">
    <value>Left eye</value>
  </data>
  <data name="ojo_derecho" xml:space="preserve">
    <value>Right eye</value>
    <comment>Send order</comment>
  </data>
  <data name="ojo_informativo" xml:space="preserve">
    <value>Informative eye</value>
    <comment>Eye basic data view</comment>
  </data>
  <data name="ojo_izquierdo" xml:space="preserve">
    <value>Left eye</value>
    <comment>Send order</comment>
  </data>
  <data name="optimizacion_freemax" xml:space="preserve">
    <value>FreeMax Optimization</value>
    <comment>Leyenda del precalibrado digital para MAXIMA</comment>
  </data>
  <data name="orientacion_del_bisel" xml:space="preserve">
    <value>Bevel Position</value>
  </data>
  <data name="parametros_proxy" xml:space="preserve">
    <value>Proxy connection parameters</value>
  </data>
  <data name="paso1" xml:space="preserve">
    <value>Step 1</value>
  </data>
  <data name="paso2" xml:space="preserve">
    <value>Step 2</value>
  </data>
  <data name="password" xml:space="preserve">
    <value>Password</value>
  </data>
  <data name="password_internet" xml:space="preserve">
    <value>Internet Password</value>
    <comment>proxy</comment>
  </data>
  <data name="pedidos_al_por_mayor" xml:space="preserve">
    <value>Wholesale orders</value>
  </data>
  <data name="pedidos_obtenidos" xml:space="preserve">
    <value>Order/s obtained</value>
  </data>
  <data name="pedidos_realizados" xml:space="preserve">
    <value>Entered Orders</value>
    <comment>Main navigation bar</comment>
  </data>
  <data name="pedido_con_errores" xml:space="preserve">
    <value>The order can not be sent. Check the alerts</value>
    <comment>Alerts</comment>
  </data>
  <data name="pedido_con_montura" xml:space="preserve">
    <value>Order with frame</value>
  </data>
  <data name="peso" xml:space="preserve">
    <value>Weight</value>
    <comment>Technical data</comment>
  </data>
  <data name="plano" xml:space="preserve">
    <value>Flat</value>
    <comment>Bevel type</comment>
  </data>
  <data name="portugal" xml:space="preserve">
    <value>Portugal</value>
  </data>
  <data name="por_favor_confirma_datos_pedido" xml:space="preserve">
    <value>Please confirm your order details:</value>
    <comment>Send order</comment>
  </data>
  <data name="precalibrado_a_cotas" xml:space="preserve">
    <value>Measurement pre-calibration</value>
  </data>
  <data name="precalibrado_digital" xml:space="preserve">
    <value>Digital pre-calibration</value>
  </data>
  <data name="precalibrado_digital_abr" xml:space="preserve">
    <value>Precal.</value>
  </data>
  <data name="precio" xml:space="preserve">
    <value>Prices</value>
  </data>
  <data name="PreguntaElegirOjo" xml:space="preserve">
    <value>Please select left or right eye</value>
    <comment>Elegir ojo</comment>
  </data>
  <data name="prevision" xml:space="preserve">
    <value>Forecast</value>
    <comment>Home screen, list of last orders</comment>
  </data>
  <data name="prisma_1" xml:space="preserve">
    <value>Prism 1</value>
    <comment>Supplements</comment>
  </data>
  <data name="prisma_2" xml:space="preserve">
    <value>Prism 2</value>
    <comment>Supplements</comment>
  </data>
  <data name="producto_lente" xml:space="preserve">
    <value>Product (lens)</value>
  </data>
  <data name="puente" xml:space="preserve">
    <value>Bridge</value>
    <comment>Additional data</comment>
  </data>
  <data name="quitar_seleccion" xml:space="preserve">
    <value>UnSelect</value>
  </data>
  <data name="radio" xml:space="preserve">
    <value>Radius</value>
    <comment>Technical data</comment>
  </data>
  <data name="ranurada" xml:space="preserve">
    <value>Grooving</value>
    <comment>type of frame</comment>
  </data>
  <data name="ranurada_metal" xml:space="preserve">
    <value>Metal grooving</value>
    <comment>Bevel type</comment>
  </data>
  <data name="ranurada_nylon" xml:space="preserve">
    <value>Nylon grooving</value>
    <comment>Bevel type</comment>
  </data>
  <data name="realizar_pedido" xml:space="preserve">
    <value>Order Entry</value>
    <comment>Main navigation bar</comment>
  </data>
  <data name="recetas_borrado" xml:space="preserve">
    <value>Prescription deletion</value>
  </data>
  <data name="recetas_borrar" xml:space="preserve">
    <value>Delete prescriptions</value>
  </data>
  <data name="recetas_enviar" xml:space="preserve">
    <value>Send prescriptions</value>
  </data>
  <data name="recetas_envio" xml:space="preserve">
    <value>Prescription sending</value>
  </data>
  <data name="recetas_guardadas" xml:space="preserve">
    <value>Prescriptions saved</value>
    <comment>Main navigation bar</comment>
  </data>
  <data name="recetas_obtenidas" xml:space="preserve">
    <value>Prescription/s obtained</value>
  </data>
  <data name="receta_envio_masivo" xml:space="preserve">
    <value>Order generated massively</value>
  </data>
  <data name="receta_guardada" xml:space="preserve">
    <value>The prescription has been saved correctly</value>
  </data>
  <data name="recordar_mis_credenciales" xml:space="preserve">
    <value>Remember my credentials</value>
  </data>
  <data name="redimensionar" xml:space="preserve">
    <value>Sizing</value>
  </data>
  <data name="referencia" xml:space="preserve">
    <value>Order Number</value>
    <comment>Order screen</comment>
  </data>
  <data name="ref_cliente" xml:space="preserve">
    <value>Client ref.</value>
    <comment>Home screen, list of last orders</comment>
  </data>
  <data name="registros" xml:space="preserve">
    <value>Registers</value>
  </data>
  <data name="reiniciar_fecha_oferta" xml:space="preserve">
    <value>Reload offer</value>
  </data>
  <data name="resto_de_paises" xml:space="preserve">
    <value>Rest of countries</value>
    <comment>Login</comment>
  </data>
  <data name="rx" xml:space="preserve">
    <value>RX</value>
    <comment>Home screen, list of last orders</comment>
  </data>
  <data name="salir" xml:space="preserve">
    <value>Quit</value>
  </data>
  <data name="segura" xml:space="preserve">
    <value>SECURE</value>
  </data>
  <data name="seguro_que_quiere_desconectarse" xml:space="preserve">
    <value>Are you sure you want to disconnect?</value>
    <comment>Alerts</comment>
  </data>
  <data name="seguro_que_quiere_salir" xml:space="preserve">
    <value>Are you sure you want to quit the application?</value>
    <comment>Alerts</comment>
  </data>
  <data name="selecciona" xml:space="preserve">
    <value>Select</value>
  </data>
  <data name="seleccionar_todos" xml:space="preserve">
    <value>Select All</value>
  </data>
  <data name="selecciona_abrv" xml:space="preserve">
    <value>Selec.</value>
  </data>
  <data name="selecciona_cliente" xml:space="preserve">
    <value>Select client</value>
  </data>
  <data name="selecciona_la_lente_deseada" xml:space="preserve">
    <value>Select desired lens</value>
  </data>
  <data name="seleccion_de_espesores" xml:space="preserve">
    <value>Thickness selection</value>
  </data>
  <data name="seleccion_vacia_recetas" xml:space="preserve">
    <value>No prescription has been selected</value>
  </data>
  <data name="SelEscaner" xml:space="preserve">
    <value>Select scanner</value>
    <comment>IndoScan</comment>
  </data>
  <data name="se_actualizo" xml:space="preserve">
    <value>Updated</value>
  </data>
  <data name="siguiente" xml:space="preserve">
    <value>Next</value>
  </data>
  <data name="simulador_de_lentes" xml:space="preserve">
    <value>Lens simulator</value>
  </data>
  <data name="simulador_error" xml:space="preserve">
    <value>Error while launching the lens simulator</value>
  </data>
  <data name="simulador_no_instalado" xml:space="preserve">
    <value>The lens simulator is not installed</value>
  </data>
  <data name="sin_coloracion" xml:space="preserve">
    <value>Without tinting</value>
  </data>
  <data name="sin_fotocromatico" xml:space="preserve">
    <value>No photo chromatics</value>
  </data>
  <data name="sin_precalibrado" xml:space="preserve">
    <value>No pre-calibration</value>
  </data>
  <data name="sistema_pedidos_por_mayor" xml:space="preserve">
    <value>Online stock orders tool</value>
  </data>
  <data name="situacion_de_los_pedidos" xml:space="preserve">
    <value>Order status</value>
  </data>
  <data name="solicitar_acceso" xml:space="preserve">
    <value>Request access</value>
  </data>
  <data name="solicitar_clave_acceso" xml:space="preserve">
    <value>» Request access key</value>
  </data>
  <data name="solicitud_acceso" xml:space="preserve">
    <value>Access request</value>
  </data>
  <data name="solicitud_enviada_correctamente" xml:space="preserve">
    <value>The access request has been sent correctly</value>
    <comment>popupMail</comment>
  </data>
  <data name="solo_stock" xml:space="preserve">
    <value>Stock only</value>
  </data>
  <data name="stock" xml:space="preserve">
    <value>Stock</value>
  </data>
  <data name="sugerencias" xml:space="preserve">
    <value>Suggestions</value>
    <comment>Toolbar</comment>
  </data>
  <data name="superior" xml:space="preserve">
    <value>Upper Side</value>
  </data>
  <data name="suplementos" xml:space="preserve">
    <value>ADDITIONAL DATA</value>
    <comment>Basic data</comment>
  </data>
  <data name="taladrada" xml:space="preserve">
    <value>Drilled</value>
    <comment>type of frame</comment>
  </data>
  <data name="talla" xml:space="preserve">
    <value>Size</value>
  </data>
  <data name="temporal" xml:space="preserve">
    <value>Temporal Side</value>
  </data>
  <data name="texto" xml:space="preserve">
    <value>Text</value>
  </data>
  <data name="texto_direcciones_email" xml:space="preserve">
    <value>E-mail address where you want to receive this information:</value>
    <comment>Mi buzon</comment>
  </data>
  <data name="texto_pedidos_en_curso" xml:space="preserve">
    <value>Current orders (1 daily e-mail)</value>
    <comment>Mi buzon</comment>
  </data>
  <data name="texto_pedidos_en_envio" xml:space="preserve">
    <value>Orders to receive in each shipment (1 e-mail for each shipment)</value>
    <comment>Mi buzon</comment>
  </data>
  <data name="texto_presentacion1" xml:space="preserve">
    <value>Welcome to the new generation of online orders.</value>
  </data>
  <data name="texto_presentacion2" xml:space="preserve">
    <value>This is the beginning of the road of the new Indo!</value>
  </data>
  <data name="texto_presentacion3" xml:space="preserve">
    <value>Surprise yourself with the most agile and intuitive tool on the market to place your orders. With features that will allow you to increase the added value of your sales.</value>
  </data>
  <data name="texto_presentacion4" xml:space="preserve">
    <value>Customize and optimize thickness for lab lenses.</value>
  </data>
  <data name="texto_presentacion5" xml:space="preserve">
    <value>Use most of the market tracers.</value>
  </data>
  <data name="texto_presentacion6" xml:space="preserve">
    <value>Track your orders more closely.</value>
  </data>
  <data name="texto_presentacion7" xml:space="preserve">
    <value>Check with your sales representative, or your custom manager, the most appropriate option to cover all your needs.</value>
  </data>
  <data name="texto_si_deseas_recibir_mails" xml:space="preserve">
    <value>If you accept to receive informative e-mail about your orders, kindly select the following options:</value>
    <comment>Mi buzon</comment>
  </data>
  <data name="text_info_contacto" xml:space="preserve">
    <value>If you need further information, contact us at:</value>
    <comment>Login screen</comment>
  </data>
  <data name="tipo_bisel" xml:space="preserve">
    <value>Bevel type</value>
  </data>
  <data name="tipo_de_bisel" xml:space="preserve">
    <value>Bevel type</value>
  </data>
  <data name="tipo_de_montura" xml:space="preserve">
    <value>Type of frame</value>
  </data>
  <data name="tipo_lente" xml:space="preserve">
    <value>Lens type</value>
  </data>
  <data name="tipo_montura" xml:space="preserve">
    <value>Frame type</value>
  </data>
  <data name="tono" xml:space="preserve">
    <value>Tone</value>
    <comment>Order screen</comment>
  </data>
  <data name="toolTip_abrir_indoscan" xml:space="preserve">
    <value>Open INDOScan</value>
  </data>
  <data name="tooltip_boton_importar" xml:space="preserve">
    <value>Imports a shape from a file</value>
  </data>
  <data name="tooltip_boton_iniciar" xml:space="preserve">
    <value>Shows all the readings saved in INDOBISEL</value>
  </data>
  <data name="tooltip_boton_ultima" xml:space="preserve">
    <value>Retrieve the last shape saved in INDOBISEL</value>
  </data>
  <data name="tratamientos" xml:space="preserve">
    <value>Treatments</value>
    <comment>Eye basic data view</comment>
  </data>
  <data name="tratamiento_coloracion" xml:space="preserve">
    <value>Treatment and colouring</value>
  </data>
  <data name="trazador" xml:space="preserve">
    <value>Tracer</value>
  </data>
  <data name="trazadores" xml:space="preserve">
    <value>Tracers</value>
  </data>
  <data name="trazadores_anadir" xml:space="preserve">
    <value>Add ...</value>
  </data>
  <data name="trazadores_borrar" xml:space="preserve">
    <value>Delete</value>
  </data>
  <data name="trazadores_borrar_todos" xml:space="preserve">
    <value>Delete all</value>
  </data>
  <data name="trazadores_comprobacion" xml:space="preserve">
    <value>Check</value>
  </data>
  <data name="trazadores_conexion_agente_trazador_OK" xml:space="preserve">
    <value>THE TRACER AGENT {0} IS CORRECTLY CONNECTED</value>
  </data>
  <data name="trazadores_direccion" xml:space="preserve">
    <value>Address</value>
  </data>
  <data name="trazadores_modificar" xml:space="preserve">
    <value>Change</value>
  </data>
  <data name="trazadores_nombre_agente" xml:space="preserve">
    <value>Agent name</value>
  </data>
  <data name="trazadores_popup_anular" xml:space="preserve">
    <value>Annul</value>
  </data>
  <data name="trazadores_popup_titulo" xml:space="preserve">
    <value>Selection / Modification of tracer agent</value>
  </data>
  <data name="trazadores_puerto" xml:space="preserve">
    <value>Port</value>
  </data>
  <data name="trazadores_trazador" xml:space="preserve">
    <value>Tracer</value>
  </data>
  <data name="trazador_busqueda_ordenadores_red" xml:space="preserve">
    <value>Choose the unit on the net</value>
  </data>
  <data name="tto_adicional" xml:space="preserve">
    <value>Tmt. Additional</value>
    <comment>Eye basic data view</comment>
  </data>
  <data name="tutoriales" xml:space="preserve">
    <value>Tutorials</value>
    <comment>Toolbar</comment>
  </data>
  <data name="t_por_ciento" xml:space="preserve">
    <value>T%</value>
    <comment>Technical data</comment>
  </data>
  <data name="ultimos_pedidos" xml:space="preserve">
    <value>Latest orders</value>
    <comment>Home screen</comment>
  </data>
  <data name="url_indonet_direct" xml:space="preserve">
    <value>http://pedidos.direc.indo.es/</value>
    <comment>Links </comment>
  </data>
  <data name="url_indo_en_el_mundo" xml:space="preserve">
    <value>http://www.indo.es/indo-en-el-mundo/indo_enelmundo.asp</value>
    <comment>Links</comment>
  </data>
  <data name="url_mas_informacion" xml:space="preserve">
    <value>http://www.indo.es/lentes/lentes_lentesindo.asp</value>
    <comment>Links</comment>
  </data>
  <data name="url_tutoriales" xml:space="preserve">
    <value>http://www.indo.es/en/optics/tutorials</value>
    <comment>Links</comment>
  </data>
  <data name="usuario" xml:space="preserve">
    <value>User</value>
  </data>
  <data name="usuario_internet" xml:space="preserve">
    <value>Internet User</value>
    <comment>proxy</comment>
  </data>
  <data name="valor_fuera_de_margenes" xml:space="preserve">
    <value>Value outside margins for {2}. Allowed values ​between {0} and {1}.</value>
  </data>
  <data name="ventana_espera_actualizar_pedido" xml:space="preserve">
    <value>UPDATING ORDER</value>
  </data>
  <data name="ventana_espera_areaventa" xml:space="preserve">
    <value>LOADING SALES AREA</value>
  </data>
  <data name="ventana_espera_borrar_recetas" xml:space="preserve">
    <value>DELETING PRESCRIPTIONS</value>
  </data>
  <data name="ventana_espera_busqueda_pedidos" xml:space="preserve">
    <value>SEEKING ORDERS</value>
  </data>
  <data name="ventana_espera_busqueda_recetas" xml:space="preserve">
    <value>SEEKING PRESCRIPTIONS</value>
  </data>
  <data name="ventana_espera_cambio_clave" xml:space="preserve">
    <value>SAVING NEW ACCESS KEY</value>
  </data>
  <data name="ventana_espera_cargando_clientes" xml:space="preserve">
    <value>RETRIEVING CLIENTS LIST</value>
  </data>
  <data name="ventana_espera_carga_pedido" xml:space="preserve">
    <value>LOADING ORDER {0}</value>
  </data>
  <data name="ventana_espera_carga_pedidos_inicial" xml:space="preserve">
    <value>LOADING INITIAL LIST OF ORDERS</value>
  </data>
  <data name="ventana_espera_carga_receta" xml:space="preserve">
    <value>LOADING PRESCRIPTION {0}</value>
  </data>
  <data name="ventana_espera_comparando_lentes" xml:space="preserve">
    <value>COMPARING LENSES</value>
  </data>
  <data name="ventana_espera_configuracion" xml:space="preserve">
    <value>LOADING SETUP</value>
  </data>
  <data name="ventana_espera_destinatarios" xml:space="preserve">
    <value>LOADING RECEIVERS</value>
  </data>
  <data name="ventana_espera_envio_correo" xml:space="preserve">
    <value>SENDING MAIL</value>
  </data>
  <data name="ventana_espera_envio_pedidos" xml:space="preserve">
    <value>SENDING ORDER</value>
  </data>
  <data name="ventana_espera_envio_recetas" xml:space="preserve">
    <value>SENDING PRESCRIPTIONS</value>
  </data>
  <data name="ventana_espera_esperar" xml:space="preserve">
    <value>Wait ...</value>
  </data>
  <data name="ventana_espera_guardar_configuracion" xml:space="preserve">
    <value>SAVING SETUP</value>
  </data>
  <data name="ventana_espera_guardar_datos" xml:space="preserve">
    <value>SAVING DATA</value>
  </data>
  <data name="ventana_espera_guardar_datos_optica" xml:space="preserve">
    <value>SAVING OPTICAL DATA</value>
  </data>
  <data name="ventana_espera_guardar_receta" xml:space="preserve">
    <value>SAVING PRESCRIPTION {0}</value>
  </data>
  <data name="ventana_espera_lecturas_trazador" xml:space="preserve">
    <value>TRACER READINGS {0}{1}</value>
  </data>
  <data name="ventana_espera_lecturas_trazadores" xml:space="preserve">
    <value>TRACER AGENT READINGS {0}</value>
  </data>
  <data name="ventana_espera_lectura_trazador" xml:space="preserve">
    <value>READING {0}</value>
  </data>
  <data name="ventana_espera_login" xml:space="preserve">
    <value>CLIENT AUTHENTICATION {0}</value>
  </data>
  <data name="ventana_espera_misbiselados" xml:space="preserve">
    <value>LOADING TRACERS</value>
  </data>
  <data name="ventana_espera_monturas" xml:space="preserve">
    <value>LOADING FRAMES</value>
  </data>
  <data name="ventana_espera_obteniendo_bases" xml:space="preserve">
    <value>RETRIEVING BLANK RANGE</value>
  </data>
  <data name="ventana_espera_oferta" xml:space="preserve">
    <value>UPDATING OFFER</value>
  </data>
  <data name="ventana_espera_revision_pedidos" xml:space="preserve">
    <value>SENDING FOR CHECKING</value>
  </data>
  <data name="ventana_espera_seleccion_lente" xml:space="preserve">
    <value>SELECTING LENS</value>
  </data>
  <data name="ventana_espera_verifica_conexion_trazador" xml:space="preserve">
    <value>CHECK TRACER AGENT CONNECTION {0}</value>
  </data>
  <data name="verifique_conexion" xml:space="preserve">
    <value>Check the Internet connection</value>
  </data>
  <data name="VolverAObtenerDeEscaner" xml:space="preserve">
    <value>Call shape again</value>
    <comment>IndoScan</comment>
  </data>
  <data name="volver_al_listado" xml:space="preserve">
    <value>Return to the list</value>
  </data>
  <data name="zona_de_identificacion" xml:space="preserve">
    <value>Identification area</value>
    <comment>Toolbar</comment>
  </data>
  <data name="jobs_omain" xml:space="preserve">
    <value>CALCULATED JOBS</value>
  </data>
  <data name="fabricante" xml:space="preserve">
    <value>Manufacturer</value>
    <comment>Seminterminados FFS</comment>
  </data>
  <data name="familia" xml:space="preserve">
    <value>Family</value>
    <comment>Seminterminados FFS</comment>
  </data>
  <data name="semitermin" xml:space="preserve">
    <value>BLANK</value>
    <comment>FFS</comment>
  </data>
  <data name="semiterminados" xml:space="preserve">
    <value>BLANKS</value>
    <comment>Seminterminados FFS</comment>
  </data>
  <data name="BlankRange" xml:space="preserve">
    <value>Blank range</value>
    <comment>BlankRange</comment>
  </data>
  <data name="info_no_fabricara" xml:space="preserve">
    <value>This lens will not be manufactured</value>
  </data>
  <data name="fecha_llegada_real" xml:space="preserve">
    <value>Arrival Date:</value>
  </data>
  <data name="aviso_biselar_por_defecto" xml:space="preserve">
    <value>EDGING</value>
  </data>
  <data name="segunda_gafa" xml:space="preserve">
    <value>SECOND PAIR</value>
    <comment>Configuración óptica</comment>
  </data>
  <data name="aviso_borrar_pedido" xml:space="preserve">
    <value>delete order</value>
  </data>
  <data name="aviso_encargo_anulado" xml:space="preserve">
    <value>Your order is deleted  </value>
  </data>
  <data name="aviso_encargo_no_anulable" xml:space="preserve">
    <value>Unable to delete the order</value>
  </data>
  <data name="pregunta_borrar_pedido" xml:space="preserve">
    <value>Are you sure you want to delete the order {0}?</value>
  </data>
  <data name="ventana_espera_borrar_pedidos" xml:space="preserve">
    <value>DELETING ORDER {0}</value>
  </data>
  <data name="aviso_error_buscando_datos" xml:space="preserve">
    <value>Error retrieving data.</value>
  </data>
  <data name="aviso_error_enviando_mensaje_SRI" xml:space="preserve">
    <value>Error sending mail to SRI</value>
  </data>
  <data name="aviso_no_hay_incidencias" xml:space="preserve">
    <value>Incidence not found</value>
  </data>
  <data name="aviso_no_hay_tema" xml:space="preserve">
    <value>Subject not specified</value>
  </data>
  <data name="aviso_tema_no_encontrado" xml:space="preserve">
    <value>Subject not found</value>
  </data>
  <data name="email_contacto" xml:space="preserve">
    <value>Contact Email</value>
  </data>
  <data name="errores_en_formulario" xml:space="preserve">
    <value>Errors found in the form.</value>
  </data>
  <data name="ficheros_adjuntos" xml:space="preserve">
    <value>Attachments</value>
  </data>
  <data name="fin_ficheros_adjuntos" xml:space="preserve">
    <value>End of attachments</value>
  </data>
  <data name="motivos" xml:space="preserve">
    <value>Motivos</value>
  </data>
  <data name="motivo_no_definido" xml:space="preserve">
    <value>Reason not specified.</value>
  </data>
  <data name="no_hay_motivos" xml:space="preserve">
    <value>No hay motivos</value>
  </data>
  <data name="sin_ficheros_adjuntos" xml:space="preserve">
    <value>without attachments</value>
  </data>
  <data name="sin_observaciones" xml:space="preserve">
    <value>no observations</value>
  </data>
  <data name="tema" xml:space="preserve">
    <value>Subject</value>
  </data>
  <data name="mensaje_pedido_duplicado" xml:space="preserve">
    <value>ATTENTION. Possibly Duplicated Order. Entered on Date: {0}.  {1} #: {2}</value>
  </data>
  <data name="pedido_duplicado" xml:space="preserve">
    <value>DUPLICATE ORDER</value>
  </data>
  <data name="receta" xml:space="preserve">
    <value>Prescription Saved</value>
  </data>
  <data name="no_mostrar_aviso" xml:space="preserve">
    <value>Do not show this message again</value>
  </data>
  <data name="error_usuario_o_clave_no_validos" xml:space="preserve">
    <value>User or password not correct</value>
  </data>
  <data name="cantidad_mayor_que_cero" xml:space="preserve">
    <value>In stock</value>
  </data>
  <data name="descripcion" xml:space="preserve">
    <value>Description</value>
  </data>
  <data name="filtro_descripcion" xml:space="preserve">
    <value>Filter Description:</value>
  </data>
  <data name="filtro_fabricante" xml:space="preserve">
    <value>Filter Manufacturer:</value>
  </data>
  <data name="base_optima" xml:space="preserve">
    <value>Optimal Base</value>
  </data>
  <data name="borrar" xml:space="preserve">
    <value>Delete</value>
  </data>
  <data name="densidad" xml:space="preserve">
    <value>Density</value>
  </data>
  <data name="foco" xml:space="preserve">
    <value>Focus</value>
  </data>
  <data name="guardar" xml:space="preserve">
    <value>Save</value>
  </data>
  <data name="modificar" xml:space="preserve">
    <value>Modify</value>
  </data>
  <data name="nuevo" xml:space="preserve">
    <value>New</value>
  </data>
  <data name="base_nominal" xml:space="preserve">
    <value>Nominal Base</value>
    <comment>FFS</comment>
  </data>
  <data name="bonus_clicks" xml:space="preserve">
    <value>Bonus clicks</value>
    <comment>FFS</comment>
  </data>
  <data name="bonus_clicks_local" xml:space="preserve">
    <value>Bonus clicks local</value>
    <comment>FFS</comment>
  </data>
  <data name="bonus_local" xml:space="preserve">
    <value>Bonus local</value>
    <comment>FFS</comment>
  </data>
  <data name="bonus_restantes" xml:space="preserve">
    <value>Bonus restantes</value>
    <comment>FFS</comment>
  </data>
  <data name="nombre_producto" xml:space="preserve">
    <value>PRODUCT NAME</value>
  </data>
  <data name="productos_y_credito" xml:space="preserve">
    <value>Products &amp; credit</value>
    <comment>FFS</comment>
  </data>
  <data name="recargar" xml:space="preserve">
    <value>Refresh</value>
    <comment>FFS</comment>
  </data>
  <data name="ventana_espera_recuperar_credito" xml:space="preserve">
    <value>RETRIEVING BONUS</value>
    <comment>FFS</comment>
  </data>
  <data name="ventana_espera_recuperar_datos" xml:space="preserve">
    <value>RETRIEVING DATA</value>
    <comment>FFS</comment>
  </data>
  <data name="ventana_espera_recuperar_fabricantes" xml:space="preserve">
    <value>RETRIEVING MANUFACTURERS</value>
    <comment>FFS</comment>
  </data>
  <data name="ventana_espera_recuperar_familias" xml:space="preserve">
    <value>RETRIEVING FAMILIES</value>
    <comment>FFS</comment>
  </data>
  <data name="ventana_espera_recuperar_semiterminados" xml:space="preserve">
    <value>RETRIEVING BLANKS</value>
    <comment>FFS</comment>
  </data>
  <data name="borrar_forma" xml:space="preserve">
    <value>Delete shape</value>
    <comment>Borrado MiBiselado</comment>
  </data>
  <data name="confirmar_borrar_forma" xml:space="preserve">
    <value>Are you sure you want to delete this shape?</value>
    <comment>Borrado MiBiselado</comment>
  </data>
  <data name="montura_seleccionada" xml:space="preserve">
    <value>Selected frame</value>
    <comment>FormaRemota</comment>
  </data>
  <data name="ventana_espera_borrar_forma" xml:space="preserve">
    <value>DELETING SHAPE</value>
    <comment>Borrado MiBiselado</comment>
  </data>
  <data name="borrar_mi_biselado" xml:space="preserve">
    <value>Remove from My Edgings</value>
    <comment>Borrado MiBiselado</comment>
  </data>
  <data name="pedir_montura" xml:space="preserve">
    <value>Add Frame</value>
    <comment>FormaRemota</comment>
  </data>
  <data name="base_automatica" xml:space="preserve">
    <value>Automatic Base</value>
  </data>
  <data name="anillas" xml:space="preserve">
    <value>Ring Settings</value>
    <comment>FFS</comment>
  </data>
  <data name="configuracion_taller" xml:space="preserve">
    <value>CALC Settings</value>
    <comment>FFS</comment>
  </data>
  <data name="macros_talla" xml:space="preserve">
    <value>Cutting Macros</value>
    <comment>FFS</comment>
  </data>
  <data name="marcas_laser" xml:space="preserve">
    <value>LASER Marks</value>
    <comment>FFS</comment>
  </data>
  <data name="peticiones_talla" xml:space="preserve">
    <value>PROCESSED JOBS</value>
    <comment>FFS</comment>
  </data>
  <data name="biselado_remoto" xml:space="preserve">
    <value>Remote Edging</value>
  </data>
  <data name="descentramiento" xml:space="preserve">
    <value>Decentering</value>
  </data>
  <data name="espesor_especial" xml:space="preserve">
    <value>Special Thickness</value>
  </data>
  <data name="otros_datos" xml:space="preserve">
    <value>OTHER DATA</value>
  </data>
  <data name="precalibrado" xml:space="preserve">
    <value>Pre-calibration</value>
  </data>
  <data name="prisma" xml:space="preserve">
    <value>Prism</value>
  </data>
  <data name="anadir" xml:space="preserve">
    <value>Add</value>
  </data>
  <data name="eliminar_seleccionados" xml:space="preserve">
    <value>Delete selected</value>
  </data>
  <data name="exportar" xml:space="preserve">
    <value>Export</value>
  </data>
  <data name="importar" xml:space="preserve">
    <value>Import</value>
  </data>
  <data name="diametro_resultante" xml:space="preserve">
    <value>CRIB</value>
  </data>
  <data name="datos_opcionales" xml:space="preserve">
    <value>Show additional optional data</value>
  </data>
  <data name="grabar_cambios" xml:space="preserve">
    <value>Save changes</value>
  </data>
  <data name="setup_laboratorios" xml:space="preserve">
    <value>Lab Settings</value>
  </data>
  <data name="diametro_resultante_abrev" xml:space="preserve">
    <value>CRIB</value>
  </data>
  <data name="espesor_taladro_minimo_abrev" xml:space="preserve">
    <value>Drill Min. Th.</value>
  </data>
  <data name="indoCenter_popup_titulo" xml:space="preserve">
    <value>IndoCenter reading selection</value>
  </data>
  <data name="nombre" xml:space="preserve">
    <value>Name</value>
  </data>
  <data name="aviso_espera" xml:space="preserve">
    <value>Wait</value>
  </data>
  <data name="error_debe_seleccionar_lectura" xml:space="preserve">
    <value>A row must be chosen</value>
  </data>
  <data name="aviso_faltan_datos_en_prisma" xml:space="preserve">
    <value>Unspecified axe or base of the prism.</value>
  </data>
  <data name="aviso_desactivar_isotipo" xml:space="preserve">
    <value>Orders without shape or with a basic shape cannot ask the logo. Deactivate the logo</value>
  </data>
  <data name="aviso_diferentes_alturas" xml:space="preserve">
    <value>Fitting height difference between eyes greater than or equal to 3mm.</value>
  </data>
  <data name="aviso_si_quiere_recogida_llamar_ATC" xml:space="preserve">
    <value>Should you need a pick up please call our Customer Service</value>
  </data>
  <data name="aviso_mas_365_dias" xml:space="preserve">
    <value>It is not allowed more than 365 days between dates</value>
  </data>
  <data name="abonos" xml:space="preserve">
    <value>Credit Notes</value>
  </data>
  <data name="añadir_archivos" xml:space="preserve">
    <value>Add files</value>
  </data>
  <data name="bono" xml:space="preserve">
    <value>Bond</value>
  </data>
  <data name="bonos" xml:space="preserve">
    <value>BONDS</value>
  </data>
  <data name="comercial" xml:space="preserve">
    <value>Commercial</value>
  </data>
  <data name="condiciones_anulación_pedidos" xml:space="preserve">
    <value>Conditions of cancellation of orders</value>
  </data>
  <data name="condiciones_comerciales" xml:space="preserve">
    <value>Commercial conditions</value>
  </data>
  <data name="consulta_factura" xml:space="preserve">
    <value>Consult invoice</value>
  </data>
  <data name="consulta_indocenter" xml:space="preserve">
    <value>Consult Indocenter</value>
  </data>
  <data name="consulta_indonet" xml:space="preserve">
    <value>Consult Indonet</value>
  </data>
  <data name="consulta_indoshow" xml:space="preserve">
    <value>Consult IndoShow</value>
  </data>
  <data name="consulta_pedido" xml:space="preserve">
    <value>Consult order</value>
  </data>
  <data name="crear_devolucion" xml:space="preserve">
    <value>Create Return</value>
  </data>
  <data name="define_el_motivo" xml:space="preserve">
    <value>Specify reason</value>
  </data>
  <data name="deseo_recibir_informacion_bonos" xml:space="preserve">
    <value>I want to receive information in my e-mail when one of the bonds is becoming exhausted or the consumption is lower than the ideal one</value>
  </data>
  <data name="duplicado_factura" xml:space="preserve">
    <value>Duplicate invoice</value>
  </data>
  <data name="escribe_los_detalles_complementarios_que_creas_conveniente" xml:space="preserve">
    <value>Provide complementary details if neccesary</value>
  </data>
  <data name="estadisticas" xml:space="preserve">
    <value>Statistics</value>
  </data>
  <data name="estado_de_los_bonos" xml:space="preserve">
    <value>State of the bonds</value>
  </data>
  <data name="este_contacto" xml:space="preserve">
    <value>This contact</value>
  </data>
  <data name="facturacion" xml:space="preserve">
    <value>Invoices and delivery notes</value>
  </data>
  <data name="formacion_visualmap" xml:space="preserve">
    <value>Training VisualMap</value>
  </data>
  <data name="gama" xml:space="preserve">
    <value>Range:</value>
  </data>
  <data name="gestion" xml:space="preserve">
    <value>Management</value>
  </data>
  <data name="incidencias_visualmap" xml:space="preserve">
    <value>Incidences VisualMap</value>
  </data>
  <data name="incidencia_indonet" xml:space="preserve">
    <value>Incidence Indonet</value>
  </data>
  <data name="indicar_producto_o_tratamiento" xml:space="preserve">
    <value>Indicate product or treatment</value>
  </data>
  <data name="iniciar_sesion" xml:space="preserve">
    <value>Login</value>
  </data>
  <data name="instalacion_indonet" xml:space="preserve">
    <value>Installation Indonet</value>
  </data>
  <data name="material" xml:space="preserve">
    <value>Material</value>
  </data>
  <data name="mes_o_numero_factura" xml:space="preserve">
    <value>Month or number invoice </value>
  </data>
  <data name="mi_cuenta" xml:space="preserve">
    <value>My account</value>
  </data>
  <data name="numero_de_albaran" xml:space="preserve">
    <value>Number of delivery note</value>
  </data>
  <data name="numero_de_bono" xml:space="preserve">
    <value>Number of bond</value>
  </data>
  <data name="oferta_lentes_bono" xml:space="preserve">
    <value>Offer lenses bond</value>
  </data>
  <data name="otros" xml:space="preserve">
    <value>Others</value>
  </data>
  <data name="piezas_pendientes_bono" xml:space="preserve">
    <value>On going pieces bond </value>
  </data>
  <data name="promociones" xml:space="preserve">
    <value>Promotions</value>
  </data>
  <data name="referencia_o_numero_de_albaran" xml:space="preserve">
    <value>Reference or number of delivery note</value>
  </data>
  <data name="renovar_bono" xml:space="preserve">
    <value>Renew bond</value>
  </data>
  <data name="saldo_bonos" xml:space="preserve">
    <value>Balance of Units and consummate Units</value>
  </data>
  <data name="servicios" xml:space="preserve">
    <value>Services</value>
  </data>
  <data name="solicitar_informacion" xml:space="preserve">
    <value>Request Information</value>
  </data>
  <data name="solicitar_tarifa" xml:space="preserve">
    <value>Request TariffPrice</value>
  </data>
  <data name="solicitar_visita" xml:space="preserve">
    <value>Request visit </value>
  </data>
  <data name="texto_soporte_online" xml:space="preserve">
    <value>If our customer service departement indicates it to you, please access to this link to obtain support online</value>
  </data>
  <data name="tiene_recogida_solicitada" xml:space="preserve">
    <value>You have a withdrawal requested:</value>
  </data>
  <data name="tus_datos" xml:space="preserve">
    <value>Your data</value>
  </data>
  <data name="tu_comercial" xml:space="preserve">
    <value>Your commercial</value>
  </data>
  <data name="tu_gestor" xml:space="preserve">
    <value>Your sales administrator</value>
  </data>
  <data name="tu_peticion" xml:space="preserve">
    <value>Your request</value>
  </data>
  <data name="año" xml:space="preserve">
    <value>Year</value>
  </data>
  <data name="mes" xml:space="preserve">
    <value>Month</value>
  </data>
  <data name="he_olvidado_mi_clave" xml:space="preserve">
    <value>» I forgot my password</value>
  </data>
  <data name="aviso_eliminacion_boton_igual_suplementos" xml:space="preserve">
    <value>In order to avoid mistakes we have removed the button to equalize the supplements in both eyes. Now you must enter the values you want for each eye.</value>
  </data>
  <data name="aviso_ha_generado_premio" xml:space="preserve">
    <value>This order has generated a promotion applicable to the second pair. Do you want to do now the new order? 
In affirmative case we will help you to filter the available products or, if you prefer, you can make it from the eyelash "Realize order".</value>
  </data>
  <data name="aviso_nuevos_botones_promociones" xml:space="preserve">
    <value>Now you can activate the new guide of promotions in the eyelash of configuration. If you want to know how it works you have a video available with the instructions in Tutoriales's section. If you wish more information, put in touch with your commercial or with our service of Attention to the Client</value>
  </data>
  <data name="debe_seleccionar_un_pedido" xml:space="preserve">
    <value>You must select an order</value>
  </data>
  <data name="eliminar_filtros" xml:space="preserve">
    <value>Remove filters</value>
  </data>
  <data name="hay_generadores_para_el_pedido" xml:space="preserve">
    <value>You have an active promotion that can be applied in this order. Do you want to see the products that allow it?</value>
  </data>
  <data name="no_volver_a_mostrar" xml:space="preserve">
    <value>Do not show this message again</value>
  </data>
  <data name="pulse_boton_enviar_y_revision" xml:space="preserve">
    <value>Just touch the button "Send and review" for the customer service to review it.</value>
  </data>
  <data name="receta_no_grabada" xml:space="preserve">
    <value>This recipe couldn't have been recorded</value>
  </data>
  <data name="selecciona_premio" xml:space="preserve">
    <value>Select your second spectacle:</value>
  </data>
  <data name="selecciona_promocion" xml:space="preserve">
    <value>Select the promotion:</value>
  </data>
  <data name="seleccion_primer_pedido" xml:space="preserve">
    <value>Selection of the first order</value>
  </data>
  <data name="entendido" xml:space="preserve">
    <value>OK</value>
  </data>
  <data name="aviso_cambio_contraseña_indonet" xml:space="preserve">
    <value>To improve the security and confidentiality of our clients, we recommend to you to modify and to personalize your password access to this platform. You will be able to do it from the eyelash of configuration that will be opened later.</value>
  </data>
  <data name="aviso_debe_rellenar_todos_los_campos" xml:space="preserve">
    <value>You have to fill out all the fields</value>
  </data>
  <data name="aviso_nueva_clave_no_es_indonet" xml:space="preserve">
    <value>The new password cannot be « INDONET »</value>
  </data>
  <data name="notificacion" xml:space="preserve">
    <value>Notification</value>
  </data>
  <data name="aviso_recogidas_montaje_o_devolucion_1" xml:space="preserve">
    <value>These pick ups do not have any additional cost in invoice.</value>
  </data>
  <data name="aviso_recogidas_montaje_o_devolucion_2" xml:space="preserve">
    <value>You have {2} pick ups for returns per month when you request them from Indonet.</value>
  </data>
  <data name="condiciones_anulación_pedidos1" xml:space="preserve">
    <value>Cancellation policy and order refunds</value>
  </data>
  <data name="continuar_sin_verlo" xml:space="preserve">
    <value>Skip</value>
  </data>
  <data name="importante" xml:space="preserve">
    <value>IMPORTANT: </value>
  </data>
  <data name="invitacion_uso_devoluciones" xml:space="preserve">
    <value>To make your returns in an agile and clear way, we invite you to use our returns tool. In it you can also manage any pick up of your returns.</value>
  </data>
  <data name="ir_a_gestion_devoluciones" xml:space="preserve">
    <value>Go to return tool</value>
  </data>
  <data name="numero_recogidas_disponibles_este_mes" xml:space="preserve">
    <value>Free pick ups available this month</value>
  </data>
  <data name="numero_recogidas_efectuadas_este_mes" xml:space="preserve">
    <value>Pick ups requested this month</value>
  </data>
  <data name="quiero_solicitar_recogida" xml:space="preserve">
    <value>I would to request a pick up in:</value>
  </data>
  <data name="recogida_garantia" xml:space="preserve">
    <value>Return pick up</value>
  </data>
  <data name="recogida_montaje" xml:space="preserve">
    <value>Assembly pick up</value>
  </data>
  <data name="recogida_para_montaje_o_comprobacion" xml:space="preserve">
    <value>Assembly</value>
  </data>
  <data name="redireccion_pestaña_devoluciones" xml:space="preserve">
    <value>You will be redirected to the Returns tab. Do you want to watch the use tutorial before?</value>
  </data>
  <data name="resumen_recogidas" xml:space="preserve">
    <value>Pick up summary </value>
  </data>
  <data name="solicitar" xml:space="preserve">
    <value>Apply</value>
  </data>
  <data name="solicitud_recogida" xml:space="preserve">
    <value>Request Pick up</value>
  </data>
  <data name="solo_solicitar_recogida" xml:space="preserve">
    <value>Only pick up</value>
  </data>
  <data name="texto_importante_devoluciones" xml:space="preserve">
    <value>To avoid damage to the lenses and facilitate their analysis, they should be received in an appropriate package and separately without elements that can damage them like staples, cards, etc</value>
  </data>
  <data name="enviada" xml:space="preserve">
    <value>Sent</value>
  </data>
  <data name="mensaje_advertencia_espesor_ranura" xml:space="preserve">
    <value>IMPORTANT: The type of groove must always be checked to avoid incidents in the assembly. Remember:
Thickness min. Nylon groove = 1.5 mm
Thickness min. Metal groove = 2.2 mm
If you have any question, ask to our Customer Service department.</value>
  </data>
  <data name="domingo" xml:space="preserve">
    <value>Sunday</value>
  </data>
  <data name="jueves" xml:space="preserve">
    <value>Thursday</value>
  </data>
  <data name="lunes" xml:space="preserve">
    <value>Monday</value>
  </data>
  <data name="martes" xml:space="preserve">
    <value>Tuesday</value>
  </data>
  <data name="miercoles" xml:space="preserve">
    <value>Wednesday</value>
  </data>
  <data name="sabado" xml:space="preserve">
    <value>Saturday</value>
  </data>
  <data name="viernes" xml:space="preserve">
    <value>Friday</value>
  </data>
  <data name="alta_buscador" xml:space="preserve">
    <value>Search/Modification web browser</value>
  </data>
  <data name="anular_cambios" xml:space="preserve">
    <value>Cancel the changes made</value>
  </data>
  <data name="audiologia" xml:space="preserve">
    <value>Audiology</value>
  </data>
  <data name="aviso_eliminar_buscador_web" xml:space="preserve">
    <value>You are about to delete your data from our web search engine. By accepting it, you will stop appearing in the possible searches made by the users. Do you want to continue?</value>
  </data>
  <data name="baja_buscador" xml:space="preserve">
    <value>Unsubscribe on web browser</value>
  </data>
  <data name="baja_vision" xml:space="preserve">
    <value>Low vision</value>
  </data>
  <data name="buscador_web" xml:space="preserve">
    <value>Web browser</value>
  </data>
  <data name="de" xml:space="preserve">
    <value>to</value>
  </data>
  <data name="horario_comercial" xml:space="preserve">
    <value>Opening hours</value>
  </data>
  <data name="optometria" xml:space="preserve">
    <value>Optometry</value>
  </data>
  <data name="rellenar_con_datos_por_defecto" xml:space="preserve">
    <value>Fill with default database</value>
  </data>
  <data name="taller_de_montaje" xml:space="preserve">
    <value>Assembly shop</value>
  </data>
  <data name="terapia_visual" xml:space="preserve">
    <value>Visual therapy</value>
  </data>
  <data name="texto_autorizo_buscar_web" xml:space="preserve">
    <value>I authorize INDO OPTICAL SLU to store these data and keep them in its databases whose use is exclusively intended to appear in its optical search, in order to communicate with its customers</value>
  </data>
  <data name="venta_online" xml:space="preserve">
    <value>Online sales</value>
  </data>
  <data name="y_de" xml:space="preserve">
    <value>from</value>
  </data>
  <data name="informacion_contacto" xml:space="preserve">
    <value>Contact information</value>
  </data>
  <data name="informacion_optica" xml:space="preserve">
    <value>Optical shop information</value>
  </data>
  <data name="mensaje_entrada_buscador" xml:space="preserve">
    <value>Configure your data here to appear in the optical search of www.indo.es</value>
  </data>
  <data name="tooltip_boton_copiar" xml:space="preserve">
    <value>Copy from Monday to Saturday.</value>
  </data>
  <data name="ha_escogido_esta_devolucion" xml:space="preserve">
    <value>You have chosen this return</value>
  </data>
  <data name="configuracion_hojaPedidoIndoscan" xml:space="preserve">
    <value>IndoScan Form</value>
  </data>
  <data name="configuracion_link_hojaPedidosFax" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/Indonet/Enlaces/HojaPedidosFax_EN.pdf</value>
  </data>
  <data name="aviso_texto_no_copiar_pedido" xml:space="preserve">
    <value>IMPORTANT
The product you are requesting may have changed its offer from the date of the original order. Please check all fields and treatments to make sure the lenses you select are correct.</value>
  </data>
  <data name="selecciona_trazador" xml:space="preserve">
    <value>Select tracer</value>
  </data>
  <data name="selecciona_trazador_1" xml:space="preserve">
    <value>You have more than one Indobisel connected to INDONET. Please, select the tracer you want to connect to:</value>
  </data>
  <data name="no_albaran" xml:space="preserve">
    <value>Delivery Note</value>
  </data>
  <data name="no_factura" xml:space="preserve">
    <value>Invoice number</value>
  </data>
  <data name="url_ayuda_inset" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/ayudaIndonet/inset_GB.html</value>
  </data>
  <data name="ver_albaran" xml:space="preserve">
    <value>Download Delivery Note</value>
  </data>
  <data name="consulta_disponibilidad" xml:space="preserve">
    <value>Check availability</value>
  </data>
  <data name="marca_opcion_deseada" xml:space="preserve">
    <value>Check the desired option</value>
  </data>
  <data name="url_condiciones_indomedcare_new" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/ayudaIndonet/condiciones_indomedcare_EN.txt</value>
  </data>
  <data name="url_condiciones_indonet_new" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/ayudaIndonet/condiciones_indonet_EN.txt</value>
  </data>
  <data name="texto_presentacion10" xml:space="preserve">
    <value>Perform and control your returns.</value>
  </data>
  <data name="texto_presentacion11" xml:space="preserve">
    <value>Control and print your invoices with Indomanager.</value>
  </data>
  <data name="texto_presentacion8" xml:space="preserve">
    <value>With Indonet GO you can:</value>
  </data>
  <data name="texto_presentacion9" xml:space="preserve">
    <value>Track your orders more closely.</value>
  </data>
  <data name="url_ayuda_pedidorapidoclasico" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/ayudaIndonet/pedido_rapidoclasico_EN.txt</value>
  </data>
  <data name="degradado_alto" xml:space="preserve">
    <value>High gradient - 5mm</value>
  </data>
  <data name="degradado_bajo" xml:space="preserve">
    <value>Low gradient + 5mm</value>
  </data>
  <data name="texto_ex_bono" xml:space="preserve">
    <value>The requested treatment is excluded from bonuses and has an added cost of {0} per lens</value>
  </data>
  <data name="crear_abono" xml:space="preserve">
    <value>Create credit note</value>
  </data>
  <data name="crear_analisis" xml:space="preserve">
    <value>Create analysis</value>
  </data>
  <data name="selecciona_una_opcion" xml:space="preserve">
    <value>Select an option</value>
  </data>
  <data name="selecciona_un_motivo" xml:space="preserve">
    <value>Select a reason</value>
  </data>
  <data name="solicitar_abono" xml:space="preserve">
    <value>Request credit note</value>
  </data>
  <data name="solicitar_analisis_lentes" xml:space="preserve">
    <value>Request lens analysis</value>
  </data>
  <data name="solicito_abono_por" xml:space="preserve">
    <value>I’m requesting the lens analysis for...</value>
  </data>
  <data name="solicito_analisis_por" xml:space="preserve">
    <value>I’m requesting the credit note of the order for...</value>
  </data>
  <data name="pedido_urgente" xml:space="preserve">
    <value>Urgent order</value>
  </data>
  <data name="que_tipo_devolucion_quieres_realizar" xml:space="preserve">
    <value>What is the motive for returning the lenses?</value>
  </data>
  <data name="en_proceso" xml:space="preserve">
    <value>In process</value>
  </data>
  <data name="solicitar_devolucion_rodenstock" xml:space="preserve">
    <value>Activar Garantía Especial Rodenstock</value>
  </data>
  <data name="aviso_pedir_devolucionRD" xml:space="preserve">
    <value>Solicitar Garantía Especial Rodenstock</value>
  </data>
  <data name="texto_activar_bonificacion_rodenstock_checkbox" xml:space="preserve">
    <value>Activar Garantía Especial Rodenstock</value>
  </data>
  <data name="aviso_devolucion_mas_de_un_pedido_seleccionado" xml:space="preserve">
    <value>Ha seleccionado más de un pedido para devolución. Solamente se tratará el primer pedido. ¿Desea continuar?</value>
  </data>
  <data name="aviso_no_se_han_seleccionado_pedidos_aptos_para_devolucion" xml:space="preserve">
    <value>No se han seleccionado pedidos aptos para devolución</value>
  </data>
  <data name="aviso_opcion_valida_solo_desde_pedidosrealizados_solicitarabono" xml:space="preserve">
    <value>Esta opción sólo es válida desde Pedidos Realizados o Solicitar Abono</value>
  </data>
  <data name="seguro_actualizar_pedido" xml:space="preserve">
    <value>This calculation is not required to ship the order and may take some time.
Are you sure to continue?</value>
  </data>
  <data name="rodenstockmanager" xml:space="preserve">
    <value>RodenstockManager</value>
  </data>
  <data name="bienvenido_a_rodenstockmanager" xml:space="preserve">
    <value>Welcome to Rodenstock Manager</value>
  </data>
  <data name="url_fb_rodenstock" xml:space="preserve">
    <value>https://www.facebook.com/RodenstockES/</value>
  </data>
  <data name="url_in_rodenstock" xml:space="preserve">
    <value>https://www.instagram.com/rodenstock_es/</value>
  </data>
  <data name="url_indoloyalty" xml:space="preserve">
    <value>https://indoloyalty.com</value>
  </data>
  <data name="wells_pedido_sonae_obligatorio" xml:space="preserve">
    <value>In the Reference field, the Sonae order must be indicated</value>
  </data>
</root>