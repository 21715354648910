import router from '@/router';

class SessionService {
  constructor() {
    this.timeoutId = null;
    this.sessionDuration = 60 * 60 * 1000; // 1 hora en milisegundos
    this.lastActivity = Date.now();
    this.shouldTrackActivity = false;
  }

  startSessionTimer(store) {
    this.clearSessionTimer();
    this.lastActivity = Date.now();
    this.shouldTrackActivity = true;
    
    this.timeoutId = setInterval(() => {
      const now = Date.now();
      const timeSinceLastActivity = now - this.lastActivity;
      
      if (timeSinceLastActivity >= this.sessionDuration) {
        this.endSession(store);
      }
    }, 10000);
  }

  clearSessionTimer() {
    if (this.timeoutId) {
      clearInterval(this.timeoutId);
      this.timeoutId = null;
    }
    this.shouldTrackActivity = false;
  }

  async endSession(store) {
    try {
      this.clearSessionTimer();
      await store.dispatch('Logout');
      
      if (router.currentRoute.value.path !== '/login') {
        await router.push('/login');
      }
    } catch (error) {
      if (router.currentRoute.value.path !== '/login') {
        await router.push('/login');
      }
    }
  }

  resetTimer(store) {
    if (store.getters.isAuthenticated && this.shouldTrackActivity) {
      this.lastActivity = Date.now();
    }
  }

  isSessionExpired() {
    return Date.now() - this.lastActivity >= this.sessionDuration;
  }
}

export default new SessionService(); 