<?xml version="1.0" encoding="utf-8"?>
<root>
  <!-- 
    Microsoft ResX Schema 
    
    Version 2.0
    
    The primary goals of this format is to allow a simple XML format 
    that is mostly human readable. The generation and parsing of the 
    various data types are done through the TypeConverter classes 
    associated with the data types.
    
    Example:
    
    ... ado.net/XML headers & schema ...
    <resheader name="resmimetype">text/microsoft-resx</resheader>
    <resheader name="version">2.0</resheader>
    <resheader name="reader">System.Resources.ResXResourceReader, System.Windows.Forms, ...</resheader>
    <resheader name="writer">System.Resources.ResXResourceWriter, System.Windows.Forms, ...</resheader>
    <data name="Name1"><value>this is my long string</value><comment>this is a comment</comment></data>
    <data name="Color1" type="System.Drawing.Color, System.Drawing">Blue</data>
    <data name="Bitmap1" mimetype="application/x-microsoft.net.object.binary.base64">
        <value>[base64 mime encoded serialized .NET Framework object]</value>
    </data>
    <data name="Icon1" type="System.Drawing.Icon, System.Drawing" mimetype="application/x-microsoft.net.object.bytearray.base64">
        <value>[base64 mime encoded string representing a byte array form of the .NET Framework object]</value>
        <comment>This is a comment</comment>
    </data>
                
    There are any number of "resheader" rows that contain simple 
    name/value pairs.
    
    Each data row contains a name, and value. The row also contains a 
    type or mimetype. Type corresponds to a .NET class that support 
    text/value conversion through the TypeConverter architecture. 
    Classes that don't support this are serialized and stored with the 
    mimetype set.
    
    The mimetype is used for serialized objects, and tells the 
    ResXResourceReader how to depersist the object. This is currently not 
    extensible. For a given mimetype the value must be set accordingly:
    
    Note - application/x-microsoft.net.object.binary.base64 is the format 
    that the ResXResourceWriter will generate, however the reader can 
    read any of the formats listed below.
    
    mimetype: application/x-microsoft.net.object.binary.base64
    value   : The object must be serialized with 
            : System.Runtime.Serialization.Formatters.Binary.BinaryFormatter
            : and then encoded with base64 encoding.
    
    mimetype: application/x-microsoft.net.object.soap.base64
    value   : The object must be serialized with 
            : System.Runtime.Serialization.Formatters.Soap.SoapFormatter
            : and then encoded with base64 encoding.

    mimetype: application/x-microsoft.net.object.bytearray.base64
    value   : The object must be serialized into a byte array 
            : using a System.ComponentModel.TypeConverter
            : and then encoded with base64 encoding.
    -->
  <xsd:schema id="root" xmlns="" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:msdata="urn:schemas-microsoft-com:xml-msdata">
    <xsd:import namespace="http://www.w3.org/XML/1998/namespace" />
    <xsd:element name="root" msdata:IsDataSet="true">
      <xsd:complexType>
        <xsd:choice maxOccurs="unbounded">
          <xsd:element name="metadata">
            <xsd:complexType>
              <xsd:sequence>
                <xsd:element name="value" type="xsd:string" minOccurs="0" />
              </xsd:sequence>
              <xsd:attribute name="name" use="required" type="xsd:string" />
              <xsd:attribute name="type" type="xsd:string" />
              <xsd:attribute name="mimetype" type="xsd:string" />
              <xsd:attribute ref="xml:space" />
            </xsd:complexType>
          </xsd:element>
          <xsd:element name="assembly">
            <xsd:complexType>
              <xsd:attribute name="alias" type="xsd:string" />
              <xsd:attribute name="name" type="xsd:string" />
            </xsd:complexType>
          </xsd:element>
          <xsd:element name="data">
            <xsd:complexType>
              <xsd:sequence>
                <xsd:element name="value" type="xsd:string" minOccurs="0" msdata:Ordinal="1" />
                <xsd:element name="comment" type="xsd:string" minOccurs="0" msdata:Ordinal="2" />
              </xsd:sequence>
              <xsd:attribute name="name" type="xsd:string" use="required" msdata:Ordinal="1" />
              <xsd:attribute name="type" type="xsd:string" msdata:Ordinal="3" />
              <xsd:attribute name="mimetype" type="xsd:string" msdata:Ordinal="4" />
              <xsd:attribute ref="xml:space" />
            </xsd:complexType>
          </xsd:element>
          <xsd:element name="resheader">
            <xsd:complexType>
              <xsd:sequence>
                <xsd:element name="value" type="xsd:string" minOccurs="0" msdata:Ordinal="1" />
              </xsd:sequence>
              <xsd:attribute name="name" type="xsd:string" use="required" />
            </xsd:complexType>
          </xsd:element>
        </xsd:choice>
      </xsd:complexType>
    </xsd:element>
  </xsd:schema>
  <resheader name="resmimetype">
    <value>text/microsoft-resx</value>
  </resheader>
  <resheader name="version">
    <value>2.0</value>
  </resheader>
  <resheader name="reader">
    <value>System.Resources.ResXResourceReader, System.Windows.Forms, Version=2.0.0.0, Culture=neutral, PublicKeyToken=b77a5c561934e089</value>
  </resheader>
  <resheader name="writer">
    <value>System.Resources.ResXResourceWriter, System.Windows.Forms, Version=2.0.0.0, Culture=neutral, PublicKeyToken=b77a5c561934e089</value>
  </resheader>
  <data name="adicion" xml:space="preserve">
    <value>Adición</value>
    <comment>Pantalla de inicio, listado últimos pedidos</comment>
  </data>
  <data name="cilindro" xml:space="preserve">
    <value>Cilindro</value>
    <comment>Pantalla de inicio, listado últimos pedidos</comment>
  </data>
  <data name="configuracion" xml:space="preserve">
    <value>Configuración</value>
    <comment>Barra de Herramientas</comment>
  </data>
  <data name="eje" xml:space="preserve">
    <value>Eje</value>
    <comment>Pantalla de inicio, listado últimos pedidos</comment>
  </data>
  <data name="esfera" xml:space="preserve">
    <value>Esfera</value>
    <comment>Pantalla de inicio, listado últimos pedidos</comment>
  </data>
  <data name="estado" xml:space="preserve">
    <value>Estado</value>
    <comment>Pantalla de inicio, listado últimos pedidos</comment>
  </data>
  <data name="fecha" xml:space="preserve">
    <value>Fecha</value>
    <comment>Pantalla de inicio, listado últimos pedidos</comment>
  </data>
  <data name="inicio" xml:space="preserve">
    <value>Inicio</value>
    <comment>Barra de navegación principal</comment>
  </data>
  <data name="lente" xml:space="preserve">
    <value>Lente</value>
    <comment>Pantalla de inicio, listado últimos pedidos</comment>
  </data>
  <data name="logout" xml:space="preserve">
    <value>Log Out</value>
    <comment>Barra de Herramientas</comment>
  </data>
  <data name="noticias" xml:space="preserve">
    <value>Noticias</value>
    <comment>Pantalla de incio</comment>
  </data>
  <data name="nueva_prevision" xml:space="preserve">
    <value>Nueva previsión</value>
    <comment>Pantalla de inicio, listado últimos pedidos</comment>
  </data>
  <data name="n_pedido" xml:space="preserve">
    <value>Nº pedido</value>
    <comment>Pantalla de inicio, listado últimos pedidos</comment>
  </data>
  <data name="realizar_pedido" xml:space="preserve">
    <value>Realizar Pedido</value>
    <comment>Barra de navegación principal</comment>
  </data>
  <data name="prevision" xml:space="preserve">
    <value>Previsión</value>
    <comment>Pantalla de inicio, listado últimos pedidos</comment>
  </data>
  <data name="pedidos_realizados" xml:space="preserve">
    <value>Pedidos Realizados</value>
    <comment>Barra de navegación principal</comment>
  </data>
  <data name="recetas_guardadas" xml:space="preserve">
    <value>Recetas Guardadas</value>
    <comment>Barra de navegación principal</comment>
  </data>
  <data name="ref_cliente" xml:space="preserve">
    <value>Ref.Cliente</value>
    <comment>Pantalla de inicio, listado últimos pedidos</comment>
  </data>
  <data name="rx" xml:space="preserve">
    <value>RX</value>
    <comment>Pantalla de inicio, listado últimos pedidos</comment>
  </data>
  <data name="sugerencias" xml:space="preserve">
    <value>Sugerencias</value>
    <comment>Barra de Herramientas</comment>
  </data>
  <data name="tutoriales" xml:space="preserve">
    <value>Tutoriales</value>
    <comment>Barra de Herramientas</comment>
  </data>
  <data name="ultimos_pedidos" xml:space="preserve">
    <value>Últimos pedidos</value>
    <comment>Pantalla de inicio</comment>
  </data>
  <data name="zona_de_identificacion" xml:space="preserve">
    <value>Zona de identificación</value>
    <comment>Barra de Herramientas</comment>
  </data>
  <data name="datos_optica" xml:space="preserve">
    <value>Datos Optica</value>
    <comment>Barra de navegación principal</comment>
  </data>
  <data name="datos_basicos" xml:space="preserve">
    <value>Datos Básicos</value>
    <comment>Pantalla de Pedido</comment>
  </data>
  <data name="formas_y_espesores" xml:space="preserve">
    <value>Formas y Espesores</value>
    <comment>Pantalla de Pedido</comment>
  </data>
  <data name="monturas" xml:space="preserve">
    <value>Monturas</value>
    <comment>Pantalla de Pedido</comment>
  </data>
  <data name="cancelar_pedido" xml:space="preserve">
    <value>Cancelar pedido</value>
    <comment>Botonera Acciones del pedido</comment>
  </data>
  <data name="enviar" xml:space="preserve">
    <value>Enviar</value>
    <comment>Botonera Acciones del pedido</comment>
  </data>
  <data name="enviar_y_revision" xml:space="preserve">
    <value>Enviar y revisión</value>
    <comment>Botonera Acciones del pedido</comment>
  </data>
  <data name="guardar_receta" xml:space="preserve">
    <value>Guardar receta</value>
    <comment>Botonera Acciones del pedido</comment>
  </data>
  <data name="imprimir" xml:space="preserve">
    <value>Imprimir</value>
    <comment>Botonera Acciones del pedido</comment>
  </data>
  <data name="avisos" xml:space="preserve">
    <value>Avisos</value>
    <comment>Pantalla de Pedido</comment>
  </data>
  <data name="OD" xml:space="preserve">
    <value>OD</value>
    <comment>Ojo derecho</comment>
  </data>
  <data name="OI" xml:space="preserve">
    <value>OI</value>
    <comment>Ojo Izquierdo</comment>
  </data>
  <data name="diametro" xml:space="preserve">
    <value>Diámetro</value>
    <comment>Vista datos básicos ojo</comment>
  </data>
  <data name="d_especial" xml:space="preserve">
    <value>D. especial</value>
    <comment>Vista datos básicos ojo</comment>
  </data>
  <data name="foco_y_material" xml:space="preserve">
    <value>Foco y Material</value>
    <comment>Vista datos básicos ojo</comment>
  </data>
  <data name="indice" xml:space="preserve">
    <value>Indice</value>
    <comment>Vista datos básicos ojo</comment>
  </data>
  <data name="mostrar_lentes_stock" xml:space="preserve">
    <value>Mostrar sólo lentes de stock</value>
    <comment>Vista datos básicos ojo</comment>
  </data>
  <data name="ojo_informativo" xml:space="preserve">
    <value>Ojo informativo</value>
    <comment>Vista datos básicos ojo</comment>
  </data>
  <data name="tratamientos" xml:space="preserve">
    <value>Tratamientos</value>
    <comment>Vista datos básicos ojo</comment>
  </data>
  <data name="tto_adicional" xml:space="preserve">
    <value>Tto. Adicional</value>
    <comment>Vista datos básicos ojo</comment>
  </data>
  <data name="color" xml:space="preserve">
    <value>Color</value>
    <comment>Pantalla de Pedido</comment>
  </data>
  <data name="coloracion" xml:space="preserve">
    <value>Coloración</value>
    <comment>Pantalla de Pedido</comment>
  </data>
  <data name="fotocromatico" xml:space="preserve">
    <value>Fotocromático</value>
    <comment>Pantalla de Pedido</comment>
  </data>
  <data name="referencia" xml:space="preserve">
    <value>Referencia</value>
    <comment>Pantalla de Pedido</comment>
  </data>
  <data name="tono" xml:space="preserve">
    <value>Tono</value>
    <comment>Pantalla de Pedido</comment>
  </data>
  <data name="altura" xml:space="preserve">
    <value>Altura</value>
    <comment>Datos técnicos</comment>
  </data>
  <data name="angulo" xml:space="preserve">
    <value>Ángulo</value>
    <comment>Datos técnicos</comment>
  </data>
  <data name="base" xml:space="preserve">
    <value>Base</value>
    <comment>Datos técnicos</comment>
  </data>
  <data name="espesor" xml:space="preserve">
    <value>Espesor</value>
    <comment>Datos técnicos</comment>
  </data>
  <data name="espesor_centro" xml:space="preserve">
    <value>Espesor centro</value>
    <comment>Datos técnicos</comment>
  </data>
  <data name="espesor_maximo" xml:space="preserve">
    <value>Espesor máximo</value>
    <comment>Datos técnicos</comment>
  </data>
  <data name="espesor_minimo" xml:space="preserve">
    <value>Espesor mínimo</value>
    <comment>Datos técnicos</comment>
  </data>
  <data name="espesor_taladro_nasal" xml:space="preserve">
    <value>Espesor taladro nasal</value>
    <comment>Datos técnicos</comment>
  </data>
  <data name="espesor_taladro_temporal" xml:space="preserve">
    <value>Espesor taladro temporal</value>
    <comment>Datos técnicos</comment>
  </data>
  <data name="ganancia_espesor" xml:space="preserve">
    <value>Ganancia en espesor</value>
    <comment>Datos técnicos</comment>
  </data>
  <data name="ganancia_peso" xml:space="preserve">
    <value>Ganancia en peso</value>
    <comment>Datos técnicos</comment>
  </data>
  <data name="nd" xml:space="preserve">
    <value>Nd</value>
    <comment>Datos técnicos</comment>
  </data>
  <data name="n_abbe" xml:space="preserve">
    <value>Nº Abbe</value>
    <comment>Datos técnicos</comment>
  </data>
  <data name="peso" xml:space="preserve">
    <value>Peso</value>
    <comment>Datos técnicos</comment>
  </data>
  <data name="radio" xml:space="preserve">
    <value>Radio</value>
    <comment>Datos técnicos</comment>
  </data>
  <data name="t_por_ciento" xml:space="preserve">
    <value>T%</value>
    <comment>Datos técnicos</comment>
  </data>
  <data name="datos_tecnicos" xml:space="preserve">
    <value>Datos técnicos</value>
  </data>
  <data name="altura_montaje" xml:space="preserve">
    <value>Altura montaje</value>
    <comment>Datos Adicionales</comment>
  </data>
  <data name="angulo_de_envolvencia" xml:space="preserve">
    <value>Ángulo de envolvencia</value>
    <comment>Datos Adicionales</comment>
  </data>
  <data name="base_especial" xml:space="preserve">
    <value>Base especial</value>
    <comment>Suplementos</comment>
  </data>
  <data name="calibre" xml:space="preserve">
    <value>Calibre</value>
    <comment>Datos Adicionales</comment>
  </data>
  <data name="datos_adicionales" xml:space="preserve">
    <value>Datos adicionales</value>
    <comment>Datos Básicos</comment>
  </data>
  <data name="descentramiento_nasal" xml:space="preserve">
    <value>Descentramiento nasal</value>
    <comment>Suplementos</comment>
  </data>
  <data name="dioptrias" xml:space="preserve">
    <value>Dioptrías</value>
    <comment>Suplementos</comment>
  </data>
  <data name="distancia" xml:space="preserve">
    <value>Distancia</value>
    <comment>Datos Adicionales</comment>
  </data>
  <data name="distancia_trabajo" xml:space="preserve">
    <value>Distancia de trabajo</value>
    <comment>Datos Adicionales</comment>
  </data>
  <data name="dnp" xml:space="preserve">
    <value>DNP</value>
    <comment>Datos Adicionales</comment>
  </data>
  <data name="grados" xml:space="preserve">
    <value>Grados</value>
    <comment>Suplementos</comment>
  </data>
  <data name="prisma_1" xml:space="preserve">
    <value>Prisma 1</value>
    <comment>Suplementos</comment>
  </data>
  <data name="prisma_2" xml:space="preserve">
    <value>Prisma 2</value>
    <comment>Suplementos</comment>
  </data>
  <data name="puente" xml:space="preserve">
    <value>Puente</value>
    <comment>Datos Adicionales</comment>
  </data>
  <data name="suplementos" xml:space="preserve">
    <value>Suplementos</value>
    <comment>Datos Básicos</comment>
  </data>
  <data name="acceso_indonet" xml:space="preserve">
    <value>Acceso:</value>
    <comment>Pantalla de login</comment>
  </data>
  <data name="clave_acceso" xml:space="preserve">
    <value>Clave de acceso</value>
    <comment>Pantalla de login</comment>
  </data>
  <data name="codigo_cliente" xml:space="preserve">
    <value>Código de cliente</value>
    <comment>Pantalla de login</comment>
  </data>
  <data name="contacta_con_indo" xml:space="preserve">
    <value>Contacta</value>
    <comment>Pantalla de login</comment>
  </data>
  <data name="entrar" xml:space="preserve">
    <value>Entrar</value>
    <comment>Pantalla de login</comment>
  </data>
  <data name="idioma" xml:space="preserve">
    <value>Idioma</value>
    <comment>Pantalla de login</comment>
  </data>
  <data name="text_info_contacto" xml:space="preserve">
    <value>Si necesitas más información, contacta con nosotros en :</value>
    <comment>Pantalla de login</comment>
  </data>
  <data name="base_de_datos_de_marcas" xml:space="preserve">
    <value>Base de datos de marcas</value>
    <comment>ComboBox de formas</comment>
  </data>
  <data name="base_de_datos_de_mis_biselados" xml:space="preserve">
    <value>Mis biselados</value>
    <comment>ComboBox de formas</comment>
  </data>
  <data name="basicas" xml:space="preserve">
    <value>Básicas</value>
    <comment>ComboBox de formas</comment>
  </data>
  <data name="biselador_remoto" xml:space="preserve">
    <value>Biselador remoto</value>
    <comment>ComboBox de formas</comment>
  </data>
  <data name="ninguna" xml:space="preserve">
    <value>Ninguna</value>
  </data>
  <data name="altura_boxing" xml:space="preserve">
    <value>Altura boxing</value>
  </data>
  <data name="altura_de_montaje" xml:space="preserve">
    <value>Altura de montaje</value>
  </data>
  <data name="marca" xml:space="preserve">
    <value>Marca</value>
  </data>
  <data name="modelo" xml:space="preserve">
    <value>Modelo</value>
  </data>
  <data name="orientacion_del_bisel" xml:space="preserve">
    <value>Orientacion del bisel</value>
  </data>
  <data name="tipo_de_bisel" xml:space="preserve">
    <value>Tipo de bisel</value>
  </data>
  <data name="tipo_de_montura" xml:space="preserve">
    <value>Tipo de montura</value>
  </data>
  <data name="aceptar" xml:space="preserve">
    <value>Aceptar</value>
  </data>
  <data name="asunto" xml:space="preserve">
    <value>Asunto</value>
  </data>
  <data name="cancelar" xml:space="preserve">
    <value>Cancelar</value>
  </data>
  <data name="email" xml:space="preserve">
    <value>Email</value>
  </data>
  <data name="mensaje" xml:space="preserve">
    <value>Mensaje</value>
  </data>
  <data name="error_email_direccion_invalida" xml:space="preserve">
    <value>La dirección de email no esta bien</value>
    <comment>Errores</comment>
  </data>
  <data name="error_email_falta_asunto" xml:space="preserve">
    <value>El asunto es obligatorio</value>
    <comment>Errores</comment>
  </data>
  <data name="error_email_falta_cuerpo" xml:space="preserve">
    <value>El mensaje es olbigatorio</value>
    <comment>Errores</comment>
  </data>
  <data name="error_email_falta_direccion" xml:space="preserve">
    <value>La dirección de email es obligatoría</value>
    <comment>Errores</comment>
  </data>
  <data name="solicitar_acceso" xml:space="preserve">
    <value>Solicitar acceso</value>
  </data>
  <data name="forma_escogida" xml:space="preserve">
    <value>Forma escogida</value>
  </data>
  <data name="formas_leidas" xml:space="preserve">
    <value>Formas leidas</value>
  </data>
  <data name="fecha_rango" xml:space="preserve">
    <value>Fecha (rango de 7 días)</value>
  </data>
  <data name="intervalo_de_fechas" xml:space="preserve">
    <value>Intervalo de fechas</value>
  </data>
  <data name="numero_pedido" xml:space="preserve">
    <value>Nº pedido</value>
  </data>
  <data name="producto_lente" xml:space="preserve">
    <value>Producto (lente)</value>
  </data>
  <data name="a" xml:space="preserve">
    <value>a</value>
  </data>
  <data name="buscar" xml:space="preserve">
    <value>Buscar</value>
  </data>
  <data name="imprimir_lista" xml:space="preserve">
    <value>Imprimir listado</value>
  </data>
  <data name="hora" xml:space="preserve">
    <value>Hora</value>
  </data>
  <data name="calibre_completo" xml:space="preserve">
    <value>Calibre completo</value>
  </data>
  <data name="espesores" xml:space="preserve">
    <value>Espesores</value>
  </data>
  <data name="espesor_borde_especial" xml:space="preserve">
    <value>Espesor borde especial</value>
  </data>
  <data name="inferior" xml:space="preserve">
    <value>Inferior</value>
  </data>
  <data name="precalibrado_a_cotas" xml:space="preserve">
    <value>Precalibrado a cotas</value>
  </data>
  <data name="precalibrado_digital" xml:space="preserve">
    <value>Precalibrado digital</value>
  </data>
  <data name="redimensionar" xml:space="preserve">
    <value>Redimensionar</value>
  </data>
  <data name="sin_precalibrado" xml:space="preserve">
    <value>Sin precalibrado</value>
  </data>
  <data name="superior" xml:space="preserve">
    <value>Superior</value>
  </data>
  <data name="temporal" xml:space="preserve">
    <value>Temporal</value>
  </data>
  <data name="tipo_bisel" xml:space="preserve">
    <value>Tipo bisel</value>
  </data>
  <data name="actualizar" xml:space="preserve">
    <value>Actualizar</value>
  </data>
  <data name="resto_de_paises" xml:space="preserve">
    <value>Resto de países</value>
    <comment>Login</comment>
  </data>
  <data name="solo_stock" xml:space="preserve">
    <value>Sólo stock</value>
  </data>
  <data name="abertura" xml:space="preserve">
    <value>Abertura</value>
  </data>
  <data name="biselar_lente" xml:space="preserve">
    <value>Biselar lente</value>
  </data>
  <data name="modificar_espesor" xml:space="preserve">
    <value>Modificar espesor</value>
  </data>
  <data name="seguro_que_quiere_desconectarse" xml:space="preserve">
    <value>Está seguro que quiere desconectarse?</value>
    <comment>Alerts</comment>
  </data>
  <data name="pedidos_al_por_mayor" xml:space="preserve">
    <value>Pedidos al por mayor</value>
  </data>
  <data name="situacion_de_los_pedidos" xml:space="preserve">
    <value>Situación de los pedidos</value>
  </data>
  <data name="salir" xml:space="preserve">
    <value>Salir</value>
  </data>
  <data name="detalle_estado_pedido" xml:space="preserve">
    <value>Detalle del estado de pedido</value>
  </data>
  <data name="expedido" xml:space="preserve">
    <value>Expedido</value>
  </data>
  <data name="finalizado" xml:space="preserve">
    <value>Finalizado</value>
  </data>
  <data name="montaje" xml:space="preserve">
    <value>Montaje</value>
  </data>
  <data name="talla" xml:space="preserve">
    <value>Talla</value>
  </data>
  <data name="selecciona" xml:space="preserve">
    <value>Selecciona</value>
  </data>
  <data name="selecciona_abrv" xml:space="preserve">
    <value>Selec.</value>
  </data>
  <data name="error_abriendo_base_de_datos" xml:space="preserve">
    <value>Se ha producido un error abriendo la base de datos</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_actualizando_fecha_oferta" xml:space="preserve">
    <value>Se ha producido un error actualizando la fecha de la oferta en la base de datos</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_actualizando_oferta" xml:space="preserve">
    <value>Se ha producido un error actualizando la oferta</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="oferta" xml:space="preserve">
    <value>Oferta</value>
  </data>
  <data name="registros" xml:space="preserve">
    <value>Registros</value>
  </data>
  <data name="se_actualizo" xml:space="preserve">
    <value>Se actualizó</value>
  </data>
  <data name="error_base_de_datos_no_abierta" xml:space="preserve">
    <value>La base de datos debe estar abierta</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_caracteres_no_permitidos_clave_acceso" xml:space="preserve">
    <value>Caracteres no permitidos en la clave de acceso</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_clave_acceso_no_permitida" xml:space="preserve">
    <value>Clave de acceso no permitida</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_clave_incorrecta" xml:space="preserve">
    <value>La clave indicada es incorrecta</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_base_de_datos" xml:space="preserve">
    <value>Se ha producido una excepcion con la base de datos local</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_claves_no_coinciden" xml:space="preserve">
    <value>Las claves de acceso no coinciden</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_enviando_mail" xml:space="preserve">
    <value>Se ha producido un error enviando email</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_login" xml:space="preserve">
    <value>Se ha producido un error durante el login</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_guardando_oferta" xml:space="preserve">
    <value>Se ha producido un error guardando la oferta</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_leyendo_fichero_base_de_datos" xml:space="preserve">
    <value>Se ha producido un error leyendo el fichero de la base de datos</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_busqueda_inicial_pedidos" xml:space="preserve">
    <value>Se ha producido un error durante la busqueda inicial de pedidos</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_caracteres_diferentes_clave_acceso" xml:space="preserve">
    <value>Caracteres diferentes en la clave de acceso</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_introducir_nueva_clave" xml:space="preserve">
    <value>Introducir nueva clave de acceso</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_signo_interrogacion_no_permitido_clave_acceso" xml:space="preserve">
    <value>Signo de interrogación no permitido en la clave de acceso</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_usuario_bloqueado" xml:space="preserve">
    <value>El usuario está bloqueado</value>
  </data>
  <data name="error_usuario_no_existe" xml:space="preserve">
    <value>El usuario no existe</value>
  </data>
  <data name="agente_trazador" xml:space="preserve">
    <value>Agente trazador</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="codigo_de_retorno" xml:space="preserve">
    <value>Código de retorno: </value>
  </data>
  <data name="error_autenticar_usuario" xml:space="preserve">
    <value>No se ha podido autenticar el usuario</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_cambiando_password" xml:space="preserve">
    <value>No se ha podido cambiar la password</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_conexion_agente_trazador" xml:space="preserve">
    <value>No hay conexión con el agente del trazador</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_excepcion_autenticando_usuario" xml:space="preserve">
    <value>Se ha producido una excepción autenticando el usuario</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_excepcion_obtenido_el_area_venta" xml:space="preserve">
    <value>Se ha producido una excepción obteniendo el área de venta</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_excepcion_producida" xml:space="preserve">
    <value>Se ha producido una excepción</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_exceso_numero_intentos_permitidos" xml:space="preserve">
    <value>Se ha excedido el número de intentos permitidos</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_iniciando_aplicacion" xml:space="preserve">
    <value>Se ha producido un error iniciando la aplicación</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_no_se_han_obtenido_datos_configuracion_cliente" xml:space="preserve">
    <value>No se han podido obtener los datos de configuración del cliente</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_no_se_ha_obtenido_el_area_venta" xml:space="preserve">
    <value>No se ha podido obtener el area de venta</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_obteniendo_los_datos_configuracion_del_cliente" xml:space="preserve">
    <value>Se ha producido una excepción obteniendo los datos de configuración</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_proceso_login" xml:space="preserve">
    <value>Se ha producido un error en el proceso de login</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_reintentos_excedidos" xml:space="preserve">
    <value>Reintentos excedidos</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_tipo_de_base_de_datos_desconocida" xml:space="preserve">
    <value>Tipo de base de datos desconocido</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="correo_solicitud_acceso" xml:space="preserve">
    <value>Correo solicitud acceso</value>
  </data>
  <data name="error_enviando_correo" xml:space="preserve">
    <value>No se ha podido enviar el correo</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_excepcion_enviando_correo" xml:space="preserve">
    <value>Se ha producido una excepción enviando correo</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="solicitud_acceso" xml:space="preserve">
    <value>Solicitud Acceso</value>
  </data>
  <data name="error_actualizando_pedido" xml:space="preserve">
    <value>***&gt; No se ha podido actualizar el pedido.</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_excepcion_copando_clase_pedidos" xml:space="preserve">
    <value>Se ha producido una excepción copiando la clase pedidos</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_excepcion_enviando_pedido" xml:space="preserve">
    <value>Se ha producido una excepción enviando el pedido</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_excepcion_enviando_pedido_para_comparacion" xml:space="preserve">
    <value>Se ha producido una excepción enviando el pedido para comparación</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_excepcion_obteniendo_pedidos_busqueda" xml:space="preserve">
    <value>Se ha producido una excepción obteniendo lista de pedidos (busqueda)</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_excepcion_obteniendo_pedidos_inicial" xml:space="preserve">
    <value>Se ha producido una excepción obteniendo lista de pedidos (inicial)</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_excepcion_recuperando_oferta" xml:space="preserve">
    <value>Se ha producido una excepción recuperando la oferta</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_grabando_pedido" xml:space="preserve">
    <value>***&gt; No se ha podido grabar el pedido</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_no_se_ha_podido_recuperar_lista_pedidos" xml:space="preserve">
    <value>***&gt;No se ha podido recuperar la lista con los pedidos.</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_no_se_ha_podido_recuperar_oferta" xml:space="preserve">
    <value>***&gt;No se ha podido recuperar la oferta.</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_recuperando_pedido" xml:space="preserve">
    <value>***&gt; No se ha podido recuperar el pedido</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_servicio_cambio_de_password" xml:space="preserve">
    <value>El servicio 'Cambio de Password' ha dado un error</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_servicio_envio_correo" xml:space="preserve">
    <value>El servicio 'Envio Correo' ha dado un error</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_servicio_login" xml:space="preserve">
    <value>El servicio 'Login' ha dado un error</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="estadoOD" xml:space="preserve">
    <value>estado OD</value>
  </data>
  <data name="estadoOI" xml:space="preserve">
    <value>estado OI</value>
  </data>
  <data name="es_anterior_a" xml:space="preserve">
    <value>es anterior a</value>
  </data>
  <data name="no_se_actualiza_la_oferta" xml:space="preserve">
    <value>No se actualiza la oferta</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="no_se_ha_podido_enviar_la_solucitud" xml:space="preserve">
    <value>No se ha podido enviar la solicitud, intentelo más tarde</value>
    <comment>popupMail</comment>
  </data>
  <data name="solicitud_enviada_correctamente" xml:space="preserve">
    <value>La solicitud de acceso ha sido enviada correctamente</value>
    <comment>popupMail</comment>
  </data>
  <data name="enviar_sugerencias" xml:space="preserve">
    <value>Enviar sugerencias</value>
  </data>
  <data name="aviso_cancelar_pedido" xml:space="preserve">
    <value>¿Estás seguro que quieres cancelar el pedido? se perderan todos los datos</value>
    <comment>Avisos</comment>
  </data>
  <data name="biselar_sin_pulir" xml:space="preserve">
    <value>Biselar sin pulir</value>
  </data>
  <data name="advertencia" xml:space="preserve">
    <value>Advertencia</value>
  </data>
  <data name="error_conexion_servicio" xml:space="preserve">
    <value>No hay conexión con el servidor</value>
  </data>
  <data name="d_esp" xml:space="preserve">
    <value>D.Esp</value>
  </data>
  <data name="forma" xml:space="preserve">
    <value>Forma</value>
  </data>
  <data name="siguiente" xml:space="preserve">
    <value>Siguiente</value>
  </data>
  <data name="seleccion_de_espesores" xml:space="preserve">
    <value>Selección de espesores</value>
  </data>
  <data name="atras" xml:space="preserve">
    <value>Atrás</value>
  </data>
  <data name="espesor_borde_esp" xml:space="preserve">
    <value>Espesor borde esp.</value>
  </data>
  <data name="tipo_montura" xml:space="preserve">
    <value>Tipo montura</value>
  </data>
  <data name="cota_inferior" xml:space="preserve">
    <value>Cota inferior</value>
  </data>
  <data name="cota_superior" xml:space="preserve">
    <value>Cota superior</value>
  </data>
  <data name="nasal" xml:space="preserve">
    <value>Nasal</value>
  </data>
  <data name="error_recuperando_monturas" xml:space="preserve">
    <value>Error recuperando monturas</value>
  </data>
  <data name="enviar_pedido" xml:space="preserve">
    <value>Enviar pedido</value>
  </data>
  <data name="paso1" xml:space="preserve">
    <value>Paso 1</value>
  </data>
  <data name="paso2" xml:space="preserve">
    <value>Paso 2</value>
  </data>
  <data name="continuar" xml:space="preserve">
    <value>Continuar</value>
  </data>
  <data name="confirma_destino_mercancia" xml:space="preserve">
    <value>Confirma el destinatario de la mercancía</value>
  </data>
  <data name="fecha_prevista_envio" xml:space="preserve">
    <value>Fecha prevista de salida</value>
  </data>
  <data name="observaciones" xml:space="preserve">
    <value>Observaciones</value>
  </data>
  <data name="ojo_derecho" xml:space="preserve">
    <value>Ojo derecho</value>
    <comment>Enviar pedido</comment>
  </data>
  <data name="ojo_izquierdo" xml:space="preserve">
    <value>Ojo izquierdo</value>
    <comment>Enviar pedido</comment>
  </data>
  <data name="por_favor_confirma_datos_pedido" xml:space="preserve">
    <value>Por favor, confirma los datos de tu pedido:</value>
    <comment>Enviar pedido</comment>
  </data>
  <data name="precio" xml:space="preserve">
    <value>Precio</value>
  </data>
  <data name="fecha_hora_grabacion" xml:space="preserve">
    <value>Fecha y hora de grabación</value>
  </data>
  <data name="informacion" xml:space="preserve">
    <value>Información</value>
  </data>
  <data name="info_pedido_procesado" xml:space="preserve">
    <value>Tu pedido con referencia {0} ha sido grabado y se ha procesado correctamente</value>
  </data>
  <data name="error_busqueda_pedidos" xml:space="preserve">
    <value>Error en la búsqueda de pedidos</value>
  </data>
  <data name="error_busqueda_recetas" xml:space="preserve">
    <value>Error en la búsqueda de recetas</value>
  </data>
  <data name="automatico13_23" xml:space="preserve">
    <value>Automático (1/3-2/3)</value>
    <comment>Orientacion del bisel</comment>
  </data>
  <data name="bisel" xml:space="preserve">
    <value>Bisel normal</value>
    <comment>tipo de bisel</comment>
  </data>
  <data name="centrado12_12" xml:space="preserve">
    <value>Centrado (1/2-1/2)</value>
    <comment>Orientacion del bisel</comment>
  </data>
  <data name="frontal" xml:space="preserve">
    <value>Frontal</value>
    <comment>Orientacion del bisel</comment>
  </data>
  <data name="l_taladrada" xml:space="preserve">
    <value>Plano</value>
    <comment>Tipo de bisel</comment>
  </data>
  <data name="mini_bisel" xml:space="preserve">
    <value>Minibisel</value>
    <comment>Tipo de bisel</comment>
  </data>
  <data name="ranurada_metal" xml:space="preserve">
    <value>Ranurada Metal</value>
    <comment>Tipo de bisel</comment>
  </data>
  <data name="ranurada_nylon" xml:space="preserve">
    <value>Ranurada Nylon</value>
    <comment>Tipo de bisel</comment>
  </data>
  <data name="sin_coloracion" xml:space="preserve">
    <value>Sin coloración</value>
  </data>
  <data name="sin_fotocromatico" xml:space="preserve">
    <value>Sin fotocromático</value>
  </data>
  <data name="fecha_hi_menor_low" xml:space="preserve">
    <value>La fecha "hasta" no puede ser anterior a "desde"</value>
  </data>
  <data name="fecha_hi_nula" xml:space="preserve">
    <value>La fecha "hasta" no puede ser nula</value>
  </data>
  <data name="fecha_low_nula" xml:space="preserve">
    <value>La fecha "desde" no puede ser nula</value>
  </data>
  <data name="verifique_conexion" xml:space="preserve">
    <value>Verifique la conexión con Internet</value>
  </data>
  <data name="error_recuperando_receta" xml:space="preserve">
    <value>***&gt; No se ha podido recuperar la receta</value>
  </data>
  <data name="pedido_con_errores" xml:space="preserve">
    <value>No se puede enviar el pedido. Revise las alertas</value>
    <comment>Alerts</comment>
  </data>
  <data name="espesor_max" xml:space="preserve">
    <value>Espesor máx.</value>
  </data>
  <data name="espesor_min" xml:space="preserve">
    <value>Espesor mín.</value>
  </data>
  <data name="texto" xml:space="preserve">
    <value>Texto</value>
  </data>
  <data name="trazador" xml:space="preserve">
    <value>Trazador</value>
  </data>
  <data name="acetato" xml:space="preserve">
    <value>Acetato</value>
    <comment>tipo de montura</comment>
  </data>
  <data name="metal" xml:space="preserve">
    <value>Metal</value>
    <comment>tipo de montura</comment>
  </data>
  <data name="ranurada" xml:space="preserve">
    <value>Ranurada</value>
    <comment>tipo de montura</comment>
  </data>
  <data name="taladrada" xml:space="preserve">
    <value>Taladrada</value>
    <comment>tipo de montura</comment>
  </data>
  <data name="el_valor_maximo_es" xml:space="preserve">
    <value>El valor máximo es</value>
    <comment>Validaciones</comment>
  </data>
  <data name="no_esta_bien_formado" xml:space="preserve">
    <value>No esta bien formado</value>
    <comment>Validaciones</comment>
  </data>
  <data name="no_es_multiplo_de" xml:space="preserve">
    <value>No es múltiplo de</value>
    <comment>Validaciones</comment>
  </data>
  <data name="seguro_que_quiere_salir" xml:space="preserve">
    <value>Está seguro que quiere salir de la aplicación?</value>
    <comment>Alerts</comment>
  </data>
  <data name="dnp_de_cerca" xml:space="preserve">
    <value>DNP de cerca</value>
  </data>
  <data name="aviso_desactivar_solo_stock" xml:space="preserve">
    <value>Si desea esta opción desactive la opción Sólo Stock</value>
  </data>
  <data name="adicion_abrv" xml:space="preserve">
    <value>AD</value>
    <comment>Cabecera listado pedidos</comment>
  </data>
  <data name="cilindro_abrv" xml:space="preserve">
    <value>CIL</value>
    <comment>Cabecera listado pedidos</comment>
  </data>
  <data name="eje_abrv" xml:space="preserve">
    <value>EJE</value>
    <comment>Cabecera listado pedidos</comment>
  </data>
  <data name="esfera_abrv" xml:space="preserve">
    <value>ESF</value>
    <comment>Cabecera listado pedidos</comment>
  </data>
  <data name="nueva_prev" xml:space="preserve">
    <value>Nueva Prev.</value>
    <comment>Cabecera listado pedidos</comment>
  </data>
  <data name="trazador_busqueda_ordenadores_red" xml:space="preserve">
    <value>Seleccionar el equipo en la red</value>
  </data>
  <data name="condiciones" xml:space="preserve">
    <value>Condiciones</value>
  </data>
  <data name="datosoptica_codigo_postal" xml:space="preserve">
    <value>Código postal :</value>
  </data>
  <data name="datosoptica_comunicaciones" xml:space="preserve">
    <value>Quiero recibir comunicaciones periódicas de Indo</value>
  </data>
  <data name="datosoptica_corregir_datos" xml:space="preserve">
    <value>Si tus datos no son correctos entra en el formulario de contacto del menu superior de INDONET</value>
  </data>
  <data name="datosoptica_direccion" xml:space="preserve">
    <value>Dirección :</value>
  </data>
  <data name="datosoptica_email" xml:space="preserve">
    <value>E-mail :</value>
  </data>
  <data name="datosoptica_enlace_condiciones" xml:space="preserve">
    <value>Leer condiciones de privacidad y protección de datos</value>
  </data>
  <data name="datosoptica_fax" xml:space="preserve">
    <value>Fax :</value>
  </data>
  <data name="datosoptica_logotipo_optica" xml:space="preserve">
    <value>Logotipo óptica</value>
  </data>
  <data name="datosoptica_nif" xml:space="preserve">
    <value>NIF :</value>
  </data>
  <data name="datosoptica_nombre" xml:space="preserve">
    <value>Nombre :</value>
  </data>
  <data name="datosoptica_poblacion" xml:space="preserve">
    <value>Población :</value>
  </data>
  <data name="datosoptica_telefono" xml:space="preserve">
    <value>Teléfono :</value>
  </data>
  <data name="explorar" xml:space="preserve">
    <value>Explorar</value>
  </data>
  <data name="trazadores" xml:space="preserve">
    <value>Trazadores</value>
  </data>
  <data name="trazadores_anadir" xml:space="preserve">
    <value>Añadir ...</value>
  </data>
  <data name="trazadores_borrar_todos" xml:space="preserve">
    <value>Borrar todos</value>
  </data>
  <data name="trazadores_borrar" xml:space="preserve">
    <value>Borrar</value>
  </data>
  <data name="trazadores_direccion" xml:space="preserve">
    <value>Dirección</value>
  </data>
  <data name="trazadores_nombre_agente" xml:space="preserve">
    <value>Nombre agente</value>
  </data>
  <data name="trazadores_puerto" xml:space="preserve">
    <value>Puerto</value>
  </data>
  <data name="trazadores_trazador" xml:space="preserve">
    <value>Trazador</value>
  </data>
  <data name="trazadores_comprobacion" xml:space="preserve">
    <value>Comprobación</value>
  </data>
  <data name="trazadores_modificar" xml:space="preserve">
    <value>Modificar</value>
  </data>
  <data name="error_enviando_lista_recetas" xml:space="preserve">
    <value>Error enviando lista de recetas</value>
  </data>
  <data name="seleccion_vacia_recetas" xml:space="preserve">
    <value>No se ha seleccionado ninguna receta</value>
  </data>
  <data name="receta_envio_masivo" xml:space="preserve">
    <value>Pedido generado masivamente</value>
  </data>
  <data name="confirmar_borrar_recetas" xml:space="preserve">
    <value>¿ Confirma el borrado de las {0} recetas guardadas y selecionadas ?</value>
  </data>
  <data name="confirmar_envio_pedidos_1" xml:space="preserve">
    <value>¿Confirma el envío de la siguiente cantidad de pedidos? #</value>
  </data>
  <data name="confirmar_envio_pedidos_2" xml:space="preserve">
    <value>Los pedidos con errores quedarán en estado "En revisión por INDO"</value>
  </data>
  <data name="error_borrando_recetas" xml:space="preserve">
    <value>Se ha producido un error borrando las recetas</value>
  </data>
  <data name="base_interior" xml:space="preserve">
    <value>Base interior</value>
  </data>
  <data name="plano" xml:space="preserve">
    <value>Plano</value>
    <comment>Tipo de bisel</comment>
  </data>
  <data name="indonet" xml:space="preserve">
    <value>Indonet</value>
  </data>
  <data name="error_agente_trazador" xml:space="preserve">
    <value>Error con el Agente Trazador</value>
  </data>
  <data name="receta_guardada" xml:space="preserve">
    <value>La receta se ha guardado correctamente</value>
  </data>
  <data name="condiciones_1" xml:space="preserve">
    <value>INDO OPTICAL, S.L.U. con CIF B-66232976, domicilio en Avda. Alcalde Barnils, 72 - 08174 Sant Cugat del Vallés - Barcelona e inscrita en el Registro Mercantil de Barcelona en el Tomo 44215, Folio 212, Hoja B-449860 como responsable del tratamiento de los datos, destinataria de la información y titular del fichero denominado “clientes”,  notifica al suscriptor que los datos proporcionados serán introducidos en el fichero denominado “clientes”. La finalidad de la recogida de datos es permitir el intercambio comercial entre INDO y su Óptica a través de Internet y poder así prestar el servicio INDONET.</value>
  </data>
  <data name="condiciones_2" xml:space="preserve">
    <value>La aceptación de las condiciones de uso es obligatoria y la falta de respuesta a los datos solicitados impedirá el servicio a través de la modalidad INDONET.</value>
  </data>
  <data name="condiciones_3" xml:space="preserve">
    <value>Se notifica al suscriptor que puede ejercitar ante el responsable del tratamiento cuantos derechos le otorga la ley y, especialmente, los derechos de acceso, rectificación, cancelación y oposición. Para ello deberá remitir su deseo a través del formulario de contacto de IndoBox: www.indobox-indo.com.</value>
  </data>
  <data name="condiciones_4" xml:space="preserve">
    <value>El suscriptor, como titular de los datos proporcionados, consiente expresamente su tratamiento en la forma descrita.</value>
  </data>
  <data name="configuracion_activado" xml:space="preserve">
    <value>Activado</value>
  </data>
  <data name="configuracion_altura_montaje" xml:space="preserve">
    <value>Altura de montaje</value>
  </data>
  <data name="configuracion_aro" xml:space="preserve">
    <value>Aro</value>
  </data>
  <data name="configuracion_boxing" xml:space="preserve">
    <value>Boxing</value>
  </data>
  <data name="configuracion_catalogo_lentes" xml:space="preserve">
    <value>Catálogo de lentes Indo</value>
  </data>
  <data name="configuracion_clave_actual" xml:space="preserve">
    <value>Clave actual</value>
  </data>
  <data name="configuracion_confirmar_clave" xml:space="preserve">
    <value>Confirmar clave</value>
  </data>
  <data name="configuracion_desactivado" xml:space="preserve">
    <value>Desactivado</value>
  </data>
  <data name="configuracion_descargas_automaticas" xml:space="preserve">
    <value>Descargas automáticas</value>
  </data>
  <data name="configuracion_dias" xml:space="preserve">
    <value>días</value>
  </data>
  <data name="configuracion_enlaces_relacionados" xml:space="preserve">
    <value>Enlaces relacionados</value>
  </data>
  <data name="configuracion_espesores" xml:space="preserve">
    <value>Configuración de espesores</value>
  </data>
  <data name="configuracion_jerga" xml:space="preserve">
    <value>Jerga</value>
  </data>
  <data name="configuracion_listado_pedidos" xml:space="preserve">
    <value>Listado "Situación de pedidos"</value>
  </data>
  <data name="configuracion_modificar_clave" xml:space="preserve">
    <value>Modificar clave</value>
  </data>
  <data name="configuracion_mostrar_pedidos" xml:space="preserve">
    <value>Mostrar pedidos realizados en los últimos</value>
  </data>
  <data name="configuracion_mostrar_precios" xml:space="preserve">
    <value>Mostrar precios</value>
  </data>
  <data name="configuracion_nombre_comercial" xml:space="preserve">
    <value>Nombre comercial</value>
  </data>
  <data name="configuracion_nombre_lentes" xml:space="preserve">
    <value>Nombre de las lentes</value>
  </data>
  <data name="configuracion_nueva_clave" xml:space="preserve">
    <value>Nueva clave</value>
  </data>
  <data name="configuracion_precalibrado_cotas" xml:space="preserve">
    <value>Precalibrado a cotas</value>
  </data>
  <data name="configuracion_precios_no" xml:space="preserve">
    <value>No</value>
  </data>
  <data name="configuracion_precios_pvo" xml:space="preserve">
    <value>PVO</value>
  </data>
  <data name="configuracion_precios_pvp" xml:space="preserve">
    <value>PVP</value>
  </data>
  <data name="configuracion_precios_si" xml:space="preserve">
    <value>Sí</value>
  </data>
  <data name="configuracion_pred_indo" xml:space="preserve">
    <value>Pred.Indo</value>
  </data>
  <data name="configuracion_propio" xml:space="preserve">
    <value>Propio</value>
  </data>
  <data name="configuracion_punto_taladro" xml:space="preserve">
    <value>Punto taladro</value>
  </data>
  <data name="configuracion_ranura_metal" xml:space="preserve">
    <value>Ranura metal</value>
  </data>
  <data name="configuracion_ranura_nylon" xml:space="preserve">
    <value>Ranura nylon</value>
  </data>
  <data name="configuracion_restablecer_valores_predeterminados" xml:space="preserve">
    <value>Restablecer valores predeterminados</value>
  </data>
  <data name="configuracion_tooltip_espesores" xml:space="preserve">
    <value>El espesor es inferior al recomendado. Las lentes no serán biseladas</value>
  </data>
  <data name="configuracion_tooltip_numstepper" xml:space="preserve">
    <value>El listado "Situación de pedidos se encuentra en la pantalla "Inicio". El rango permitido es de 1 a 7 días.</value>
  </data>
  <data name="trazadores_popup_anular" xml:space="preserve">
    <value>Anular</value>
  </data>
  <data name="trazadores_popup_titulo" xml:space="preserve">
    <value>Seleción / Modificación agente trazador</value>
  </data>
  <data name="configuracion_URL_catalogo" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/Indonet/Enlaces/CATALOGO_INDO_2019_DIGITAL.pdf</value>
  </data>
  <data name="error_conexion_agente_trazador_elegido" xml:space="preserve">
    <value>Error de conexión con el agente trazador elegido</value>
  </data>
  <data name="error_conexion_agente_trazador_insertado" xml:space="preserve">
    <value>Error de conexión con el agente trazador insertado</value>
  </data>
  <data name="datosoptica_logotipo_help" xml:space="preserve">
    <value>El nuevo logo se enseñara a la proxima autenticación</value>
  </data>
  <data name="error_desconocido" xml:space="preserve">
    <value>Error desconocido</value>
  </data>
  <data name="error_usuario_ya_existe" xml:space="preserve">
    <value>El usuario ya existe</value>
  </data>
  <data name="error_guardando_datos_configuracion" xml:space="preserve">
    <value>Error guardando datos configuración</value>
  </data>
  <data name="error_guardando_datos_optica" xml:space="preserve">
    <value>Error guardando datos optica</value>
  </data>
  <data name="biselador_remoto_guardar" xml:space="preserve">
    <value>Guardar</value>
  </data>
  <data name="biselador_remoto_iniciar" xml:space="preserve">
    <value>Iniciar</value>
  </data>
  <data name="biselador_remoto_popup_titulo" xml:space="preserve">
    <value>Seleción lecturas agente trazador</value>
  </data>
  <data name="mis_biselados" xml:space="preserve">
    <value>Mis biselados</value>
  </data>
  <data name="guardar_biselado" xml:space="preserve">
    <value>Guardar biselado</value>
  </data>
  <data name="error_abriendo_aplicacion" xml:space="preserve">
    <value>Error abriendo la aplicacion solicitada</value>
  </data>
  <data name="simulador_de_lentes" xml:space="preserve">
    <value>Simulador de lentes</value>
  </data>
  <data name="error_lecturas_agentes" xml:space="preserve">
    <value>Se ha producido un error accediendo a las lecturas de {0} agente{1}</value>
  </data>
  <data name="error_guardando_forma" xml:space="preserve">
    <value>Se ha producido un error guardando la forma</value>
  </data>
  <data name="forma_guardada_OK" xml:space="preserve">
    <value>Forma guardada correctamente</value>
  </data>
  <data name="duplicar_receta" xml:space="preserve">
    <value>Duplicar receta</value>
  </data>
  <data name="volver_al_listado" xml:space="preserve">
    <value>Volver al listado</value>
  </data>
  <data name="url_indonet_direct" xml:space="preserve">
    <value>http://pedidos.direc.indo.es/</value>
    <comment>Links </comment>
  </data>
  <data name="url_indo_en_el_mundo" xml:space="preserve">
    <value>http://www.indo.es/indo-en-el-mundo/indo_enelmundo.asp</value>
    <comment>Links</comment>
  </data>
  <data name="url_mas_informacion" xml:space="preserve">
    <value>http://www.indo.es/lentes/lentes_lentesindo.asp</value>
    <comment>Links</comment>
  </data>
  <data name="url_tutoriales" xml:space="preserve">
    <value>http://www.indo.es/es/optics/tutorials</value>
    <comment>Links</comment>
  </data>
  <data name="recetas_borrar" xml:space="preserve">
    <value>Borrar recetas</value>
  </data>
  <data name="recetas_enviar" xml:space="preserve">
    <value>Enviar recetas</value>
  </data>
  <data name="destinatario" xml:space="preserve">
    <value>Destinatario</value>
  </data>
  <data name="envio_recetas" xml:space="preserve">
    <value>Envio recetas</value>
  </data>
  <data name="confirmar_borrar_receta" xml:space="preserve">
    <value>¿ Confirma el borrado de la receta guardada y selecionada ?</value>
  </data>
  <data name="confirmar_envio_receta" xml:space="preserve">
    <value>¿ Confirma el envío de la receta guardada y selecionada ?</value>
  </data>
  <data name="confirmar_envio_recetas" xml:space="preserve">
    <value>¿ Confirma el envío de las {0} recetas guardadas y selecionadas ?</value>
  </data>
  <data name="confirmar_envio_recetas_2" xml:space="preserve">
    <value>Las recetas con errores quedarán en estado "En revisión por INDO"</value>
  </data>
  <data name="envio_recetas_falta_destinatario" xml:space="preserve">
    <value>Hay que elegir un destinatario</value>
  </data>
  <data name="recetas_borrado" xml:space="preserve">
    <value>Borrado de recetas</value>
  </data>
  <data name="recetas_envio" xml:space="preserve">
    <value>Envío de recetas</value>
  </data>
  <data name="cliente" xml:space="preserve">
    <value>Cliente</value>
  </data>
  <data name="tratamiento_coloracion" xml:space="preserve">
    <value>Tratamiento y coloración</value>
  </data>
  <data name="borrar_recetas" xml:space="preserve">
    <value>Borrar</value>
  </data>
  <data name="ventana_espera_areaventa" xml:space="preserve">
    <value>CARGANDO AREA VENTA</value>
  </data>
  <data name="ventana_espera_configuracion" xml:space="preserve">
    <value>CARGANDO CONFIGURACIÓN</value>
  </data>
  <data name="ventana_espera_login" xml:space="preserve">
    <value>AUTENTICACIÓN CLIENTE {0}</value>
  </data>
  <data name="ventana_espera_misbiselados" xml:space="preserve">
    <value>CARGANDO BISELADOS</value>
  </data>
  <data name="ventana_espera_monturas" xml:space="preserve">
    <value>CARGANDO MONTURAS</value>
  </data>
  <data name="ventana_espera_oferta" xml:space="preserve">
    <value>ACTUALIZANDO OFERTA</value>
  </data>
  <data name="simulador_error" xml:space="preserve">
    <value>Error al lanzar el simulador de lentes</value>
  </data>
  <data name="simulador_no_instalado" xml:space="preserve">
    <value>El simulador de lentes no esta instalado</value>
  </data>
  <data name="ventana_espera_busqueda_pedidos" xml:space="preserve">
    <value>BUSCANDO PEDIDOS</value>
  </data>
  <data name="ventana_espera_carga_pedidos_inicial" xml:space="preserve">
    <value>CARGANDO LISTA DE PEDIDOS INICIAL</value>
  </data>
  <data name="ventana_espera_esperar" xml:space="preserve">
    <value>ESPERA</value>
  </data>
  <data name="agente_trazador_desconectado" xml:space="preserve">
    <value>INDOBISEL parece que no esté funcionando. Por favor verifique su estado</value>
  </data>
  <data name="ventana_espera_verifica_conexion_trazador" xml:space="preserve">
    <value>VERIFICA CONEXIÓN AGENTE TRAZADOR {0}</value>
  </data>
  <data name="ventana_espera_lecturas_trazadores" xml:space="preserve">
    <value>LECTURAS AGENTE TRAZADOR {0}</value>
  </data>
  <data name="envio_recetas_ningun_destinatario" xml:space="preserve">
    <value>No se encontró ningun destinatario</value>
  </data>
  <data name="error_cargando_destinatarios" xml:space="preserve">
    <value>Error cargando destinatarios</value>
  </data>
  <data name="error_recuperando_recetas" xml:space="preserve">
    <value>Error recuperando recetas</value>
  </data>
  <data name="ventana_espera_borrar_recetas" xml:space="preserve">
    <value>BORRANDO RECETAS</value>
  </data>
  <data name="ventana_espera_busqueda_recetas" xml:space="preserve">
    <value>BUSCANDO RECETAS</value>
  </data>
  <data name="ventana_espera_carga_receta" xml:space="preserve">
    <value>CARGANDO RECETA {0}</value>
  </data>
  <data name="ventana_espera_destinatarios" xml:space="preserve">
    <value>CARGANDO DESTINATARIOS</value>
  </data>
  <data name="ventana_espera_envio_recetas" xml:space="preserve">
    <value>ENVIANDO RECETAS</value>
  </data>
  <data name="error_actualizacion_pedido" xml:space="preserve">
    <value>Error en la actualización del pedido</value>
  </data>
  <data name="ventana_espera_actualizar_pedido" xml:space="preserve">
    <value>Actualizando pedido</value>
  </data>
  <data name="ventana_espera_envio_pedidos" xml:space="preserve">
    <value>ENVIANDO PEDIDO</value>
  </data>
  <data name="ventana_espera_guardar_receta" xml:space="preserve">
    <value>GUARDANDO RECETA {0}</value>
  </data>
  <data name="ventana_espera_revision_pedidos" xml:space="preserve">
    <value>ENVIANDO A REVISIÓN</value>
  </data>
  <data name="trazadores_conexion_agente_trazador_OK" xml:space="preserve">
    <value>EL AGENTE TRAZADOR {0} ESTÁ CONECTADO CORRECTAMENTE</value>
  </data>
  <data name="laboratorio" xml:space="preserve">
    <value>Laboratorio</value>
  </data>
  <data name="stock" xml:space="preserve">
    <value>Stock</value>
  </data>
  <data name="tipo_lente" xml:space="preserve">
    <value>Tipo de lente</value>
  </data>
  <data name="es_necesario_cerrar_la_aplicacion" xml:space="preserve">
    <value>La aplicación se ha instalado correctamente.{0}A continuación pulse Aceptar, espere unos segundos a que se cierre y haga doble click sobre el icono Indonet que está en su escritorio</value>
  </data>
  <data name="configuracion_nombre_lentes_help" xml:space="preserve">
    <value>La nueva impostación se enseñara a la proxima autenticación</value>
  </data>
  <data name="ventana_espera_cambio_clave" xml:space="preserve">
    <value>GUARDANDO NUEVA CLAVE DE ACCESO</value>
  </data>
  <data name="ventana_espera_guardar_configuracion" xml:space="preserve">
    <value>GUARDANDO CONFIGURACIÓN</value>
  </data>
  <data name="ventana_espera_guardar_datos_optica" xml:space="preserve">
    <value>GUARDANDO DATOS OPTICA</value>
  </data>
  <data name="ventana_espera_carga_pedido" xml:space="preserve">
    <value>CARGANDO PEDIDO {0}</value>
  </data>
  <data name="datosoptica_aviso_logotipo" xml:space="preserve">
    <value>No se ha especificado ningun fichero para el logotipo.{0}Se adoptará el logo por defecto </value>
  </data>
  <data name="chile" xml:space="preserve">
    <value>Chile</value>
  </data>
  <data name="conexion_ssl" xml:space="preserve">
    <value>CONEXIÓN SSL</value>
  </data>
  <data name="espana" xml:space="preserve">
    <value>España</value>
  </data>
  <data name="francia" xml:space="preserve">
    <value>Francia</value>
  </data>
  <data name="indo_en_el_mundo" xml:space="preserve">
    <value>» Indo en el mundo</value>
  </data>
  <data name="italia" xml:space="preserve">
    <value>Italia</value>
  </data>
  <data name="login_verificar" xml:space="preserve">
    <value>VERIFICAR</value>
  </data>
  <data name="mensaje_solicitud_clave" xml:space="preserve">
    <value>Para disponer de una contraseña sólo debe introducir una dirección de email correcta y su código de cliente Indo.{0}En breve recibirá su contraseña para entrar en Indonet.{0}Muchas Gracias</value>
  </data>
  <data name="portugal" xml:space="preserve">
    <value>Portugal</value>
  </data>
  <data name="segura" xml:space="preserve">
    <value>SEGURA</value>
  </data>
  <data name="solicitar_clave_acceso" xml:space="preserve">
    <value>» Solicitar clave de acceso</value>
  </data>
  <data name="confirma_biselado_espesor" xml:space="preserve">
    <value>Confirmación de biselado y espesor</value>
  </data>
  <data name="estado_pedido_grabados" xml:space="preserve">
    <value>Grabados</value>
  </data>
  <data name="estado_pedido_pendientes" xml:space="preserve">
    <value>Pendientes de arreglo</value>
  </data>
  <data name="estado_pedido_todos" xml:space="preserve">
    <value>Todos</value>
  </data>
  <data name="pedidos_obtenidos" xml:space="preserve">
    <value>Pedido(s) obtenido(s)</value>
  </data>
  <data name="recetas_obtenidas" xml:space="preserve">
    <value>Receta(s) obtenida(s)</value>
  </data>
  <data name="ventana_espera_lecturas_trazador" xml:space="preserve">
    <value>LECTURAS TRAZADOR {0}{1}</value>
  </data>
  <data name="ventana_espera_lectura_trazador" xml:space="preserve">
    <value>LECTURA {0}</value>
  </data>
  <data name="ventana_espera_envio_correo" xml:space="preserve">
    <value>ENVIANDO CORREO</value>
  </data>
  <data name="configuracion_espesor_borde" xml:space="preserve">
    <value>Espesor borde</value>
  </data>
  <data name="texto_presentacion1" xml:space="preserve">
    <value>Welcome to the new generation of online orders.</value>
  </data>
  <data name="texto_presentacion2" xml:space="preserve">
    <value>This is the beginning of the road of the new Indo!</value>
  </data>
  <data name="texto_presentacion3" xml:space="preserve">
    <value>Surprise yourself with the most agile and intuitive tool on the market to place your orders. With features that will allow you to increase the added value of your sales.</value>
  </data>
  <data name="texto_presentacion4" xml:space="preserve">
    <value>Customize and optimize thickness for lab lenses.</value>
  </data>
  <data name="texto_presentacion5" xml:space="preserve">
    <value>Use most of the market tracers.</value>
  </data>
  <data name="texto_presentacion6" xml:space="preserve">
    <value>Track your orders more closely.</value>
  </data>
  <data name="texto_presentacion7" xml:space="preserve">
    <value>Check with your sales representative, or your custom manager, the most appropriate option to cover all your needs.</value>
  </data>
  <data name="configuracion_guardada_correctamente" xml:space="preserve">
    <value>Configuración guardada correctamente</value>
  </data>
  <data name="datosoptica_guardados_correctamente" xml:space="preserve">
    <value>Datos de optica guardados correctamente</value>
  </data>
  <data name="calibracion_monitor" xml:space="preserve">
    <value>Calibración monitor</value>
  </data>
  <data name="calibracion_correctamente" xml:space="preserve">
    <value>La calibración se ha realizado correctamente</value>
  </data>
  <data name="descripcion_calibracion" xml:space="preserve">
    <value>Introduce en milímetros el diámetro real de la circunferencia anterior</value>
  </data>
  <data name="error_calibracion" xml:space="preserve">
    <value>No se ha podido realizar la calibración</value>
  </data>
  <data name="infolog_actualizacion_oferta" xml:space="preserve">
    <value>***&gt; Comienzo actualización oferta</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="infolog_cabecera_oferta_ok" xml:space="preserve">
    <value>***&gt; Cabecera oferta OK</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="infolog_creacion_bbdd_local" xml:space="preserve">
    <value>***&gt; Comienzo creación bbdd local</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="infolog_creacion_bbdd_local_OK" xml:space="preserve">
    <value>***&gt; Creación bbdd local OK</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="infolog_grabar_oferta" xml:space="preserve">
    <value>***&gt; Comienzo grabar oferta en bbdd local</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="infolog_grabar_oferta_OK" xml:space="preserve">
    <value>***&gt; Grabar oferta en bbdd local OK</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="infolog_oferta_descargada_ok" xml:space="preserve">
    <value>***&gt; Oferta descargada OK</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="fecha_envio" xml:space="preserve">
    <value>Fecha de envio:</value>
  </data>
  <data name="cancelar_comparacion" xml:space="preserve">
    <value>Cancelar comparación</value>
  </data>
  <data name="comparar" xml:space="preserve">
    <value>Comparar</value>
  </data>
  <data name="comparar_lentes" xml:space="preserve">
    <value>Comparar lentes</value>
  </data>
  <data name="espesor_centro_abr" xml:space="preserve">
    <value>Espesor cen.</value>
  </data>
  <data name="ganancia_en_espesor" xml:space="preserve">
    <value>Ganancia en espesor</value>
  </data>
  <data name="ganancia_en_espesor_abr" xml:space="preserve">
    <value>Gan. en esp.</value>
  </data>
  <data name="ganancia_en_peso" xml:space="preserve">
    <value>Ganancia en peso</value>
  </data>
  <data name="ganancia_en_peso_abr" xml:space="preserve">
    <value>Gan. en peso</value>
  </data>
  <data name="comparar_otra_lente" xml:space="preserve">
    <value>Comparar otra lente</value>
  </data>
  <data name="sistema_pedidos_por_mayor" xml:space="preserve">
    <value>Sistema de pedidos al por mayor</value>
  </data>
  <data name="conexion_ssl_segura" xml:space="preserve">
    <value>Conexión SSL segura</value>
  </data>
  <data name="pedido" xml:space="preserve">
    <value>Pedido</value>
  </data>
  <data name="imprimir_pedidos_seleccionados" xml:space="preserve">
    <value>Imprimir pedidos seleccionados</value>
  </data>
  <data name="quitar_seleccion" xml:space="preserve">
    <value>Quitar selección</value>
  </data>
  <data name="seleccionar_todos" xml:space="preserve">
    <value>Seleccionar todos</value>
  </data>
  <data name="ventana_espera_cargando_clientes" xml:space="preserve">
    <value>RECUPERANDO CLIENTES</value>
  </data>
  <data name="selecciona_la_lente_deseada" xml:space="preserve">
    <value>Selecciona la lente deseada</value>
  </data>
  <data name="obligatorio_seleccionar_destiantario" xml:space="preserve">
    <value>Es necesario informar el destinatário de la mercancía</value>
  </data>
  <data name="selecciona_cliente" xml:space="preserve">
    <value>Selecciona el cliente</value>
  </data>
  <data name="altura_montaje_total" xml:space="preserve">
    <value>Altura de montaje final</value>
  </data>
  <data name="error_grabacion_pedido" xml:space="preserve">
    <value>Se ha producido un error en la grabación de su pedido. Por favor, vuelva a realizarlo</value>
  </data>
  <data name="ventana_espera_comparando_lentes" xml:space="preserve">
    <value>COMPARANDO LENTES</value>
  </data>
  <data name="borde_especial_abr" xml:space="preserve">
    <value>B. Esp</value>
  </data>
  <data name="precalibrado_digital_abr" xml:space="preserve">
    <value>Precal.</value>
  </data>
  <data name="ventana_espera_obteniendo_bases" xml:space="preserve">
    <value>RECUPERANDO BASES</value>
  </data>
  <data name="info_envio_pedido_revisar" xml:space="preserve">
    <value>Su pedido ha sido grabado. Indo lo revisará y lo pondrá en marcha en breve</value>
  </data>
  <data name="info_ojo_informativo" xml:space="preserve">
    <value>Al marcar este ojo como informativo no se fabricará</value>
  </data>
  <data name="ventana_espera_seleccion_lente" xml:space="preserve">
    <value>SELECCIONANDO LENTE</value>
  </data>
  <data name="mas_informacion" xml:space="preserve">
    <value>Más información</value>
  </data>
  <data name="configuracion_fecha_oferta" xml:space="preserve">
    <value>Fecha Oferta</value>
    <comment>Lectura de la fecha de la oferta en la BBDD</comment>
  </data>
  <data name="optimizacion_freemax" xml:space="preserve">
    <value>Optimización FreeMax</value>
    <comment>Leyenda del precalibrado digital para MAXIMA</comment>
  </data>
  <data name="aviso_reiniciar_oferta" xml:space="preserve">
    <value>¿Está seguro de que quiere recargar la oferta?</value>
  </data>
  <data name="reiniciar_fecha_oferta" xml:space="preserve">
    <value>Recargar oferta</value>
  </data>
  <data name="fecha_prevista_recepcion" xml:space="preserve">
    <value>Fecha prevista de recepción</value>
  </data>
  <data name="toolTip_abrir_indoscan" xml:space="preserve">
    <value>Abrir INDOScan</value>
  </data>
  <data name="aviso_falta_scaner" xml:space="preserve">
    <value>No se pudo inicializar el scaner</value>
    <comment>IndoScan</comment>
  </data>
  <data name="activar" xml:space="preserve">
    <value>Activado</value>
  </data>
  <data name="finalizar_pedido" xml:space="preserve">
    <value>Finalizar pedido</value>
    <comment>Enviar en Segunda gafa</comment>
  </data>
  <data name="segunda_gafa" xml:space="preserve">
    <value>SEGUNDA GAFA</value>
    <comment>Configuración óptica</comment>
  </data>
  <data name="anadir_encargo" xml:space="preserve">
    <value>Añadir encargo</value>
    <comment>segunda gafa</comment>
  </data>
  <data name="anadir_encargo_pregunta_enviar_pedido" xml:space="preserve">
    <value>Enviar ahora el pedido</value>
    <comment>segunda gafa</comment>
  </data>
  <data name="anadir_encargo_pregunta_guardar_receta" xml:space="preserve">
    <value>Guardar y realizar el pedido más tarde desde Recetas Guardadas</value>
    <comment>segunda gafa</comment>
  </data>
  <data name="anadir_encargo_pregunta_graduacion_cerca" xml:space="preserve">
    <value>Graduación de cerca</value>
    <comment>segunda gafa</comment>
  </data>
  <data name="anadir_encargo_pregunta_graduacion_diferente" xml:space="preserve">
    <value>Graduación diferente a la primera gafa</value>
    <comment>segunda gafa</comment>
  </data>
  <data name="anadir_encargo_pregunta_graduacion_lejos" xml:space="preserve">
    <value>Graduación de lejos</value>
    <comment>segunda gafa</comment>
  </data>
  <data name="anadir_encargo_pregunta_misma_graduacion" xml:space="preserve">
    <value>Misma graduación que la primera gafa</value>
    <comment>segunda gafa</comment>
  </data>
  <data name="Aceptar_Cambio_Y_Enviar" xml:space="preserve">
    <value>Aceptar Cambio Y Enviar</value>
    <comment>Alternativa de producto</comment>
  </data>
  <data name="Atencion" xml:space="preserve">
    <value>Atencion</value>
    <comment>Alternativa de producto</comment>
  </data>
  <data name="comprobar_disponibilidad" xml:space="preserve">
    <value>comprobar disponibilidad</value>
  </data>
  <data name="error_comprobar_disponibilidad_segunda_gafa" xml:space="preserve">
    <value>Error comprobando disponibilidad segunda gafa</value>
  </data>
  <data name="montura" xml:space="preserve">
    <value>Montura</value>
  </data>
  <data name="aviso_alternativa_producto" xml:space="preserve">
    <value>SE HA DETECTADO UNA ROTURA DE STOCK PARA LA GRADUACIÓN Y PRODUCTO SOLICITADO. PARA GARANTIZARLE UN BUEN SERVICIO, LE SERVIMOS AL MISMO PRECIO EL SIGUIENTE PRODUCTO. SI SE TRATA DE LA REPOSICIÓN DE UN SOLO OJO LE ACONSEJAMOS QUE LLAME A ATENCIÓN AL CLIENTE:</value>
    <comment>Alternativa producto</comment>
  </data>
  <data name="codigo_de_barras" xml:space="preserve">
    <value>Código de barras</value>
  </data>
  <data name="aviso_atencion_urgencias" xml:space="preserve">
    <value>Atención: Para urgencias, reclamaciones y anulaciones llama al 93.748.68.00</value>
  </data>
  <data name="pedido_con_montura" xml:space="preserve">
    <value>Pedido con montura</value>
  </data>
  <data name="desde_scanner" xml:space="preserve">
    <value>Desde Scanner</value>
    <comment>ComboBox Formas</comment>
  </data>
  <data name="aviso_falta_tipo_montura" xml:space="preserve">
    <value>Debe especificar el tipo de montura o el tipo de bisel. Pinche en el botón Siguiente, dentro de selección de espesores.</value>
  </data>
  <data name="valor_fuera_de_margenes" xml:space="preserve">
    <value>Valor fuera de márgenes para {2}. Valores permitidos entre {0} y {1}.</value>
  </data>
  <data name="aviso_escoger_alto_indice" xml:space="preserve">
    <value>Para lentes con taladro es recomendable usar lentes a partir de índice 1.6 para garantizar la seguridad del montaje.</value>
  </data>
  <data name="pregunta_montura_y_lentes_biseladas" xml:space="preserve">
    <value>Montura y lentes biseladas</value>
  </data>
  <data name="pregunta_montura_y_lentes_montadas" xml:space="preserve">
    <value>Montura y lentes montadas</value>
  </data>
  <data name="pregunta_montura_y_lentes_sin_biselar" xml:space="preserve">
    <value>Montura y lentes sin biselar (lente asociada a la montura solicitada)</value>
  </data>
  <data name="pregunta_solo_lentes_biseladas" xml:space="preserve">
    <value>Sólo lentes biseladas</value>
  </data>
  <data name="pregunta_solo_lentes_sin_biselar" xml:space="preserve">
    <value>Sólo lentes sin biselar</value>
  </data>
  <data name="formas_fax_enviarFichero" xml:space="preserve">
    <value>Obtener de Fichero</value>
  </data>
  <data name="anadir_encargo_pregunta_enviar_revision" xml:space="preserve">
    <value>Enviar a revisión el pedido.</value>
    <comment>segunda gafa</comment>
  </data>
  <data name="OjoDerecho" xml:space="preserve">
    <value>Ojo derecho</value>
    <comment>Elegir ojo</comment>
  </data>
  <data name="OjoIzquierdo" xml:space="preserve">
    <value>Ojo Izquierdo</value>
    <comment>Elegir ojo</comment>
  </data>
  <data name="PreguntaElegirOjo" xml:space="preserve">
    <value>¿A qué ojo corresponde la forma obtenida?</value>
    <comment>Elegir ojo</comment>
  </data>
  <data name="aviso_caracter_no_valido" xml:space="preserve">
    <value>Este carácter no es válido. Por favor use el alfabeto occidental.</value>
  </data>
  <data name="caracter_hiperlink" xml:space="preserve">
    <value>»</value>
    <comment>Login</comment>
  </data>
  <data name="direccion_indo" xml:space="preserve">
    <value>Avda. Alcalde Barnils, 72 - 08174 Sant Cugat del Vallés - Barcelona</value>
    <comment>Login</comment>
  </data>
  <data name="aviso_falta_montura" xml:space="preserve">
    <value>No hay stock de la montura solicitada. Elimina la montura del pedido en la pestaña formas y espesores o cámbiala por otra montura con existencias.</value>
    <comment>SegundaGafa</comment>
  </data>
  <data name="descargando_oferta" xml:space="preserve">
    <value>Descargando oferta</value>
  </data>
  <data name="actualizando_nueva_oferta" xml:space="preserve">
    <value>Actualizando la nueva oferta...</value>
  </data>
  <data name="comprobando_si_BBDD" xml:space="preserve">
    <value>Comprobando si existe BBDD...</value>
  </data>
  <data name="comprobando_si_nueva_oferta" xml:space="preserve">
    <value>Comprobando si hay nueva oferta...</value>
  </data>
  <data name="descarga_oferta_fallo_1" xml:space="preserve">
    <value>No se ha podido recargar la oferta en este momento.</value>
  </data>
  <data name="descarga_oferta_fallo_2" xml:space="preserve">
    <value>Inténtelo más tarde desde el botón RECARGAR OFERTA en la pestaña de CONFIGURACIÓN de DATOS ÓPTICA</value>
  </data>
  <data name="Esperando_respuesta_del_usuario_para_actualizar_la_oferta" xml:space="preserve">
    <value>Esperando respuesta del usuario para actualizar la oferta...</value>
  </data>
  <data name="nueva_oferta_actualizada_correctamente" xml:space="preserve">
    <value>Nueva oferta actualizada correctamente</value>
  </data>
  <data name="oferta_actualizada" xml:space="preserve">
    <value>Oferta actualizada...</value>
  </data>
  <data name="aviso_cargando_oferta" xml:space="preserve">
    <value>cargando datos oferta</value>
  </data>
  <data name="configuracion_taller" xml:space="preserve">
    <value>Configuracion taller</value>
  </data>
  <data name="jobs_omain" xml:space="preserve">
    <value>JOBS(OMAIN)</value>
  </data>
  <data name="macros_talla" xml:space="preserve">
    <value>Macros de talla</value>
  </data>
  <data name="peticiones_talla" xml:space="preserve">
    <value>Peticiones talla</value>
  </data>
  <data name="productos_y_credito" xml:space="preserve">
    <value>Productos y credito</value>
    <comment>FFS</comment>
  </data>
  <data name="semiterminados" xml:space="preserve">
    <value>Semiterminados</value>
  </data>
  <data name="id_producto" xml:space="preserve">
    <value>ID_PRODUCTO</value>
    <comment>FFS</comment>
  </data>
  <data name="job" xml:space="preserve">
    <value>JOB</value>
    <comment>FFS</comment>
  </data>
  <data name="nombre_producto" xml:space="preserve">
    <value>NOMBRE PRODUCTO</value>
    <comment>FFS</comment>
  </data>
  <data name="ffname" xml:space="preserve">
    <value>FFNAME</value>
    <comment>FFS</comment>
  </data>
  <data name="id_design" xml:space="preserve">
    <value>ID_DESIGN</value>
    <comment>FFS</comment>
  </data>
  <data name="marcas_laser" xml:space="preserve">
    <value>Marcas LASER</value>
    <comment>FFS</comment>
  </data>
  <data name="indice_desde" xml:space="preserve">
    <value>Índice desde</value>
    <comment>FFS</comment>
  </data>
  <data name="indice_hasta" xml:space="preserve">
    <value>Índice hasta</value>
    <comment>FFS</comment>
  </data>
  <data name="macro" xml:space="preserve">
    <value>Macro</value>
    <comment>FFS</comment>
  </data>
  <data name="maquina" xml:space="preserve">
    <value>Máquina</value>
    <comment>FFS</comment>
  </data>
  <data name="material" xml:space="preserve">
    <value>Material</value>
    <comment>FFS</comment>
  </data>
  <data name="anillas" xml:space="preserve">
    <value>Anillas</value>
    <comment>FFS</comment>
  </data>
  <data name="detalle_OMA" xml:space="preserve">
    <value>Detalle OMA</value>
    <comment>FFS</comment>
  </data>
  <data name="id_job" xml:space="preserve">
    <value>ID JOB</value>
    <comment>FFS</comment>
  </data>
  <data name="ventana_espera_recuperar_datos" xml:space="preserve">
    <value>RECUPERANDO DATOS</value>
    <comment>FFS</comment>
  </data>
  <data name="aviso_solicitar_solo_monturas" xml:space="preserve">
    <value>Para solicitar sólo monturas para tu stock hazlo a través de recetas.lentes@indo.es</value>
  </data>
  <data name="alt_anilla" xml:space="preserve">
    <value>ALT ANILL</value>
    <comment>FFS</comment>
  </data>
  <data name="c1_desde" xml:space="preserve">
    <value>C1 DESDE</value>
    <comment>FFS</comment>
  </data>
  <data name="c1_hasta" xml:space="preserve">
    <value>C1 HASTA</value>
    <comment>FFS</comment>
  </data>
  <data name="diam_anill" xml:space="preserve">
    <value>DIAM ANILL</value>
    <comment>FFS</comment>
  </data>
  <data name="diam_desde" xml:space="preserve">
    <value>DIAM DESDE</value>
    <comment>FFS</comment>
  </data>
  <data name="diam_hasta" xml:space="preserve">
    <value>DIAM HASTA</value>
    <comment>FFS</comment>
  </data>
  <data name="diam_resul" xml:space="preserve">
    <value>DIAM RESUL</value>
    <comment>FFS</comment>
  </data>
  <data name="grabar_cambios" xml:space="preserve">
    <value>Guardar Cambios</value>
  </data>
  <data name="oval" xml:space="preserve">
    <value>OVAL</value>
    <comment>FFS</comment>
  </data>
  <data name="precal" xml:space="preserve">
    <value>PRECAL</value>
    <comment>FFS</comment>
  </data>
  <data name="prod_desde" xml:space="preserve">
    <value>PROD DESDE</value>
    <comment>FFS</comment>
  </data>
  <data name="prod_hasta" xml:space="preserve">
    <value>PROD HASTA</value>
    <comment>FFS</comment>
  </data>
  <data name="semitermin" xml:space="preserve">
    <value>SEMITERMIN</value>
    <comment>FFS</comment>
  </data>
  <data name="sucursal" xml:space="preserve">
    <value>SUCURSAL</value>
    <comment>FFS</comment>
  </data>
  <data name="ventana_espera_guardar_datos" xml:space="preserve">
    <value>GUARDANDO DATOS</value>
    <comment>FFS</comment>
  </data>
  <data name="aviso_lecturas_no_cargadas" xml:space="preserve">
    <value>No se han importado lecturas para esta forma</value>
  </data>
  <data name="aviso_lectura_importada" xml:space="preserve">
    <value>Forma {0} importada con éxito</value>
  </data>
  <data name="biselador_remoto_importar" xml:space="preserve">
    <value>Importar</value>
  </data>
  <data name="biselador_remoto_ultima" xml:space="preserve">
    <value>Última</value>
  </data>
  <data name="aviso_lectura_cargada" xml:space="preserve">
    <value>Forma {0} cargada con éxito</value>
  </data>
  <data name="tooltip_boton_importar" xml:space="preserve">
    <value>Importar una forma desde fichero</value>
  </data>
  <data name="tooltip_boton_iniciar" xml:space="preserve">
    <value>Muestra todas las lecturas de INDOBISEL</value>
  </data>
  <data name="tooltip_boton_ultima" xml:space="preserve">
    <value>Cargar la última forma leída en Indobisel</value>
  </data>
  <data name="insertar" xml:space="preserve">
    <value>Insertar</value>
    <comment>FFS</comment>
  </data>
  <data name="aviso_introducir_montura_1" xml:space="preserve">
    <value>Atención: Para realizar correctamente el pedido</value>
  </data>
  <data name="aviso_introducir_montura_2" xml:space="preserve">
    <value>debe introducir la forma de la montura.</value>
  </data>
  <data name="espesor_borde" xml:space="preserve">
    <value>Espesor borde</value>
  </data>
  <data name="Discrepancia_muestra_leida" xml:space="preserve">
    <value>E r r o r : Discrepancia entre número de muestra esperada y obtenida.</value>
    <comment>IndoScan</comment>
  </data>
  <data name="IntentarOtraVez" xml:space="preserve">
    <value>Por favor, inténtelo de nuevo con otra imágen o avise a su contacto en INDO.</value>
    <comment>IndoScan</comment>
  </data>
  <data name="NoPosibleObtenerForma" xml:space="preserve">
    <value>No se ha podido obtener la forma a partir de la imágen.</value>
    <comment>IndoScan</comment>
  </data>
  <data name="SelEscaner" xml:space="preserve">
    <value>Seleccionar escáner</value>
    <comment>IndoScan</comment>
  </data>
  <data name="VolverAObtenerDeEscaner" xml:space="preserve">
    <value>Volver a obtener de escáner</value>
    <comment>IndoScan</comment>
  </data>
  <data name="FicherosFormas" xml:space="preserve">
    <value>Ficheros de imágenes de formas</value>
    <comment>IndoScan</comment>
  </data>
  <data name="AnalizandoImagen" xml:space="preserve">
    <value>Analizando imágen para obtener forma</value>
    <comment>IndoScan</comment>
  </data>
  <data name="msg_Prerequisitos" xml:space="preserve">
    <value>A continuación se iniciará de forma automática la instalación de los programas necesarios para la captura y posterior proceso de las imágenes para obtener la forma de la montura.</value>
    <comment>IndoScan</comment>
  </data>
  <data name="calibre_marcas" xml:space="preserve">
    <value>Calibre</value>
  </data>
  <data name="reiniciar_fecha_oferta1" xml:space="preserve">
    <value>Recargar oferta</value>
  </data>
  <data name="configuracion_hojaPedido" xml:space="preserve">
    <value>Hoja para pedido</value>
  </data>
  <data name="configuracion_link_hojaRombos" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/Indonet/Enlaces/HojaPedidos_ES.pdf</value>
  </data>
  <data name="configuracion_link_manualIndoScanIntegrado" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/Indonet/Enlaces/ManualIndoScanIntegrado_ES.pdf</value>
  </data>
  <data name="configuracion_manualIndoScanIntegrado" xml:space="preserve">
    <value>Manual IndoScan integrado</value>
  </data>
  <data name="configuracion_lentesMaxima" xml:space="preserve">
    <value>www.lentesmaxima.com</value>
  </data>
  <data name="configuracion_link_lentesMaxima" xml:space="preserve">
    <value>http://www.lentesmaxima.com</value>
  </data>
  <data name="configuracion_link_manualIndoNet" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/Indonet/Enlaces/ManualIndonet_ES.pdf</value>
  </data>
  <data name="configuracion_manualIndonet" xml:space="preserve">
    <value>Manual Indonet</value>
  </data>
  <data name="aviso_precalibrado_forma_cuadrada" xml:space="preserve">
    <value>En Precalibrados con Forma Básica se recomienda seleccionar la Forma Cuadrada para cubrir la forma de la gafa. Esta forma no garantiza el espesor más óptimo. Para asegurar ambos parámetros utiliza la forma real de la gafa.</value>
  </data>
  <data name="parametros_proxy" xml:space="preserve">
    <value>Parámetros conexión al Proxy</value>
  </data>
  <data name="password" xml:space="preserve">
    <value>Password</value>
  </data>
  <data name="usuario" xml:space="preserve">
    <value>Usuario</value>
  </data>
  <data name="aviso_falta_diametro" xml:space="preserve">
    <value>Debe escoger un diámetro.</value>
  </data>
  <data name="recordar_mis_credenciales" xml:space="preserve">
    <value>Recordar mis credenciales</value>
  </data>
  <data name="mail_informacion_estado_pedidos" xml:space="preserve">
    <value>MAIL INFORMACION ESTADO DE LOS PEDIDOS</value>
    <comment>Mi buzon</comment>
  </data>
  <data name="mi_buzon" xml:space="preserve">
    <value>Mi Buzón</value>
    <comment>Mi buzon</comment>
  </data>
  <data name="texto_direcciones_email" xml:space="preserve">
    <value>Direcciones donde quieres recibir esta información</value>
    <comment>Mi buzon</comment>
  </data>
  <data name="texto_pedidos_en_curso" xml:space="preserve">
    <value>Pedidos en curso (1 mail diario)</value>
    <comment>Mi buzon</comment>
  </data>
  <data name="texto_pedidos_en_envio" xml:space="preserve">
    <value>Pedidos a recibir en cada envío (1 mail por cada envío)</value>
    <comment>Mi buzon</comment>
  </data>
  <data name="texto_si_deseas_recibir_mails" xml:space="preserve">
    <value>Si deseas recibir mails con información de tus pedidos selecciona una de las siguientes opciones:</value>
    <comment>Mi buzon</comment>
  </data>
  <data name="aviso_color_nd_diferentes" xml:space="preserve">
    <value>No se permite coloración en lentes de distinto índice.</value>
  </data>
  <data name="aviso_actualizacion" xml:space="preserve">
    <value>La aplicación se actualizará a la versión:</value>
    <comment>clickonce</comment>
  </data>
  <data name="aviso_apagado" xml:space="preserve">
    <value>Aplicacion Actualizada. La aplicación se cerrará. Puede iniciar otra vez INDONET desde el icono del escritorio.</value>
    <comment>clickonce</comment>
  </data>
  <data name="aviso_descarga_aplicacion" xml:space="preserve">
    <value>Descargando nueva versión</value>
    <comment>ClickOnce</comment>
  </data>
  <data name="aviso_instalando_version" xml:space="preserve">
    <value>Instalando nueva version</value>
    <comment>ClickOnce</comment>
  </data>
  <data name="password_internet" xml:space="preserve">
    <value>Password Internet</value>
    <comment>proxy</comment>
  </data>
  <data name="usuario_internet" xml:space="preserve">
    <value>Usuario Internet</value>
    <comment>proxy</comment>
  </data>
  <data name="error_envio_pedido_paso2_1" xml:space="preserve">
    <value>Se ha detectado un error en la comunicación con nuestro servidor al grabar el pedido. Verifica en la pestaña de ""Pedidos Realizados"" si se ha grabado y actúa de la siguiente manera:</value>
  </data>
  <data name="error_envio_pedido_paso2_2" xml:space="preserve">
    <value>Si se ha grabado borra la pestaña de ""Realizar Pedido""</value>
  </data>
  <data name="error_envio_pedido_paso2_3" xml:space="preserve">
    <value>Si no se ha grabado reenvíalo de nuevo.</value>
  </data>
  <data name="valores_por_defecto" xml:space="preserve">
    <value>Valores por defecto</value>
  </data>
  <data name="aviso_mas_30_dias" xml:space="preserve">
    <value>El intervalo de fechas debe ser inferior a 60 días</value>
  </data>
  <data name="aviso_encargo_anulado" xml:space="preserve">
    <value>Su encargo se ha anulado correctamente</value>
  </data>
  <data name="aviso_encargo_no_anulable" xml:space="preserve">
    <value>Ya no es posible anular el encargo</value>
  </data>
  <data name="error_borrando_pedido" xml:space="preserve">
    <value>Se ha producido un error borrando el pedido</value>
  </data>
  <data name="pregunta_borrar_pedido" xml:space="preserve">
    <value>¿Estás seguro que quieres anular el encargo con Número de Pedido {0}?</value>
  </data>
  <data name="ventana_espera_borrar_pedidos" xml:space="preserve">
    <value>BORRANDO PEDIDO {0}</value>
  </data>
  <data name="ventana_espera_consulta_anulable" xml:space="preserve">
    <value>Consulta si pedido {0} es anulable</value>
  </data>
  <data name="info_creacion_bbdd_local" xml:space="preserve">
    <value>***&gt;Inicio creación BBDD</value>
  </data>
  <data name="info_creacion_bbdd_local_OK" xml:space="preserve">
    <value>***&gt;Creación BBDD OK</value>
  </data>
  <data name="info_grabar_oferta" xml:space="preserve">
    <value>***&gt;Inicio guardar oferta en BBDD</value>
  </data>
  <data name="info_grabar_oferta_OK" xml:space="preserve">
    <value>***&gt;Oferta guardada en BBDD OK</value>
  </data>
  <data name="log_actualizacion_oferta" xml:space="preserve">
    <value>***&gt;Inicio actualización oferta</value>
  </data>
  <data name="log_cabecera_oferta_ok" xml:space="preserve">
    <value>***&gt;Cabecera Oferta OK</value>
  </data>
  <data name="log_oferta_descargada_ok" xml:space="preserve">
    <value>***&gt;Actualización Oferta OK</value>
  </data>
  <data name="aviso_color_reposicion" xml:space="preserve">
    <value>Hemos recibido tu pedido. 
Para asegurar que el color de ambas lentes sea igual nos pondremos en contacto contigo lo antes posible.</value>
  </data>
  <data name="graduacion_resultante" xml:space="preserve">
    <value>Graduación Resultante:</value>
  </data>
  <data name="titulo_reposicion_color" xml:space="preserve">
    <value>AVISO REPOSICION COLOR</value>
  </data>
  <data name="aviso_falta_referencia" xml:space="preserve">
    <value>Debe introducir una referencia</value>
    <comment>FFS</comment>
  </data>
  <data name="fabricante" xml:space="preserve">
    <value>Manufacturer</value>
    <comment>Seminterminados FFS</comment>
  </data>
  <data name="familia" xml:space="preserve">
    <value>Family</value>
    <comment>Seminterminados FFS</comment>
  </data>
  <data name="BlankRange" xml:space="preserve">
    <value>Blank range</value>
    <comment>BlankRange</comment>
  </data>
  <data name="info_no_fabricara" xml:space="preserve">
    <value>Esta lente no se fabricará</value>
  </data>
  <data name="aviso_fallo_co_proxy" xml:space="preserve">
    <value>Hay una versión más actualizada que la que está utilizando que no se ha podido instalar. Póngase en contacto con INDO para analizar el motivo</value>
  </data>
  <data name="tooltip_biselar_sin_pulir" xml:space="preserve">
    <value>Para monturas de acetato y metal el bisel siempre será sin pulir</value>
  </data>
  <data name="aviso_faltan_datos_montaje" xml:space="preserve">
    <value>Datos de Montaje erróneos. Verifique los datos</value>
  </data>
  <data name="aviso_faltan_datos_para_montaje" xml:space="preserve">
    <value>Debes completar todos los datos de montaje</value>
  </data>
  <data name="indobisel" xml:space="preserve">
    <value>» Indobisel</value>
  </data>
  <data name="aviso_biselar_por_defecto" xml:space="preserve">
    <value>Biselar por defecto</value>
  </data>
  <data name="fecha_llegada_real" xml:space="preserve">
    <value>Fecha Llegada:</value>
  </data>
  <data name="tooltip_biselar_por_defecto" xml:space="preserve">
    <value>Al seleccionarlo, al hacer un pedido con forma se marcará de manera automática Biselar Lente. Siempre lo podrás deseleccionar.</value>
  </data>
  <data name="indobox" xml:space="preserve">
    <value>Indobox</value>
  </data>
  <data name="favoritos" xml:space="preserve">
    <value>Favorites</value>
  </data>
  <data name="aviso_borrar_pedido" xml:space="preserve">
    <value>Borrar pedido</value>
  </data>
  <data name="aviso_error_buscando_datos" xml:space="preserve">
    <value>Error buscando datos</value>
  </data>
  <data name="aviso_error_enviando_mensaje_SRI" xml:space="preserve">
    <value>Error enviando mensaje a SRI</value>
  </data>
  <data name="aviso_no_hay_incidencias" xml:space="preserve">
    <value>No se encuentran Incidencias en este formulario</value>
  </data>
  <data name="aviso_no_hay_tema" xml:space="preserve">
    <value>No se ha definido el Tema de la incidencia en este formulario</value>
  </data>
  <data name="aviso_tema_no_encontrado" xml:space="preserve">
    <value>No se encuentra el tema especificado</value>
  </data>
  <data name="email_contacto" xml:space="preserve">
    <value>Email de contacto</value>
  </data>
  <data name="errores_en_formulario" xml:space="preserve">
    <value>Se han encontrado errores en el formulario</value>
  </data>
  <data name="ficheros_adjuntos" xml:space="preserve">
    <value>Ficheros adjuntos</value>
  </data>
  <data name="fin_ficheros_adjuntos" xml:space="preserve">
    <value>Fin de ficheros adjuntos</value>
  </data>
  <data name="motivos" xml:space="preserve">
    <value>Motivos</value>
  </data>
  <data name="motivo_no_definido" xml:space="preserve">
    <value>No se ha definido el Motivo de la incidencia en este formulario</value>
  </data>
  <data name="no_hay_motivos" xml:space="preserve">
    <value>No hay motivos</value>
  </data>
  <data name="sin_ficheros_adjuntos" xml:space="preserve">
    <value>sin ficheros adjuntos</value>
  </data>
  <data name="sin_observaciones" xml:space="preserve">
    <value>sin observaciones</value>
  </data>
  <data name="tema" xml:space="preserve">
    <value>Tema</value>
  </data>
  <data name="aviso_anadir_favorito" xml:space="preserve">
    <value>Haz click para añadir a favoritos</value>
  </data>
  <data name="aviso_falta_valor_en_motivo" xml:space="preserve">
    <value>No se ha definido un valor para el motivo {0}</value>
  </data>
  <data name="aviso_no_posible_anadir_favorito" xml:space="preserve">
    <value>No se puede añadir esta lente a favoritos</value>
  </data>
  <data name="establecer_favoritos_a_todos_clientes" xml:space="preserve">
    <value>Establecer favoritos a todos los establecimientos</value>
  </data>
  <data name="aviso_no_posible_quitar_favorito" xml:space="preserve">
    <value>No es posible eliminar de favoritos</value>
  </data>
  <data name="aviso_quitar_favorito" xml:space="preserve">
    <value>Haz click para eliminar de favoritos</value>
  </data>
  <data name="contacto" xml:space="preserve">
    <value>Contacto</value>
  </data>
  <data name="url_contacto" xml:space="preserve">
    <value>http://www.indobox-indo.com/front/incidencias</value>
    <comment>Links</comment>
  </data>
  <data name="configuracion_link_manualFavoritas" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/Indonet/Enlaces/ManualFavoritas_ES.pdf</value>
  </data>
  <data name="configuracion_manualFavoritas" xml:space="preserve">
    <value>Manual Favoritas</value>
  </data>
  <data name="mensaje_pedido_duplicado" xml:space="preserve">
    <value>ATENCIÓN. Posible Pedido Duplicado. Realizado en Fecha: {0}. Con Nº de {1}: {2}</value>
  </data>
  <data name="pedido_duplicado" xml:space="preserve">
    <value>PEDIDO DUPLICADO</value>
  </data>
  <data name="receta" xml:space="preserve">
    <value>Receta Guardada</value>
  </data>
  <data name="no_mostrar_aviso" xml:space="preserve">
    <value>No volver a mostrar este aviso</value>
  </data>
  <data name="error_modificando_noticia_emergente" xml:space="preserve">
    <value>Error modificando noticia emergente</value>
  </data>
  <data name="cliente_no_autenticado" xml:space="preserve">
    <value>Cliente no autenticado. Reinicie Indonet</value>
    <comment>FFS</comment>
  </data>
  <data name="cliente_no_dispone_permisos_para_ejecutar_operacion_indicada" xml:space="preserve">
    <value>Su cliente no dispone de permisos para ejecutar la operacin indicada</value>
    <comment>FFS</comment>
  </data>
  <data name="cliente_no_identificado" xml:space="preserve">
    <value>Cliente no identificado</value>
    <comment>FFS</comment>
  </data>
  <data name="cliente_no_tiene_configuracion_creada" xml:space="preserve">
    <value>El cliente no tiene configuración creada</value>
    <comment>FFS</comment>
  </data>
  <data name="cliente_no_tiene_historico_de_crédito" xml:space="preserve">
    <value>El cliente no tiene histórico de crédito</value>
    <comment>FFS</comment>
  </data>
  <data name="cliente_no_tiene_productos_asignados" xml:space="preserve">
    <value>El cliente no tiene productos asignados</value>
    <comment>FFS</comment>
  </data>
  <data name="cliente_sin_codigo_sucursal_configurado" xml:space="preserve">
    <value>Su cliente no tiene codigo de sucursal (_BRANCH) configurado</value>
    <comment>FFS</comment>
  </data>
  <data name="cliente_sin_credito" xml:space="preserve">
    <value>El cliente no ha sido correctamente creado. Crédito inexistente</value>
    <comment>FFS</comment>
  </data>
  <data name="codigo_producto_incorrecto" xml:space="preserve">
    <value>Código de producto incorrecto. Debe ser numerico</value>
    <comment>FFS</comment>
  </data>
  <data name="consulta_bases_con_error" xml:space="preserve">
    <value>Consulta de bases con error</value>
    <comment>FFS</comment>
  </data>
  <data name="diseño_no_encontrado" xml:space="preserve">
    <value>Diseño no encontrado</value>
    <comment>FFS</comment>
  </data>
  <data name="error_al_leer_fichero" xml:space="preserve">
    <value>Error al leer fichero</value>
    <comment>FFS</comment>
  </data>
  <data name="error_al_obtener_numero_job" xml:space="preserve">
    <value>Error al obtener el numero de job</value>
    <comment>FFS</comment>
  </data>
  <data name="error_al_obtener_numero_sesion" xml:space="preserve">
    <value>Error al obtener el numero de sesion</value>
    <comment>FFS</comment>
  </data>
  <data name="error_al_salvar_los_datos" xml:space="preserve">
    <value>Error al salvar los datos</value>
    <comment>FFS</comment>
  </data>
  <data name="error_autenticación_servicio_solicitante_datos" xml:space="preserve">
    <value>Error en la autenticación del servicio solicitante de datos</value>
    <comment>FFS</comment>
  </data>
  <data name="error_conexion_BDD" xml:space="preserve">
    <value>Error de conexion a la BD</value>
    <comment>FFS</comment>
  </data>
  <data name="error_conexion_con_SAP" xml:space="preserve">
    <value>Error de conexión con SAP</value>
    <comment>FFS</comment>
  </data>
  <data name="error_consultar_tabla_ProductoCategoria" xml:space="preserve">
    <value>Error al consultar la tabla de ProductoCategoria</value>
    <comment>FFS</comment>
  </data>
  <data name="error_consultar_tabla_productoParametros" xml:space="preserve">
    <value>Error al consultar la tabla de ProductoParametros</value>
    <comment>FFS</comment>
  </data>
  <data name="error_llamada_servicio_de_SAP" xml:space="preserve">
    <value>Error en la llamada al servicio de SAP</value>
    <comment>FFS</comment>
  </data>
  <data name="error_obtener_forma_basica" xml:space="preserve">
    <value>Error al obtener la forma básica</value>
    <comment>FFS</comment>
  </data>
  <data name="error_usuario_o_clave_no_validos" xml:space="preserve">
    <value>Usuario o clave no válidos</value>
    <comment>FFS</comment>
  </data>
  <data name="error_validacion_sesion" xml:space="preserve">
    <value>Error en la validacion de sesion</value>
    <comment>FFS</comment>
  </data>
  <data name="error_validación_sesion" xml:space="preserve">
    <value>Error en la validación de sesión</value>
    <comment>FFS</comment>
  </data>
  <data name="forma_basica_indicada_no_disponible_en_este_momento" xml:space="preserve">
    <value>: La forma básica indicada no se encuentra disponible en este momento</value>
    <comment>FFS</comment>
  </data>
  <data name="incongruencia_tablas_producto_categoria" xml:space="preserve">
    <value>Incongruencia de tablas producto_categoria</value>
    <comment>FFS</comment>
  </data>
  <data name="incongruencia_tablas_producto_parametros" xml:space="preserve">
    <value>Incongruencia de tablas producto_parametros</value>
    <comment>FFS</comment>
  </data>
  <data name="indice_material_no_valido" xml:space="preserve">
    <value>El índice no es un índice de material válido en la oferta actual</value>
    <comment>FFS</comment>
  </data>
  <data name="jerarquia_incorrecta" xml:space="preserve">
    <value>Jerarquia incorrecta</value>
    <comment>FFS</comment>
  </data>
  <data name="job_no_se_encuentra" xml:space="preserve">
    <value>El job no se encuentra</value>
    <comment>FFS</comment>
  </data>
  <data name="no_anillas_para_este_taller" xml:space="preserve">
    <value>No hay anillas para este taller</value>
    <comment>FFS</comment>
  </data>
  <data name="no_hay_familias_para_este_taller" xml:space="preserve">
    <value>No hay familias para este taller</value>
    <comment>FFS</comment>
  </data>
  <data name="no_hay_macros_talla_para_este_taller" xml:space="preserve">
    <value>No hay macros de talla para este taller</value>
    <comment>FFS</comment>
  </data>
  <data name="no_hay_marcas_laser_para_este_taller" xml:space="preserve">
    <value>No hay marcas láser para este taller</value>
    <comment>FFS</comment>
  </data>
  <data name="no_hay_ningun_st_para_este_taller" xml:space="preserve">
    <value>No hay ningún st para este taller</value>
    <comment>FFS</comment>
  </data>
  <data name="no_hay_peticiones_talla_en_periodo_indicado" xml:space="preserve">
    <value>No hay peticiones de talla en el periodo indicado</value>
    <comment>FFS</comment>
  </data>
  <data name="no_hay_restricciones_de_espesores_borde_para_este_taller" xml:space="preserve">
    <value>No hay restricciones de espesores de borde definidas para este taller</value>
    <comment>FFS</comment>
  </data>
  <data name="no_hay_restricciones_de_espesores_para_este_taller" xml:space="preserve">
    <value>No hay restricciones de espesores definidas para este taller</value>
    <comment>FFS</comment>
  </data>
  <data name="password_no_validado" xml:space="preserve">
    <value>Password actual no validado</value>
    <comment>FFS</comment>
  </data>
  <data name="sesion_calculo_no_valida" xml:space="preserve">
    <value>Sesion de calculo no valida</value>
    <comment>FFS</comment>
  </data>
  <data name="sesion_ha_caducado" xml:space="preserve">
    <value>La sesión ha caducado. Salga y entre de nuevo en Indonet</value>
    <comment>FFS</comment>
  </data>
  <data name="sesion_no_valida" xml:space="preserve">
    <value>Sesión inválida</value>
    <comment>FFS</comment>
  </data>
  <data name="usuario_o_clave_no_correctos" xml:space="preserve">
    <value>Usuario o clave no correctos</value>
    <comment>FFS</comment>
  </data>
  <data name="cantidad_mayor_que_cero" xml:space="preserve">
    <value>En existencia</value>
  </data>
  <data name="descripcion" xml:space="preserve">
    <value>Descripción</value>
  </data>
  <data name="filtro_descripcion" xml:space="preserve">
    <value>Filtro Descripción</value>
  </data>
  <data name="filtro_fabricante" xml:space="preserve">
    <value>Filtro Fabricante</value>
  </data>
  <data name="base_optima" xml:space="preserve">
    <value>Base Óptima</value>
  </data>
  <data name="borrar" xml:space="preserve">
    <value>Borrar</value>
  </data>
  <data name="densidad" xml:space="preserve">
    <value>Densidad</value>
  </data>
  <data name="foco" xml:space="preserve">
    <value>Foco</value>
  </data>
  <data name="guardar" xml:space="preserve">
    <value>Guardar</value>
  </data>
  <data name="modificar" xml:space="preserve">
    <value>Modificar</value>
  </data>
  <data name="nuevo" xml:space="preserve">
    <value>Nuevo</value>
  </data>
  <data name="base_nominal" xml:space="preserve">
    <value>Base Nominal</value>
    <comment>FFS</comment>
  </data>
  <data name="bonos" xml:space="preserve">
    <value>Bonos</value>
    <comment>FFS</comment>
  </data>
  <data name="bonus_clicks" xml:space="preserve">
    <value>Bonus clicks</value>
    <comment>FFS</comment>
  </data>
  <data name="bonus_clicks_local" xml:space="preserve">
    <value>Bonus clicks local</value>
    <comment>FFS</comment>
  </data>
  <data name="bonus_local" xml:space="preserve">
    <value>Bonus local</value>
    <comment>FFS</comment>
  </data>
  <data name="bonus_restantes" xml:space="preserve">
    <value>Bonus restantes</value>
    <comment>FFS</comment>
  </data>
  <data name="recargar" xml:space="preserve">
    <value>Recargar</value>
    <comment>FFS</comment>
  </data>
  <data name="ventana_espera_recuperar_credito" xml:space="preserve">
    <value>RECUPERANDO CREDITO</value>
    <comment>FFS</comment>
  </data>
  <data name="ventana_espera_recuperar_fabricantes" xml:space="preserve">
    <value>RECUPERANDO FABRICANTES	</value>
    <comment>FFS</comment>
  </data>
  <data name="ventana_espera_recuperar_familias" xml:space="preserve">
    <value>RECUPERANDO FAMILIAS	</value>
    <comment>FFS</comment>
  </data>
  <data name="ventana_espera_recuperar_semiterminados" xml:space="preserve">
    <value>RECUPERANDO SEMITERMINADOS</value>
    <comment>FFS</comment>
  </data>
  <data name="pedir_montura" xml:space="preserve">
    <value>Pedir Montura</value>
    <comment>FormaRemota</comment>
  </data>
  <data name="borrar_mi_biselado" xml:space="preserve">
    <value>Eliminar de Mis Biselados</value>
    <comment>Borrado MiBiselado</comment>
  </data>
  <data name="confirmar_borrar_forma" xml:space="preserve">
    <value>¿Confirma el borrado de esta forma ?</value>
    <comment>Borrado MiBiselado</comment>
  </data>
  <data name="montura_seleccionada" xml:space="preserve">
    <value>Montura seleccionada</value>
    <comment>FormaRemota</comment>
  </data>
  <data name="tooltip_anadir_montura_en_tracer" xml:space="preserve">
    <value>Montura seleccionada en este pedido</value>
    <comment>FormaRemota</comment>
  </data>
  <data name="tooltip_boton_anadir_montura" xml:space="preserve">
    <value>Seleccionar una montura para este pedido</value>
    <comment>FormaRemota</comment>
  </data>
  <data name="ventana_espera_borrar_forma" xml:space="preserve">
    <value>BORRANDO FORMA</value>
    <comment>Borrado MiBiselado</comment>
  </data>
  <data name="base_automatica" xml:space="preserve">
    <value>Base Automática</value>
  </data>
  <data name="altura_pasillo" xml:space="preserve">
    <value>Altura de pasillo</value>
  </data>
  <data name="biselado_remoto" xml:space="preserve">
    <value>Biselado Remoto</value>
  </data>
  <data name="descentramiento" xml:space="preserve">
    <value>Descentramiento</value>
  </data>
  <data name="espesor_especial" xml:space="preserve">
    <value>Espesor Especial</value>
  </data>
  <data name="otros_datos" xml:space="preserve">
    <value>OTROS DATOS</value>
  </data>
  <data name="precalibrado" xml:space="preserve">
    <value>Precalibrado</value>
  </data>
  <data name="prisma" xml:space="preserve">
    <value>Prisma</value>
  </data>
  <data name="url_contacto_indobox" xml:space="preserve">
    <value>www.indobox-indo.com</value>
  </data>
  <data name="diametro_resultante" xml:space="preserve">
    <value>Diametro Resultante</value>
  </data>
  <data name="espesor_taladro_minimo" xml:space="preserve">
    <value>Esp.Taladro Mínimo</value>
  </data>
  <data name="anadir" xml:space="preserve">
    <value>Añadir</value>
  </data>
  <data name="eliminar_seleccionados" xml:space="preserve">
    <value>Eliminar seleccionados</value>
  </data>
  <data name="exportar" xml:space="preserve">
    <value>Exportar</value>
  </data>
  <data name="importar" xml:space="preserve">
    <value>Importar</value>
  </data>
  <data name="datos_opcionales" xml:space="preserve">
    <value>Ver datos adicionales opcionales</value>
  </data>
  <data name="setup_laboratorios" xml:space="preserve">
    <value>Lab Settings</value>
  </data>
  <data name="diametro_resultante_abrev" xml:space="preserve">
    <value>Dia. Result.</value>
  </data>
  <data name="espesor_taladro_minimo_abrev" xml:space="preserve">
    <value>Esp.Tal.Mín</value>
  </data>
  <data name="cargar_imagenes" xml:space="preserve">
    <value>Cargar Imágenes</value>
    <comment>IndoMediCare</comment>
  </data>
  <data name="codigo_paciente" xml:space="preserve">
    <value>Código de cliente</value>
    <comment>IndoMediCare</comment>
  </data>
  <data name="comentarios" xml:space="preserve">
    <value>Comentarios</value>
    <comment>IndoMediCare</comment>
  </data>
  <data name="condicion_previa" xml:space="preserve">
    <value>Condición Previa</value>
    <comment>IndoMediCare</comment>
  </data>
  <data name="diabetes" xml:space="preserve">
    <value>Diabetes</value>
    <comment>IndoMediCare</comment>
  </data>
  <data name="download" xml:space="preserve">
    <value>Download</value>
    <comment>IndoMediCare</comment>
  </data>
  <data name="edad" xml:space="preserve">
    <value>Edad</value>
    <comment>IndoMediCare</comment>
  </data>
  <data name="enfermedad_autoinmune" xml:space="preserve">
    <value>Enfermedad autoinmune</value>
    <comment>IndoMediCare</comment>
  </data>
  <data name="genero" xml:space="preserve">
    <value>Género</value>
    <comment>IndoMediCare</comment>
  </data>
  <data name="hipertension" xml:space="preserve">
    <value>Hipertensión</value>
    <comment>IndoMediCare</comment>
  </data>
  <data name="hombre" xml:space="preserve">
    <value>Hombre</value>
    <comment>IndoMediCare</comment>
  </data>
  <data name="mujer" xml:space="preserve">
    <value>Mujer</value>
    <comment>IndoMediCare</comment>
  </data>
  <data name="normal" xml:space="preserve">
    <value>Normal (48 hrs)</value>
    <comment>IndoMediCare</comment>
  </data>
  <data name="puntual" xml:space="preserve">
    <value>Puntual</value>
    <comment>IndoMediCare</comment>
  </data>
  <data name="seguimiento" xml:space="preserve">
    <value>Seguimiento</value>
    <comment>IndoMediCare</comment>
  </data>
  <data name="tipo_diagnostico" xml:space="preserve">
    <value>Tipo de diagnóstico</value>
    <comment>IndoMediCare</comment>
  </data>
  <data name="tipo_servicio" xml:space="preserve">
    <value>Tipo de servicio</value>
    <comment>IndoMediCare</comment>
  </data>
  <data name="toma_medicacion" xml:space="preserve">
    <value>Toma Medicación</value>
    <comment>IndoMediCare</comment>
  </data>
  <data name="upload" xml:space="preserve">
    <value>Upload</value>
    <comment>IndoMediCare</comment>
  </data>
  <data name="urgente" xml:space="preserve">
    <value>Urgente (24 hrs)</value>
    <comment>IndoMediCare</comment>
  </data>
  <data name="visualizar" xml:space="preserve">
    <value>Visualizar</value>
    <comment>IndoMedCare</comment>
  </data>
  <data name="crear_caso" xml:space="preserve">
    <value>Crear caso</value>
  </data>
  <data name="subir_imagenes" xml:space="preserve">
    <value>Subir imágenes</value>
  </data>
  <data name="con_prisma" xml:space="preserve">
    <value>Prismatic</value>
    <comment>FFS</comment>
  </data>
  <data name="sin_prisma" xml:space="preserve">
    <value>No Prismatic</value>
    <comment>FFS</comment>
  </data>
  <data name="indoCenter_popup_titulo" xml:space="preserve">
    <value>Seleccionar Lecturas de IndoCenter</value>
  </data>
  <data name="nombre" xml:space="preserve">
    <value>Nombre</value>
  </data>
  <data name="enviado_a_oftalmologo" xml:space="preserve">
    <value>Enviado a oftalmólogo</value>
  </data>
  <data name="enviado_a_optico" xml:space="preserve">
    <value>Enviado a óptico</value>
  </data>
  <data name="error_busqueda_expedientes" xml:space="preserve">
    <value>Error en la búsqueda de expedientes</value>
  </data>
  <data name="expediente" xml:space="preserve">
    <value>Expediente</value>
  </data>
  <data name="fecha_fin" xml:space="preserve">
    <value>Fecha Fin</value>
  </data>
  <data name="prioridad" xml:space="preserve">
    <value>Prioridad</value>
  </data>
  <data name="realizados_pedidos" xml:space="preserve">
    <value>Realizados pedidos</value>
  </data>
  <data name="rechazado_oftalmologo" xml:space="preserve">
    <value>Rechazado oftalmólogo</value>
  </data>
  <data name="recibido_de_oftalmologo" xml:space="preserve">
    <value>Recibido de oftalmólogo</value>
  </data>
  <data name="recibido_de_optico" xml:space="preserve">
    <value>Recibido de óptico</value>
  </data>
  <data name="cargar_fichero" xml:space="preserve">
    <value>Cargar fichero</value>
  </data>
  <data name="recuperar_imagenes" xml:space="preserve">
    <value>Recuperar imágenes</value>
  </data>
  <data name="diagnosticar" xml:space="preserve">
    <value>Diagnosticar</value>
  </data>
  <data name="diagnostico" xml:space="preserve">
    <value>Diagnostico</value>
  </data>
  <data name="no" xml:space="preserve">
    <value>NO</value>
  </data>
  <data name="resultados" xml:space="preserve">
    <value>Resultados</value>
  </data>
  <data name="si" xml:space="preserve">
    <value>SI</value>
  </data>
  <data name="generar_informe" xml:space="preserve">
    <value>Firmar y Generar Informe</value>
  </data>
  <data name="aviso_configuracion_favoritos" xml:space="preserve">
    <value>Configura tu lista de lentes favoritas y realiza tus pedidos de una manera más ágil y eficaz.</value>
  </data>
  <data name="desmarcar_todos" xml:space="preserve">
    <value>Desmarcar Todos</value>
  </data>
  <data name="favoritas" xml:space="preserve">
    <value>Favoritas</value>
  </data>
  <data name="marcar_todos" xml:space="preserve">
    <value>Marcar Todos</value>
  </data>
  <data name="no_favoritas" xml:space="preserve">
    <value>NO FAVORITAS</value>
  </data>
  <data name="aviso_espera" xml:space="preserve">
    <value>Espera</value>
  </data>
  <data name="firmar" xml:space="preserve">
    <value>Firmar</value>
  </data>
  <data name="rechazar" xml:space="preserve">
    <value>Rechazar</value>
  </data>
  <data name="anulado_optico" xml:space="preserve">
    <value>Anulado óptico</value>
  </data>
  <data name="descargar_diagnostico" xml:space="preserve">
    <value>Descargar Diagnóstico</value>
  </data>
  <data name="diagnosticado" xml:space="preserve">
    <value>Diagnosticado</value>
  </data>
  <data name="pendiente_diagnostico" xml:space="preserve">
    <value>Pendiente Diagnóstico</value>
  </data>
  <data name="rechazado" xml:space="preserve">
    <value>Rechazado</value>
  </data>
  <data name="ver_certificado" xml:space="preserve">
    <value>Ver certificado</value>
  </data>
  <data name="anexo_medicare" xml:space="preserve">
    <value>El presente informe es orientativo y solo pretende describir en un momento concreto los aspectos morfológicos de la retina a través de una fotografía. Como es bien sabido, el proceso diagnóstico es fruto de la interacción de diferentes parámetros como son la anamnesis, exploración clínica, pruebas complementarias y razonamiento final con la emisión de la Orientación Diagnóstica. Por lo cual, el presente informe no constituye un elemento exclusivo de diagnóstico, debiendo considerarse solo como descriptivo morfológico.</value>
  </data>
  <data name="copiar_carpeta" xml:space="preserve">
    <value>Bajar información a PC</value>
  </data>
  <data name="rechazar_caso" xml:space="preserve">
    <value>Devolver Caso a Optico</value>
  </data>
  <data name="texto_medCare_1" xml:space="preserve">
    <value>Bienvenidos a la nueva plataforma de telemedicina IndoMedCare.</value>
  </data>
  <data name="texto_medCare_2" xml:space="preserve">
    <value>La plataforma IndoMedCare utiliza las últimas tecnologías de captura y transmisión de imágenes para enlazar ópticos y oftalmólogos, facilitando así el acceso del público en general a diagnósticos de algunas de las patologías que tienen origen en la retina.</value>
  </data>
  <data name="texto_medCare_3" xml:space="preserve">
    <value>Las enfermedades oculares en la retina no son las de mayor incidencia, pero son las que crean mayores problemas en la calidad de vida en las personas.</value>
  </data>
  <data name="texto_medCare_4" xml:space="preserve">
    <value>Gracias por preferir la plataforma IndoMedCare.</value>
  </data>
  <data name="texto_medCare_5" xml:space="preserve">
    <value>Los informes que se emitan serán orientativos y solo pretenden describir en un momento concreto los aspectos morfológicos de la retina a través de una fotografía. Como es bien sabido, el proceso diagnóstico es fruto de la interacción de diferentes parámetros como son la anamnesis, exploración clínica, pruebas complementarias y razonamiento final con la emisión de  la Orientación Diagnóstica. Por lo cual,  los informes no constituirán en ningún momento un elemento exclusivo de diagnóstico, debiendo considerarse solo como descriptivo morfológico.</value>
  </data>
  <data name="isotipo_en_maxvita" xml:space="preserve">
    <value>Isotype</value>
  </data>
  <data name="isotipo_rodenstock" xml:space="preserve">
    <value>Isotype RODENSTOCK</value>
  </data>
  <data name="error_debe_seleccionar_lectura" xml:space="preserve">
    <value>Debe seleccionar una lectura</value>
  </data>
  <data name="aviso_indocenter_altura_boxing" xml:space="preserve">
    <value>Para este pedido hemos cambiado la configuración a BOXING. Tenlo en cuenta si ahora y en este pedido vas a modificar algún valor de los importados por IndoCenter.</value>
  </data>
  <data name="base_gafa" xml:space="preserve">
    <value>BASE GAFA</value>
  </data>
  <data name="aviso_faltan_datos_en_prisma" xml:space="preserve">
    <value>No se ha especificado el eje o la base del prisma</value>
  </data>
  <data name="aviso_poner_pedido_en_montura" xml:space="preserve">
    <value>Recuerda poner el número de pedido en el envío de la montura: {0}</value>
  </data>
  <data name="aviso_desactivar_isotipo" xml:space="preserve">
    <value>En pedidos sin forma o con forma básica no se puede solicitar isotipo. Desactive isotipo</value>
  </data>
  <data name="aviso_diferentes_alturas" xml:space="preserve">
    <value>Diferencia entre DNP’s o ALTURAS MONT superior a la recomendada.</value>
  </data>
  <data name="configuracion_FAP" xml:space="preserve">
    <value>Formulario Adaptación Progresivos (FAP)</value>
  </data>
  <data name="configuracion_link_FAP" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/Indonet/Enlaces/FAP_ES.pdf</value>
  </data>
  <data name="cargar_desde_navis_ex" xml:space="preserve">
    <value>Cargar desde NAVIS</value>
  </data>
  <data name="caso_manual" xml:space="preserve">
    <value>Caso Manual</value>
  </data>
  <data name="antecedentes_familiares" xml:space="preserve">
    <value>Antecedentes patológicos y familiares</value>
    <comment>IndoMedCare</comment>
  </data>
  <data name="ancho_ranura" xml:space="preserve">
    <value>Ancho ranura</value>
  </data>
  <data name="configurar" xml:space="preserve">
    <value>Configurar</value>
  </data>
  <data name="espesor_minimo_de_borde" xml:space="preserve">
    <value>Espesor mínimo de borde</value>
  </data>
  <data name="profundidad_ranura" xml:space="preserve">
    <value>Profundidad ranura</value>
  </data>
  <data name="acepta_condiciones_solicitud_recogida" xml:space="preserve">
    <value>Acepto las condiciones asociadas al servicio de Solicitud de recogida</value>
  </data>
  <data name="aviso_montaje" xml:space="preserve">
    <value>HAS SOLICITADO UN PEDIDO DE LENTES CON MONTAJE. ES OBLIGATORIO IMPRIMIR EL PEDIDO Y ADJUNTARLO A LA GAFA ASOCIADA</value>
  </data>
  <data name="garantia" xml:space="preserve">
    <value>Garantía</value>
  </data>
  <data name="imprimir_pedido" xml:space="preserve">
    <value>Imprimir pedido</value>
  </data>
  <data name="recogida_para_garantia" xml:space="preserve">
    <value>Recogida para Devolución</value>
  </data>
  <data name="recogida_para_montaje" xml:space="preserve">
    <value>Recogida para montaje</value>
  </data>
  <data name="solicitud_recogida" xml:space="preserve">
    <value>Solicitud de recogida</value>
  </data>
  <data name="aviso_debe_aceptar_condiciones" xml:space="preserve">
    <value>Debe aceptar primero las condiciones marcando el checkbox.</value>
  </data>
  <data name="error_solicitando_recogida" xml:space="preserve">
    <value>Error al solicitar la recogida</value>
  </data>
  <data name="solicitar_recogida_negativa" xml:space="preserve">
    <value>Hoy ya has solicitado una recogida. Hasta mañana no podrás solicitar una nueva.</value>
  </data>
  <data name="solicitar_recogida_positiva" xml:space="preserve">
    <value>Solicitud de recogida realizada.</value>
  </data>
  <data name="desea_recogida" xml:space="preserve">
    <value>¿Desea realizar una recogida para este montaje?</value>
  </data>
  <data name="leer_condiciones_montaje" xml:space="preserve">
    <value>Leer condiciones de montaje</value>
  </data>
  <data name="quiero_solicitar_recogida" xml:space="preserve">
    <value>Quiero solicitar una recogida en:</value>
  </data>
  <data name="texto_acepto_condiciones_montaje" xml:space="preserve">
    <value>He leído y acepto las condiciones asociadas al suplemento de montaje</value>
  </data>
  <data name="aviso_faltan_datos_montura" xml:space="preserve">
    <value>Debes completar los datos de la montura con el botón Poner Datos</value>
  </data>
  <data name="poner_datos" xml:space="preserve">
    <value>Poner datos</value>
  </data>
  <data name="texto_acepta_condiciones_recogida" xml:space="preserve">
    <value>Al aceptar las condiciones estás aceptando que un mensajero recoja un paquete en la dirección de tu código de cliente con la/s monturas para proceder al montaje de las lentes asociadas a ella/s.
El precio del servicio de recogida es el que aparece en la pestaña “Mi Buzón” y aceptando estas condiciones estás aceptando el cobro de este servicio por el precio indicado.
El servicio de recogida lo podrás solicitar en cualquier momento desde la pestaña “Mi Buzón”y sólo se puede solicitar una recogida al día.</value>
  </data>
  <data name="aviso_si_quiere_recogida_llamar_ATC" xml:space="preserve">
    <value>Si deseas solicitar una recogida para el montaje solicitado debes llamar a Atención al Cliente</value>
  </data>
  <data name="texto_soporte_online" xml:space="preserve">
    <value>Si nuestro personal de atención al cliente te lo indica, accede a este enlace para obtener soporte online</value>
  </data>
  <data name="url_TeamViewerQS" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/tools/TeamViewerQS_es-idcafqhj4r.exe</value>
  </data>
  <data name="aviso_leer_condiciones_borrar_pedido" xml:space="preserve">
    <value>Borrar pedido: lee las condiciones de anulación de pedidos en el enlace que verás en la parte inferior de la pantalla</value>
  </data>
  <data name="condiciones_anulación_pedidos" xml:space="preserve">
    <value>Política de anulaciones y devoluciones de pedidos</value>
  </data>
  <data name="condiciones_comerciales" xml:space="preserve">
    <value>Condiciones Comerciales</value>
  </data>
  <data name="estadisticas" xml:space="preserve">
    <value>Estadísticas</value>
  </data>
  <data name="estadisticas_consumo" xml:space="preserve">
    <value>Estadísticas de consumo</value>
  </data>
  <data name="facturacion" xml:space="preserve">
    <value>Facturación</value>
  </data>
  <data name="indomanager" xml:space="preserve">
    <value>IndoManager</value>
  </data>
  <data name="login" xml:space="preserve">
    <value>Login</value>
  </data>
  <data name="mi_cuenta" xml:space="preserve">
    <value>Mi Cuenta</value>
  </data>
  <data name="modificar_email" xml:space="preserve">
    <value>Modificar email</value>
  </data>
  <data name="url_condiciones_anulacion_pedidos" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/Indonet/Enlaces/anulaciones.pdf</value>
  </data>
  <data name="aviso_comunicacion_indomanager" xml:space="preserve">
    <value>La comunicacion de los datos de la sección IndoManager se enviarán a la dirección de E-Mail que indique a continuación. Asegúrese que es correcta.</value>
  </data>
  <data name="aviso_entrar_email_valido" xml:space="preserve">
    <value>Es obligatorio entrar una dirección email válida</value>
  </data>
  <data name="direccion_email" xml:space="preserve">
    <value>Dirección de email*</value>
  </data>
  <data name="abono" xml:space="preserve">
    <value>Abono</value>
  </data>
  <data name="albaran" xml:space="preserve">
    <value>Albarán</value>
  </data>
  <data name="aviso_saber_consumo_actual" xml:space="preserve">
    <value>¿Quieres saber tu consumo actual desde la última factura?</value>
  </data>
  <data name="bienvenido_a_indomanager" xml:space="preserve">
    <value>Bienvenido a IndoManager, el nuevo servicio de IndoBox con el que podrás gestionar tu negocio de una manera más autónoma y con mayor información a tu alcance cuando la desees.</value>
  </data>
  <data name="calcular" xml:space="preserve">
    <value>Calcular</value>
  </data>
  <data name="calculo_aproximado_consumo" xml:space="preserve">
    <value>Cálculo aproximado basado en los pedidos realizados desde la última factura</value>
  </data>
  <data name="cargo" xml:space="preserve">
    <value>Cargo</value>
  </data>
  <data name="fecha_albaran" xml:space="preserve">
    <value>Fecha de Albarán</value>
  </data>
  <data name="fecha_factura" xml:space="preserve">
    <value>Fecha Factura</value>
  </data>
  <data name="importe" xml:space="preserve">
    <value>Importe</value>
  </data>
  <data name="importe_bruto" xml:space="preserve">
    <value>Importe bruto</value>
  </data>
  <data name="importe_neto" xml:space="preserve">
    <value>Importe neto</value>
  </data>
  <data name="importe_total" xml:space="preserve">
    <value>Importe total</value>
  </data>
  <data name="iniciar_sesion" xml:space="preserve">
    <value>Iniciar sesión</value>
  </data>
  <data name="iva" xml:space="preserve">
    <value>IVA</value>
  </data>
  <data name="no_albaran" xml:space="preserve">
    <value>Nº Albarán</value>
  </data>
  <data name="no_factura" xml:space="preserve">
    <value>Nº Factura</value>
  </data>
  <data name="ocultar_detalles" xml:space="preserve">
    <value>Ocultar detalles</value>
  </data>
  <data name="ponte_contacto_comercial" xml:space="preserve">
    <value>Ponte en contacto con tu comercial para que te facilite Usuario y Contraseña.</value>
  </data>
  <data name="solicitante" xml:space="preserve">
    <value>Solicitante</value>
  </data>
  <data name="tipologia" xml:space="preserve">
    <value>Tipología</value>
  </data>
  <data name="todo" xml:space="preserve">
    <value>Todo</value>
  </data>
  <data name="vaciar_campos" xml:space="preserve">
    <value>Vaciar Campos</value>
  </data>
  <data name="ver_detalles" xml:space="preserve">
    <value>Ver detalles</value>
  </data>
  <data name="no_albaranes_obtenidos" xml:space="preserve">
    <value>Albaran(es) obtenido(s)</value>
  </data>
  <data name="no_facturas_obtenidas" xml:space="preserve">
    <value>Factura(s) obtenida(s)</value>
  </data>
  <data name="codigo_cliente_facturacion" xml:space="preserve">
    <value>Código cliente facturación</value>
  </data>
  <data name="ventana_espera_busqueda_albaranes" xml:space="preserve">
    <value>BUSCANDO ALBARANES</value>
  </data>
  <data name="ventana_espera_busqueda_facturas" xml:space="preserve">
    <value>BUSCANDO FACTURAS</value>
  </data>
  <data name="renovar_bono" xml:space="preserve">
    <value>Renovar bono</value>
  </data>
  <data name="aviso_salir_indomanager" xml:space="preserve">
    <value>Por seguridad, quieres cerrar sesión en IndoManager?”</value>
  </data>
  <data name="cerrar_sesion" xml:space="preserve">
    <value>Cerrar sesión</value>
  </data>
  <data name="borrar_pedido_de_cesta" xml:space="preserve">
    <value>Eliminar el pedido de la cesta</value>
  </data>
  <data name="recalcular" xml:space="preserve">
    <value>Recalcular</value>
  </data>
  <data name="actualizar_cesta" xml:space="preserve">
    <value>Guardar modificaciones del pedido en la cesta</value>
  </data>
  <data name="anadir_pedido_a_cesta" xml:space="preserve">
    <value>Añadir pedido a la cesta</value>
  </data>
  <data name="ver_cesta" xml:space="preserve">
    <value>Ver cesta</value>
  </data>
  <data name="enviar_seleccionados" xml:space="preserve">
    <value>Enviar seleccionados</value>
  </data>
  <data name="fecha_prevista_llegada" xml:space="preserve">
    <value>ENTREGA</value>
  </data>
  <data name="desea_añadir_pedido_a_cesta" xml:space="preserve">
    <value>¿Quieres añadirlo a la cesta?</value>
  </data>
  <data name="devolucion" xml:space="preserve">
    <value>Devolución</value>
  </data>
  <data name="devoluciones" xml:space="preserve">
    <value>Pick up</value>
  </data>
  <data name="mi_cesta" xml:space="preserve">
    <value>Mi cesta</value>
  </data>
  <data name="posible_pedido_repetido_en_cesta" xml:space="preserve">
    <value>Posiblemente este pedido ya existe en la cesta.</value>
  </data>
  <data name="referencia_devolucion" xml:space="preserve">
    <value>Referencia devolución</value>
  </data>
  <data name="resumen_pedidos_enviados" xml:space="preserve">
    <value>Resumen de pedidos enviados</value>
  </data>
  <data name="aviso_devolucion_anulada" xml:space="preserve">
    <value>Tu devolución se ha anulado correctamente</value>
  </data>
  <data name="aviso_pedido_no_se_puede_devolver" xml:space="preserve">
    <value>El pedido debe estar enviado para ser devuelto.</value>
  </data>
  <data name="aviso_pedir_devolucion" xml:space="preserve">
    <value>Pedir devolución del pdido</value>
  </data>
  <data name="denominacion" xml:space="preserve">
    <value>Denominación</value>
  </data>
  <data name="fecha_devolucion" xml:space="preserve">
    <value>Fecha Devolución</value>
  </data>
  <data name="fecha_pedido" xml:space="preserve">
    <value>Fecha Pedido</value>
  </data>
  <data name="motivo_devolucion" xml:space="preserve">
    <value>Motivo Devolución</value>
  </data>
  <data name="motivo_rechazo" xml:space="preserve">
    <value>Motivo de rechazo</value>
  </data>
  <data name="ojo" xml:space="preserve">
    <value>Ojo</value>
  </data>
  <data name="pregunta_borrar_devolucion" xml:space="preserve">
    <value>¿Estás seguro que quieres anular la devolución {0} del encargo con número de Pedido {1}?</value>
  </data>
  <data name="recogida" xml:space="preserve">
    <value>Recogidas</value>
  </data>
  <data name="devolucion_correcta" xml:space="preserve">
    <value>Devolución aceptada</value>
  </data>
  <data name="oferta_correcta" xml:space="preserve">
    <value>En estudio</value>
  </data>
  <data name="rechazada" xml:space="preserve">
    <value>Devolución rechazada</value>
  </data>
  <data name="informacion_bonos" xml:space="preserve">
    <value>informacion de bonos</value>
  </data>
  <data name="lentes_incluidas_en_bono" xml:space="preserve">
    <value>Lentes incluidas en el bono</value>
  </data>
  <data name="puntos" xml:space="preserve">
    <value>Puntos</value>
  </data>
  <data name="saldo_despues_grabar_pedido" xml:space="preserve">
    <value>Saldo después de grabar el pedido</value>
  </data>
  <data name="consulta" xml:space="preserve">
    <value>Consulta</value>
  </data>
  <data name="crear_devolucion" xml:space="preserve">
    <value>Crear devolución</value>
  </data>
  <data name="desea_pedir_recogida" xml:space="preserve">
    <value>¿Desea pedir una recogida para estas devoluciones?</value>
  </data>
  <data name="devoluciones_tramitadas" xml:space="preserve">
    <value>Devoluciones tramitadas</value>
  </data>
  <data name="devolucion_en_tramite" xml:space="preserve">
    <value>El pedido ya existe en las devoluciones pendientes de tramitar</value>
  </data>
  <data name="direccion_Maroc" xml:space="preserve">
    <value>Indo Maroc, S.A.R.L Z.I Mghogha, Rte de Tétouan, Allée 1 Lot N° 5 Tanger (Maroc)</value>
  </data>
  <data name="direccion_Papiol" xml:space="preserve">
    <value>Indo Optical. Guttenberg nº 5 (P.I. Les Torrenteres). 08754 El Papiol (Barcelona)</value>
  </data>
  <data name="enviar_a" xml:space="preserve">
    <value>Enviar a</value>
  </data>
  <data name="ir_a_tramitar" xml:space="preserve">
    <value>ir a tramitar</value>
  </data>
  <data name="oferta_devolucion_creada" xml:space="preserve">
    <value>Solicitud devolución creada. No será firme hasta que se tramite a INDO.</value>
  </data>
  <data name="pendientes_tramitacion" xml:space="preserve">
    <value>Pendientes tramitación</value>
  </data>
  <data name="resultado_tramitacion_devoluciones_1" xml:space="preserve">
    <value>Se han enviado {0} devoluciones y {1} son correctas. Puedes ver las correctas en la pestaña de consulta de devoluciones tramitadas.</value>
  </data>
  <data name="resultado_tramitacion_devoluciones_2" xml:space="preserve">
    <value>Ahora se crearán los documentos de las devoluciones. Imprímelos por tu impresora y adjúntalos a las lentes.</value>
  </data>
  <data name="tramitar_devoluciones" xml:space="preserve">
    <value>Tramitar devoluciones</value>
  </data>
  <data name="tramitar_devoluciones_seleccionadas" xml:space="preserve">
    <value>Tramitar devoluciones seleccionadas</value>
  </data>
  <data name="debe_escoger_una_lente" xml:space="preserve">
    <value>Debes escoger como mínimo una lente</value>
  </data>
  <data name="debe_escoger_un_motivo_devolucion" xml:space="preserve">
    <value>Debes escoger un motivo de devolución</value>
  </data>
  <data name="error_en_devolucion" xml:space="preserve">
    <value>Error en la devolución</value>
  </data>
  <data name="esta_lente_no_descuenta_de_bono" xml:space="preserve">
    <value>Esta lente no descuenta de bono</value>
  </data>
  <data name="ha_escogido_esta_devolucion" xml:space="preserve">
    <value>Has escogido esta devolución</value>
  </data>
  <data name="pregunta_desea_continuar" xml:space="preserve">
    <value>¿Deseas continuar?</value>
  </data>
  <data name="con_demora" xml:space="preserve">
    <value>Con Demora</value>
  </data>
  <data name="cumplimiento_de_servicio" xml:space="preserve">
    <value>Cumplimiento de Servicio</value>
  </data>
  <data name="en_plazo" xml:space="preserve">
    <value>En Plazo</value>
  </data>
  <data name="pedidos_laboratorio" xml:space="preserve">
    <value>Pedidos Laboratorio</value>
  </data>
  <data name="pedido_stock" xml:space="preserve">
    <value>Pedido Stock</value>
  </data>
  <data name="resto" xml:space="preserve">
    <value>Resto</value>
  </data>
  <data name="tipos_de_pedido" xml:space="preserve">
    <value>Tipos de pedido</value>
  </data>
  <data name="ventana_espera_busqueda_estadisticas" xml:space="preserve">
    <value>BUSCANDO ESTADISTICAS</value>
  </data>
  <data name="clase_de_devolucion" xml:space="preserve">
    <value>Clase de devolución:</value>
  </data>
  <data name="monofocales" xml:space="preserve">
    <value>Monofocales</value>
  </data>
  <data name="motivo_de_devolucion" xml:space="preserve">
    <value>Motivo de devolución:</value>
  </data>
  <data name="progresivos" xml:space="preserve">
    <value>Progresivos</value>
  </data>
  <data name="añadir_mas_devoluciones" xml:space="preserve">
    <value>Añadir más devoluciones</value>
  </data>
  <data name="año" xml:space="preserve">
    <value>Año</value>
  </data>
  <data name="borrar_devolucion" xml:space="preserve">
    <value>Borrar devolucion</value>
  </data>
  <data name="evolucion" xml:space="preserve">
    <value>Evolución</value>
  </data>
  <data name="mes" xml:space="preserve">
    <value>Mes</value>
  </data>
  <data name="periodo_maximo_es_un_año" xml:space="preserve">
    <value>El Periodo máximo es un año</value>
  </data>
  <data name="datos_generales" xml:space="preserve">
    <value>Datos generales</value>
  </data>
  <data name="solicitar_devolucion" xml:space="preserve">
    <value>Solicitar devolución</value>
  </data>
  <data name="url_fb_indo" xml:space="preserve">
    <value>https://www.facebook.com/IndoEspana/</value>
  </data>
  <data name="aviso_documento_devoluciones_1" xml:space="preserve">
    <value>(Este documento se debe adjuntar con las lentes a devolver de este pedido)</value>
  </data>
  <data name="aviso_documento_devoluciones_2" xml:space="preserve">
    <value>(Este documento se debe adjuntar junto al paquete con los pedidos relacionados a devolver)</value>
  </data>
  <data name="aviso_instalacion_fuente_barcode" xml:space="preserve">
    <value>Se va a instalar una fuente de código de barras necesaria para algunos listados. En la ventana de instalación pulsa instalar y después cierra la ventana tal como indica la imagen inferior.</value>
  </data>
  <data name="aviso_recogida_indonet_gratuita" xml:space="preserve">
    <value>Las recogidas de montajes no tienen coste si se solicitan desde IndoNet</value>
  </data>
  <data name="aviso_montaje_gratuito" xml:space="preserve">
    <value>Las recogidas ordenadas desde IndoNet son gratuitas</value>
  </data>
  <data name="bono_agotandose" xml:space="preserve">
    <value>Bono agotándose</value>
  </data>
  <data name="caducidad" xml:space="preserve">
    <value>Caducidad</value>
  </data>
  <data name="cantidad_consumida" xml:space="preserve">
    <value>Unidades consumidas</value>
  </data>
  <data name="codigo" xml:space="preserve">
    <value>Código</value>
  </data>
  <data name="consumo_inferior_al_optimo" xml:space="preserve">
    <value>Consumo inferior al óptimo</value>
  </data>
  <data name="contratados_en_vigor" xml:space="preserve">
    <value>Contratados en vigor</value>
  </data>
  <data name="busca_informacion_tracking" xml:space="preserve">
    <value>Busca en la web del transportista información del estado del envío</value>
  </data>
  <data name="deseo_recibir_informacion_bonos" xml:space="preserve">
    <value>Deseo recibir información en mi mail cuando uno de los bonos se esté agotando o el consumo sea inferior al óptimo</value>
  </data>
  <data name="estado_de_los_bonos" xml:space="preserve">
    <value>Estado de los bonos</value>
  </data>
  <data name="numero_envio" xml:space="preserve">
    <value>Número de envío</value>
  </data>
  <data name="saldo_bonos" xml:space="preserve">
    <value>Saldo de Unidades y Unidades consumidas</value>
  </data>
  <data name="tracking" xml:space="preserve">
    <value>Tracking</value>
  </data>
  <data name="aviso_mas_365_dias" xml:space="preserve">
    <value>El intervalo de fechas debe ser inferior a 365 días</value>
  </data>
  <data name="motivo_no_aceptacion" xml:space="preserve">
    <value>Motivo no aceptación</value>
  </data>
  <data name="clips" xml:space="preserve">
    <value>CLIPS</value>
  </data>
  <data name="cantidad" xml:space="preserve">
    <value>Ctd.</value>
  </data>
  <data name="existencias" xml:space="preserve">
    <value>En stock</value>
  </data>
  <data name="producto" xml:space="preserve">
    <value>Producto</value>
  </data>
  <data name="ventana_espera_envio_clips" xml:space="preserve">
    <value>ENVIANDO CLIPS</value>
  </data>
  <data name="tu_comercial" xml:space="preserve">
    <value>Tu comercial</value>
  </data>
  <data name="tu_gestor" xml:space="preserve">
    <value>Tu gestor/a</value>
  </data>
  <data name="abonos" xml:space="preserve">
    <value>Abonos</value>
  </data>
  <data name="añadir_archivos" xml:space="preserve">
    <value>Añadir archivos</value>
  </data>
  <data name="comercial" xml:space="preserve">
    <value>Comercial</value>
  </data>
  <data name="consulta_factura" xml:space="preserve">
    <value>Consulta factura</value>
  </data>
  <data name="consulta_indocenter" xml:space="preserve">
    <value>Consulta IndoCenter</value>
  </data>
  <data name="consulta_indonet" xml:space="preserve">
    <value>Consulta indonet</value>
  </data>
  <data name="consulta_indoshow" xml:space="preserve">
    <value>Consulta IndoShow</value>
  </data>
  <data name="consulta_pedido" xml:space="preserve">
    <value>Consulta pedido</value>
  </data>
  <data name="define_el_motivo" xml:space="preserve">
    <value>Define el motivo</value>
  </data>
  <data name="duplicado_factura" xml:space="preserve">
    <value>Duplicado factura</value>
  </data>
  <data name="escribe_los_detalles_complementarios_que_creas_conveniente" xml:space="preserve">
    <value>Escribe los detalles complementarios que creas conveniente</value>
  </data>
  <data name="este_contacto" xml:space="preserve">
    <value>Responder a:</value>
  </data>
  <data name="formacion_visualmap" xml:space="preserve">
    <value>Formación VisualMap</value>
  </data>
  <data name="gestion" xml:space="preserve">
    <value>Gestión</value>
  </data>
  <data name="incidencias_visualmap" xml:space="preserve">
    <value>Incidencias VisualMap</value>
  </data>
  <data name="incidencia_indonet" xml:space="preserve">
    <value>Incidencia indonet</value>
  </data>
  <data name="instalacion_indonet" xml:space="preserve">
    <value>Instalacion indonet</value>
  </data>
  <data name="mes_o_numero_factura" xml:space="preserve">
    <value>Mes o número de factura</value>
  </data>
  <data name="numero_de_bono" xml:space="preserve">
    <value>Número de bono</value>
  </data>
  <data name="oferta_lentes_bono" xml:space="preserve">
    <value>Oferta lentes bono</value>
  </data>
  <data name="otros" xml:space="preserve">
    <value>Otros</value>
  </data>
  <data name="piezas_pendientes_bono" xml:space="preserve">
    <value>Piezas pendientes bono</value>
  </data>
  <data name="promociones" xml:space="preserve">
    <value>Promociones</value>
  </data>
  <data name="referencia_o_numero_de_albaran" xml:space="preserve">
    <value>Referencia o número de albarán</value>
  </data>
  <data name="servicios" xml:space="preserve">
    <value>Servicios</value>
  </data>
  <data name="solicitar_informacion" xml:space="preserve">
    <value>Solicitar información</value>
  </data>
  <data name="solicitar_tarifa" xml:space="preserve">
    <value>Solicitar tarifa</value>
  </data>
  <data name="solicitar_visita" xml:space="preserve">
    <value>Solicitar visita</value>
  </data>
  <data name="tiene_recogida_solicitada" xml:space="preserve">
    <value>Tiene una recogida solicitada: </value>
  </data>
  <data name="tus_datos" xml:space="preserve">
    <value>Tus datos</value>
  </data>
  <data name="tu_peticion" xml:space="preserve">
    <value>Tu petición</value>
  </data>
  <data name="bono" xml:space="preserve">
    <value>Bono</value>
  </data>
  <data name="cantidad_inicial" xml:space="preserve">
    <value>Cantidad inicial</value>
  </data>
  <data name="gama" xml:space="preserve">
    <value>Range:</value>
  </data>
  <data name="indicar_producto_o_tratamiento" xml:space="preserve">
    <value>Indica producto o tratamiento</value>
  </data>
  <data name="numero_de_albaran" xml:space="preserve">
    <value>Nº albarán</value>
  </data>
  <data name="todos_los_pedidos_para_mi" xml:space="preserve">
    <value>Todos los pedidos para mi óptica</value>
  </data>
  <data name="he_olvidado_mi_clave" xml:space="preserve">
    <value>» He olvidado mi clave</value>
  </data>
  <data name="aviso_1_crear_contrasenya_nueva" xml:space="preserve">
    <value>Por favor, indica tu número de cliente y pulsa enviar para solicitar una nueva contraseña.</value>
  </data>
  <data name="aviso_2_crear_contrasenya_nueva" xml:space="preserve">
    <value>En pocos minutos recibirás un mail a tu cuenta de correo registrada con una contraseña temporal</value>
  </data>
  <data name="aviso_cambio_password" xml:space="preserve">
    <value>Se ha creado una nueva contraseña temporal que recibirá en su email ({0}). Con ella puede entrar a indonet y crear una nueva. La contraseña temporal será válida durante un día</value>
  </data>
  <data name="aviso_error_crear_password" xml:space="preserve">
    <value>Ha habido un error al crear la contraseña temporal</value>
  </data>
  <data name="error_clave_no_puede_ser_misma" xml:space="preserve">
    <value>La nueva clave no puede ser la misma que la temporal</value>
  </data>
  <data name="error_numero_caracteres_minimo_en_clave" xml:space="preserve">
    <value>Clave debe tener mínimo 6 caracteres</value>
  </data>
  <data name="aviso_faltan_datos" xml:space="preserve">
    <value>Falta completar datos en el motivo escogido.</value>
  </data>
  <data name="codigo_restablecer_contrasenya" xml:space="preserve">
    <value>Clave de acceso para restablecer contraseña.</value>
  </data>
  <data name="debe_introducir_codigo_cliente" xml:space="preserve">
    <value>Debes introducir tu código de cliente</value>
  </data>
  <data name="equipo_de_indonet" xml:space="preserve">
    <value>El equipo de {AplicacionINDONET}</value>
  </data>
  <data name="este_es_tu_codigo" xml:space="preserve">
    <value>Tu código {codigoIndonet}.</value>
  </data>
  <data name="gracias" xml:space="preserve">
    <value>Gracias</value>
  </data>
  <data name="paswword_actualizado" xml:space="preserve">
    <value>Clave de acceso actualizada</value>
  </data>
  <data name="pregunta_enviar_o_cancelar" xml:space="preserve">
    <value>¿Desea enviar o cancelar?</value>
  </data>
  <data name="Usa_este_codigo_temporal" xml:space="preserve">
    <value>Usa esta clave de acceso temporal para entrar a {AplicacionINDONET} y restablecer la contraseña de tu cuenta</value>
  </data>
  <data name="has_recibido_este_correo_solicitud_contraseña" xml:space="preserve">
    <value>Has recibido esta notificación porque se ha solicitado recordatorio de la clave de acceso de {AplicacionINDONET}.</value>
  </data>
  <data name="si_no_lo_has_solicitado" xml:space="preserve">
    <value>Si no lo has solicitado basta con ignorar este correo y puedes seguir usando tu clave de acceso habitual.</value>
  </data>
  <data name="solicitud_nueva_contraseña" xml:space="preserve">
    <value>Solicitud nueva clave de acceso a {AplicacionINDONET}</value>
  </data>
  <data name="bono_tarifa_plana" xml:space="preserve">
    <value>Bono /Tarifa plana</value>
  </data>
  <data name="cambio_datos_fiscales" xml:space="preserve">
    <value>Cambio datos Fiscales</value>
  </data>
  <data name="contraseña_indomanager" xml:space="preserve">
    <value>Contraseña Indomanager</value>
  </data>
  <data name="detalle_modelo_347" xml:space="preserve">
    <value>Detalle modelo 347</value>
  </data>
  <data name="duplicado_albaran" xml:space="preserve">
    <value>Duplicado Albarán</value>
  </data>
  <data name="duplicado_carta_detalle_bono" xml:space="preserve">
    <value>Duplicado carta detalle bono</value>
  </data>
  <data name="extracto_cuenta" xml:space="preserve">
    <value>Extracto Cuenta</value>
  </data>
  <data name="factura" xml:space="preserve">
    <value>Factura</value>
  </data>
  <data name="fecha_o_importe" xml:space="preserve">
    <value>Fecha o importe</value>
  </data>
  <data name="formacion" xml:space="preserve">
    <value>Formación</value>
  </data>
  <data name="giro" xml:space="preserve">
    <value>Giro</value>
  </data>
  <data name="incidencia" xml:space="preserve">
    <value>Incidencia</value>
  </data>
  <data name="indicar_mes" xml:space="preserve">
    <value>Indicar mes</value>
  </data>
  <data name="indicar_periodo" xml:space="preserve">
    <value>Indicar período</value>
  </data>
  <data name="solicitar_llamada" xml:space="preserve">
    <value>Solicitar Llamada</value>
  </data>
  <data name="solicitudes" xml:space="preserve">
    <value>Solicitudes</value>
  </data>
  <data name="configuracion_indonet" xml:space="preserve">
    <value>Configuración indonet</value>
  </data>
  <data name="aviso_cambios_sin_guardar" xml:space="preserve">
    <value>Tiene cambios pendientes de guardar</value>
  </data>
  <data name="aviso_jergas_diferentes" xml:space="preserve">
    <value>Los productos sugeridos requieren distintos datos adicionales y si aceptas el cambio volverás a la pantalla de pedidos para introducirlos.</value>
  </data>
  <data name="debes_escoger_donde_quieres_recogida" xml:space="preserve">
    <value>Debes escoger dónde quiere la recogida.</value>
  </data>
  <data name="indica_lugar_recogida" xml:space="preserve">
    <value>Indica donde debe efectuarse la recogida:</value>
  </data>
  <data name="filtro_productos_y_descripcion" xml:space="preserve">
    <value>Filtro de productos y descripción</value>
  </data>
  <data name="mostrar_tabla_equivalencia_en_euros" xml:space="preserve">
    <value>Mostrar tabla equivalencia de puntos en euros</value>
  </data>
  <data name="aviso_eliminacion_boton_igual_suplementos" xml:space="preserve">
    <value>Con el fin de evitar errores hemos eliminado el botón de igualar los suplementos en ambos ojos. Ahora deberás introducir los valores que desees para cada ojo.</value>
  </data>
  <data name="entendido" xml:space="preserve">
    <value>Entendido</value>
  </data>
  <data name="equivalencia_puntos_bonos" xml:space="preserve">
    <value>Equivalencia puntos bonos</value>
  </data>
  <data name="direccion_indo_devoluciones" xml:space="preserve">
    <value>Dpto.Devoluciones. Avda. Alcalde Barnils, 72 - 08174 Sant Cugat del Vallés - Barcelona</value>
  </data>
  <data name="recupera_valoracion" xml:space="preserve">
    <value>Recupera Valoracion</value>
  </data>
  <data name="valorar_sin_firmar" xml:space="preserve">
    <value>Valorar sin firmar</value>
  </data>
  <data name="clase_de_comprobacion" xml:space="preserve">
    <value>Clase de comprobación:</value>
  </data>
  <data name="motivo_comprobacion" xml:space="preserve">
    <value>Motivo Comprobación:</value>
  </data>
  <data name="ha_escogido_esta_comprobación" xml:space="preserve">
    <value>Has escogido esta comprobación</value>
  </data>
  <data name="aviso_comprobaciones" xml:space="preserve">
    <value>Desde ahora puedes solicitar también tus comprobaciones de lentes en esta misma pestaña. 
Solo has de seleccionar dicha opción en clase de comprobación e indicar el motivo. 
Si necesitas más información ponte en contacto con nosotros.</value>
  </data>
  <data name="String0" xml:space="preserve">
    <value>Desde ahora puedes solicitar también tus comprobaciones de lentes en esta misma pestaña.</value>
  </data>
  <data name="String1" xml:space="preserve">
    <value>Solo has de seleccionar dicha opción en clase de comprobación e indicar el motivo.</value>
  </data>
  <data name="String2" xml:space="preserve">
    <value>Si necesitas más información ponte en contacto con nosotros.</value>
  </data>
  <data name="selecciona_promocion" xml:space="preserve">
    <value>Selecciona la promoción:</value>
  </data>
  <data name="eliminar_filtros" xml:space="preserve">
    <value>Eliminar filtros</value>
  </data>
  <data name="numero_ojos_en_premio" xml:space="preserve">
    <value>Núm. de lentes</value>
  </data>
  <data name="selecciona_premio" xml:space="preserve">
    <value>Selecciona tu pedido:</value>
  </data>
  <data name="solo_promos" xml:space="preserve">
    <value>PROMOCIONES</value>
  </data>
  <data name="no_volver_a_mostrar" xml:space="preserve">
    <value>No volver a mostrar</value>
  </data>
  <data name="aviso_ha_generado_premio" xml:space="preserve">
    <value>Este pedido ha generado una promoción aplicable a una segunda pareja. ¿Deseas hacer ahora el nuevo pedido? 
En caso afirmativo te ayudaremos a filtrar los productos disponibles o, si lo prefieres, puedes hacerlo más tarde desde la pestaña Realizar pedido.</value>
  </data>
  <data name="aviso_nuevos_botones_promociones" xml:space="preserve">
    <value>Ahora puedes activar la nueva guía de promociones en la pestaña de configuración. Si quieres saber cómo funciona tienes disponible un vídeo con las instrucciones en la sección de Tutoriales. 
Si deseas más información, ponte en contacto con tu comercial o con nuestro servicio de Atención al Cliente.</value>
  </data>
  <data name="debe_seleccionar_un_pedido" xml:space="preserve">
    <value>Debe seleccionar un pedido</value>
  </data>
  <data name="filtrar_por" xml:space="preserve">
    <value>Filtrar por:</value>
  </data>
  <data name="hay_generadores_para_el_pedido" xml:space="preserve">
    <value>Tienes una promoción activa que puede ser aplicada en este pedido. 
¿Quieres ver los productos que lo permiten?</value>
  </data>
  <data name="pulse_boton_enviar_y_revision" xml:space="preserve">
    <value>Pulse el botón de ""Enviar y revisión"" para que un agente de ATC la verifique.</value>
  </data>
  <data name="receta_no_grabada" xml:space="preserve">
    <value>Esta receta no se ha podido grabar.</value>
  </data>
  <data name="seleccion_primer_pedido" xml:space="preserve">
    <value>Selección del primer pedido</value>
  </data>
  <data name="por_el_mismo_precio" xml:space="preserve">
    <value>Por el mismo precio puedes solicitar este pedido en {1}</value>
  </data>
  <data name="por_los_mismos_puntos" xml:space="preserve">
    <value>Por los mismos puntos puedes solicitar este pedido en {1}</value>
  </data>
  <data name="por_x_puntos_mas" xml:space="preserve">
    <value>Por tan solo {0} puntos más puedes solicitar este pedido en {1}</value>
  </data>
  <data name="por_x_puntos_menos" xml:space="preserve">
    <value>Por {0} puntos menos puedes solicitar este pedido en {1}</value>
  </data>
  <data name="numero_recogidas_disponibles_este_mes" xml:space="preserve">
    <value>Recogidas gratuitas disponibles este mes</value>
  </data>
  <data name="numero_recogidas_efectuadas_este_mes" xml:space="preserve">
    <value>Recogidas solicitadas este mes</value>
  </data>
  <data name="aviso_recogidas_gratis_agotadas" xml:space="preserve">
    <value>No dispones ya para este mes de recogidas gratuitas ¿Quieres seguir con la solicitud de recogida?</value>
  </data>
  <data name="continuar_sin_promocion" xml:space="preserve">
    <value>Continuar sin promoción</value>
  </data>
  <data name="hay_generadores_para_el_pedido1" xml:space="preserve">
    <value>Recuerda que tienes la promoción:{0} Si quieres utilizarla selecciona el botón Primera Pareja</value>
  </data>
  <assembly alias="System.Windows.Forms" name="System.Windows.Forms, Version=2.0.0.0, Culture=neutral, PublicKeyToken=b77a5c561934e089" />
  <data name="logoInicio" type="System.Resources.ResXFileRef, System.Windows.Forms">
    <value>Assets\logoInicio.png;System.Drawing.Bitmap, System.Drawing, Version=2.0.0.0, Culture=neutral, PublicKeyToken=b03f5f7f11d50a3a</value>
  </data>
  <data name="promocion_aplicada" xml:space="preserve">
    <value>INFORMACIÓN TARIFA:</value>
  </data>
  <data name="hay_generadores_para_el_pedido1_plural" xml:space="preserve">
    <value>Recuerda que tienes las promociones:{0} Si quieres utilizarlas selecciona el botón Primera Pareja</value>
  </data>
  <data name="guia_promociones_activada" xml:space="preserve">
    <value>Guía promociones activada</value>
  </data>
  <data name="limpiar" xml:space="preserve">
    <value>Clean form</value>
  </data>
  <data name="mensaje_ayuda_atc" xml:space="preserve">
    <value>¿Tienes dudas? Nuestro equipo de Atención al Cliente te podrá asesorar en el teléfono 900 110 557</value>
  </data>
  <data name="mensaje_ayuda_atc_rod" xml:space="preserve">
    <value>¿Tienes dudas? Nuestro equipo de Atención al Cliente te podrá asesorar en el teléfono 900 102 189</value>
  </data>
  <data name="mensaje_ayuda_atc_indo_rod" xml:space="preserve">
    <value>¿Tienes dudas? Atención al Cliente Indo 900 110 557 | Atención al Cliente Rodenstock 900 102 189</value>
  </data>
  <data name="mensaje_ayuda_atc_PT" xml:space="preserve">
    <value>¿Tienes dudas? Nuestro equipo de Atención al Cliente te podrá asesorar en el teléfono 219 609 030</value>
  </data>
  <data name="mensaje_ayuda_gestora" xml:space="preserve">
    <value>Soy {0}, tu agente personal, te ayudaré en cualquier consulta sobre tus facturas, bonos o devoluciones. Mi teléfono es el {2}</value>
  </data>
  <data name="numero_lentes" xml:space="preserve">
    <value>Número de lentes</value>
  </data>
  <data name="OPC" xml:space="preserve">
    <value>OPC</value>
  </data>
  <data name="primera_pareja" xml:space="preserve">
    <value>Primera Pareja</value>
  </data>
  <data name="reposicion_stocks" xml:space="preserve">
    <value>Reposición de stocks</value>
  </data>
  <data name="atencion_pedido_en_promocion1" xml:space="preserve">
    <value>Tienes este producto en promoción hasta el {DD-MM-AA}.</value>
  </data>
  <data name="atencion_pedido_en_promocion2" xml:space="preserve">
    <value>Por este pedido solo restaremos {xxx} puntos de tu bono activo</value>
  </data>
  <data name="enhorabuena" xml:space="preserve">
    <value>¡Enhorabuena!</value>
  </data>
  <data name="enhorabuena_pedido_en_promocion" xml:space="preserve">
    <value>Recuerda que tienes esta promoción activa hasta el {DD-MM-AA}</value>
  </data>
  <data name="mensaje_ayuda_gestora_indomanager" xml:space="preserve">
    <value>Soy {0}, tu agente personal, te ayudaré en cualquier consulta sobre tus facturas, bonos o devoluciones. Mi teléfono es el {2} y mi correo {3}</value>
  </data>
  <data name="pedido_fuera_oferta" xml:space="preserve">
    <value>Fuera de norma</value>
  </data>
  <data name="aviso_recogidas_montaje_o_devolucion_1" xml:space="preserve">
    <value>Estas recogidas no tienen ningún coste adicional en factura.</value>
  </data>
  <data name="aviso_recogidas_montaje_o_devolucion_2" xml:space="preserve">
    <value>Tienes {2} recogidas de devoluciones gratis al mes al solicitarlas por Indonet.</value>
  </data>
  <data name="comprobaciones" xml:space="preserve">
    <value>Analysis</value>
  </data>
  <data name="aviso_cambio_contraseña_indonet" xml:space="preserve">
    <value>Para mejorar la seguridad y confidencialidad de nuestros clientes, os recomendamos modificar y personalizar vuestra contraseña de acceso a esta plataforma. Podréis hacerlo desde la pestaña de configuración que se abrirá a continuación.</value>
  </data>
  <data name="aviso_debe_rellenar_todos_los_campos" xml:space="preserve">
    <value>Debe rellenar todos los campos</value>
  </data>
  <data name="aviso_nueva_clave_no_es_indonet" xml:space="preserve">
    <value>La nueva clave no puede ser 'INDONET'</value>
  </data>
  <data name="notificacion" xml:space="preserve">
    <value>Notificación</value>
  </data>
  <data name="bisel_cuadrado" xml:space="preserve">
    <value>Bisel Cuadrado</value>
  </data>
  <data name="bisel_de_seguridad" xml:space="preserve">
    <value>Bisel de seguridad:</value>
  </data>
  <data name="bisel_especial" xml:space="preserve">
    <value>Bisel especial</value>
  </data>
  <data name="bisel_inferior" xml:space="preserve">
    <value>Bisel inferior</value>
  </data>
  <data name="bisel_stepback" xml:space="preserve">
    <value>Bisel Step Back</value>
  </data>
  <data name="bisel_superior" xml:space="preserve">
    <value>Bisel superior</value>
  </data>
  <data name="cuadrado" xml:space="preserve">
    <value>Cuadrado</value>
  </data>
  <data name="curva_a_seguir" xml:space="preserve">
    <value>Curva a seguir [Dp]:</value>
  </data>
  <data name="dimensiones" xml:space="preserve">
    <value>Dimensiones</value>
  </data>
  <data name="distancia_al_borde_inferior" xml:space="preserve">
    <value>Distancia al borde inferior [mm]</value>
  </data>
  <data name="distancia_al_borde_superior" xml:space="preserve">
    <value>Distancia al borde superior [mm]</value>
  </data>
  <data name="doble" xml:space="preserve">
    <value>Doble</value>
  </data>
  <data name="guardar_como" xml:space="preserve">
    <value>Guardar como...</value>
  </data>
  <data name="inclinacion" xml:space="preserve">
    <value>Inclinación:</value>
  </data>
  <data name="posicion_del_bisel" xml:space="preserve">
    <value>Posición del bisel</value>
  </data>
  <data name="ranura_especial" xml:space="preserve">
    <value>Ranura Especial</value>
  </data>
  <data name="sencilla" xml:space="preserve">
    <value>Sencilla</value>
  </data>
  <data name="tipo_bisel_especial" xml:space="preserve">
    <value>Tipo de Bisel Especial</value>
  </data>
  <data name="aviso_estas_seguro_eliminar_bisel" xml:space="preserve">
    <value>¿Estás seguro de eliminar el bisel?</value>
  </data>
  <data name="custom" xml:space="preserve">
    <value>Custom</value>
  </data>
  <data name="debes_dar_nombre_a_bisel" xml:space="preserve">
    <value>Debes dar un nombre al bisel para poderlo guardar</value>
  </data>
  <data name="mm" xml:space="preserve">
    <value>[mm]</value>
  </data>
  <data name="nombre_no_puede_estar_vacio" xml:space="preserve">
    <value>El nombre no puede estar en blanco</value>
  </data>
  <data name="nombre_nuevo_bisel" xml:space="preserve">
    <value>Nombre para el nuevo bisel</value>
  </data>
  <data name="percent_al_borde_superior" xml:space="preserve">
    <value>% al borde superior</value>
  </data>
  <data name="montura_taladrada_no_admite_personalizacion" xml:space="preserve">
    <value>El tipo de montura taladrada no admite personalización</value>
  </data>
  <data name="basica" xml:space="preserve">
    <value>Básica</value>
  </data>
  <data name="bisel_plano" xml:space="preserve">
    <value>Bisel plano</value>
  </data>
  <data name="pulido_de_bisel" xml:space="preserve">
    <value>Pulido de bisel</value>
  </data>
  <data name="rebaje" xml:space="preserve">
    <value>Rebaje</value>
  </data>
  <data name="seguridad" xml:space="preserve">
    <value>Seguridad</value>
  </data>
  <data name="PrimerosCaracteresDiferentesClaveAcceso" xml:space="preserve">
    <value>Los tres primeros caracteres de la clave de acceso deben ser diferentes</value>
  </data>
  <assembly alias="System.Drawing" name="System.Drawing, Version=2.0.0.0, Culture=neutral, PublicKeyToken=b03f5f7f11d50a3a" />
  <data name="AyudaAlturaPasillo_ES" type="System.Drawing.Bitmap, System.Drawing" mimetype="application/x-microsoft.net.object.bytearray.base64">
    <value>
        iVBORw0KGgoAAAANSUhEUgAABJkAAAHcCAIAAAD6BewEAAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAAAO
        wwAADsMBx2+oZAAA/7JJREFUeF7snQdcVef5x+2/u9kxs9lpm7Zpm46YxIxmN23Tptl7T/dC9p6iAqKI
        Am5EVBQHThQHojhwDxQEtyIIDhABUZP/9973eDzexWWDeb6+3s/l3fs8v3tWh+8EQRAEQRAEQRCE9oZo
        OUEQBEEQBEEQhPaHaDlBEARBEARBEIT2h2g5QRAEQRAEQRCE9odoOUEQBEEQBEEQhPaHaDlBEARBEARB
        EIT2h2g5QRAEQRAEQRCE9odoOUEQBEEQBEEQhPaHaDlBEARBEARBEIT2h2g5QRAEQRAEQRCE9odoOUEQ
        BEEQBEEQhPaHaDlBEARBEARBEIT2h2g5QRAEQRAEQRCE9odoOUEQBEEQBEEQhPaHaDlBEARBEARBEIT2
        h2g5QRAEQRAEQRCE9odoOUEQBEEQBEEQhPaHaDlBEARBEARBEIT2h2g5QRAEQRAEQRCE9odoOUEQBEEQ
        BEEQhPaHaDlBEARBEARBEIT2h2g5QRAEQRAEQRCE9odoOUEQBEEQBEEQhPaHaDlBEARBEARBEIT2h2g5
        QRAEQRAEQRCE9odoOUEQBEEQhMv49ttvtW+CIAhtGNFygiAIgiAIl9HkWo4Mz507d7amprKysqys7NCB
        AwW7d+/Yvn3L5s2bNm3avHkz3/E5eODAsWPHiFNTU0N8kZSCIDhGtJwgCIIgCMJlNJWIIp/z58+frqg4
        dPAQsm3pkiUpU1OGxwwPCgh07efS5auvPvvkk08++uizjz/me/9+/fAfFj106pSpSzIyEHkHDhyoqKgg
        hwsXLmg5CoIgGBAtJwiCIAiCcBmN13LkcLam5vDhwxs2bJicnOzv6/fuW28/8re/3XPHXbd2vPmGa669
        5ue/wF37i6uu+8VVfF7zs59f+/Nf4H9Lx5vu/uWdnf7y17ffeNPHyzs5KSln3bqDBw/W1NTIaTpBECwQ
        LScIgiAIgnAZjVRN1dXVBQUFs2fM9HB1e/bvT6Pfrr/6mhuvufau227//QO/ffThTnj+51//eu/tdz75
        8KPPPv7k048+5js+zz399GMPd3rwgQeIeeO115Hqrtt/+fQTT7n0dZk5Y8bu/N1nzpzRyhAEQRAtJwiC
        IAiCYEGDtdzZs2d35+cnTZz4yUcfP/jAb2+6/oZbOt7063vvf7Lz46g1pN2I4bFps2evW7tmV27uvn37
        Dh06dJh/hw7t37dvZ+7OdWvXzp0zZ+SIEV4eHu+/8y6pfnPf/bd2vOnmG278/W8e+OCdd8eNHbtr1y5K
        0coTBOH7jWg5QRAEQRCEy2iAliNJ0ZGilKkpn3/66R9/9/tbbrjxjltuffjPf/no/Q+iIiMXL1qUtyuv
        qKjo+PHjZ86cOXfuHAm0lAbwJ/TEiRNHjx7Nz8vLWLR4aNSQTz/6+JG//fXOW2+7+cYbf/vr35Dh5OTk
        AwcOyE10giCIlhMEQRAEQbiM+mq56uqaVatWebp7dH7k0Vs73nTbTTd37vRI3169p6dM37pla0lxcW1t
        rRa1PpDqWEnJtm3bZs6Y4ebS/8nOncn55htufOSvD7u7umatWFFVVaVFFQThe4loOUEQBEEQhMuol5Yr
        KyubOmXqu++8c/cdd954zXV//dNDPbv3mJ4ybdfOui+GPHL48PqcnL179jgu8dy5c/n5+bNmzuzbu8/D
        f/4Lpdx1+x1vv/FmclJySckxLZIgCN8/RMsJdrlw/vzxsjL1ApxtW7Yot33r1rxdu4qKiuRifUEQBOFK
        xUktR7QD+w+MjI39e+fHr7v6mnvuuPPt19+IGzFyZ26uFsMhxUeLhw2J/uDd94IDAnN37NB8HbJrV96Y
        UaPefett0/NUrrrmiUcfi44asnfvvvPnz2sxBEH4PiFaTrDLwQMHpk2ZEujn5+Xu7u/lhQvw8vL18PBw
        dZ2UmHjk8GEtnnBFcOHChZPmOzQOm27EP3To4EE+GeWS4uKKigotki2qq6pKS0uPHDmiEirHn3hWV1dr
        kQTh+8rZs2fLy8vLyspKSkpYUKwOtbhwRUeOFB89WlZaSoSamhotgdA2cEbLsW0WFhSEhYT9/te/uf7q
        ax76wx/79e6TuWx5jXNbX+mxY8OHxfz1Tw/99Ic/uv/ue7zcPchNC3MIsm1l1gpXF5e/PfTnG666+jf3
        3hcYEJi3a9e52nNaDEEQvjeIlhNsc/z48amTJ3u7u3u6uLj366c7j379vN3cRg4fvn/vXi2q0MbAvKiq
        qqqsrDxdUYGrKC+vPH0an9raWgfWCQbl6Pj4IF9fHzc3FDvOz9OTEQ8PCZk9Y4YWyYpz587lrF0bEx2N
        wvdxd1cJ+cKfw6Ki1q1e3bBbRAShvcPSqK6uPn369IacnKnJyayRAcHBnv37s6zUMsF5u7qGBgQMj46e
        MmnS6uzskuJiFJ08zaKN4IyWKyjYHeDnd/ftv7z5+hueePSxiEGDUOtamEPIvLS0dOTw2D/97vd33HIr
        Qu62m27+zX33e7i57du3z8k5cOTIkaFDhjz9xJO33NDxl7fe5t7fdefOnaZHqgiC8H1CtNyl/ZovDjDG
        /D6QlZkZ4OOD5WEUcjgvV9dBYWHrc3K0eELb42hR0fgxY0IDAz1cXHAMor+398iYmFVZWQg8LdLlnD17
        du3q1ah0i+HGMeIh/v4H9u+3Of+RiChAj/79KciYSpU7Ki5OXoUkfA9h2m/euHFMQkKQj49aDsbVYe2I
        4NavX5C//6SJE/fu2SNyri1Q5xF/z969vt7ed93+y1tvvOnZvz+dNDHJ+e0OIRcXO+LB3zxw+00333vn
        Xb++9z4l5+676253VzfnH1BZfqo8ZWrKi889/8tbbqUabv375+XlaWGCIHw/+L5ruVMnT+asXTtn1qyJ
        48fHxcSEBgUhYEwXE3p74/gSEhAQHxuLm5Wamrl8+eFDh74Piu7QwYMJI0ZYW/ZY577u7mtWrdLiCW0P
        DMG44cNNtqPZfPS4aEfyibpbsnixFu9yKsrLF86fj2zTx1p3DHqgr++mDRts2hbYLtERETZNVTyHDBp0
        +vRpLaogfD/gMDE5Kcnbw8P6tzDHjiVj+uXFy2vGtGlyQ3Kr4/hYX1R0NMDf/7677rrlxo4vPPtc2uzZ
        zj9PsqSkJDZm+O8f+O2tHW/6yx//9Mff/R4h99CDf3j4z39Bkt1zx53u/V332/n5zJrKyspFixb98x8v
        3X7LrXfcdpu/nx9ptTBBEL4HfE+13IkTJ7IyM8eMGjUoLCzIz8/P09N0MaH53AKfRocPBi5ffD09sWjD
        AgPjR4xIX7Dg4IEDWl5XImkzZqhWW9oZLi6LFy6skjMtbZh1a9eaLnG0Elf4IM6ReTYveiwvL18wd64D
        LbfRnparrIwePNi6OByeaLlK0XLC94zslSvdXVysT1Y740iC8/HwGDVixP59+7QchdbAgZRCtkVFRKLB
        Ol573fPPPDNzxgzn7w0uPno0ZujQP/3uwY7X3/Do3x4ODw179b+v3H7zLf/998shQcGv/ve/N99w4z13
        3OXp7r6nsFBLUxdnKs8sTl/0wnPP3Xzjjb+5//7oqKgTx49rYYIgXOl8X7ScvikfKylZmpGBRRsSEMCB
        E+PV66Jms3fcVUHIGKKpU1WYtsOiohA8V6Siy92xI2LAAGuz3tPVdXJSUklxsRZPaHucOnVq+tSp6j43
        a8fsHRwWtsfWvfXlp04tmDfPkZZbv96mlkPYi5YTBCMZixYFeHtbLId6OfU72pj4+KIjR8jw+3AxSBvE
        QbdPnTL16SeevO6qq594rPOE8eOqnT4jd/jQoaFDhvzlj39S7y0YPWr07vzdX3z62Q3XXPvuW29nZ2cv
        WpT+4nPP33T9DffffY+3h+eunTudHH224mlTpz75WOfrrzY92TI5aZJcqSsI3xPakJZjwzp9+vTmTZuW
        LFo0Z9as2TNmzJ45E/ty3Zo1ZaWlWqQGobbC2rNn169bN27MmCA/P4xazFZ74s2xIxXyjxy8XV1HjRy5
        Jjv7SjpPVVlZOTohQYlbY6vpruHR0fv27hWroi2DoTBhzBgfd3fj2OmOQQwPCdm2ZYsW24BoOUFoKjiE
        BXh5WSyH+jrWnVu/fjOnT5cTLK2FvYPd1i1b3nzt9Y7XXv/H3/0+cnBEeXm5FuAEE8aPf+jBP1z786s6
        /eWvMUOHlZaWnjp1Ci2HLHzz9Te2b9t+7ty5mTNn/uP5Fzpee90dt94WFRl17Jiz747DgoqOjPrzH/54
        3VXXvPHa69lyN4QgfD9oW+flFs6bFxEezjHM18ODY6Gfp6dH//6hgYHTU1LO2HlmQ52o7RiJsnzp0oGh
        oV5ubuonz8Y78kHRUb2F8+efOnlSFdfeWZmVFeDra9FFGOU0Ew0gv/O1cUTLCUKrs2TxYuN5OVYQK4uD
        ha+7u5+HB0496xVPmwtHd0TgILg0I0N+QWsVbHY7toSXh8e9d9512003u/Tpu7c+z3Mmw8jBg0nbudMj
        Qy6KtGPHSj796KPrr77mtf+9un79enxqamqmTU3554v/uLXjTT5e3ocOHTKndoqiI0XU6q7bf3nnbbe7
        9nM5XiY/BAjClU8b0nLFR49yqLM4vPEdHzcXFwzQBj9pt7q6OmPRItPh09Zj+hrpvMy/nqYvWNDe5RyH
        GZoQNWgQjbIYAuySrMxMLZ7QhikrLZ2clGTvGkvm/+ABAw4dPKjFNiBaThCaCtQXgo35z9rxML+EICwo
        KHLgwIQRIyaMGTN+zJixCQkxQ4aEh4SoW1ttLh8c/qSNi429sm/PbrNYa7nz58+nL1hoeqXbNdf+77//
        XVH/w2L2qlUDQsNGjxqtn20rKUHLfYyWex0tl2PSckBB01JSggICMxZn1PfxUatWrXrz9devu+rqTn/5
        W+q0aZqvIAhXLm1Fy1VXVc1NS3NwVJs0YYLjFxbbo7a2FqHl5+Vl007VnSoax9HX6BxUSXdE45NS7D3w
        vV1QU1OzcN48i/Z6Ykx4eKQkJ2uRhDbPsowMNJv1pMXH281tTHy8Fu9yRMsJQlOxeuVKdk7mf7CfX3Rk
        5Izp01dlZe3KzTW+DfxoUVHO2rXTJk8eGBKC3lNPnbV2avVlLFqkJRNaEGstV1xc/PEHH95+8y2/ue/+
        8WPHOv/gSiNkasz5mC0tpzh/7rz2rZ6MHjXqoQf/cGvHm9576+0jR45YN0QQhCuJtqLlzlRWTk5K4uDn
        YXUww+E/Ytiwkw068TUvLc3P09OxkMNxyPR2d/dxdze9jcDHx+S8vUmoroSxiGztSO7v7d2uL0/Hmlf3
        eJhGQSk6s7IdPXJkw45YQquwOz9/WFSUmpaXhtL8fWBo6MoVK7R4lyNaThCairxdu+JjY4cPGbJ29eo6
        f4LcsXXr8KFDTc+9tCPnODAljh+vxRZaEAsJVFNdMzdtzq/uuffGa6/r2qVLodMPmXSMAy3XYAoKCvv2
        7nPDNdc+cN/9ExMT5e3hgnBl01a0XGVdWm5wWNjxsjItttOsWL48NDDQy+ENcpiqvp6eo+LiFi1YsHnD
        hrMXfzqtrq7ev3dv5rJlkydODPb1tUhl7bzd3IZHR+/KzVXJ2x3Y3DHR0aYhMDvMeiz4KZMm2XzsodBm
        OVdbiymZOHZsWFCQGkpmeIC3d+ywYRvsv+FdtJwgNBVoAB3NyyE7d+wYFhXFEcRiBSnn4+ZG6AF5Y1iL
        YzF8yPJh0UNvv+nmP/7+wfSF6U0lkJpDy8G8OXMf/stf77ztdl8vL3lXoSBc2bQbLTcoNLReWo5duPjo
        0SEREQ5+78RI5XN0XFzezp1sdrW1tefPX3ZJA8YrnjU1NdijC+fNC/D1NV0MY5WPcqZSXFymTp5skUl7
        gR6rqqo6rVNRwaDQdpsWvNCWYQZWV1efqWTaqpGs4BPR5cD4EC0nCK1I1vLlvrZeC4ljVQ4KC3PwQ4zQ
        TFhoudqztWvXrO3y9dexw4cjwDTfRlNSXPzJh9qzT3KabpSPHDkyckRsl6++XrpkSTu1SQRBcJI2o+VO
        n06eONGe6MI/YcSI+j5cJG3WLF9PT3t5coAM8PFZtmQJRTuz02Gzrl+3LmrgQHUjhEVuypHn4AEDNm3Y
        oKURhHaCaDlBaEUK8vNHDhtm89QcqzI0MHD50qVaVKGlsNBy/FldXV1UVIQp0oQ/cR47duyDd9/76Q9/
        9N9/v7y+6bQcVs2pUyepLVu0RUMEQbjCaCtarqqqataMGQ7OoU0cP975Z5+wc5WUlIQFBVlkojtK8fHw
        yFy2rF7XHpw7d25jTk50RISXnYthyNbb1VWeFHJl8L06/omWE64k2t3iLTpyZNKECTbfJuLVv39IQMDS
        jAwtqtBStMwsOlNV5erict9dd3/1+RcFBbs1X0EQBKdpK1oOM3FvYSFCCGvSaBfy3XS3m4vLujVrnNdd
        NTU1SxYvJq1NExPn6eqaNnPmabOVWa/9GjnHMRXT1t49ePhj2hYfPaolaA2cbJEejS9Hi4q2b92anZW1
        LCMDm37+3LkmN2dO+oIFWZmZOWvX5u/aderUKRW/7VBSXLxl06Y1q1YtnD+f2i6YO5fKM/TrVq+mwo1X
        EQz3gX376Jk1q1evWL6czClC75xFCxeuXrVq7Zo1eTt3Gke8YRZAdVVVwe7dSCbyTDc3h1Ioju8MCm3M
        3b59X33eZVQv2paW+/bb4uLiXbm5TLys5csz0tNVb6hu5088CSICE0BL0gbQx53OpG7ZK1cuXbxYmzBU
        e9EiRnbbli3FRUUqWuMpKy3Nz8vbkJNDWYvT042Tk2lDL61euXLH9u0230LRtBwvK2N+rs3OVoNFTRbO
        m7c8I4MK7Nq5k7mtojVsaeicP3+e9bh506a1q1fTt/MuzgrTMlm4cGVmJrN0d34+c1JL0NSwzNevW8dQ
        UiLrhWMBfx6uz+u/7MFMnjJpkl0t5++/bMkSLarQUhinK98vnD/PHmiPxlzHuHTpsqiICObzmYZOXa0S
        9tHb0sg1KAhCG6StaDlFSnJyaGAgJqCPm5t6m6qH2Y4cM2pURXm5FskJ2BCj7NiXylFKgw/AJ0+cSJ06
        FdlpkadyWMNU2MFvqN9euHBg//7t27ZhBCBQlcMwRZMUOv2UESynrVu25BhywLjZvHHjnsLC8vp0FHY2
        ha5csWLiuHFRAwea3kvWty+fAV5e/l5eAd7eDIG3u3twQEDs0KEzp0/HVCo6ckTddtUchwTypHt37tih
        dw49s3HDBgxWJoBeIkdNjKqcNWuSExMHDxhAPU0vl/f2Vu+XR/kH+/kNj46eM2vWztzc6upqlcp5qqqq
        Dh44gBGM0TYmISFy4ECTeje/t1f1jN45/Bnk5xczZMjkiROx1Pfv21ff4jjKlh47tmXz5nlpaSNjYgYE
        BwfSHPPzVE2l0CIPDwaFP6MjIkbFxWUuX05vOHima21tLZ1jmmA5Ofr0YKpQBBPPnq3QRrQc1UPQZi5b
        ljxx4rCoqNCAAG83N2xZ1Ruq2/kTT4KIwARYsWwZSZr7dSDMPZNqWr9e71K6N3fHDjSnFsMMs3fTxo2z
        UlNjoqL8vb09+/XTq003Bvj4MJeYKju2bWPQtTT1h/nJTEO/pUyePGLYsLCgIH9PT09XVzVhVHHqXZ18
        Z9qMHzNmhZo2J05oWThBRUXF3sJCxp29RW81TWbuGWcCM2fr5s2pKSlDIiKCfH3ZGCldzVuPfv34PnzI
        ENQdi7oBD2DQl/wp5PHOneiZsQkJLHkK8jS/91J1L45tiiYPDAmhQ1B31BN15OQeVXv27JHDh0076tq1
        WkvXrmU7xdNYZzaESYmJpiNU//6UqLYaVBYajE5wsix7lJSUONBywf7+aFctqtBS6GPK6LDeWXGrs7Nt
        uuzsVbB3z162X5WkXtScPXvixImGPS+a2cvRihpYVMnosldlM6VLS0u1NIIgXFm0LS3HdoYZh6IbEx+f
        MGJEwsiRiePHpy9YwDFVi+Ec6A03+0KOAzC2RcN+u1Wbe+727epVsJY5m+1XdAXmmopvTU1NDU3DDtDl
        B5/kZjqhFxlZVlpap02AqkmfP9/0BgVyMFtvuEAfHyx+FJeTd+uhxxCESxYtiggPV3XG8sPctG4UNhP+
        WM/UGUMNW2rzpk0NEEjOQNsx17CWtM4x22pYToPDwzGk1A+fFL1ty5YJY8ZQYb3axgrTBK3CHh7hISHL
        ly7FbnbS0sJ0Y/IgDxgjL6xD8w8K9noGh7+pLHNN+BNRx/He+ROYtAW1iQ2H3c8gUmeVobEInGoR1TD9
        xuHpSf8snDfPnhg4WlQ0bcoUMmSGaNMDy97T08PVlQW1betWLd7ltLqWO1tTg9RcnJ4eOWgQRrlxQlpk
        rnxUhxCNyAikjPR0DBqbNWwS6NVBAwboegm9zWCFBQYumDtXRWBdHyspSZs5E8HGwlRDaay5XmeCGEdk
        WANeAI2liERhkxweHa1+dtHnjPVTo/QSmcNq2lC9PYWFTi5eRpxSWP7sLWoi0fYgPz9KV1c0sB4PHTw4
        b84clKSSjjabrNTdgODglStWOH+dvM652tp9e/dSCmuZ/qe91gUpR1WVzjctW/MmvMu5n3I4vrAGyZxe
        Mm075iXj1rfvxPHj1aGH3RJph2Y27QaGovmCD6OAmnVyh7FH8dGjyRMn2tZyrq7MtKz6v5ZaaCRqTBn9
        GTNmfPDe+2+/8ea7b79t073z5ltvvf5GcGCQxY87LcDhw4ejhwx56/XX333r7ffefvu9d96xdFTvrbep
        3szUGVoaQRCuLNqWlmsSzpw5gx7gYG9xRNRdgK9vIy/N2r9vX1xsLIdYi5yV4wAfGR5u79BeVVWlTByL
        VMoHOwmjUItqh507dgyNjLQ+6pMDdgYyVYtnH8wjJMSIYcNIRStsPjvUpqPm2ElYqxmLFpGJll3TgS0e
        P2KEhaFmaper6+pVq2rNzxpdlpGBQUk1jHHsOdKarLqkpDp/DmC8TldUbMjJGRwWRnFYhM7kb3TEV4b1
        pMREZybY+XPnsM+wcb1IVZ+yUAL0gD3bDrMyyHwW0SIVPhSUOm2aFu9yWlfLoQ3Wrl6NJEN+U5xFPnU6
        kmBPD4uKytu5szFXOtmDNiLv0STGZlIoLjkxkQgIhvXr1qmfRZypv5oqtLdeD5o/e/YsUnxYZCSzpQHz
        k4qxaYQFBzNzapxQOEwVNZ+NmTBDRo0cecz8OwIb0ciYGE/nakJCok1NTq7XuUFW5aaNG6MjIijFmY7V
        HZEpMdjPL3vlyjqvW2NuDx4wwGJHJQc0au727SqC8jFGUA5PlhsRGqnlmAljEhLocIv8cWqq7DDXRGhJ
        1JieOnUqwM/v+muuufHa626+4QabruN1119/1TV/f+KJgt0tfcMbe8Jr//vf1T/7+U3X33CTuTJ86k79
        2fH663/2ox/6+fhUnZFXxQrCFcgVqOWOl5WNHz3apnmBJ4fe8WPG1KmXHFNRUbFowQKbv6HiEFQc3cvs
        XM+AlqMO1tVTdVu5YoWTWs7a7Ca5r7v7wnnztHh2QINt3rAhyN/fwfsVHDsKwk2dNEnLselALcRhHVr9
        uk9Xr8nORiChyvhOBD3UGefp6po0YYLj+5QqysunTZmiLE6L5PVyKvmouDgtX/tkLV+urkkzJnfGqYFe
        NH++ltHlbNm0KdDb23pwqRhGYerUqVq8y2lFLYcQyli0iDGq77BaONYdig6p74xQqReIAXX/rbE41Z9T
        kpLoFurvR5/Xc+bQXmz0/fv2acU45GxNzfq1a329vOpbioWjUKqd4sSrU0xXklvpNJKPGzXq+PHjOWvX
        Bvn51Wv2kpVbv36sMq0AJ1hhXiM2p6VTzsXFy919wdy5ji/BRYej5SxKobbBvr678/J25uaafnCxMznp
        EGq4acOGRmo51hel2FwC7Hho5rL6v15VaCRqTFngixct6tenb49u3Xr16GHPde/adURsbMsPU3Fx8fix
        Y7t8/U2vbt0tqqQ7at6tS5fF6Yu0NIIgXFlcgVruaFHRwJAQi8Ohchyevc1qp2EXtRvJ37XL39PTIn/l
        OB77e3tvyMmxaS01XsvlNkLL0fDNGzf6+/hYpK2vU22clZqq5dtEcNSMHz7con/4jjWDVRczZIhX/U9H
        4EhCdy1OT9eKscURx9fl1seZOsfLS7/6zhqauXnTpmA/P2JapHXGqYFOt6PlTOflfHysc1bd2Na03Lna
        2tRp0xr8s4KFo6oB3t4rli3Tcm8i0HJLbWk5Xw+P0XFxixYsaNg44hBLU5OT63xXVXVVVebSpRZpG+zU
        TJg5fbq68dUe0+1ouUmJiVMnTx4YGtqAVqOIQgMCnBwgFHKD14jRsednr1ypP3/Fmhw7Wi48OJhOGDVy
        pM11oRzVa7yWKz12bOL48QyKReY4quHp4iLPRm4V9DFl6zt79uzp06fZCs5UVlo6k5+J5rgowBlYyJR+
        huqZK3OpYuY/CaLm1dXVNjdwQRCuANqQlmPfZMuZMX36kMjIAcHBYYGBA4KC+DIkImLRwoXOX9F34MAB
        e0a5Mr9QWY6NGGc4sH8/doaFoaMcnhyVMeVtltK6Wu7ggQPhDl/VQLZUnl7yM9/RhzFnz5bCH7MMZdiE
        RwiystZyOP4MCQz0tG9R1eloV9SgQdRWK8mKQwcP0l6LVDhTn5hPZWjdYnb0s4OeUW5gWNgpO09EwHNM
        XJx1M5XDn9peKss8CvjoxfHlitFy8+fM8fX0tK5tgx1NGDJ48JZNm7QCmgLsIRtajp4xv6OSlWKz+c44
        EqJjVzi8Faq2tnbTxo0NOO/nwJGVj4cHGsbBbmNTy+GYDAyZhaeTzpSbi8uImJg6b2Nbt2aNA7mIv7d5
        p6Lz1Xo0LhALR6FhQUEOrrK2p+WCfX3VUrLuBN0R2kgtV1pSMnHCBKaBzVKo1cCQkDXZ2VpsoQVp8JgK
        giC0JG1Iy50/f37J4sVBCCSzLas7/sTT+eumDh86ZNMox3Hc9ffyOtMUj7zDMoiOiLB59MUTG2jGtGlt
        TcudPHFianKyvTqTXD2TLWft2j0FBXTj1i1baEXEwIEmO8NWEtyIoUO13JsCe1rOpiOaj/mJLKanMnh6
        YtjhYxFHd2To1q8fNpO9n07RcmSlx1e3JGEvknPCyJHz0tIYmj2FhWh4YqIJ6Rk0g4eduw0pDls5OyvL
        eg5QgYL8fNMzXeyYbpiVKZMn79i2DeGN25Wbi+BJGj8+PCSENuKIQ93sDXQ70nIoLnV3okVC3ZEDoSxn
        9ewNnFK2NotTjiAqPDo+Xj2fo0mwqeVUWSgTi3sd8aSGCAzTtDRX2N7lecp5u7vHjxjB1NIKM6BMSeab
        6R65unqJwaU41UvMZOrgYDngSBUxYIB6To9Nm9WelmMdWXhSECtRla6Ktk6lOxoSEhDg+BFNZaWlMUOH
        0rfW+eBDDmxKVG9DTg7b1N49e5ick5OS1K9UNoumf+bMmnXCzq16NrUcTnWgg7bgiNMwLceBINf8pNzh
        Q4cyB+yVwuSZkpTk4Lm1QvMhWk4QhHZBG9JyWL2pKSkcUC1MEA5yOI58WjyHIPmwuTFrjDkYHcdsLWrj
        KCkpGRUXZ/MAjCcVmJWa2qa0HIb4po0bbZpZJMRh/u7Oyys9dsxY7RPHj+/auXPalCkYcBapcKaWurkh
        Hmy2tAE4qeUIVSoLhZO1fDmGVPbKlVMnTULzW3eL7rzc3CLCw2mjVtjlYBQqo5zSsUf9vb3jY2Mz0tPz
        8/IIOnXypPHXBOqJMM7ftQvpa69nqEnyxInW5x/wQZsRwaKN/EnRsUOH5u3aZXHTBUKr+OjRgt27V69a
        NW7UKKS1n5cXDdeCL6e9aLnTFRUjY2JUNGMq3ZlOtri5YenOnT0b1bdxwwYkNN9Zd74OBQNBwf7+q7Ky
        tJIajT0tZ+2oM6XHDR++aMGCLZs302mzU1MHhoYSZD0iypnmm4eHvdFk4tFkZqa90knOfBgWFTVt8uQ1
        2dmbN22i3BXLl48dNQrJhJJ0UG3SEtPe1mpPyxkdoWSCmJkwZkzO2rXMPcQJA8T8JK1FZOWIT63YUuwZ
        yvgvWriQHGiXRVocU2JSYuKO7dvZmvTfZS6cP3+spARRxyZm6hCrOuMZ6u+/c8cOFd8Ce1rO2pEz7TKp
        Vm9vpVqVhKbtddr9FeXlDFBKcjKbBspz3OjRQwYPZqJSN5xFQTjKoqOoWNOeZBacRx/To0VFq7Ozly9b
        viIz057LXLZ8d/7uBrx4o5HUnD27d+9eyl+RuSLLjiN05cqVHES0NIIgXFm0rfNyM6dPtzY+lLngpJbj
        AI+IsmlGqHwwH5vkxzZMBw7GFlVVDk/MgvGjR9u8K6+1tBwVxoCgey1SqZrEDhvm4JF6HMlG2rn00d3F
        ZcLYsU11DsQZLUcobtTIkRvWrTty+LAuMFChy5YsGYRNZt+IxAKbb+c2NpJHhYdjn6FbGDsGAtOwzrPB
        hw4ejI6MtK4tPliiI4YNQ+poUS9CX9n8FYDqUXqddtvBAwdQKdmrVjEomtfltAstxxpcmZWFJLNnQDOI
        WLpo6cKCAuOD7FGA+/fuJS0L2dTJdsql8+lke+dg64uTWo5tZ0hEBHVm5uga/uTJk9u3bWMmkNx6UJQz
        vaIgOdmmFchohgUG2uwl1XzEwML583ft3Gl8RwUjxTxZt2YNe5T1aTTdUZ/Y6OjDdt606VjL4U+t+ES4
        bsjJYSGoVLSCnYRdCCVps70qw+ioKHv7cGVlJQLGFNMqIRkmJybSNC2qFQcPHqSrbXYXnbx44UKbU8IZ
        LWe6pNa8Lw2Pjp43Z87SjAz6J2bIkEDzOwOxkus8rLCCIsPD/T090X6sdLZiutfelMCxWkPNryJw8tgn
        NDlqTDkiJ06Y8K9//OPF555/6YUXX3rR0v2TzxdeeP6ZZ/v36Vd0xNETtpqD/fv3BwUEPvf00y+9+I9/
        2nFU77mnn5k4IZEmackEQbiCuNK03KmTJ+fMnm1Py3G0ToiNbQEtR4WHRkTYNM5aS8vt2L49yM/P4now
        HH01IDh47erVWjw77MrNDfb1tVltDI4GvDbKJnVqOYJQj+PHjLFpz9WePUsHhtl56Bx54hJGjNBiXw7j
        krN27ZRJk5YvXWr8/dLxbDl37ty8tDSEh82ewQrHMNWiXqS8vByVYhEZR9OQTKaLqZyenzbr1l7Oyw0f
        OhTFZTMh1YgYONB03ZqtEhX5eXmxWO32dTvTwMHPE/XCGS3n6eoaOWjQJju3j+bt2hVLe61SKce4mF6o
        sGuXFvsijO/8tDREiEV85WhjaEAAFXMwYY4cPhw/ciQx7Q0QK8Xe2SrHWo4xwo0dNcr4EHZ9QiJlFy1Y
        YJFEd9QnyN9fxbSABbVtyxbiWJeLD8LVwWM/VemZS5YglijCIjmdPG7UKJv7Rp1ajqJx9DaLHdmsUjEr
        2IpXLF++2rmb2VKmTCEr64pZO8pi0EP8/VcsW6YOfI53IaGZUN1eXlERHBR00/U33HzDjbd0vOmWGy3d
        rXjecOON11734nMv7NmzR6VtMZiE77319rW/uOqWGzvadTfcePVPfx4cGFTnfaqCILRH2paWm5Wa6mNH
        y9WpcBRtR8vFREW1KS23bOlS65NyOPKZNGGCM1LZ+m0BymEk6b/KNxLHWk55jk1IUMXZHEcasmjhQntm
        GRMDfdWExzMmbe727fSAvQE9baVyK8rLowYOtIiMI7Kfp+fmjRsbOT/bhZZjnTp4mAf13JiTc76uC3e3
        b93qa+e5I6bO9PJanJ5us871xRktNzAsjJ63edpHDeiaVauC/fxs/sqAZ0hAQObSpSq+zr69exNGjmRR
        W8THmRro4TFn1iwtqn327tkzKCzMXuX9PT2XLF5sc0U40HJ4UoHEsWNtLnzV3tJjxwaGhNiUr6QN8Pa2
        XhrAbJk4bhwRLJJQojezNyWlzgFFwI+Oi2MKWeTA3KYfbN6n54yWCwsKWm4YoAYsUmrFArfI2ehUr+JU
        VRFy6ljQyA1BaDCq589fuLBsyVJfL28PNzcvD0+bztPdw9PVbezoMfbuyWw+TFfcTJrk5tLf093dC+dh
        5dzdPd3c3V1dl1rtMIIgXBm0rfvlZkybZm0WqyNceXm5Fs8houVsarmDBw6MHz3a2r4hSZCf3wo79+pY
        MHvmTJs/eOMzf+5cm5ZZfalTy2HaqtdwOxhELGDTE3Rs5UDOgT4+a1evdnDCpwFgoll3CxXAWV99SkfF
        mq+4s45PJiNiYo6XlTVmirZ9Lce6SJ8/n7JspzK/gsyZq3ZPnTqVNmOGzTqTM5vAhLFjW0LLubgE+fsj
        LG0KOZ1Dhw6NHzPGeg3iyJlWsPtpUS+yKisrNDDQpvwjn7EJCU7eADMvLS3A1pTAebu6jomPt3kC04GW
        o7YTxoyxd3Gmgq2YHGwPMVPRw2P1qlXWTydmHg4MDrZOReWRfwW7d9c5oKwdJC4a1SIHU6Hu7pm2Xofg
        WMuZ5r+Pz0I7j411HsYL+W2RuXKqvRTk6+ERGhBA3x5sol/HhMZwaR/+9ls2gbKyMqSaTY6fOFGvl+A3
        OZR+/Phx/tt0oO+oTWL/CILQpmhbWg7by9rC408cesOZR1mKlrOp5TA0oyMirJPgExEe7viZcjoL5s7F
        nKIUi0yo+aTERI4WWrxG4FjL0Tp7N4kZYWhs/rSPwxPdhfJsEhNfgQVfLy2H+ElJTrbXRpRM5KBB+bt2
        1Z49y0RtwFxt+1qOJZBoHiCbqXA7tm1z8mk6R48edbVKjiNn8h8xdKhjfeUkDrQcnuw2GenpzlR4RWam
        6XJcq0xw+E8YN06Ld5G5s2eTv3W5yodCtXh1sX/v3qiBA23uiox47NChhQUFWlQD9rScqcnu7nVuGmzX
        a7KzrechjhwQLWtXr7butLLSUnv1DLFzWaY19Iy/l5dFDjiqbfN2WQdaTjV/SlKSkxeGOAAtZ/PKCOXC
        AgPHxsdT86IjR7QEQmvTJKaCIAhCc9OGtBxge1lbePyJQ8s5c2lc29FyI4cPbzvPPlmZmWnvHpL42Nh9
        e/dq8Ryyft260IAA63Kp+fgxYzDCtHiNwLGWGxgcrE7KOQbxkLFokT3LDHNq9owZTWLi69RLy5luJVq4
        0F4blfP18oodNmz5kiXHL3+gpTO0fS2Hj70kuAHBwc6clFOwDMODgy1yUM7TxYU53wJajl5dt2aNM1pu
        44YNpntWbckbpiW2vhbvIlMmTbJ5VR7DZO9yQXvEDR9uU0iYBtfHx+Z7Fx1ouUBvb2cersjstdlYcrCp
        5ejnZUuW2Jy6ODSnFq8ulIa0rjnzAXmsRTLgQMsxuGPi45vkGvJp5vvlbNaKrZUI9AbTVfRD20HGQhCE
        dkHra7nDhw4tWbRo4vjxsUOG2NNguKzMzHak5bzd3adNnWrTvGsVLZeRnu5hy4zAB2MlNDAQUwbr0LEL
        tmOGkkkLabmQEGe0HOzft492WSTHkS39Nnb0aCdN/MrKSjQM8zN12rSJ48Zh1UUNGjQwNPSyngkNtVlb
        PHE2ZcnpigrTg2SskuiOhHSCn5cXTZ4wenTO2rVnnT4t0Pa1XHl5uc2XTatWx48Y4fwNjax3lqFFPsqR
        FYKhSRa7Yy1HKU5qufy8vGFRUbY1g5vb0IgI4/usmeoIMJvTmP2NoN35+VpUJ5g+ZQodYt0EPJktNmWh
        PS2nkjDNtHj2YXSIbJEcpzrNWssRf+b06TaT4OiKy9adHcdWhmC2SKtcA7ScvSQNIG3GDEbZunUUsW3L
        Fi2S0JbQd4+ioqLsVdnLly5bbotM3LJly5ctz8/Pt3kxTrOCwbBnTyF1WJ5pFwJXZmUdlXcSCMIVSqtp
        OXbJvF27EDxDIiI49JqMBvMx3uI4h8MT1yRaDhc7dGiTmHelx45NGDPG+rGQOEqhAqkpKW1Hy81LS3Nw
        qwZZUeE6nT0bixxGx8cbH4neYJpKyx0tKrJ5QkN18shhwxxrOWx35NPUyZNjhw0LDw5GxKqn59vrK4tS
        lCMyzqaWYwamzZxJVva6FKeqiiMadaCH0xcs0K+/cjCH276WO3XqlL34Xm5uUyZNcl7LIQsnT5xokY9y
        qr2Fu3c3/tRcU2m53Xl5MUOG2OxkZlFkeHjexWckQmFBAcLP5uyiXYljxzq+Xc0C5hv1tDkr3F1cbD6J
        0cF5OSaYM+flTtZTy5WVlU0cN866ROXwV8utTmezUJy/pyfbslaYAcdazuaO2gDmzJzJwFnXLdDb2+Z5
        UaHVUdtsdXVVQlzc448+1rnTI088+hhfLJzJ85HHOv314a+++OJQfVZlk1BYWOjaz+Xhv/z1icdMNbHl
        Oj/+6KN/fejP8XFxFy405QUpgiC0EVpHy508cWLxwoUms8Z86PUy2+4ODuG45UuXOmPhnbD/fjkc+QwI
        DtaiNg6sagSVzTrjiaWyaOHCtqPlsOQCbN1A0iSOmkcMGNAkt3k0lZYjWoC3t0VynOpkB7dR1dbWbsjJ
        SZ44cVBYGDauaXKaFZeqks1a2XMqvj0th9maMGIEEcjfcbYqDhUI8PEZFR+flZmJtNAyskU7OC936pR1
        9XCqpUiIOpeATmVlJRPeIh/lyI3hW5Od3fhfyptKy6G+0GA2dyc8I8LDja8H2J2fzzK3GZlxnJSY6My9
        ozpLMzJCAgJsPkbFz8trRWamFs9Ay2u50tJSexc7NImj38YkJFj3m2MtZ++NlPWFTdhay9FYN/MNolok
        oS2htNzpitMDB4Tffccd99xx57133W3t7rvr7nvuuOvOW29/+Z//2uvcDQtNyM7c3I8/+PC2m2+59867
        7r3Lprv7njvvuvmGGweEhTm/tQqC0I5oBS136uTJWTNmcIz0dnheQncc7YiG6WDz9jMLiIP1hqSxyEQ5
        8qFcZ56hUicH9u1DHdk0OyjFz9Mze+VKmw9VbxUtZ3qwW3NquaEREfWyLO3RVFqurLQ0AjFmlYPqZJta
        jsN2SUlJxsKFqDhMWJtWbL0cyXEObv0q2L174rhxjCMWXp1leZjH18vNLdjff15amoMHGLZ3LTdtyhTn
        DY7q6uoVy5db5KMcuTGILMPaNqPlSo8dQyAxChaZ4KiqScvl5mpRmR75+Q7Oy02aMKFeK45+CA8Otjm+
        Ad7eK2w93bEVtNyxY82q5diWJ44ff+rUKa28i7Sulgv08bF+taDQFlBa7tsL367Ozo4YPDgsJDQ8bIAt
        FzYgNDQsJCRl6lTr2eUMFHShoZcLHT9+fM7stNDg4LDQUKoRHmbpBoRRt9ABISFr6nqLrCAI7ZSW1nLY
        XnPT0jztqziObQRxWMWM4MiH8+jfnwOt8w+BOHTokI+d6wnJmcP5/n371B7dGHbn5dm734lSAnx89hQW
        2rZ9rzgtR02Sxo9vwFM6rGlCLTe4nlru5IkTM1JSGFOb5y4a4CgL5/gxHkWHD8+YNi0sKIj4dKPNVls4
        b6L165ecmGhPzn2vtByrKXPZMot8lCM39hAWlDO/ATmmCbUcrWMULDLBtYSWCwmxOb4mLWfrrSRXnpYz
        CbM5c7TCDLSulgvx92estUhCWwI7QTcVKisry8rKzI/3t4U5SMVsAIWFhRvWbzhsuF22vpw4ccJR5crK
        Ki6+NKjxxo8gCG2NFtVyHLm3b9vmZv9aNfxx2BYDgoOjBg1CseAS4uI25OTYtCNtcvDAAeSfRc7KkTlH
        UwyXRpp3Z2tqspYvxxyxyF85jtaYTVpUK9qallPlkhsapt7OrMnp0oz0dMeixUmaSsthFIYGBFgkx6nG
        xkZHW2i56urq1atWuffta7Nc5QhSyU3dZXD8aRFTORXfQbeoYypTcf26dXHDhwf5+nqbTT1TQqvcjM5U
        B1fXWampNhfF9+oaS1QWc88iH+XIjf6kN6xfYlZfmkrLIb8nT5zoY/8aS5a2FrUuLZc0YUK9rmpm0zO9
        qs7W+JIb244Wz0Bb03L4G9dd/Zx5fwvw8lplq6UtpOVmzKA/LTqEP4N9ffPlvFybpGVkD9tLVETkB+++
        N37cuGqntz5BEASdFtVyx0pKoiMjjUcyC8ehDjsbNbJr507nn39gwdGiokGhoRY5K2eyBlxdJyclNfIW
        msOHDo1NSLB57KcIXHxsrBbViram5cgH8Rzs5xfUAOfrG+LvX68TKY5pKi3HANlU2qqT42JiLLRcYUHB
        wNBQgizi646ETE4/T09/b29arXeX+mKztnjinJS4zIo12dkTx48P9PHBtnZQE+WozIDgYPSnlt7A9+q8
        HFlNSUqyyEc5imC8MMe0qI2gqbSc6TmW9p99EjVwoPH8TB1abvz4emm5tFmz6A3rbjcNbv3fSdB8Wm68
        fS1Hv5mWm6+vtvnUy/n6hgUGps+fb/OFzi2j5ebPmUNudAgN1B1FhAcHF+zerUUS2hLWWg6f2tpae7db
        N4x9+/a+/NJLHTp0+PjDD48WN+WjJi+cv3Cu9pyciBOEK56W03LYZxy8bZomOI5qHEpnTp/eYAmnU1Za
        OmrkSHWYtOnCQ0IwzrTYDQI1FRYUZNNMwdPPy2v2jBlaVCtaRcthydnUchiFprcnHTigxWttmkTLkUnu
        9u02z36QLUakxfvliJ+VmYmlaxFZd54uLgOCgtAYaKe8XbssJg/JbVrJlIWr7+nK42VlWJzRkZHeDm+i
        M2Xer9+4UaO0ZAbau5ZLmjDB+TtaT508iQC2yEc5imBcmsTqaiottys3d8jgwTY7mUzGXP5+OUdazs1t
        7KhRB/bv16I6AVurzd8ITIOLlqvPOwnwaUYtN2aMzRK9+vcfHRenxWtqWkbL7SksZK7ScJqjHPlHDBiw
        OD3dpsIUWh0LFcQGeOzYMcZr+7btjTdUdFDyb772+i9+/NMvPvvM+FaSRkINd+3cuWzJ0uLiYpFzgnBl
        03JarujIkVFxcfYO7dgZKZMnN8n+WF5ejrGujpT2XPbKlQ0uCzHG0Z0KW+SpnLerK1oRo02LbYVjLUfF
        6tRyO3NzMfLqpeWWmC1R60Kx1ZCF29vMU9Q4WDZey52uqFi0cKFNy4xssaVmpaYaTfzDhw5NGDPGptFM
        TRjoKcnJtWYwPa31TNNqOVAFodgTx4718/Cw2RAc/oPDwvbt2aMlu0g70HLl5faeqkoSrGry1KLWhekh
        NwMGWGSCUxepRg4c2DJaLmftWme0HH0Y5OtrPTQ4BtpCq5SUlMTHxtrcZxgmmra1Pi8lI3PbWfXvj7zE
        5tPiGWh5LXfq1Km0mTPtJRk0YIAWr6lpGS3H8jl79ixz20h1VRWdIKZ228RiXBivycnJDz34h3feenuH
        4XLoRlJYWPjW629c9ZOfffX5F0ea4nHQCvaHzz7+pNNf/ho/Mo5jiuYrCMKVSMtpucLdu7FjrC0DHAfv
        ASEhTfiLFLrRpmmuHHZeTFQUxisx63UQVZE3b9w4wM4T4XDYRqgRFd8mDrQcLn3+fAciU1Vg08aNNs8K
        4mNPy61cscLf29s6Ca0YFBa2ft06LV5r41jLBfv7qyesOB419N7YhATrxuLwDPD2Xr1y5bcGWVJYUDAy
        JsZ6QKkDnlOSkhyra9RC02o5BRYeUwWRYG+y4ck02Gh1RqXtazn6xOaTaZSjOAdP6bSAXcXeOx5py7jR
        o23Wub5gwznQcj4eHqtXrXJmJ1m0YIFNQUUmtNp6aCaOH2/zdDqR0UJZtl4kYJPd+floFZtborerKzLv
        wL59WlQDLa/lzlRWLs3IsJkEzyA/v9MVFfXasZ2kZbSc0O6wmGxsXHGxsbfe2PGWGzvGDB16vBEPOzGy
        p7DwbbOW+/Lzz5vk1T7AtjwiJuaOW2795S23hgQFiZYThCubltByakPM27XLpjHBcd3bfCu/itwkYBNE
        hIdbWyG6I2jZkiVKNdXLODh08KA9nYDDGsDgWJKersW2BQa6u61TZPiQ7dTkZCJoUW1RcvQofaXiG5Pj
        SG5Py23asAHNZm2smJJ4ei5ZvFiL19o41nJ4Tps69dTJk8R0MGrbt23z8fS0mQM9gCAsuvxXA8R5aECA
        9ZgSGf86LWZq0hxaToFQXLdmjacdiRXk67tk0SIt6kXavpZDGqnbouylSp8/nzhabPucOnVq5vTpNncV
        BB6DMnf27Hqtbns40HI4+ic6MnJPQYHjsvbt2ZMwYoRNLWcaSj+/DKuhnDtrFqE2y2WkJiUmnnDu2ryZ
        06bZnKI4NFXazJlqTVnQ8loO9u/bZ6+L/Ly8mNtNcqLVAtFygk0sVjTTdeOGDU889viN11734nPPZdp6
        +msDuKjlftqEWm7F8sxX//Pfa3/+i86PPJq1YkVzrBpBENoOLXRerqa6GqMTe8LiSInjIB3o44MdqUVt
        CihuzqxZWAw2zSAchQ4ICclZu1btcU4afMVHj2I/qYcNWmSonLerK0qvvLxcS2ALBKTN39px1HZgWFjp
        sWNaVCtKiosRe5hBXrYqQK3sabnCgoJ4+3Zk4rhxjZQcTUWdWg57KyM9Xd1PZTFq6s+TJ04oG9QirXLk
        MGTw4AuXH9iYBsxM6xIpa2BoKBanFs8ONTU16GHrKUGGuMZ0rGrRsWPHYoYMsa4eJQZ4eyNXVGSdtq/l
        6LGF8+dTls1UuLDgYGfO0m+hpXYePEPmCN2C/Hwnl7ZjHGs5k3NxGT9mjOPXcqQvWODj4WHzLCL7BlrC
        +vT4yszMUDsv+GaGDwgOVpPTcRsPHzpk+mHLKgflaJT1qV1Fq2g5Nk+b2yNJqMzkiRObZEAtaCEt9+23
        zOrVq1YtmDsX/cwRCrciM3NvYaF1PwhtAevJxlYQHhb+63vvu/mGG708PPbX555VxeFDB9lL9xleKV5o
        R8vl5+VtWL+htLRU+9tpOGR4e3recett9991j4+nV513bQiC0N5pIS2HCJkyaZLNx1FwsDf9Ju3wXFZ9
        wejcv29fiL+/RVlG5+nqGh0RgbWkv3fFASrD5IkTsSfs2aD4Y1tnLl2qpbEDG+ugkBCLtJeci8uSxYv1
        zdd4LDlaVDR10iQqYNPAwtGT9rQcR6B5aWm2H4bu6hoaGJjVRD8xNhLHWg5H28ODg9Pnzz9h6/oWNN6y
        JUuYTjYtSMxoLEjEsBb7Ips2bGCqWCdhQMNDQmw+K1Knuqpq+dKlNkcEH5y1lsNuy9u1a83q1Qhsm+rI
        Ao7lCbae5UOFg/38rN8M1va1nGk17d2LsLGZCsfanJmaWubQiKH3xo0e7WXnVD+fI4YNayoTuW4tZ+6l
        6Skp9p5HslM9r8jOTwyMC1LQekozT4ZHR9v8CcZUmb59R40c6fiH/JKSkqQJExhW6/mAwzNq4MBDBw9q
        sS+nVbQc62VMfLzNrsaTdbqnsFCL2iBsSsGW0XIF+fnJiYmmB9W6uQV4eZEzzs/TM2748K1btshVcG0Q
        m7MlPz//tVf+d9P1N/z1Tw+NHT2mqj433ldVVY0dPfqdN98M8g/YffGhtRfvl/vpl59pWu78+fNbt2zt
        36/fO2++lTZ7tuNLdSygzonjx3fu9Mj1V1/z6n/+uz4nRwsQBOHKpYW0HPYoloG980KYpE2r5RRTkpLs
        GTHKYU8E+PjMmT374MGDqM1Tp06dvfwXLPZQDCxC169bNyImBmOC3OzZGZQ1cdy4Ol/gi05LiI11kA/K
        KmftWsrVr4s4ceLEgX37Jo4fr0qxmRBHnja1nDogbd+61d/W2Sflg6GJ4ejkqxqwxbHSDh86VLh7d8Hu
        3bWNe8GDkTq1HP7Yl3QC06m4uFhXSvQVmjxz2bIBQUE2pxmOhKYbzNavV0l0crdvjxg40PrsB9VgeqCB
        jTfXGWFcVmVlka1FQuWoKs5ay52uqIgdOhSzflhU1MYNG44bBtomjIvNs0+I8IGhofl5eVq8i7R9LaeI
        GjzY5sXGODzd+vadlZq6b+/eUydPGs1cZBUab9vWrQg54thMTivYUpgMWppG44yWI9TX03N0fPyO7dsZ
        Yr2vKsrL83bujI6MJILNHEyeLi6LzBughe3I5JmRkmJzUHCmZdK/P3KOzYFZZLzPln2MDQTZwzJxZybY
        qTk5LM3IsDdAraLl2IKys7IQ8/b6anRc3F6n5Rwri344dOhQ/q5dBw8cqLZjE7eMlpuEqDa/6c6YP/0T
        4O1No6ieFk9oM9jUcjB+3PjHHu507c9/8crL/1myZInm6wQnT5x06dPvZz/80f333Ovr7YOKw5Nd7q03
        3vjFT3761edfqId7bd+2vXfPXrfffMtVP/1ZZEQk68ic2inWZK9+9T+v3HDttX996KG4ESM0X0EQrmha
        SssdOzZtyhR7Wg5LceH8+VrUJoJdeHd+/qCwMJs2ge48zdrA290dIyxt1qx1a9YcOXz4WEkJFS4sKMAc
        TJk8OXLQIOwhjsEO3uBMKwaHhe0wPxDS3gFAgfkyNy2NrrBXMQrC0EkcOxaD9eSJE2z0GFVhgYEUUUdb
        7J+XA9PTGseOtWmvkC0uPCQEaYE+wXK1kGcYpliK+GPxY1vvys3Fzo4cOJAOcXNxObB/v00rvwHUqeWU
        IwKfKJnFCxcyWLitW7bQS0wkm9ancrR95PDh1sdFBjpu+HCkkUV8HAUh85AxNF+3OzEQMQppNZ3g4M0B
        qlettRx9GDFgAEFKBI4fPZppg8VP9xovhjGVUl1NKcxA5oMxZ+VozpDBg8usrutrF1qONbIsI8Pm83iU
        Iy1ylzlJhSka/YaVw6pkSU4YM4b6ODD3aUX8iBGOL3WuF85oOeUYqbCgoNkzZuzcsYM6Hy0qYrGHBgYS
        ZC8504AdZsvmzVphl8NosvZtjguOPOlAVn1KcvKa7GzWOIUeOniQfWxyUhL6xME+w+IdEBjo4Gn4raLl
        4HhZWVhwsEUS3dGiEcOGIY8rKysZF4sc+BM1iD8LigmzbcuWqcnJLGE/T8+I8HD9TIgFLaDlOKxER0TY
        OwIyZzY70Z9CC2PvUM7e4uHq9ut77r31xo6fffKJ88+0rD1bO3XylBeefe6m62+45467/H19Dx48uHfP
        nnfefAst1+Xrb4qKju7atatf7z633XTzbTff/Mp//rvYcJ2OY6gt1gKC8M7bbse5u7o24VMxBUFoy7SQ
        lsPiTE1JsXkk47iOxZCUmKhFbVIWp6cH27p8zsJh1hAHx+GcyviYHV/4E097xpDuTKaJm1vO2rVaqQ7B
        IEYLBdi3YnFkSCjqgh6jGjbrYO1DNAdaDjAXkF4WqXRnKtTNLXbYMKxPrOfS0tLa2lqOIhhGKIqVmZnz
        0tLGjhqF/efety89Q3wcX1ZlZTXy3es6Tmo55VTp2mDVNVKEhgQELLP1G6qDyYkjz0A/vwVz5+bu2IH5
        iMTCjsTMHRAcbC+JciTEWWs5rMzI8HA9mqkn+/XDJ23mTKzbmupqHD1fsHv3nFmzMENtDhk50yLsdS1T
        A+3lvBz+2tkqq4S6U81Uo6wWJt/xsVmccl79+6MAmZNaMU2B81oOp+qsamvaQ+qazAxK2qxZFKEVdjlo
        MyYn+VikMjq9ly4V6sTGRQSWg4PLt1pLyzHN2Gp87Ty+CGeaD+7uyPV5c+Zs37qVTKqrqnC1Z88ioVev
        WjV/zpxRI0cG+/kRTfUDn4E+Pkg7rYzLaQEttzM3lzVucxypG6U4efgQWhIHP8tu2bL5s48/ueXGjvfe
        eWff3r2RZI5/w9WpPF2ZMmXq808/Q9q7br9jYHh45vLMt15/49pfXPX1l18uX7bMy8Pzths7/vLmW17+
        178yFmc4KeRYNUePHvVyd7/vrrs7Xnf9R+9/wKzWwgRBuNJpIS3HRpOdlWXz2Sc4DqLhwcHl5eVO7ob1
        Ypb5SXc2TYqmctR/UmKi8w9Sh+hBgywycd6ZLCF390BvbwtbhzY61nKlx44ljhvnuCtUnsr60R1/6v56
        TPUnbc9eubJVtFy9nKer6/ixY63PYilyt29HUTgulFC9NyyCbDqi4erUcnpk9amK0EtRn9YO7TooLGyD
        rXsh2ouWg+VLloT4+9sstGGOEslt9owZ6uk4TUW9tFy9HLWNjojYbXWhrJH8vLwBwcHWA9pgR0PIbeyo
        UY57qbW0HBb0iePHmVQqpjGh0akgPilCd/yp++sx1Z+tq+V25eZG2NdyAd7eouXaII4NksWLFr36yis3
        XHvd/Xff4+3pefjwYScNmNMVFTNnzHj270/f2vGmX91z7zdffvXMU0/dedvt/3j+BTTYPXfcefvNt/z7
        pX9mLl/u/Bm5oiNFA0LDyO2m62546YUX0xcu1MIEQfge0EJaDnbu2OHet6/FYUw5DrQc2qenpGhRmxQk
        4oyUFI6XTWgyWjjq7+Xmlr5gwZnKSq3UusjKzMQ+bkCVTPZK//5j4uNNv9Zfnpwgx1oOSoqL1ckQY0Jr
        RwRrZxEHhydNaLHzcvaqUafzcXPDVkONaMVYUVxUNH70aJuWlu5U6bbrgKXo7W0aTUOQimlby9l6vTVO
        z185i1Dd0T+4tBkztBwvpx1pOUibNYu13yRrk+K83N0njBtn72EeDaZOLWfd2844MvTo23fVihWOl0/t
        2bPr161j7BxUwHlnysT8QvYjhw45Nj1bTcuZ2bFt20Bbr1GxcGRl7Szi4PCkzqLlhHrheIEQOmvWrBee
        e77jdci5uz09PPfs2aOF1QVHgbTZs5Fzt99087133Pnre+/7zX33/+ree1F0d9xy63/+9W+EXLVzQg72
        7t0bEhz86/vuv+n6G5587PFZM2eyLWthgiB8D2g5Lbd3z56BoaE2D7TKcTybPXNmnb9soc0QQuNGjbJn
        lVpTXVWF3gjx98eAcFCBxjiyDfD1TZk8GQtJK9UhtbW1kyZO9HR1tfnAcXsOUwCTbt6cOaYL8GbPtrAM
        sAnq1HJQdOTI0KgoIjfMADU6Wk0m69asaarHnzjWciEBAf7e3nypV83pJQwyJoBNKaLD/DS9lbuefUJN
        3Pr1y1i0iGpbWNt8x9nQchUVMdHRKoIe2XmnOiclOfmEnTfVYmQH+vhYZ44PXTF9yhQt3uWwrBbMndsA
        LXemshLBZrMteEYNHOhYy9XU1KAhfTw80NsWyevlqKSXi8ukCRPsPUmyMTjWchTNgNJ1fLEIcuDIzdPN
        beb06Y7fZKCorq5GzplEr52l4aQzVdLFZXh09N7CQsfLAZgqTBjr4vAJ9Pbe2sxa7sL581s3b44aPNjB
        806dd1QjyM9v29atWu6XQ9/a03IBXl6sCy1eI9i5Y4cDLSfXWLZN6rRG2BlSp09/4dlnb7r++nvuuKtn
        9x7sk+eceyRpRXlF2qzZzz/z7C9vufXuX96BnLvj1ttwr/73lSVLljh/Rm7zps39+7n86p57O153w+OP
        PjZ1ylTWnRYsCML3g5bTcqWlpVPMj9S3OJLpjgO2n5fX5IkTV69ahalh/K0a5XP40KH1OTnz58wZEx8f
        an4QSFhg4NGiojp3WwU7I5bB2FGjKAWbu74mu3KkdWCx4Y/ZkTBy5Ib169litYLtc/z4cTqEVA76RHdE
        w/oJDwnJXrlSPV0TKxAfizjerq50kVaAfQ4eOJA4dixKkq6w1xzHTnUFNccKKSsrc3IU6sSxlhsYErJ5
        40YEsxpEi1BrRzS6CAVIpxmf8mcTit6VmzswNNRJo5w4xAzw8VmSkVFZWTl+9GiLhJTOcJy2euMFUzEr
        M5OK0Xv16nwik4pSZkyb5uBxqWg5akU06+R4TrfzCwj1TJ8/n/yte556qtc027T+afsQ83k5i4TKJ2rQ
        IGs1awFiL3PZMuY2y4caOrh9zqYjCZOBPSFj4UK6pU6J0gAcaDk86bQlixfPT0ujlyz0vE1HBOqMpmIs
        SoqLnd/BED8xQ4YwqdC9dZZi4Rh9b3d3akihpsnjRKHENNXTaoriGeDtbe9hLUZOnjjha2uHIQfc6pUr
        HWg5OH/u3I7t20fHxbn37etMx9p0qrcRnyNjYvbv26dlfTlouUFhYdZbCmn9vb2b5Lzcgf37WSY2dy36
        J8Tf395b/oRWxJm1WWG+YPJf/3jp5hs73nHb7R+8+97ctDn2Lua3gL0xdXrqS8+/cPftv7zlho533f7L
        N19/PX1hupPXuZSXly+cv+CzTz5BCt50/Q3PPf3MlMmTT5y0+zQjQRCuVFpOy50/f37b1q0WhzELZzI3
        +/cfEBQUHxubmpKyaOFC7MuF8+ZhvI5JSFA/nWLKqM9gPz9n7AlQOzKfhw4eXLliBWY39j2HVZM9bTZW
        cDYNBTxVKOYskfGhDqbXl9m58kf9aj4wLGzh/Pn27AaFqhKW3KzU1EBfXyqjamJRDf40NdZcevyIERvX
        r1fPZ686cwbzl2pgn/l7epqclxcx6ToaaC6hDg4fPJi5fPno+HhyNvWD+XkJqr3GCiiHvwpSlfEzvxo7
        LCgIMwszSMuxKahTy1WUl584cWJeWhrf6W0qY4psiGOsJ18SRoxYt2aNOjQ6c2DetmXLqJEjVXI+rWvC
        n/gzXnQ+MRFO6ukRSGu6xc/Dg4FgOPhONPSJ9WW3VKP81KmctWsZUEpRD6ugIJtlKX9TNPN0jRs+fMXy
        5Y7P5Ozfv3/cqFEkVzVRLsDLiwk2ZNCgtWvWaPEuh+W5a+fOYH9/OpPKawm9vLDF8cEOPnH8uM0OPFtT
        kzZzZqCPD9W7VCIJPTxMZ9pnzHCsolWe1VVVrGWWuUlLX/70Dos+waluUaNATDqZFc3SQHGpPJucOrXc
        hpycUydPZq9cOXzoUDxN08NiNC+uIPqTeYskQ3mWHjumFeAc52prd+fnz5k1KzoigtyYbM70kmnymF++
        wha6IjOzzOlXDyMdmTBMG9PL0AxTgsyZYM6c/8TMHRwWxkq5NDfME4MZZXqqZF4es06Lagc2BDbtjPT0
        2KFDqQlp7S0W5VSrVcNNS8Y8ewcEByeOG7d961Z7D3o5cvhw0oQJjBEdZZzD5KPeEKPFawTs2+oqbipm
        rDCSnoJGxMQ08r15QnPgzCED2B/mzZ33zptv3XHrbTdff8OLzz8/NDp6+7ZtzpxbI+3kSckvv/TPX993
        /1uvvzFv7lx1iHcMcfJ27RoxfDga8tYbb7rtpptf/e8rqdOns+K0GIIgfJ9oOS0HbDSINIuDmYXjYKwO
        wxiUxheq4oO/fvzmKIuW27Rxo5O7rZHDhw6tysrCykTJRIaHk4+vp6fpKItkMlvMylEiR3d8EG+RAweO
        iovDZsV2T1+wINxscep1NjpVfxKiczbm5Ozfuxc7VSv4clTNT544gSobO3r0oNBQmonlYTJBzBUgH2qF
        7MSCx5Tct3evSgikLT56dPGiRTNSU6dPmTJ96tRpkyfTouysLMfvDrbgwL59y5YsmThhAtZhaEAAxSmn
        dwLO1A/mWmHoYzcPGTwYo2RuWtqa7GzHerUB1KnlSswPmEEgodAmjBkzKCyMGqoTjKq22EZ0I9Yb8gNF
        rXea8/Nkb2EhtiM6DbHq5+VFTVTOODVJEMxxsbHz5szZa7g7AsMU63xGSso083DgZs+ahRXo4MxDYUHB
        kvT0iePGYTHTt9iRtFEvC0td/YkVzvQbN3o0tTKWaA/mG5VZOG+eXhPT9JgyhdmL4EFyaPGswPJYvWrV
        vNmzjaloUfr8+dglWiQrvr1w4WhRUebSpSgxvURTwmnTli9Zwmys80SZPjSnT59ev27dzBkzYqKjWQ4s
        fLXk9T7BMQRMD/okPDh4+JAhFEQns6JVDs1EnVqO2ahagdai+VEDB6JjCdWrrX53YCcZGhlJl+bu2HHe
        4SkpB6CNGQ4WO9sC0pc8Tb1k2DdwlIUPX+glFmzKlCmouIMHDqixcHItMFXQqEwbfVjVyDK1CvLznXm6
        TO3Zs0w5NszUi+tCTYyFc+cyavSqkzUhGh3LPBwTH09zWCw0jZVOw/UmK8fc4JM+YWcYHh3NTjUvLQ1R
        WnpRwdosEc+C3bsXzJuXOm2asaXqLTVOnmOpk00bNjBkVM9YbW93d6qavXKl9Y8+Qqvj5PwE4i1fuqxn
        t+4PPvDb66+++g+/+123Ll0QV3v37K1T0VVWnpk2NWVAaOjCBQvqjFxTU3348OE5c+b07tnzT79/8Pqr
        rvnt/b/65quvF6WnOz7LLQjCFUyLarna2trtW7eGBQZiANk0jJx3Sstt3rhRy7pBHC8r4/iasWgRhhGK
        aHRCAlJTOYz1hJEjE8eNm52aujg9HQl3rKREpcKIQX2pVx1gMFlUTDlap8wp7InlS5fau5JK90TRYTTM
        njkzafz4hBEjKJ2j/thRo7CBSF7fW4CcPwIpztXW7ti+nUZhZWJpoUIpXe8HVM2k8eMJWrJoETaH6eft
        ZjsBUreWM79KVaF32phRo1SPjRw+HIE3d/ZstHoj75sqKipauWLFnNmzpyQlIQtVb6Dnp02div+Rw4dV
        tPp2tTWVlZV06ZLFi2empiZPnKgKwtEWJgPTD0W6aePG79UtEBjua7KzscJTJk+eMHbspdk4fPio+Pip
        ycnIieysLNOpjEb3vzM4o+V0QwotjdaaP3du0oQJTEtVc0QF2oCtxt4rzhoAOpleYndKnTo1adw41T/q
        kyWcnJho+nHHvGC1BO0fduytmzezWGgaC3NsQoI+N3Cs05TkZILoE7Qi8qz81CktZeNo/DIHMiksKJgz
        a9bY+HiqrRzqceuWLercdZOUIjQh9R2R7du2h4WEvvDss7d2vKnjddc/2flxH0/vBfPmsVOdPn3agdbi
        EMy+4eBnr/Pnz3Ok2L9v36KFC0OCgp75+9M3XXf9LTd2fObJp4L8AzZvcuoCJUEQrlRaVMspVq9cGRoQ
        gA1k0zZy0nmZ72Vv2Hm5xqCKQ85h0Pv7+FjUysLRQC9XVz8vr5QpU+w9qUIwUi8tJwgtQ720nCAIVwYN
        sC5OnTo1PSXl6y+/+Nuf/4ycu/Ha657s3NnDzS1lasqG9euLjhxhM2GvQJtxsLOXP/5w4fx5YlZVVZFq
        88aNM1NTfby8nnv6mZuuv+GGa67904N/+PSjj6ckTy4tbZrzxoIgtF9aQctB9kU5Z2EYOe8w90MCApy8
        X645QM6tW716UFgYNbGom4VDzkUNGmTvcdiCEdFyQhtEtJwgfA+xp7XqZM+ewriRI997592//PFPN19/
        w3VXXf3bX/367ddfDw0Onj4tZXX26ry8vIMHD5aVlpaXl1dXV9fW1rKB8FlTXVNRXlFaWnro0KH8vLw1
        a9bMSE0dEBL67ptvPfjAb6/9xVUIuT/9/sE3/vdadHR0QUGBVp4gCN9vWkfLwYb164dFRnqYjSEL86hO
        hzrycXdPbZ730TnP+fPnDx86FDtsGFVy0AqUib+XV9by5VoywT6i5YQ2iGg5Qfge0mAtBwgztNaohFGf
        fvTxk491vu+uuzteex3uvjvverLz4x++975b//4RgwePTkiYPm3anNmz582ZMzctbXrKtDGjRkcOHuzh
        5vbRBx/+/Ykn77v7nhuvvQ53zy/veKzTIx+9/0FsTExubm6dT2YWBOH7Q6tpOUyf0mPHlmZkRA0ciKIz
        PVXCbMFbG0xK72Hfm65X9PT09fQcN3r0pg0bmur91I0B7VFZWblsyZIgf3/TDXK2rD1qTrVFyzmDaDmh
        DSJaThC+hzRGywGHM7YO06NKZs8O8PN7+/U3Hn/k0d/+6td33nb7rR1vuvmGG395y633mF8Ujqdyv773
        fnzwJ/SWGzvecettD9z/q0f/1un1/73q4+k5Y8aMAwcOVJ4+XecDYAVB+F7RalpOwU53rKQkd/v21JSU
        kcOHh/j5oej8zE8zD/DyUs+x9DC/fmBYVNSkxMSVK1bsKSw8cfx4WxByOlVVVQW7d0+bMgWdiWxDihit
        Pf7E2stculSLLdhHtJzQBhEtJwjfQxqp5XTYQEpKSnbt3DlvzpzoqKh+vfu8+/bbzz/9zKN/+9tf/vTQ
        g7/93W9//esHfvUrtNyDD/z2z3/84yN//dszTz719htv9OnZM3LwYKTgju3bOfZVysNOBUGwRStrOZ2y
        0tKDBw7sys3dvmXLxvXrN+TkaG79+s2bNuG/d8+e4qNHnXlhS2tx/Pjx9evWJY0fH+Dj421+85XSJF6u
        ruHBwU37HrYrFdFyQhtEtJwgfA9pKi2nU11VVVxcvKewcNvWrdmrVs2fO3dK8uSE+ISYYcOGDhkyLHpo
        QlzclOTkeXPnZq1YsXXLlsKCgqNHj9p7L6IgCIKirWi5K4ajRUVrsrNnTJsWExUV6Ovr6erq7+0dO2wY
        m7IWQ7CPaDmhDSJaThC+hzS5lrNJTU1NZWXl6YqK06dPO/PaRkEQBAtEyzUL1VVVO3fsWLZkyfSUlEkT
        J2avWvW9ej9YgxEtJ7RBRMsJgiAIgtA2ES3XcrTMj3ztGrRcXEyMPS0XHhxcfPSoFlUQWorKysol9rWc
        j7u7aDlBEARBEFoF0XJCG8LBeTkPOS8ntBJVZ84sW7KEaWkxJ3F4+nh45KxbJ1pOEARBEISWR7Sc0IZA
        yyVPnOjj7m7zHMjImJiysjItqiC0FLW1tVs2b7Z3Xs7fy2tPYSFTV4stCIIgCILQUoiWE9oW27ZsGRUX
        5+Pp6enq6uPmZnrxoJubp5vbkIiI1StXVssTvYTW4PTp0xPHjw8LCjKdiDM/pZZPtNygsLAZ06aJkBME
        QRAEoVUQLSe0LWrPni0vLz9eVlYKx47hoKys7NTJkzU1NXLPodBaVFRUnDhxQs1J5ZijJ0+ckJc+CYIg
        CILQWoiWEwRBEARBEARBaH+IlhMEQRAEQRAEQWh/iJYTBEEQBEEQBEFof4iWEwRBEARBEARBaH+IlhME
        QRAEQRAEQWh/iJYTBEEQBEEQBEFof4iWEwRBEARBEARBaH+IlhMEQRAEQRAEQWh/iJYTBEEQBEEQBEFo
        f4iWEwRBEARBEARBaH+IlhMEQRAEQRAEQWh/iJYTBEEQBEEQBEFof4iWEwRBEARBEARBaH+IlhMEQRAE
        QRAEQWh/iJYTBEEQBEEQBEFof4iWEwRBEARBEARBaH+IlhMEQRAEQRAEQWh/iJYTBEEQBEEQBEFof4iW
        EwRBEARBEARBaH+IlhMEQRAEQRAEQWh/iJYTBEEQBEEQBEFof4iWEwRBEARBEARBaH+IlhMEQRAEQRAE
        QWh/iJYTBEEQBEEQBEFof4iWEwRBEARBEARBaH+IlhMEQRAEQRAEQWh/iJYTBEEQBEEQBEFof4iWEwRB
        EARBEARBaH+IlhMEQRAEQRAEQWh/iJYTBEEQBEEQBEFof4iWEwRBEARBEARBaH+IlhMEQRAEQRAEQWh/
        iJYTBEEQBEEQBEFof4iWEwRBEARBEARBaH+IlhMEQRAEQRAEQWh/iJYTBEEQBEEQBEFof4iWEwRBEARB
        EARBaH+IlhMEQRAEQRAEQWh/iJYTBOEK5/z58/v27duwYcNGM5vMFBYWXrhwQYshCIIgCILQDrnytdzZ
        qpqy/UVHthce3lZwdNe+qpMVWkBrcOH8hVNHyw5vL6Q+R3bsqSg5fv7ceS1MEITmoaamZsqUKZ+Z+fQi
        bm5uu3fv1mII7YQzZ84UFRUdNFNcXFxVVaUFCEJ9OH/+PPNHTaRDhw6dPn1aC+AwfeFCSUkJniqooqI1
        bQZBEIQ6aQkt9+2FC7XVZ6srzpw5WVF5vFy56vLKmsqqczVnCdXimeFP/M+cMMUhPgm1AIecO1tLhqYk
        J8opCMmkBXz3XWH21shnv+nR4dFeuKueWhKdrAW0BhXHTkz8OqR7h0eoT48Oj6T0jTpxqEQLcwJjM2tO
        VxmbKQgXzp1n8qv1ZeGYMFWnTrOyztee02LbgQhEY5qpNYirOllRc/pMbVUN+as4LNKzlVXGnL/99lsV
        ZA+mLtG0JCcr9KxakvT09C5dunzwwQfvm/nwww+Rc3l5eVpw46AHqqurKysrMQqtwR/VcfbsWTkT2Bjo
        5KVLl3bt2vXjjz9Glvfq1Wv58uVaWCtBlWpqatS4ozNra2vrXAtCi8FYsO700Tl37tLud/z48d69e3/0
        0UeffPIJW8HcuXP1UCK7uLioID5TU1MZVhUkCILQBmkJLXdo6+7ZviMHPflF/9v++U2Hh7v8oFOXDg+H
        /u2DcZ/4LYtNKc4/oMUzg7YZ9b5Pz6ue6tbhEfdf/it94AQtwCGrE+eF/u1DMu/6o0ejXui2Z812LeC7
        79anLA784zvk1qVDpx4/e3zkay6teKw9fuBo2CMf0QNUFUU37F8996zZpoU5waqxs4P//L6pmT9+bOi/
        eu3LydUCBOG77wqyNg1+6kvmlWmJGRwTpsfPnvB74PWxH/nmTE5HU2kJrEDIrZ+6ePR7Xv4Pvt3j50+Y
        VmuHTn2ufybm5V7T+g/ZOnel+m2lbF/R2A99e3R4xBTh/x7x/tWrVeWVKgd7ZI+fQwW6duiE63/zi/vW
        7bD4EacFOH/+fGZm5ldffWUh57Zu3arFaATl5eWDBw9GY5At+RvBp0ePHkFBQcnJyYWFhVoCof6wdU+d
        OvXLL79UHYucmzhxohbWShQXFw8bNuzTTz+lPlRs+vTpiAQtTGhtjh075uvry9CwBr/55puVK1eyCehB
        LH/TLvD++yzbuLi4o0ePqiDWskqigoYOHXro0CEVJAiC0AZpXi337YVv5wYleN//v24/fqzbjx41m4aa
        6/rDR7r/9HG+jPvYv7rijJbgu+9WJMzwvPs/GHwEdevQKfih94rz9mth9ol89huzWnuYhH2uf3Zq7wgt
        4LvvcqYu0rVc9592HvFqv1bWcp0+VOZ1A7RceOfP6BPVzH43Pje9f7QWIAjffcda69fxeTVDLJwSXd1/
        0rn3NX8Pe/ijzTOXaWkMFOXuGfrPHkQgGpH11coX1m+Pnz3e8xdP5i1bT8zyo2XjP/Hv1eFRc4ROXX/4
        6NqkBWfPVKt8bDL6PW+0H/FZiUzdoty9La/loKamJjs7W4kBZatBt27d5syZ08htYcuWLb169froo4/I
        2SYEYRd27959woQJVENLJtQHay2XmJiohbUSubm5jCyzSFXJ3d19586dWpjQqlRUVKSlpalxAYYpIiJi
        z549KhQtpwYOWJgjR44sKipSQaLlBEFoXzSvlktxie5/6z80g69DJ4y5Xh0ew/Xs8CgOMdO7w2NDXuhm
        PDW3KGJi/5tfNNmjPzDFR9cd2pyvhdkHyUeGJDGV8rPHx37kqwVcruXawnm5AY04L+f3wOuXmvnzJxK/
        CNICBOG772Z5x/a5/hnmhpJhTDC13BBdPTo8ir9aiYR63fvf3EVrzp29dOHQ4W0Fg578ouuPkGfqZ5RH
        mGkqLV9YieSGyx43p6bSdIdS+qDEb8wxyQ2X+GVwRYnd0xH7cnaEP/ap0nLf/KDTgM6ftco1lorq6urV
        q1frcs5s5n2AnJsxY4YWo0Fs2LChR48e6pd+MuSLfm+euohLFQRfffVVfHy8lkyoD23wvNzWrVu/+OIL
        s9lvOs3LHGiS07xC4zl58uTkyZMRY2p0+BIUFLRr1y4VanFeTrSc8P2hFW1goZloNi337bd71273vu9/
        yrLECux7w7NTeg7aOmfFppnLVk+YO9NzeMy/e3f70aNouZOHj2mpvvuOVEF/fh8jsm+Hzl3+r9OkbgOc
        uWVupldst5907tehc88Oj/k+8IbxtMOVpOWm94/G2qZnMK/9H3x7a9oKLUAQ0HI+sawys2br1Ovqp8Z+
        7LdpxtItszPXJS9MdR2KfkPRMfcQVMQZ8Mgnp0tPqoSVx8vnBY/ual4jzExEV8TTX2WOnL59/qoN0zKW
        Dp2c+EVwyF8/YMauT8lQ63FzWqbf795QqxvncefLzG2VmzUrR8/y+dWr3cw/6PTr+PzcwAQtoJWoqanJ
        ysrq37+/kgRYbHx2794dOdfgzWHjxo09e/ZU1uFHH33k7u6OTZ+Tk4PGmzlzpouLi9kyNEGcb775Zs2a
        NfrlXoKTtEEtt2/fPiYSNQHGPSEhQb9UT2hdmC3bt2//9NNP1egwYViJ6DQVKlpO+F6xatWqyZMnL1q0
        iJmveQn1pC1r4ObSchfOnZ/Sa1CPnz+B9WYy4G58bnLPQeVHy7Tg776rKDmBbFs1ZvbWOVnnai6pNSzF
        zbMzF0cmLQgbmzVq5pEdTt1eUrL7QPa4NJKQcOOMZdWGu3euJC13NG8/PaaaSS8Zr00VBF3LKcm0dNgU
        LQC1VnaKaeN+x78JUuqLFcHSU49CyVu2fmDnT5mQKij4z+9tnXPpZ4LzZ2tLdh/ckb6aDMv2F6lrI1lx
        4z8LQBxezK3TppnL7D1YZeJXISw9FiBFIOr2rG79ExfoqC1btvj6+mKuKbuNz65du86ZM6dhzzkwarlP
        PvkkLCxMC/juu6qqqrVr13p5eekX4xEtPDy8utrRVamCNW1Qy505c2bz5s1MG1i4cOGBAweMD9gQWpea
        mprMzMy5c+cyOkuXLi0pufSksbap5Y4cObJ48WI2E+1v58xH4rCZFBcXnzp1Sp6u1C5g+qWnp6OvtL/N
        h6QTJ04wRev7bF4mOalKS0u1v61gSjCBIyMjQ0JCli1bVllZx83tggPY8Bk1FmlGRgY7Rtv5Qba5tBxW
        3ZAXu3b78WNdzKIl9G8fbpu3UgurJ3XuZY4jXDFarjmq3Ypd0XxckY1yhsu03I3PpQ+aQF9oYd99d/LI
        sUldwnT1RZzprkNPFZkOAKwRt9tf0mXelN6D1bNMHPXkt9+uiEtlWakkaLlJXQeg9LRQA2dOVIQ9/JGK
        SRGRz3Y5d7atGLvIuYCAACw2Zbrx+fXXX3OIbYDKMmo57D+OmlrARaZNm6ZODpgsRPOTGDgqaGFXIs20
        X7UpLWevjd+HLajtt9FxDduglqNcZOeAAQOY5MpHNYHt6MCBA1u3bl1vhi8FBQXG3YMI+JMKE/PK3lXq
        RVueojt27Bg2bBhjrf393XfMsQULFnCY4KikeTlHYWHhpEmT5syZgxTUvC7n7NmzqampwcHBycnJ6rx0
        K/aMXnRZWRkzecOGDfv27WtHP0DQyfHx8QMHDhw0aND8+fNVf7YFmlHLxbzcy/QQBfMZgIAH38qZkq6F
        OeT82dpDW3fnLVufu3jt7qxNpXuPaAEOKS8+jijauXjtriXr9q/PNZ6wstRyr/fHs+pkBaXkZ27cmbEu
        b/mGfTm5x/YctveqtzMnK44VHiJ+4aotu5bmUASpdq/YeHhbQenew8abjurEUsv9u9e+nB34H83bX7By
        MznjaEhx/oGz5ruSLDh1tGzP6q1aMzfsrDltI863Fy6cOFR8aEs+Ncwz15ZuPLg5/8ShEptVxcTPX7GR
        PImvTpzWVp/FKKdPaCwtpZeKdu5t8DnAqlOnS3Yf2Lt2O2NqauDSHBp4ZHshKsLm0y8unL9w/OBRKkx9
        KJ0kJDT1Sd7+MydsLBu2hsqyU7SRJhBTXel34dz5EweL9SbsXrHp8PZCm8kdQ+YVx04c2bGnMHurqfJL
        1uUv38AEY9wtBoiJaqqDucJUvrbqsodb0CimEDPNFGFpDmNn3faayio6StWZTIhZmL2lOG9f5fFyJ3de
        x1oOshJm9OrwmFJfLIdxnwYo9bVheobHXZdO2Y3/LIAuVUkcwMRwu+Pf5KNyC/3bhwc2aveiGMmZssjj
        rpfVBZZ9b3huhkeMFmCm5vQZeoZWX5oeq7cV5e45XXaqZV65sWnTJn9/f4w2Zb3x+fnnnzfgx8s6tdya
        NWvc3d31U3N8Whdx7ty5kpKSvXv35ubmbjfDIT8/P5/D/KlTNkaEiYG/iswnR0c8OXIfPXp0z549Kgf8
        OVieNrw+yyYckw4ePEhZKhXs3r37yJEjNk1DPKkkR2Ki7d9vejZVVVUVX6gtPhRdr1dyUTQN1IsmE4rG
        x7po2tswLYfhvmvXLiqcl5ennjOJ+asKVXXG//Dhw8ZeolYY0Dt37iSUPsR6JhPrlUhvk4+54qaaM3wW
        5+X04SATVRZ50lcMlvE3Xb6TVuVDBGUi0APUirrhSVo1E4w/NBCNgaBpKiHfaZ1jw4iRYlix/1QSoGL8
        iZKp708YjDIJt23bRg5UTHmePHmS1qkq4U/Di4uL7f16TYcTyvykjapz9FQ03MEJCkphuqqeAdIyQCSh
        x4xjROfrLSVb4yJqvJajeiQhf3JWRQB/0r31PbWiYNwjzBgfn0OL1q1bl5CQwDaFBRkWFsbeMmTIkMzM
        TDVJAPty/PjxGJdEkMeoGmFKMyJMMCePoS0Gc4aBHjx4sPa3+QCBtAsPD581a5bm5RwrV64cMWIEUo1j
        mXUz2Q1YTYGBgUxj+qEtnEdSq3LmzJlMafXLRTt6GBhLOysri/4MCgqKjo5mF9ICWpvm1XLdzFoOO6/3
        NX+f2nuw6Ul3da0obOJBT33Zo8OjfcxGZ9I3oVqAQ5bFprjc/GLfDp17d3jM99ev7VySowVYabnYV/oe
        P1i8KGJi0J/fx7gkftcOj2CSjn7PC+vZ4t48rMmSgoNLoifHv+0e8pf3evz0cSrWq8OjpKJuoQ9/OOZD
        n61zVzovEoxajioN+1fPTTOXbUpdOuxfvbr+2PScCVyfG55D462ZOP/0MctfWZYMnYyxrpoZ8Pu30AZa
        gBnkAXqgIGtTcrfw4D+/R3vV4yu++UEn/wffxnPbvJWYzlrsi2RETyZmnw6dqVXmiGmnS09umL5k/GeB
        7r/8F40190+nqOe6ZMal6rdXOQnbCkmWx6bQ5663/EM9+caU5/XPhj/6SUqfCKSLFvUixEfDJ3UJC/jj
        O3QRpZuf2/FI3xufH/pSj4yoSVj5For02wvfrkteyEyjCWSe5m96qkRu+uqkr0MYVtWEbzp0GvDoJ/Se
        UnpOgiBEdKX5xQ164vMev3jS3JmmB/Z43fPfUe96bpqxVItnZlq/qG4/foxJS3Hhj316dOdeLcAM0iip
        y4Du5spQJe9fvWqcaSb5fbB4TeK8mP/2UXVWc6zX1U8Nfal7xpBJJ517CWGdWo5JpWs54kz4Ikh1yMbU
        JZ53v4yPCvL77esFKzfX+Rb74vz9zH9Empbhjx/bviBbCzOQ6j5M1Uqdn9+ZsVb5Mz3OnKzIHpcW/6ab
        +x3/IpRWmzr5mqcjn/l6XvBom2f5mgOMUXV2TqFEQn3lXJ1abu3atZ6enrqWA2P+HHExwtavXz9s2LA+
        ffqQg3puCl/Qlhw5UlJSSktLOQpqCcxgqSxZskRF5nP27NnEwfLDSujevTutUI9dcXV1nTNnDlJBS3Y5
        aA8O9tOnT/f29qYsVS5pv/rqq9DQ0Pnz52OwWpgIGA3kiaYiJqmoPN3l4eFB6/Dp27cvhqYW1SEcwrH7
        U1NTOairQvmkzhTNiMydOxez2Fg03xum5RITE6nbF198QZJp06ZxPF6xYgWF0l76DU96iRIXLFhAEOYO
        xjG1omnvvfceocTp2rVrXFwc4sFCqlFDxlrVnGiY1IgEFURtsbZXr14dGRmp5oZqIzV3c3MbPXo03a6b
        VsRMSkpS+VAo9hlyJT09naFXNeST2vr5+dHViCiqgWU2ZcqUfv36kSGpoHfv3lSAbC3GS1FbW4uqzMjI
        wF785ptvVBKgZ7p164YMYC6pnLUEdYH1SbvoVXIgOfOZ0aQVzA0muaoz8xCLBxVtYa5RQyY8Q0znEJ9B
        J7KqD19YAvTq4sWL9c7UoRVI1smTJ/v4+FCu3vNdunQhCZ1jFOSMDi0lcxVn4cKF+hXUjdFydBEdRXfR
        arpOTXsFxdG9dDJdXd+rtVk1ZDhhwmXvYSIrliGWOvqN2Z6cnExVUXTAxqXiUBmaxtZBt/BdeQrAWoiK
        isLsbgsaxoi1lsOHoY+NjTVeeOkMLC7yGTNmjE3JynxgW2MO5+TktHonUD0OYRwKmasMCpsbExtR1460
        HE3gSDpo0CCOF6xBOl8LaG2aUcuN+9iv58+fQCcow7HnL55MeNv98PbCs4aXDluzd+12/9+9iWFHKoz4
        sIc/0gIcMqXXIOQipeBcb3sJq10LuEzLPYyWC3zoPZ8H3lCPf1A2KE792ee6Z7bNzTpXc2n/PbytYOg/
        e1AZQmkI0fQk5lQmz+4/6Zw+cIIz5zHAqOXIc8AjHwf96V1VujFz7OMeP39ytm/cucu15aQuodj3FEoE
        9zv+jYLVAswgg1P6DcEUJlR1u+7InFb0vvbpVNehWuyLzPGPRzYQnw6c4REzqduAHj9/QnWXIbmpxNm+
        I+sh5779tnTP4eh/dOvyf6ZBuTw3U4ZY7fNDx5429FvFsRPjPw8kgkV8PQlN8LznPyg3o5xDb88PHcPY
        kYo8mXWZI6d3/5npRk1jJqY/f/jI6Pd9qu2/Xc0CZHbgn95BWVG0Rf0paNATXzA9tKjffTf+s4BeVz2p
        Khn68EcHNl32BuqSgkMjX+tPKnNy05wxnrgjn7Ef+eJv3VHkxny2GGh71KnlEE5GLZf4ZbDSctvnr2KN
        4KMHed//StaomYwIis7m6VM4e6Y6e2waU0WloqPmBY2qPG75u0bYI58QSltYzrGv9NPfbldRciL+LTe6
        wjxdtSarmHQFynx9ymIV0zHq8ICqaTBkosSJbtthyWE3c4zBnlal1El9z8sR03jeCZsDWw1/hbkWl1Ce
        WKsoEONP/hidSA6VJ59YchyzqTnfjZnwneIQD8XFxVpKAxiLaADqbEwCpiLNT3LnoLV7924ttpnly5cj
        IcgTaHV2djbmskqOD8Y9XadFtQ+GBdaw6jSVVsdc8gfkSTcaz1Ew1g3TcnQLuVEQKhHxgM2kl6JKBEKx
        xYcPH46J7OLiYhGq/uzVq1dhYaHxxBcDh6eKSQ7Y8VhUKghBOG7cODUWKoIOMWHUqFH6aGL6oxXVKBCE
        9sbWIS3fjWnVd4wzQBDq3a7gOz6Y/vrTGo0wA1GnKkNjKuBP5Y9AovnWFqFNmDn0Jwmpp6+v79KlS1Fu
        1hUGFA4LxGhKMnUZCILs1UeBaWvUUeSwfv16lJ4K1WKb4U+y6tGjBwOkxf7uuwMHDtAhKgJ9yxLQf9Fo
        sJajc+giOkqVaF0N5e/l5UWHa2mcIC8vjzow4VFlmhfm0N69eGI4jh071ngGQP3os2OH6aIewQF0IL2H
        zd32tVzDcHKpthj0c21trb1anT59OiUlBQnHPsnuFx8fz/f2peUAeyMpKQkVyk6bmJjYRk7NNZeWw/7b
        mbGu/y3/wFC7ZKX9wPQk/aEvdV+dOM/e26j2rtvh//u3lIGIIYva0QIcMrX3YISKsmLdbv+n8akPupbT
        62ByZoMVh9Wo/E3uB5087v7PQcMrEApXbfZ94DVzNJN9qZJcTHgxFfX8SecdC1draRxi1HKqRGNlcHqe
        fPe4899LopO1lGYmdQ1TkpVeJRTRogWYSfo6VG+Ozdriia2fFhBvNPHT/OMv2fc/fARnTG5sJoVunL7E
        gQ43gske8vBHXc0vFTRnaMzTlC2Du2DAON30P3GwOP5NN71nrOPr/v1ueoH5o1KBScuFjVVazhTh/x65
        +CZDPbmWlu9MyDkBCVpKh5QUHBzxqgvyQ6XVczNn2AndEvH014e3XzIa0HI9L2q5sE4fG2cRkFvcG26k
        Uln1+NnjRi23IGwcnhcLujQZ8GTemgY6LlWL6hDHWq7qZMUsr1ilJ3HEme0zQr1I4FRR6XTXoXqQqRrm
        gfC67xVW1j77r6Q/tHV3n+ufVUmoKtJ979pLr+lnE9i/fmf/m19UbWHtz/QeoQctiphIJQkyFXd5q/mT
        OclkU5EdgyJSZ3UwKDG8Goa1TabgeGN9ZsAmdWo5TLRu3brp5iNWr67lCgoKMDiovwoCZQ6CsVZ8p5lr
        167Vf+/H6JwxY4aepx7fOjlfAIWgEuqkp6cjRYipR9MTgu7p5+enW7qAlkPtqAoTGYNexQQiO6PlOOQb
        3wyhEloXTREDBgzIz9dWUyO1nMqWT0rRvyhPHUokf77gbx2B79HR0cb7UtByKGEVh8gDBw7EfMSfqm7a
        tMkiLRFUhkBBGOjWWk5FVtH0VOq7Dj2sJIopI1uVRLRYXJSLrujfvz8x9TigV0Z5KlDpNMEZGxEp/vXX
        X6tMqJLKXGVoXSUEmPEXgbKyMnd3d3NcDZKoVKAlMydMS0vTxTM2H8Yf0fRQMCZkLBgRFRnQcnqX8oUO
        15dzw7QcNUFB0RYVCqpcvQ6ar3kEaSBzQCWsk+zsbCQHs8t4u9SKFSvYGVD1Fie6qQa0NTu+DbJv3z6l
        5dpaX6nraRuv5doUHJg4xIwYMSIvL4/5qfka2LVrV1RUFJOcL+wAEyZMaI9ajmYuXryYQzywOdj84azl
        aS4tB5hr092G9rr6KV1HKaPNdCnadc/4/f6tFfEz1B1fxmXWJFpuiQMtZ8520jehecvWl+QfWDIk2ffX
        rxlD5wbE6xc3YsWGd/6sb8cXRn/gTW0LV20p3XvkdOnJgpWbR7/rqUxzHBnODxlTYXVJpDUWWs7UG6Y2
        frJ51vJTR45tm7/K97dvmgSeOZSg6Be74a8ldqjlVo1N8/3N6/jj6F5Mc7Q0xWGsb52TFfnMN6RSJfa/
        5cWjefv1ky1GLafq43n3y8uGp9BSBMmUnoN1mUHQxK9D8VcJHVBdUZmVMKPrD7X+QSQwjhumZaDcjhUe
        ypmcntx9IJ2wODJJPXGUSs4LHq0i4+jYyGe/IVrZviPEX5+yeMRrLno16O2Et9yLcrUDtqWWM6sC11tf
        IsMTh0qO7NgzufvAfh2fV6F8Bv7hbZXQMUuHTdHv8kKuzAsaRWeiedAqy2Kmhj78UeSzXYyCrcFabt+6
        HcP+1ZNU1Lz7TzoTjdlFzYvzD6yfumjUu54DHv14cdQkFdkxjrVcfubGwU9+oU91ZCpiSX+E7L6cHYP/
        /qWuXc31NP/48rPHWVCxr/a3aJHi+MFihuZi/E69rnl63eRLt8WSeVpAfM+rTGeSaeCARz/RH4B04dx5
        /9+9qZXSodPwl3vvWppTXlzGiG9KXTrx6xCC1k916rxcZWWll5cXxpMyqhqMssCM4IlBNnnypZP8DnCs
        5ZBtHMCU+CFbJVHUKUHqn5CQYDQEMQEx3YqLizEuMzIyUKrKH4gTHh5+5Ii2Bi20HFA0KnH69OmkPXz4
        cGxsLElUzgRFRkbiqdIC5qyHh4cqmlAfHx+k3cmTJ48fP75169bhw4ersy6AvElKStKSXa7lFOTAnwgM
        JaqpgBbVDmfPnnVzczNXzQRtRBgcPHiQAzxWzpgxY6iPCiK35GTtJy2OFE2i5fjE6J87d25paSkix9PT
        U3nqqAFat24dRvy0adOMmpN89P4He1pOaTN88CeUIrDL6fCSkpKcnByUQ9++fePj43U9b6HlgO9IaLqa
        OvDp6upqrKR5WD4MDQ0lN6YKXYSnikDlfX19N2/erHIGClUDreLQBGYRMoPexthlsNSZLhXKCI4aNcqZ
        6wOVltMTKrBQGUGmX2pq6jfffIMPoUCtmKv6dcXoYfUTTHBwMGO6YcMGepX5TO/p/QZ0AgtH/fJNldav
        X6+6iGwZfeYJ/U8qiiMTsmINGhWjtZZr5Hk5LFR2GxXKJ42i0NWrV6Oc6czZs2f36tVLJSSU/LFcVcI6
        Yelh2Y8bN854jSjjzoyiCHXdnT1BQhI2CsqaMmWK42ssqf/27dvZ02gvI0USxppxVOOi8mdtsvyHDBlC
        kMXdd0SbNGkS2wjbndq+qqqqVq5cSVa0nWqwcTGricCoMQH0c9RUb/To0RQ3bNgwRsriPL9Obm4uK5qd
        h64gT8piDao1oteNIshn3rx5DC6LlzGlqpCYmEit9C7ie0xMDPMctRAQEECVgD9Z9bqeZxKiPehzklMi
        8alzQcGly20cwHSlUePHj6dFpOWTtPStcYzU9/Pnzy9cuJBNnmoDnc8kVxdGgooJ+fn5aBvyQdVrXmaY
        9sh7UhFEn1BVuigrK0tfoRRKPgwWe4jyUeXSTEaWPElF22kdezhzVc0QYz2JQw60iDmclpbGUYP4fLIz
        kIkxpmMYHXWqbdGiRSwizdcADWfbUQuNlUjvOa/lLGYaS6MBM40NX00wqkHTZs2axeomMvqTDFXP4M9O
        pTqBvWjBggVqSer9wICi38LCwoKCglib7MDKv3VpRi0HZ06ULxqc6HWP6cVWmMUXbT6TSY0Bh4U97hP/
        /RtMl9Do3dSsWo5CERgpfSJL9x5WJZ6vPYfqcLnpeYJUhMFPfVF08Wanmsqqo7v2YcVis5oe/mGY07tX
        bAx7+CPaQioyH/uxHwJJC7OPhZYj4fD/9NmdtUk95uHc2do9q7fRXbSCUJrv98DrG1MvnZ1woOVMd9wp
        4XHdM5N7DNR8L7JrybqIv39lFgymC/zWJs3XjfjLtNwPOg168ou85ev1s6Z7Vm8d9OTneueE/OV9Zx68
        WbrncMAf37nYOXTpl1vSMvXeQ8Ygivavzy0/WqYavnvlZv8H31YzhFKinu+at3yDCgK6hREZ2PlTdcKN
        TvO+75WVo7Uf/i20HJ+My5bZmVUntUPaiYPFg574XJ10ogjXW/9hugDSMJQ2QRep926TxOveV4z3blWd
        Os1YMzGM55YbrOV2LMwO7/yZGppeV/995ehL9z0ThwlDQcaXeTjgMi3X8fllw7WHoTHJdy5ZF/u/fl3+
        T5NqDM3Azp8ZZfmF8+eRcwlve5BWVeZiTFNu3X78GIsoY2gy0bQEZmpOn8kaNZMIKjJScJZXrN405ljU
        C93Uw2x7mS5/9dcbQpV6XfUUCU2T5P8emR82TvlDbfXZk0eOMT2sL9e0Cftsv379ME/VnTMNgIS6oa/D
        n5hxbNaYF1pJDjFqOXJDCWgB5ueMcUhQukhZeOS8du1addkP5ghmOj4kJAghh+WqH9vOnTtHzli9Kmcg
        h7w87QpeCy3HF4x4TAFlZgHHS91Mx+7EoKdcFcQGiB2DSlH1oaX66S8dDD5SkRw46Gq+VlqOUL5jVGFV
        kD/Wkn4nj01oHbaIqjYJyZmEWpgZLE5MFjUo1A0LTD+aNlLLKVCtDKuuKzAmunbtquqjImD37N+/Xw0Q
        fai0pbmtpgpjX6ogsKflSM53lSef5KCfKMMIw0RmlDEa9F+vLbQcGQ4aNIhKqrvXsGMwy4yhKk+UuaoJ
        Vim6Tg0WQSiKxYu1n0LoNEwWNdCEEgcrB8Wu3xfHcMyfP595omoLaDC9gQ4wajkFxiIWtgplcqJP9FDK
        xQ7TT5qRP7YjFhhClJjGm/RolD7hSYWgVaeqmNU0Ck/VRsQw0k4lATKhD+l2ffJD02o5TFWsSdXJBPEF
        o5Ag3apGdRCBniSICOTPd3u6xQjzgZmMdYidqnmZoeGYqmwmWNsOLuViamHrq7MEDi4Lp10YpswrtA02
        KMXpXyhX7xk6kOWJP/a9xZPuyYEimGlLly5VEgvrn+WDD12B9FIGLiuayvCJvsJoRhKYTmGEhCjbl08G
        Qt/EFFQbAUCJRMCwRplQEN9Z+0xOSlHRWAjkhj85MP/pGUqhdFUcxveGDRsYJmJiYTPfiIk/UDHgC7Wl
        Uaw7FhcF0QMqVFWMPyna8ZMkWVCkRZsRGVRaBbsWIk3NB2VhMjSqW6gq9SQ+paAT0Bt4GrUck5n6Uxlk
        quZl3v/pFrLFX5XFJ43lCwcOdVafL6gRdpsdO3aoQoEaolFVnwPx+VQtNapZBVOC5NOmTWO8iEZDSEjP
        80k91WUIes4OoOfZxEjOkFlcF6AgAruNyoqFXy8txxppwEyjE4wzjcXIgiKIyQNqOFR7yRAduGrVKpqs
        slVdDYyIPgMVVJj+ISHDypoy7jmtRfNqOThdehJrdXr/aP/fvYmpZzYTTWYfju9df/To6Pe9jfcdNZ+W
        o1yKC3/sE+xjLcwMtnLcG65KSOD6XPu08ToxeyDtEr8MVlYsaTGCnXkVnlHLkTboT+9aX0g2/tMAzFxl
        Q/e/5UXji5Xtabl963ag+lTfohOC//zexK9Cxn3sN/ZDX9yEz4Ni/9vX4y7Twy2I0P2nj5On/hhGXcuZ
        +ueHjywZOhk7WwUBw0cE1TlUyefXr6I8tTD77EhfrXoGh4hKHzhBt+9tsmbi/K4XzwjRijWJ84x1UCyL
        mUrbqYOpnh06Tek5SPkbtRyhPX/x5NQ+ESpIB5/uP+2sutT11pd2ZqzTT0vaY0HYWDpf5YnEmvhlyM6M
        tda10mmwlkO1Rjz9lQpifiI7syfMUa8KqC+6lqOLuv2kc8hfPxjzgc/Yj/xGvtaf7z0vaic+XW75B3LX
        +rmmRbl7shJmEp9JcvnvL6Yp537nv1eNnW3xRNPi/AO9rnlaRWOBG1+zcazwUN8bnzcnN4UuCB+v/IGe
        7H/rS2SrggY99eXKMbOtn4XjzPGDox2HXrbghoHtxTEARaTf9QTqC0dQjo7OGLVg1HJ8qkdlcMzGXMAY
        RXXo9h+h8fHx6jAMU6ZM+eKLLwgFDG4OqMpfh05YsWIFUsRUs/ffR3yiwVRyTCujliMOxxWLTsNIJQmZ
        U3T//v3JSvkTjaMUaQnis0ePHrSXilFnIBXWknqdOjkTAcWiEoKFlqOxGNnKFiRbdJfeOptgHCAnVFry
        p2isLmwj+koVjTml7scjAsY00ktpADJvpJYjT+QHfa4FmNm5cydHdGX0E4dW64JEwSShD831NY1gWlqa
        fvbDnpbDxKc/VRcRivBgZPUfj62x0HJkZXFXPRl26dJFhZItBRlFAj2DYaQGmrRfffUVE0MPIjI5E0RC
        Bo7mqCAdTBP6XLVRtYXSjfrKJkYtxyfGkPGsL/AnnWmqsblFCBuj+nIAFpheYW9vbxYXnhiCq1evJh/8
        4fPPP2dY165da3HuyEjTajkGnV5SQaTt3r07mkcl0UEkxMTEmCuoDQSrlSHQgu2wa9cuJj8WJOtX8zLD
        uDBtsF+Zn1Se/cp4TlgHIYR1i+mJnaosb5sUFhayvpiWKDEmT0FBATOfaUNCrFL97jul5fBEIVhrOVYu
        9SQH9VMI3ZiamspOooQK1WA3ZglgdvMn1aZKfKHyzCgWC0ICIxgfC9WKIMEfyxj9z7RROh8Zw6Qi53Xr
        1qloCEg6hGyJSSZUnhlFcbpyYwdTPUCfIOxpCDGpHgY9zc/Pz2ehMbEZSrqaHMht27ZtdAWZKKFLJsZr
        EKxhiBlT1hTbCNKRtORMBfBRaXUZw064bNkyakuPzZo1i94mJlslfYInkY1ajhwYGqpEF2le5idmIfCo
        Pxsd9SQ5Im3hwoU0llWs5DRdx5+wefNmNdNoI/HpNzzpT2oI9EBycjL5UxlWrlK8ClVz/IlP6aoURhAf
        iqYPVUF1orQcTbOn5YzUV8s5P9PoKHszjSFgeapOYKwRaUwJVh/rQg0HswXIkx7Dn42ImORj8dMDsGOQ
        D5E5JOm7SivS7FpOcfrYiY2pS6f2joh85msMVmVTImmQWOgWlJ4Wr3m1nMmGjn/LTQu4SHnx8TS/OMqi
        RFXo7hWXyRXsfmzcTTOXrRqbtmDAOCLPCx6d0jcq9G8fKkvUpOXe8dSv+nOAUctRUOwrfa0f4744ahJN
        UG2hUcaTbPa03Koxs/lTt7xNvWp+JKByfMfIJhXlUuHuP+k83XWobpEbtVy3Hz26GRPf8PSXqvJKSlHD
        QQf6PvBawcpLl+7YpOb0mRXxM/Sr9Ui1Je3Si6etqTldNS9oFJVU8bv++LGiXO28qA471MnDx1zMd14R
        hwojUVSQUcvh+nV8bn7oGBWksyBsjMtNpsssca63/iNv2fo6tRzNHPTkF/SeaaKaHX8mdwunqwuzt1i/
        MaLBWq7qZEVKvyh6WDWN5H6/fQMNtnDghG3zVxmvsK0TXcupniRPfQ4wHEwPMufT51f/WzY8xeKRrUYO
        bMpbHJlEHbzufUV1mp5h4B/e3r/+svNUTKTI57p0/aH5txKm5d3/WTnWtHXSwLWTFpDKNK86dAr+8/s0
        RyUBRm1yz8GkIogIRHO/498Jb7lnRE3aNm+lfha0ThuoScAoYTvWrwdTdhjSwoHlbY1Ry6kcsIwVmIP4
        A1/4E6PNaJBxaFGp+OzWrZvFGS3VA5iSJCQOkAmHQPXDqoWWIw62hZ4KEKI0TWlFzGJXV1fdjicOIgp/
        Eprra7r0zlRdA+SsIpAWIaESgq7lCCWOr69vnXa/EY58np6e5iqbiiYH1TNG9EYRRGRlaFLnxms5Wm18
        vARg8XNcpyAi0CgO6lrARehVqqTqQw7Tp0/XLRV7Wg6DGHvXVMuLHYjs4cA/efJkhoCpZTG3LbQc8S1+
        CabTGD4VSrTExESjhmGgMdTUTwaAzklJ0R6YREF6DUlIHSzO8KiaYDe/9957KjkNwQSs88dmo5YjCVaU
        RRKsagwsc5VNA01ki+vHKJrKYKnTV9jHGNaYa5jIzCgyJBX9oGs5IiO31O8LQARC+ZMFhX2MIasLbJ2m
        1XI0B8NOBVE0ZqXNW2UwIvVnwDBXGXGLsbYGOx7hxNBYy2z2Cuab/8UXEmDC0l26ZlY5o1uUmU5VHWg5
        hNmmTZsw043DRP9jwmLEo5OVjwMtRw+zX9EJupYjAluQOknClNMrRs9jB+NPZLpd30uRNzSH4vDUu4Ws
        JkyYQInY1sadpLa2lvXOemRKqIFTWo48KY4v+m8uxGRRkwMgEpQn0DqWNta/9vdFqqqqlABTG6mCzJmE
        1I026ifMraHajDtz0mjBI2OoD6UbO42tAJFAbekKfTnTvXT18OHDqZVNLaeflyNPpCl9OHr0aGqrPIE+
        3L59OzVXfaVrOdSy6tIVK1YoeUafGH9WY5OhJkwzsjVmSGQmM5VhReuLiPxZFGTCVmPzFwSbkDNJ2D0c
        /5wH9dVyxplGV9c502gIM41GGWcaA8fgos3oauMzbxkRJeTIh/Wrn9xm/tCxTCH91wQdZiw1IT75q58a
        W5cW0nKKc9Vn8zM3TOoW7n3fK+oZGzjsy/DHPtVPRDS3lrN+V3jl8fIFA8ZTlqoPhm/+xWf9Y3Ee3bVv
        6dDJ2LUBv3+z300vkE9v83Pn0R4G7dRALWfzXeEr4lPVnVpUuNfVT03qcmkbsqfllg6bouQf2SrTGdvd
        pqN1RJsXMqbGznm5DdMyjFZ+1anTS2OmquGgPs5ouTMnKhZFTLyY5OH+t73kOAkiLaVPpKZzftDJ5zev
        WZw41aGNqs/p/PGfBihPSy1343NzAk2vJTAyNyihr/kxGzgntdy3F75Fkfr+2vTkGwrlk/Fi3Pvf/OKQ
        F7rSwGOF2h0UioZpOTUV967dPuzfvel80pqLM0nxb35g0j8pfSNzF6+1PoFmEystp08DU7l9rn/W74E3
        Rrzqol+e6hhGITMuNfof3VlZSm7hqGH2uDnnDfVhtiyLTVGnPVW0VLdh+J8uO5X4RZC6qpNZN6lLWNm+
        S8cDGn40b3/sK/3Mz7FUrTbVli8hf3l/4tehuYvX6JcBNysYxGPGjMHQxzjDRFM2Igdao0HgDNZaDitQ
        Qc74d+3aFYMMo0S3PxQcJyhdWYd9+vTBjtQCDGBK6uc3yBABoywDay2nX0KpwCLByMOypz7kb6Hl1GWf
        JCSUL6q2NqGGtE4lBKOW45PjmRbgHPRtv379VJ3rLBp94uXlpSwP6tx4LYedPX/+fC3ADId/rBnKUhEw
        p/Sr5hSYWbqWo72YCPow2dNyQBwmEuXir1DTjJmAJZGZmWnUHtZazmIG8id9rkKJhgFhNEPRclj5upZD
        vWPuqCA6TX8wCRVA+dg81Ux9kOtEIzmfWHV1GlgWWm7UqFG6baRAV2AOmqtsGuhvvvlG13KmHeDoUTTY
        sGHDaBdjSg7UH+hqmq9S8UXXcioVMoNpTGSC1Ce9oaKxuBgOY+uaVsthTSolqfwxCpW/BVjS+uqgXcxY
        qq2F2YFuwZRkVqjfYnRUQurMjKWG2I5sF5jdjDXWvH5qxUktZw+kAvOWIpQ8a4CWwzgmB+OEZOdhl6BR
        kZGRFpYujcU+Jh8mgPLZsmUL0bCkmYTsbPQqljqQeVpaGu1lHanLsHUtR2R9oQEdRSrqTE2MC4ei6TE2
        KCd/bKIUItNwSq9z1CwoKCigYoyRPsfIDR+qpCsBlSfbCy2lYvSP8gcLLUcHMnuVukCtOaiMtZZjyiEU
        Oa5Z/xzJwJEhVWKINS+zlqOgyZMnG9UpWbHR0Rymh815DlSSVZOXl6dO8+bm5rKcScJK3Lx5M5s2/nxa
        zCIFw9QALdfImaa0HJFTU1ONhTKv2BjxZ3YpIad3uPJfcPGuOR0EJC2ln4mgJmfr0qJaTnHh3PmshBlu
        t72kjE6sw8A/vKNf1tjyWg6jc0HYOMqiRGV36lquKHfvuI/9CcJha5KJ+VMz7slQJeHPJtRyGND11nIx
        U91/+S9zlR7u8fMnfH/z2qAnvxj0xOfWbmDnz6Ke67IzY51+uWDTa7mTFYujJulJ/H/3huNrVpHx0/oN
        0bVcyF/fP37Qthmt69UW0HJAP6xLXjj471/Rtxdv7jLlQFW7/F+ncR/7Ga8JbPB5OcWhLfmj3/fyvv9/
        fW94jjiqLPOseyToofe2zFquxXOIUct1/dGjXvf8d+DjpkEPf+zTka+7TncdmjM53clb73RMS+ATfzWa
        OOTW3KBRxldTsJpOHCpxNV8wSQSWT+x/+5YXHy/bX+R9/ytqqpN8WazlpYNwcNOuiV8G+//2DfUwTNVq
        5jbjG/TQu1vnZjn50NQGg2mSkJCgLGzsM2wvvnMgd3DVlj0stBw2HBs9B2b0m7JFZs2aZfwpVIfjBIWS
        ijpgAmq+l8OhVBc/GJGJiYlNouUwxPUKIzCoJxW2xs/Pj7ZMMLz2ykLLcTyz2FQdw1Fcl6bk0K1bNwxx
        rbDLofd8fX2xXNV5MEppR1oOMAtQOPQVspkIpkqbiyATBgU7VRnE0KxaTiklEtIKLDzlbwHGpTo1DUTO
        yMiwOMlmTWO0HBOY+PjTFtJaoPIE+sGo5RTqujVmrBpThUpI/2NTavGaWstRZ1aQCsI/Pj5eT2KE1cFq
        pebEdFLLqdMC7DwWWg5UWj6ZabNnz8YSpe1Y3sTHdmeBE1pfLYcIpI3sckRmZSUlJZEnOajTLw3TchjN
        Ko4ObSEHtj7t74uwR1Ecljf1Vz5MNnJAY2AWoxyYGAq+0yIiR0dHq2f56FqO4ixOFlFt6kwmuuEOdWo5
        OpaG0A/AF1Y6kZlFm2y9d9sCOp8kdD49SX8ytZRy0yvAvKUHLLpRZYv+oQkMovIECy3HimaxUBl2V+PV
        1NZYaDkS0o10L7uctUYiDiNIKcZrE2gv1WZ2qcOKgh6bY34zCjuGPS1H81Fi7NLUUxEcHEzm1Fn9yTGF
        5rNRaAkMNFjL0VLN6yKOZxpB+kxTWo7OSU+/9JA2BfON/l9l9ZAhKklzELoWi11pOXVwd3x/eMvQClpO
        Efq3DzFVMeD4RGvtX6+9RKjZtdzr/bWAi1QcOzE3aLSqDA6DOz/TdOSoLq80ajx0DoLB7Zf/8rz7Pzjs
        +55XPaWCGqzlYv7dy1rnmITZHf9WbaFRyd3DtQD7Wm55bAoVM/s/4vvr19YkXWapOKY5tFzGkGTVb+TZ
        t+MLaCctzBaoi2kumpbD9bnhOev7pgDxqet/BMOEz7QnMTSfllOY3qI2Lm3CZwHMK6S1OtFEPt1+/NiE
        zy89DcKxlivdc9ixllPkLd8ww31YyF8+cOn4PGJMdYgaU3qpzkOLxbNPjG9ZbAyF2VvpcFUZWmfxVkBF
        zH/6dPtJZ4ab2iI+109dVJC1CeGn2ut57yu77M8BlkCq+7DQTh/3vf5ZelUVRF8NfurL3Ssus+GaEDqT
        Yz/HPMxBZdJheGHpcuA3ni1xHqOWI08OY1pAXehajrQ9evTAErUe6IMHD5I5cQAjsqnOy+lajoQcmZS/
        MzShlqM5FF3nzRUKSmlfWk6xY8cOTAEkMWKGUlQ+Kklubq46j9SsWk4/AUtCLCFjQh2sc71jicyf9T0v
        h+Wt7Hsd7GMMHXOVTe1FfakL+Vh66DHlDwTRvYQyyXv16sUqUDOWIPrBWssB/U8Nsb1QTarVKivyoRq6
        YGtyLacevoo/FWOvsPnrDN2iHiRDzMZrOQvYClJTU9XpmoSEBCVcnddyGOjYxOiiWbNmJSYmouKAcjFJ
        p0yZonqgYVrOKEsUrBpyIL7290VoI8VhT+t36DEZ6EwlhMyq5BJEI2eWuXoykwMtRxB1pluc13KnT58m
        wuLFi1meFMEKoksx0EmyZcsWB6NGEL3E6DPW9Btp6UZ1mShl6ROJeUv9GRT1pxEWPk1wrOX4QkexpTip
        5agzfyJXyIdyra/XBfY62kgpixYt0rwuajmmhHG46bG0tDTHWo6JRyrSMtBAHYivOoGmUQd8YmNj9Zu0
        jTRYyzVmpulazuIye6Bb6Dfj6UoFc4NGMcoWG7JRy+lasRVpLi3HXMf4xs7W/rYi5C/vK3Ofz6A/vbt/
        g3bdebNque4/7Rz7v74WtTq6a9+4j/1Uibje1z9LHfDHxERrqUri/B98G1Vz5oRm5508XDLxqxCKI6hh
        Wo4Sh77UozDb8olJk7oO0NviettLC8LGagH2tdyaxLnISzwJQm8kd7v0DL06aXItV1NZtXL0LL3fenV4
        NHtsmhZm4MK582ogqk5WpPnFGdWCfl5Uh8jb5q/qc/3FB3uYnn2irefm1nI6JQWH5oWOYUTIRNUz5K8f
        6I+yNGo507vCL78Nsjj/wMjX+tep5RQ1FWfQQuGPfqLmCcX1v+Ufm2Yur7POl2k5W+8Ktwd9yGq1l/+u
        JeuMo7Mg7NJbARUsdkacSlIuret1zdMj33Bj3qqOYvIkfROKmtVi24FZt2V2pumhqeZWm/K59ulV4yzf
        h9YkoHCwA3TzHcuMT2x9DhINE3Jg1HJkyxavBdTFsGHDVCo+sfUxa6xtiLy8PKX3VOZoCVXPRmo5/XEv
        5MkB2Pr2bns0UsthRvv6+qo6U3kO5zsNLwR3AKW0Ry2nwF5Zv349tog+XqTCMFVD2axaTh9osqXnbf6K
        THySE4eYRGMiWXSCNRZaDmPIYvlQZ5SYqcbmCAihTeb3reGP2aQS8omKwzDS7UXK5U81HNTEppbTQV8x
        w6mGXgqaGYtNhTatljt+/DhzQ2+vh4eHao4F2PRqIIhDxTCI61wdzms5BYMVbH74nrp4z3ktR0GY1yQk
        Mpaogu8sYVZWi2k5Kk+QUcth/WMT17kPNKGWY+agUlTzyVB1hcKxlsOfTmaUSWWRUFWgSbScklJ0FH1b
        Ly1Hr9K3lGtTQaE/4+PjKaVJtJwFJCFzkixYsKDOIymbQAtoOYuZ1gAtx8qiRY613BV7Xg7T8NDWAtTF
        wvDxiAHN1wDKzfv+/2GRY7T16PBI2MMfnTyizaHm03LKQEQLoXyMcm7z7MyeV/8dhWCO8HD0i93U2wWo
        SczLvbWEP+iEcFIvQ1Ogyka/73XRWm2IllMlTus3xGgZnzhUoh44QRDND3jwra1zLi1Ie1ru8LaCwD+8
        TXxzZUj19rECZ19F3+RaDg5uyiO+ctQKqVxs7lId5Er0P3vO9hlBh/Dn+pTFqggcrUvqEoZUVjEVKI1R
        73p2/+njSsj5/OrVVRf1YYtpObWzj/3E31wN0ylQ/99eunx0/Kf+vcxajuox85fFag8eMPHtt6nuMb3N
        NaSBxHGs5RTZE+b4/e4NSiFV3+ufXTBgnHHS2qRhWg7tPcM9hjW4a6mN16RUlVcuHDhBnWHDIeqQbcan
        4ygQut73saLVPHm4743PB//lAzXDSZs9fq51EmsYFGZyl/8zPUaFhExLNhAtrEk5ePCgu7u7bskpqwtN
        pRvoDaDBWg7TRBcnJORYpQVchEMIRwuMWlVbvmAHqKBGajkOaZRIkDI6U1OdeiU9NFLLnTp1apT5+jpQ
        rZ5j9RJzm1BK+9Vyiu3bt+sP9gBGVl3Q23xaDrDzVPNV0GSrVyZiBSKBKFTl36NHDyxjLcw+Ri0HlI4l
        pN/EhWHHZNNbSuYYVXQ1QbSFEVEJ8actxsaSA12BPxH4dKzlACXDrFC58Yky1BVR02q506dPY9fqQXzR
        HzCjw95ChVVP8smeoJrsGKXlkBDY5ZqXQ2ggY4qdyszkTye1HKUwBFjPtGLz5s1sDnS1mjlkpZ+Xq6qq
        QgaQG5PZWstZPMeySSxschswYAAtsqmNjTRYy9FMzcsMcdg6iE810tPTGXEl9lgIbN2OtRxZYcdTB/ZP
        RAvVoCfxZFvDk4boE4nmEIeqMn8sckO14u9Ay5Hn+vXrqTl95fhXNuv75RBUql0WrQY6Pzo6mjayeDWv
        ptNybJusXKXl6rzUgiV/BWg5jn1sHfoRuRVpFi137mzt1jlZ3X/Suecvnhz6Uvcl0clGnbNl9vLQv33Y
        9UfqCYGmc2VJ31y6JaxZtZxyLh2fXxajXfaQtzQH8UYqFUS0RRFJZ06YjtN71mwb+s+eesJh/+qpn2wp
        WLVl/OeB+vVgDdNyOJrf66qnkr4OLS82Hc6PHywe+bqrkgqE0vyYf/c2Ckh7Wg6GvdybVOYuNd0rNfCx
        TxGB+uMW+VKQtWlRRGLCOx6JXwYZ3xLWHFqubN+RiKe/vtjGh3tf/fepvQYf3aUd0rbNXxX5XBdMdr3T
        CrO3Bj303qVR+NGj0/tH61daFuXuTekbRUepUGqb8Ja7fp6nmbTc8tiU+Lfc0vxGFq7aov8ecWhrwaAn
        vlDVQKIE/fEd/TxtWkC8y80vkL9pCP7vkcFPfanaW3O6akrviP63/EONKY4vRi2HGkz8KmTUe96MV9k+
        7RhAtqRCEWmN6vj8uuSFzXRejlk37hN/muN93ytJXUJZnvozTk8dOTbDfRjrhUaZa/6wx13/3rvO9t2P
        w/7dW5dhzHB9dfS76QX1DkkjF86dpyeTu4Uj409cvD2ysuzUsuFTv7n4S0e3n3TOjNeeq96EcHTH2NIN
        Muw5vrMdq6sWG0yDtRwGFhJLtzgxiDl26npg9+7dI0aMMNaW465uxDRSy3HwRhfpybHgser279d+diFC
        QUHBokWLsN44YhkPV43UcliQtFr1POXySddxXDeqIBq+ePFiDsOgP8ieUtqLlsNYTExMxJLAotJNserq
        asyjbt26qfh80uEqq+bTcmqg8VEDTWivXr2YNspuIyHdS5NVzoSSOTNQmbaOsdBy0KVLF/pWpWWI9SeF
        AC3CwlNKj8Yaz8u5ubmp21QgPz+fmUBD9FS6lmNQMI49PT0nTJhA5rr9p05xqNxoo4eHh266Na2Wo13r
        1q0jSIXyyVCyvvStA4Hk7+9PnVUclVYFOYZsmeeYhhkZGZqXeeC0b1awNNRllkq1OqnlmAaYznQCi8uY
        OWtKWauqB5gSzGpyYxPji4qjIAKWNJGXLVvWhFqO5mNes41Y38WkoyrcAC1HxWgIM4HNUPM1n55CsOHP
        xDNeGMxSJQdWsWMtR3uJs337dr7r2fKFChi13LZt25QP01X5qDyZMKmpqRadZqHliMn2S1qisd+qhWMT
        ay3HAiEV64hWqzg69DDDR0EUp3k1nZajkkrLsQlc8VqOTsaf2cIxyLqfW57m0nLb5q3EIEONYNIhrkL+
        8n7kM98MeaFb1LPfmJ4NaL7pCKO2Z4dH8TfeXNTcWo5CieN+x7+wtqOe6+L32zd6/vwJo6l6ZIe2eR3b
        c3jiVyGmJwqaQ9ESwQ+9F/nsN6iUgAffRlPpeTZMy/X42RPUhDaiQ2jmkOe7hnX6uMfPn1B54u/+y38t
        NTQEHGi5LXOy/B54Q68tVrXvb14f+PjntJFu5wt1RsaQPPof3YxneJpDy509U71m4nziX+zYTugB8xz4
        Gkc9u/zQ9ETN8Z8GqPOHpldOJ8z82nxSjvh8utz0QuhfP6C3ccF/fr/vDc+prEjFkFFJVRA0k5ab5R3b
        /+YXkEYBD74V3vkzRgcX9Kd3laSk/3v+4snJPbR33AFz2P93b1I9BpeqotZC/vIBSeh5Kk8Hmko3P7uV
        3jBquR3pq8kfAcN4hT38kamgF7oNePQT0yWLPzT93kHCgD++Y/FWN5s0TMudPHxs3Mf+fTqY7nZjerA8
        1bSJfrFbWKePkJFUWA0KrWYyVBl+XDCSMWQSy4poRDY3U/sc86HPiUOXbYJwvvYc647O9L7/f0x7ZgUl
        0mqPu15WyenJIS90debF9PWCrZyDh7LSAKtLXQSlm+YNpsFaDgsVWwqloVuWmN3u7u7kwHEIMxclpoL4
        /Oqrr3bu3KkbGY3RcnD69GlKUXanygGZhCmMJ3YVHUXpCDzyR/8oq1HRSC0HWJ+YKRZF02oOjeQGqmj8
        +/Xrp18sRCntRcupd4XjT3F0O0NJ0zD0VX8SmSCy0uVr82k5qKiooDIk1Iv+5ptv0Ej0M43t27evGkqF
        l5fX8ePHnRlQCy1H5kADmTlYOX0uvqQeyJ95pb+MntFPSkrSG8sXIpOEXqKvjL8vkFDXcihhSiRPWkd8
        9cQFUqEYSaLi0wPkrOuZptVyKki9ZUGPQA9QQ2rCqmGuqjwJJSE9qf8y4hjk6IgRI8hB2fE6ixYtwvhG
        eBgnJHkyVYhMEtWlTmo5pgQ9jA2qtxTYT2gRi5GVpfuzM6gW6VOdCYZ0waxXg9u05+XQANSKKTp8+HCL
        U3NMe8rSLzKsr5ZjAtAKitN/LFCQIZOfyMZtja6jLfjTFsdajgyRgvp8BpQPY0fFjFqOzYHm013MLn3i
        qR7DUxWkPMFCy4GSRtSfT+NPaUhBJAeykxXBn9ZaDqmvlgYyyfgbJX1LfehnlIlxnuDTVFqOlpLEGS3H
        pE1MTKTHLN40aK/b25qWI7IabtaIuraidWkWLcdgnDhUEvV8F5PJa3q3len1VmZn+m4SeObv3X786IhX
        XXIXXXZRwZ4127GJiYM9R5yQv36gBThkSs+BSuTgMNaXRCdrAQzzlPTAP7ytjGns45kew/lCrVTF8MdU
        xYcS+930Qvb4Ofrz32kFmoEkxFQ5E4dW8CeSiYS9rnlambk008l3hZftLwr52wdmc7+T173/jXu9P6Yz
        ZrSqDPmrypiKuOqpWd4jLB4yMfHrEHVTFiW6/fJfy4ZP1QLM+jlr1ExkEmnJR68t2eLwVNXGP+bfvS7T
        cn5xvS9qOSqWM3WxpZYbNoXGmiM4+65wqDh2YpZXLGqEOlBbY+8ph0jYNGOp3tunjhyb2iey59V/Jxrx
        KfFi/U1fzJ6PoDkRcqsT5+kvVADaMjd4tK7l+lz/TJrfSC3sInMDE3SRw4DuWlq3lkt1G9r9J49RT1UH
        1Y2qbvxJXzF2h7dedhU7pVANktBRRCAy3+lzutfznv8w1UMf/ogKmIb4R4/qWm7r3KzgP79HHKuCtIZ7
        /+rVlWMu2+zsgf6k+aqZaLmF4ePrbKYJ891uLje/cLEOqsmmavBF1YHKeN798pyAhIoSu4bCoa27keuk
        YqrojoRrEufpdxXqoOUINXevKs5U4sWyTPOfHmPlNu1rCbBFOHxiZik7DDMRO4yjjjoiNhIOG7qZjmzg
        EKIFOAFGyejRozH+lKID6safCr4revXqhWGnTnooMLM4kBBkMirNhqzFNVpoOUx8Xcth9Vocqzj8qz6h
        IIuiqYz6QrZdunQxPnZ52bJlCAAik5BPDnX2DsAOwNDhQEhyijAVbC5aFWosGqvdeC5x8uTJupZDX2EC
        qiDHYK2Sm6ot4srikk4EFSaCHsFay61bt46aqE6mkliTuqVCK9DwJCSItLRIyTP0IUaY3hBQ30mutxQh
        qheE/YcZRARVCl+MJilgSRifZYqBpVuHwKzAMKKSpn4xq1xMcy3MDNaMcaD1UQZVJb7w6efnh2GkpakL
        o5bjU2lv2qVaSm54qpy7du3KzNTPLTAtMaoYCFU00VR99LR8mhtqqeVYrcaaq8gqEwWRjRekYcrrWRHT
        +FgUtBwJVRBx0EW6Cc7gkpUexOQ5aHgjH9+x4/WeBPIx1kR9ol0zMzO1NHVBz2AsYmJajBqjzE5C3bBi
        yW358uXsABideLLo0Cdq79K1HPalAy2HMiQhUx3redu2bZs3b2YEkX/4MFepgD7lqM/48eMpAn+KpqC5
        c+cSkz/pDUxYEiotR39an2JSKAt72DDTK2qMKAubxhq3FGQGlSd/SkGBrFixgvYuWLBAXc2o71q6lqO4
        w5e/mJ4g/C20HN/Va6BRiRkZGfQApROTtPhQHD1MPekN5ifrkYbQasaXzrG3pzF7aRQF0UWkYmWRA71E
        bniCPpEqKioQomQI7FR8Z+ckldJOxKcgFRPUuVkGka7WvL77jp5XMePj45Ef9ANNIAdKQbCpe9KoA52J
        ltPrzARm2ycOSz4pKYlpozqTHsYTZcjqM2evYU/LMU+oeb20HHOVmtDVxrOdOhyw1q9fTyewpbOWkVW0
        d9SoUfQMI05jLTY9Iw5mGnk6OdOQxERzoOWsbzLUtZxeNzqZ7/Qk/tSH5ij/1qW5nn0Cu5blTO0dMfjv
        X2GWffMD06VxynTr+YsnPe/57+CnvsTmxv7TYl/kyI49aLk+phe4YVk+OvSl7lqAQ2Z6x/b4+ROkwh51
        u+2l1RMuLYYtszP9Hni9b4fOZBj00HuVx8tTXYcOfPzznlc9heGoVBkqbuhLPVYkzMTE1JKZIfKKuNTI
        Z79xMz8N32yaP9blBw8jw0a/7z0/dCx2OZ6IMf4szq/7NOuJg8UDH/+MJNQz8I9vb561bN2kBUNe6Nb/
        5hepDP44VMeARz+ZFzxavY/LuKGYBMZPO5MW53Hny2snLdACzFRXnEF8jvnAJ/ihd3tf+7SyiXHUuesP
        H3H75T8Dfv/WyNf6m05qGfKcHzaup/l1ecTs9pPO2xdk6/oKqssrl8dOU/lQKENj/bAWe5wuPTnHPz76
        xW5e9/wHbaMmAF887no59pV+dGx1hWm1qwbyefzA0SVDJw/7Z0+6t/vPHmdcVLn0DEoj4MG3Et7xXJ+y
        WAk5vVvQcstiU1BHVI8J4HLTC0uHWd4NsmDAOKS+mh6InN0rNtUpcjbOWBrzn97ud/6b6aoPDV/6Xv9s
        8EPvTek5yLofThw8SnuD/vQuSVRjGQIaG/VcF8TYlrTM4f/t28+s2/ve+LwumIty90zvH+37m9d6XfN3
        JW9wpmn540fxjHvDbdW4OdavJrcJYrLXVU+p6dH9p4+bTuoaBtoBp4pKVyTMoCyahsZGWak6ML35M+iP
        7yS87bE2ab7jc4OMyNgPfc1rytR2HOu3/y0v6tfWGmHUmKhMJ9fbXjLPDS0+4+h9//+G/rNn9vg59k4A
        NoDz589zpPTx8cHGRQBgeAFmKDa9urpDn04NhuMoBqvKH+3E0VcLcA7kHNaAv78/mSgjFcgKo5DcEE4c
        ZjiQ69awguMlB349MkakfjmigoZjHWKSUjGVj361j87OnTsxDjw9PekQZbwqlFXarVs3X19fFJTx912s
        B7IiQ1VDzLuGdSBmNyaOl5cXWpGi9aGhXL5j6xNE/fUf4CklLS2NUJqjcPIePywh1QlAi7BEtQAz+/fv
        55CsmkPmHP4ttBxaQlWJuvFJn+uWCmldXFz0mmNSKLvn+PHjDCjqi2z1XiUaGoAhxoJBhGM56f1G92Iz
        EVnFpFeNUg34EymuMqEVY8eONRpeDDQZMnNUKGCZaWEXwY5hoNEYX331FdVQBRGf6uHj5uaG1KnXA9mM
        Wo4KYyFhlpE/31XOav5g8WCrUUOS6O3lT+xLlom64lTFJyEZ0jnz5s0z1+6TL774gjmwxfxQB5IwYVgj
        zBaVOZ+gWs2ExDpnGRrXCLpLVQaIYzxlh5bTywU6X+9PLE4yV0H0JOapxVjs3r0b5YxupCZkQjRzCaYT
        v1SYYaInqUmdr0s2gvk7YMAADEqjOYsxykDTSwp6UlmQ6BMMdP0NEGg5bE3mMCuR78rTGjQGc5JS0DyY
        7+ZzOREMGW0nc6x//cwDXU0bCVLqiBIZFIxg7G8WLAUxoCgiYtKfWPyUS7Yqrc7atWsx1tm4tL8vgj9L
        jPjGJ53QV/izTimIulEuUCti0ie6PicaTaBKxLHWckgXFqAupQCtS7ZY/9SZrEhIctYmk4QjAi2iOGpC
        BGCg6W2mMTH1c1zWoOXYAKmq6hwaSDfymZKSQibIM2MFmGb0D6UTRLa0iErigwDgCz5aPPNYE4cIxnOz
        TEVWGRObIaCefJKKcmkmGlJtU1RG9T8KTa8z+xKiF0/iqyR8J3O0McLGYn+jM6m/6hnNy6zl2ENIO3Lk
        SONvGQ5g2tAK1OaBAweMvznq7Nu3j7bQadRHtUXViu+qcyzOyhppwExjiMmW+PpM27t3LzlQB4sT4MDo
        48+y0v6+CBOeMUXR6ZsATUOLUnlVZ1a68m9dmlHLAaqgYOXm5SOmJXcPH/GqC8YiRmGqeww+u7M2fXvB
        xlI5c7JibkBC3OuuxB/3if/qCU7dE78zY21y94EoBIqY4R5zZPulU2RHd+6d5R0b83Lv+LfcF1x8lMLO
        xWtneA4f/Z43pYz92B//feZnVxrRV8XetdszoiYlfhFEDsSf3D18RXxqye4D2KOJX4XEv+lGuajHSqsH
        tVtjel5/1KQEcz5zA0epJ3wc3lawcMA4TFuyouFzg0btWpKjbH2L3WRH+upJXQeM+J+pJ2d5xRblXrq9
        RKei5PjG6Rno5PGf+JMhzlTnnoMyhkxal7zwyPZL795R7FySM+7TADQerZvSO+LYnsNGnXOu5izKZ8z7
        3uRDHOpmfOOzM+zL2bF8RMrEr0OoBqOf9HXospgp6ukyYL1dHtiwc0Xc9OmuQ8d86Etv4OiZBQPGrp00
        v/ziSaHLUn377YGNuxgd8mcgZrgPs747i/6c3GPQiP+ZpkdKn8hS2mhVrgXnamoLV29FJc7wiEGikJAe
        4MucgPhNM5epOyqNqAxPl53aOmfFTI+Yka+6EJ8hWBYzVdWn4tgJhoB5OOodz1k+Iy/9avDtt9RnXfKC
        WT4jJnUNV0NGV6f0jWTyF+c7dYmOgumR3C2cZtIViV8GF66y+8uiET3OySPH0PnzQkajmWkvdSCT+aFj
        N6Qstngxuj22zVuZ9E0opdMEcqCliwYnGk/z6lBo2b4iJmT64ETmBmURn3Kn9onMSphx+OL6dab+zsCR
        e+HChRykOQSylbPpDx06lAODhX3ZGNAbEydOJH8ypwiMHi3AaagGhxx0AvlgZJAPYDEgZjjwW1xQpOCg
        gpVDZNUoDBGLH1DJk6MjB2MqhqFAVjYv+sIM4hg8c+ZMjvTko3KLi4vD9uWYh9FgYZViRWG4YMGQLYdA
        9GGD+xBjC6XEWFB5NTpAnhw7FyxYwGFSGawqfz5pLyYmQwkcZY3moAOU7aVGB1265/K7gDB/mR4EEQGb
        gHLVxNChvVRP9Qy9jc2kWypYGFg8etCKFSvUj+VUFXnGIR8jm7RqQMkfwxQ5um3bNixClYOCKUolVQ2Z
        nBjWuqWuIFuMLUpRZWVnZxt/+SY3hklVkp5Bs/GnFmaAJKh9i4HGlEcSY/eoq+mcx0LLkQ/mDnYV40IT
        aAuSg/mjnwSwniT5+fmsFAZUxWfK0ZlKTzL3qB6TltoaJz+hrBGmB5OEygNDxpiyRow/NygYWfpENZPK
        sBb0O3PoXjqBcgnChsvJydGDmJNINRVE5nS1Ov1lhPFCYTKUZELNKQJoCAY0g27zpIRjWIAsKNrLNNC8
        zMOK9ZmVlcUCoY3MJeYAOgpZruaP6lKWJ8PK6tYlljUqJgNEbmTCBCM3Oo0NgS7Fh3ItphyLi3XBSidn
        WooGoIuIxp8sQ6WZ+cSfwbLe8VhllIJlr/19kcLCwunTpzOIFhetITAKCgoWL17MiKvGzpkzB8GPMFBl
        qTjsCXQyi9RCtRKHVjAxdH/VZDqKESFPMmSesy7UaLLGGUGqQVnUky8EUQH6hEnIlLOerjpUg8h0Dl1B
        icRn+BBC6enp1M2iYqxcWqFXgO+nTp2iq2kdCbVI5t8dyJAIxtOVwOCyXdC9DARV5ZMuZSbrexT9TM7U
        3+KULPWh4frM4QvTgwOExc4DVJtWsA8bJw/R6GpGimVuvbIaBtOPhjBbqA/Qe2p28Z1K0nb9mnNrmmSm
        0fNE4whr/aMVnUMmFmcsgfVC3djW9NVBTchWCTl2If0C4NalebVck+BgRSnqjGCkXpGveBrWG22/D401
        bKbatrVOaPX6tP1Z0cK0VofUWe4VNlLN3RwH+dsLaiM93NzVsNByKBl7QqLxNKAtrTsK9S0dxYIGDggI
        wOjXvJqaJu8QJzNsvoGwl3OdJTZflRT1zd8ifuOr1+QNbO4eaxIaXMl6JaysrIyNjQ0MDAwNDUWNG6+P
        aEXagZYTBEEQBKFNYa3lLE7sCM6DNbl8+fKwsLD4eMtndwmC0EY4f/783r17EXLqMtHCwrofk9EyiJYT
        BEEQBKF+iJZrWvLz82NiYpBzWVYP0xMEoS1QUVGRmpoaYH7VPjue8d7I1kW0nCAI7ZV2ceGHIFyRiJZr
        WjAT09PTQ0JCkpKSNC9BENoSZWVlMTExoWays7Mt7iRvRZpFy50qKvV74PWAB98O/IM4ceLENb3zvPs/
        KX2jtB1HEIQWJyMjQ70fAi3HZ1xcnGi5xvDtt9+eOXOmpKREf+OFIAhtivPnzx8/fpxFeuzYserq6rbz
        a3KzaLnSvUdcOzzh0uHxfuLEiRPXDM6zw1ORz36j7TiCILQ4a9eu7dmz5xdffPGZ+b12M2fOtH7eoyAI
        gtDcNIuWq644kzEkeemwKeLEiRPXHG7R4MTt81dpO44gCC1OWVnZunXr5s+fv3DhwiVLlhw5csTiXQ6C
        IAhCCyD3ywmC0F6R++UEoVWQpScIgtBGEC0nCIIgCIIgCILQ/hAtJwiCIAiCIAiC0P4QLScIgiAIgiAI
        gtD+EC0nCIIgCIIgCILQ/hAtJwiCIAiCIAiC0P4QLScIgiAIgiAIgtD+EC0nCIIgCIIgCILQ/hAtJwiC
        IAiCIAiC0P4QLScIgiAIgiAIgtD+EC0nCIIgCIIgCILQ/hAtJwiCIAiCIAiC0P4QLScIgiAIgiAIgtD+
        EC0nCIIgCIIgCILQ/hAtJwiCIAiCIAiC0P4QLScIgiAIgiAIgtD+EC0nCIIgCIIgCILQ/hAtJwiCIAiC
        IAiC0P4QLScIgiAIgiAIgtD+EC0nCIIgCIIgCILQ/hAtJwiCIAiCIAiC0P4QLScIgiAIgiAIgtD+EC0n
        CIIgCIIgfN/59ttvtW+C0H4QLVcHp8tOzQ8d4/Pr13x//Zr/b99YHjut8ni5FiY0BYe27k76OtTzrpd9
        f/N6+KOf7Fy89lzNWS1MaH4ac+hqQFo5UgqCM1y4cCE9PX3w4MGenp59+vRxdXUdMmTIli1bzp8/r8Vo
        n9S5A9DAzMxMX1/ffv369ejRg1bn5uZqYa0KFSsoKAgJCXFxcenduzdfNm3apIU1DzQ8Ojq6b9++vXr1
        CgoKWrVqlRbQfqiurl6+fLm3tzej2bNnz/j4+L1792phrQ1TsaKigiqxuOjkrl27zpo1SwsThHZF82q5
        FfGpca+7Dnm+a/Q/uttzg//+VXK38JOHj2lp2hilew8PfuqL7h0ewfXs8GjCO56HtxVoYUJTsDF1idd9
        r9C39HCXDg+n+cWdOVmhhQktwu6sTWM/9HG8To0u4umvk7sPLFy9VUtvh3Pnzi1cuBBjNDQ0dMCAAZMn
        T963b58WJgiCHTAxT5w4MWLEiO7du3/00Ucffvghn/DJJ59ERERUVVVp8do8iJ8DBw4kJCSw/IODg0eP
        Hl3nDkDbsfUx+j/44IOPzYwbN+7kyZNacKtSU1ODyDQOx+zZs7Ww5mHx4sXMAQqiHygR1aEFtB9Onz49
        ZswYRlP1WLdu3SwU6YwZMwYNGoQw5kixYsWKBv/Yx2SbOnVqeHg4WUVGRq5fv14LsA9JOCp98cUXjOnn
        n3/OrGuPalkQoHm13MDHP+9hlkA97Ls+HTr7/OrVAxt3aWnaGCW7DwQ99M43HR7GITZiXu69L6dN/Ebo
        PA3eHFuGNUnz3W5/qWuHTvRwlx90mtJr8OmyU1qY0CLkTEnv1/G53h0e69/hcReHjgj9TK5zwINvbZyx
        VEtvB0wfVByHSQ7hn376qbu7+7p167QwQRDscOHChblz52JcYv5iBCtV89lnn/HJIjpz5owWr82DHT99
        +nR2AGrODvD+++9PmzbNnhZVx6mKioqRI0eqhpMK9XL48GEVodVhQ8vIyFAjQlsYEVqnhTUP8+fPR8vR
        gao3hgwZogW0H8rLy+Pi4lSP8fnVV18tXaodOJBS+fn5PXr0oGkcIyAsLGz37t0qtF6cO3duy5YtZK6y
        4jM6OvrAgQNasC1YZbm5uV27dqVWzDdE5sKFC9vRDyWCYKR5tZzn3f9B/ygbvUuHTkrXWbi+HTp73/+/
        g5vytDRtjJKCgyF/fV9puW4dHhn+37771rczLQenS09unbNiYfj4xRETl0QnH966+8K581pYa7MueYHH
        nf/WtNz/PZLSN1KuYm1hNqYuQU73veG5mZ7DU92GpfmOnBMQPzdo1Lzg0XzOCUiY7TNiuuvQ6f2jCR3/
        WaDLTS8EPfTelrRMLb0dMH18fX0xRDiKc7B0c3PLzs7WwgRBsAOqxsfHBxNT8c0330RERCQlJcXExKSk
        pJw9224uQT9+/HhUVJTaAWgI4gcL++jRo1qwFdXV1ZmZmdjixCdVYGBgmzqTz4a2ZMkSpayoIeo0NTVV
        C2se0HJIHVUi3TJ06FAtoP2AOE9ISFA9xufXX3+9fPlyFcRMXrRokfIHBFifPn2YACq0Xpw5c2b27NnG
        3z7c3d1zcnK0YFsUFRVFRkbSt8DMnDlz5unTp/Fv4799C4JNmlfL+fzqVfSPyUbv0AlLMeq5LnGvu458
        rb/Rxfy717R+UaeOtNFrLK8MLbd3zbbIZ7526fB4nw6Poa7nh46pqWwrvz+Jlmt10HL9Oj4X/NB76s/y
        4uOHtu4uzN66e8VGPg9tKzhdeukap5NHSiP+/pXfb99wRsv5+/ur4yufHh4ea9as0cIEQbBDeXn5l19+
        yarBzMUqRciVlZXhjzTiSzuyNbHjk5OTVSuAL1OmTKmsrNSCrcjLy+vfvz8aibZ7enru2LHjwoULWlgb
        QLRcA3Cg5c6fP799+3Z8ODowPfgMDQ3duXOnCq0XtbW1KDdGRGVFj0VFRRUWFmrBVqD9Fi5cSJcSn8/E
        xETqib8IOaGd0kJarkeHRwY9+UXp3iNaQPvhytByO5esi3j6a3VDWvefdE51H1ZdYfeA2sKIlmt10HIu
        Nz0f+Ie3+X6s8NCI//Xrc/2zPTs82q9D5+4dHu1/60sJb3sUrNx8rqaWCEW5ewd2/sz/d2+KlhOEJkdd
        LYZ9qQsG9I8W1t7AWN+zZ094eLiXl5erq2t8fPz+/fu1MFsg3tglUHGBgYGbNm1qU0IORMs1AAdaTjF2
        7FgfHx83N7eQkJDMzMwGDzoLZ+TIkd7e3u7u7gMGDFi3bp0DYVZaWjp16tT+/fszM+Pi4tSllSLkhPZL
        C2k5VET4Y58e2tKQK6FblytDy+Ut3xDxjKbluv2k80yvWDkvJ+hoWu6P7/B9+/xV/To+3+PnT/DpctML
        va56yjRnOnTqdfXfd2dtJkLRTtFygtBcYJJi7H788ce6YEhOTtbCrgjar8UsWq4B1KnlBEFoPG1Ry7HX
        62hel3sqtIAGoWVxOVrY5TRYy2mZ2mmC5nU5WtjlaGH20eIZ0ALMqD93Lc2JvKjluqPlPIdXV5huoLeO
        b0SFWqCFNQgtCwN4Oq/ltDSXo4U1CC2Ly9HCrNCCrdCC7aBFuhwtzA5aJCu0YPto8S5HC3OI0nJBZi1X
        uGrL4sgkdRautqpm6fCU/reankzT9YePjH7Pq7q8smT3wZbRcloDrNCCG4qWy+VoYXbQIl2OFmYLLYYV
        WrAttBiXo4U5RIvqEC2qAS3ACi3YCi3YjLWPQvnraL4GtAD7aPGs0ILto8W7HC2snmiJL0cLs0ILNqN5
        NajhRlg1Flpu0qRJ+Ftkpf7U0XwNaAFmNC8DWkDjqq3Fs0ILvojma0bzsoUWwwot2Aot2Izm1aBW2ENL
        fxF86qXltGSXo4U5jTNaTsvaFlqMeqIltoUWwz5avIvgU15e7ljLaVEvovlaoQVfjhZ2Ec3XjOZlCy2G
        FVqwFVqwGc3LViZagCC0Bm1Ry6X0G+L2y391/+njEU9/VZBlen/L7qzNMz1igh96z3Si4Oq/B/3p3Tn+
        8Ud27FHx60vBqi2ImcFPf00pfa9/1vWWf4T85YPJ3cOxaLUYBhqg5c6fO08RXvf8t9+Nz1HhrPgZNRVn
        8jM3xrzcu9dVT/W/5cWxH/lWnTLdZWvk8PbCmd6xQ17s7nqb6SkUva99OvAPb0/qGrZ55rLaats3u586
        cmzZ8Knxb7m53f5PkvS57hnf37we95b7stiUk0dKVZw035GMQs9fPNntx4+pVuBMZ12oW8fn8fx/9t4D
        vqrsPPtl7Lgkn/N9cWwnjm+SL7nXTq7jOF/KeNydOC5x4tjX3Z7xNMoMvfc2wNB7rwJRBEIMRYWqAghR
        RIdBaOggehMw9KGN7//sZ2vNYp+irSOJQWg9P/32b2uv9q53vWut5zl7n33SWw69fuGyMhtcOHxy9biM
        ST/p2POzP8FFZOZv8Fcbzms5hI7cv3vPz1cVXCo7k9ljwoCnX8BUauv9+V8saDfy4pFTES33F5VruUsn
        zq0aNnvCjzt0+9//jUn8dfvfP8STG6Zn3ryczG8YEI0rBs4Y8x+tVGHHT333tb/92eSfdVo9dn75sdPv
        Wk96PLh3v3j2Uqyd9utuNCqHdPyT7yF+pj/XY31K5s044vPS8bMrh84a/6P2aoIB6v5X/z3j+Z4bZmRF
        fB5r9S8vO7ticOq4/27X7S8jDeErBnfI1xsv7DQGz/uZooDo2rGwYG7TAa99/udEjkqN+l6LrF4TiatK
        x6viGcuIlqOzxNuD+373b129kT86vaU3i7Hn6tlyYuMRaLnt27dPnz69R48ezZo1Y/sHr776av/+/efP
        n5/47WQJUF5evnLlyhEjRnTo0EF1tmjRgjrhyvF+8ujWrVtbtmyZMGFC9+7dVaRx48Y9e/acMWPGzp07
        6aCfz8KxY8fmzZtHtc2bN1cRmhs0aBAXY77I4dq1a2vWrBk9enSnTp2o3BTBzhUrVuiLUgGIN1y5coXu
        DBw4ELaHc1TQRpMmTZo2bZqdnX379m0VFDA7Jydn1KhRbdu2pSCNtmrV6vXXX09PT9+3L8b7hEtKSshJ
        NjJnZmY+ePDg5MmTGRkZvXv31uiQSm0bNmyQQzBv//7906ZN69atm8zo0qUL3T9//rxSvVqDuHDhwhtv
        vDFkyBC645kfcTUFp0yZsnnz5rt3I8/3RoOurVu3bvz48eSks7SFSb169WKACCE/U2gQWgTYgAED5E9q
        00Dg50uXLvmZKnDixIlJkyYhushJi/SL7hcVFTGUbdq0wXjsIVQWLlwYcxCjcerUKSps2LDhyy+/LO4r
        QOLlDfwp72EnVjXyQFsMnGowOHjw4PDhw6mHLtAX+wV9eAxBwnXqxNV6HdGBAwfmzJnDgFIh16lz3Lhx
        paWl9+7FXTroPsEwePBgRl+1EUV9+/adPXs2UaTxovjevXs7d+5MKjUznXftitzbD0BfdoLxs0TQTTID
        bGPWLFiw4Ny5c34+CwwHg46vyM94aWYxj3AgxtMWvcZdeCZm8Urx1ltvsf507NiRemiFE/zz5ptv6jcJ
        NDoJtNzhw4fT0tKYU6ww6g41MGSrVq1SIMWbBQEk1nIsHayoTDTcTv1qCIewkgwbNgzbEj/RGg1iY/fu
        3TNnzsSlTCgzC9q1a8dAs07GXB8MmIkslRiMDZQl+Fl8WFqxUB7jSIW2lrt//z5LB0ulirACcMVPqwDd
        xO1MKwJJ4YFJdJmx3rRpk15YAohwAoZKSGXqEfMxH9c8ffp0VlbW0KFD6ZTiliOBR8BTW8yXxOJJLGQ2
        4RP9aAGRMGvWLErRHMHGFEhJSWGdVH4Hh0ePx1HLjfx205beKy77/N0vCyctXD5wRte/+C94cNOnno7c
        H/BIP4pu0DMvHlhb+U+I2EBOzG06EJVFbc1+75mmXm38UXPrj32jy//1nxN/0vHyyQjhMEhCy925eXvz
        3JWqmV6sGjo7d3ja6//n2WYf/FJz72LLj3716rn3FvS3z1xEW9J62z/6N8SVCvLndfMbXT7zg9HfbXHh
        0Amvbh/3bt/ZnVU48EsvohWpzXQkYuSHv9Llz/8zvdVQ5ZzduF+Lj3xFo2D+mjb4F+Vv3eDLU3/Z1f4e
        47sP3l3ad0q/f/h1+z/+d+Q0mZUz8vfBZ9r84Tc7/en3p/6qq35bLOSGBIrnLO//z7+lOGpNtVEzynb4
        t14Z98O23f/6x7oSU8vdu3O3YEx677/5KQoKk94btQb/gkbt9KcRKY7mt9VXpVgzcUHfv/8VYdDiw1+x
        vYczUSwLO45GOirnyT2HRn6nOQocjYQGNq7z8//+1zBg2DebHN+x3zYAcbUtI3fQ0y+0/0TEjQ/Z/Ptf
        Y0xXDp6pW6NAbiRscvpO6fXZn0R1M3JPDFOZBXj+7L6H9ACi60zp0Yn/X8euf/6f+NOMF6WIJfoy7r9a
        XzxayUu9peWYbv7/D2NfwRbCWDVfOXW+trUctIxtG0IgRgugMgKVsJezccKTzpw5Q+aQEUg22ADbP8Uh
        37AKVSjD4GpQ9sWLFwdqO3LkCPwSNgZps83gnEr69esX+AkjiqO+2O+xXNmUn+ZoBUA+ZLYBVvXp0wc+
        AVEzVgHOaQKSAf3Ny8vzc3uQkVAuVBzdoVrbtgBIgkjZdGf9+vX4gZrtFslGPfihffv20CllNoDi4AFl
        g8HAz7DZHh2O0huQe1QBMs8Mn1d9pDvUgMEx6c7bb79NQUlZ3Y9SKcA5BSFSaLwdO4KfZSAp4Y60Sx67
        L4B6UCYY42etDMgtOi7JiitUFaBausZFuhwQh/A5miCV/HQWzkrcYqrdBU5wNZweU/1i8UENkFEVp10D
        1UYrmKHRh1IjVNQ0SbSrGgxgnGhapVKcML561V9U0RJMH8pG7Pvtb3Nzc5HZClrbbFyKNoN2Bz4IEIgi
        +k7ABDrLkSvISJF+yubn59MFZaBRGLZqMEDdITyYZRhgcgJqw36IMnMTPRz4iTnEKvqZCmmOoMUepJ0I
        t7GH2vAYSxATOfy3sFi18Al9p3fGHk74V1/5o37+BbgoWsudP38ezYOyIj/228ZgKvb0799fkRxYbWIi
        gZY7dOgQKojoosuq32snAs7JjAH0ncnrF6gMp06dQr4iTmR5oEKuyAM4xy/gQb24ceMG6wwhh0/sLlMb
        w8T4ymkcma0BLccVBSo2oxhPnHiI6iCqCW+0Gd6zTaII+fEzQ6+cSD7VA0giHgJvTKWtJUuW6PMCO24B
        51iOJaxR0T9MxyJDKkXoIKHIoLdp0wZvmBpUnDhk3j1uX/J0qCd4pO8+KT/2EI+JB3Ki5WC9UP9ef/PT
        Tp/+PsXbNPgyf3BKCKVoJRnQh5dPXah0TVSG8mOnZzzfC76LoEJbUhVKhjo50pzPgD/4pSm/6GrLuSS0
        3Ds3bm2cuRQyLTsH/NNv8QPnuiIxgH5T5vKyM4s6j6WnmET9llWRH9/jSsSqp54e+/2WF4+cMj09vmPf
        6O+1kM382aU4p4lJP+mgnLMb9eNfUlWPOkJbyt+pwdcmIV8rdAsKZGGnMZ3/7D9UreyRiziRMZRt+oGn
        J/y4/aENMT5hjYkdC/P7/9NzqhNjvNYjBnPEmNZ/GNFIMixay928cm312PmYROv8EQmUCpjE38B//m2C
        21Y27t5+pyhlSfe//pEK8oefPWMiFXLOX8qzPVAsyv9W3mauYDNHMpDNat0TWpFhfXrar7tdOPKeZEJf
        9f3CLzXWZFNBleKPi3RTP4muMUXbo+cRfsbJdhEuRrSZJ8/G/mcb/Vq9CqIA5zYd0OKjX6UUf55/3rOQ
        f/v83c/P7q/k09nEWm7nkjVUpbBhatSelmMXhHVBfM2WrA0bsqKt1OydXElNTb1wIezLb5FDsBCZYeoE
        nPCv6oQIIuf8Ah7gZLSrVLJxThGOlOII71+3bp2f1UNWVlbr1q1lJEcMNq3oHKJAB/3cv/sdNFdWqQny
        qAlZZeqhISidX8bD4cOHRY9MQUpRVi4Cok38S1U4ynzYXFRUBEklSaXUEdOiaoOvQLjt+2DQQfJQJ6CD
        kEtOVJAjRfiXOqmhXbt2PXv2FPFSBplEBrJxAivS3TkDhNycOXPgfJG2PWAMBQEn/CtrOSJrIXbmY3uo
        24IFC6hZvooeIOzcuTPyWEelQCegZyBnXvvvDYSq0kUZUFpaagygcjpLu+o+52SmrGd7xHguKokjaqrS
        cEXLQfRRL1RCEUFdo0LMwx7NesxAy6njZI7+2TEiE2JqDJg4caLRQqjW2bNnmyaIQHLSQdNl0yNOoKeS
        32brARs3bmQuk0FuIZvKqvscu3fvLs/funWLWFJDgDxLly5VJcKePXvg+iR5Pn5ollGVmgAoFoLE3FoE
        aEWGQ3nITy/UurGEUqqWIwoh3n3dAFiyoOMsBRSkZiCTgAaUQI30xAMXA1qO2MDVjKAKGnsA55GeeNWy
        xNFxv0xCJNBya9asYTJSPzWTZBpS38mvI5L72LFj9vDFA0PGwJkKQXSFgOXIngWAKfzGG29QUNmAOq6q
        cBQBJo+RxHlAy2nekUpm4t9++SSrJWsmnlTNHKkTkzgaC1etWqXMV69e9RqPxDxVMSlsWUgApKena2QF
        8nj9e6+DNMSRPSvwqc24ceNokWrJSfdZ3+xApay6xgkLILM4jLcdHGoWj0jLwTKRZDOe772w4+g32o80
        f/OaD94yL/hB3fBvNoGDRpi9Rx/56/Tp781vNSx/1DxkT5/P/4LaVCfHTbOXxXsE0cadG7fWjJuPCFEp
        tMH0Z3usGJi6elzG8gEzJv+0k+SEJ7eeXjd5kXk1SHJabtMstFykiP7E6cWzJbeMlqNTEnLImHZ//O+T
        f9Z55eCZqJfl/adz3ulPv0dBimMYdt68HBE5D+7dzx8199UG/8xF5Fn7T35nbtNBucNmrxm/YHGXcaO+
        07zXZ38y5vstVP/BdTvWp2SmNR3IWPh+++CXyBNpZUw6x72rNqEHyHnvnTsbpmdhg1pEOXT7yx/ObTqQ
        avNGpKW3GNL9r/6b5pSEJxHGYX6kDpUy4Uftmnld4I/WR367aXbvyZEO/rRTqz/4OhrpPUdFaTnMizxw
        68VDq//xjdHfa5nz2hRMwvJZDfv1+Osfyc/4Nu2VAUaAJcDpvUck5ChFR9r/8ben/rJr3vA5OHBB2xGY
        2vSDz8x4oTfKWfkxAAvb/K9/Hf3d5tN/24tRYHTIT3M9/u+K24meV/eu9H887daVawWj5ynySSJb2qsD
        8WHu8DnE/6jvNCOe01sN1WiC29durBmf0fZ/fktF6GyfL/xyfquh+SPn0k3UeO+//RmmkkRzzRp8Cde9
        o687PnhQtrWULtAKfzhz3A/bEtVrxmWsGDiD+EGe9f7bn54preRp5ARa7tyB47Ne7huJz6ee7vCp7zCa
        taflYOcjRowgs8AGSRE4XG5uLjJpyJAhbKVcBGzDkKoVK1b4JRPi5MmT8MXI7u2RxQ4dOkyfPh2SBMuc
        OXMmu6+a004Me9BOvH//fkMWOXIOX6FF2JtuG3bs2LGg4L2nso8fPw6hVGfVEAbTBEVoDvbGdZiKHhBF
        tSJLIMqRhj3nQGiGDh26ZMkSDMMqSJWqUip+sJ8shZSQXxk4wR5KYRg1wCNpneucDB8+HAMOHTqkJ+U4
        GTRoED4kFR/So5SUlOzsbNwLuac7FOQ6qfTOZiS6L+c5PkKO8QaVUFVmZmZGRga9VkEBq8gAY0O20Xeo
        LYxHSVyHCcFBVa1AJQyl+kIeCo4fP37ZsmVQtClTpmCJrnMEhIchkTt27EDPUCepGECkESSUwiQc0sJD
        yMcsEdXmfhpHuoaHcSm2jR07Vje4ZAAuNT/vtmvXLuKKpEjfvN5xzpjOnTtXHadfFCGJI6lElArGw/Xr
        1+HT9IJGTbWcEDZEGvYY1xE8JkIYi+j7cuvXr7dbnzx5MoRbSRcvXrS1HK6jhsaNG6MTcPvChQul05RK
        Eq4wIoqQgDGjWskAKIsNqAXF7axZs4YNG0YRbNOzlBRkRFQVIBJowlSFuO3Tp49dFf6cP38+VTGITBmq
        IomCJKFbzB0YgJazhTQn9Ij5iw3EPP1l1KhTqWDv3r22/IiHsrKyvn37GudQLRYy8XEL19WQkgCTIqDl
        UCnITvIATgikefPmEQz0CPdK48kqlIYtTeOBgvG03OrVqwlaqsLbBABjSmb6Pm3aNIp4BkYGl/WBOR5G
        XTChunTpQhFmNBMtNTVV0xCXsjiowogrn3uOCLefRWSW0VnjbWJp5MiROTk59F1LopKUGq3l5BZScfuA
        AQNsLccKQFkQafW554gBmqabjAiuoJu0S5eVme1DTagqW8vRfWaQQoIMHCnILKN31IbHqFllBcywfzmD
        FQnnkwFQlsqJZNa3pUuXYo+cplQ6y1xI8KsbDg61hEek5SCC0FBzq8H8dWzwtTHfa2H/ehWwtVzzD325
        3z/8pnDSG/pOlPcE4NR2f/xt6Y1mv/dMyrPddX8jMQ5v2D3sG41VbcuPfLVo6mL7S2LnD56Y3aS/J+Qi
        THryzzrp1kckqSa0HNa2+/i/jf1BKwg6gnbM91tKrkCyuYgfaBpFl9N3mn3rEt6c2WOi1AJ//f/pOWmV
        K6cvzG02SDe1mn3wmdHfa3HtvN+Xe+/cPbRh94YZWeumPLTHYDMSIsLI6eBHvrq03zQ/wcLlE+dg8zKb
        nr72tz9De5ih4aRw8sLuf4V2itiDJ5EotKXUBECr9/jrH0uMoQAHPv08Y6Ekup/eckiHT35HQsXL8JCW
        u3zqPP+2aRD5ph+GzWsx5Lj1m/LvXL+1YUZ289/z/NDgaWzbszzyLHsCUDPqSOqRClv/j29ktBlunl+9
        9fb14zv2LX09Zcei1UZoEQCLuo7L9V4HYmtFHLJyyMxXK251MogoTH2sQLbUF1/TwNE1xNiViju9yGbq
        QbntylwrCQ0OFO4Y8tWGRtP2+txPN87MMd+oRPYXTlnU8VPf1W1VPDnsG432r4k8B0INxXOWG5n32ud/
        YXuAGN62IC9vxJy3z1bydZ1oLXf76g0szB81DyHX4RP/TqPUz2C9c/0m6q42tByb+tatW82myAlaiPyG
        8Rw4cACKZtM7iGMYPoQa1Ee/AHtKSkoMHdceD1MR4SAb/EO8BwbWqlUrlYIEQE1UBEARoCNwiIMH33to
        HEIAt/OYQOR5G9iMuQUHjYZ8L168GPqlR91u374NF6EXyk+7kyZNMl9ugdNLJ5AkV0B3MEypAEUhr+IN
        9NupU/7nDug96CPXKQJZgRDrugApVzcB/KO0tJQB8tM8aihZK9/SfUPXbC0H4GoQR9M7hBD5/TTP2jZt
        2ixYsED3386ePYsrNGqAE4ZDBUF5eTmWqDgFIWfwTvNk1OXLlxE2qCNTP8WNyMT/9NFUq++xAFIZIGQP
        VQWe14oJvE2k0QT1QNEYF/t5SAgiftPIygDzlGxAy5EB+YEbFV30YsaMGUYVUDOOSiAn1CkBh8tjKogz
        /YQK1KCWIxUiS6iYfhUVFZnhJtvAgQPNo6r37t3Ly8vjIoNFKkdch0xSKhSWqGCmQJFPn448t59AyzEF
        GEFqMEaiH5ibchFH+khsmzkFCHuVBbaWA5wwFuvWrVPQ0lnmI0WUygnTh6FU2QSgBlMK2wYPHmw+Dtiz
        Zw9uNH4D2GZrOTrbrVs3rlMDg4IB9jN+V65cQZkwd0ilkrZt2xq/JUACLcdywexgVWHZJM7NSkjfERhG
        t2AkpcI8+Hfu3Lnc3Fy0NKvusWPHjLsYKUQLBlOhete/f3/zyC4n+JaR9VwSSUUEaikjWvCnNLBJDanl
        mA6sZohVJXHs3Lkza7Xur5LKJGWu6aFur6ZEWo45jqpUKr1o0aIFLjKjw9DQcX2yRh5iiYXF/kkDW8uR
        h/lCu1rfsJ91RguRMuCc8A+MODjUFB6RluMP6gzphBTaf20bfHnUd5oHfnfOaDnoY6c//d6acQ8xkiOb
        S8b8R0vKRjJ88EuDv/JSQArGxJoJb7z6gYiSoVSbj32zZMVGKHVpbnHpqk37CrZwsrz/dJFvjqimA4X+
        Cl59LUeFXf/ivxZ2GHXuQGSBe3Dv/rn9ZbqjtXp8Bklk4K/9H39744xsOPpbeZv3rtrE8djW0rwRadJO
        kdY//JVTJZFlDmqe3nKotBzqCAceLNp5vdzfqmOCagPvsYSU+2kesIcW5VXZjLDUOzNskjGr8eutIi+p
        jxiMBst+bbKfEB+Ipci3E70inT/9/VVDZ/kJHq6eK5/bdGCkI167AS3HuPT94q8oqAyrhsx6K39LZLxW
        bSKJEURvtPJvqPL39KbZPlGIh5NvHmRw1RbVIqpP7437c6LA7ns0Lh0/2+XP/5Oq+CNis3pNlqgmnmc1
        7Gsk1tRfdsVm8+24aKwel4EmlFX8EfCBt5Wg2eY06Y9zSKWbrf7ga2snROgd15HK8g/HPp//RdG0JdfO
        X7p3J/hAUeKORGu5s/uO9fv7X3Vo8NVW3hOh7T/x74O//BJCl6Ra+k0CtkaokqELyBvDoozxsI0OHTqY
        HRcNxo6rpHiAWNOu6qRy5B8bPCzqTQ/wxY0bNxoj2bBhAGoOzsqezXVKQYuhOOz9tvixAZEiswyDRvTu
        3TvxTZhLly7Bacis/HDW6LcUwJ/gHLROtaBPnz5+wu9+B73GVJESRJd/1QOknApJ4mhzX1QTMk/MmLJw
        JjgQck5+wNrCwsJ+/fpREJPIk5KSopc0AFvLkYQ2M0kAMoeu4LpSYWYQXNtRet5PNXOcMGGCn+AJZnzL
        RQoGkjQKULe0tDSVFahcH3tDv1QtZeks44WmDfkonQ2YJeLTDDRSE28gKuQZxAm8zXBKgDek1mwtRypl
        A+wcvWFKYeH06dMDz5fGBJVjEvlVFs/DO/20CtSglqMSOmh/KY6xI4BVllQaMt9UJM6JQ3UZd0F/9eqU
        eEig5SDQSF9dV1XR34ekmzB4NYfBTDHz+YKt5ShOJOze/dAHi6wezA71AowbN84O2pggA54xrB0gk+yV
        k/WHiWPqtLWc7rRjD6mgadOmrBhHjx41gURQMTc1owERZW4oJUA8LZd4PT906JDuLlIKk/BzpVoucYV0
        fOzYsSZm0EXmkV36hXrRQAAkn/0EAaDvZm3kGFLLMQuYiXSZJBDzEw0DGR9Py9EEC51JwtSpU6ei7kxB
        YebMmbTitRZpjjzGaUbLkcSgsyLpusAo68MgMgA8EPhStIPDI8Cjui8H2f3oV7v/1Y96/+3P7L8un/nB
        os5j/dwVMFqOsr0+99Nj297yEzxcOX1xbtNB+gIPWm7oNxrdSChjwK23r89vPUziR3/eHcL3/vjXyBi4
        cve/+m/zvFz1tRyVZ/WY+G7FiwFtpDUdRHPKGXHRw1bxJz+Yek7sinwEBdFfNXQ2DJuyXEcz9Pm7X2T1
        nHRk0x5k3q2rN6LX5Uq1HPJpxaCZqpCaO3/mB28uLfLTLGxfWIBgwA/kbPn7X5v2m25+QnyM+HZT6oy0
        2+BLr//Db45uCX4YuX1hfre/jAjaSLaHtdz66VmvVviHP2qIHjgl8de2wZfXTXno+07ROLRhN5LY1Las
        //QwHwQAtC5RdOXU+fJjp88fPI4a56RkxQY8r9oYqZw+U695N3sJyFVDfGfyh5H9/uHXK4fMou9Xz5YH
        fqWdmtH5rb17j/w1/9CXL5UFdwJGfN/qbSTJFfQ0q+dErr/74N3Dm/a0/tg3dZ0edfyT7856ue/u7HWY
        iry/H+IhWBCt5Yi0Qc+8SKeYYox4Tp8p5k51Lf1WOGJGD1yxHcJd2B1jvi5i0qRJEiTkgeXAdfyEWGAz
        hvNJLVCtSlHcBpu0qBLASHiJps+ePXt69OihHZokjpiELDl9+jS02NYMcAVIvG79kY0KJ06MjE48iFuo
        TuWHTPtpFug+4kr8CTMgf37C736HKpPZzZo1e8N68xuWo8HITBIZbIqPAtHzTmo0nh+Uim2wRvPpsq3l
        yJOTkyMmJKDlaFTdoX6Io61jMQlCDLVV5bRiK0wKismRiqDKz8/3Eywg10VnKU5O9J54JNWae3oCNW/Y
        sIEBwqTwog763rhxY1M/FcohBuLEagKgP3UPJKDl0DyBZ6ugdB06dFApKqEhdJSfFh+PWMtRHDKqJIHe
        wWtVOUckk/naIVKKoVRBasDh0Z9B2Eig5TADC3WdqqJfCyTQNZnKADEFWEA0PW0tx7FVq1bKb3DgwAGE
        gTIAwuby5eDrmgPQ11BNEUh5YLwIKlYALDFDY7QcYhjxZkZNrvPC5z2YVGrAjYFv58ZEgvtyAnOfyYgK
        xVQcCM6fP8+UQY1jgMzAD2Huywm4lzDGV6ZC1gEWw5EjR2ogqJN5Z5xJW/YtO2SPuWUnEDPTp09XxzmG
        1HL4c+jQoaYLtJhg7xDiaTkMYJhUFaC51atXq4gN6td6Qh66w9obreW4jv2sPGbJBYEJxZS378c6ODwa
        PCItB8sc8tWGgVfwxcNDWu6zP0Gi+AkeLp04N6dJfz2QFlLLlZedgeCaZ9gS/yEqevw/Py7N9ReO6mu5
        Ng2+rLso0Zj2q262Gkn8hw9P7vYfJzj55sFJP+tk1AIn2IZKGfTll1YOmXnuwPHASx0r1XJQ/7RXB8hm
        PDDsG43Nk5A2TpccHvq1Rl49T7f6g6/PeL6XnxAf/f7+V+SnWsaUGIjWTgl+X65gTLoesAzzhxwqmrZE
        BeNh3+qtKEDlx57iOct1gzQxbl+7ebBo17J+04Z/q3GXv/ivV7yydMrTtH7rXDFaDlw+ca7vP/yG+FQq
        7ork/9CXh3y9UcHoeeXHzhiVdfHo6em/7eUH51NP9/jsT6J/rwLcvfWOXrlJNnqa3du/I0qwLeoyxsg5
        jrof2OP/+f/SWw1B6VFQORMgWsudfPMQWg7n9/3CL3cufmjnqyUthwCAtZBTWyYsP+aTKllZWXp8CEDg
        AnelAmDHRYfo7o022gSQkcgkkUWATEKhySSTgdqgNZs3bzbPIN27d6+oqEg5lRlWp6SYgFtA0Uyd2AZB
        9NMeBgQUCkg28uAc/6pHcLmi5lp73yOCNEMgcA4+wUhSoSyzZr13D7ygoMBQrkpBtdAXQ2QDWm7JkiWm
        7wDqhlSgCKk03bdvX/v2FM6E7dlaDu/5aZ4oFcGlYLdu3WJ+vQ3dgj4kA8XJaWSJKJrdI1KpDUeNHTuW
        AVLxSkGFWCX7E0N58vLyYmo57A+w2FOnTrVv394r+lhrOftRYYBjZ8yYoco52loOls9FoIJwdFvVRyOB
        ljt37pxcB6hq4cKF8moA87x30tAiA42WMzfKAvflorUcGUzYgDBarrS0lOhVEVokOAO38ljQmEc0Jw8w
        NEbLSbFQVkmJQQ3MCGaryiZAAi2HHwDLZmpqKma3a9eO1g1kBkfOQ2o5VXjy5El8jus6ed+DJU7sClWn
        reUCkbZgwYJASLBWTI3/+3LxtBzBwOTCAK7TOvYQ8yoSD/G0nAxQVRzRWjGf5jhy5Aita97Rou00W8s1
        adKE9dDWcuxTM2fONBPKaTmH9wWPTsuF/02CGtdyl46fnfmwloMTx/vD1NHfa24EW41ouTXj42i5X3e3
        tVzAksBf17/8of1CwnMHj0/+eWckkGnIr+QDkdtfAf5duZY7fSGt6UBVRTcn/aTjiV0xHhKD4qPHvHpC
        a7kv/pr8VIv/x3zPfyOLjQRabvXY+baWs70R+EPAtP2jf9ux+KHXKkQDLWcczslB79cLE4Pg8b5LGbEt
        8uqXii/IBf4CWo6N8dz+MrRf0w9EzLNzNvu9Z3p//hcHK36mD1034/neOIcmSBr57VdvXY3xzWnGK6aW
        A8jRRV3Htv7YN+RD84fB7f/433OHzb5V2RdKo7Vc5AUnX23YrsFXiKWAZqslLXfgwAEImXZiMqOjDPu0
        AQGSMABJaDnqjwfyQM5gsRAaFcd+mospBdnap0yZcsa7k4CWg9AYLde4cWP7y3XRgPZhtuohP92J55Z4
        Wk7fbZNV1MAJHgNe+74DyYDY8AtEaTnljAeywcmMMqk9LTdkyBCRJ459+vQJiAoBDtevXz9qpjg5bVkC
        oVy8eDG2qXWBcwBBZIACN8pigmx2DSoeD6gRvKqbfvVcy+GKmPeTbYTUcvSxqCjGYyCAvlOKFomf2tZy
        eNXWcrbThMRazjjNazBRIOF8DGZaqWwCJNByx48fnzRpEnFu2lK7NriISSG13Pnz51Fxulkar0LAdVvL
        MXB2pOXm5gY0efW1HNdHjBgReHQzGvG0HNrSaDm8wZb05puRX1QKAC3Xv39/ClJDwGlOyzk8/qgXWs6+
        L0eRYd9oBM++dv7S22cuRv+ham5cett8YalWtdzUivtySBH0zM3LVwPG2H9Xz1+yH5ljS7t+8creVcUz
        X+7T6TM/gMcbKk/T3f/3fx9av8v84nOV7sthzPBvvXJ4Y4z17vTeI/Z9uem/7eknxEdf674cZaN/1zvk
        fTlsO1VymLEOuEV/jNrVc5cqfaPpw/flntkT6zlSG9fOX17aL4Wm5RmMpC8U1J/8UFHbw1qOAXrwgEDa
        sbBgVsN++k2F9wboA1/q+uf/ddp7arG87Ky0nK73+puf3Y6l5eia0XL4xNZygJAr2/ZWZs+Jfb/461ef
        ihgpg/lr/nvPrJuyOMG39UC0liO67ty8TSmOgQc1a+++nNFybJkQo5j35bKzs819OVhOpVpu48aNEmPU
        jNyCGsISrsQC7IRjgIhQw9GjR6E4UGcZJgs5gokTJ5IB2PflYHiJn54KaDk8E+++HPVTG9nUWf+q93O3
        gwcPFntQozagHa1btw48R2S0HBlQm2lpaep1TOAK/GA0be1pOfWCshREC0V/YwrAsA0pJyf81TBsKkdW
        IZlSU1Nh9jRBNlnCEY6IYcqZAOTBKtmAi+gs9fuOiAValGfquZbDsPSo3ygPgCgKeV8OUcRyoSQbTD1x
        fQaXif8otdyQIUMCP/KeWMuZ+3IcGR2GAHf5QfMwsIRQCfMYcAItR8xrzZG1NMqYkgcoeASuh9RyDI15
        h4cK2hWaXnNMoOXeeOONwAcoSWs5zWhdZyx0PQESaDm9D5PrHON9y5p1ntYpSA30HV3ntJxDHUK90HJv
        n75AEXNDps3//Fb0yyHioVa1XOoLr6mnsPxOn/7+uQOVfPIUDTQnMuzsvmNFU5cMeuYlvUIjIjm8l1Xe
        e8fXNraWa/7hr2T2mBDQcsihjDbDjWLp+hc/jPlOyF2Za2H8+AFR0fpj35zVsK+fEB+DvvSinvqj9X7/
        8Bskh5/gUTGOCIl435crnPiGBjqS1OBf3irYqutJ40DhDgWk7Fk1dHa0trSxr2DL6//nN6YIAibntSn7
        CraWbX/r5O4D2xbk9f6bnygpWssJd269g2/p9eox6cO+3shoP/q7PiUThXbt3KVZL/c1wcnAmZ/7M3hw
        7/7Bol3m+3JkXvZ6ip9WAZyJtr9w5NSbOUXTftPD/logklvv3YmHaC138ejphR1GD/yX52c36nfs4a84
        1pKWg1Ca9yiyZQ4bNgwq7KdZYFc2n9ND4GCKfkIssBlv377d3FjDBviWnxYaOBaWgHyCAcAIYR7elh2h
        C/QICUoGkgyv4jpG+oVjAZqSn5+vzACWgED10yycOXNGnxOTB/uRZ37C7363YMECfcULYzp16oRDoJVy
        Cz5BASKKkAR+bg/wJz2FRR5ahJ34CSFQe1puxIgRIk+kYh5E2U+wAH2n7ypOTnhhQDIB+DH8acOGDWPH
        jjVffuPYpk2bSm/NERIaO/LDm2N+lyYmHhMtRwZ67SdULKpMNONz8tSUliNJpBZQA3Q5MEnVukFiLedf
        rZgyMRkwXZOH6Uvz5s13796tJmpDy1HEfo0HYxcYrwTfl6OnIvQkKZDi3WmsEuJpOYSNvtyFGSQx1rNm
        zdq2bduhQ4eOHTuGkXKORjCMlmN9Qy+ZvjNSjAgTn9g4fvz45s2b9e01NWdrOfKYd8xwnDRpEpNRSQKe
        YYqZDCG13O3bt83deI6stAEBZkeazuNpOa5jAD5UKutD4GdRBOqnFdoiD8XxhtNyDnUI9ULLgeUDZhi6
        DI3elpEb2HUCMKm1quWye09u84ffhN9LGumFFokRz2xE3a6swqFf102zyCs0xv2wDZYo9a2CLcO+2cQk
        pbccapKE21dvIJwkqPjjBI/ptp7dYlqzQa09g5FniM814xJ9L0iY8vPOngiJFOn6F/+F5vQTPDB281sP
        e/Up/8ZXQMttfyO/45981+gfpGPM75KFBxrM/CQd9oz/rzbRv722ee4KdNel45HleFfW2q5/UXHPsMHT
        2xfk3aywDVw7f+m1z8d490lM3L9zd+fi1QOffl61cUxvNRSZRxL6UMEpw9ZNWRz4Fh+SL73FEKJdBTv/
        2X8UJ3xj54ldB5gjpqG+X/xV4h92j9ZyWzNymbxtG3yl7f/612X9H9KNtaTl2HcHee/TZzvkyJZpGKSJ
        QKhV586dlYe9U1JKSTFBQTZykSEVgXzDdfzkWFBbMWcZWzsGQIvNjQI0Rl5eHknQF+iUMYxeB/gxgGCh
        gvSkEEdlVn4kTfSLXlauXGloHHngUrpOWb2UBaAfEKsQXOgFvoXMlZaWwiT0g3I2YP/QO+pRo4gcm3DE
        7K9B7Wm5OXPmQK0oCygLEfQTKkyiI+np6apc2dBa9ksXbZAZxUIlykmLVI7iSkxks7KyEAnys8xL7A2D
        91HLlZSU0JxpGl1nk8tLly6lpKR4zUZAhprScnieSSfD8FjHjh2JDSUZEJAoHHQR5wm0XHl5uf3ZTadO
        nfCnkgxQJsgGmUoemqZCJdWGlmMJst/WiG3MJj/NA65jGspmYGs5Rk2/1qBUjgxB4POUAMKEWTwtt2PH
        DvPyVdQRQu769eumQgJv4MCBMoY8YbQcC2mfPn1URJ8OMECmFOsb0k6eIYOt5Vh8CEXM8FwSEcAm0gRW
        A+QNpVQ2pJZj75g6dSpdJgmQZ+HChSpiwJq5YMECs6fE03IELWup6iEJU5F2BLlKGaSlpZlnKzhh2TH+
        dFrO4fHHI9JyMN2hX2t4oeL3lxOjNrQcLLy996t0lOKv/z8+uyV9lflZNoCYObXn0J5l65d0n2D/jFit
        arkdC/PNI4jIGNxFwcsVv0UG7t565/TeI28uK1oxYAZKWE9+3rl5e+/KjbnDZh9cv/Pqufe+nH2waOfo
        77aQYGvx4a9M+mlHcirp8MbdY77v/5ADDQ3459/q5g+mIj+kHNA5r3pvveePnP3+/lfoLlPDzcvX0Bjd
        //rH6heeHPj0C2FeZrNi0IxOf/o9hJOsGvXvzU6XVPx238HjizqN8VL9dgNa7tiWvaO/10LBwF/HT313
        1ZBZ9t3Ldx+8e/7gif1rtq4clLp/zbaAQI3GxaOnpj/XU/cJsQchvaz/9Cun/Wf53j59YUNqdp+/+8Xr
        /8d/32ZAy22avdTc6kQEZvWahAKXbbaWu3nlWsnyDfmj049t3WvH2N7c4oFfesFIrDfaj5KW2zJ3RbuP
        vxeckdeNLFlj3lny9tnyoqmL0W/6ql7rBs8gj097v07BwJ196+jy/tNLVmywg7a87AyVm4b6//NzhzfF
        eGLWIFrL7c5e1+tzP+3Q4KvtPv5v+SMfegVz0loOxhnzIToBBsDGrC0TkB+F8+abbxo+ceTIESip2d0B
        rN3eU+MBfuAX8L7vBFk/cOCA2cupga0XcgwVgznpIqC53NzcoqKisrIywyChBdOmTRP5wEIYpLmVhIZR
        EwAJAfU5fdr/qZXz58/r94vY5vUOd1qHyKqnyp+ammoYAKkwJFwnhkRDr776qnnH44oVK6Q9uA6pMj+v
        lBjQShiJYWMUh91CVRkjP4d3J5B2aQhrbb1Ue1oO8UkSpVQz/qRp87k+IbF06VL8pgygRYsWxktwPjJv
        2LABcWsGiO5gDFWpjzgWTp+YyGItblTgUQp7MjMz7Vc73r17l3+3bt2KMQSwCbn3UcvhhN69e2suqJuF
        hYUS8AQbzJKAMU4jT01pOTFsJSkV0s8EkWihic2bN2MYPmRouJJAy+GrcePGqR6B+GRM5V6GjHOEE90n
        iT5S1r6pXhtajoFmiBUJgGrpHeuAUt966y3cYlKBreUA04eBwFqSaBeTSNVXaoUbN24cO3YM7YG0IPL9
        qwmRQMu1bdtWA0Gj6EZz/5npgCwxpciThJZj7TWxSrAxI1i7qJCukcHWchLAxi2kZmdnK9gISJYO8wmd
        UkNqOa6z9lKtGuVIXLGSqJvEFcPBmHLd/F5lPC3HEoTqI36UpKFBGZp1hoIsekZwUmfr1q2JH6flHOoQ
        HpGWgz33+L9/vOz1lOLZyzbOzLH/1qdkvplTZH9HqDa0HAJAX5mTFOHk9X98NqPN8MKJb2xOWw5Xzuo5
        adqvug38l+fbN/jK3lXvfdZYq1oOKj/nlcj9E0O7u//Vf89rPnjd5EXFc5avn7ZkWb9pKc/2gIvT9MbU
        HGmVq2fL5746kMyjvtt8QbuRG1OzN81elj9q3tRfdkUXqTZU07opi8yjpGiYmS++Zr4qhmRa2HE0zl/S
        bXzRtEz9bPrtazen/aYHikX3wWjxtb/9GXJl89wVG2ZkL+k6rvNnfhAp6ym99p/8zvKBqao8Mcq2lQ56
        5kWFAYY1/71npvyiCzbjeWQVWkhDqb+Alrv19nWExKtPRQqSShR1/NR3pj/bo2BMOqO2YXpW7tDZM1/u
        O/xbkVuO9MUWTjGBQNqWkdv0g34YUIoAI5xWj51PHM5vNbTzn32fi8g5zCb/W/mb+33xV9J+/BEJ2b0n
        M7jkR1C1/tg3TJKt5ZBVM17o3ez3vjzhR+3eaD8SDYwPC0bPm/STjnrhpFpHsEkZIokJzmaeCFdVQ7/W
        kIZwO2XTWw715pE/KG3/6N/wnm6ZorQ3p63A4IFPPz+rUb/V4zJoqHDSQoqgtVRbqwbPEOfMGvLHA1qu
        /Se+3e+Lv/b/x/NXb9B02isD8kakBX4jofzYmaFfb9T7b39WJS3HLgi9gAwhZuCdBmvWrNm+fbu+l8L2
        rN9fYkfUtoowyMrKgq+z10IvvL0yAjZOlGH0B/kxAXWgWsygIJWD/v37Z2RkcB26QP0wXeykTjioX8a7
        LYZkatasGZwAkbl+/XqshTe0adOGGjCP/LAfI9iQWGzzJNEKqZxDVWGu69atg/7CrWEDRsvhGdq16QUU
        B8ZAo7SCbQgDkoAqHDZsmCFPeEzdIRUahHmyTYBM40/dnQvcFli9ejXdMZyD4jAnOA0V4mFMRRsjTrCc
        PtoPStWelrt+/TpdM90EWIiT6QgmzZo1y8QDR8ouXrzYsChoLk6DdUFwGSAVQRJzhfzUCXBjvJt4BnhJ
        vyttbMCrGEnIER7UCZHlX/2iA0Np1O/7qOWAuf1IHizv3LkzdhIJEydObNiwoRllQJ6a0nI4v6SkhGA2
        g4KQYAQZF/zPwOF8inBRn3Ek0HIIJyl50wVSCUjmBVMmJydn8ODBJHFdR2booUP+h4CgNrQcYAkysQoo
        TnTRF8weOHAg102FIKDlgNSpinNkKjGpCVR6RCzNnz+fRYyQoKeJf7bEIJ6WQ2F27NjRGEMeIp9WyE+s
        EsCygVLkCaPl0Jx6qawqZGYRM6wMDB+m6ivKSqJOW8sxjhpirutIZoyhvywUTAoMAMaekFqOdePSpUtm
        GQSksuanp6cTacx31hmKEOrmpcHxtBygtsDQyEitnJK+KoupjA5J9qMNTss5PP6oXS3X469/JBLf9KmI
        wIBWImxaP/zXoUHkd+fMr1eBYd9oLH5PWVhs4CUclz0tp2fSIlru6w3DaDlwYO32Xp/7KWoBS6C5VE4l
        yJsKk/y3WfAvOf0ynpZ7/R9/Q1uyZ/wP24rlJwCKa+NMtJzP8iNa7uGfOxf0qc++1VtH/OsrFVZFHkTE
        DOyxreKP61vTV+leDbwckk0GSL/XBWUmW6SSSD1PPT34mRevnrtkPlh698G7kPKIx7y+c6KxwPkTftSe
        Pnp5Hpw/eKL/Pz6rwZIxZsg44V/V3/KjX0NwhvxlNoCG7/Cp7+I9muYPJ3tdi9RJbQxKn7/7JSdYhR8y
        2o7Q757LeFQoqqnV739NJkVajxR/b9SohCv8u6xfSuIfTBeQrFN/1a3FR/0KKxwecSNVYRtmoKwueveQ
        UWX0lJxe5oiYtB3CxRYf+Sr5+cOG7NemSMvhTxQ4V/gjG5m9IpHKVVWz33tmxLeb3rz8HvM7ULjj9X98
        7lXf8xEXqSB/3ujjuoipbf/Xv+b0mWJuxr5z/SZ6j1STX7YpYChCVR0/9d19lX3PEC3X8U++2/1///ex
        raUH1+3gj0l3YteBM6VHTu4+cKR4jy7q+qbZyxCKfb/wyzBarnfv3mYXB2x47JRwIAM2csgTG6ryQwvE
        G5SfnZVNVEUoq4sANpn4rSc2CCQouHl+Bqha6lTNnFM5J2zDfpkKLackYwMnsk2bOmTFz+3dlIAumAxA
        dVKQi2IzMDnzhCeMefjw4WRQZlKVHyi/LuI6+I394jWoA/UoA6CUcSknXIEtweynTJmCDjHCAyDPYEJk
        MxbG9AOpcDVD/QHchQweUYmwGXptixbOpStIpcJoLYe8tLUcxvtpHsiMtKa4rOJoTOJEFwFXhgwZYncH
        2koeDAbRRTjCz5YuXernTogDBw5glUrpSJ2qEHCiVkiCtBmFjMKxtVzAaQAtZ+6+UhxpGlLLwaHpCHVS
        EAMCv00soJBFlJUNm40HuIi2QTNwkSQyMEBGnIfRctOt13jYWk5A6aHW5Cvlkf/VNNeZ9bu9X+5GyzGP
        1BDAPKPlBOSf5JxAcfVCPueKWkG3mPvSAlKtR48e6gWlGGs/oQL79+83v30PECRhtBx50AnqhWeRP4uB
        RoTVydwoxk5byxHqR48elcZQcY6muIaGf+UlfOgXSwjcZbQcZdEnuo7SMOJER6qV3zgxFznSVhgtBzIy
        MmiC/JQCdsfVXx1B165dbWeePn1an8gAUm1jqISLbdq0wW+ckMSySXj7JSu0HNcpSGaWAnMjFKD68LYK
        csQAu2b+JXjMF1xZiMgDVBWa1tZyTEbCUuaRxzbS9hhgKbDvpgJcTTaVJfKjtRzajxbJADDYaTmHR4/a
        1XJ9/s7/XekEf/BdqHzZ9sjj9cKIf3sVMhohyg2+1Ptvfhb4denLJ8+jZMSk4dDDv9U4DIMH9+/chdSO
        +Pdm7f/42y0/+tVm3s2ZCjMir4PnIly565//l63WYPMDv/S8smFP5GX9OxN9RQfobklFzRH2Xzgp+Ki3
        jUPrdyER2/3Rv7X6g69jhrGK3iFWW3zkK23+57e6fOYH+wq26D4bVH5x13FeEr14z73kb/7hr7T7+LdH
        f7c5ElGVG5zee3jGC73J4HVcRSLqdOZLffTdMOHQht2jcNEn/r3l73/Ne5/+e8Y0/9CXW3/sG50//f1F
        nccmvs8TwK0r15Z0HdfhE/9ODdTjt/6BL7X+H9/o+8Vfow0KJ76B1NGALuw05salhwa0/Ojp1Bdf6/Sn
        3yP/QzVw8oEvtfjwV9r84Tdx0fppS+5U9oylcPPytUk/69zp09/3+mgc+C+4GiOJkMOb9piXNx7esHvQ
        V16mCe81kmo6Mi4Y0/2vfzT8X1/VRSI2p89U6dvysjPzWw9jNCNFIvW/50NirMMnvzP835qe3HXAvGUU
        3HvnTmluMQIvOjgpFYmBP/wmns/pO9X+xiDafsfCAiInUsSKHE5wFD557W9/tjE1u9JHT3cszO/65/9J
        eFco5ER/xDN/A/75t7uyKtdyr7/+uvbIyB4bC+zH7I5IOL+M96ZK9n52We2Ofj4PXOE6TIL89q2hSqHX
        bUOsDS/xa6xQIFyHUsBI/AIejYCdmzw6AfSFzBAsKgy85o5Wxo8fD1khg5+7gtxgNrxfj5jqQwpQVlYG
        xWzRogX9Ik90K+hPBExJSYnyG6xfv9788Ldf4GFQnFREXVFRkWkOYDCMjRaxJ9Ai55hBo+jksWPH2j8S
        pftyykaprKwsO5WBQCorlRr69Oljf8uI1rdv3w6Twx4yUL/howbw/oEDB2ISqdSvqoBcx0W8jTQ1zEyA
        EZqvDEW7jqT58+cH7kzGhPwDgxw9ejQOD9gA5BnMkzi8W/H6QQQ27JAk5UHzRGs5MVFAtpBajvoZNfLL
        DDwf73WREH051u4+9jO+OTk5K1as4FwX8Z6x7dKlS4hDmQ0oHvO+HEkYQDb6FbgBjoWQV1oJ+Iqq8BKz
        hqHR7VCOeXl5frLXFxS4KhFoC2Patm1LUqAjgNY1cQoLC+0wBshvDFMvKMhw+wkVQMux+JhuombDaDlA
        PGsWU63KCljYuXNnVqe5c+dyTsc5Rr+x9q233mIZCSwCAkW4iEksRIl/tsSAhY6owxKNBYb5Cd6HIMwa
        Kgz4jSutW7dG/aoLFCRbGC137Ngx3YCiIbtCKmGsqVCfspHECmzftwdMH4ZJq4pfrGJtZxSKi4tfe+01
        XWF50Sd3AqLIfC5Au5hqP94MCGPcLofbVnEFIYfwNnOKgfPTKu5hMgGVJLCQDho0iFWRVD9fBaicCrGN
        BTn6C9UTvN+GUTaKb9myxdZyGKD7cqoKRzkt5/DoUbtabmGHUZFvQ33oy5DmeH9Q1SFfefn8wff26bkt
        hiBImv/eM60/9s0JP+5gf38MQJezekxExiBLILizG/Wr0vsw2BKKZy+d+eJr/f/5t7RCPfwhXYZ+s8n0
        Z3vkj0xD2Ng/tK3HIOHK/LX52Dczu0+Ifs1gAA/u3T+wbmenP/kevYNhv/b5n+9Zvt5PiwPk367MtXOa
        9B/y9cboSVnV4VPfxcgpv+icN3Lu+UMnH+Jkx06vHDxz4o/bD/zSi2gGbMPJ3f/6x5N+1qkoZcm1C7Hv
        mNGX7N6TqTPi+Y9+tf3Hvz3omRc3py1/1xIVAIUQMeaV/q//n9+gExASNIGQGPntV3Nem3zqzYOBHyIP
        idJVmyb8qH23v/qRnNnn73+97PWUt73vqu3OKsR+utzqf3xj48yl5kt6Nvav3f5G+5EIrY5/+n00Eplx
        FFJw3H+1zukz5fiOfeZnJEJid3bhvOaD+n3x123+8Fu0jsSa8vNOxXOWBUgDQAvlDptNQ5Gh+XDkdSAD
        /+V5IgGT3sot7vip77b6/a8h7daOX6BHhRFpFw6dWDV01tj/bNXn73+FpsKHNNHtL3844/mehN/tOBGL
        YzfNWpr6Qu/X/+k5Pzg//BU056SfdsjsNdH+dUEDRCBqbcZvew771isETMQzH/4KfRn5nebZr00O+WbU
        nUvW9P6bnyaep/Yfc3bo1xvtWVZJVMP54DpskOxz7IUxweYHJwt8e+T06dNvvPHG0KFD9cgWWy8kGwI0
        ePDghQsXBp5kCw826Tlz5vTr149qqZPWMQwe2bdvX7ZwGAbM0s/qsUwk07hx47p16wadUhco1b179+nT
        p0Ok7IdwDBAPSJfJkyfDP7CcInSfFqGwAfZjAFGeNm0aTEiiTq3wL4IKEWWbJEBZSKJyUQe1IlBQlALS
        I94DTzLfJRMI7+PHjyNE6XXz5s1VikroI0QHyqu3udhAtOgWJZnJtnnzZvv+2I0bNxgU1UOj2GaTIZpD
        stIWGWgFwRzzLhPiEG9Dv0TQycyRRqGAKSkp0WoWEAbr1q1TEZwsA6i/R48eGiA/X2jQKcaOSECOSliq
        QvQYoQiDD0ga3Kh7I5hKfCLVAoN17tw5sW1hyZIlAbEXE9Bu/dYiRfAYURHz9Z4AQolJKAcGRU1gKrGE
        nCB1z549GI9t9AUJamxDX+Xm5io/QHoFKC9+QLEQOaQyoMOHDw+IPYFBwc9MB7WC/xkspq09PVkBCB6N
        Dn1h3hE8fpoF+ot7If1koCraJT8ziK6hVwOfmAhcHDVqFNyaajHVPHxowOigTLDf6+WLkP7wn/7g2NWr
        VzN21KzBxZiMjIwLFy4Qz1rTROtjvvUUB0L3CSR8K1GneMZXOJM4CR+cO3fuJJ6JMZpDrwbuap4/fx5N
        iN+YKRoC7GQCIoeYGpzTfQxAyYfRcoD4pEcsswwEtWE5qy6jsHv3bsJJD1kQTiNGjIh2JmPN1GYyEg8M
        Cs7BsPz8fLyBP+k41+kIQaJ7tgJJLA7UKT+npqZGS24WEOKKVVSrEJnpGnF+6NAhu19EOCJTVdEWIx69
        U2AMiyqBQQ2KcLqJqTiZdYY+xlzVqYrMdIrMrMy41yYJLOyLFi2SuzjivTAf2Tg41CxqV8tBr+/evoM2
        SPwX+daQNTcodce7zvHeHf/HfAz4N1Jt/AyVAqpNKRp9z7bbd/iXi8iwxM1xHqY5SLlV+TsPrE9x4gGr
        qBwz1HevYCKruHhfvbDzk/n+g3gWqi+mSMR779yx7w4ZxDMm0v2khBygIAZbbrlj+kVzukhzMe0BkeIy
        vqIG2U+dyVkV7GOF9/zkhyFvK6ffrtcof8Ye8vi5rQEi1a+/siYEUsnznlUVpSLNxRlWU8RYEikiC0PE
        KqCG98qG+6P+xB0R2GvZ41E4CcD2GaAakUC9f5/rdlnOAddDdioaqlb1+JVWVBttA+AK1wNm8G9iG1TK
        FPGqT2S2yQ9UBHDOxWiTAKwX8iF6B4lHJZKNIuS/dOkSigjSA4cD5IF/rF27llS/sIfEfiDJz1cB8puc
        nASsUm1KBdQQ6KldHETXD8gT8BvgHMRznV0E+GVCDFACRNsA+Fd1Rnec634mr1+BRvnXroriIa2yC3IS
        02NCwGBlViscdREEbONfP8Fzl3/VAtX6ybEGVOAi9dgesFs3oCrbvIAPBVVFqskJOKfy6AoFLtpNR/ci
        kCFePfEge/zCD3fNJOmi8gegcbENALpSJUtUj4pHN0c9MgaYPOTnunG7rvgFKoMqJH90hcBc1BW/jAUZ
        ozycAGMwRcz1QAxwRUmA/NE1cyVeN/0cHvjXtAJiVgXkUlMV8CqOEboGJPlZY/Wdf+0MVBWvHgeH2kPt
        ajkHBwcHhxpE165dUXHoNDDJeom/AeruxYqHiNBy+fn5MAw/zcHBwcHBweHJgtNyDg4ODnUGbdq0ecH7
        QguKrmfPnm+++aZ5cu/27dslJSXDvPcQ6L4cxwMHDtyPf2PHwcHBwcHBoU6j5rWcu7/s4FD34KZtHUFf
        64fsXnrppa5duyLepngYMWJE9+7dG1pv7Hz99dfND085ODg4ODg4PHmolftyt6/eWPZ6yvIBM1YMdH/u
        z/091n9ZvSbtK9jiT12Hxx7Lli3TW1J+64ETfececMK/zz//PEcJOb0Gw8HBwcHBweFJRa1ouQtHTnVq
        8LUODb7q/tyf+3vM/3o0+OacJv39qevw2OPy5cuLFy/u06ePXgqPZkO5Cag41F3Tpk07d+48fvx4+1fp
        HBwcHBwcHJ5I1IqWu3q2vP8/PTfoSy8MeuZF9+f+3N/j/Pfa//vzmL9l7/DY4t133z1y5Mjy5cunTJky
        ePDgXh569uzZt2/fMWPGLEOPKe4AALoISURBVFy4EBUX/WMGDg4ODg4ODk8e3LtPHBwc3NdcHRwcHBwc
        HBzqHpyWc3BwcHBwcHBwcHBwqHtwWs7BwcHBwcHBwcHBwaHuwWk5BwcHBwcHBwcHBweHugen5RwcHBwc
        HBwcHBwcHOoenJZzcHBwcHBwcHBwcHCoe3BazsHBwcHBwcHBwcHBoe7BaTkHBwcHBwcHBwcHB4e6B6fl
        HBwcHBwcHBwcHBwc6h6clnNwcHBwcHBwcHBwcKh7cFrOwcHBwcHBwcHBwcGh7sFpOQcHBweH37377rv+
        mYODg4ODg0MdgdNyDg4ODvUUmzZtGjJkyK9//eu0tDT+dXLOwcHBwcGhbsFpOQcHB4d6h/nz57/wwgv/
        8A//8PGPf7xBgwa///u/36VLFz/NwcHBwcHBoY7AaTkHBweHeoQ7d+6kpaV94QtfQMJ95CMf+dznPvez
        n/2sZcuWU6ZM8XM4ODg4ODg41BE4Lefg4OBQX4CQW758+Wc/+1mE3De+8Y3BgwcvWLCgtLT0wYMHfg4H
        BwcHBweHugOn5RwcHBzqC/bv3/+LX/wCIff5z39+y5Yt/lUHBwcHBweHugmn5RwcHBzqCwoLCz/96U//
        wR/8wZw5c9y9OAcHBwcHh7oOp+UcHBwc6gsWLVrUoEGDT33qU/7/Dg4ODg4ODnUZTss5ODg41BcsXrwY
        LffJT37S/9/BwcHBwcGhLsNpOQcHB4f6AqflHBwcHBwcniQ4Lfe4wP1K7xOPBEP8OI/+2bNnx40b1717
        99c89OzZs3///uvXr/eTHeoUnJZzcHBwcHB4kuC0XPKoDv++fPlyZmbm+PHjx4wZk5KSsmnTJj/BoY4j
        cVRcvHhx7ty5SCMwZcqUo0eP+gm/+93bb7+dnp5OSCjp4MGDfsL7jVu3bhUVFTVt2vSll156wcPzzz/f
        pUuXdevW+TlqHzWidR9nwfzI4LScg4ODg4PDk4S6p+XKy8t37ty5atUqSMkbHpYsWZKbm7tly5ZTp07d
        vXvXz+fh0KFD5CTPokWL4KOXLl3yE6qC+/fvr1+/nhrU3IIFC3Jyct566y0/OSns27evXbt2kOOXX375
        xRdfHDFihJ/w/uHKlSs7duzIy8tTTzkuW7asuLj4zJkzfo7QOH/+fFZWltwVwMKFC0lavXr1rl27kqhZ
        uH379p49e/wao0D9yAwy3Lx50y/waHH16tX8/HwZs3z5ciLWT/jd7w4cOPDcc88x7oBxX7p06Y0bN5R0
        4sSJ3/zmN0oC+P/69etKeh9h9M/GjRvbtm2L8cJvf/tb5Fwt3Z07e/aspi3RkpGREb4VVoDNmzevXLlS
        iwPFMzMzGQuCzfi5nsNpOQcHBwcHhycJdUnLvfPOO6i4mTNndu/eHR78wgsvIIRgvZxw7Nix44QJE3bv
        3u3n9sAVSKeytWnTBgnhJ1QF586da9myJTWonoYNG9LiuHHj/OSkgGpq1arV888//+yzz9KX1157zU+o
        ISBj4LUh3zl+7dq1bdu2zZ49u2vXrvQOe6QwsQ36PmXKlJKSkvCvL2eY1qxZo0EJQA5kRJo0adKjR4+J
        EyeSE0VX1Rsmx48fHz16NKaqQhtcof7mzZv37t0b1Y3kfsR3Y6T8FZwAH65YscKoytLSUl0E5MHnRukd
        PXoU+03S9OnTkcRKekzAYLVv394Xcx74F9lcg5oTR+GiadOmKQhB48aNGUo/OT4uX76M25nvmEQAqDj+
        ZIoRJ507dy4uLnav4AdOyzk4ODg4ODxJqDNaDopcVFTUqVMnaC78DLpmgysSDwgPv4CHFi1acB1yTB5O
        Jk+e7CeEBsqksLCQ4iLZAlVhyaFDh/xMVQeiFG2paqmtX79+fkK1ARs+fPjwsmXLUlNT79y541+Nj7Nn
        z77xxhuIH7gvlgCsgqZjGEf5FjciPkNS4StXrmRkZEimCqqEow2uQLhpYsyYMdD3KvFsFHvPnj2jmwCc
        GLOpH3W6d+/eR0ni8efUqVONKsOMYcOGnTx5UqloS4lkJc2bN8/cKz527BjOVxInM2fOvHDhgpIeH+Tn
        5xs5h534GVNzcnKuXbvm50gWSG68RNx26NABz6h+uaLS2YGQS09PVwArBhQA5gQ5x8rwGPrz0cNpOQcH
        BwcHhycJdUPLwfP27dvXqlUrmJkYHvysadOmrVu35iI6pEmTJrBnWPKECRP8Mh66desmXkjBV155Ze7c
        uX5CaEATx40bp0YNqK1Zs2ZZWVl+pqqjxrUcLkJ2njhxYsmSJe3ataPOHj163L5920+OA4pMnz4d1+Ei
        qLBc2rJlS7ivIdOAc/obkgq//fbbiEPcbspSLZ1lpDRYDITRM6TK1JKSkvA30N58883XXnvNNMEJNmM5
        oH6jowBJyLn9+/f7JWsfBAwKTVEnoCLOnTun1Lqu5UBeXl7Hjh0xkq7pCFasWFEdOcfQM0ZDhgyhKiYF
        R88NEeCK119/3c8XCzdv3pwzZw45NZsoSyQTBooH4pbFgeuP4X3O9wVOyzk4ODg4ODxJqDNaDkElyRHh
        d88+C5U331g7depUYWEhjLlt27azZ8/WRWH58uXwOfJD70aMGAFd9hNC4/jx40YbGJYpxjlo0CA/U9VR
        g1oO5wCcMH/+fCSNVAQ19+rVq1ItV1ZWNmzYMNQFcmvatGl79+7lIgIPwkdVMk+gzoULF4b50lFAy+G9
        Xbt2KQk7z549u2HDhvHjxyMXlQFQeefOncN/BdHWcpRFrdGoklBNsHbTOqB3aWlpSCxleAQ4dOhQ+/bt
        sYGmsc2+6/gEaDmwfft25HcgPJYtW5a0nNuxY4dux6kqTjiq5sRa7u7du9u2bTMupRQqjkXg9OnTyoBX
        EZ/ENtkCX6atn3BazsHBwcHB4UlC3dByUOFXXnlF9A6eh0qxvxeHQrh///69e/fu3LnD0b/qgYJcBOgT
        mBw5/YRwuHXr1sqVK0UxYa5Tp06FmnukMfIvKvHkyZNVrVOoQS134sSJ1NTUZs2a4R+5SCdh7svhE2gu
        NHrPnj10xO4Lys2m1GDixIlhFFG0loOp+2neYDEojNfWrVs7duyo+jlCx0eNGuVnqgwBLdelSxfbMNRR
        enq6MQAn9+7dWzL10YA+4lhCLjrqngwtBw4ePDhkyBBjMKA7WVlZyX13rri4uGnTppoOqPpFixaZjxIS
        a7ny8vJBgwZ57UcGul27dlu2bOG68TknWhwe5XO2jzOclnNwcHBwcHiSUGe0XKNGjQy369+/v/0y99oD
        Um348OFqF6paVFSUlpYm0omEaNiw4fz585PjiLt27UIKmh4lp+UOHz6MEoP16s4hJgHqxFSk75IlSwLK
        NiauXbuGnIjOuXnzZjSSUURgwoQJRngkQLSW2759u5/2MJYuXdqiRQs5ActxLCIN5u0nx0e0lgsYhi56
        9dVXvfYjGRC6j+xXH2zlFo0nRssBwk83dWUzfn755ZcZ+iTk3IYNG5jgem4Zh2zbtq1VxQPVibUcEp12
        ZQD5iSj0s59WFSQetScJTss5gPoT8A4OIcHWs2LFihEjRowePXqMh5EjR65cufLq1aukuinj8Dijzmi5
        xo0bi9vBgLt162bf6kmA4uJiJmTfvn2hg7NmzTpx4oSfEA4wb/t+IMVpV9/BE3fs2bOnnzUOysvLV69e
        PWnSpAEDBiDYhgwZsnDhwoMHD6Ll2rZtq3qqquVYU6CwKSkpHTp0QE9iBhZyFKvu1atXTk4OwgBNVZ3V
        h5726NFDFgo1ruXu3r2rF42SjS7gh+nTp3PRT46PSrXc+fPn27dv77UfyYCuQy34ad4PQrBAz5kzZ9So
        UYxL//79CQ+Ghiss5ea7bQFg2NatWzMyMpD3jBdFBg8ezChQ1dmzZ21Jf/LkSfpFBmoms/25Q/W1HAqK
        8Z08efKgQYOoX8Bvubm5Jv8j23UOHTo0dOhQYzZDyXyZP39+VX8A4MiRI3l5eWVlZdKBzI4wWu7KlSu0
        ZcKgRYsWpaWlflpCMJTIRYaPcRw4cKDu2TLuSMGJEyfiT1ocO3bsokWL8LaKgP379y9YsIDdnQyUYlKv
        X7/evKHU4Pbt24WFhdRMNjLru5rEwJIlS6AIanH8+PFoKhNp9+/f37Nnz+zZs1U5YLFiAkqX1uxovr9a
        7lHyoXhtPUob3l8wQTZt2pSdnU1osZ6kpqayfLEfVXUffDJQI+Nef4KnHoJ1mJ0IGsCmpnUYegBgBUwl
        Mjz60Xfx5hASdUbLNWnSBLom0vbyyy8z2SD0fnJ8TJ06FUaInACww7Vr1/oJIQDhMw9Ygu7du1++fBlL
        pkyZIvKKJSjM48ePx5tvaLZx48bp/htFIPHUhq7o06dPenq6uSVFUkgtB3HcvXs3PA+VRY8oSA3UCeDQ
        MEUIMY1G88sksGXLFqNahaSfsYyn5cDy5cvNw3UU6d27d5j7KpVqOVhyp06dvPYf0nJXr16lRVphUBo1
        akSSxkVDgzAmSIYNG4bBthks8bCfGTNmdO3aVZ8pmCIUpwghoY/uBKQISWQgFdi/SVAdLce/mZmZhErz
        5s1NREUa8N7g2qZNG8QJik4VhtkDamSfQMeiTOgLzsRyDGNA586dGxiRxED/3Lp1y/+n4hc7FBX0Lp6W
        Y1DMbXNaJ5v5mlxi0FZaWprGkcUEbYwIZNxxrAkJ6mROobs2btxYXl6OzCPkCCRlABRv3749LDnQU4aJ
        eKBaVYLeW7VqFcJbZTVknOAlFjG0IjIPhk1oUUQmAc4J4GXLljGb/HprCLWq5ZDiJSUl69atY+XMyspC
        RYD8/HwUBWJVCt+OOhQ1jiWqOVbph08M7ty5c+DAAYqrLRoFW7duFfeKBwKABcFYaINgYBUlFYUf5hmB
        R4mysrLNmzcjxugvxuM3ImTNmjXMl+jPfU6ePDl//vyRI0cyLwhjQBD27dt3yJAhBG3ITz0eK7Ams7Qy
        RjEHDjBwRBqBZPapwBLHGks04jdqwIfESaVrIBsuNZMfFBYW+lffV0A56KlMoi/4xE+ID1hBcXExC5H6
        YsKm+u8ffvKAe6FnCDl4GuCEiQNteL/kHEHLcDPQQEHrJzg4WKgz7z5hRkGABBgP6NWrFxwINhC9jRmM
        GjVKzIn8EDVmgp8QAkzpsWPHUhxCBtgXddMAag7ZMpawMkbLDwyGVsIOySOaS06qElEDMG/4N9epmesh
        tRx7CZsxRaiBClUcJTNt2jRmO5u3n68mwJ4H+6SVSOc9js42EEYlVknL7dy5k3E0mSG4NqePhzD35ahK
        dWJ5y5Yt2cm4Dhnq2LEj3rPHAvCv/MmRanv27Mnmp6oAvU5NTVWSjn6xCinVtm3bixcv+rm9V4PAxdU6
        mYkcwyyT03KEE+cpKSk4k1ICeWQD57KKK82aNVuyZEn4HZogLygoYJOAwlYVxAOcAIwYMYLWFY0AY9DJ
        SN9Tp075zVQRIbUcY8TQq10y47F9+/bB5OiRyD1GQlmifzWeGMOZspkjscS0oiHcCzjhOqBO0L1796lT
        pxJOuFoZVBBwgv5ftGiRX68HJv6ECROoBKvII4lITpW1K+fi6NGjyUAlBIwycFEFaQ4nYH/Nsofa03IE
        MMFA3wdUPIMw2AN8CNXKWlpUVGQiE9l29uzZMWPGwJNIJRtTLIn7Rchs5pcaAjSKP8ePH08Y+DligdmK
        tvHLRIFg4Dhx4kQCCZPCPClQJeCEqvb06tWrrGCzZs3SQwEYT08BrtY9ZNZ/WwnTQdYBktg3yU82eqRx
        4SJHQvrMmTN+7joCdhamLR2h45FxioIijYFjSkIMoj953LJlCxnwITUQeIRf4h2NFlnHiCjyc8TPfsKj
        hVkB2EdQbkhxzS8Aw4EFKdWGihASbHkbN27Eb9jP0OMlSilsWHxgL2RQEQcB17EtpqWl4WSWAoJK0wdf
        Jb2jJQdmsZ7XwAziFmAJ25yf7OBgoc5oua1bt4reiQkBSA90p0OHDtOnTyc15n0AFl/ykJmCEHqohp8Q
        Aps3bza3zqhk//79+piWtrp27SrKRSrTLHo/uHPnzpw5c2SnmBkkm22VlZey8DmKKwnwL6uGXzIh2HvI
        L5OooX379unp6Wxalb7jpKrA/ilTpqghAV135MiRMLSySlru6NGjrFCG+IIwcrFSLYcY4LrXfsRR+s0D
        rtOF1q1bk8RwMBBQopEjR44bN4593X5vJ8ONEjCq8tChQ+a2MLV17twZNkApjijDV155pV27draWo3V6
        rarIjzfM3ZXktBzjO3fuXK6bTiEPCCdswH7ssS1nmoT/TfxVq1YxskgvtERyMPLDBvZgKrOPjoeJmQBC
        arnS0lLdSVOLMHgYardu3TjHD7INMPoIGPsHCRhZKBo1UxZgP+cA2cYUw7E4xOuHH5NUSB5igMlOBv3K
        JdeVgYgy4wugyJMmTSKDavAqfoGycAJEb+/evVFuSgIUp2bqN+uDeSEQoCBFavYzmlrScihY1mHWMSMY
        OBHoF4BBQrLNr4Pcu3cPiklOgFfJQE+T+FKrPjIT5RKokNoST4Fz586J+gsqwmogaz2j/Ct0CpuTiOGY
        QMUdPHhw5cqVrAn+pRBgHVi+fLnswbGAc/lW4AqRb99FxLfk4Tq94zht2rQFCxaQhxrUO4DYq6l+PRqw
        VLLcaciAwkajJieoyxxZzxnfpUuXIvX9wh4OHDiAE8hMcTxADQxugm+Vb9iwgbCkTnKSP4nfNKo+NEYY
        yeYFgRGhNwFPN+PF0jvvvLN3714WOuzHIQBf4RyVpTvyXmpqKtLlcbsF/b7jhPdKOY077sJXHCF1Ce4c
        1CDu3r3LoGRmZjJqDJ9swBgwa9YsP5ODg4W6oeUEJhKEG4ojkieIt8HYCPHDhw8H1uXqaLmFCxeKdlMc
        goW80XUm+ejRo6FZMgBmFv0hKxMeiq8MNA0xzcjIEGvfvXs3GxJEU6mAqpirKpgY4ohUiEnUAK2kzitX
        roR5LrFKyM/Pb9OmjTg6bdEo61rIz6erpOVYsNgs5UwaolQSWg5VZsg0exJ6iQ0PL3ntRyQT+5moPMeU
        lBRSYbQMhCmFA7lixpQiRI6G9fr163AC0xZRtH79epVily0uLkZOoyLsZ7pqXMsR2DStJPpFNGZlZZlP
        nXEvOw1FlIcMuFRJlYKBRopgEqWShrHNgCtcR2hheRIPzoXUcjiTbHbruJT8HD27/M99+BcwTGYUbC1H
        KY6IN2YlFZIK/8OBjKBShaZNm7L+6FbGzp07UX0yjzzkhDqblSeg5QCVszrpM11GmYZUVqCG9u3bs9oo
        EtAzZunAbIQ6lXsV1wxqQ8vduHEDtSCqIaCvJk+ePMUDAhVGwhKHlpOHAVGBFEfgEboC+fXFxSphz549
        8FGIjriOTriSWCyxDojQA0phxoQJE+gCNrC2Y4xJwvLZs2dX//N4lhHWOlQcoVWlOzzIP7YtLDF8DvpO
        cRyLwZhNbVxhuTB0nEBCuUmskh+3y350r27pcJEkeprE3HwfwYqHzZGB8frFCOIHnKAwIwlXmN6xaHBk
        gbUfUmDiw4819GQjM4I2wSehpJKZbNRG5VX6jkYNgp2X3YpxlDHqoMAos3T4+R7GoUOHYAgKA7Y8Nim8
        NG7cOCqxa2CyzJ8/32xDDgYQAClhuQvPc2Si2Xt9bYCAZE/XcJvWgSKWKeznc3CwUJe0HGpqzZo1PXv2
        FPW0mRbnXOnRo8fmzZv93B6S1nJwDnZ0sT1qQMnYeiknJ8dwNTKkpaUZmgiwE0Jm6Bo5YXL2jsK+zgpr
        qCQnTFE/LSHgo/BjVUtZgUkORTh37hxLQIKPGMMDLkud8Eg1BOCaEJGQGz+uCK/lysrKhg8fLmfSF0ol
        oeU6dOgARaYgTigsLGT581qOgFSY8b59+yiV+ENoFmh2NZVi7KhE9xAYLISTRpPaunTpQutUleCDzJrV
        cmRg+TbhBJgFKmJw9OhR8xMXVIvYQ2/4aQmBLu3du3eTJk2aJQWikSNaBc/INoO2bdsWFRX5zVQR1dFy
        GIPuwirGXRECyMOIrFixQgUDWg7AiW02s2vXLuNPjlQV0AbwJ8oK1IxAMp/1BLQcGchsRzXxY0aTVEy1
        OSKzDNYl28jWokULZrefVhOocS0H0aRH8AxxDpYy+CLrsFku0Gx0YerUqfiBZUQXBb1LBikF6WTmVvVp
        xpMnT7K0GullSCpXsCHB94hsLccJ/N7M0MOHD2MVIadUukOG3NxcpVYVOIFlGRHFlgGfxjBVyBD7OSoD
        nkRFyB56hxTUaq9UVqfi4mKuIPiNw3HLnDlz5A0Zb5KojVUOGwAndetujK3lcAgqjjXTT/OeuGbbnT59
        Ov1SHvqIB3CFHVe4S6NABlKZ+PZXnQNglVAlFEHnM45+QvWA24kK1oSQ/t+2bZsejJTNpoMggZYrKSkZ
        5L0fC8tZ5/XZH3sK+dUpvwrPmYGJ6SAg5xC6uFHu4giIMZvO1TjYzRWitMXRDldOnJZziIm6pOUEVt78
        /Pzu3btHaNTDDBIChNITcReS1nIFBQVik5Rt3LgxtMBedvm3Xbt2ap0j08z+7gG0AHVnUhs2bBj9ybpN
        FjGbKeonJMSNGzfoO1PafkwL0Ar8FVbERiXWWJ2HZ9gvDc3FQmrOy8vz00LgEWs5gbIG/iUP1Lxq1aro
        1yriHxtcQcFCsFSKXrNxHjhwgOtwdIZPI6Uk/SQ9nE/1RKp7GDWr5SAQbNjqF60TKmo6AIynUbKRB4lV
        pa+GVgf4ByqsWSbjQa9evZK4x2KQnJbDnzAeBKQ+N2VH5F+TioUE9tmzZ0kKPGPJBDcyz4AuaDg4MuP8
        qxWAmlNKxamZwTKf9QS0HL0IPOjFgJqXrJINNWJngHfCPl9++WVVzkwPfB+vmqhxLYecyMjIiFDCCrbx
        yL6dz1KPthHRgeAyKPoXQL+Y+H6+KAS03JIlS8w9cMBSj06gErqjmhmjBHdv4oF6Tp06xfDp3ogB5AwJ
        4WdKCBYHgkHcHWNYKiHolQoAGmUDol90gbIoAVOEpUzXAS6qtKrHCgEtN2XKlEOHDvlpFWCpZ9x1g45s
        HOkvCtZ8yonkY3AZAlMPy2n0Ms6V/fv3s6WSAc+DmpqGLBSlpaXoAYxkBYu5gwSARmUc6QtmT506FXJC
        RKmDCbQcPWWVY74HxCrrT3p6uol/QCVHjhzxkx0eBuOVnZ2Nt4kBuYsThi/BRwDVBAFpVgwmMuM7atQo
        xSFmOC3nEBN1T8ux9j148AC6xgI3btw4CByMR8QI2gT1Yd/1s1ZDy7GDUsRjU8/B3f2rFTh37hzU2WgJ
        8kAc/TRPFbBVK4lKOnXqtNv6ZXMhOS1H39mT2IBZpukaBWUklXDknCtUy3KfNJ1ikTK3WagQf3LFTwuH
        Kmk5NmOWLUN84cfwbD8tPqK1nA2MlzeoDekbLeTgBFxnBFkcEQP0kZz02phBWbY6aTkAo8JI+USg6Vde
        eaV3797z58+P/jpTzWo5dlnzwQFFJk6caH9wYJCVlYWEw3LQokWLR6PlGCx4sDEb0Dq0I6baDI8ktByZ
        hw4dqs/pDT1iuyWDZ1fEdQy3vjb5iLWc/V1KENBylLUz1GktxzqGQgh5T7iaYBcoLCzUR+a0yzIF+Wbh
        FU0X640nwBJrOWresGEDNVOtMjCg0bIhAYhApi1uISaJXjUEqBDDuJhAZ9pAeklOUJBpRdSZO2wJELgv
        R1tGsxlVoAqfPC0HWPAZUDpI9wUiwUxP1gTWRgZX9ZCNf2PeZsHb8hVtMWTVf8Dyzp076GoClWqxij2F
        hS7MgBIGhA07PqKUSthPKYth2M9JPC1HzWSOea+bGTpkyBB5AFCJ03IJcPPmzZUrV+Io+RwwfIxj4EO6
        mgLsjtGBnxAtDF/gS5tOyznERN3TcgZEOYs7m1OHDh0gPeJGMDZWPT9HsloOYcbMMWyMk86dOyPJBM7h
        1mJyykCjrKfmKerAHR7sMarAgMU0CS1nwDLNngRjZj9DNmAAVUWon2cSRBAKO23aNJZyv0AIsK9nZ2fT
        L8/wiOW4LiUlxeyCIVElLVdcXNyl4hfJMb5r165hPv8OaDkKYioNyRVNmjSBiKelpZ09ezZQGyS+oKCA
        QWzatKkyU5ae2t4D/AsHskcN8sfyynUGSzkF3IXNq1evtrfMGtdyel9LdBEbMBK9qgfUtpaTWILEE/ZS
        HcbsYcOGsRUpW9JITsvBegMq9969e+htjTJHIo144/pjruXYrW0th/ry02oCj+C+XPhXt2dlZeErVks0
        GKWq9Ig4ikV3n2iUCGFEcB2zQ2ZwhXiI95hlYi1HeBOBzHdRNxbwiRMnRq/hMUHZ0tJSHGLf0qEeWqGe
        yZMnb926FeUQ5ukDkJmZiQHqDnxuz549fkJCsD6w5kDQVZZG9dVfrqd7L1vnIkl0KoyQCAlkBtZCcKmW
        AR03bhwKimDbsmVLmI/nwiCklgM4CjPoqTLjf3Ia4crmy/YhJ3DE5sAMFdj7SAWMHZup/XFtVcE2BFFJ
        TU3VDUPqxH4GiJ0xzBDQNFseO742dOwPo+USAP8k0HJYyzrJlGQcmZ76sPL48ePQJxYrrgNO+LesrEx7
        Ab2gToYbp5FKqblz5xLqLA5ele+B2phuY8aMIduCBQu4QqcIEiKWgCFsCFemz/r1682HsMxNFgcmO9VS
        iiZYN0zTjwY4Pz8/n7CR2xlBjrNmzdKDHjUL5inqHT6p4Q6p5TRqmn0cNeUrHbWSkhKGQ6NGKUaNsYge
        tVOnTmnUqNweNc71JUxGbf78+YFRW7duHRVq1JhBjBokpwbXHAcbdVjLCezfq1at0h0JcSPYm5+WrJYj
        G5lVoQARDIA6/bQK6m/W+oCWgxSyuyvJoJpazoAJs2/fPqai2CFVeQwwgp49e4bRRZrVuJHlEoVDQepR
        DWyc8E5lC4+QWk7tsvqTQc0xUvgtjP60tRwFkTpFRUXsJVA3knAIq1W04GEvxxIy43YBd9E6vBlwwhXZ
        zAkDGmBvLGe5ublsxvoiFpDZgGhhqzZsvla1HCLE5p0Gy5cvV9CCWn3GUgPH3gaHQDbTnLGZLSck5U2M
        kFpu79699FSeITPsWe94MCCW2P9IIg+u69y5M/si152Wq1ktx47uccIIWMpYdQsKCsJ8aD18+PCB3nsI
        YZMw3SqtNrt372aBIjZokaNo/dq1a4cOHcoVQM3Z2dnKHECl9+WoXPWQAUkAF9G3Z+OBScFiS6lFixbB
        bLBHhlF20KBBLBrQU5YmmF/4j8ZYryCLspMja6OmXhhAqoxckYaEVMGVdbNRhq1cubL6vAr5zZoGjWPu
        Y6Rq5kTnHEeNGgULZ0ZLSoXvQjTCazliEuJIUCkzfoBWGknJSssMxTylYqQYubGNEyKEUaMgoEdJL6es
        k6w5eAD/0BaNUiFHgpOxYLUMMwTEjNkWyY8zq6nlYOHGOQBLbC137do16tQ4IvnITIt4G5vJHBlaLyb5
        F4IOk2Ha4h+GhusadMrSTbrMOhnYiJkFrA9US3E8jOeZMnqGEFCWI5VQln2TUaBp1kMmowZCGSjLCFJV
        +NlUfTAESCz7ji5HplU1H0KJBjPF/vgjpJYj5u1R27x5M0PDqMlgLgJOcB3rgBk15BlF7FFjG2XZDKze
        SD6WNY0aR/a4eKPGXsBayqjN8X5y3R41jo9+1OoP6ryWA+wl7dq1g6uJG1VfyxHNFBHZolp4eUwYukZm
        WmGeqziL18SJE1UDxxYtWkSLGeYGWs7YzDTwE5LCuXPnUDLMIn2NUGohpJYD0EeMx4diz9QAWJrj7ZSJ
        Ef6+HHOedUE5aRr+CgUxn54mAMuBreW6du0a5qNftg2WNtswljZMzc/Pz8vLg89B35WEMaw70bIER6HY
        t23bBnehUWUGjCAjbuggCxmVK4nmqqnlaBHqTze5jmEsl9FPdQLogpQVIORChnpVIbrDFssehvI3zuQE
        Xl4jQg7gwDBajk2UIZNnOLJtoOH9NA/EEjOLekjFQmaHnj12Wq5m333CeDGV8BJHgM+Z18R2UVER0Wto
        aDSPJxuDSxGmGwyjSqyIOUtDNArQOfI/NaBbqI06uc5AYJ7y26hUy7EeGrpMBtaNxC+HINKgvCwCNCpu
        RL845wqEae/eveZtJSCknmGaU5zWqQcz0HVXrlxhLdVPsS9fvpygXb169a5du6JvK2EtEU4XxLGoBErH
        kX8xj2NKSgp0LaQl8cDIIl9xsuoENEGjDCvN8a/8AKCM5Kwmhwuv5UBxcTF+k+sAYW8epKQelkdYqZKw
        meG290piZtOmTRpHQF/4108LDQYFPs2mLCIO5A15hllPKvy7qkNQfS3HjoPQNd3HRVSi2zgC+jM9PV2p
        mIqf4VEaWdg5BeVYzzf9GH3WK3xFvzT0nCuDirC9+vV6QEVQG9WSgSNRTSVSCGQ2ZVU5+yyZOVHNHDnH
        Ko5UjmG22ZVCP7LPxEkCzDUmXUZGBu3KMwAzMFWC1m+jFhBSyxHejBp5MIzMLIMJRo2FBa0Vb9S4GHhF
        AotMyFHjyKgh/DiJHjWKVLqWOiSHOqDlWLyOHDlCQNvz1l4BWYgN84OxEWp+QlJaDkrdyfv9KNXWpUsX
        WDLzxwbTIDU1lbhUowDuznVRdo4UoVElcRL4dgTGs7WYX+KmLaLcT6sGbt68yZSj6W7dusEFO3fuHEbh
        YAwrUY8ePaQxZDZrR9KfNoXUcmxjmGqcQLuIScqG2dsCWi769+Vigo2f2DDNDRs2jE3RNAcbY/mLWBxf
        yxmw2euTb1MbPjdComa13KlTpwgPjQtHQh1WpCIGDDRrpfJQFqmADX5aTQOKCRHU1wtpUWD1r/6jlQYY
        H0bLoZog8WYIXnnlFX0dzgDSRlCRCnA7mwpO5rrTcjWo5QA0gvWQKAURjuORCWYQ/3K9oKCAqRfz/gMk
        g5zkJzOUyL45kBhII7xE1DE0lM3MzNRExnuwZ67IBoVl9JKSWMsBWBHFqVwZ5s6dm1iH3Lt3D8eSny5z
        BBMmTIDF0nqYRTgmUIDsX+JJ2MDqBGsXq6NfIkkkIW/ofuCWJipr37599IJsdIGtiiLic6NGjWJFSnyb
        MSQYVpiZqqUVjhiZnZ0NEWQUMEzXZQA5q/lAWpW0HIukMQyw0ppFmHhgoTBJVMUctG/pM9ZEl4YSz7Pc
        JWgoGrqbtGjRIrYYbKB+qtI4Dh8+HD0AmQl86hQe1dFymgilpaW4EavUfQwj/s03RADTGTquVGwmA70A
        MHj0DHOBc5OqrlEbc5l4IwORL9eRgROWaCo0cxCyoRvXKk5VFFcM64lrr+IISCWb5inXiSvsJJ5Vs1LZ
        hWMuLDGBr5g+1JA0sFatG6j7dDn62auaQngtx6gZ52jU8B6xzaAQ//KkUqNHDXVnjxpjhKQ3o8Zsijdq
        jAujo4JK1ahxQp2MKaOmlUqpFFy3bl34UXMIiTqg5W7fvg3ph9OMGTOGhYCF0n7egO2BiUSqqA/ciCVe
        qSAJLUdoNmvWjPwAdpjgxQNsh9QsTgYvJ0xFJeETmzdvNkkYwApuNgM2pOLi4jZt2igVYDOTR6k1AkgA
        85OJjSX+pfhgDWKm2dJr5MiRAWZQJURrOaSXn+aBDMePHyeP/EwejmgDbPZzVIbktBzqlDVILVKKNc6U
        kgzu2bNnxOKHtRwxBtWmRWy2NzwCD/ZpbICdGyZas1qOgCH+jT85mT59OoLKrLPUgLTAgcaZ7Dohv5BT
        VWASKzhNmI4Dojc8Cw+DPXv26FFYmsAVrP5+wsOgj2gw4xnywzNYH5SKW5jvXJeRgFQtHU7L1ayWA0wW
        pgORIM5h9nXGjqmEQ5hf0W8hSlrLwW9YVCkLqASqYaYD0pGLNK3WYRLRt/oDWg5GwixT0vXr1496b0Cl
        OKlURSXUqdR4oAlYC5nFhziB+LIdlJeXU6GfqYpgrZCWwwyvN5Efu8NaUTS7jzTKEmGvTgKdKiwsZEdj
        nSH4WWA537p1a5jHXysF8nX58uUY43kxwv9QKeYl0sxNxkgEURmwk+3bDFMSqJKWY8U2ggfQd7ipn+Z9
        FgCdwHskcSSE7I+iyElZFaSDLA4hBTmLAKOGjpVyVjxwZLxojusbN260zUgC7EdJazmAhayKJvjpO3ay
        M9rc2tZygDzMNcJbrIDFithW+CmVI+NiXiAMo0C/kYHrHJlKzP2YWg7QC8YRRiQDCM5Ro0aZUaNywNgp
        rphlq1atMqlYTsiF/4AAaY276HvSMGYbYB4X0VpJ/wBPpUhCy8kw+muPGgVViVI5atS09BG3zFZ1kCOO
        IsxMVNhaDmDG5MmTzaht27aNqgKjxppDnYw7o5abm2uPGgNazY91HKJRB7QcyyjkFUoEP27VqhXbG7Ke
        tRhuvX37dmIISgTp4Qir69q1K4Hll0xKyzFtVITaOnTokODhClYuVISa5tikSROz5bOTtWjRIkLWvCRa
        Z4VCYsESmG/ma2kCfI5AV8HEoMVTp06dOHECKh8PZMA5ENkwz29oSzO8E6vw0pYtW5j/1OPXWAHaZR+K
        JkYBBLQczmQms5RQA9WybZCqO1pAjWIAAjKai8RDcloO57NnG8MYIHgt9qDJOSFybP7Nwi0tBwFdunQp
        V+AirEFcxGlsLWzMHTt21DiSykrHdTVUs1oOh7OSUqHa4khzRCmtsCDSqTlz5lCVUjlp06ZN4LGWmgK9
        YFOndeCZGRHquAUb/BzJ4vbt2/RFgU3g5eXl2d+ARWPDPr0YjKSaj3IAPpemIidHlE9KSgqjgypggJiS
        npkRt6DNzGcKTsvVuJYDBLD5EoW2c23egCDhX4YVwuHn9pC0lqMhNUFxKIj9gJxoBy2qaSI2WkPaWo6c
        TEPmNZyGPYXFCquUpPoZjkrvosBp1q9fLxoqw6hWtrHp0Bxzx0RISNhaTqBybJvgvVuCtugCDXFdLbL7
        VLWJ6gDz4HPGjdhjVIr2HcQee7EsBOSsEvOORpW0HGGAoDIMkm3XFlEMB7LWpJKTXU9JrLcEgxlHGjLr
        RqUggHUvTjVTFk7MIC5ZsmTPnj1mO650X04AIq06Wg5tj4UY5vU7YiF0PxA2tpaTExDt9iQqKSkhDk0f
        2bs3P/y7vuyYKkgefEtkGlUQ0HIYo31WYFxsqUm2tLQ0e3Nn6aYINZPK1GAZD/8AEROZppmSSYBJx9FW
        LEBmsBez4VbKi5JGcvflAJ60P0iCf9qjRp2IMT/NA/lVljzEsD1qAS1HPYFRI0LsUYOT2B8YEWCsw9RM
        KqNW1RvdDmFQB7QcccAeYMgN3AhmBgcCnBhaCWBXLJp+MQ8IlfBajhUW9gyJpFpqo36iUx9axASr29y5
        c40B8FpiVEQTm+FMslmpxmxOuMg5RJN/SeLI5FGdiTF16lSK0yOKJAbZmDmVfl+OZaJTp07qr4HsjAYd
        pL8JHCIwsW0tp876VVQMmXGLUuEBVdrjk9NyaEUCidZN05xTCZBJQEn8y9BrtWKJJKjIY/LH7IVeH6yG
        wms5/BlPy6WmpprnvtjAUCZGzgFjCeBcFwU2v+pwhXhgV2AFN73mSHizw9UIfYRINW3a1AQ2XVNfAA0B
        XQec27sI/Awf2vnlGWAPEAWhF2Y66OMh1QZqXMux66sswIxqarkEjwYkgdrTcgLUCkoB37JJD+Bf9vjA
        p9dElPgB0y28lsPD8HiKiHNARv0ED3AIZhzXqZaBA5CYwBsybS0HqAeGwRWAPRThosqOHz8+/NNT27dv
        nzVrFp1SWaBeUy1L98aNG7ENzgfCzFBby1EVJwSGkRwsmKJuQBkQeIa6QcIISOI8HkgN+KSqoDvwM7mL
        DhJX0XSWxY0tOOJij+HhhPByPRrV1HJmEQbMMsSVScV+fKuv8WjB13VaQa+GVAuMKULFhDRuYV+D4xKu
        fo6aQHW0nO6Y0VmZB+gdMRmIRlvL0QpujFazDKsiM6YBK1euJJWygJnI2muaMFpOBjCIgbBhTIdUvGOT
        ps1zFgK20QWlUgkrLdPET6t9sJLQX7UO6B3GsPWHnEqQQ/YgfwY+jAQ1JKHlyIbrdkW9xdesJ/QCLuRf
        rUBubq5GjUoIEkYtWstFxswLG8N2BOaI8QxNB+gcVBlHUZBUjVrtPZJab1EHtByrwLlz54gA+BnkRhwo
        AJK6d+8OUQiolyppOdYUVl4klkeiIhoj3rQRiOZNmzYZqzghUtkhlEpthK9al5EGXGEyzJs3T68bgc8x
        f1QqMditY1YYDaqFjFaq5bAfIYTlfrGEoE4275OxXrxhgy0T4RGmTirs2LEjtNj+CCcMAlquc+fOYbQc
        OHHiRO/evWOOCI41363CMNYyo+UyMzO5oqRoNGrUiGXR/tgyWsuZW47JaTlAsM2ZM8eWcwFwHXVBheb2
        YA0CycT+apxAW1gI+WYT8nNUD8XFxYGb1fGA9wLbADYgKXFmzOJcbN68ORPf/lSeIjWr5RisGtRyZqBB
        ndNyIm0wMFwE89b+LTCncJQYs5CcljPkBrAirV+/3k+oAMtahHFUALEXeOQ4oOVigspZb6t0z1l9p4M4
        ma6xqvt1VVBnWA57CutDmAcvbS2HtRjDRZt2Yxv+VAbADDVBzhxh7xMtiwkyV/NbK3l5eVhFE4B+rV27
        NlrLYY9RBfjzUWo5nEweeqr8TCJ7BQDsU+ZeATmhqjB1rrMZMX9VCh3CymCLwARgaKC8uutFtbiFISss
        LAxZPCSS1nKwbexRKYCFI0eOpMvRMRCt5aJVAQNhVAHbvX+1AjhNqgAQJPG0HKkMov2cBWDbNaoA/7Na
        +gkesI3iSqUSemQe661VoLW2bt2KPSaiMJ4pZn+wWCkYKS07VGKDquCu8b4WkbSWI078tAoYBU5qtJZb
        tWqVRo1KNGoxtRwZoK+Bz3APHjxoa7kAS2S5o7hso5JHrMDrCeqAlgMsBNAvYprNQM/CwZ8ABA5WROgQ
        dsx5e58T0tLSyAwxInOHDh0C95QDYCtiMsCfyA+F6tSpk956lwAw+NatW8seFTGfmwLCvaCggOmBndSp
        bGyi27dvJyk3N7dZs2aUIpWV0S+TEHRf5lFVAsgzTJtKtRxbPtoGG/ySCfHyyy/Dt2yBERO4Eb9hp1/M
        AoZBbVu0aNGjRw/4N9s/woNVMnrgEoOFA69iNhVSLT0N/3wmWzV7D0wdF1FWsUENhBBdo49cpGa2ZMSV
        iiC3INw9e/Zs6b30n/wAIk7s0QuWuYCfS0pKlI2q8MPq1atNBsiH/ABIYs8zKzjLn1pXEgQ0QPiIGcYr
        JSUFS8gpy2Vtt27dxJNwflWdWSmYWay/uEvNcaTv7ChsHn6OamPz5s2vvvpqmMDGsdGvbWBxWLNmDRse
        oWU8D4htdBHEPRBjsAfci5PJiSc7duwYeEYIEGCkCuxS/tUKoBYYfcqSSj0Ej/mcEsooMUYSNrA+BG5l
        nz17Vq8aUoZ58+bZGTB15cqVjRo1IpX6WSJq9onZ2tZyAt6mI/SLucbk0i6OSxEn9puQktNyrNIiHKoZ
        ggUpZ8IKnLMjkCSQwX78T7C1HBmojX+ph4IYyWo8Y8YMFrHwq4oNCBB9Z9agZrGHCqlfDXHknCuJPyUU
        Ymo5GwQShMx0hJqNwTBmuiPuFQ31d9myZdVZK9By6hpgU4M4RqsCZEy69UbER6bl6Berrh0G/BvgyqzJ
        2dnZvkc8bPTeRM2WJJ8DLlaJrLNlIJDwLQUpjmcYBQj0G2+8Uf0H0YXktBxbCfxbPeKIhRJy0fIb1IiW
        Y4AikfFEaDmMJzZ0v900zbBWdUyho+o1Rxt4EjYYbz91Ws4hDOqGlhOY8+xVLLXHjx9n0WQicTxz5szF
        ixfjfaRBfjKTE5AzXjaBGUv+srIyqmXLIX+Y2w6sNTIGcG7fnwFsGCgE2UC2EydOYK1oH/FNxNMQ1wOf
        3McDOgTz1FZiUCeOqnSrZkKSDRv8YpWB3TTm6h8AfjNuDwDDTp06BQuhqsByEB5EgjFbPQ1jlQGet6OI
        QYHbaeA45wo1c8XsMbgRLkgRxlfhATjhX3oBb1M2AwbdVM4JzZmBoMtqAlCDnURUmCRK0WI0N+KKpoDJ
        SSucc4XrlQ53csAPDJkJEvU9QI6rCSYmc0H1JwZNxwwbfM7swE4zQLiFQYxppwZUOakz5kynLEnKEP35
        BQYz+spAPeyjxvkaI5KUSrbAOBIwxL8yADLbGaiHqDDjS4/410+rCTwaLWfABGHnZhcH7OJQQPsDsiS0
        HP6EkaiUoJptwDb8NA9kgDrYS4St5aAvc+fOJQMXiQTAalyd1UlgHFlSsBZlDvXBKlr03BARn+PHj/fz
        xceePXsM96IslfgJFSCKoNF2R8wtIMg3raAkUVnR4DpmFBYWRq8w4WGeyAKwt5g/J8MsgzLKPDwQXq7H
        BIMSUsuxmMCb8QA56SmZo3dYrGV+eQMSoZhkXrJkCdNt7dq1cinXGSau+AVCgDWc1SArKwsnUyHtqn4c
        NWpU5Hf2ioqKqrlyMmRV1XK0SISTX8bQO8yjm/H2C6flAli3bt3w4cOxlkZlNhOTtd1PDg0CAG+w6Hmz
        8D1QeX5+frx13mk5hzCoS1ruyUa8hdXB4clGnYj8J2Z61qyWC+OW6dOnix+wi0MB7ScXktByBQUFIrJi
        FfCGaG7EFa6T6lGLCHtYtGgRSsCv4mEtx4n9HssaB0waPQAhmzFjBs1hGAx1woQJfnJ83L59G59IkMCx
        xo4dGxAkFy5cgMcrA6CbRidwgs4pjY/9+/dfunSpOlHNQOA6DUSA+RmgLXU7SOY9Mi23efNmEwCYhySL
        qcxveY9ni2Li4alTp0Jn58yZo4JcoVNXqn5vliIIjGXLlkHBpeioUBwa1g4XR0Uj+ar0+aNBeC2nwUUp
        wdqVGWAPSh4hl+BzbaflDMrLy1evXo0Ox1QMFpiJyYUxCgdr/RloYe/evTQULx6clnMIA6flHBwcHOoL
        avy+HKRw3bp17M327m6oGxfNLg5RgAZB5pQEktByqd4P2VEhBSGUMOaNGzdCd2wUFxdnZ2cj6tQuRzi0
        TQoDWm5J1O/L1TggrNB3SDz20yj2+AkJATmTf+gyvgo8CXz06NGUlBSRMwBR00f71VFo4YGTbYoJKUd8
        KskYgGI05sHwUNTV8XNILYefZ86cqXYVKsuXLw8IBgGqytATAOoCIgeSOnLkSBM2MNRogRoSKLo9e/ZA
        iEWgMZgmOHKORCEM1qxZE9OqxAip5YyQQ7apLxzpqYRc4OmhAJyWE4i3zMxMLSOyluP48eOjH/KvVbCg
        OS3nUCmclnNwcHCoL6hxLQc7h3KxPSNUUBf2mx6QTBARcWV2cbJBsu1tvqpaDn6MClJtMAZYbPQTzgJN
        z5gxQ5UDTux38T16LWdw5syZ9evXo379/xMCdoXTxK7owqxZs85WvCAO6rZy5Ur5Vg5BscS8+1RLYLAY
        MkaBpmUe/SqveIvVfe/N/mlpaeKOMhJymbQ0AgEtR+v2c263bt1iWGnC3AcWxo4de/z48ZjtIjBKSkrg
        oIoTjlhLETVBW9V/bQmqCZMIMMxQE9Qvqk27yM5KhXd0BiIZgUElVEglzGg/4WHgkE2bNtGQctIuPeJK
        vCkD1JbTcuDSpUuYTROAttQXVjkCxs9RO4ge7sCHJnPmzPETKmCPmqKXbIya03L1Ck7LOTg4ONQX1LiW
        u3jxIpRLJBhmBo2D7pw4cYLdGq0l6qAtnHP0nl/MQ5W0HBx048aN4hPC2rVr/bQowKFJNa1TPx03zyjW
        uJaDTiFj9O3KBCADLAd6GvK9u/DaqVOnqhfqcnp6OrQJ32ZnZyNrlQS74hyZVx2lVFXcvn2bscYkWvcc
        GRlirMI2+rh161Ysl5PJQ9LcuXOjv7RWJdhajkZRR6hHIg2vHjp0CHmMqIBHmgghz6hRo6Jfa2SDXkyY
        MMF0gVI6AYsWLdJ9zuoDubJ9+3aoNvbTFhYCTMVLYYbs6tWryFFmByF0+PDh3NxcKXyM1OcjRJQCDHFo
        pNpe7wX69gREBnCR0VFmA2qmfvPUpdNyRJpiW/GgjqSkpET/NkNtgJjUWAPGJScnR9Jd9uAxRlmjRvAH
        Rs0YzKg5LVev4LScg4ODQ31BjWs5ZAmbN/s0YJ+GBPAv+zpHmIGuAxhAZmZmQDIFtFziH/K6devWrFmz
        RDUoBZu0H9eMxrlz58jssYsIuYHHQBOVFNBy+KSaWu7+/ftQLlqBddH3xBg2bBjMxi+ZEHCpgoICEXe5
        EZdSA77FbHMRIZeXl+eXeYQ4deoU2hIDbPM0+tgs5ifQZVipXyxZwLAZRI0aoFpa8TwaAeeGiQpkjv69
        imhkZWXJw369FbK5tLTUvJy2RkCQ7NmzB03LeNEKNhPAYbRccXExzmSyqKcmdAF2kqTrgHMjmCmlhgxI
        1egEQNCSaoRKjWs5+42pdBkRTiqWk4oqiNZysgcwLtFajuJKpRK0XI2rAhY0VA2VqxXs5MioSZ9E3zer
        cRw6dIiB09AAraIyBuA0XScegOk+nkGeKSd5GLWqajnzq4BUwqixoMXTcrjdabnHDU7LOTg4ONQX1LiW
        Q2IhJCDr2um1YQv6l70fzpGfnx/9xBrXxZmgDmi5ownf8X3z5k2RPOokf1paWoAxBACphTSQWUUww2i/
        aC1XzVtG0HQkgZiQqk0AWgzz7hOhvLwcKgw9Ukds6MqIESNCPrFZGzhz5szs2bPl3oCF/AtE3dAJAcqe
        BK5cuSIeH2jIBmYA4oTwCHnHZuvWrSNHjlQcAlU+atSo6hscE/Bj5MqcOXMg1vv37w+j5TZt2kTM0C9Z
        mABkM59KbNmyxYiiSsFM3LZtmwpKFXARV9AoHB0qryQDowpIjdZy5g4PQI8tX77cSCBkIbSe1EhweHeZ
        krgvp7JUUuP35RByWKulTDZwPm/evIAZtQr7d7cTQEOz1/tRRIBeYiC4juUksWJEf9SVWMvZo0YgRd+X
        SzBqibXcjRs3WPFIoiyVOC1XG3BazsHBwaG+oMa1HGDLh0GuXLkyNTUVUggVEDNG4KWkpKxYseL48eMx
        b3FAxcgsAgG3SCyoICuqGVDE/m2DmID20TQ5VQSqYdgqDalRVQV7S+J1hTbu3btHJeqLmksAGOrkqB8Y
        SIB33nkHwop/oGLGZirBe7m5uagpP9/7BMT8nj17ECfjx4+Hq6mP2Ikcmjlz5tpYvyGeHAiARYsWmRiw
        Ic+j9GbNmoX0FfMOeQuFapGjfkWeb6ktJycnwffKqo/b3o8VhbQQVUb0Qq99E+NATjBfWUQ1MQfDBCR5
        GDhzX+7mzZvQevlZYRb9jhnYvAqCrKws/2oFMBh5TFlshtlv3LjR9JSqWBO4SEGaWLJkSWBlQMmYKALm
        C6ICwcZEUAYqYeCqf7/XANdlZmaqXYEupKennzt3zs/xSHDkyBHa9S2ID3zLFDMvYmHU8vLy7FFDXynJ
        YOrUqaRq1Oipf7UC+lBDo8bRHjVMMqOG86NHjQz23A8sSkQ7OtOMGpO0BkfNQXBazsHBwaG+oDa0HGDX
        R3LcuHEDBfX2229fvXqVI+dcYSOPd/MBGk02AS6S+B4FqVRrUOltE/LTtJ/bs8cUCVQFQUzcdKWg+2qL
        VlRnApAHt/glwwE5hJG4y6/CA5Xg82paXiNA9jB8AfP4Vxb6maoNnIwTYnpYF2kRM2jRcNAwIDN2qh4D
        RrNKlSSB8PUTt5gUJrSYcSYeYNv+1XAw7BzD8KEu0ihejZa1GmuZhK/8qxXAYDKQqgz2VKUqvK3rgAEN
        +IEMJhUEwpvMDLEycKSqmvqkANAWlV+5ckVNA5rgSm1HQgABD8QDeRhuMzQaNdsziUcNz/tXK2CPGjXb
        o4aTA6PmJ1SADEoSAoNSq6PmIDgt5+Dg4FBfUEtazsHBwcHBweF9gdNyDg4ODvUFTss5ODg4ODg8SXBa
        zsHBwaG+wGk5BwcHBweHJwlOyzk4ODjUFzgt5+Dg4ODg8CTBaTkHBweH+gKn5RwcHBwcHJ4kOC3n4ODg
        UF/gtJyDg4ODg8OTBKflHBwcHOoLnJZzcHBwcHB4kuC0nIODg0N9gdNyDg4ODg4OTxKclnNwcHCoL3Ba
        zsHBwcHB4UmC03IODg4O9QVOyzk4ODg4ODxJcFrOwcHBob7AaTkHBwcHB4cnCU7LOTg4ONQXZGZmouU+
        8YlP+P87ODg4ODg41GU4Lefg4OBQXzB//ny03Gc+8xn/fwcHBwcHB4e6DKflHBwcHOoFrl692rt3b7Tc
        N7/5Tf+Sg4ODg4ODQ12G03IODg4OTzgePHhw//79xYsXf/GLX/zYxz42YMAAP8HBwcHBwcGhLsNpOQcH
        B4cnE+964GT//v3PPffchz70oQYNGnzhC1/YvXu3Mjg4ODg4ODjUaTgt5+Dg4PCk4fr165mZmT/84Q//
        4z/+4+tf//qnP/3pp556CiH3r//6r9u3b/czOTg4ODg4ONRxOC3n4ODg8KRh06ZNH6gAEg585jOf6dSp
        0759+/wcDg4ODg4ODnUfTss5ODjEhZ7Qc6hzKCsre+mll/7sz/7sU5/61B/+4R+i5Z566qkvfOELAwcO
        vHHjhp/JwcHBwcHBoY7DabnHBY40P/FIMMSP8+hfunRpyZIlM2bMmO1h1qxZ6enpb731lp/s8Fji3r17
        yLnFixcvXLgwIyOjV69en/3sZ1F0f/RHf9SlS5fy8nI/n4ODg4ODg0NdhtNyyaM6/PvmzZvbt2/Py8tb
        uXLl2rVrDx486Cc41HEkjorr169v2LAh18Pq1asvXLjgJ3ghsXHjRiUVFBScO3fOT3i/ceXKlblz5z73
        3HMvv/zySy+9xPGFF14YNGhQaWmpn6P24T7pqCZu376NqPvpT3/60Y9+9OMf//iiRYv8BAcHBwcHB4e6
        jLqn5aC8p06d2r9//549e3Z74IR/T5w48fbbbz948MDP5+HixYv79u0jz5tvvnn06NHkHi6CR1KWGtQc
        2Lt3bzWp9qFDhzp06AAtbtiw4fPPPz9hwgQ/4f0DbO/kyZMHDhxQTzm+9dZbx44du3btmp8jNFAsJSUl
        8lUAVEsS3WcQk6hZuHv37pkzZ/waH8auXbuo/8iRI2S4c+eOX+DRAk/iRizBHnxIxPoJv/sdov23v/0t
        4w5efPFFxPw777yjJJzPFZO0dOlSk/Q+QiKKI0rg1VdfRdE9++yzHEH//v1r6TMIAoMZrQEFzD4/IT6u
        Xr2q6DXLApHGv2VlZUmH2ZOHw4cP//znP3/qqafQ5P4lBwcHBwcHh7qMuqTl7t+/D11bsWLFoEGDGjdu
        jASCkQDIMSyzT58+GRkZAdo3Z84cxBJ5IMddunQpLi72E6qCK1eudOrUiRrUHFQbIpuSkuInJ4UdO3a0
        bt2aLsCMqfm1117zE94PII1QVuiKAQMGNG3a9IUXXqCPHEGPHj0WL1586dKl8DdG7t27h58ZFLkrADqL
        91q1ajVkyJBFixbBtm/dulXVuy5nz56dNGmSX+PD0Oigk4cPH15UVFReXl7VyqsPVBwBiSXYgw83bNhg
        VGVpaSkXGXRAEvFpnnYjdHGOSZo+ffr58+eV9JggPT09IOd69eqFPKhBzUzwEGyZmZmvvPKKN54vMX/7
        9u3rJ8cCy8KFCxeIpYEDB2KeWRbwIf8SwNTGukE2v0D9xtSpUxs0aPC5z33O/9/BwcHBwcGhLqMuabm3
        3noLwQaDRCdwFOsVdBEaN2PGDD+3h7Zt20LplAFuPW3aND8hNNA5W7ZsCTRHnd26dUP/+Jmqjp07d7Zp
        0wabVVu/fv38hEeOmzdvFhYWduzYETPoZqCncjVS9vjx4yFFEdJ3wYIFkqnxoIbI06hRo9mzZ1f1Jueb
        b76JikjQBJUrHuD3Z86c8Ys9EqArkGFGlWHDqFGjTKgQw3bSvHnzkC5KOnbsmGIVcDJz5kz7CczHBLac
        A5wgujZv3lwjco5Q3Lp1a+/evSPBUdFE4tmBQtu/fz/xSf6YywKghu7du7sv+AlLlixBy33iE5/w/3dw
        cHBwcHCoy6gbWg4VcfLkyS5duthcTUTNgCsvvfTSxIkT/TIe2rVrJ8ZPhoYNG0Ky/YTQQJlQymvwPcAa
        W7ZsmZub62eqOh4TLQcVRk7Ie8BzZAT614AriL2QT6i+/fbbb7zxhi20VKcNP8ED/w4ePBit6JcPAbTc
        a6+9Fq8J/5IH/kXOVanyagIBlpqaalQZQzxmzJjTp08rta5rOYBQR84pdIXGjRsXFxdXU86h53WvNTCC
        uOL111/3M0Xh7NmzRKaf1YMXAj78S56rx48ff+LECb9YPcbixYvRcp/85Cf9/x0cHBwcHBzqMuqMloMf
        I8bEz+CRELgNGzZcvXoVrQWzz8zMHDp06MsvvzxlyhS/jIeMjAzYoZ4YhP0n8dk8LLxp06ZihLQrAzhS
        4ciRI/1MVUdyWo7++mc1BETOsGHD8A+WcFJUVIS0OHXq1IwZM5o0aWLzdbB8+XL7q1/xENBySJd169a9
        8847XL948WJpaWlOTs6AAQNIUv04k5P+/fuXlZX5VVQGW8tRnGDQ9/pQmwzxqFGjNEwClcNfr1+/7heu
        fWBeo0aN6Dho3rz51q1b7969q6QnQMsxGQsKCswdbwF1t379+qTlHFHRp08fOx5ULaCVeFpOd4DtsWYR
        2LNnz+3bt5Ft8+fPx/lKpcJ27dpt2rTJL1mP4bScg4ODg4PDk4S6oeUePHjQqlUr0TIYcOfOnVEd5gsw
        nMDU0QkQOI66KCB+kCtHjhw5evTouXPnDKUOCRRIYWGhoZgjRoyAwtrsMOmvYyWn5dauXYuATEtLM/d5
        qgm02bhx4yDiq1evvnz5suHidBzxrF5z1Ak6GfasDAkQ0HJo6V27dvlp3lCiuBgmlGHLli2NM6t01zSg
        5bp27WqrXMZ68uTJxgBOEIoHDhzwk2sfeI9QJOoAwth+hckToOWAvhLZs2dP0xdGoUWLFsRncnKO2vRF
        TXxCPUQC/5rZEU/L4Vt0u5me2IOQ89O89x7NnTtXqaoHGeOn1WM4Lefg4ODg4PAkoc5oObi+4XZQc4iv
        nxYFI66SU1k2zp49O378eLULy8zPz586dWqTJk0gjlzkBEESeHNmSCBvWrdubXoUUsstXbq0WbNmqCM8
        sHDhwkOHDvkJ1UBZWdnevXtv377t/1+BDRs2tG/f3lBhgCuM8EiAaC23fft2P80CCgdRau7+4VIcgvgJ
        M2oBLdelS5eAYUimRo0aee1HhCKicfPmzX5aLSOx/dXUctUP6ZrC/fv3t23bZss5/Ny2bduCgoKqfmIC
        1q9f/7KHlJQUopHBsmdHPC138uTJYcOGKRvHkSNHMmG5Li9hxurVq3EyEUIG4pCw9MrVGKo0HEmMXW0M
        t9NyDg4ODgaPz67q4JA06oyWa9y4sUgb5KxXr14lJSV+WkLs378/Ozs7PT19/vz5sMzAXbtKceDAAXM/
        EKA0Nm7c2LlzZxFEjmgqP2scXL9+fc+ePStWrFiwYAFmLFq0CI105swZtBzElxqoNryW070s8lMQ4jt4
        8GCu2O+Fr8FVaefOnYH3i0yYMKEGtRxgOODiyok/6Rcuunfvnp8cH5VqOVRQu3btvPYjGV555RXUgp/m
        3c/ZsWPH2rVriQ3GJSMjg/BAG69ZswZTsd/P9zAIQsRzUVHRkiVLFFEUycvLo6orV67Yni8vL1+2bBkZ
        qJnM9usoq39f7ty5c4goRRT1C5hBRJmX79f25qT6kXNbt27t1q2bGWtOOnTokJ+fX9W7c6WlpXPmzFm3
        bp3egkNfmHeVarkTJ04MGTJE2Rjl3r17m3fMALTcqlWrMIkkwHwJ8wVXaVRGlqHBybKHI7bl5OTgaoae
        wWXQ7VvBhw8fJpwopTmOhoz5OwpITZxDzUwQ1gGuEO1oVwyjFGWpIfBrkzdu3GAmYgnhxIhnZWXh8+o8
        a+20nANw/NXBwQCSBj9kG+UIWMC3bNmSxIeSDg7vF+qeloOWvfrqq9OnTw/znCGEmPwNPbRu3Rol5ieE
        g3nAErRv3x7ajTYbM2aMODc1N23aFEESb1+ExEPRUETkR9LAJjlBwk2aNAleaLPVkFoObkqLiAGapqx4
        6qBBgxAh6MwwKig8AjQdoOUuX77sJ8dHSC0np2VmZjZq1IheqEewdvuJxHioVMtBvjt16iQDyEDAiDrf
        vn0bZjx+/Hi6pq9Balw0NFzp2rXr3LlzcaZ5ghdgKvWz0A8dOhQtjZ0qwkAQV927d4cf2y+GwTxqJgOV
        Uy2jYzpVHS1H7CFypk6dStfIQD1qAuDDHj16YDlNh/lOYw2CXZCxMNOETjFTUCxVMgMVaktolFIYLcco
        T5kyxTiNsWBaGZ1z8uTJ4cOHM/ok4auBAweGec6WkWJCUScuJWwYdErhcz0PjKvxOX0k5JjaaDMsJ7QI
        jGbNmlGKVI6vvPLKqFGjNm/eHLhpz5XmzZtTM3n69u17/PhxVBxzX6U4YidBOHjwYKYMDiwrK0O/EatK
        pXVsYPTRdbZqrRKclqs/IJhPnDixf/9+Fj3AtGK2Hj16tDqfBdQtVFWyPp4S9/G06kkCUwNexzI+bNiw
        IUOGsAKzX2zatCkMG3FweBxQZ7QchAZOBpUBkDxIz4gRI7Zs2QJjS/B+RRgVNEhFYFFLly71E0LgzJkz
        cDiKe0Tx2RkzZmgLzM7ONpbA6mB70XchWHzJrBdgygCADeTnX4xHAEA9uagMIbVcaWnp6NGj6Qj1UJuq
        5ZwjfLeoqAh2G/20ZHJAx5rn3ABNwCDD/Oxy+PtygBE0ipEmYPBh7A+j5ajKaz+SAY/pvRewZ5rD/5S1
        IWdyBPw7cuRIOJCqArdu3crKymKYlFPwyvlo06aNrbvoL8xbrZOK38z3DJPWcgg5ordJkya2GZG2rXOS
        IPoMXHhVj2GHDx8mrjAsCUAT9+3bh6l0CgfKeCxp0aLF8uXLk6aMIbUc8w4dZZomP0IoLS2NHh08eDA1
        NdVcJw/y0tbn8UD49ezZkyKUpTZ2d0QXNgC5mgpJ4l8Ce9y4cUuWLFEqIINpkfOOHTtihs3D1q1bp4eK
        QYcOHSZOnMgiEF2Wf9u1a8dwwye4rnDlOhmAcqLbw3x5NRq1quXu3r17+fJlVk6mD0KUkOaI7GQ+EufR
        n3MT4SZbmM+JosHWgB+Y11QiUBXVJiZhTBBM8gs8DIqzp6DSsedx49AsAufPnz99+rRxGn7mX/ob/Sud
        zL7i4mK2LXFT8dQ+ffoQtMyFeE8fvI8gPMrLyzUK0WCIGRdGjXGJObh0n+Jkk3PIHGb9IThVPxL3cfAJ
        a5SJTDqCeZVuiBRhchGxmgWKCuYg3Ql8luQQE5o4bIJTpkxhtQdME479+/ffunVrTRGqqoIZbWY6wckc
        T+676A71BHVDyzHZVq5cCbWC0EB3BDgN6N27N6SHJS9moI8dO1ZUj4ItW7ZctmyZnxACEErYmFrkyL+i
        yGyQMGZznQ0yWkxiDFaRgaZ1hPlBcCH9r7zyCqVUXIC6hdRy4ObNmzBCNmbILnVSnMoBrqBOaOiaNWuw
        p/qPB8yePds2Ejey2IXZG6qk5aDd8Aw8QE51JMz9nEq13IEDB7zGI6AXXbt23b17N9dZExGoXERrMRAa
        kbZt2xIbXKEqFcGe3Nxco4iILjIoiTzQcZxPKapq3LgxHSRO7Md3CRUNDcBIvGFYAvoHT5qkkFoO7mIe
        F1QGrMV4bMB+7DHXqZzgZAdSwUqRl5fHtEJRUDBpmLE24ApIT09P7nPNkFoOwG5hq9ivdvEDpfAMIYFh
        XOFfUpHTIaUCOzdLippWbYCqmjVrhqvtJYhUoBOaoFFs5sRcxPIxY8bYAnL9+vWvWj+2Lr81atSIgsQS
        J/wrqBXycEKgkoH6lR9wQn7WAb/eqqD2tBxjXVJSwqCPGDECMiQJMWjQIBTphAkTEJ/2s6OARTIlJWXA
        gAGsZhwXLlwY5qOiAJhZEr20JVDb9OnTjxw54ueIBYKBocE2v4wFLmIMGwcxA5HCyBpXdCwsVSWIRBEq
        jkVp8uTJOBYLMZUTNg42oNTUVNZY206IYFFREaMAHyUPxFRHMVSO9C78Jz6PBsgPrFLYRIMuMzQEUkZG
        xpYtW1g2Ax/NsDdlZmbSTWrQRzCVPlPN6uHXPngwQVtQUOAnvB/AfoKNnWvixInYj0nqCPuCnyMKCiSm
        FfvI6NGjWSdxkaJi5MiRzHT2u8dtlB9PaO7s27dv0qRJ3izx5RwuJdiIE2V7NNDnUyzvxIBWNkZz1qxZ
        +ja4g0NM1A0tJ7BaiZ/ZECWCaeXk5ETTtepoOSgCNVMWwJzM7nvo0CFWWEO7IWfl5eVKMoBhUISCshC2
        PWXKFN3qYYp269bNMEJAVSy+KhgSLD0s09B9WqG4GgJUC9A5mzdvrs4iTnH0j7gjlXOC8+EHfnJCVEnL
        HT9+fPjw4XKmGkpOy9lDDyeA8RgPk40wkPNPnjzJ4ti+fXuGg4EgpxZxmAGSwLiRIqzpSDiSsGflypWm
        LYYbWSWJQtKKFSsghb169bJjoMa1HIu4IfrYgKLAWvpCEut+fn6++dCBVADjUcFKsXr1avMbjDULzEBw
        sjsGKFcYhNdy4O7du2lpabYUBzrnyMrA8IUMXWBrOdWAPIMu7927l1QMI3iUJCgDQkX3ghhQ3XlTEtFi
        f8ZkazmB+QvlQsMQS5BU067Av4TWhg0bsIrh5tyUZchQTX69VUEtaTnicMmSJegEBktMyMCjRn2Zd7bE
        IiogSVIa5OGEFYYrSg0PpjDRbreIAazP9vdjo4Euwh6/QCxQoQzLysqyv+9aTbDaMJQo3kplhg1CaNeu
        XaNGjZJv7c4C7AQsF/ZEY4VEnJCfDOTnBC2kf1WE7jP3GTW/wGMANAnrtjEyJtR9+sL6vHv3bvujInyb
        l5eHmKF35OQEnyTYTRgIVk5VKCSOmdqD9iDWf1YAMyPUWVYeNnpliwbCb/bs2WSjCEeVEvgXL7G3uh9i
        qRIOHz7MemIPASOSnZ0dzfFqCYSlHnWhXTOmDCUDLQ7j4BATdUnLQYihC0gyQ3ltwHvYwuHKfm4PSWs5
        OIcEG2U5Mr3NtsGWuWjRIkPWyQA9sj+5QUTt3LnTsC4MYIs6571EAVAcag4twyTloX7WDqWGBxsAOzFW
        IS2Y7XA7mwhiHnWyLvi5qwLqpO/Gfk4QDzg2JCmvkpYrKyurvpZDdupxGjy/Z88exJVpHZCB4VBBnEYe
        2D99wXvaRAUGhaVTRfAkq6e+XgXPzszMlG85osPN7QWKUw+gTruqmtVydG2B9Stq2EAY2/dd6QgOad68
        uYykWhzCfuAnJ0RBQQFyApMomzSMbQZcRHwuXboU5/gtVQVV0nICLmrcuHG0JWi8lJSU8I8jMmXs+3K0
        jgKxb7wzZLq1TgaOnNsvs2XLJ/w0xBTnhIEwqQEt16JFCwbODCVbNVFnUml6yJAhCkKAJ9GTxJUyUDMN
        mbgKj9rQcsQ/S6JNPlh8GDVBRJPUiRMnlpaWqgjdQbnRX9EmUtFy4e8nGzBraEKNGlBtTk6OnyMWkGfk
        8XNXqB1B1voJXkeY/kn4ORqsqyxEhBP1h/+0BUdt3LgRZWKs4iTgXi5OnTrVzDUtWRKrZGaBRT2SSrDR
        roYJIIdMZD4OOHTo0JQpU+iR18sI1EFB4wKUxAkdtH+aCGg3ITMZ6CYThJXET4sCW3ZqaqoqpAiZ2Tv8
        tEcLtrw1a9ag1U3vBP5lI46n5YqLi5kycouJBwWDAf/ikG3bttnbk0NisA6PHz/eeBL3AphnVd+clwRg
        iehGe7ILLFZOyzkkRl3ScuDOnTts3vA2Q0DFewAUB8B+jh8/7ueuhpZbt25du3btKEXZhg0bspvaewac
        A+pMktodPHiwkWrAJt8c4WRr16710zywsFIh9sh+MrBw+GlVBxSBbUkf0Rmb1W4YXRQNVAREnBqwjdro
        /sKFC6FrfnJlqJKWY7BGjBiBqeSkRZqrqpYDFMeZbdq0ISqQnWLSwHND5Gt+YajYrVu3IDcqiBlQAdFo
        3MvwyUJAhaytBFKCT+trVsshMtmPaZfrGNajR4+YOo08VEg28uCNlStX+gkJgXTBABpio0oCKksHNctk
        PAYQh9As+5ZUlVAlLYd8ggahqTAA4ASKyBUU58i/LAu6jVkpp7G1HJV06tQp8Gk9trVv3171UzMU0F4Z
        wNy5czX6ylBYWGj8YGs5jqxO9v1k1g08qaZJJZID2oZ6zDOc1AyXJWb8tNCocS2HHoBuwjbEPxgsuAgy
        nrilR3SQtTQtLQ1rEdX2Y5Z0B3VHQQir2JI+kQkPYo/uMFU9whNpWjZQJ6rg8OHDfr4o2FqOE5aII0eO
        sPJwHYqMVVw33WFmVfWlWQGwhmzatElfXaNaXAFZ9NMqA+qXdQkzjEk4E1VP2N+4cQPP42qurFixwsTh
        6dOnCUJaIT/OQdeZD/WIRvrLdgPYtgKh+/4ioOWY1OwO6FKigsm7efNmIoRQMRnoAv5cvXq1vTchlUmi
        46QSh8uXL/cTosCmOWzYMLkUXzH1EizptQrCDwPUI3rHMdK9yrRcVlaWxnfcuHEFBQUQ/StXrmzYsIFO
        2TUAfOK0XJXA2sX+y1jgPbmRc1gQW56foxbA2jhr1ixvxCLRa5Y1wJx1Ws4hMeqYlhMkXdiiCHfxHo4A
        ggWlY8r5+aqh5TIyMlQzQCH4VysAhxg9ejTNqd2XX37ZnmYQXHZfCpIkXmvuCxmgRtAeqgFaxtT1E5IF
        qw9bQtuKnzL3DA/13bMAcnJymjVrpkowHowcObJK9VRJy0Gh2I/xgDLjyTCPwwW0HMBO/pXBgv7FJ1JE
        NmA227ZtQ29PnTpV7Ar/9+rVC/ZsamP1NLdE4Ebdu3fnIkl4hprJ2bNnz8mTJ+fn5xsxZlCzWo7rHTt2
        NCNCSGOPitgg7LGKDKCqr/mpDtAnJupkOb7asmVLePEfjfBaDrZHc+TRuOBb6GBeXh5cWQFADbINv5WV
        lfnF4iOg5bp16xZ48K+kpIThIIlqaY4Y8BMqkJ6ebmu5NWvWxNNymGqrF5Y1+JmxmfVKD3YaoJrMhyzU
        TIgmkCvxUONaDkVBsImCCPgf9eIne5+SEOSnTp2CKxtRIXCFlZNxERP1r4YG3YfIirlyRNIwnTkhYGC0
        6BY/XxQCWg6RYEQ1lrOWEi1KBewybAfJ3cJCijDoUDQEIZ7BNo4Qd+r3cyQErsM2lVJBpAuE0mgwODpN
        4F4CyfB1lA+uoF8UpAgBbPKjVLnCdSU9zlpuzJgxdM1P88aFPhInyFR5gzxkRnjbP1C0atUqNCq9I5Uj
        K7yZfTbu3r1LKVWietgR/LTqgVmM7MT/4R8YpqeMBcoTIPhxggzjmEDLQWbYmmFB+MRs0HiJCCHYjBtx
        ApU8Vjdg6wRYImAIDIGJJSYUV2pPzhGBsEpigIZYx8yiSutccVrOITHqpJYTWDRZ/RctWtSiRQvxGwAT
        YjL4OZLVckxXW2DAn9gSWGHhbYBz1lAUmmkUYmd/J6e8vByGoSTaZRXYv3+/kgxQd2g58TYaIo+fUEWw
        J5WWlqIWMBiT9K0hQJ2cV1XL6W6hLFc9I0aMiKkcEqBKWo71q0ePHspMc+3btw+wvZgIaDkK0gr9BXSc
        EccVbJDsqYGvUOIuPE9UdO3aFdlPZoaA/JwA6lGFXGT1DDzeJjtVROBfBpH1lzrtDz5rVsshd1tXfPeS
        IrCEmM/uE9tMBBnGSa1qOXUWkoRQMWEss5G4uL2a1CGkloNzM8pkwDkAKZudnc3kFR2H01CJDKMqMuDt
        Sj8pCGg54gT666d52LNnj63lJk2a5CdUgFZCajkWExMYQI/GqWlSGcTAQ1/Eoa3l4H/m62fhUeNajnUY
        +SrOwTqGVXrP0CMAkUaLYjyod9YZpjbSS1eYKfFuR0RrucAnPqznXKdyMtAjFn/d1w0PJummTZv0Jhjq
        IYYxiSPbEyeQQj9fQhAA9AtLKEK/6JEtb+LBaDlKcVy3bp2Zj3iM7mAPoMKqarmAP+O5NzkEtBzramD1
        FpjaTDrlUUdYYI0lLNrEAL0mlapQNeaxXhvUDHOgrCphjGjdT0sWV65c2bp16/z580eNGsV2HP6D42HD
        hmEqKo4AZoOAtcswhi+Bljtz5szBgwej9wKWuNTUVEY54iCvd4ScCYAkhqw6o1yzEfKIwZrAPMWBiklO
        mDIEWy3JOTYahluLA4NO67TIvzRNPNeUlqu9EanTY/0EoA5rOQG5wuppvsECu4KK+WnJarn8/Py2FT/k
        DSgOObNBnWKHykBOlmMz01he2YCVRDamYvRDcdXXcmgAln72bBYaagCYQYUcsZDlOy8vL/y9EeYhDKxz
        587qlDxGzWwtfo7QqJKWQ3LoXRG0SBcQyfYn+vFgazkKQuJZ6dibWQShZatWrYKy2I/aCvSRgSA8KAjk
        K4bSG1J/TGUzJ6yeRssJu3btYrvt3r07ilE1yEscETAwBuPtWtVy9DSmllu+fLnRcrV6X05LNsOESkF7
        G5uxTUKu+h/2h9RyhYWFZJBnyIwzA2+UhcUab3NCzMQkdjaclqsq0HIZGRkR2uixT5aygMcSgIlcVFTE
        ODJSkOkqfQQAHacvgwcPVqMLFy6ETMNiOccS5i9iIB4PTqzliHAiULf4yACBmzBhQvTncfFA5XB6Zj2b
        ApUTvdRDJZxzBTtJDXOLGLBhUYrigLIhHYsBaEiJRlqfM2eORNHdu3dZFuiRCCKSI/FUxZ/4gdFZu3Yt
        oRtYDwX2XxZVMpAN4KUwC3hMhNRyAJEM61VOHDtu3DgzEZhrrJBKwmnMERbGaJapj4HkB2qAQtjfkqgq
        WJDZ0BlZ9i9qAwzWihUr/OTKwKyHcigk2A5Y+bEcwzAvnpaL7pENjT41AKqytRxgndm7dy/rEpOO5VoX
        T506xQa3ceNGhpIpuW3bNoSiWbgYU8xgHyeJUhs2bCBzTEmDwmR0qIRs6hHaksgh5rlChNAETAP/Kz/A
        e9hDKTIAgpz8YT7SfQQgKiAVhJOJKMCyE/jop0YANyBm8ICcA52gLYUoi0A8LcekPnz4sCYgrpNhDE1J
        SYkZTUaZjc98DEQwMNxELBkoRYucnzx5UkESCC0GiwxEC0uBHp1giLlIDHCdyhlZQoVdQPlZUmAyZGbF
        UOtUThGlOtQe6ryWA+wl7SrexQfFgbH5CclquYkTJ4qrUSEFqTMm1CIgJ3LCKBbWJvZ+UxzNRjQryQAS
        Y+QiVTFp/YTKwBp3+vRpJie6AqJJDUAdBI0bN2bRgUbY30upFMxe1hFskD3UBnr16hX+KREb4bUcfWGA
        MFuNopFYy8Loz8B9uW7duoUhEGyT0Bq8rVIUZwjYLBkswDaMZFISJrF6RnMXiAt9oXfDhw+HaiszIMYY
        DqPKWMhqUMuxiZqHGDEMiqMXbAbAloNOiASBd18u/KfCSQA/5OXlIWlMX2iUaLSf5qoOcGClWg6XGtWk
        bDEfODT3z/E2BrO1+Alx4LRcVSEtJ84BILJ4gLEIw8bg04gxiC+YO3duPO4eE7ATFmpig6Y56hk5iLsR
        P9RMlCpzAIm1HJwGqsrKQCXqEQ3FVDI2WLiohKWJ+U7TjA5WUYPH7SN6gxkKYa3So6T4hLKyAV+F9A/x
        tmrVKorgGYAxrKvE0urVq6mE61ilbSKxJEBckX/EiBGMDnoAnRy9OB8/fhznsB4ifcmGKKrSINoIr+VY
        f6ZNm6acHGmarvlpv/tdbm4uzqeP9J0jZvsJFhhNUtUQDmFVhxP7aaFBnGAhpJn4kYswRo1iEkPg56sM
        duzBesNoucRIS0uztRwBYGs5KsR77HeKcJYgpBTyj3+xXx3BJ4wFXP/q1avsUJxMnz6dmUISXcO9ZKbX
        7FmBBR/6jtnEA2GTnZ3N4oBa0DzVTKdFjsQeQ8CuTQ1ZWVnQADkNYDOLKgu1kQfvL9BF9JSOm0EB2GzL
        0RoBrra7jHMUTjSK5+NpOdgmztRokpNIZlNgH+GKXI3bqYSJzLwgXBluFjcWFq4zypSS26FGEOnoWcDQ
        kIfaOEG2sbATKlSLSZSiElphI0PXsbJRv6gp7iJIyMaRbFjoHhCtbdQBLcd+Q4gQCvZ3S2wQYbBww/yI
        LT8hKS3HNt+lSxfqEaNCpCEboHcB9OzZ0/6aEDqErVr0hZhmOVaSmobPqXKBHnFFd1HIE7A5AdjDmDPM
        q1deeQW+SHHqF5o0aYJJTLMk5gybKNPV8HJqQx3Bp/3kKoLFgqkL2VVt8bQctGDp0qUMinLSKPQ95Iej
        gfty0b8vFxN0k8VRPqdUjx498vPzjQhkFWP3ilgcX8sZQLtZzU0AcMQe4/ma1XJUa15Gz7Fz587RN5fY
        UFkxFQ9Ui2HIBj+tpkEQslU0b94cI2UVaN++Pb22GUN1EEbLsZXCSGyPRd+JxS1MCtWDW4iTSu9sOC1X
        Vdy6dQvJ5DEcnxwzXuPHj4f/QYNgJwmiAipAZoow3Zh9zFA/IQRwJqVYOWkX0qBPnfEYTUvGkDRr1qyY
        cqXS+3IoQ9EUZYCMJnY1fSwrK0PJ0B1jFaAS7GGtwDDDehMrKAN2PXwiO6mWaOci1I1F8vTp06c8QP3J
        Fi2boWVoIeMHMSrxUa5QG9yOPIktoSEapRIM4IiTWb4CRXbv3k0qdcpIBEzSFDy8lgMMGTnVO1zExPET
        KkS+zCbVxIYB/xKx+EQNMUZV5eVsXrARvLFo0SLqUajIGI5cYWrjGT93VYAl1dRybCiMmsJGgAXZc5Bx
        Z9WS90jV9+vID3CamuZIBo5oM92YksAzpUii14jGwAeLUCy6Tyq1If/Y4imiylUWqGZs2LRp07hx45Rq
        miaDzqE6YT7YTQxqYBXCyJNJgYLMuC1btpiIEujCwoULyRByLieBkFoOC9lx8Lkcm5OTM8r78RKuyI0R
        j3tJ/Mv0ZC1iKQD8C0iifrIxmvSR2PDrrQBsk2xkIIpQgKwb5FRZM16aAtTM6NOQBtQYzwk5GW6mc+25
        y6EOaDliOiMjo1mzZiwcTB6Ikb0pso1xHSUDxRFYHZQKktByubm5UlmgcePGzBM/IQo7rR8egNux2Ok5
        HMhNQUGBScIAtijDFbCf1RmOaDJAy4h1pSYGUxE/qEcqywkyslOnTrgowc6XAKwFmEdnTYXUVumjaAkQ
        reUCtyXxAHqgsLDQ1kJkmxD6Pd3JaTnYGGOknlIK4mU+HSCQUAIsSRGLH9ZyJBGB6HNsNqQcsEOw6Rob
        2rRpY+4L1ayWYy9hUzQDRCnoeOADPPSGXnyiDKyeyQVDpWDqYZieMpU96jvEpaaEHNi1a5f96Qzd8RMs
        4De2N+Mx7IHc26QWUxkjxI8yaJZVGthOy1UVTBAClS1f+zpHgKvZ7GGiLLksdzdu3Ii5i5OBwSU/0w0C
        Gr47TEamFQSCFmkoPT1d9ePPOXPm4BnqpGbqR7ZFN13pfTk4KNfVHU5SU1MTTygoI5WQX/xGpRhfdpOk
        P78nVtm/6B3VUhu6lFBkraZTXKTvdJPrzEe4pr00AWIJFg5jkz3UIHCOW2B7IX8XW+SMSihLo+wyfoIH
        Vh6UAJZ4PY60Ev15SnhUScsxrRBpZKZRzGPQ/QSv76ztigFSyZOfn2/fcIATs6KqITJQNsz2ITAH9dCg
        dDutUIPqwQz2F+jH8uXLVWHMmE+M6ms5FpkRFT8tSD1ECKuKvThjPJGpDIBhJRtH2qKg/Caoa6RyQj04
        HNVnCiqVfdyv18OKFSvMRwaqlhPq1N1dUxbYlRNCZNB0Viogeu3lMTmwBcyYMYOasSpp0HfbMIHr1Bzy
        YekkEFLLnTlzhtWPDLKKIriUI95jsAIuJUkOJ0P0cFMJi1hgLrBWMIikqpSpnAUfD5jKOQEabo40zXCr
        oEklNpg7fr0ONY06oOUYfuYM5EnUipjWhweszkg7dlmjbaBByAN7l0pCy0HOoGIUoTlqC9xSs8EyYb7I
        pPpZRpWESIDvch2QgQrpAqQcpsju3qFDB5USxDJVMDGYDLQitgc4adeuHSs1097PUUUgUaZOnWoqxCp6
        hHvZsdiHOAYQZnOK1nJ6/EnFIWFs/ywiZDBO4KRXr17hH6quvpYDuI59iOhisNatW8cKZTuWdU1aDoMZ
        MsaITZoTyT/8Rpj16NFDRbCB1c28JKZmtRxxDoezx6hhw4YpKSkEP6lEFNShSZMmSiUbamHRokVelTUP
        CDeBbYwBBAzDUU0hR2BQAxHCERAwtpbT7PACMAIThPjZDiE6jlBhlpEEn2PmEiQmA26HwRj1Hg9OyyUB
        DNu0aZO9tRtwhZkF8WW19HNbgE+QSjamW5W0HEPPfIQrEBvwFeYvYaMkFkmPOUSoJC5inmKekgyitZxt
        HjMOIaFUVZX41+oATWRnZ1OV8tOpBQsWaP6GWTNjgrVCWg4z6IupXIYZKGnlypWBbvIvS0paWtqYMWOw
        h2xURYVZWVnh5cHevXvtOxLjx4+3u0M97L9KxTC2EmLYT6s6qqTljGKhXbpmaznApOOi561IKmupvUWW
        lJSoIK2QygS3PwNKAAKDzREGomplp4ATaIWqbNGYBKqv5RiRSLe93tFN3Hjq1Cl71AJaDnCucL13797G
        jRvZy1RcwBhcvWrVKoYDN7K+cUUZmF8EmP1phdFyKgsQA+JF586dw3XG8wLnTHy9iZTljvg0hpG0efPm
        pLmNwHaAhXaLNQh6yuZbS8+CJqflAM5nOjCbiGrNX3s4qBCz4SREBcOd7/1cvpKIYbazwMedRssBctIQ
        zUnBEu2ag0oFqhyaTdN43tw8VyqVV7r/OiSNOqDlCAviGIoDiQGQGKgSnAZGa98fEAcip1/MQ5W0HOsd
        W5H9ShKiMMGnqmS2b5jQEFNIiyY2I95ks1KxE4OB7AHUL+M5YbKpzsSQliM/xTt16sTyys5RnecQWCu7
        detmfAioGa/i3mjQLlyz0tkY0HLArlAeMKk0xznzvEpf8EtOy0HX2CbphWkaS7AHqzixB4tz1ixpOUYZ
        9sMVQDa7F1xRfupcu3at2XVqVssBfA7L4bqxkLLyKpaQWdc5cs6KWUuffmEDzZm2AHs5RhoanTR27tzJ
        IGpeA7qmVgT8rOu03qxZM8P4dStVYQAoYgYU2ANEnpCPDTNznZZLAti2fft29BVLmU0dBBZSOAGjHAiV
        pLUcKzmtUC08g5qZRIatGoZBKpgwYUL0ChnQcrhdsoGcu3btQpNQ0KRSAwuOCsYDBc0tLBWkhmHDhmVk
        ZCT3rWNgazmBOqmfK1hlkyROCIZt27YFhATe5grhx9IEOAGYajP7xGA6EDDylVphEI1oxFfDhw/XQIDc
        3FzyKykJ1KCWC0hQ1L4xDJ8UFRXBOJXESYJWbLAIs+GqsyrLOSCGEUIoLhxb/ZWwmlouLy+Pzqo4R3oX
        /RKsgJYjW2FhoRHhhoKbVPxsPy/K5qKb8AAPM2T2g3m2luNIQzRn4o169BEMqRo4lOdN623bTBauA2XA
        +GoqJbYn/EncEsO0mwQCc82AOuPpqxoBE9a0iw3htRwT1pAKYpJdz0Q7tVEn8W+mw40bNxgCBkJJjPXW
        h3/U1Gg58phwMnFOqKhaHYcMGcIqhOVKtT/rAaSGnGsOSaAOaDnihj2+c+fOsBwYDEcAoRH0L+QJDQbn
        1qfyBuwHYrrkgRtFr2s22KIgXua1ihCmlJQUPy0W2Bdh8J4VPrtiRpnXncHSmO0kifaJn1Ez/0IBCWu4
        i3Qj/zINVCox6CBcFlZKu2wt1dk4hQ0bNuBYTMI8g0h/YoFsLOKnYr14w8aVK1dgMHadfvkK0GWBUdMr
        KBnf8PQCwHEh3GqCCpG1YbQcYHTatWunKJIxgHOq4kS/N02dXGFdk5bDz4gxCQPglfChK+Rnx7INgNSi
        RiI99/QA3sAnSoKfGZmHGVAQE7FoOVoxSTNmzIBxKgmwCLIL0mjAeAN1Yfz48Unw+wTQuLAlMBdMp2iI
        I+t+gk86qgT4N85XFwQ1ZOBf9W5x2x8c0lkiQT6JdosuUqRDhw4xv7QZDeZU4q/YQeupTaYyXtEPBs+d
        O1eOIgPZVq9ebfa2devW6Wlwr0/PUjag5dga1TR5mjdvHq3l6Isy0GX2yJive0mMWtJygPUQWrZp0yb6
        FSFBFV/GAJwAaEFAFCWn5XAaFIEi1EnxwIN/MJ5Zs2ax9qpdzGBmGfkh2FqOPHAUdgrmDtoJk7iiJMpS
        D4ukXyw+mCb0Ha5PHyE9FBQ94oSlnrnD0s16EjAjMWwtR1WAylGM586dwwPoKDxmp9Jrvce1Smtppdi4
        caPkAU1wnD9/vonnlStXGj/jQ6MHkkOVtBx8NIGWY1FduHChobCAQdR2qe8XafQpyzJra4kEuHjxYpr3
        1mhKAWqYPn061UKmqaFKI5sA1dFyLIwmenVkbw0wImBrObLh58A987KyMjM7GHo9VmNATxkmCgIqIUTt
        z8iMllMqYe8nVADdq3EhddiwYYE3D5eUlBBRlFXrBJu9QiYBrGUHxwlJAzG5c+fOSZMmaa4JGJ+Zmclg
        1excs5GElosMSd++5iNggYAnKjyrIwvaokWLbF7Bos2OoIYYNZwfeD2Y0XIcWdhZlPwED6z2cEIVxwb2
        Lyr007xlFu5kYols+NNPc6hp1AEtB6CSrC/EHEHZqlUriA5sCUgMdO/enRV59+7d0Ysymyi8p5H3ST8F
        A2EaANOeWUHlDRs2bNy4ccuWLQPPgkeDRRByhhnUT5G2bdturnjJL2CasRhBB1Un2TAGpoikJGn58uWv
        vvoqpbjOiumXSQiaYyHGFdVXcQLUqmvXrtjgubMS4EboDkzCLxwHd+/exc+wWL/Yw4D+Ug+OYtWAhGEA
        OqeqCyILymuvvSaz8R4BEPLzHgKJsqzL7du3Z1Aoy7gwKLBzmBDUmXMuUjPmiVliGw5nRUMEch0OrY7Q
        QWKPdSo3N5c1y+4CoWi6zwnbm9mxEJNwcSVRFVud+dzx+PHjtK4kvMQiyCakJIFW9D11vEdOLCcn1lIh
        sc3UgHUhKWuKVRiwNzDuxmyA5Wzh1fluTABsCXRHPUoM+mtvJ7gdroOv2If0WKafzzOyRYsWvXr1gtUx
        a0I++8QOiicpK2MQigFBRetEgqxlFODTfkIFdG9NxcmAhiTqlLRlyxZWFS1H5IEa2p86c87KwNCTSnFy
        7o36rXCaVgYqQSRUOhmjUXtaTrh16xaBCr2DkjI7DAFis+ecKW9/6oGr2eBJJWd4LYeMGeO9j5Gy0EE9
        wWWDK6IXgMoLCgoCCyYWGoYBCB5qEygosCbjYSLTSJdKgZiB3jEN2XeoljWEesRrOWfKoPOZwqwnYW7g
        EGlGy3Gkywy3WROoAT9M9N4QSAaaQNuYVJqAV7GmIXKiAS9kIODNYRbew4cPI1rkLlrB4ZpKTH8UNf3i
        In2ky2E6lQBV0nJszVhCZlrnyMbtJ1SAUSAJqDY2YjmHHUcPnSp13bp1IceXZRB/0lNqoyDeZmGp6o+v
        VorktBxrPsPEvqZSHBkXYiOwgAgBLUeMBTYaekpxUgFTwHw8LbCa6cY1oBKcaYs9o+UAqdFPQhGWzCxq
        JhUZ4F+tAIutiqt1htV8DPp+AaqG/iT+6S8mqeN0MyCA4wFtw0SOnonMwXnz5q1du9bPF4WktVyABhNR
        +NnUw4jYLsU8Niw1hNvJECC9RsuRRNgEPj1kR2OeUpyyxAxu8RM8sMwyR2QboB6n5WoPdUPLCeyUrGuw
        BNbffA9s0lu3bmXBCnwUYQA5IDNsOy8vj5yJpx8bG5G6Zs0aaqYIJCyMQkC8YQb1U4RzwtdP8EANbJlM
        DzKQDWNKS0v1CByTcMOGDZRidw88o/zI8Pbbb+/cuXPVqlXyZ2Jg6sGDB8PsfPSaLvvFHgZOKC4uhoXg
        6sAWEh5EAjVgjyqkC/anQZUCzyO3GAsNHCds8AohWCB1UjOjZng2gQFxoQjjq/AAnLCH6al0ZTNgrTQh
        ih9YTI2+ossIXRoliXUcKmCSWILVupJYuI0GMIBFHTt2jL6bSugCpZgUTI1qcql4wNsQIztI6HsNCjmA
        82lCPUoMPBP9SS1eJTIJAzvqmFZM4X379tnjqJMEYDgoQllVQmAEopR/WUlIwlqaIwD8hAqcOnWK0Vdf
        OMFa0y4yhs1PcUtH8KE9xJxTVk1TnJyBnlIPfWTElYFFo0phL9SqljM9xY30BWvZ/j2C4TOJ8ePH41Ll
        AclpuaysLPLDHqgWAoHSgKAggwUCFY3hkQcfKARbMwNby1EJNsAgoa2AE+qEoEA6MVWzO0zkGLBCnjx5
        knieMWMGNdOQuI5OILLIHkbZzx0ftpajLJ70EyrsYWExNE4wAYPnKajuRENkGqeF6RdhScjZrbD+cJ1Z
        oI/k6RptLV++3CxlyaFKWi4nJ4dGaRowXtFfaGRBINiia2Pl5KIXkpFHwkIycsDkRQDjOhXHjYwOLJ+B
        Zi+rUoQkQHJaDpFPUJlS2IaQY5hirg8BLYcYC6wz1GZruQA5idZy9iMPtpZjgKK1HOuP0XLoYf9qBdBy
        pKojALXz/mo5duG5c+dij9ylExaZMMxQYFtnskfPRLqJf1gi/HxRSFrLBW51ElH42dSD8fanaUQIC50a
        ondkYM/y0zzYWm7SpEmB78IEtByLgD0RnJZ7lKhLWu59QRJrdHLLek1tBuHx6FusETxKs2t29JNLen/x
        CAyrWSfXBhI3Z6cmyJmczTXe09q+LwdsmxEk5lNhtnw2frZ/Py0pLYcqg4katkfNUASokg3DHgQyBz56
        sLUcqRMmTMjMzEQeowNR2vqwyTCeKg2ByfzgwYNjx45BjKBruoOEnSJMWAgDVrYE2Lt3L9kMkbK1nADn
        RksY2k1OQ8p37NhBizTkM8eHwXUsoaeVfvqj7uzbtw86KJ9TUPeT4W36l+sQPohmNWM1vJa7e/euvuRD
        NvITRdHPweIKLKSzqg07oaHQXOJf3QfoHz93CMCtUTUwe5rD1QYMDUO8du3a/fv3h6f48ZCElkOOEr0m
        DLCHDhLD8aS103IhgUxiuNVNGYxbcHWVRpmQS6Dlpk+f7ueLgtNyDlWC03IODg4O9QWPQMsFAF9hp4cr
        sOWz8dvfra+qloMoUBxiJPIBRKajwXVSDYcoKCiwb83ZWg4KkpWVVc3veiUAim79+vVQseHDh8sYmh4/
        fryfHB+nT5+GPMlOSiE4A7dZ6MUbb7xhaDf9NaQc2peTkwMVnh8LXCcMoGUh1Rfk3v7eC9QQzo3koGlv
        EPrSOz9rNRBey5WUlIz0fvaKbOTHSzBaP80C2bBNtZF55cqVhYWFyBjORVvDqNkAIKNFRUX0HX1O5aYq
        XDF27Fh8jlK64L0QksxJiNvwWk6V46Ls7OzIGFR8XMLsQDwkiGen5cLg5MmTtE4fZQzW4hOEnC2EwuDu
        3buI/OiZiABbsGABseTni4LTcg5VgtNyDg4ODvUFNa7l4HaQ16tXr8akxVAZ3UaDK7DlB5hfVbUcgAbB
        CSKcxXvAkgqpH5JhgytcV82AnAghOI1fxcNajhPYTLyn9GsKcOtNmzbNmDFDQhTe7yckxNy5c8WqKSIF
        5Sd4OHXqFBnUEfqIVtTzwElIiMSA8BUXFxt/4n86gj0MKxfpEVLZz1oNhNRyUFV4Ldm8EIgg3k+wSAyL
        i3NEgSCT9OJNgP1J30tEYMDCU1JS6DsGUBtHTGKwQFpaGvoHLu7nrgqqdF+OGYfnvd74+WkdIacvccTr
        mtNyiYHfCAwGV+sMZmCPVomqCrnqwGk5hyrBaTkHBweH+oIa13IXL15kL4d1ocQgkfetHwCEKBw/flx8
        V3s5ZN1+g3lVtRw8Eu6o2kSa45EDeLz9w0oUsSt/9FpOuH37NsQXKkxn/UsJkZWVRRdEs+hvdna23jsi
        bN26ddiwYcoA8Eb0279qCvgTPUwrcpqhuZxwPSbLrCoCWo6Btnk8uoXRv379uqGe5OGEbIEXLRogBXGg
        qZA4xIdyF/+mpqb6+ZIFxiBxGU15Q9Vy5F9OCit7cVpMoKPsN6wwQY4dO+anRWHNmjUIJ9NBIpkrdoTE
        hNNyiYF4plPGq3QTe3Jycqr0aGX1wThiA61jAyPrtJxDYjgt5+Dg4FBfUONaDhWk3Rral5GRsXPnTt0d
        glJAZ6EC2sgBezlMwuaaVdJykEioHjlF9ThZtWqVnxYF+EpmZqZYiDLnVvzQP3i/tFxVAUseW/HryZAt
        upOXl4cf0GywKESUOkgSeaBZ1Xz7SAJA9xk7WWJAu4BB9zNVDwnuy8E4y8rKGO5x48bRojKodRyiPNGA
        Vu7bt88MtAHxg2CIlhnJgbEg5oleYxhgLqxcudLPURWEvy+HbNP3MJWTbq5evZrY8JPjw2m5BMA59MgY
        ADhHojxiIQfcfTmHKsFpOQcHB4f6glq6LyeuIDYAG4ODwgJFAgQY3vTp0wNqrUpaDnIDM6AJ0Q7K2j8A
        Ew3YvyoHnEBADS2ucS2HiCooKID30OsEwGaAf2BmfsmEgJYxXsa9AJdSA1XpFhNX5BBYb6U3ZKoJKL49
        oADDkBMB8pc0AlqOfhmPcUL80Lrxg7KhlxLfioQ7Iv9UxIB6UMiwdj9TtcHoM1LoEGSY6sfm8FouNTVV
        UwZwYjwA6K+SOBJddp20ZWIYkJN/VUkk1CqgKzOsn9FzWi4aUiAlJSVEi2mdDtKFXbt21dRPQCUGraek
        pOA9jRpHmSFglSKB60w688GN03IOgtNyDg4ODvUFNa7lrl27Nn/+fPZytmpt6sCcAK5zJM+xY8fsnR7A
        S8gpogBBDPx4UQCQUXiDaAH50X4BYhEATGLkyJHKD1OhIfNzCCQZtkpVixYtqr6W0/OQckJiQNegjH7J
        yoDT0tLSqFZuFOUyoBdcYUztN7vUEmD8jJGcJtA6+vzUqVN+juqB0YQs4h+/do+/ymPqrM7xA0M2YcKE
        jRs3BkRINAhO2KSIrAGVzJ07189Rc0BLX716FTVC5RgZ/r4fUY0n1Tu6GbBWHadCztE2fhnvt3MJJOUR
        FBuqJwCSzNtQ9u3bZ/zMdVpPrOXeevjnoQl1pCAFAZWg5exnXOk1ZSOj5SH6tyKYawm0HDNUIa3W6W+l
        Q1xT2L17t54iVuscsYSLyX3vMQnoRz7sSJATBOzhipI4J/iRYZRi9uEljRfXSQ0sBYi9gJaz7zHSO0aE
        mlU/GQLPBpuXDJE0ceLEwCcgmzdvRuzRKGWxgdG3vzVNINkv2qUGW0Y61CyclnNwcHCoL6hxLQe3Qxpt
        374dyQGxgxPoc2UYG+fQiDlz5mzatIl9PfrlKKTqY2Ywc+ZMaIefEAtwFFhF5C7D0KHDhw8vKCiI+bYV
        gytXrmRkZCg/gHNsqfg5BAw2n39T1ZIlS6r5gbG0HObREapNAFqk1xBov2RluHfv3unTp+ksBBoypBoA
        vqUtfA4FfDQPgEH7NmzYIGIHRP6ys7P95Grj5MmTs2fPplP0Tr4y4IqGfuzYsXQZInv8+PEwd0vQV6Wl
        pSYmOTLcUM81a9b4OWoajNfFixfRAGVlZf6lykA8gOhe28AteCDd+kn0WbNmUSRxKaAMjJfRcseOHZs2
        bRocnSQmKWrcJAlMVTM7aDdwX45Q1xt3SKISpnxJSYmf9rvfrV27Fm/jZPl5ddTvKLL+qLMU58S/WgGk
        AhcpSwbAxAzYVktggTJWcaTveIA17ZEJOcBEpmnMiAxbfJCHUduxY4fuw6OOFi5cKONJRThF35fTWAN6
        h9yy5TGCcOXKlQwZqbidGGNy+WkemHEqCFJSUgIf3OAiSlE/qVSyatUq+9M6VmCko2zmSBA6LVd7cFrO
        wcHBob6gxrWcAMODi+/btw+SATGCEBQXF3MOETxx4oQ+Qo7Gnj17lJnjwYMHE7NzuAt1mvz6lefAjT4b
        5IfxKzNHdAgkVUk0hK7TdQC7rSZpQ1VSyebNm6mNahOADFhi/2B6GKBjDx8+vGvXLr+WTZu2bdsGh4Za
        hfmKVE0B0QhrhLRBy2Dq0Dgjj6uPW7du0Ue6Fu1DrtAQ0bJ//366HC+cYoJqYZyqhCPO37lzJ2LeT641
        JP6gwQbCD9tkXjyQigfsG9fMF11XhnhQBmaiiRNiiXm6fv16JVFPIITwmJkdzLjAJwXMuCNHjijUqYQg
        tB+DZIqhSXAyoFGEhJ9QAaYJBQH1M6D+1QpcvXqVCimrPOjhRxPeaEiao13jMWboo5xZgJgkMmVDAsg8
        BkUBxip39OjRiLO868RS9GjSF6WCwGpMJadPn2YxIYmmo6fG3r17SaI4kcDsozY/wUN5eTktaryIGeam
        vSBjCc1RUK0/Ym1c3+C0nIODg0N9QS1puTqEBPLvUeIxMaNKgLWj3yJ35bwHLGfNmhX+7lNiPHpv1EX/
        GyRtfKUFY2ZIrpQQ3tTwOWsW71e7NpKzodJStVStUKuVO1QVTss5ODg41Bc4LeeQHN55551Nmzb19YCW
        GzBgwMqVK6t0i8zBwcHBoTbgtJyDg4NDfYHTcg7JwX73Sb9+/QYNGrR7924/zcHBwcHh/YPTcg4ODg71
        BU7LOSSHs2fPIuGk5fr37z9z5syaesDSwcHBwaE6cFrOwcHBob7AaTmHJHD58mUix2i5gQMHLl269NH8
        9peDg4ODQ2I4Lefg4OBQX+C0nEMSOH/+/OzZs4d472rnOGbMGPPD6w4ODg4O7y+clnNwcHCoL3BaziEJ
        3L17t7y8/GQFkHb6eSsHBwcHh/cdTss5ODg41Bc4Lefg4ODg4PAkwWk5BwcHh/oCp+UcHBwcHByeJDgt
        5+Dg4FBf4LScg4ODg4PDkwSn5RwcHBzqC5yWc3BwcHBweJLgtJyDg4NDfYHTcg4ODg4ODk8SnJZzcHBw
        qC9wWs7BwcHBweFJgtNyDg4ODvUFTss5ODg4ODg8SXBazsHBwaG+wGk5BwcHBweHJwlOyzk4ODjUFzgt
        5+Dg4ODg8CTBaTkHBweH+gKn5RwcHBwcHJ4kOC3n4ODgUF+waNEitNynPvUp/3+HWsC7777rnzk4ODg4
        ONQynJZzcHBwqC9YtmwZWu7jH//40aNH/UsO1QPK7cSJE6WlpXv27CkrK7t9+7af4ODg4ODgUPtwWs7B
        wcGhvmD79u1f+tKXnnrqqaZNm164cMG/6lANnDx5csKECUOHDh0yZEhmZmZ5ebmf4ODg4ODgUPtwWs7B
        wcGhvuDMmTM9e/Zs0KDBBz7wgfHjx6NDrl+//s4777jHApMDDpw2bVp/D3PmzLly5Yqf4ODg4ODg8Ejg
        tJyDg4NDPcLp06d/85vfPPXUUyi6p59++sUXX0SEHDly5OrVq34Oh3DAk/PmzRswYMDrr7+ek5ODJPYT
        HBwsPPDwrgf/koODg0PNwWk5BwcHh/qFixcvNm3a9KMf/ShyDlH3kY985EMf+tDnP//5mTNn+jkcKsP1
        69eXL18+dOjQwYMH5+Xl3b1710947OEUxSPDnTt3tmzZMmLECAT/5MmTT5w44Sc4ODg41ByclnNwcHCo
        d7hw4cK+ffuGDBnygx/84LOf/ewHP/jBT37yk2PGjPGTHyHqnLQwBt+7d+/q1as3b9588OCBrjyewMLF
        ixePHz9+9OjR69ev96861D5OnjyJz/t52Lp1a3LvxVG8lZeXz5w5k0GcMmXKgQMH6tBnBw4ODrUNp+Uc
        HBwc6ikuXbqEotu2bVthYSEs//26b0C72dnZs2fPTvOQl5cXzXrRS2RbtGgRGebMmaOcYO7cuTk5OcXF
        xdevX/ez1j7OnTuXm5uLGRkZGfPnz1+6dOljq0gx7NChQyNHjhw8ePDrr79eUFDgJzwRiHb74zMQb7/9
        9pIlS/A5WLZsWXWeYb527RqTgnoGDBgwcODAjRs3utelOjg4GDgt5+Dg4ODwvuGdd95ZuXJl3759ERuD
        Bg2Cqo4ePRry6idX4O7du+iQPn36kGfIkCEjRowg26hRo4YOHUpZTjIzMx/ZmzlXr16NnTSNMZgNw75x
        44af9vgBoS4jhw0btmXLFv/qkwI0PPGzfPnyFStW3Lx507/6foOo3rBhQ//+/RFgKLpqRibFMzIyhg8f
        LkFeVlb22H524ODg8OjhtJyDg4ODw//f3n1/S1kl79//zw0zY2IkHQQk5yA5g0SRoOQMAkpQQIJkJYnO
        8zpd7f72nIPMPKMc7Y/X+4defequXbt27Zu16mLf3f2HceLEiYGBAdKIJCPVoAMefsj2+PHjOXPm8Hnz
        zTdpEq3tli1bVq9ePWnSpOqYXdqxY8cIaKqLFy/Wd1easfD+3Llzz58/73r8yVi2bBkhh4kTJ548ebJr
        /T/BTz/9dPz4cdq++PP8IMSjR4/c2O7P7du337p1q2v9XxHt9OnTmzZtolp37dr1448/di+EEEK0XAgh
        hD8KXSmlQb9RaKWOSstdu3at6/EL2tkxY8bU1dmzZ1+/fr3sR44cIeTYvU6ePPny5ctlf3Vo0Oki08Eb
        OXuzc+fOp0+fdj3+HLSjm5kzZyovtaxuly5dKuNv4RUdCv0PYesbaOyCG+Dtt98eyeds/1hyLhdCaETL
        hRBC+GM4fPjw2LFjdeHvv//+tGnT6I3Sdfv37x/yFf937twhmVzVuPc+tKZ9HxgY6ByPDT6lefbs2bK/
        IohM09GT0vigQ0fT/W3Dhg1DPsL0/Pnz77777saNG3VYZDmWIG2WW7duvVB1PHv2rPl4vXv3rlHff/89
        4WpIO3IkGm/fvs3Ip/dhVMPNVfYWXxrjxo1THEWmQns/tUUPiClUzViJcfhvvlfjhx9+MMpcFVAcU1cc
        S3hhhPLhwM2kMrSQsvizlPBPP/3EjUVkbxi5ycqfFbndFS6Z+tSpUwSq+lsgqf/VV19xVrd2ciWgEolv
        OExtRpbhR1ss9+7dM7w8uZl6yLfa+JOxolWdpfTo0aPu5Q4S643DRz7W0qu+vDdKBAioXOpZpYCYVY2G
        VbvK2VW3WW+oIdN5488hqzN7u68E8Ubphvz7CiH0L9FyIYQQ/hjmzJlT8mzu3Lmff/55qQ5absuWLXrc
        rlOnYT1y5IhLnOm9Tz/9tAknjXIb5Y1uvuyvArpoyZIlJeSmT5++adOmefPm+dPUH3300ZCeXtNcn1Ij
        U3X/u3fvll6dj2H58uVDBI/hx48fJw6thQNP4vbAgQNr164dNWqUPz/++ONq4k+fPi3UP//5T8Z169bV
        cJw7d85cnFVJbiz8pUHF8Xz33XdVuDxhLVevXl25ciUtbUY+UjWvHTl06NB/lHM2yChDLJwqoBLlU5Wh
        dY8ePdr160AC0RJr1qyRgyn4SFIlTfReh8mTJ0ueJ0mzdetWPjytVxy6XW7+JNgmTJigIJXbyZMn/clu
        adaLykcaCxcu/Pbbb/m4bS5evLho0SJGw2uNhixevJgIHKLTVHXGjBmWwE0CXrlVnELFLl++bL2jR48W
        zeaadPz48UrR9eisdN++fe4NcTiI482kSZNskwp0nTp7vXPnTlPAJVOvX79eTfiLPHHixIMHD/ZuAQfR
        YLFq0qSacvlT2uJUzl6nTJnyxRdflIMbwL8jwdXKWA6CmGLmzJluyOGCNoTQj0TLhRBC+APYtWuXPr4E
        wJ49e6iydsKm0fdn169zsKCzr36d//nz57sXOl/FqUN1SYdKEA5/OPP3QptOKJpLTywHvTidYEbvZTV1
        6lRJdl07UEqcXSLJdNLlNqg5flnF5s2b2/GLVejpex3qVX9fJfKqXKXlTM2uaPryXiHhPaHCmX3Hjh0s
        mvX9+/ezKA4OHz5cnub1nltNh96pYTtefm5jg8xlgVZHGNSoimAiUpMa6bp2RKYKNIdyRolbC2kPf9r0
        BQsWuMRuCjpTtBoFdguvOtOBhlta91oHDizqo1D0Es0vTvdazxoZ1fPIkSNVT3zyySfSaM7lybJq1arv
        Oseqdv/ChQukcgtSb6Q3ZsyY+hTi/fv3Z82aJcgQHzBaY1OGtnv+/PmuspuCgO91hsJeuXKlqU3FLNUq
        JeK80ib27LL1DpmODzVb/xDcsXTsEId6w426jpwL4f8A0XIhhBBGFM2oxnfy5Ml6yrfeeksHrHO9d+9e
        fbsJNPT63a535+vdFy9eXD2oppaIKru2fv369dWCa3818a/umwx1vZSbuXTPEydO1CU/ePBAI64nlpV2
        vPcg0SXKqpSGdt+bKVOmEAOa/jpPM2TGjBmV7fPnz0tL8GQ3i+GnTp2qsxQW9tGjR58+fbqCL1++XED+
        pE7vUZtkFLPsBw4cYKHHGA0HyVERaABpKKPcOEvj2LFjN2/eFIpWqWKSSb1aegh0Qj0QKzdIZuXKlZZP
        ndJI5hKhHRjaO2vn1hKza3v37vUnI09xVqxYUZJJGqZmhExgf7/44gvR+DMaQsXRoqVzKFULGUzijTeo
        UzeVkqrn48ePP/vss5pCPm4nQW7cuFFyl4VdVjwFUWq3Ime4zYil+r8DfwpS52NkmC2orAYGBlTMFMo4
        fvz4SZMmcQBNK7JqEJxr1651S1sOCVdpiybbkmEyqQNYlwzBhg0b+CsLY9k3bdpUqvXWrVvLli1TJUYF
        bOqrTvZquH8433zzDflqgYYTsRyuXr3acnYvCa4+Mpe/IdzElElFCyH0L9FyIYQQRhQ9dD1Kp8vUU+7Z
        s4eReFu0aNFgJ/vGG1rk3sfbtLOa0epKCb96XI142LhxY7XyGujp06frwsv/f6Yd1AxBQ3/kyBGzyEHa
        pBcLxaUF16MzysGfXe9Os04V6JhdMops+PLLL9kvXbrkfXXSVlTNOpnBWHG80XB3YvyLkKiDKZc4t1Ma
        8kAdGKdOndr7TKmGnl16hFY9skhvKFfVh5GYZJQb6VKVN52UKjI1RZLVGl0tcfVCSg/wrOmMaqqjRBGN
        8dFHH7GoUmlanmhHi9a4Zs0aCYD8I3IG43YeTOUmAVj49u3bqxrkVp0i8t+xY0dpYNKFejQXozR6Zbzl
        twyVS2K1xq+//nrevHkyNMTA0kWCCO5P+urMmTOdAIP/TUCJmcJ7WS1ZsqR2kxQXpKJZnSq5G8WRak3H
        jWpqJ66qPbjIToarV6+uW5cYNl1nlYMrospqmZcvX544cWJVQAHrjnLnuLflzE54l/4kI2VrRsOtSLZV
        WEUwo9klL0LlTKJTeiVKIT3r7ST1RntmNYTQv0TLhRBCGFHu3btHrWkl9ZQ0j76TUTtLGmlY2Sm0q1ev
        ljO0p9WVuqSZJlqWLl2qrzVciwwS4oU/uqXn3rZtm6aWSjTRr+GqZr33jKtRMenGepJQGvRP+1H148eP
        V1PO3is+tc4ffvhhNeWSbN/IcvHixfowlVACllQga1k066NGjaoTlcIoQdgFmTt3bhmVrpp4JVKE3iXX
        OR772rVrS2zQctSg4YJYJk3CTiJWkeV86NCh0gYQmTYWufxfouVoj/oEGiyn90CScLIdta3+pBPqAEpA
        qlJZyo1dGuwyQSkoualnrVfkhQsX2vfyp0Kti50zGVMa7MqVKwsWLCh/yq0dWKlqLUQQr71fhyOgjZah
        OC7VEKrSe5hixYoVpbp7OX36tF0WzSgOw8WPGesETBC3ZVsmOFtmVWD58uV1q1PvPGs5vf8H4Y0Cmsgl
        yy+Bpyb+sfAXYdmyZbVf9HPFpNPqv0KGYJQ4lTPnVhycOHHCSisr6jpfghJCvxMtF0IIYeR4+vQpMVCt
        pJ6yffiNLCmZoZGFTr3Z9frV+8KQMWPGjBs3jvLhTCroxXs/QdeLxpcPPfNy9Mr6+1WrVnWH/Tva8cOH
        D1diXtsXS4B+M7Ds9exf2emB9iwlGdaOjM6dOyfh6rBNx58MKEECKrH3cO/YsWPkmfiwxjKeOnVK+64a
        Im/evLmMSkQGKAi74E0Q0mNVN+JKbVmIGcLVXIbLsPcLOWg5Wrr8zfgSLUcrcuBpL7Zv3961dr5rtMRn
        03K0q3wqIKncdKNVb9q0SRFcVfw6XSRdrKgSAA1WipQO2bdvX2lmV9vDt03rCqKGTa7UQZYZrXHSpEm9
        0kuVCHtDTC1UDZGYUojP36Vp06a5P+mxmt3wbdu2tSHsvfoZgrg/XZUeN9HabQDvha3ItLeq1v0vPf4y
        b9IUX3/9dTuXsyI7wmjj5GZqr5988oms3CSSLAvt13vPFDQ8NWsXzOt1SM5kXtNybpVouRD6nWi5EEII
        I4eGlRrRrWolR48erVEmWgghPeuSJUsYXdKAMlYD+vDhwzq5qiF68XXr1tE2hIee+MSJE/WA3wvR+HJe
        vXo1/5fA4eOPP67nEnupBAiA+nFwCXjdvXv30aNHDxw4cOTIkRIklZiJWldN77EU+/fvb829bPXfOnho
        o2kbk2rfS3iU/mmojJ6bBqA0rLSMRr333nuMXtuBjDw5t46f8mEkQuiEysF09YAl0Usf+pNx3rx5mv5O
        gEEoMaVodX6Jlqvv7ZAz8dl7ikXxktkiiE+pyorSG5y+c/5Wn3KsktoyEped58yZM+sg6+bNmyrAIgFa
        vRXz1q1bK1assOSyN2FsF9w/ppOJ7WtC0daU3RADy1hcvXp14cKF7KZW2xpi4e4lRRbfpbpKStVxmSHe
        l/Syuk6Yf8P9SdwOLrLzLGutpZYJZaxL9oW+ImJZ3MNV57ac8rdHdSe4pIBVgR07dqgJfxmW6CXGWp1N
        zTIEcVTVQsSx3V3rL7g9SuZBqKYkQwh9SrRcCCGEEUITr+2uzlgn6s2oUaN03iDwtNfs0IZqfOsZM618
        +7SS/rX3ib7fndaCN6jB+ipIs9fJjJa6svVKUFXn7ZUOacdc2uU6+uDf+yUuFarilLZhGTt2rPVaYO/B
        oHmpjoowfvx4yrbsK1euHFQb//gHIzFZxidPntQ3KJYzCceo1KRm5SbP+qLI06dP13kmli9f3qvlrl27
        1h7wE+TXvvtE61/iE4sWLSJOuhc63wYpuNxIBZqTgiWlKgFFa99YA7LNqmt1fEo3EvntwVTFbBvNbnU8
        +XvTZqynNCVsut6HRYltFv4ln3r3tI7yKtS0adPaEIv9/PPPabZSdK5aBRFFD5NPlLw/JTZ9+vQ6rOvF
        WNkaAndFe/4WKuBOqIAmlSTj5cuXJ0+eLG1Gqrj3fyIoK7OwG9JU1urVq91L/AcGBmq/Ss8LKKutW7d2
        hv4bp06dmjRpUuU8Y8aM3gootYLbO7OoT+/BbAihT4mWCyGEMEJoppv+0fiOGzeObCi0qvr7auX1qYRN
        tfhXr17Vdpf9gw8+GOHuU8KkS3X/Mu/m+gtjxozRMVezTghVwt9++63mnp3RAtuhHMVFXJEZ1iJUGfXl
        9IP4mnU6rYygx4ytfp2WqDMiaM0NL2M97alT//LLL6s758+hTm/u3bs3b948ufEnYOpL6oWtBEDL9T5f
        51J1/+KsXbu296tEeqEG61CI8/r167vWDrSQVbCb7vz58yKQo1UcGq/EJAgSqxbBJc7elzwzhLqQADvB
        3zTbuc5PGojs0po1a1rOfJSRXQFrdcXOnTsFR2m5rrWDS4IY4tK2bduGCDPKjQ6sxLgRWmroBqgPKLLQ
        csPVvhuyDiohkzqXKwx3V7AbazerApbDjYXdTd6WWceP9tHsylWPhtpZN78qMdKx5Wnh1J2U2F+o5U6c
        OPFrOZOI7tu6JLemZkMI/Uu0XAghhJHg5s2bS5curW5VM937ZSGF7l/X7qpuWw9aRxzaWfKj0yoPPmA5
        /NNBrw6yQd+sY9b4SridgzWqj6++XINeKyKl6uNM7PrvpuXa03rs7bRk165dNC1nS64HAgkMlyz/tdde
        E9a8lGEnwCB1/iYInVay4e7du1OmTFE0sC9btqy+YOP27dtl579o0SJSgfHUqVNkAAvsRRMScjNLbQ2B
        dOPGjeGipahfBZStnJcsWVKSjySwavZayIYNGxjtlHkFtAqX2rmcN/5krITbQVaJSXYcPXq0xAzag6mC
        79+/v+xKrfKGs9ejjxIubUYcsnM2itpsC+n9HB19Xud+P3ZoPko3uvMtJsLSut9//32vmKefW1aWXO8J
        tjpBRa9kdVW1GSvali1b6ta1nHJG7/EjlWVqniBZ67Zxyw0MDCiLBORTnps3by4tB/dnGa3dQiol81bO
        dlnO7OVju6n0up9tgSIPP2YMIfQd0XIhhBBGAt1qPS1WQqKJnIa+Vretx+UzefLk6v7rS/8Y9cQa4iY/
        RoDe840hz6oVdBQB5qrcCE6KiJEmGTt27GCj3XmAsHXS1WFXX07kVDQ1IV9ZLJlOIx6IIjqNpdPtD666
        9zNRM2fONJ2r48ePP3DgADlXJz/coLz1IB/oMX8yiqzjrwdWCScRKjjxKSXGa9euyYdFZOkp8kuOa8gM
        cogn6AGRSRQ5K5QIIntTYck8VyXALgfKQQ579uyRubGdfAe/4r8EMGfzUl+c0bvL9cCqIXI7f/58yQ8L
        sfCSJa66SehqN4xqnz59etKkSbVGta27qIQcC7tJ20cNSR1K7Ny5c7ZD8eVQuYlsXnVQ4Tlz5vizxqq5
        UXTypk2bBN+2bZs/9+7dW6M42CwFoak+++yzkqxwM5eWFpDUdKt0Vj84yyeffCJn6yK8DRdHepzr9tj5
        y+/CC9WO4A4ePEj/Kyn/Dz/8sD4JaZk2UW0lTF7691XFbDmrD50vVA3k0AkWQuh7ouVCCCG8cugcTXN1
        qxrTK1euDD8T0EwvXryYQ/nosPls3769ulK977Fjx5o0etUQHnrf6oYlo7PvXuhB175582bZyq0dMWmd
        DdExs+jymy4SQZvuEnv7HJcIrSxeOWj0jR03bhw3b/zZ+12RCxYs6JRnEGUpZw19SSmUjsL169c17pVY
        +1LQ+gSXsUa5RAWNHj2aWqhs+RMz7ejphcydO5eziQz3Kme6okRjSQ4CuOva0brCmo6nVK3Oe2/oycFc
        Ox9aK5FD0dU3TDIODAy0HERYuXKllRrYnjzE48eP6WTTVRoSEHbVqlVkDB+bwl558rFGDt7zka1NUXyc
        PXu2bq2qg0tN6ixZssTUNdf+/fv5VMVEqIp5b4/IKg4mpR6rqiJYplC1cBZCrh6+hcUuX77cpQpoIv4C
        SpLFewOPHDnS/mnUT+Gzjx07tn1mEtR+BfdaC5SYCLNmzaofcjh+/Di7gYwt50rPkCG/UhBC6Gui5UII
        IbxySAg95ZgxYzSU7WeyhvDDDz9U464b1nTScmXRyOq2dcCa5q7rq4cmMa+Ezat1fqHCoSg+++wz2UpP
        F17P133yySfVOlvItWvXSrOVXRvNTR2OHj1aRlAUc+bMoQ3Eqb5869atZjRvNfG0RNe1880Z1JQ4nMXn
        v27dOu27N2Zk/LLzxZIS27Vrl+QlpuBNS+Dhw4cUyNSpU3X5hshHHMyfP9+S/+Oxp3zMLjEZrl27tukE
        xtmzZ9uy3p31vqphLV75UHHkItFlCAibOjD86quvaJ7ypFdbtalQYU3KTvi1YuLMmTNErExq7VZKwNTA
        O3fuWP7Ezvf7q4k1cuNgOhnWJ+6EsliqjyRz1dQ8+UuD0r59+3abS8XqY41yMBFPpSOe3dKliHjevHlT
        cJmopOkqlATEL7FanD9/vj7/ZlO8cW+LyVlYFiU9efJk7522aNEi0UArWlTX2vmfkaVLl5KCFlXDbcSM
        GTO+/uV3zCX2xRdf1BO5roogN/O60w4cOND7hSshhH4nWi6EEMIrR0d76tQpGsZrHR0MR+tP/OjIuenU
        dZwsuvkjR44w6k17W/lXjW7ejKbWXtfDk8PRN+uwZXusQ314zOqM8qcl9J5+aPeJJc7EW310qlFzWSMH
        as2qtfV1okL8tE+UwYxKpIbic6bc6vjo9OnTIhMq1aabl1slJvKQx1mJGTGlZ0YOFiil69evv/ysRvGJ
        VVqF6vC6Y8cOcxEnNYtoqlQb1LbJG0KITqs9lSQf4q3l9u2339akCmItjBb1Tc/voZFbFy9eVM+qTLPD
        KniyiyO+TDg3B3sh2yqUq9bIoT272Lh3796FCxdaEBmapZ3INWql5QYl5Va6twUkmNV/SKhSqo3Dhw+T
        XnUcR9C6JQzhDDFVY8h/GYhQ95IatsO6wlrqnqnpxGl3aaXEn8W+DGbc8eGv8u3LY0II/zeIlgshhPBq
        GdJA//kZnvB/uYRfc/s1OylYcrEdldBdlNXYsWNJJk3/+vXrhzTxfxRE17ZffjX79ddf7z0tHHn+JHfU
        /9809uzZUw9/vvXWW+03A0MI4bcQLRdCCCH8MRw6dKief1u5ciV1RNdt3LixnmMk5KZNm1bPTP4ZePbs
        2fLly2VFiowePfr48ePdC+G/ZtOmTXWwace//vrrP4kiDSH0NdFyIYQQwh/D3r176/Na9cGn0nVvdH6K
        YPLkyWfOnOn6/Ql4+vTpjBkzKjdv6vshw3/PrVu3FixYMKrzvZQTJkzoWkMI4bcRLRdCCCH8MVy7dm3z
        5s3Tpk2j6EaPHj2mw5w5czZt2uTSn+TpyuLZs2eLFy8e2/k+GDnfvn27eyH8d9y8eXPFihXvvPPOwMDA
        kJ9ZDyGE/5louRBCCCGEEELoP6LlQgghhBBCCKH/iJYLIYQQQgghhP4jWi6EEEIIIYQQ+o9ouRBCCCGE
        EELoP6LlQgghhBBCCKH/iJYLIYQQQgghhP4jWi6EEEIIIYQQ+o9ouRBCCCGEEELoP6LlQgghhBBCCKH/
        iJYLIYQQQgghhP4jWi6EEEIIIYQQ+o9ouRBCCCGEEELoP6LlQgghhBBCCKH/iJYLIYQQQgghhP4jWi6E
        EEIIIYQQ+o9ouRBCCCGE/8fPP/987969W7du3bx58/79+z/99FP3Qggh/MmIlgshhBBeIYTBs2fPHj9+
        /OTJkx9//LFr7Vss5+nTp7Uc6+paRxbiyuyvrqTffffd1KlT//73v//jH/9Yu3at9XYv/AbUTaqV9u8S
        MIQQEC0XQgghvEJu3Lgxb968NzqsXr26a+1bbt68uWjRolrOsmXLutaR5fjx4xMnTiS0Ro0a9cknn3St
        vx8rV64U/O233541a9bFixe71t/GnTt31qxZ8+abb6qbsF1rCCH8NqLlQgghjCjPnz8/evTopEmT/tlB
        Ow5v3n///cmTJy9cuHDbtm2PHj3qev/rX0+ePDl8+HC5vfvuu5cvX+5e+HfOnTunRdZ/v/fee8uXL+9a
        f1d++OEH6bWcC3+OGzduxowZa9euvXTp0s8//9z1/oUtW7ZI6W9/+9tbb71FBXWtfcuOHTus2nKonTlz
        5nStI4s7xOzqOXr06GPHjnWtvwc//vjjvn373nnnHTfS9OnTv/rqq+6F/4LhW9/LgQMHxo4d+/cOAwMD
        XWsIIfw2ouVCCCGMKM+ePVu/fv2bb75Zz7CRZ6WOvKEQoJOeOnXq2bNny5+u07u3Sxs3brx//35d6mXV
        qlVvvPGGFlxMPl3r74qUiAfxKxNpg04jKl5//XWvY8aModwePHjQHdBh69at/F2y3u3bt3et/4nvvvvO
        KiheiuJVHD39z9ByKmA5dnDz5s1d68iycuVK1ZbGxIkTb9++3bX+Hnz//fd21t2o8l9++WXX+l9z8OBB
        +nbChAlLly69du1a19qBlhO26rZixYquNYQQfhvRciGEEEaUJ0+ezJw5k+6icMaOHUunnT9//ujRowSe
        BprgGRRtf/vblClTqpn+6aefvv7668FH+t54Q/uuj79161aFaly4cGFgYMAoLf6MGTO+/fbb7oXfj59/
        /pmM0ehXepp1aZ84cWLPnj3z58/XoDNKjwPB1ntEQ5WdO3eOJ/+7d+92rf+JkydPkgSEolWbq2v9E0A7
        teXcuXOnax1B7t27N3v2bKJdwX/3g8Hnz5+rPL755pv/4ZN4y5cvdwPTbOPGjVOfrrWDtN3P6vbFF1/c
        vHmzaw0hhN9GtFwIIYQR5enTp++//z6JouudO3duO764f/++NpeEo4vg6rp16+pbIh49esTy+uuvMy5a
        tGjIiQeWLFmis3f13Xff3blzZ9f6u0KeLVu2zBQgsQ4cOFB2Hf/Vq1cJ0ZJ5xOSHH374kg9ZvfxJvMbe
        vXv/+c9/EodWRO52rR3+ywgjw8gnc/r06fqwnGq/8IDrj6qPeWfNmuUGAJH5p9qmEML/VaLlQgghjCg3
        b97UhdNyWt5Vq1Z999133Qsdzp49Ww9bujpt2rQ63Pj+++9HjRplCPuMGTMuXbpUztAx37hxY/To0cSe
        /n7p0qW/70N3vdRxIi33wQcfHD9+vGvt8ODBA4nJQYZEXXv4kNKzokOHDtF+33zzTRlRh42nTp06fPjw
        wYMHjx07Rv5Rs5Zz4cIFwefNm2eiirZ48eKjR49yaydFT548UQSjRBbBFMYKaOD+/ftbfUQ7d+4cB8Mf
        Pnz47Nkz9fTeqCNHjpjo+fPn5dkwhTzpJQ5m5HzmzJnr16+3qyLUckxXxuKHH364fPmylIwyVkp2luoW
        oZxf/uWNUuVvLsFhIRQyi+ms6MSJE+1Ik8odM2ZMqdwtW7aUcQgWe/LkSXHM3qupVEBKNYVLvd/D6R77
        6quvTNSufvnll72Py9oyC2T//PPP2W2BNVZtxbRkktt9aMts3OTJk21uFdlAOdhfYZViyHmdkhqu4OJw
        EFAR3NJtr2F4bUontcG9857P8O0LIfzViJYLIYQwcmjo9fqvv/46UfT222/v3r17+PfaU0olisaOHVvH
        X48ePZo0aVJpuQ8//LD3g0wa5WXLlrFDQK0wY2/7/nuht5aPHKiI2bNn69G7F35h48aN9Kc0JF9PRUqD
        CjKKENXlb9iwoTwtmVah/eqIr5SJVdfDmVOnTqVShDIXRPOn4VZHbxhONe3bt08dXHr//ff/2fm04ccf
        fzx37lyFNd1HH31UE2HcuHH1zSt79uxR+YpTn/Ezo0oqYNe1szvEWD3BaFKJeUNMWo5JOdy6dcuoWg4d
        XqNASdrKKVOmWJFR4vNZuXKlgZYmhzVr1rzk+VKaRD1XrFhhUssR3NLmzJljlOkMF4TCqW1VZynJ30rJ
        qoowBMJJ5nLg1vvALZ0vbXYTqcm9e/cYzc5H8SdMmPDaa68pKSptmrw9D2nXFixYUPPSk+7M+rSkOPL0
        BtK2BTC86rB8+XIDaT9VNa/IM2fOrIBwieYUVrawcD5Wx9I+FKryFOasWbPENKMIwnK2y8Rk7/aFEP6C
        RMuFEEIYOagRkkazWyplyBlFMX/+fP2xznX8+PGHDh1iefz4MeVjCLsW+fTp0+UJ7bjWtrpn3W07Qfp9
        0TFfu3ZNHy9nbTTVMVyZ6O/14tKQZPsiTR2/9OqE7dNPP2WhHM6dO2ctjCXGaAYdvPc6dQ6LFy9m9Ccf
        6zIdB6VQlidPnnAgZqo+Xg0hLYTiTAPUqHXr1g3O3amb4a5SKYLw9yd/b6pi9FKTOsQqXWGNdVVKJq1M
        KB+X+JDKZqypt27dWgPNYsYKaC5FMEW9F0Q+Jt25c2cl/0K++OKLyZMny5+zuaogphCh8hfQzpaWs8vc
        5GmIgRVhCLRcTS2rbdu2lRAt+6RJk0R2iaYqbXzp0iV1qNm9mt2q+RS9ClwZy4fCtK1VBxlu2bLFJX+a
        jgOj2cXhVue3hCiHun/ac6FuKle5sRsoYE3NR8zal4cPH1LpYiqC+Grr/ufPwofs7z3sDSH8BYmWCyGE
        MHLcv39/wYIF1fJSBXrr7oUe5s2bp53VvGptS8uRATpgxmpz29fQ69HXrFnTGuhf6+x/O3TOZ599ppmW
        ALFBtnUv9LB7926NuLRlUlqO9li7dq3Om1EXfvDgQUbic8qUKXLmNnfu3Poel7Nnzy5cuNDCBwN1tM3A
        wIDprMsCy1js2rWr1JSY/OtBR3qjcqv06nsvaQ95VpCqD2lBkj19+vTDDz+UAGdZtUNOqqA5t6dbKdjZ
        s2fTDOVjIvXnJoc6EzOLddXUBi5ZsuTGjRuMlF5ZTCRV8rUiDIdoIVOF5SkfdWaU1cSJEw0UQeYtAaie
        koIA+7XnackzGdbstFBpNrhzGCE30tq2qr9dsCKeal6LkpIKcDOLqatEJGtbUb1KjEWendj/WrRokT8N
        IRd7/7sBpGztGh1Yz4W6Ny5cuECYGSKOIadOnWJXPXfC9OnTa2m28rXXXuNgoOLfvXv30aNHbqq2C6/o
        06EhhH4hWi6EEMLIoUMdO3asPlgLO3PmTFKhe6EHzbGr+nharvQP+bF582aWanyPHDlSnlpb0kU0lzS4
        L3mK7zciAWLS1NBGHz16tHuhB622Pp6DJOtHtPXr5BaLtpt8OnPmDCPxUEpAnCtXrnSGDno+71B/NsHG
        px1/gSYhLcSvgL2nmnVuWUWrB1PlvG7dOrOXXVXrbO3hw4d1tCWO6pXKKs1WzjaoVyP99NNPLTFyRSiz
        i3bixAkW0UjEEjb0T/uo3p07d+wvu4DvvffeC0V7Qa4IKKzFrl+/vmv9179oLUVgl2f7XT76xwIrh5f8
        8PrVq1frS3QkYGw9SymlVatW2SMpWbj6MCqv9BjJqv3793dGD6JEVsRep382yF1nIGqxFKDF2pE6eYMd
        qSEqKYEyFiXp5WyWffv2sSgpwSZDa1HAKmbhUj056YYRswa20zzs2bOH6GWXee/tEUL4CxItF0IIYeT4
        7rvvqsPW9WpPh5yr6Jg1x/pUV/Wv9En9WHM7FtNJv/POO3V48uDBg40bN1Y0rfmv/YY4tm3bNjAwIKxO
        +iUQCfPnzzdXd1gPjx8/njVrltklNn78+FJlQ6DfqjUfNWrUjh07yqhNN0rbTdTV43C0nCBy5kxv7Ny5
        s30yqtEOXmTV+5Gw+oxWyZshv+1Wv4tgIkWrz/LJuU44IVQTKtTXkiVLai0kWamvs2fPVlh2CtDYch5C
        HeiZhVypgp8+fZoFolEpJZBgUUQUu5hSeqFoBzeZS89wqqlOKQsJU5XmsrltsaqhvJXqS2TMt99+S0ny
        kQDJVL+dQMraO2OhAvSS2482rmhNGUrV9qlz3WxW6qa1rnrWUcDazaZa3TDu2+vXr9ssq5Bte8a1sXDh
        QgMtc9KkSUrNQtzW/Vyre2HBN2zYwMF0RtXDw48ePSIp7YKEDX///fd7b48Qwl+QaLkQQggjhK73+PHj
        Wmd9rddPP/20fZCp0F4fPHhQA61V1eNSR/V03PPnz2kGTS37e++9V/2rnrsabg30qlWrer9ycAjaejpN
        4/sSKD0zUh2/puXoCnNJm6gbrhtpEsJMJhx41nGilGotcm6/913PWJZ+qFbepHv37m3PAaIJM0qj9+nE
        +lEETTxpOuRskGg0xHTLly+vUPr++nyXIeI07SFV8sYWyJYmaY9ElrRAr6BqlMxW8FpOiSsLXLNmTS1E
        AXu/2ZJMIhRrFmmTQ90L/477oT5IVm5da4ctW7Y0QduEKPU+3DgcRa4zSQmoVf2XAUkvT9WQf6VKG9t6
        Fm7ekG0qIx+bYqyJ2iGntZOmhlusIvQeo0FxDh8+LKxQfIY890gHUl8u2Z2POl9L8+TJEwWsaCZqzwz3
        omJujNoU9XHP+NOr2WUrlIGq0cRzCOGvSbRcCCGEEULrr4Wt1tlrnbkNYeHChZpUPS7qy0IKY/XWjF61
        6XV4UqFYtOb66a7rMEx0qPMt8y+HriDSXhhHY13CQG9NLA1XO8QbMcBH200g0VF0Kf3Jv9ZSX7AJWvHC
        hQtz5sxhdLUcCKHexU6aNIlRv7548eLeUzuSrDp7qqP3qcWbN29q8VVDtPZjdARDqw+p1rTi3bt3TSeO
        qyr58OHD+oyWsJwJ0XrAbwiMZ8+eFap2px4UVAcCQygQzFWWKuCdO3dqdq907BDR3ti+ffuoUaOk7dX7
        rrXDypUrq0Rkap00oh7yLG3zko9H0nL1DTES4CyZb775Rqq1RqlWkpZfNxXoN2snEUkyfxKiEjh16pSt
        5GkXJk+ebKyYit++MKZQHBUWSm5ug5J/DTtFE9ZGb+h8k4q9KLkumole+AAquViniGor7Xaq7E/v3WOf
        f/75q3uoOITQL0TLhRBCGCH0+vUxpOqwhysiUqqOHVzVOg/5woxqZF1atmwZeTBhwoTqdNevX98+0PUq
        qONEE0lMx//ZZ58NmY5Q0VtbV1FqipT6+OOPy0Ie1GfVGlZKDtVPS+vpLYROK9lGmNVhke5fhPIvVICz
        IQsWLOj9RJYZS2UZVSqLuvjyyy85S5uxvlejMLDWYggFy0LrTps2rYpJIz0b9isRUARyhY9ZFKE+skjS
        0BtGgThph28iEM88zWLI+fPnf01pU1OWCUvevXt319oRz9OnT2e3WNqpnT5RueaqCgwRVL1IwNJMLQFB
        3GmEYmeewV8aaKdqpDIfUHGUm5uKG1GthoSiHFratsaktZw1a9bUQ5sN1a5Q4rtvhzyCu2fPnnouVIQ6
        nqWfuVmIElGYveeZjfZVOrZp7ty5lRvkduzYsStXrtQ2/VphQwh/EaLlQgghjBAaUC0sgaEn9mbIh+Vu
        3LihQa8G1yt90o6SoGclM9i1y4TTos53BopD3vT23K8CaWzo+R2F9sWPhZZa91/PvOm85VbfaPLo0SPL
        4c8+ceLEIaOKa9euEVEdiTGoMcpHE1/RjO2VNyg7Z8KpaTlCRfyqm2qUPJPzxo0bK2dioD1gSRSRYfJ0
        CSWH6BYaiZGzIr/wXM4yyRUpcfvggw/qTKw95opeLUepto2WbbMPh4KqutEtu3bt6lp/OTGrIrQfeFBS
        qpjRJTtSYubXMCk3CQi+c+dOdSal5Nn7JCeNJD43uzb8fxZ6uXDhQmk5yzl48OCQqVVMTeqqmEO02erV
        q411qT18S8vZbv7WQsu1r8DpZceOHRZroFHtUDeEEIYQLRdCCGGE0BBTI/ppXeyKFSvquTt9sDfa2bVr
        12qsO7pg8KvtSwA0keaNLrmae0GgR9cf10Nrr5S7d++aWs6m1oK3X/R68uTJnTt3aKdaVF1tX0ZPeNQh
        kjybwLt//z751KtRiVJawkKIHxOphhXp4K2Ucc+ePXwohzoJJHjMwj5nzpxKgwBudTMRxVKC8N69e+2r
        R8iMphYkQCYxuiRbwxnPnz9PwhkuyMDAQH3ro4JXtnUGRQTyqdln//LFJ65SGkZh5syZ5UkUbdq0qXQU
        6Mwhp1i9rFmzxrygW+oLY9wMlmCltdfi1OGkyqitUrOjfrrgJViarCoHOVfmitx7aFYP9Kr21KlT28bV
        Den2s5D6ShL7tXXrVp5CiTP8PNDu1JOZpiDgSVxGt0ddrfvWJQUsDUzLKYto7G6S9n2kDx48uH79ev0f
        R32Xqdy8btu2rR0FC+s+sTpByhJC+CsTLRdCCGGEOHv2rP61OmyKQj9K8BB4mnVNdnX/ulu9b5MTNbAg
        cvjomDvaoaudBHmlh3LQW0uv+vgPO78EoJ/Wr5MT9JKEK6UxY8b0fiUGIfRu5/cStOMy13lbFOniT8LA
        e4JBj16fcxOhTp8IifXr15f2EHP16tWXLl0Stvp7eomRv4Xv37+fqJBAlcKryO2zfPyJhMp5/vz5N2/e
        HMypo3AEKX/CrwQz49KlS4VlNGTz5s2Gm3flypU0Vf3AnWxHjRpVy6EeS55xI1MrGjHz1VdfKU5JF3AG
        WU6iDM79Inbv3q0Ctd7FixfzrJoYLmattD5+9uOPP37S+fUClxT2Px5VyW38+PGVg1Diox5xbDeMlQpl
        FtBg8rdrFy9e3LJli+JMnz69VJbFqo/hQjUB3AuhVXem3Ig08p5PfaOJuWhpCaibzWpfR6ks/Csx9WR3
        R9luBbeJFnvu3DmhJGZSb/bt2+cWUh93nVRJ6HyDZQgB0XIhhBBGAv2u7vOdd97pdNf/r72G9yzeaKw1
        su1AYwhNy5Wz9wRh99qrhBAqVYbhaZdR608JUGI1RAdPEpSPJZck+Prrr2f/8htuvRG80j/1zKQIuvY6
        5KlL1AuVWFf37t1LULVLnRhvUix6fWFN9Omnn9YBDi1RObNTLO0MR5z2CTf6pDQzKAdig39vZEJiwoQJ
        pUmuXLlSl4Rtn7579uxZ/fp2DaxRMMWMGTO8cYlwpQPLfzgU15w5c6yxghvijUwUykpt8ZQpU+pL/Mkb
        2lJKnEtRV4RfgwArNVsY1U7MGsSwjav8a/bCe3Vrx4+EFreyL1y4cPg3jtg1at/VwljT1c1JLdtNFnVr
        D5HaI/mXNh4ytU08efJk3UhUvYGu8ule7uTgldzNg5chBETLhRBCGAnIBt2tvnbwEKSDfhe6Vb27VnjT
        pk2aZi17d8C/Qx2dPn1aQ6y/r4E0wP1hv8z2KtDxm66b9C9py1lX/cEHH1AIEhvy+2DkaP0gXnmWJAAp
        NWvWLKuoONZOhm3btm3IUc/GjRsVqoZr7nt/QXvLli00ALuGnsZzScyxY8cKqONvB4NEC59Ovn8/c+ZM
        e0KPnhTQJcnv2bPnUedLGkGVUWiSYTdEzpKcOXNmBXT1wIEDZTe297N/ItCKta2WM3r0aOLt0KFD5BZn
        SYrwa3takGr1232VMBGonqtWrfKnsLRTHR7KoQQV4+LFi4cfjg3hwYMHH330kYDiWLJ8XviRRdVbs2aN
        AopcCchEYW1r6WfQsRwsBy+Upm5Oy5w6daotcH96VTra3iV2MSUgfn1YrrAjZHAtXNjy8efu3bvb/whQ
        4NQ7NSsxPl6hwiwq/Gu/ARhC+EsRLRdCCGEk0Lx+//33t27d0pr3cvv27bt371JletP28NsL0c3fuXOn
        hnjVrL/c//eCFKkZe2EBgarhbkqpITEip3zk3MSMNp2/9bYItfDWvhfGlg8M7xUPnF2qsd7wNFcLqETl
        JqUajmaETASs4UMK7pJkWoXFlGplzo1z2Tn0BqyV9o6iY58+fSoUC3v7CspfwxQmMrb8bWvdKhXTpSqO
        iVSbm1vI1eE1HwJ/biIUFbZ7rQfB5S+y6VoO/jS2bYoMW805D9msgo8pKoJX76t0ll9GYYccONfCa5mo
        gg/x8WdtSgUpN8H/Y1VDCH8RouVCCCGEEEIIof+IlgshhBBCCCGE/iNaLoQQQgghhBD6j2i5EEIIIYQQ
        Qug/ouVCCCGEEEIIof+IlgshhBBCCCGE/iNaLoQQQgghhBD6j2i5EEIIIYQQQug/ouVCCCGEEEIIof+I
        lgshhBBCCCGE/iNaLoQQQgghhBD6j2i5EEIIIYQQQug/ouVCCCGEEEIIof+IlgshhBBCCCGE/iNaLoQQ
        QgghhBD6j2i5EEIIIYQQQug/ouVCCCGEEEIIof+IlgshhBBCCCGE/iNaLoQQQgghhBD6j2i5EEIIIYQQ
        Qug/ouVCCCGEEEIIof+IlgshhBBCCCGE/iNaLoQQQgghhBD6j2i5EEIIIYQQQug/ouVCCCGEEEIIof+I
        lgshhBBCCCGE/iNaLoQQQgghhBD6j2i5EEIIIYQQQug/ouVCCCGEEEIIof+IlgshhBBCCCGE/iNaLoQQ
        QgghhBD6j2i5EEIIIYQQQug/ouVCCCGEEEIIof+IlgshhBBCCCGE/iNaLoQQQgghhBD6j2i5EEIIIYQQ
        Qug/ouVCCCGEEEIIof+IlgshhBBCCCGE/iNaLoQQQgghhBD6j2i5EEIIIYQQQug/ouVCCCGEEEIIof+I
        lgshhBBCCCGE/iNaLoQQQgghhBD6j2i5EEIIIYQQQug/ouVCCCGEEEIIof+IlgshhBBCCCGE/iNaLoQQ
        QgghhBD6j2i5EEIIIYQQQug/ouVCCCGEEEIIof+IlgshhBBCCCGE/iNaLoQQQgghhBD6j2i5EEIIIYQQ
        Qug3/vWv/w9mwhlUKdnzFgAAAABJRU5ErkJggg==
</value>
  </data>
  <data name="importante" xml:space="preserve">
    <value>IMPORTANTE:</value>
  </data>
  <data name="texto_importante_devoluciones" xml:space="preserve">
    <value>Para evitar deterioros en las lentes y facilitar su análisis estas deberán recibirse en un embalaje apropiado y por separado, sin elementos que puedan dañarlas como grapas, tarjetas, etc</value>
  </data>
  <data name="AyudaAlturaPasillo_EN" type="System.Resources.ResXFileRef, System.Windows.Forms">
    <value>assets\recortes\ayudaalturapasillo_en.png;System.Drawing.Bitmap, System.Drawing, Version=2.0.0.0, Culture=neutral, PublicKeyToken=b03f5f7f11d50a3a</value>
  </data>
  <data name="AyudaAlturaPasillo_FR" type="System.Resources.ResXFileRef, System.Windows.Forms">
    <value>assets\recortes\ayudaalturapasillo_fr.png;System.Drawing.Bitmap, System.Drawing, Version=2.0.0.0, Culture=neutral, PublicKeyToken=b03f5f7f11d50a3a</value>
  </data>
  <data name="AyudaAlturaPasillo_PT" type="System.Resources.ResXFileRef, System.Windows.Forms">
    <value>assets\recortes\ayudaalturapasillo_pt.png;System.Drawing.Bitmap, System.Drawing, Version=2.0.0.0, Culture=neutral, PublicKeyToken=b03f5f7f11d50a3a</value>
  </data>
  <data name="continuar_sin_verlo" xml:space="preserve">
    <value>Continuar sin verlo</value>
  </data>
  <data name="invitacion_uso_devoluciones" xml:space="preserve">
    <value>Para realizar tus devoluciones de un modo ágil y claro te invitamos a usar nuestro módulo de devoluciones. En él podrás también gestionar cualquier recogida de tus devoluciones.</value>
  </data>
  <data name="ir_a_gestion_devoluciones" xml:space="preserve">
    <value>Ir a gestión de devoluciones</value>
  </data>
  <data name="recogida_garantia" xml:space="preserve">
    <value>Recogida para Devoluciones</value>
  </data>
  <data name="recogida_montaje" xml:space="preserve">
    <value>Recogida para Montaje</value>
  </data>
  <data name="recogida_para_montaje_o_comprobacion" xml:space="preserve">
    <value>montaje, comprobaciones o muestra</value>
  </data>
  <data name="redireccion_pestaña_devoluciones" xml:space="preserve">
    <value>Vas a ser redirigido a la pestaña de Devoluciones. ¿Quieres ver antes el tutorial de cómo utilizarlo?</value>
  </data>
  <data name="resumen_recogidas" xml:space="preserve">
    <value>Resumen recogidas</value>
  </data>
  <data name="solicitar" xml:space="preserve">
    <value>Solicitar</value>
  </data>
  <data name="solo_solicitar_recogida" xml:space="preserve">
    <value>Solo solicitar recogida</value>
  </data>
  <data name="tip_help" type="System.Resources.ResXFileRef, System.Windows.Forms">
    <value>assets\iconos\tip_help.png;System.Drawing.Bitmap, System.Drawing, Version=2.0.0.0, Culture=neutral, PublicKeyToken=b03f5f7f11d50a3a</value>
  </data>
  <data name="altura_pasillo_mayor_altura_aro" xml:space="preserve">
    <value>Altura de pasillo ({1}) no puede ser mayor que la altura de montaje ({2})</value>
  </data>
  <data name="enviada" xml:space="preserve">
    <value>Enviada</value>
  </data>
  <data name="mensaje_advertencia_espesor_ranura" xml:space="preserve">
    <value>IMPORTANT: The type of groove must always be checked to avoid incidents in the assembly. Remember:
Thickness min. Nylon groove = 1.5 mm
Thickness min. Metal groove = 2.2 mm
If you have any question, ask to our Customer Service department.</value>
  </data>
  <data name="altura_gradal" xml:space="preserve">
    <value>Altura gradal</value>
  </data>
  <data name="aviso_debe_especificar_la_forma_basica" xml:space="preserve">
    <value>Debes especificar la forma básica</value>
  </data>
  <data name="mas_claro" xml:space="preserve">
    <value>Más claro</value>
  </data>
  <data name="mas_oscuro" xml:space="preserve">
    <value>Más oscuro</value>
  </data>
  <data name="mm_10_mas_bajo" xml:space="preserve">
    <value>10 mm más bajo</value>
  </data>
  <data name="mm_5_mas_bajo" xml:space="preserve">
    <value>5 mm más bajo</value>
  </data>
  <data name="opciones_color" xml:space="preserve">
    <value>Opciones coloración</value>
  </data>
  <data name="sin_modificar" xml:space="preserve">
    <value>Sin modificar</value>
  </data>
  <data name="tooltip_ayuda_altura_gradal_y_tono" xml:space="preserve">
    <value>Aquí tienes la opción de cambiar la altura del gradal o la intensidad del color. El texto del color aparecerá remarcado para recordarte que tienes activadas las opciones.</value>
  </data>
  <data name="tooltip_opciones_color" xml:space="preserve">
    <value>Tienes activadas opciones de coloración</value>
  </data>
  <data name="audiologia" xml:space="preserve">
    <value>Audiología</value>
  </data>
  <data name="baja_vision" xml:space="preserve">
    <value>Baja visión</value>
  </data>
  <data name="buscador_web" xml:space="preserve">
    <value>Buscador Web</value>
  </data>
  <data name="de" xml:space="preserve">
    <value>de</value>
  </data>
  <data name="domingo" xml:space="preserve">
    <value>Sunday</value>
  </data>
  <data name="eliminar_datos" xml:space="preserve">
    <value>Eliminar datos</value>
  </data>
  <data name="guardar_datos" xml:space="preserve">
    <value>Guardar datos</value>
  </data>
  <data name="horario_comercial" xml:space="preserve">
    <value>Horario comercial</value>
  </data>
  <data name="jueves" xml:space="preserve">
    <value>Thursday</value>
  </data>
  <data name="lunes" xml:space="preserve">
    <value>Monday</value>
  </data>
  <data name="martes" xml:space="preserve">
    <value>Tuesday</value>
  </data>
  <data name="miercoles" xml:space="preserve">
    <value>Wednesday</value>
  </data>
  <data name="modificar_datos" xml:space="preserve">
    <value>Modificar datos</value>
  </data>
  <data name="optometria" xml:space="preserve">
    <value>Optometría</value>
  </data>
  <data name="sabado" xml:space="preserve">
    <value>Saturday</value>
  </data>
  <data name="taller_de_montaje" xml:space="preserve">
    <value>Taller de montaje</value>
  </data>
  <data name="terapia_visual" xml:space="preserve">
    <value>Terapia visual</value>
  </data>
  <data name="texto_autorizo_buscar_web" xml:space="preserve">
    <value>Autorizo a INDO OPTICAL SLU a que guarde estos datos y los conserve dentro de sus bases de datos cuyo uso se encuentra destinado exclusivamente a aparecer en su buscador de ópticas, a fin de tener comunicación con sus clientes.</value>
  </data>
  <data name="venta_online" xml:space="preserve">
    <value>Venta online</value>
  </data>
  <data name="viernes" xml:space="preserve">
    <value>Friday</value>
  </data>
  <data name="web" xml:space="preserve">
    <value>Web</value>
  </data>
  <data name="y_de" xml:space="preserve">
    <value>y de</value>
  </data>
  <data name="alta_buscador" xml:space="preserve">
    <value>Alta/modificación en buscador web</value>
  </data>
  <data name="anular_cambios" xml:space="preserve">
    <value>Anular los cambios efectuados</value>
  </data>
  <data name="baja_buscador" xml:space="preserve">
    <value>Baja en buscador web</value>
  </data>
  <data name="modificacion_buscador" xml:space="preserve">
    <value>Modificar en buscador web</value>
  </data>
  <data name="rellenar_con_datos_por_defecto" xml:space="preserve">
    <value>Rellenar con los datos por defecto</value>
  </data>
  <data name="aviso_eliminar_buscador_web" xml:space="preserve">
    <value>You are about to delete your data from our web search engine. By accepting it, you will stop appearing in the possible searches made by the users. Do you want to continue?</value>
  </data>
  <data name="informacion_contacto" xml:space="preserve">
    <value>Contact information</value>
  </data>
  <data name="informacion_optica" xml:space="preserve">
    <value>Optical shop information</value>
  </data>
  <data name="visualizar_datos_en_buscador" xml:space="preserve">
    <value>Visualizar datos en el buscador</value>
  </data>
  <data name="copiar_horario" xml:space="preserve">
    <value>Copiar</value>
  </data>
  <data name="visualizar_datos_buscador" xml:space="preserve">
    <value>Visualizar en buscador</value>
  </data>
  <data name="mensaje_entrada_buscador" xml:space="preserve">
    <value>Configure your data here to appear in the optical search of www.indo.es</value>
  </data>
  <data name="texto_presentacion_nuevo_1" xml:space="preserve">
    <value>Bienvenido a la nueva generación de pedidos online.</value>
  </data>
  <data name="texto_presentacion_nuevo_2" xml:space="preserve">
    <value>Sorpréndete con la herramienta más ágil e intuitiva del mercado para realizar tus pedidos. Con funciones que te permitirán aumentar el valor añadido de tus ventas.</value>
  </data>
  <data name="texto_presentacion_nuevo_3" xml:space="preserve">
    <value>En ella podrás:</value>
  </data>
  <data name="texto_presentacion_nuevo_4" xml:space="preserve">
    <value>Personalizar y optimizar los espesores en lentes de laboratorio.</value>
  </data>
  <data name="texto_presentacion_nuevo_5" xml:space="preserve">
    <value>Hacer un seguimiento más detallado de tus pedidos.</value>
  </data>
  <data name="texto_presentacion_nuevo_6" xml:space="preserve">
    <value>Usar la mayoría de trazadores del mercado.</value>
  </data>
  <data name="texto_presentacion_nuevo_7" xml:space="preserve">
    <value>Realizar y controlar tus devoluciones.</value>
  </data>
  <data name="texto_presentacion_nuevo_8" xml:space="preserve">
    <value>Controlar e imprimir tus facturas con Indomanager.</value>
  </data>
  <data name="texto_presentacion_nuevo_9" xml:space="preserve">
    <value>Consulta con tu delegado comercial, o tu gestor personalizado, la opción más adecuada para cubrir todas tus necesidades.</value>
  </data>
  <data name="tooltip_boton_copiar" xml:space="preserve">
    <value>Copiar de lunes a sábado</value>
  </data>
  <data name="configuracion_hojaPedidoIndoscan" xml:space="preserve">
    <value>Hoja para IndoScan</value>
  </data>
  <data name="configuracion_link_hojaPedidosFax" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/Indonet/Enlaces/HojaPedidosFax_ES.pdf</value>
  </data>
  <data name="aviso_texto_no_copiar_pedido" xml:space="preserve">
    <value>IMPORTANTE 
Puede que el producto que estás solicitando haya cambiado su oferta desde la fecha de realización del pedido original. Por favor, verifica todos los campos y tratamientos para asegurarte de que las lentes que seleccionas son las correctas.</value>
  </data>
  <data name="configuracion_link_catalogoAvista" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/Indonet/Enlaces/AVISTA_INDO_DIGITAL.pdf</value>
  </data>
  <data name="configuracion_link_productosINDO" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/Indonet/Enlaces/PUBLICIDAD_INDO_DIGITAL.pdf</value>
  </data>
  <data name="configuracion_URL_catalogo_0803" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/Indonet/Enlaces/CATALOGO_INDO_2019_DIGITAL.pdf</value>
  </data>
  <data name="aviso_reposicion_color" xml:space="preserve">
    <value>Estás realizando un pedido de una sola lente coloreada. Debes tener en cuenta que puede existir una pequeña diferencia de color entre ambas.</value>
  </data>
  <data name="conforme_diferencia_color" xml:space="preserve">
    <value>Estoy conforme con la posible diferencia y deseo continuar con mi pedido.</value>
  </data>
  <data name="nota_reposicion_color" xml:space="preserve">
    <value>Nota:  Si tu pedido original tiene menos de dos meses, envía el pedido a revisar y te enviaremos ambas lentes pero solo pagarás una.</value>
  </data>
  <data name="no_conforme_diferencia_color" xml:space="preserve">
    <value>Prefiero enviar mi pedido a revisar por Indo.</value>
  </data>
  <data name="selecciona_trazador" xml:space="preserve">
    <value>Select tracer</value>
  </data>
  <data name="selecciona_trazador_1" xml:space="preserve">
    <value>You have more than one Indobisel connected to INDONET. Please, select the tracer you want to connect to:</value>
  </data>
  <data name="aceptar_cambio_y_recalcular" xml:space="preserve">
    <value>Aceptar cambio y recalcular</value>
  </data>
  <data name="cancelar_cambios" xml:space="preserve">
    <value>Cancelar cambios</value>
  </data>
  <data name="propuesta_cambio_producto" xml:space="preserve">
    <value>PROPUESTA DE CAMBIO DE PRODUCTO</value>
  </data>
  <data name="ver_mas" xml:space="preserve">
    <value>Ver más</value>
  </data>
  <data name="configuracion_link_catalogoAvista_PT" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/Indonet/Enlaces/AVISTA_PT_final.pdf</value>
  </data>
  <data name="flecha_verde_2" type="System.Resources.ResXFileRef, System.Windows.Forms">
    <value>Assets\Iconos\flecha_verde_2.png;System.Drawing.Bitmap, System.Drawing, Version=2.0.0.0, Culture=neutral, PublicKeyToken=b03f5f7f11d50a3a</value>
  </data>
  <data name="url_ayuda_inset" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/ayudaIndonet/inset.html</value>
  </data>
  <data name="ver_albaran" xml:space="preserve">
    <value>Ver albarán</value>
  </data>
  <data name="consulta_disponibilidad" xml:space="preserve">
    <value>Consulta disponibilidad</value>
  </data>
  <data name="marca_opcion_deseada" xml:space="preserve">
    <value>Marque la opción deseada</value>
  </data>
  <data name="bisel_seguridad" xml:space="preserve">
    <value>Bisel seguridad</value>
  </data>
  <data name="bisel_solar" xml:space="preserve">
    <value>Bisel solar</value>
  </data>
  <data name="condiciones_indonet_new" xml:space="preserve">
    <value>INDO OPTICAL, S.L.U. con CIF B-66232976, domicilio en C/Reyes Católicos, 4 – 28108 Alcobendas (Madrid) e inscrita en el Registro Mercantil de Madrid en el Tomo 36641, Folio 185, HojaM-657291, como responsable del tratamiento de los datos, destinataria de la información y titular del fichero denominado “clientes”,  notifica al suscriptor que los datos proporcionados serán introducidos en el fichero denominado “clientes”. La finalidad de la recogida de datos es permitir el intercambio comercial entre INDO y su Óptica a través de Internet y poder así prestar el servicio INDONET.
La aceptación de las condiciones de uso es obligatoria y la falta de respuesta a los datos solicitados impedirá el servicio a través de la modalidad INDONET.
Se notifica al suscriptor que puede ejercitar ante el responsable del tratamiento cuantos derechos le otorga la ley y, especialmente, los derechos de acceso, rectificación, cancelación y oposición. Para ello deberá remitir su deseo a través del formulario de contacto de INDONET 
INFORMACIÓN PROTECCIÓN DE DATOS 

RESPONSABLE: INDO OPTICAL, S.L.U. B66232976 FINALIDAD: Gestionar el alta en la plataforma Indonet y la contratación de los servicios de óptica. Remitirle comunicaciones comerciales de nuestros productos y servicios, incluso por medios electrónicos. LEGITIMACIÓN: Ejecución de la relación contractual. Interés legítimo en mantenerle informado de nuestros servicios. CESIONES: Aquellas necesarias para la prestación del servicio y las legalmente previstas. CONSERVACIÓN: Mientras dure la relación con el cliente y, finalizada ésta, durante los plazos exigidos por ley para atender eventuales responsabilidades. Hasta que solicite la baja comercial. DERECHOS: Puede ejercer su derecho de acceso, rectificación, supresión, portabilidad, limitación y oposición dirigiéndose a los datos del responsable. En caso de divergencias, puede presentar una reclamación ante la Agencia de Protección de Datos (www.aepd.es).
Teniendo en cuenta que este acuerdo de prestación de servicios, puede implicar el acceso a datos de carácter personal responsabilidad del cliente por parte del proveedor, mediante las presentes condiciones se da cumplimiento a la exigencia del artículo 28.3 del Reglamento General de Protección de Datos (UE 2016/679), que establece que el tratamiento de datos personales por parte de un encargado de tratamiento se regirá por un contrato u otro acto jurídico. 
En consecuencia, mediante la expresa aceptación de las presentes condiciones de uso de INDONET, el SUSCRIPTOR - CLIENTE, en adelante responsable del tratamiento autoriza a INDO, en adelante encargado de tratamiento, para tratar por su cuenta los datos de carácter personal necesarios para la prestación de los servicios objeto del presente contrato y que implican el acceso a datos identificativos de sus clientes durante la ejecución del contrato. 
El encargado del tratamiento y todo su personal se obliga a:
a.	Utilizar los datos personales objeto de tratamiento sólo para la finalidad objeto de este encargo y de acuerdo con las instrucciones del responsable del tratamiento.
b.	A llevar un registro de actividades de tratamiento efectuadas por cuenta del responsable, que contenga las exigencias de la normativa de protección de datos vigente.
c.	No comunicar los datos a terceras personas, salvo que cuente con la autorización expresa del responsable del tratamiento, en los supuestos legalmente admisibles.
d.	No subcontratar ninguna de las prestaciones que formen parte del objeto de este contrato que comporten el tratamiento de datos personales, salvo los servicios auxiliares necesarios para el normal funcionamiento de los servicios del encargado. Si fuera necesario subcontratar algún tratamiento, este hecho se deberá comunicar previamente y por escrito al responsable, con una antelación de 1 mes. El subcontratista, que también tendrá la condición de encargado del tratamiento, está obligado igualmente a cumplir las obligaciones establecidas en este documento para el encargado del tratamiento y las instrucciones que dicte el responsable.
e.	Mantener el deber de secreto respecto a los datos de carácter personal a los que haya tenido acceso en virtud del presente encargo, incluso después de que finalice su objeto y, a garantizar que las personas autorizadas para tratar datos personales se comprometan a respetar la confidencialidad y a cumplir las medidas de seguridad correspondientes, de las que hay que informarles convenientemente. Si el encargado recibe la solicitud de ejercicio de derechos de acceso, rectificación, supresión, oposición u otros recogidos en la normativa, debe comunicarlo al responsable de forma inmediata y en ningún caso más allá del día laborable siguiente al de la recepción de la solicitud, junto con la información relevante para resolver la solicitud.
f.	Si el encargado de tratamiento tiene conocimiento de alguna violación de la seguridad de datos que, constituya un riesgo para los derechos y las libertades de las personas físicas, lo notificará al responsable sin dilación indebida, y, en cualquier caso, antes de 72 horas, junto a la información y documentación relevante de la incidencia.
g.	Poner a disposición del responsable toda la información necesaria para demostrar el cumplimiento de sus obligaciones, inclusive la realización de auditorías, revisiones e inspecciones que lleve a cabo.
h.	El encargado deberá implantar las medidas de seguridad necesarias, en función de la naturaleza, alcance, contexto y los fines del tratamiento que permitan garantizar la confidencialidad, integridad, disponibilidad y resiliencia permanentes de los sistemas y servicios de tratamiento, así como verificar, evaluar y valorar, de forma regular, la eficacia de las medidas técnicas y organizativas implantadas para garantizar la seguridad del tratamiento.
Una vez finalice el presente contrato, el encargado del tratamiento, según las instrucciones del responsable del tratamiento, deberá suprimir o devolverle todos los datos personales que obren en su poder, cualquiera que sea el soporte. El encargado podrá conservarlos, debidamente bloqueados, mientras puedan derivarse responsabilidades de la ejecución de la prestación.
El CLIENTE está obligado a:
a.	Entregar al encargado los datos necesarios para prestar el servicio y descritos en el presente contrato.
b.	Realizar las consultas previas que corresponda.
c.	Velar, de forma previa y durante todo el tratamiento, por el cumplimiento de la normativa vigente en materia de datos personales por parte del encargado, incluido la realización de inspecciones y auditorías.

El suscriptor, como titular de los datos proporcionados, consiente expresamente su tratamiento en la forma descrita.</value>
  </data>
  <data name="url_condiciones_indomedcare_new" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/ayudaIndonet/condiciones_indomedcare.txt</value>
  </data>
  <data name="url_condiciones_indonet_new" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/ayudaIndonet/condiciones_indonet.txt</value>
  </data>
  <data name="catalogo" xml:space="preserve">
    <value>Catalogue</value>
  </data>
  <data name="consultar_pedidos" xml:space="preserve">
    <value>Order inquiry</value>
  </data>
  <data name="gestion_devoluciones" xml:space="preserve">
    <value>Returns management</value>
  </data>
  <data name="url_in_indo" xml:space="preserve">
    <value>https://www.instagram.com/indo_optical/?hl=es</value>
  </data>
  <data name="url_ln_indo" xml:space="preserve">
    <value>https://es.linkedin.com/company/indooptical</value>
  </data>
  <data name="url_indoacademy" xml:space="preserve">
    <value>https://academy.indo.es/</value>
  </data>
  <data name="url_rodenstockacademy" xml:space="preserve">
    <value>https://academy.rodenstock.es/</value>
  </data>
  <data name="tienes_un_aviso" xml:space="preserve">
    <value>Notice</value>
  </data>
  <data name="texto_presentacion10" xml:space="preserve">
    <value>Perform and control your returns.</value>
  </data>
  <data name="texto_presentacion11" xml:space="preserve">
    <value>Control and print your invoices with Indomanager.</value>
  </data>
  <data name="texto_presentacion8" xml:space="preserve">
    <value>With Indonet GO you can:</value>
  </data>
  <data name="texto_presentacion9" xml:space="preserve">
    <value>Track your orders more closely.</value>
  </data>
  <data name="url_ayuda_pedidorapidoclasico" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/ayudaIndonet/pedido_rapidoclasico_EN.txt</value>
  </data>
  <data name="grabado_clasico" xml:space="preserve">
    <value>Classic registration</value>
  </data>
  <data name="grabado_rapido" xml:space="preserve">
    <value>Quick registration</value>
  </data>
  <data name="aviso_cuenta_email_valida" xml:space="preserve">
    <value>Para poder usar Indonet debe introducir una cuenta de mail válida</value>
  </data>
  <data name="calcular_espesores" xml:space="preserve">
    <value>Thickness Calculation</value>
  </data>
  <data name="aviso_grabado_rapido" xml:space="preserve">
    <value>Your order has been sent to Indo/Rodenstock. In case all of the data is correct, in a few minutes the order will be saved.
Please make sure to check the “saved orders” tab to be assured the order has a number. If not, please get in touch with our Customer Support team which can be reached by telephone.
Indo orders: 900 110 557
Rodenstock orders: 900 102 189</value>
  </data>
  <data name="enviar_a_revision" xml:space="preserve">
    <value>Enviar a revisión</value>
  </data>
  <data name="opciones_de_pedido" xml:space="preserve">
    <value>Order options</value>
  </data>
  <data name="pedido_urgente" xml:space="preserve">
    <value>Urgent order</value>
  </data>
  <data name="degradado_alto" xml:space="preserve">
    <value>Degradado alto - 5mm</value>
  </data>
  <data name="degradado_bajo" xml:space="preserve">
    <value>Degradado bajo + 5mm</value>
  </data>
  <data name="mas_opciones_degradado" xml:space="preserve">
    <value>Más opciones de degradado</value>
  </data>
  <data name="observ_pedido_urgente" xml:space="preserve">
    <value>Urgente</value>
  </data>
  <data name="altura_control" xml:space="preserve">
    <value>Altura de control</value>
  </data>
  <data name="aviso_faltan_observaciones" xml:space="preserve">
    <value>Por favor, indícanos brevemente en observaciones el motivo de la revisión de tu pedido.</value>
  </data>
  <data name="texto_ex_bono" xml:space="preserve">
    <value>El tratamiento solicitado está excluido de bono y tiene un coste añadido de {0} por lente.</value>
  </data>
  <data name="crear_abono" xml:space="preserve">
    <value>Create credit note</value>
  </data>
  <data name="crear_analisis" xml:space="preserve">
    <value>Create analysis</value>
  </data>
  <data name="selecciona_una_opcion" xml:space="preserve">
    <value>Select an option</value>
  </data>
  <data name="selecciona_un_motivo" xml:space="preserve">
    <value>Select a reason</value>
  </data>
  <data name="solicitar_abono" xml:space="preserve">
    <value>Request credit note</value>
  </data>
  <data name="solicitar_analisis_lentes" xml:space="preserve">
    <value>Request lens analysis</value>
  </data>
  <data name="solicito_abono_por" xml:space="preserve">
    <value>I’m requesting the lens analysis for...</value>
  </data>
  <data name="solicito_analisis_por" xml:space="preserve">
    <value>I’m requesting the credit note or the order for...</value>
  </data>
  <data name="vi" xml:space="preserve">
    <value>IV</value>
  </data>
  <data name="vl" xml:space="preserve">
    <value>FV</value>
  </data>
  <data name="vp" xml:space="preserve">
    <value>NV</value>
  </data>
  <data name="mimetika_popup_titulo" xml:space="preserve">
    <value>Seleccionar dato de MIMETIKA</value>
  </data>
  <data name="resolucion" xml:space="preserve">
    <value>Resolución:</value>
  </data>
  <data name="debes_seleccionar_una_resolucion" xml:space="preserve">
    <value>Please select at least one of the motives</value>
  </data>
  <data name="debes_seleccionar_un_motivo" xml:space="preserve">
    <value>Please select at least one of the reasons.</value>
  </data>
  <data name="vas_a_borrar_los_datos_esas_seguro" xml:space="preserve">
    <value>You're about to delete the form. Are you certain?</value>
  </data>
  <data name="escoge_tipo_mimetika" xml:space="preserve">
    <value>Choose the type of Engraving</value>
  </data>
  <data name="iniciales_en_mimetika" xml:space="preserve">
    <value>initials</value>
  </data>
  <data name="isotipo_en_mimetika" xml:space="preserve">
    <value>Isotype</value>
  </data>
  <data name="que_tipo_devolucion_quieres_realizar" xml:space="preserve">
    <value>What is the motive for returning the lenses?</value>
  </data>
  <data name="sin_isotipo_en_mimetika" xml:space="preserve">
    <value>Withouth Engraving</value>
  </data>
  <data name="escoge_tipo_isotipo" xml:space="preserve">
    <value>Choose the type of Engraving desired</value>
  </data>
  <data name="iniciales" xml:space="preserve">
    <value>Initials</value>
  </data>
  <data name="isotipo_especial" xml:space="preserve">
    <value>SPECIAL ISOTYPE</value>
  </data>
  <data name="pulsa_para_recuperar_VMAP" xml:space="preserve">
    <value>Press button to retrieve the VMAP code.</value>
  </data>
  <data name="sin_isotipo" xml:space="preserve">
    <value>Withouth Engraving</value>
  </data>
  <data name="activa_filtro_codigo" xml:space="preserve">
    <value>Selecciona para buscar por CODIGO</value>
  </data>
  <data name="activa_filtro_marca" xml:space="preserve">
    <value>Selecciona para buscar por MARCA</value>
  </data>
  <data name="activa_filtro_modelo" xml:space="preserve">
    <value>Selecciona para buscar por MODELO</value>
  </data>
  <data name="filtros_mis_biselados" xml:space="preserve">
    <value>Pon los caracteres que quieres buscar en los datos de MARCA, MODELO o CODIGO</value>
  </data>
  <data name="filtro" xml:space="preserve">
    <value>Filtro:</value>
  </data>
  <data name="en_proceso" xml:space="preserve">
    <value>In process</value>
  </data>
  <data name="gama_de_lentes" xml:space="preserve">
    <value>Range of lenses:</value>
  </data>
  <data name="altura_corredor_mayor_altura_aro" xml:space="preserve">
    <value>La Altura de Montaje ({2}) debe ser al menos 2mm mayor que la Longitud de Corredor ({1}).</value>
  </data>
  <data name="solicitar_devolucion_rodenstock" xml:space="preserve">
    <value>Activar Garantía Especial Rodenstock</value>
  </data>
  <data name="aviso_pedir_devolucionRD" xml:space="preserve">
    <value>Solicitar Garantía Especial Rodenstock</value>
  </data>
  <data name="texto_activar_bonificacion_rodenstock_checkbox" xml:space="preserve">
    <value>Activar Garantía Especial Rodenstock</value>
  </data>
  <data name="aviso_devolucion_mas_de_un_pedido_seleccionado" xml:space="preserve">
    <value>Ha seleccionado más de un pedido para devolución. Solamente se tratará el primer pedido. ¿Desea continuar?</value>
  </data>
  <data name="aviso_no_se_han_seleccionado_pedidos_aptos_para_devolucion" xml:space="preserve">
    <value>No se han seleccionado pedidos aptos para devolución</value>
  </data>
  <data name="aviso_opcion_valida_solo_desde_pedidosrealizados_solicitarabono" xml:space="preserve">
    <value>Esta opción sólo es válida desde Pedidos Realizados o Solicitar Abono</value>
  </data>
  <data name="seguro_actualizar_pedido" xml:space="preserve">
    <value>Este cálculo no es obligatorio para enviar el pedido y podría tomar algún tiempo.
¿Está seguro de continuar?</value>
  </data>
  <data name="rodenstockmanager" xml:space="preserve">
    <value>RodenstockManager</value>
  </data>
  <data name="bienvenido_a_rodenstockmanager" xml:space="preserve">
    <value>Bienvenido a Rodenstock Manager el servicio con el que podrás gestionar tu negocio de una manera más autónoma y con una mayor información a tu alcance cuando lo desees.</value>
  </data>
  <data name="url_fb_rodenstock" xml:space="preserve">
    <value>https://www.facebook.com/RodenstockES/</value>
  </data>
  <data name="url_in_rodenstock" xml:space="preserve">
    <value>https://www.instagram.com/rodenstock_es/</value>
  </data>
  <data name="url_indoloyalty" xml:space="preserve">
    <value>https://indoloyalty.com</value>
  </data>
  <data name="wells_pedido_sonae_obligatorio" xml:space="preserve">
    <value>En el campo Referencia se debe indicar el pedido de Sonae</value>
  </data>
  <data name="aviso_no_se_puede_recargar_oferta" xml:space="preserve">
    <value>Fecha de Oferta obsoleta: Para una recarga completa de la Oferta salga de la aplicación y vuelva a entrar.</value>
  </data>
  <data name="codigo_promocional" xml:space="preserve">
    <value>Código Promocional</value>
  </data>
</root>