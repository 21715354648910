<template>
    <Teleport to="body">
        <div class="toast-container">
            <div 
                v-for="group in groupedToasts" 
                :key="group.position"
                :class="[
                    'toast-group',
                    `toast-${group.position}`,
                ]"
            >
                <TransitionGroup 
                    :name="group.toasts[0]?.animation || 'slide'"
                    tag="div"
                    class="toast-list"
                    :key="group.position"
                >
                    <div
                        v-for="(toast, index) in group.toasts"
                        :key="toast.id"
                        :class="[
                            'toast',
                            `toast-${toast.type}`,
                            `${toast.animation || 'slide'}-transition`
                        ]"
                        :style="{ '--index': index }"
                        @click="remove(toast.id)"
                    >
                        <div class="toast-content">
                            <div class="toast-icon">
                                <svg v-if="toast.type === 'success'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-6 h-6">
                                    <path fill="currentColor" d="M20.3 5.7l-9.6 9.6-4-4L5.3 12.7l5.4 5.4L21.7 7z"/>
                                </svg>
                                <svg v-else-if="toast.type === 'error'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-6 h-6">
                                    <path fill="currentColor" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"/>
                                </svg>
                                <svg v-else-if="toast.type === 'warning'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-6 h-6">
                                    <path fill="currentColor" d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"/>
                                </svg>
                                <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-6 h-6">
                                    <path fill="currentColor" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"/>
                                </svg>
                            </div>
                            <div class="toast-text">
                                <div class="toast-title">{{ toast.title }}</div>
                                <div class="toast-message">{{ toast.message }}</div>
                            </div>
                        </div>
                    </div>
                </TransitionGroup>
            </div>
        </div>
    </Teleport>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useToast, type Toast, type ToastPosition } from '@/composables/toast';

interface ToastGroup {
    position: ToastPosition;
    toasts: Toast[];
}

export default defineComponent({
    name: 'Toast',
    setup() {
        const { toasts, remove } = useToast();

        const groupedToasts = computed(() => {
            const groups: Record<ToastPosition, Toast[]> = {
                'top-right': [],
                'top-left': [],
                'bottom-right': [],
                'bottom-left': [],
                'top-center': [],
                'bottom-center': []
            };

            toasts.value.forEach(toast => {
                const position = toast.position || 'top-right';
                groups[position].push(toast);
            });

            return Object.entries(groups)
                .filter(([_, toasts]) => toasts.length > 0)
                .map(([position, toasts]) => ({
                    position,
                    toasts
                })) as ToastGroup[];
        });

        return {
            toasts,
            groupedToasts,
            remove
        };
    }
});
</script>

<style lang="scss" scoped>
.toast-container {
    @apply fixed inset-0 pointer-events-none z-50 p-4 overflow-hidden;
}

.toast-group {
    @apply absolute flex flex-col gap-2 min-w-[320px] max-w-[420px];

    &.toast-top-right {
        @apply top-4 right-4;
    }

    &.toast-top-left {
        @apply top-4 left-4;
    }

    &.toast-bottom-right {
        @apply bottom-4 right-4;
    }

    &.toast-bottom-left {
        @apply bottom-4 left-4;
    }

    &.toast-top-center {
        @apply top-4 left-1/2 -translate-x-1/2;
    }

    &.toast-bottom-center {
        @apply bottom-4 left-1/2 -translate-x-1/2;
    }
}

.toast-list {
    @apply flex flex-col gap-2;
}

.toast {
    @apply pointer-events-auto w-full rounded-lg shadow-lg p-4 cursor-pointer;
    @apply transform transition-all duration-300 ease-in-out;
    @apply bg-white border-l-4;
    will-change: transform, opacity;

    &.toast-success {
        @apply border-theme-feedback-positive;
        .toast-icon {
            @apply text-theme-feedback-positive;
        }
    }

    &.toast-error {
        @apply border-theme-feedback-negative;
        .toast-icon {
            @apply text-theme-feedback-negative;
        }
    }

    &.toast-warning {
        @apply border-theme-feedback-warning;
        .toast-icon {
            @apply text-theme-feedback-warning;
        }
    }

    &.toast-info {
        @apply border-theme-feedback-info;
        .toast-icon {
            @apply text-theme-feedback-info;
        }
    }

    &.slide-transition,
    &.fade-transition,
    &.scale-transition,
    &.stagger-transition {
        transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
        transition-delay: calc(var(--index, 0) * 150ms);
    }
}

.toast-content {
    @apply flex items-start gap-3;
}

.toast-icon {
    @apply flex-shrink-0;
}

.toast-text {
    @apply flex-grow;
}

.toast-title {
    @apply font-medium text-sm;
    color: #111827;
}

.toast-message {
    @apply text-sm;
    color: #4B5563;
}

// Base transition class for all animations
.toast {
    &.slide-transition,
    &.fade-transition,
    &.scale-transition,
    &.stagger-transition {
        transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
        transition-delay: calc(var(--index, 0) * 150ms);
    }
}

// Slide animation
.slide-enter-active,
.slide-leave-active {
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.slide-enter-from {
    opacity: 0;
    transform: translateX(30px);
}

.slide-leave-to {
    opacity: 0;
    transform: translateX(30px);
}

// Fade animation
.fade-enter-active,
.fade-leave-active {
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
    transform: scale(0.95);
}

// Scale animation
.scale-enter-active,
.scale-leave-active {
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.scale-enter-from,
.scale-leave-to {
    opacity: 0;
    transform: scale(0.9);
}

// Stagger animation
.stagger-enter-active,
.stagger-leave-active {
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.stagger-enter-from {
    opacity: 0;
    transform: translateY(-30px) scale(0.95);
}

.stagger-leave-to {
    opacity: 0;
    transform: translateY(30px) scale(0.95);
}

// Move animations for all types
.slide-move,
.fade-move,
.scale-move,
.stagger-move {
    transition: transform 0.4s ease;
}
</style> 