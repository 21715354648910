import { createI18n } from "vue-i18n";
import { XMLParser, XMLBuilder } from 'fast-xml-parser';

// import es from "./locales/es.json";
// import en from "./locales/en.json";

import global from "./locales/Strings.resx";
import es from "./locales/Strings.es-ES.resx";
import en from "./locales/Strings.en-GB.resx";
import de from "./locales/Strings.de-DE.resx";
import fr from "./locales/Strings.fr-FR.resx";
import ru from "./locales/Strings.ru-RU.resx";
import pt from "./locales/Strings.pt-PT.resx";

const loadLocaleMessages = () => {

  const p = new XMLParser({
    ignoreAttributes: false,
    attributeNamePrefix : "",
  });

  const locales = [
    { global: p.parse(global) },
    { es: p.parse(es) },
    { en: p.parse(en) },
    { de: p.parse(de) },
    { fr: p.parse(fr) },
    { ru: p.parse(ru) },
    { pt: p.parse(pt) },
  ];

  // Primero extraemos el global que seria el master
  const globales = {};
  locales[0].global.root.data.forEach((item) => {
    globales[item.name] = item.value;
  });

  const messages = {};
  locales.forEach((lang) => {
    const key = Object.keys(lang);
    const values = {};
    lang[key].root.data.forEach((item) => {
      values[item.name] = item.value;
    });
    messages[key] = { ...globales, ...values };
  });

  // console.log(messages);
  return messages;
};

export default createI18n({
  locale: "es",
  fallbackLocale: "es",
  legacy: false,
  messages: loadLocaleMessages(),
});
