export const decodeHTML = (text) => {
  const textArea = document.createElement('textarea');
  textArea.innerHTML = text;
  return textArea.value;
};

export const miraSiHayCirilicos = (texto) => {
  const cyrillicPattern = /[\u0400-\u04FF]/;
  return cyrillicPattern.test(texto);
}

export const isEmpty = (value) => {
  return !value || value.trim() === '';
}
