<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <Loader :show="showLoader" />
  <section class="section-login">
    <div class="container-content">
      <div class="firstForm">
        <div class="login-section">
          <div class="login-wrapper">
            <div class="logos-wrapper">
              <a href="https://indoonestop.jellibylab.com/"><img class="wrapper__logo" src="../assets/login/logos/logo-rodenstock.svg" alt="indo-logo"></a>
              <a href="https://indoonestop.jellibylab.com/"><img class="wrapper__logo" src="../assets/login/logos/logo-indo-new.svg" alt="indo-logo"></a>
              <a href="https://indoonestop.jellibylab.com/"><img class="wrapper__logo" src="../assets/login/logos/logo-optovision.svg" alt="indo-logo"></a>
            </div>
            <h2 class="title">
              Bienvenido
            </h2>
            <p class="text">Para empezar inicia sesión con tu cuenta</p>
            <form method="post" @submit.prevent="validate">
              <label for="#username">Usuario*</label>
              <input
                  class="credentials"
                  type="text"
                  autocapitalize="none"
                  placeholder="Escribe aquí tu usuario"
                  autocomplete="username"
                  required=""
                  id="username"
                  v-model="username"
              />
              <div class="form-field password">
                <div class="input__title">Contraseña*</div>
                <div class="input__wrapper">
                  <input
                    class="credentials"
                    ref="passwordInput"
                    type="password"
                    autocomplete="current-password"
                    required=""
                    id="id_password"
                    v-model="password"
                  />
                  <div @click="togglePassword" class="wrapper-svg" id="togglePassword">
                    <img src="../assets/login/icon-eye-psw.svg" alt="">
                  </div>
                  <p v-if="error" class="error">
                    {{ this.errorMessage }}
                  </p>
                </div>
              </div>
              <div class="checkbox">
                <div class="remember-check">
                  <input type="checkbox" id="remember" value="remember" v-model="remember" />
                  <label for="#coding">Recordarme</label>
                </div>
                <router-link to="/remember-password">
                    Has olvidado tu contraseña?
                </router-link>
              </div>
              <button type="submit" class="primary-button">Iniciar sesión</button>
            </form>
            <a class="logo_bottom" href="https://indoonestop.jellibylab.com/"><img src="../assets/login/logos/logo-rodenstockgroup.png" alt="roddenstockgroup-logo"></a>
          </div>
        </div>
      </div>
    </div>
    <div class="info-section">
      <div class="container-content">
        <div class="content-wrapper">
          <div class="title">Únete a la nueva plataforma para profesionales con la tecnología más avanzada</div>
          <a href=" http://indoonestop.jellibylab.com/solicitar-informacion" target="" class="secundary-button">Solicitar información</a>
        </div>
        
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue';
// import auth from '@/services/requests';
import Loader from '@/components/atoms/Loader.vue';
import { mapActions } from 'vuex';
import sessionService from '@/services/session';

export default {
    name: 'LoginView',
    components: { Loader },
    data: () => ({
        username: '',
        password: '',
        remember: false,
        error: false,
        errorMessage: '',
        showLoader: false,
    }),
    async created() {
        // Solo verificar credenciales guardadas si había elegido recordar
        const savedCredentials = localStorage.getItem('savedCredentials');
        if (savedCredentials) {
            try {
                const { username, password, timestamp, remember } = JSON.parse(savedCredentials);
                // Solo auto-login si eligió recordar
                if (remember && timestamp) {
                    const now = Date.now();
                    if (now - timestamp < 2 * 60 * 1000) {
                        this.username = username;
                        this.password = password;
                        this.remember = true;
                        await this.validate();
                    } else {
                        localStorage.removeItem('savedCredentials');
                    }
                }
            } catch (error) {
                console.error('Error en auto-login:', error);
                localStorage.removeItem('savedCredentials');
            }
        }
    },
    methods: {
        ...mapActions(['Login', 'PostLogin']),
        async validate() {
            this.showLoader = true;
            try {
                const ret = await this.Login({ 
                    username: this.username, 
                    password: this.password 
                });

                await this.PostLogin();

                // Solo guardar credenciales y activar timer si eligió recordar
                if (this.remember) {
                    localStorage.setItem('savedCredentials', JSON.stringify({
                        username: this.username,
                        password: this.password,
                        timestamp: Date.now(),
                        remember: true
                    }));
                    // Solo iniciar timer si eligió recordar
                    sessionService.startSessionTimer(this.$store);
                } else {
                    localStorage.removeItem('savedCredentials');
                    sessionService.clearSessionTimer();
                }

                if (ret === 20) {
                    this.$router.push('/new-password');
                } else {
                    this.$router.push('/dashboard');
                }
                this.showLoader = false;
            } catch (error) {
                this.error = true;
                console.error('Error en login:', error);
                this.errorMessage = error;
                this.showLoader = false;
                localStorage.removeItem('savedCredentials');
            }
        },
        togglePassword() {
            const { passwordInput } = this.$refs;
            const type = passwordInput.getAttribute('type') === 'password' ? 'text' : 'password';
            passwordInput.setAttribute('type', type);
        },
    },
    beforeUnmount() {
        // Solo mantener el timer si eligió recordar
        if (!this.remember) {
            sessionService.clearSessionTimer();
        }
    }
};

</script>

<style lang="scss" scoped>
  .section-login {
    @apply overflow-hidden relative bg-cover bg-top bg-no-repeat h-full;
    background-image: url('../assets/login/persona-probando-lentes.png');
    .firstForm {
      @apply w-full lg:w-[53%]  flex items-center;
    }

    .login-wrapper {
      @apply mx-0 md:mx-[14px] p-8 md:p-10 my-10 lg:my-[100px] bg-white flex flex-col items-center;

      .logos-wrapper{
        @apply flex gap-7 self-start items-end;
      }

      .logo_bottom{
        @apply mt-10 mr-auto;
      }
     
      .text{
        @apply text-lg pt-3 font-bold text-black self-start text-center md:text-left;
      }

      form{
        @apply flex flex-col self-start pt-[30px] w-auto md:w-[427px] text-neutral;

        input{
          @apply md:w-[400px];
          width: -webkit-fill-available;
          padding-left: 0px;
        }
        [for="#username"] {
          @apply text-left text-rst_secondary self-start text-xs font-bold;
        }

        #username{
          @apply  text-rst_secondary text-sm font-bold;
        }

        input[type="password"]{
          @apply font-bold text-sm;
        }


        .checkbox{
          @apply mt-[25px] flex self-start relative text-xs w-full justify-between;

          [type="checkbox"]{
            @apply w-4;
          }

          a{
            place-self: end;
            @apply text-rst_secondary text-sm font-bold;
          }

          button{
            @apply text-xs underline text-primary hover:text-primary_dark ml-[120px] self-end;
          }
        }

        #togglePassword{
          @apply right-[10px] top-[14px];
        }

        button[type="submit"]{
          @apply self-end px-[70px] mt-[30px] w-full;
        }
      }
    }

    h2 {
      @apply text-2xl pt-[34px] md:text-[32px] font-bold self-start text-center md:text-left;
    }

    .info-section {
      @apply block w-full bg-[#333334] py-10 md:py-[63px];
      
      .content-wrapper{
        @apply flex flex-col lg:flex-row items-start lg:items-end gap-10 justify-between;
      }

      .title{
        @apply text-[20px] leading-[28px] md:text-[32px] md:leading-[40px] font-bold text-white max-w-[660px] text-start;
      }

      .subtitle{
        @apply text-base mt-2 font-normal text-center;
      }

    }
  }
</style>
