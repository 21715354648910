export const dropdown = {
  mounted(el) {
    el.classList.add("dropdown");

    function clickOutsideHandler(event) {
      if (!el.contains(event.target)) {
        const dropdownContent = el.querySelector(".dropdown-content");
        if (dropdownContent) {
          dropdownContent.classList.add("hidden");
        }
      }
    }

    function focusOutHandler(event) {
      if (!el.contains(event.relatedTarget)) {
        const dropdownContent = el.querySelector(".dropdown-content");
        if (dropdownContent) {
          dropdownContent.classList.add("hidden");
        }
      }
    }

    el.__vueClickOutside__ = clickOutsideHandler;
    el.__vueFocusOut__ = focusOutHandler;
    document.addEventListener("click", clickOutsideHandler);
    el.addEventListener("focusout", focusOutHandler);
  },
  unmounted(el) {
    document.removeEventListener("click", el.__vueClickOutside__);
    el.removeEventListener("focusout", el.__vueFocusOut__);
    el.__vueClickOutside__ = null;
    el.__vueFocusOut__ = null;
  },
};

export const dropdownTrigger = {
  mounted(el) {
    el.addEventListener("click", () => {
      const dropdownContent = el.closest(".dropdown").querySelector(".dropdown-content");
      if (dropdownContent) {
        dropdownContent.classList.toggle("hidden");
      }
    });
  },
};

export const dropdownContent = {
  mounted(el) {
    el.classList.add("dropdown-content");
    el.classList.add("hidden");
  },
};
