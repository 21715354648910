<?xml version="1.0" encoding="utf-8"?>
<root>
  <!-- 
    Microsoft ResX Schema 
    
    Version 2.0
    
    The primary goals of this format is to allow a simple XML format 
    that is mostly human readable. The generation and parsing of the 
    various data types are done through the TypeConverter classes 
    associated with the data types.
    
    Example:
    
    ... ado.net/XML headers & schema ...
    <resheader name="resmimetype">text/microsoft-resx</resheader>
    <resheader name="version">2.0</resheader>
    <resheader name="reader">System.Resources.ResXResourceReader, System.Windows.Forms, ...</resheader>
    <resheader name="writer">System.Resources.ResXResourceWriter, System.Windows.Forms, ...</resheader>
    <data name="Name1"><value>this is my long string</value><comment>this is a comment</comment></data>
    <data name="Color1" type="System.Drawing.Color, System.Drawing">Blue</data>
    <data name="Bitmap1" mimetype="application/x-microsoft.net.object.binary.base64">
        <value>[base64 mime encoded serialized .NET Framework object]</value>
    </data>
    <data name="Icon1" type="System.Drawing.Icon, System.Drawing" mimetype="application/x-microsoft.net.object.bytearray.base64">
        <value>[base64 mime encoded string representing a byte array form of the .NET Framework object]</value>
        <comment>This is a comment</comment>
    </data>
                
    There are any number of "resheader" rows that contain simple 
    name/value pairs.
    
    Each data row contains a name, and value. The row also contains a 
    type or mimetype. Type corresponds to a .NET class that support 
    text/value conversion through the TypeConverter architecture. 
    Classes that don't support this are serialized and stored with the 
    mimetype set.
    
    The mimetype is used for serialized objects, and tells the 
    ResXResourceReader how to depersist the object. This is currently not 
    extensible. For a given mimetype the value must be set accordingly:
    
    Note - application/x-microsoft.net.object.binary.base64 is the format 
    that the ResXResourceWriter will generate, however the reader can 
    read any of the formats listed below.
    
    mimetype: application/x-microsoft.net.object.binary.base64
    value   : The object must be serialized with 
            : System.Runtime.Serialization.Formatters.Binary.BinaryFormatter
            : and then encoded with base64 encoding.
    
    mimetype: application/x-microsoft.net.object.soap.base64
    value   : The object must be serialized with 
            : System.Runtime.Serialization.Formatters.Soap.SoapFormatter
            : and then encoded with base64 encoding.

    mimetype: application/x-microsoft.net.object.bytearray.base64
    value   : The object must be serialized into a byte array 
            : using a System.ComponentModel.TypeConverter
            : and then encoded with base64 encoding.
    -->
  <xsd:schema id="root" xmlns="" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:msdata="urn:schemas-microsoft-com:xml-msdata">
    <xsd:import namespace="http://www.w3.org/XML/1998/namespace" />
    <xsd:element name="root" msdata:IsDataSet="true">
      <xsd:complexType>
        <xsd:choice maxOccurs="unbounded">
          <xsd:element name="metadata">
            <xsd:complexType>
              <xsd:sequence>
                <xsd:element name="value" type="xsd:string" minOccurs="0" />
              </xsd:sequence>
              <xsd:attribute name="name" use="required" type="xsd:string" />
              <xsd:attribute name="type" type="xsd:string" />
              <xsd:attribute name="mimetype" type="xsd:string" />
              <xsd:attribute ref="xml:space" />
            </xsd:complexType>
          </xsd:element>
          <xsd:element name="assembly">
            <xsd:complexType>
              <xsd:attribute name="alias" type="xsd:string" />
              <xsd:attribute name="name" type="xsd:string" />
            </xsd:complexType>
          </xsd:element>
          <xsd:element name="data">
            <xsd:complexType>
              <xsd:sequence>
                <xsd:element name="value" type="xsd:string" minOccurs="0" msdata:Ordinal="1" />
                <xsd:element name="comment" type="xsd:string" minOccurs="0" msdata:Ordinal="2" />
              </xsd:sequence>
              <xsd:attribute name="name" type="xsd:string" use="required" msdata:Ordinal="1" />
              <xsd:attribute name="type" type="xsd:string" msdata:Ordinal="3" />
              <xsd:attribute name="mimetype" type="xsd:string" msdata:Ordinal="4" />
              <xsd:attribute ref="xml:space" />
            </xsd:complexType>
          </xsd:element>
          <xsd:element name="resheader">
            <xsd:complexType>
              <xsd:sequence>
                <xsd:element name="value" type="xsd:string" minOccurs="0" msdata:Ordinal="1" />
              </xsd:sequence>
              <xsd:attribute name="name" type="xsd:string" use="required" />
            </xsd:complexType>
          </xsd:element>
        </xsd:choice>
      </xsd:complexType>
    </xsd:element>
  </xsd:schema>
  <resheader name="resmimetype">
    <value>text/microsoft-resx</value>
  </resheader>
  <resheader name="version">
    <value>2.0</value>
  </resheader>
  <resheader name="reader">
    <value>System.Resources.ResXResourceReader, System.Windows.Forms, Version=2.0.0.0, Culture=neutral, PublicKeyToken=b77a5c561934e089</value>
  </resheader>
  <resheader name="writer">
    <value>System.Resources.ResXResourceWriter, System.Windows.Forms, Version=2.0.0.0, Culture=neutral, PublicKeyToken=b77a5c561934e089</value>
  </resheader>
  <data name="a" xml:space="preserve">
    <value>a</value>
  </data>
  <data name="abertura" xml:space="preserve">
    <value>Abertura</value>
  </data>
  <data name="acceso_indonet" xml:space="preserve">
    <value>Acesso</value>
    <comment>Início de sessão</comment>
  </data>
  <data name="aceptar" xml:space="preserve">
    <value>Aceitar</value>
  </data>
  <data name="acetato" xml:space="preserve">
    <value>Acetato</value>
    <comment>tipo de armação</comment>
  </data>
  <data name="actualizar" xml:space="preserve">
    <value>Atualizar</value>
  </data>
  <data name="adicion" xml:space="preserve">
    <value>Adição</value>
    <comment>Página inicial, lista dos últimos pedidos</comment>
  </data>
  <data name="adicion_abrv" xml:space="preserve">
    <value>AD</value>
    <comment>Cabeçalho lista de pedidos</comment>
  </data>
  <data name="advertencia" xml:space="preserve">
    <value>Advertência</value>
  </data>
  <data name="agente_trazador" xml:space="preserve">
    <value>Agente traçador</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="agente_trazador_desconectado" xml:space="preserve">
    <value>INDOBISEL parece não estar a funcionar. Por favor, verifique o seu estado</value>
  </data>
  <data name="altura" xml:space="preserve">
    <value>Altura</value>
    <comment>Dados técnicos</comment>
  </data>
  <data name="altura_boxing" xml:space="preserve">
    <value>Altura boxing</value>
  </data>
  <data name="altura_de_montaje" xml:space="preserve">
    <value>Altura de montagem</value>
  </data>
  <data name="altura_montaje" xml:space="preserve">
    <value>Altura de montagem</value>
    <comment>Dados Adicionais</comment>
  </data>
  <data name="altura_montaje_total" xml:space="preserve">
    <value>Altura de montagem final</value>
  </data>
  <data name="angulo" xml:space="preserve">
    <value>Ângulo</value>
    <comment>Dados técnicos</comment>
  </data>
  <data name="angulo_de_envolvencia" xml:space="preserve">
    <value>Ângulo de envolvência</value>
    <comment>Dados Adicionais</comment>
  </data>
  <data name="asunto" xml:space="preserve">
    <value>Assunto</value>
  </data>
  <data name="atras" xml:space="preserve">
    <value>Voltar</value>
  </data>
  <data name="automatico13_23" xml:space="preserve">
    <value>Automático (1/3-2/3)</value>
    <comment>Orientação do bisel</comment>
  </data>
  <data name="avisos" xml:space="preserve">
    <value>Avisos</value>
    <comment>Janela de Pedido</comment>
  </data>
  <data name="aviso_cancelar_pedido" xml:space="preserve">
    <value>Tem a certeza que quer cancelar o pedido? Irá perder todos os dados</value>
    <comment>Avisos</comment>
  </data>
  <data name="aviso_desactivar_solo_stock" xml:space="preserve">
    <value>Se deseja esta opção, desactive a opção 'Apenas disponível em stock'</value>
  </data>
  <data name="base" xml:space="preserve">
    <value>Base</value>
    <comment>Dados técnicos</comment>
  </data>
  <data name="base_de_datos_de_marcas" xml:space="preserve">
    <value>Base de dados de marcas</value>
    <comment>ComboBox de formas</comment>
  </data>
  <data name="base_de_datos_de_mis_biselados" xml:space="preserve">
    <value>Os meus biselados</value>
    <comment>ComboBox de formas</comment>
  </data>
  <data name="base_especial" xml:space="preserve">
    <value>Base especial</value>
    <comment>Suplementos</comment>
  </data>
  <data name="base_interior" xml:space="preserve">
    <value>Base interior</value>
  </data>
  <data name="basicas" xml:space="preserve">
    <value>Básicas</value>
    <comment>ComboBox de formas</comment>
  </data>
  <data name="bisel" xml:space="preserve">
    <value>Bisel normal</value>
    <comment>tipo de bisel</comment>
  </data>
  <data name="biselador_remoto" xml:space="preserve">
    <value>Biseladora remota</value>
    <comment>ComboBox de formas</comment>
  </data>
  <data name="biselador_remoto_guardar" xml:space="preserve">
    <value>Guardar</value>
  </data>
  <data name="biselador_remoto_iniciar" xml:space="preserve">
    <value>Iniciar</value>
  </data>
  <data name="biselador_remoto_popup_titulo" xml:space="preserve">
    <value>Seleção de leituras agente traçador</value>
  </data>
  <data name="biselar_lente" xml:space="preserve">
    <value>Biselar lente</value>
  </data>
  <data name="biselar_sin_pulir" xml:space="preserve">
    <value>Biselar sem polir</value>
  </data>
  <data name="borde_especial_abr" xml:space="preserve">
    <value>B. Esp</value>
  </data>
  <data name="borrar_recetas" xml:space="preserve">
    <value>Eliminar</value>
  </data>
  <data name="buscar" xml:space="preserve">
    <value>Procurar</value>
  </data>
  <data name="calibracion_correctamente" xml:space="preserve">
    <value>A calibração foi realizada com sucesso</value>
  </data>
  <data name="calibracion_monitor" xml:space="preserve">
    <value>Calibração monitor</value>
  </data>
  <data name="calibre" xml:space="preserve">
    <value>Calibre</value>
    <comment>Dados Adicionais</comment>
  </data>
  <data name="calibre_completo" xml:space="preserve">
    <value>Calibre completo</value>
  </data>
  <data name="cancelar" xml:space="preserve">
    <value>Cancelar</value>
  </data>
  <data name="cancelar_pedido" xml:space="preserve">
    <value>Cancelar pedido</value>
    <comment>Botões Acções do pedido</comment>
  </data>
  <data name="centrado12_12" xml:space="preserve">
    <value>Centragem (1/2-1/2)</value>
    <comment>Orientação do bisel</comment>
  </data>
  <data name="chile" xml:space="preserve">
    <value>Chile</value>
  </data>
  <data name="cilindro" xml:space="preserve">
    <value>Cilindro</value>
    <comment>Página inicial, lista dos últimos pedidos</comment>
  </data>
  <data name="cilindro_abrv" xml:space="preserve">
    <value>CIL</value>
    <comment>Cabeçalho lista de pedidos</comment>
  </data>
  <data name="clave_acceso" xml:space="preserve">
    <value>Password</value>
    <comment>Início de sessão</comment>
  </data>
  <data name="cliente" xml:space="preserve">
    <value>Cliente</value>
  </data>
  <data name="codigo_cliente" xml:space="preserve">
    <value>Código de cliente</value>
    <comment>Início de sessão</comment>
  </data>
  <data name="codigo_de_retorno" xml:space="preserve">
    <value>Código de retorno:</value>
  </data>
  <data name="color" xml:space="preserve">
    <value>Cor</value>
    <comment>Janela de Pedido</comment>
  </data>
  <data name="coloracion" xml:space="preserve">
    <value>Coloração</value>
    <comment>Janela de Pedido</comment>
  </data>
  <data name="condiciones" xml:space="preserve">
    <value>Condições</value>
  </data>
  <data name="condiciones_1" xml:space="preserve">
    <value>A INDO OPTICAL, S.L.U., contribuinte B-66232976, sedeada em Avda. Alcalde Barnils, 72 - 08174 Sant Cugat del Vallés - Barcelona (Espanha) e inscrita na Conservatória do Registo Comercial de Barcelona no Tomo 44215, Folha 212, Página B-449860 na qualidade de responsável do tratamento de dados, destinatária da informação e titular do ficheiro denominado clientes, notifica ao subscritor que os dados proporcionados serão introduzidos no ficheiro denominado clientes. A finalidade da recolha de dados consiste em permitir o intercâmbio comercial entre a INDO e a sua Óptica por intermédio da Internet e poder assim prestar o serviço INDONET.</value>
  </data>
  <data name="condiciones_2" xml:space="preserve">
    <value>A confirmação das condições de uso é obrigatória e a falta de resposta aos dados solicitados impedirá o serviço através do INDONET.</value>
  </data>
  <data name="condiciones_3" xml:space="preserve">
    <value>O subscritor é notificado que pode exercer diante do responsável do tratamento todos os direitos outorgados pela lei e, especialmente, os direitos de acesso, retificação, cancelamento e oposição. Para tal, deverá remeter para o endereço de correio electrónico indonet@indo.es uma mensagem a expressar o seu desejo.</value>
  </data>
  <data name="condiciones_4" xml:space="preserve">
    <value>O subscritor, enquanto titular dos dados proporcionados, confirma expressamente o seu tratamento na forma descrita.</value>
  </data>
  <data name="conexion_ssl" xml:space="preserve">
    <value>CONEXÃO SSL</value>
  </data>
  <data name="conexion_ssl_segura" xml:space="preserve">
    <value>CONEXÃO SSL SEGURA</value>
  </data>
  <data name="configuracion" xml:space="preserve">
    <value>Configuração</value>
    <comment>Barra de Ferramentas</comment>
  </data>
  <data name="configuracion_activado" xml:space="preserve">
    <value>Ativado</value>
  </data>
  <data name="configuracion_altura_montaje" xml:space="preserve">
    <value>Altura da montagem</value>
  </data>
  <data name="configuracion_aro" xml:space="preserve">
    <value>Aro</value>
  </data>
  <data name="configuracion_boxing" xml:space="preserve">
    <value>Boxing</value>
  </data>
  <data name="configuracion_catalogo_lentes" xml:space="preserve">
    <value>Catálogo de lentes Indo</value>
  </data>
  <data name="configuracion_clave_actual" xml:space="preserve">
    <value>Senha atual</value>
  </data>
  <data name="configuracion_confirmar_clave" xml:space="preserve">
    <value>Confirmar senha</value>
  </data>
  <data name="configuracion_desactivado" xml:space="preserve">
    <value>Desactivado</value>
  </data>
  <data name="configuracion_descargas_automaticas" xml:space="preserve">
    <value>Descargas automáticas</value>
  </data>
  <data name="configuracion_dias" xml:space="preserve">
    <value>dias</value>
  </data>
  <data name="configuracion_enlaces_relacionados" xml:space="preserve">
    <value>Links relacionados</value>
  </data>
  <data name="configuracion_espesores" xml:space="preserve">
    <value>Configuração de espessuras</value>
  </data>
  <data name="configuracion_espesor_borde" xml:space="preserve">
    <value>Espessura do bordo</value>
  </data>
  <data name="configuracion_guardada_correctamente" xml:space="preserve">
    <value>Configuração guardada com sucesso</value>
  </data>
  <data name="configuracion_jerga" xml:space="preserve">
    <value>Gíria</value>
  </data>
  <data name="configuracion_listado_pedidos" xml:space="preserve">
    <value>Lista "Situação de pedidos"</value>
  </data>
  <data name="configuracion_modificar_clave" xml:space="preserve">
    <value>Modificar password</value>
  </data>
  <data name="configuracion_mostrar_pedidos" xml:space="preserve">
    <value>Mostrar últimos pedidos realizados</value>
  </data>
  <data name="configuracion_mostrar_precios" xml:space="preserve">
    <value>Mostrar preços</value>
  </data>
  <data name="configuracion_nombre_comercial" xml:space="preserve">
    <value>Denominação comercial</value>
  </data>
  <data name="configuracion_nombre_lentes" xml:space="preserve">
    <value>Nome das lentes</value>
  </data>
  <data name="configuracion_nombre_lentes_help" xml:space="preserve">
    <value>As alterações aparecerão na próxima vez que iniciar sessão</value>
  </data>
  <data name="configuracion_nueva_clave" xml:space="preserve">
    <value>Nova senha</value>
  </data>
  <data name="configuracion_precalibrado_cotas" xml:space="preserve">
    <value>Pré-calibragem por cotas</value>
  </data>
  <data name="configuracion_precios_no" xml:space="preserve">
    <value>Não</value>
  </data>
  <data name="configuracion_precios_pvo" xml:space="preserve">
    <value>PVO</value>
  </data>
  <data name="configuracion_precios_pvp" xml:space="preserve">
    <value>PVP</value>
  </data>
  <data name="configuracion_precios_si" xml:space="preserve">
    <value>Sim</value>
  </data>
  <data name="configuracion_pred_indo" xml:space="preserve">
    <value>Pred. Indo</value>
  </data>
  <data name="configuracion_propio" xml:space="preserve">
    <value>Próprio</value>
  </data>
  <data name="configuracion_punto_taladro" xml:space="preserve">
    <value>Ponto orifício</value>
  </data>
  <data name="configuracion_ranura_metal" xml:space="preserve">
    <value>Ranhura metal</value>
  </data>
  <data name="configuracion_ranura_nylon" xml:space="preserve">
    <value>Ranhura nylon</value>
  </data>
  <data name="configuracion_restablecer_valores_predeterminados" xml:space="preserve">
    <value>Restabelecer valores pré-determinados</value>
  </data>
  <data name="configuracion_tooltip_espesores" xml:space="preserve">
    <value>A espessura é inferior à recomendada. As lentes não serão biseladas</value>
  </data>
  <data name="configuracion_tooltip_numstepper" xml:space="preserve">
    <value>A lista "Situação de pedidos encontra-se na janela "Início". O intervalo permitido é de 1 a 7 dias.</value>
  </data>
  <data name="configuracion_URL_catalogo" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/Indonet/Enlaces/TARIFA_INDO_2018_PT.pdf</value>
  </data>
  <data name="confirmar_borrar_receta" xml:space="preserve">
    <value>Confirma que pretende eliminar a receita guardada e selecionada?</value>
  </data>
  <data name="confirmar_borrar_recetas" xml:space="preserve">
    <value>Confirma que pretende eliminar as {0} receitas guardadas e selecionadas?</value>
  </data>
  <data name="confirmar_envio_pedidos_1" xml:space="preserve">
    <value>Confirma que pretende enviar a seguinte quantidade de pedidos? #</value>
  </data>
  <data name="confirmar_envio_pedidos_2" xml:space="preserve">
    <value>Os pedidos com erros ficarão em estado "Confirmação pela INDO em curso"</value>
  </data>
  <data name="confirmar_envio_receta" xml:space="preserve">
    <value>Confirma que pretende enviar a receita guardada e selecionada?</value>
  </data>
  <data name="confirmar_envio_recetas" xml:space="preserve">
    <value>Confirma que pretende enviar as {0} receitas guardadas e selecionadas?</value>
  </data>
  <data name="confirmar_envio_recetas_2" xml:space="preserve">
    <value>As receitas com erros ficarão em estado " Confirmação pela INDO em curso "</value>
  </data>
  <data name="confirma_biselado_espesor" xml:space="preserve">
    <value>Confirmação de biselado e espessura</value>
  </data>
  <data name="confirma_destino_mercancia" xml:space="preserve">
    <value>Confirma o destinatário da mercadoria</value>
  </data>
  <data name="contacta_con_indo" xml:space="preserve">
    <value>Entre em contacto</value>
    <comment>Início de sessão</comment>
  </data>
  <data name="continuar" xml:space="preserve">
    <value>Continuar</value>
  </data>
  <data name="correo_solicitud_acceso" xml:space="preserve">
    <value>E-mail de pedido de acesso</value>
  </data>
  <data name="cota_inferior" xml:space="preserve">
    <value>Cota inferior</value>
  </data>
  <data name="cota_superior" xml:space="preserve">
    <value>Cota superior</value>
  </data>
  <data name="datosoptica_aviso_logotipo" xml:space="preserve">
    <value>Não foi especificado nenhum ficheiro para o logótipo{0}Será usado o logo por defeito</value>
  </data>
  <data name="datosoptica_codigo_postal" xml:space="preserve">
    <value>Código postal:</value>
  </data>
  <data name="datosoptica_comunicaciones" xml:space="preserve">
    <value>Quero receber as Newsletters da Indo</value>
  </data>
  <data name="datosoptica_corregir_datos" xml:space="preserve">
    <value>Se os seus dados não estiverem correctos, insira o formulário de contato no menu superior de INDONET</value>
  </data>
  <data name="datosoptica_direccion" xml:space="preserve">
    <value>Direção :</value>
  </data>
  <data name="datosoptica_email" xml:space="preserve">
    <value>Correio electrónico</value>
  </data>
  <data name="datosoptica_enlace_condiciones" xml:space="preserve">
    <value>Ler condições de privacidade e proteção de dados</value>
  </data>
  <data name="datosoptica_fax" xml:space="preserve">
    <value>Fax:</value>
  </data>
  <data name="datosoptica_guardados_correctamente" xml:space="preserve">
    <value>Dados da óptica guardados com sucesso</value>
  </data>
  <data name="datosoptica_logotipo_help" xml:space="preserve">
    <value>O novo logótipo aparecerá na próxima autenticação</value>
  </data>
  <data name="datosoptica_logotipo_optica" xml:space="preserve">
    <value>Logótipo óptica</value>
  </data>
  <data name="datosoptica_nif" xml:space="preserve">
    <value>NIF:</value>
  </data>
  <data name="datosoptica_nombre" xml:space="preserve">
    <value>Nome:</value>
  </data>
  <data name="datosoptica_poblacion" xml:space="preserve">
    <value>Localidade:</value>
  </data>
  <data name="datosoptica_telefono" xml:space="preserve">
    <value>Número de telefone:</value>
  </data>
  <data name="datos_adicionales" xml:space="preserve">
    <value>Dados Adicionais</value>
    <comment>Dados Básicos</comment>
  </data>
  <data name="datos_basicos" xml:space="preserve">
    <value>Dados Básicos</value>
    <comment>Janela de Pedido</comment>
  </data>
  <data name="datos_optica" xml:space="preserve">
    <value>Dados Óptica</value>
    <comment>Barra de navegação principal</comment>
  </data>
  <data name="datos_tecnicos" xml:space="preserve">
    <value>Dados técnicos</value>
  </data>
  <data name="descentramiento_nasal" xml:space="preserve">
    <value>Descentramento nasal</value>
    <comment>Suplementos</comment>
  </data>
  <data name="descripcion_calibracion_old" xml:space="preserve">
    <value>Introduza em milímetros o diâmetro real da circunferência anterior</value>
  </data>
  <data name="destinatario" xml:space="preserve">
    <value>Destinatário</value>
  </data>
  <data name="detalle_estado_pedido" xml:space="preserve">
    <value>Pormenor do estado de pedido</value>
  </data>
  <data name="diametro" xml:space="preserve">
    <value>Diâmetro</value>
    <comment>Vista dados básicos olho</comment>
  </data>
  <data name="dioptrias" xml:space="preserve">
    <value>Dioptrias</value>
    <comment>Suplementos</comment>
  </data>
  <data name="distancia" xml:space="preserve">
    <value>Distância</value>
    <comment>Dados Adicionais</comment>
  </data>
  <data name="distancia_trabajo" xml:space="preserve">
    <value>Distância de trabalho</value>
    <comment>Dados Adicionais</comment>
  </data>
  <data name="dnp" xml:space="preserve">
    <value>DNP</value>
    <comment>Dados Adicionais</comment>
  </data>
  <data name="dnp_de_cerca" xml:space="preserve">
    <value>DNP de perto</value>
  </data>
  <data name="duplicar_receta" xml:space="preserve">
    <value>Duplicar receita</value>
  </data>
  <data name="d_esp" xml:space="preserve">
    <value>D. Esp</value>
  </data>
  <data name="d_especial" xml:space="preserve">
    <value>D. especial</value>
    <comment>Vista dados básicos olho</comment>
  </data>
  <data name="eje" xml:space="preserve">
    <value>Eixo</value>
    <comment>Página inicial, lista dos últimos pedidos</comment>
  </data>
  <data name="eje_abrv" xml:space="preserve">
    <value>EIXO</value>
    <comment>Cabeçalho lista de pedidos</comment>
  </data>
  <data name="el_valor_maximo_es" xml:space="preserve">
    <value>O valor máximo é</value>
    <comment>Validações</comment>
  </data>
  <data name="email" xml:space="preserve">
    <value>Correio electrónico</value>
  </data>
  <data name="entrar" xml:space="preserve">
    <value>ENTRAR</value>
    <comment>Início de sessão</comment>
  </data>
  <data name="enviar" xml:space="preserve">
    <value>Enviar</value>
    <comment>Botões Acções do pedido</comment>
  </data>
  <data name="enviar_pedido" xml:space="preserve">
    <value>Enviar pedido</value>
  </data>
  <data name="enviar_sugerencias" xml:space="preserve">
    <value>Enviar sugestões</value>
  </data>
  <data name="enviar_y_revision" xml:space="preserve">
    <value>Enviar e rever</value>
    <comment>Botões Acções do pedido</comment>
  </data>
  <data name="envio_recetas" xml:space="preserve">
    <value>Envio receitas</value>
  </data>
  <data name="envio_recetas_falta_destinatario" xml:space="preserve">
    <value>Deve selecionar um destinatário</value>
  </data>
  <data name="envio_recetas_ningun_destinatario" xml:space="preserve">
    <value>Não foi encontrado nenhum destinatário</value>
  </data>
  <data name="error_abriendo_aplicacion" xml:space="preserve">
    <value>Erro ao abrir a aplicação solicitada</value>
  </data>
  <data name="error_abriendo_base_de_datos" xml:space="preserve">
    <value>Ocorreu um erro ao abrir a base de dados</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_actualizacion_pedido" xml:space="preserve">
    <value>Erro na atualização do pedido</value>
  </data>
  <data name="error_actualizando_fecha_oferta" xml:space="preserve">
    <value>Ocorreu um erro ao atualizar a data da oferta na base de dados</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_actualizando_oferta" xml:space="preserve">
    <value>Ocorreu um erro ao atualizar a oferta</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_actualizando_pedido" xml:space="preserve">
    <value>***&gt;Não foi possível atualizar o pedido.</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_agente_trazador" xml:space="preserve">
    <value>Erro com o Agente Traçador</value>
  </data>
  <data name="error_autenticar_usuario" xml:space="preserve">
    <value>Não foi possível autenticar o nome de utilizador</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_base_de_datos" xml:space="preserve">
    <value>Ocorreu uma excepção com a base de dados local</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_base_de_datos_no_abierta" xml:space="preserve">
    <value>A base de dados deve estar aberta</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_borrando_recetas" xml:space="preserve">
    <value>Ocorreu um erro ao apagando as receitas</value>
  </data>
  <data name="error_busqueda_inicial_pedidos" xml:space="preserve">
    <value>Ocorreu um erro durante a pesquisa inicial de pedidos</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_busqueda_pedidos" xml:space="preserve">
    <value>Erro na procura de pedidos</value>
  </data>
  <data name="error_busqueda_recetas" xml:space="preserve">
    <value>Erro na procura de receitas</value>
  </data>
  <data name="error_calibracion" xml:space="preserve">
    <value>Não foi possível realizar a calibração</value>
  </data>
  <data name="error_cambiando_password" xml:space="preserve">
    <value>Não foi possível alterar a password</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_caracteres_diferentes_clave_acceso" xml:space="preserve">
    <value>Caracteres diferentes na chave de acesso</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_caracteres_no_permitidos_clave_acceso" xml:space="preserve">
    <value>Caracteres não permitidos na chave de acesso</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_cargando_destinatarios" xml:space="preserve">
    <value>Erro ao carregar destinatários</value>
  </data>
  <data name="error_claves_no_coinciden" xml:space="preserve">
    <value>As passwords não coincidem</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_clave_acceso_no_permitida" xml:space="preserve">
    <value>Password não permitida</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_clave_incorrecta" xml:space="preserve">
    <value>A password indicada está incorreta</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_conexion_agente_trazador" xml:space="preserve">
    <value>Não há ligação com o agente do traçador</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_conexion_agente_trazador_elegido" xml:space="preserve">
    <value>Erro de ligação com o agente traçador selecionado</value>
  </data>
  <data name="error_conexion_agente_trazador_insertado" xml:space="preserve">
    <value>Erro de ligação com o agente traçador introduzido</value>
  </data>
  <data name="error_conexion_servicio" xml:space="preserve">
    <value>Não há ligação com o servidor</value>
  </data>
  <data name="error_desconocido" xml:space="preserve">
    <value>Erro desconhecido</value>
  </data>
  <data name="error_email_direccion_invalida" xml:space="preserve">
    <value>O endereço de correio electrónico está incorreto</value>
    <comment>Erros</comment>
  </data>
  <data name="error_email_falta_asunto" xml:space="preserve">
    <value>O assunto é de preenchimento obrigatório</value>
    <comment>Erros</comment>
  </data>
  <data name="error_email_falta_cuerpo" xml:space="preserve">
    <value>A mensagem é de preenchimento obrigatório</value>
    <comment>Erros</comment>
  </data>
  <data name="error_email_falta_direccion" xml:space="preserve">
    <value>O endereço de correio electrónico é de preenchimento obrigatório</value>
    <comment>Erros</comment>
  </data>
  <data name="error_enviando_correo" xml:space="preserve">
    <value>Não foi possível enviar o correio</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_enviando_lista_recetas" xml:space="preserve">
    <value>Erro ao enviar a lista de receitas</value>
  </data>
  <data name="error_enviando_mail" xml:space="preserve">
    <value>Ocorreu um erro ao enviar o correio electrónico</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_excepcion_autenticando_usuario" xml:space="preserve">
    <value>Ocorreu uma excepção ao autenticar o nome de utilizador</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_excepcion_copando_clase_pedidos" xml:space="preserve">
    <value>Ocorreu uma excepção ao copiar a classe de pedidos</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_excepcion_enviando_correo" xml:space="preserve">
    <value>Ocorreu uma excepção ao enviar o e-mail.</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_excepcion_enviando_pedido" xml:space="preserve">
    <value>Ocorreu uma excepção ao enviar o pedido</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_excepcion_enviando_pedido_para_comparacion" xml:space="preserve">
    <value>Ocorreu uma excepção ao enviar o pedido para comparação</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_excepcion_obtenido_el_area_venta" xml:space="preserve">
    <value>Ocorreu uma excepção ao obter a área de venda</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_excepcion_obteniendo_pedidos_busqueda" xml:space="preserve">
    <value>Ocorreu uma excepção ao obter a lista de pedidos (pesquisa)</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_excepcion_obteniendo_pedidos_inicial" xml:space="preserve">
    <value>Ocorreu uma excepção ao obter a lista de pedidos (inicial)</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_excepcion_producida" xml:space="preserve">
    <value>Ocorreu uma excepção</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_excepcion_recuperando_oferta" xml:space="preserve">
    <value>Ocorreu uma excepção ao recuperar a oferta</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_exceso_numero_intentos_permitidos" xml:space="preserve">
    <value>Foi excedido o número de tentativas permitidas</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_grabacion_pedido" xml:space="preserve">
    <value>Surgiu um erro na gravação do seu pedido. Por favor repita o pedido.</value>
  </data>
  <data name="error_grabando_pedido" xml:space="preserve">
    <value>***&gt;Não foi possível gravar o pedido</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_guardando_datos_configuracion" xml:space="preserve">
    <value>Erro ao guardar dados configuração</value>
  </data>
  <data name="error_guardando_datos_optica" xml:space="preserve">
    <value>Erro ao guardar dados de óptica</value>
  </data>
  <data name="error_guardando_forma" xml:space="preserve">
    <value>Ocorreu um erro ao guardar a forma</value>
  </data>
  <data name="error_guardando_oferta" xml:space="preserve">
    <value>Ocorreu um erro ao guardar a oferta</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_iniciando_aplicacion" xml:space="preserve">
    <value>Ocorreu um erro ao iniciar a aplicação</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_introducir_nueva_clave" xml:space="preserve">
    <value>Introduzir nova password</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_lecturas_agentes" xml:space="preserve">
    <value>Ocorreu um erro ao aceder às leituras do {0} agente{1}</value>
  </data>
  <data name="error_leyendo_fichero_base_de_datos" xml:space="preserve">
    <value>Ocorreu um erro ao ler o ficheiro da base de dados</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_login" xml:space="preserve">
    <value>Ocorreu um erro ao iniciar sessão</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_no_se_han_obtenido_datos_configuracion_cliente" xml:space="preserve">
    <value>Não foi possível obter os dados de configuração do cliente</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_no_se_ha_obtenido_el_area_venta" xml:space="preserve">
    <value>Não foi possível obter a área de venda</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_no_se_ha_podido_recuperar_lista_pedidos" xml:space="preserve">
    <value>***&gt;Não foi possível recuperar a lista com os pedidos.</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_no_se_ha_podido_recuperar_oferta" xml:space="preserve">
    <value>***&gt;Não foi possível recuperar a oferta.</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_obteniendo_los_datos_configuracion_del_cliente" xml:space="preserve">
    <value>Ocorreu uma exceção ao obter os dados de configuração</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_proceso_login" xml:space="preserve">
    <value>Ocorreu um erro ao iniciar sessão</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_recuperando_monturas" xml:space="preserve">
    <value>Erro ao recuperar armações</value>
  </data>
  <data name="error_recuperando_pedido" xml:space="preserve">
    <value>***&gt;Não foi possível recuperar o pedido</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_recuperando_receta" xml:space="preserve">
    <value>***&gt;Não foi possível recuperar a receita</value>
  </data>
  <data name="error_recuperando_recetas" xml:space="preserve">
    <value>Erro ao recuperar as receitas</value>
  </data>
  <data name="error_reintentos_excedidos" xml:space="preserve">
    <value>Número de novas tentativas excedido</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_servicio_cambio_de_password" xml:space="preserve">
    <value>Ocorreu um erro ao alterar password</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_servicio_envio_correo" xml:space="preserve">
    <value>Ocorreu um erro ao enviar correio</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_servicio_login" xml:space="preserve">
    <value>Ocorreu um erro ao iniciar sessão</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_signo_interrogacion_no_permitido_clave_acceso" xml:space="preserve">
    <value>Ponto de interrogação não permitido na password</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_tipo_de_base_de_datos_desconocida" xml:space="preserve">
    <value>Tipo de base de dados desconhecido</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="error_usuario_bloqueado" xml:space="preserve">
    <value>O nome de utilizador está bloqueado</value>
  </data>
  <data name="error_usuario_no_existe" xml:space="preserve">
    <value>O nome de utilizador não existe</value>
  </data>
  <data name="error_usuario_ya_existe" xml:space="preserve">
    <value>O nome de utilizador já existe</value>
  </data>
  <data name="esfera" xml:space="preserve">
    <value>Esfera</value>
    <comment>Página inicial, lista dos últimos pedidos</comment>
  </data>
  <data name="esfera_abrv" xml:space="preserve">
    <value>ESF</value>
    <comment>Cabeçalho lista de pedidos</comment>
  </data>
  <data name="espana" xml:space="preserve">
    <value>Espanha</value>
  </data>
  <data name="espesor" xml:space="preserve">
    <value>Espessura</value>
    <comment>Dados técnicos</comment>
  </data>
  <data name="espesores" xml:space="preserve">
    <value>Espessuras</value>
  </data>
  <data name="espesor_borde_esp" xml:space="preserve">
    <value>Espessura bordo esp.</value>
  </data>
  <data name="espesor_borde_especial" xml:space="preserve">
    <value>Espessura bordo especial</value>
  </data>
  <data name="espesor_centro" xml:space="preserve">
    <value>Espessura centro</value>
    <comment>Dados técnicos</comment>
  </data>
  <data name="espesor_max" xml:space="preserve">
    <value>Espessura máx.</value>
  </data>
  <data name="espesor_maximo" xml:space="preserve">
    <value>Espessura máxima</value>
    <comment>Dados técnicos</comment>
  </data>
  <data name="espesor_min" xml:space="preserve">
    <value>Espessura mín.</value>
  </data>
  <data name="espesor_minimo" xml:space="preserve">
    <value>Espessura mínima</value>
    <comment>Dados técnicos</comment>
  </data>
  <data name="espesor_taladro_nasal" xml:space="preserve">
    <value>Espessura orifício nasal</value>
    <comment>Dados técnicos</comment>
  </data>
  <data name="espesor_taladro_temporal" xml:space="preserve">
    <value>Espessura orifício temporal</value>
    <comment>Dados técnicos</comment>
  </data>
  <data name="estado" xml:space="preserve">
    <value>Estado</value>
    <comment>Página inicial, lista dos últimos pedidos</comment>
  </data>
  <data name="estadoOD" xml:space="preserve">
    <value>estado OD</value>
  </data>
  <data name="estadoOI" xml:space="preserve">
    <value>estado OE</value>
  </data>
  <data name="estado_pedido_grabados" xml:space="preserve">
    <value>Gravações</value>
  </data>
  <data name="estado_pedido_pendientes" xml:space="preserve">
    <value>Por reparar</value>
  </data>
  <data name="estado_pedido_todos" xml:space="preserve">
    <value>Todos</value>
  </data>
  <data name="es_anterior_a" xml:space="preserve">
    <value>é anterior a</value>
  </data>
  <data name="es_necesario_cerrar_la_aplicacion" xml:space="preserve">
    <value>A aplicação foi instalada com sucesso. {0}A seguir, clique em Aceitar, aguarde uns segundos até fechar e faça duplo clique com o seu cursor sobre o ícone Indonet (no ambiente de trabalho)</value>
  </data>
  <data name="expedido" xml:space="preserve">
    <value>Expedido</value>
  </data>
  <data name="explorar" xml:space="preserve">
    <value>Explorar</value>
  </data>
  <data name="fecha" xml:space="preserve">
    <value>Data</value>
    <comment>Página inicial, lista dos últimos pedidos</comment>
  </data>
  <data name="fecha_hi_menor_low" xml:space="preserve">
    <value>A data inserida no campo "até" não pode ser anterior à "desde"</value>
  </data>
  <data name="fecha_hi_nula" xml:space="preserve">
    <value>O campo "até" não pode ser nulo</value>
  </data>
  <data name="fecha_hora_grabacion" xml:space="preserve">
    <value>Data e hora de gravação</value>
  </data>
  <data name="fecha_low_nula" xml:space="preserve">
    <value>O campo "desde" não pode ser nulo</value>
  </data>
  <data name="fecha_prevista_envio" xml:space="preserve">
    <value>Data prevista de envio</value>
  </data>
  <data name="fecha_rango" xml:space="preserve">
    <value>Data (intervalo de 7 dias)</value>
  </data>
  <data name="finalizado" xml:space="preserve">
    <value>Finalizado</value>
  </data>
  <data name="foco_y_material" xml:space="preserve">
    <value>Foco e Material</value>
    <comment>Vista dados básicos olho</comment>
  </data>
  <data name="forma" xml:space="preserve">
    <value>Forma</value>
  </data>
  <data name="formas_leidas" xml:space="preserve">
    <value>Formas lidas</value>
  </data>
  <data name="formas_y_espesores" xml:space="preserve">
    <value>Formas e Espessuras</value>
    <comment>Janela de Pedido</comment>
  </data>
  <data name="forma_escogida" xml:space="preserve">
    <value>Forma escolhida</value>
  </data>
  <data name="forma_guardada_OK" xml:space="preserve">
    <value>Forma guardada com sucesso</value>
  </data>
  <data name="fotocromatico" xml:space="preserve">
    <value>Fotocromático</value>
    <comment>Janela de Pedido</comment>
  </data>
  <data name="francia" xml:space="preserve">
    <value>França</value>
  </data>
  <data name="frontal" xml:space="preserve">
    <value>Frontal</value>
    <comment>Orientação do bisel</comment>
  </data>
  <data name="ganancia_espesor" xml:space="preserve">
    <value>Ganho em espessura</value>
    <comment>Dados técnicos</comment>
  </data>
  <data name="ganancia_peso" xml:space="preserve">
    <value>Ganho em peso</value>
    <comment>Dados técnicos</comment>
  </data>
  <data name="grados" xml:space="preserve">
    <value>Graus</value>
    <comment>Suplementos</comment>
  </data>
  <data name="guardar_biselado" xml:space="preserve">
    <value>Guardar biselado</value>
  </data>
  <data name="guardar_receta" xml:space="preserve">
    <value>Guardar receita</value>
    <comment>Botões Acções do pedido</comment>
  </data>
  <data name="hora" xml:space="preserve">
    <value>Hora</value>
  </data>
  <data name="idioma" xml:space="preserve">
    <value>Idioma</value>
    <comment>Início de sessão</comment>
  </data>
  <data name="imprimir" xml:space="preserve">
    <value>Imprimir</value>
    <comment>Botões Acções do pedido</comment>
  </data>
  <data name="imprimir_lista" xml:space="preserve">
    <value>Imprimir lista</value>
  </data>
  <data name="indice" xml:space="preserve">
    <value>Índice</value>
    <comment>Vista dados básicos olho</comment>
  </data>
  <data name="indonet" xml:space="preserve">
    <value>Indonet</value>
  </data>
  <data name="indo_en_el_mundo" xml:space="preserve">
    <value>» A Indo no mundo</value>
  </data>
  <data name="inferior" xml:space="preserve">
    <value>Inferior</value>
  </data>
  <data name="informacion" xml:space="preserve">
    <value>Informação</value>
  </data>
  <data name="info_creacion_bbdd_local" xml:space="preserve">
    <value>***&gt;Início criação base de dados local</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="info_creacion_bbdd_local_OK" xml:space="preserve">
    <value>***&gt;Criação base de dados local OK</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="info_envio_pedido_revisar" xml:space="preserve">
    <value>O seu pedido foi gravado. A Indo irá confirmá-lo e colocá-lo em fabrico brevemente.</value>
  </data>
  <data name="info_grabar_oferta" xml:space="preserve">
    <value>***&gt;Início gravação oferta em base de dados local</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="info_grabar_oferta_OK" xml:space="preserve">
    <value>***&gt;Gravar oferta em base de dados local OK</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="info_ojo_informativo" xml:space="preserve">
    <value>Ao marcar este olho como informativo, não será fabricado.</value>
  </data>
  <data name="info_pedido_procesado" xml:space="preserve">
    <value>O seu pedido com referência {0} foi registado e processado com sucesso</value>
  </data>
  <data name="inicio" xml:space="preserve">
    <value>Início</value>
    <comment>Barra de navegação principal</comment>
  </data>
  <data name="intervalo_de_fechas" xml:space="preserve">
    <value>Intervalo de datas</value>
  </data>
  <data name="italia" xml:space="preserve">
    <value>Itália</value>
  </data>
  <data name="laboratorio" xml:space="preserve">
    <value>Laboratório</value>
  </data>
  <data name="lente" xml:space="preserve">
    <value>Lente</value>
    <comment>Página inicial, lista dos últimos pedidos</comment>
  </data>
  <data name="login_verificar" xml:space="preserve">
    <value>VERIFICAR</value>
  </data>
  <data name="logout" xml:space="preserve">
    <value>Terminar sessão</value>
    <comment>Barra de Ferramentas</comment>
  </data>
  <data name="log_actualizacion_oferta" xml:space="preserve">
    <value>***&gt;Iniciar actualização oferta</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="log_cabecera_oferta_ok" xml:space="preserve">
    <value>***&gt;Cabeçalho oferta OK</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="log_oferta_descargada_ok" xml:space="preserve">
    <value>***&gt;Oferta descarregada OK</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="l_taladrada" xml:space="preserve">
    <value>Plano</value>
    <comment>Tipo de bisel</comment>
  </data>
  <data name="marca" xml:space="preserve">
    <value>Marca</value>
  </data>
  <data name="mensaje" xml:space="preserve">
    <value>Mensagem</value>
  </data>
  <data name="mensaje_solicitud_clave" xml:space="preserve">
    <value>Para obter uma palavra-passe, basta introduzir um endereço de correio electrónico válido e o seu código de cliente Indo.{0}Em breve, irá receber a sua palavra-passe para entrar na Indonet.{0}Muito obrigado.</value>
  </data>
  <data name="metal" xml:space="preserve">
    <value>Metal</value>
    <comment>tipo de armação</comment>
  </data>
  <data name="mini_bisel" xml:space="preserve">
    <value>Mini bisel</value>
    <comment>Tipo de bisel</comment>
  </data>
  <data name="mis_biselados" xml:space="preserve">
    <value>Os meus biselados</value>
  </data>
  <data name="modelo" xml:space="preserve">
    <value>Modelo</value>
  </data>
  <data name="modificar_espesor" xml:space="preserve">
    <value>Modificar espessura</value>
  </data>
  <data name="montaje" xml:space="preserve">
    <value>Montagem</value>
  </data>
  <data name="monturas" xml:space="preserve">
    <value>Armações</value>
    <comment>Janela de Pedido</comment>
  </data>
  <data name="mostrar_lentes_stock" xml:space="preserve">
    <value>Mostrar apenas lentes disponíveis em stock</value>
    <comment>Vista dados básicos olho</comment>
  </data>
  <data name="nasal" xml:space="preserve">
    <value>Nasal</value>
  </data>
  <data name="nd" xml:space="preserve">
    <value>Nd</value>
    <comment>Dados técnicos</comment>
  </data>
  <data name="ninguna" xml:space="preserve">
    <value>Nenhuma</value>
  </data>
  <data name="noticias" xml:space="preserve">
    <value>Notícias</value>
    <comment>Homepage</comment>
  </data>
  <data name="no_esta_bien_formado" xml:space="preserve">
    <value>Não está bem formado</value>
    <comment>Validações</comment>
  </data>
  <data name="no_es_multiplo_de" xml:space="preserve">
    <value>Não é múltiplo de</value>
    <comment>Validações</comment>
  </data>
  <data name="no_se_actualiza_la_oferta" xml:space="preserve">
    <value>A oferta não é actualizada</value>
    <comment>Logs &amp; erros</comment>
  </data>
  <data name="no_se_ha_podido_enviar_la_solucitud" xml:space="preserve">
    <value>Não foi possível enviar o seu pedido. Por favor, tente mais tarde.</value>
    <comment>Popup Mail</comment>
  </data>
  <data name="nueva_prev" xml:space="preserve">
    <value>Nova Prev.</value>
    <comment>Cabeçalho lista de pedidos</comment>
  </data>
  <data name="nueva_prevision" xml:space="preserve">
    <value>Nova previsão</value>
    <comment>Página inicial, lista dos últimos pedidos</comment>
  </data>
  <data name="numero_pedido" xml:space="preserve">
    <value>Número pedido</value>
  </data>
  <data name="n_abbe" xml:space="preserve">
    <value>Nº Abbe</value>
    <comment>Dados técnicos</comment>
  </data>
  <data name="n_pedido" xml:space="preserve">
    <value>Nº pedido</value>
    <comment>Página inicial, lista dos últimos pedidos</comment>
  </data>
  <data name="obligatorio_seleccionar_destiantario" xml:space="preserve">
    <value>É necesário informar o destinatário.</value>
  </data>
  <data name="observaciones" xml:space="preserve">
    <value>Observações</value>
  </data>
  <data name="OD" xml:space="preserve">
    <value>OD</value>
    <comment>Olho direito</comment>
  </data>
  <data name="oferta" xml:space="preserve">
    <value>Oferta</value>
  </data>
  <data name="OI" xml:space="preserve">
    <value>OE</value>
    <comment>Olho Esquerdo</comment>
  </data>
  <data name="ojo_derecho" xml:space="preserve">
    <value>Olho direito</value>
    <comment>Enviar pedido</comment>
  </data>
  <data name="ojo_informativo" xml:space="preserve">
    <value>Olho informativo</value>
    <comment>Vista dados básicos olho</comment>
  </data>
  <data name="ojo_izquierdo" xml:space="preserve">
    <value>Olho esquerdo</value>
    <comment>Enviar pedido</comment>
  </data>
  <data name="orientacion_del_bisel" xml:space="preserve">
    <value>Orientação do bisel</value>
  </data>
  <data name="paso1" xml:space="preserve">
    <value>Passo 1</value>
  </data>
  <data name="paso2" xml:space="preserve">
    <value>Passo 2</value>
  </data>
  <data name="pedidos_al_por_mayor" xml:space="preserve">
    <value>Pedidos por atacado/stock</value>
  </data>
  <data name="pedidos_obtenidos" xml:space="preserve">
    <value>Pedido(s) obtido(s)</value>
  </data>
  <data name="pedidos_realizados" xml:space="preserve">
    <value>Pedidos Realizados</value>
    <comment>Barra de navegação principal</comment>
  </data>
  <data name="pedido_con_errores" xml:space="preserve">
    <value>Não é possível enviar o pedido. Verifique os alertas</value>
    <comment>Alertas</comment>
  </data>
  <data name="peso" xml:space="preserve">
    <value>Peso</value>
    <comment>Dados técnicos</comment>
  </data>
  <data name="plano" xml:space="preserve">
    <value>Plano</value>
    <comment>Tipo de bisel</comment>
  </data>
  <data name="portugal" xml:space="preserve">
    <value>Portugal</value>
  </data>
  <data name="por_favor_confirma_datos_pedido" xml:space="preserve">
    <value>Por favor, confirma os dados do seu pedido:</value>
    <comment>Enviar pedido</comment>
  </data>
  <data name="precalibrado_a_cotas" xml:space="preserve">
    <value>Pré-calibragem por cotas</value>
  </data>
  <data name="precalibrado_digital" xml:space="preserve">
    <value>Pré-calibragem digital</value>
  </data>
  <data name="precalibrado_digital_abr" xml:space="preserve">
    <value>Precal.</value>
  </data>
  <data name="precio" xml:space="preserve">
    <value>Preço</value>
  </data>
  <data name="prevision" xml:space="preserve">
    <value>Previsão</value>
    <comment>Página inicial, lista dos últimos pedidos</comment>
  </data>
  <data name="prisma_1" xml:space="preserve">
    <value>Prisma 1</value>
    <comment>Suplementos</comment>
  </data>
  <data name="prisma_2" xml:space="preserve">
    <value>Prisma 2</value>
    <comment>Suplementos</comment>
  </data>
  <data name="producto_lente" xml:space="preserve">
    <value>Produto (lente)</value>
  </data>
  <data name="puente" xml:space="preserve">
    <value>Ponte</value>
    <comment>Dados Adicionais</comment>
  </data>
  <data name="radio" xml:space="preserve">
    <value>Rádio</value>
    <comment>Dados técnicos</comment>
  </data>
  <data name="ranurada" xml:space="preserve">
    <value>Com ranhuras</value>
    <comment>tipo de armação</comment>
  </data>
  <data name="ranurada_metal" xml:space="preserve">
    <value>Com ranhuras em Metal</value>
    <comment>Tipo de bisel</comment>
  </data>
  <data name="ranurada_nylon" xml:space="preserve">
    <value>Com ranhuras em Nylor</value>
    <comment>Tipo de bisel</comment>
  </data>
  <data name="realizar_pedido" xml:space="preserve">
    <value>Realizar Pedido</value>
    <comment>Barra de navegação principal</comment>
  </data>
  <data name="recetas_borrado" xml:space="preserve">
    <value>Eliminação de receitas</value>
  </data>
  <data name="recetas_borrar" xml:space="preserve">
    <value>Apagar receitas</value>
  </data>
  <data name="recetas_enviar" xml:space="preserve">
    <value>Enviar receitas</value>
  </data>
  <data name="recetas_envio" xml:space="preserve">
    <value>Envio de receitas</value>
  </data>
  <data name="recetas_guardadas" xml:space="preserve">
    <value>Receitas Guardadas</value>
    <comment>Barra de navegação principal</comment>
  </data>
  <data name="recetas_obtenidas" xml:space="preserve">
    <value>Receita(s) obtida(s)</value>
  </data>
  <data name="receta_envio_masivo" xml:space="preserve">
    <value>Pedido gerado em massa</value>
  </data>
  <data name="receta_guardada" xml:space="preserve">
    <value>A receita foi guardada com sucesso</value>
  </data>
  <data name="redimensionar" xml:space="preserve">
    <value>Redimensionar</value>
  </data>
  <data name="referencia" xml:space="preserve">
    <value>Referência</value>
    <comment>Janela de Pedido</comment>
  </data>
  <data name="ref_cliente" xml:space="preserve">
    <value>Ref. Cliente</value>
    <comment>Página inicial, lista dos últimos pedidos</comment>
  </data>
  <data name="registros" xml:space="preserve">
    <value>Registos</value>
  </data>
  <data name="resto_de_paises" xml:space="preserve">
    <value>Restantes países</value>
    <comment>Iniciar sessão</comment>
  </data>
  <data name="rx" xml:space="preserve">
    <value>RX</value>
    <comment>Página inicial, lista dos últimos pedidos</comment>
  </data>
  <data name="salir" xml:space="preserve">
    <value>Sair</value>
  </data>
  <data name="segura" xml:space="preserve">
    <value>SEGURA</value>
  </data>
  <data name="seguro_que_quiere_desconectarse" xml:space="preserve">
    <value>Tem a certeza que quer terminar a sessão?</value>
    <comment>Alertas</comment>
  </data>
  <data name="seguro_que_quiere_salir" xml:space="preserve">
    <value>Tem a certeza que quer sair da aplicação?</value>
    <comment>Alertas</comment>
  </data>
  <data name="selecciona" xml:space="preserve">
    <value>Selecione</value>
  </data>
  <data name="selecciona_abrv" xml:space="preserve">
    <value>Selec.</value>
  </data>
  <data name="selecciona_cliente" xml:space="preserve">
    <value>Selecione o cliente</value>
  </data>
  <data name="selecciona_la_lente_deseada" xml:space="preserve">
    <value>Selecione a lente desejada</value>
  </data>
  <data name="seleccion_de_espesores" xml:space="preserve">
    <value>Seleção de espessuras</value>
  </data>
  <data name="seleccion_vacia_recetas" xml:space="preserve">
    <value>Não foi selecionada nenhuma receita</value>
  </data>
  <data name="se_actualizo" xml:space="preserve">
    <value>Foi atualizado</value>
  </data>
  <data name="siguiente" xml:space="preserve">
    <value>Seguinte</value>
  </data>
  <data name="simulador_de_lentes" xml:space="preserve">
    <value>Simulador de lentes</value>
  </data>
  <data name="simulador_error" xml:space="preserve">
    <value>Erro ao lançar o simulador de lentes</value>
  </data>
  <data name="simulador_no_instalado" xml:space="preserve">
    <value>O simulador de lentes não está instalado</value>
  </data>
  <data name="sin_coloracion" xml:space="preserve">
    <value>Sem coloração</value>
  </data>
  <data name="sin_fotocromatico" xml:space="preserve">
    <value>Sem fotocromático</value>
  </data>
  <data name="sin_precalibrado" xml:space="preserve">
    <value>Sem pré-calibragem</value>
  </data>
  <data name="situacion_de_los_pedidos" xml:space="preserve">
    <value>Situação dos pedidos</value>
  </data>
  <data name="solicitar_acceso" xml:space="preserve">
    <value>Solicitar acesso</value>
  </data>
  <data name="solicitar_clave_acceso" xml:space="preserve">
    <value>» Solicitar password</value>
  </data>
  <data name="solicitud_acceso" xml:space="preserve">
    <value>Pedido de Acesso</value>
  </data>
  <data name="solicitud_enviada_correctamente" xml:space="preserve">
    <value>O pedido de acesso foi enviado com sucesso</value>
    <comment>Popup Mail</comment>
  </data>
  <data name="solo_stock" xml:space="preserve">
    <value>Apenas disponível em stock</value>
  </data>
  <data name="stock" xml:space="preserve">
    <value>Stock</value>
  </data>
  <data name="sugerencias" xml:space="preserve">
    <value>Sugestões</value>
    <comment>Barra de Ferramentas</comment>
  </data>
  <data name="superior" xml:space="preserve">
    <value>Superior</value>
  </data>
  <data name="suplementos" xml:space="preserve">
    <value>Suplementos</value>
    <comment>Dados Básicos</comment>
  </data>
  <data name="taladrada" xml:space="preserve">
    <value>Perfurada</value>
    <comment>tipo de armação</comment>
  </data>
  <data name="talla" xml:space="preserve">
    <value>Tamanho</value>
  </data>
  <data name="temporal" xml:space="preserve">
    <value>Temporal</value>
  </data>
  <data name="texto" xml:space="preserve">
    <value>Texto</value>
  </data>
  <data name="texto_presentacion1" xml:space="preserve">
    <value>Bem-vindo/a à nova geração de pedidos on-line.</value>
  </data>
  <data name="texto_presentacion2" xml:space="preserve">
    <value>Aqui começa o caminho da nova Indo!</value>
  </data>
  <data name="texto_presentacion3" xml:space="preserve">
    <value>Surpreenda-se com a ferramenta mais ágil e intuitiva do mercado para realizar os seus pedidos, com funções que permitirão aumentar o valor competitivo das suas vendas.</value>
  </data>
  <data name="texto_presentacion4" xml:space="preserve">
    <value>Personalizar e otimizar as espessuras das lentes de laboratório;</value>
  </data>
  <data name="texto_presentacion5" xml:space="preserve">
    <value>Usar a maioria dos traçadores do mercado;</value>
  </data>
  <data name="texto_presentacion6" xml:space="preserve">
    <value>Seguimento dos seus pedidos mais detalhado</value>
  </data>
  <data name="texto_presentacion7" xml:space="preserve">
    <value>Verifique com o seu gestor comercial ou o seu gestor de conta as opções mais adequadas para dar resposta às suas necessidades.</value>
  </data>
  <data name="text_info_contacto" xml:space="preserve">
    <value>Se desejar mais informação, entre em contacto connosco através de:</value>
    <comment>Início de sessão</comment>
  </data>
  <data name="tipo_bisel" xml:space="preserve">
    <value>Tipo bisel</value>
  </data>
  <data name="tipo_de_bisel" xml:space="preserve">
    <value>Tipo de bisel</value>
  </data>
  <data name="tipo_de_montura" xml:space="preserve">
    <value>Tipo de armação</value>
  </data>
  <data name="tipo_lente" xml:space="preserve">
    <value>Tipo de lente</value>
  </data>
  <data name="tipo_montura" xml:space="preserve">
    <value>Tipo armação</value>
  </data>
  <data name="tono" xml:space="preserve">
    <value>Tonalidade</value>
    <comment>Janela de Pedido</comment>
  </data>
  <data name="tratamientos" xml:space="preserve">
    <value>Tratamentos</value>
    <comment>Vista dados básicos olho</comment>
  </data>
  <data name="tratamiento_coloracion" xml:space="preserve">
    <value>Tratamento e coloração</value>
  </data>
  <data name="trazador" xml:space="preserve">
    <value>Traçador</value>
  </data>
  <data name="trazadores" xml:space="preserve">
    <value>Traçador</value>
  </data>
  <data name="trazadores_anadir" xml:space="preserve">
    <value>Acrescentar ...</value>
  </data>
  <data name="trazadores_borrar" xml:space="preserve">
    <value>Eliminar</value>
  </data>
  <data name="trazadores_borrar_todos" xml:space="preserve">
    <value>Eliminar todos</value>
  </data>
  <data name="trazadores_comprobacion" xml:space="preserve">
    <value>Confirmação</value>
  </data>
  <data name="trazadores_conexion_agente_trazador_OK" xml:space="preserve">
    <value>O AGENTE TRAÇADOR {0} FOI LIGADO COM SUCESSO</value>
  </data>
  <data name="trazadores_direccion" xml:space="preserve">
    <value>Direção</value>
  </data>
  <data name="trazadores_modificar" xml:space="preserve">
    <value>Modificar</value>
  </data>
  <data name="trazadores_nombre_agente" xml:space="preserve">
    <value>Nome agente</value>
  </data>
  <data name="trazadores_popup_anular" xml:space="preserve">
    <value>Anular</value>
  </data>
  <data name="trazadores_popup_titulo" xml:space="preserve">
    <value>Seleção / Modificação do agente traçador</value>
  </data>
  <data name="trazadores_puerto" xml:space="preserve">
    <value>Porta</value>
  </data>
  <data name="trazadores_trazador" xml:space="preserve">
    <value>Traçador</value>
  </data>
  <data name="trazador_busqueda_ordenadores_red" xml:space="preserve">
    <value>Selecionar o equipamento na rede</value>
  </data>
  <data name="tto_adicional" xml:space="preserve">
    <value>Tto. Adicional</value>
    <comment>Vista dados básicos olho</comment>
  </data>
  <data name="tutoriales" xml:space="preserve">
    <value>Tutoriais</value>
    <comment>Barra de Ferramentas</comment>
  </data>
  <data name="t_por_ciento" xml:space="preserve">
    <value>T%</value>
    <comment>Dados técnicos</comment>
  </data>
  <data name="ultimos_pedidos" xml:space="preserve">
    <value>Últimos pedidos</value>
    <comment>Ecrã de início</comment>
  </data>
  <data name="url_indonet_direct" xml:space="preserve">
    <value>http://pedidos.direc.indo.es/</value>
    <comment>Links</comment>
  </data>
  <data name="url_indo_en_el_mundo" xml:space="preserve">
    <value>http://www.indo.es/indo-en-el-mundo/indo_enelmundo.asp</value>
    <comment>Links</comment>
  </data>
  <data name="url_mas_informacion" xml:space="preserve">
    <value>http://www.indo.es/lentes/lentes_lentesindo.asp</value>
    <comment>Links</comment>
  </data>
  <data name="url_tutoriales" xml:space="preserve">
    <value>http://www.indo.es/pt/optics/tutorials</value>
    <comment>Links</comment>
  </data>
  <data name="ventana_espera_actualizar_pedido" xml:space="preserve">
    <value>ACTUALIZAÇÃO DO PEDIDO EM CURSO</value>
  </data>
  <data name="ventana_espera_areaventa" xml:space="preserve">
    <value>CARREGAMENTO DA ÁREA VENDA EM CURSO</value>
  </data>
  <data name="ventana_espera_borrar_recetas" xml:space="preserve">
    <value>ELIMINAÇÃO DAS RECEITAS EM CURSO</value>
  </data>
  <data name="ventana_espera_busqueda_pedidos" xml:space="preserve">
    <value>PROCURA DE PEDIDOS EM CURSO</value>
  </data>
  <data name="ventana_espera_busqueda_recetas" xml:space="preserve">
    <value>PROCURA DE RECEITAS EM CURSO</value>
  </data>
  <data name="ventana_espera_cambio_clave" xml:space="preserve">
    <value>GRAVAÇÃO DA NOVA PASSWORD EM CURSO</value>
  </data>
  <data name="ventana_espera_cargando_clientes" xml:space="preserve">
    <value>RECUPERANDO CLIENTES</value>
  </data>
  <data name="ventana_espera_carga_pedido" xml:space="preserve">
    <value>CARREGAMENTO DO PEDIDO EM CURSO {0}</value>
  </data>
  <data name="ventana_espera_carga_pedidos_inicial" xml:space="preserve">
    <value>CARREGAMENTO DA LISTA DE PEDIDOS INICIAL EM CURSO</value>
  </data>
  <data name="ventana_espera_carga_receta" xml:space="preserve">
    <value>CARREGAMENTO DA RECEITA EM CURSO {0}</value>
  </data>
  <data name="ventana_espera_comparando_lentes" xml:space="preserve">
    <value>COMPARANDO LENTES</value>
  </data>
  <data name="ventana_espera_configuracion" xml:space="preserve">
    <value>CARREGAMENTO DA CONFIGURAÇÃO EM CURSO</value>
  </data>
  <data name="ventana_espera_destinatarios" xml:space="preserve">
    <value>CARREGAMENTO DOS DESTINATÁRIOS EM CURSO</value>
  </data>
  <data name="ventana_espera_envio_correo" xml:space="preserve">
    <value>ENVIO DO CORREIO EM CURSO</value>
  </data>
  <data name="ventana_espera_envio_pedidos" xml:space="preserve">
    <value>ENVIO DO PEDIDO EM CURSO</value>
  </data>
  <data name="ventana_espera_envio_recetas" xml:space="preserve">
    <value>ENVIO DE RECEITAS EM CURSO</value>
  </data>
  <data name="ventana_espera_esperar" xml:space="preserve">
    <value>Por favor, aguarde ...</value>
  </data>
  <data name="ventana_espera_guardar_configuracion" xml:space="preserve">
    <value>GRAVAÇÃO DA CONFIGURAÇÃO EM CURSO</value>
  </data>
  <data name="ventana_espera_guardar_datos_optica" xml:space="preserve">
    <value>GRAVAÇÃO DOS DADOS ÓPTICA EM CURSO</value>
  </data>
  <data name="ventana_espera_guardar_receta" xml:space="preserve">
    <value>GRAVAÇÃO DA RECEITA EM CURSO {0}</value>
  </data>
  <data name="ventana_espera_lecturas_trazador" xml:space="preserve">
    <value>LEITURAS TRAÇADOR {0}{1}</value>
  </data>
  <data name="ventana_espera_lecturas_trazadores" xml:space="preserve">
    <value>LEITURAS AGENTE TRAÇADOR {0}</value>
  </data>
  <data name="ventana_espera_lectura_trazador" xml:space="preserve">
    <value>LEITURA {0}</value>
  </data>
  <data name="ventana_espera_login" xml:space="preserve">
    <value>AUTENTICAÇÃO CLIENTE {0}</value>
  </data>
  <data name="ventana_espera_misbiselados" xml:space="preserve">
    <value>CARREGAMENTO DOS BISELADOS EM CURSO</value>
  </data>
  <data name="ventana_espera_monturas" xml:space="preserve">
    <value>CARREGAMENTO DAS ARMAÇÕES EM CURSO</value>
  </data>
  <data name="ventana_espera_obteniendo_bases" xml:space="preserve">
    <value>RECUPERANDO BASES</value>
  </data>
  <data name="ventana_espera_oferta" xml:space="preserve">
    <value>ACTUALIZAÇÃO DA OFERTA EM CURSO</value>
  </data>
  <data name="ventana_espera_revision_pedidos" xml:space="preserve">
    <value>ENVIO DA REVISÃO EM CURSO</value>
  </data>
  <data name="ventana_espera_seleccion_lente" xml:space="preserve">
    <value>SELECIONANDO LENTE</value>
  </data>
  <data name="ventana_espera_verifica_conexion_trazador" xml:space="preserve">
    <value>VERIFIQUE A LIGAÇÃO DO AGENTE TRAÇADOR {0}</value>
  </data>
  <data name="verifique_conexion" xml:space="preserve">
    <value>Verifique a sua ligação à Internet</value>
  </data>
  <data name="volver_al_listado" xml:space="preserve">
    <value>Voltar à lista</value>
  </data>
  <data name="zona_de_identificacion" xml:space="preserve">
    <value>Zona de identificação</value>
    <comment>Barra de Ferramentas</comment>
  </data>
  <data name="configuracion_fecha_oferta" xml:space="preserve">
    <value>Data da Oferta</value>
    <comment>Lectura de la fecha de la oferta en la BBDD</comment>
  </data>
  <data name="desde_scanner" xml:space="preserve">
    <value>From Scanner</value>
    <comment>Shape ComboBox</comment>
  </data>
  <data name="aviso_alternativa_producto" xml:space="preserve">
    <value>Existe uma ruptura de stock para a graduação e produto pedido. De modo a garantir um bom serviço, será enviado ao mesmo preço o seguinte produto. Se se tratar de uma reposição de um olho, por favor contacte o ATC.</value>
    <comment>Alternativa producto</comment>
  </data>
  <data name="codigo_de_barras" xml:space="preserve">
    <value>Código de barras</value>
  </data>
  <data name="pedido_con_montura" xml:space="preserve">
    <value>Pedido com armação</value>
  </data>
  <data name="aviso_falta_tipo_montura" xml:space="preserve">
    <value>Deve especificar o Tipo de armação. Clique no botão Avançar dentro da selecção de espessuras.</value>
  </data>
  <data name="valor_fuera_de_margenes" xml:space="preserve">
    <value>Valor fora de margens para  {2}. Valores permitidos entre {0} e {1}.</value>
  </data>
  <data name="formas_fax_enviarFichero" xml:space="preserve">
    <value>Get from file</value>
  </data>
  <data name="anadir_encargo_pregunta_enviar_revision" xml:space="preserve">
    <value>Enviar a confirmar o pedido.</value>
    <comment>segunda gafa</comment>
  </data>
  <data name="aviso_caracter_no_valido" xml:space="preserve">
    <value>Este caracter é inválido. Por favor use o alfabeto ocidental.</value>
  </data>
  <data name="actualizando_nueva_oferta" xml:space="preserve">
    <value>A atualizar nova oferta ...</value>
  </data>
  <data name="comprobando_si_BBDD" xml:space="preserve">
    <value>A verificar se o DB ...</value>
  </data>
  <data name="comprobando_si_nueva_oferta" xml:space="preserve">
    <value>A verificar se a nova oferta ...</value>
  </data>
  <data name="descarga_oferta_fallo_1" xml:space="preserve">
    <value>Incapazes de recarregar a oferta neste momento.</value>
  </data>
  <data name="descarga_oferta_fallo_2" xml:space="preserve">
    <value>Por favor, tente novamente mais tarde a partir do botão Atualizar na guia OFERTA SET DATA OPTICAL</value>
  </data>
  <data name="Esperando_respuesta_del_usuario_para_actualizar_la_oferta" xml:space="preserve">
    <value>À espera de resposta do usuário para atualizar a oferta ...</value>
  </data>
  <data name="nueva_oferta_actualizada_correctamente" xml:space="preserve">
    <value>Nova oferta atualizada correctamente</value>
  </data>
  <data name="oferta_actualizada" xml:space="preserve">
    <value>Oferta atualizada...</value>
  </data>
  <data name="aviso_cargando_oferta" xml:space="preserve">
    <value>A carregar oferta.</value>
  </data>
  <data name="aviso_lectura_cargada" xml:space="preserve">
    <value>Forma {0} carregada com sucesso</value>
  </data>
  <data name="aviso_lectura_importada" xml:space="preserve">
    <value>Forma {0} importada com sucesso</value>
  </data>
  <data name="tooltip_boton_importar" xml:space="preserve">
    <value>Importar uma forma a partir do arquivo</value>
  </data>
  <data name="tooltip_boton_iniciar" xml:space="preserve">
    <value>Exibe todos os INDOBISEL leituras</value>
  </data>
  <data name="tooltip_boton_ultima" xml:space="preserve">
    <value>Baixe a última forma lida em INDOBISEL</value>
  </data>
  <data name="espesor_borde" xml:space="preserve">
    <value>Espessura bordo</value>
  </data>
  <data name="Discrepancia_muestra_leida" xml:space="preserve">
    <value>E r r o : Diferença entre o número de amostra esperada e a obtida.</value>
  </data>
  <data name="FicherosFormas" xml:space="preserve">
    <value>Ficheiro de formas</value>
    <comment>IndoScan</comment>
  </data>
  <data name="IntentarOtraVez" xml:space="preserve">
    <value>Por favor, tente de novo com outra imagem ou alerte a Indo.</value>
  </data>
  <data name="NoPosibleObtenerForma" xml:space="preserve">
    <value>Não foi possível obter a forma através da imagem.</value>
  </data>
  <data name="SelEscaner" xml:space="preserve">
    <value>Selecionar scanner</value>
    <comment>IndoScan</comment>
  </data>
  <data name="VolverAObtenerDeEscaner" xml:space="preserve">
    <value>Voltar a seleccionar scanner</value>
    <comment>IndoScan</comment>
  </data>
  <data name="AnalizandoImagen" xml:space="preserve">
    <value>A analizar imagem</value>
    <comment>IndoScan</comment>
  </data>
  <data name="msg_Prerequisitos" xml:space="preserve">
    <value>De seguida será iniciado, de forma automática, a instalação dos programas necessários para a digitalização das imagens.</value>
    <comment>IndoScan</comment>
  </data>
  <data name="Aceptar_Cambio_Y_Enviar" xml:space="preserve">
    <value>Aceitar Chave e Enviar</value>
    <comment>Alternativa de producto</comment>
  </data>
  <data name="aviso_reiniciar_oferta" xml:space="preserve">
    <value>Tem a certeza que quer recarregar a oferta?</value>
  </data>
  <data name="calibre_marcas" xml:space="preserve">
    <value>Calibre</value>
  </data>
  <data name="descargando_oferta" xml:space="preserve">
    <value>A carregar oferta</value>
  </data>
  <data name="imprimir_pedidos_seleccionados" xml:space="preserve">
    <value>Imprimir pedidos selecionados</value>
  </data>
  <data name="reiniciar_fecha_oferta" xml:space="preserve">
    <value>Recarregamento da oferta</value>
  </data>
  <data name="quitar_seleccion" xml:space="preserve">
    <value>Retirar seleção</value>
  </data>
  <data name="seleccionar_todos" xml:space="preserve">
    <value>Selecionar todos</value>
  </data>
  <data name="configuracion_hojaPedido" xml:space="preserve">
    <value>Folha de pedido</value>
  </data>
  <data name="configuracion_link_hojaRombos" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/Indonet/Enlaces/HojaPedidos_PT.pdf</value>
  </data>
  <data name="configuracion_link_manualIndoScanIntegrado" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/Indonet/Enlaces/ManualIndoScanIntegrado_PT.pdf</value>
  </data>
  <data name="configuracion_manualIndoScanIntegrado" xml:space="preserve">
    <value>Manual IndoScan</value>
  </data>
  <data name="configuracion_link_manualIndoNet" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/Indonet/Enlaces/ManualIndonet_PT.pdf</value>
  </data>
  <data name="configuracion_manualIndonet" xml:space="preserve">
    <value>Manual Indonet</value>
  </data>
  <data name="fecha_prevista_recepcion" xml:space="preserve">
    <value>Data de recepção</value>
  </data>
  <data name="aviso_precalibrado_forma_cuadrada" xml:space="preserve">
    <value>No précal, é recomendado que seja escolhida a forma básica quadrada para garantir que a lente calculada cubra a forma real da armação.</value>
  </data>
  <data name="parametros_proxy" xml:space="preserve">
    <value>Parâmetros de conexão para o proxy</value>
  </data>
  <data name="password" xml:space="preserve">
    <value>Password</value>
  </data>
  <data name="usuario" xml:space="preserve">
    <value>User</value>
  </data>
  <data name="aviso_falta_diametro" xml:space="preserve">
    <value>Por favor selecione um diâmetro.</value>
  </data>
  <data name="optimizacion_freemax" xml:space="preserve">
    <value>Otimização FreeMax</value>
    <comment>Leyenda del precalibrado digital para MAXIMA</comment>
  </data>
  <data name="recordar_mis_credenciales" xml:space="preserve">
    <value>Memorizar credenciais</value>
  </data>
  <data name="espesor_centro_abr" xml:space="preserve">
    <value>Espess. Cen.</value>
  </data>
  <data name="ganancia_en_espesor" xml:space="preserve">
    <value>Ganho em espessura</value>
  </data>
  <data name="ganancia_en_espesor_abr" xml:space="preserve">
    <value>G. em Espe.</value>
  </data>
  <data name="ganancia_en_peso" xml:space="preserve">
    <value>Ganho em peso</value>
  </data>
  <data name="ganancia_en_peso_abr" xml:space="preserve">
    <value>G. em peso</value>
  </data>
  <data name="aviso_color_nd_diferentes" xml:space="preserve">
    <value>Não é permitida a coloração em lentes com índice diferente.</value>
  </data>
  <data name="aviso_actualizacion" xml:space="preserve">
    <value>La aplicación se actualizará a la versión:</value>
    <comment>clickonce</comment>
  </data>
  <data name="aviso_apagado" xml:space="preserve">
    <value>Aplicação atualizada. A aplicação vai ser fechada. Por favor reinicie o Indonet.</value>
    <comment>clickonce</comment>
  </data>
  <data name="aviso_descarga_aplicacion" xml:space="preserve">
    <value>A carregar aplicação.</value>
    <comment>ClickOnce</comment>
  </data>
  <data name="aviso_instalando_version" xml:space="preserve">
    <value>A instalar nova versão.</value>
    <comment>ClickOnce</comment>
  </data>
  <data name="password_internet" xml:space="preserve">
    <value>Password de Internet</value>
    <comment>proxy</comment>
  </data>
  <data name="usuario_internet" xml:space="preserve">
    <value>Usuário de Internet</value>
    <comment>proxy</comment>
  </data>
  <data name="caracter_hiperlink" xml:space="preserve">
    <value>»</value>
  </data>
  <data name="configuracion_lentesMaxima" xml:space="preserve">
    <value>www.lentesmaxima.com</value>
  </data>
  <data name="configuracion_link_lentesMaxima" xml:space="preserve">
    <value>http://www.lentesmaxima.com</value>
  </data>
  <data name="mail_informacion_estado_pedidos" xml:space="preserve">
    <value>E-MAIL DE INFORMAÇAO DE PEDIDOS</value>
    <comment>Mi buzon</comment>
  </data>
  <data name="mi_buzon" xml:space="preserve">
    <value>O meu Correio</value>
    <comment>Mi buzon</comment>
  </data>
  <data name="texto_direcciones_email" xml:space="preserve">
    <value>Endereços de envio de informação:</value>
    <comment>Mi buzon</comment>
  </data>
  <data name="texto_pedidos_en_curso" xml:space="preserve">
    <value>Pedidos em curso (1 e-mail diário)</value>
    <comment>Mi buzon</comment>
  </data>
  <data name="texto_pedidos_en_envio" xml:space="preserve">
    <value>Pedidos a receber por cada envio (1 e-mail por cada envio)</value>
    <comment>Mi buzon</comment>
  </data>
  <data name="texto_si_deseas_recibir_mails" xml:space="preserve">
    <value>Se deseja receber e-mails com informação dos seus pedidos, por favor selecione uma das seguintes opções:</value>
    <comment>Mi buzon</comment>
  </data>
  <data name="ventana_espera_guardar_datos" xml:space="preserve">
    <value>GRAVAÇÃO DOS DADOS EM CURSO</value>
  </data>
  <data name="graduacion_resultante" xml:space="preserve">
    <value>Graduación Resultante:</value>
  </data>
  <data name="BlankRange" xml:space="preserve">
    <value>Blank range</value>
    <comment>BlankRange</comment>
  </data>
  <data name="info_no_fabricara" xml:space="preserve">
    <value>La lente no se fabricará</value>
  </data>
  <data name="activar" xml:space="preserve">
    <value>Activo</value>
  </data>
  <data name="alt_anilla" xml:space="preserve">
    <value>Alt Aro</value>
  </data>
  <data name="anadir_encargo" xml:space="preserve">
    <value>Acrescentar custo</value>
  </data>
  <data name="anadir_encargo_pregunta_enviar_revision1" xml:space="preserve">
    <value>Enviar pedido a confirmar.</value>
  </data>
  <data name="anadir_encargo_pregunta_graduacion_cerca" xml:space="preserve">
    <value>Graduação de perto</value>
  </data>
  <data name="anadir_encargo_pregunta_graduacion_diferente" xml:space="preserve">
    <value>Graduação diferente do 1º óculo</value>
  </data>
  <data name="anadir_encargo_pregunta_graduacion_lejos" xml:space="preserve">
    <value>Graduação de longe</value>
  </data>
  <data name="anadir_encargo_pregunta_guardar_receta" xml:space="preserve">
    <value>Guardar e realizar pedido mais tarde a partir das receitas guardadas</value>
  </data>
  <data name="anadir_encargo_pregunta_misma_graduacion" xml:space="preserve">
    <value>Mesma graduação que o 1º óculo</value>
  </data>
  <data name="anillas" xml:space="preserve">
    <value>Aro</value>
  </data>
  <data name="Atencion" xml:space="preserve">
    <value>Atenção</value>
  </data>
  <data name="aviso_color_reposicion" xml:space="preserve">
    <value>Recebemos o seu pedido. 
De modo a assegurar-nos de que a cor de ambas lentes é igual, entraremos em contacto consigo o quanto antes.</value>
  </data>
  <data name="aviso_encargo_anulado" xml:space="preserve">
    <value>O seu pedido foi cancelado correctamente</value>
  </data>
  <data name="aviso_encargo_no_anulable" xml:space="preserve">
    <value>Já não é possível anular o seu pedido</value>
  </data>
  <data name="aviso_escoger_alto_indice" xml:space="preserve">
    <value>Para lentes com furos, recomendamos índices a partir de 1.6, garantindo a máxima segurança na montagem.</value>
  </data>
  <data name="aviso_falta_montura" xml:space="preserve">
    <value>Não há stock da armação escolhida. Elimine-a  em formas e espessuras e substitua-a por outra armação. </value>
  </data>
  <data name="aviso_falta_scaner" xml:space="preserve">
    <value>Não foi possível dar início ao scanner</value>
  </data>
  <data name="aviso_introducir_montura_1" xml:space="preserve">
    <value>Atenção: para realizar corretamente o pedido</value>
  </data>
  <data name="aviso_introducir_montura_2" xml:space="preserve">
    <value>deve introduzir a forma da armação</value>
  </data>
  <data name="aviso_mas_30_dias" xml:space="preserve">
    <value>O intervalo de datas debe ser inferior a 60 dias</value>
  </data>
  <data name="aviso_solicitar_solo_monturas" xml:space="preserve">
    <value>Para solicitar armações para o stock , faça-o para o atc.pedidos@indo.pt</value>
  </data>
  <data name="biselador_remoto_importar" xml:space="preserve">
    <value>Importar</value>
  </data>
  <data name="biselador_remoto_ultima" xml:space="preserve">
    <value>Última</value>
  </data>
  <data name="c1_desde" xml:space="preserve">
    <value>C1 de</value>
  </data>
  <data name="c1_hasta" xml:space="preserve">
    <value>C1 até</value>
  </data>
  <data name="cancelar_comparacion" xml:space="preserve">
    <value>Cancelar comparação</value>
  </data>
  <data name="comparar" xml:space="preserve">
    <value>Comparar</value>
  </data>
  <data name="comparar_lentes" xml:space="preserve">
    <value>Comparar lentes</value>
  </data>
  <data name="comparar_otra_lente" xml:space="preserve">
    <value>Comparar outra lente</value>
  </data>
  <data name="comprobar_disponibilidad" xml:space="preserve">
    <value>Confirmar disponibilidade</value>
  </data>
  <data name="configuracion_taller" xml:space="preserve">
    <value>Configuração fabrico</value>
  </data>
  <data name="diam_anill" xml:space="preserve">
    <value>Diam aro</value>
  </data>
  <data name="diam_desde" xml:space="preserve">
    <value>Diam de</value>
  </data>
  <data name="diam_hasta" xml:space="preserve">
    <value>diam até</value>
  </data>
  <data name="diam_resul" xml:space="preserve">
    <value>diam result</value>
  </data>
  <data name="direccion_indo" xml:space="preserve">
    <value>Centro Empresarial RAL R. D. António Correia de Sá 82 B/C Terrugem</value>
  </data>
  <data name="error_borrando_pedido" xml:space="preserve">
    <value>Ocorreu um erro apagando o pedido</value>
  </data>
  <data name="error_comprobar_disponibilidad_segunda_gafa" xml:space="preserve">
    <value>Erro ao confirmar disponibilidade do 2º óculo</value>
  </data>
  <data name="error_envio_pedido_paso2_1" xml:space="preserve">
    <value>Foi detectado um erro na comunicação com o servidor ao gravar o pedido. Verifique em 'pedidos realizados' se está gravado da seguinte maneira:</value>
  </data>
  <data name="error_envio_pedido_paso2_2" xml:space="preserve">
    <value>Se está gravado, apague-a de realizar pedido</value>
  </data>
  <data name="error_envio_pedido_paso2_3" xml:space="preserve">
    <value>Se não foi gravado, reenvie-o novamente</value>
  </data>
  <data name="fecha_envio" xml:space="preserve">
    <value>Data de envio</value>
  </data>
  <data name="ffname" xml:space="preserve">
    <value>FFNome</value>
  </data>
  <data name="finalizar_pedido" xml:space="preserve">
    <value>Finalizar pedido</value>
  </data>
  <data name="grabar_cambios" xml:space="preserve">
    <value>Guardar alterações</value>
  </data>
  <data name="graduacion_resultante1" xml:space="preserve">
    <value>Graduação resultante:</value>
  </data>
  <data name="id_design" xml:space="preserve">
    <value>ID_DESIGN</value>
  </data>
  <data name="id_job" xml:space="preserve">
    <value>ID JOB</value>
  </data>
  <data name="id_producto" xml:space="preserve">
    <value>ID_PRODUTO</value>
  </data>
  <data name="indice_desde" xml:space="preserve">
    <value>Índice de</value>
  </data>
  <data name="indice_hasta" xml:space="preserve">
    <value>Índice até</value>
  </data>
  <data name="infolog_actualizacion_oferta" xml:space="preserve">
    <value>Início de actualização de oferta</value>
  </data>
  <data name="infolog_cabecera_oferta_ok" xml:space="preserve">
    <value>Cabeçalho oferta OK</value>
  </data>
  <data name="infolog_creacion_bbdd_local" xml:space="preserve">
    <value>Início de criação bbdd local</value>
  </data>
  <data name="infolog_creacion_bbdd_local_OK" xml:space="preserve">
    <value>Criação bbdd local ok</value>
  </data>
  <data name="infolog_grabar_oferta" xml:space="preserve">
    <value>Início de gravação de oferta em bbdd local</value>
  </data>
  <data name="infolog_grabar_oferta_OK" xml:space="preserve">
    <value>Gravar oferta em bbdd local ok</value>
  </data>
  <data name="infolog_oferta_descargada_ok" xml:space="preserve">
    <value>Oferta descarregada OK</value>
  </data>
  <data name="insertar" xml:space="preserve">
    <value>Inserir</value>
  </data>
  <data name="job" xml:space="preserve">
    <value>JOB</value>
  </data>
  <data name="jobs_omain" xml:space="preserve">
    <value>JOBS(MAIN)</value>
  </data>
  <data name="macro" xml:space="preserve">
    <value>Macro</value>
  </data>
  <data name="macros_talla" xml:space="preserve">
    <value>Macro de fabrico</value>
  </data>
  <data name="maquina" xml:space="preserve">
    <value>Máquina</value>
  </data>
  <data name="marcas_laser" xml:space="preserve">
    <value>Marcas Laser</value>
  </data>
  <data name="mas_informacion" xml:space="preserve">
    <value>Mais informação</value>
  </data>
  <data name="material" xml:space="preserve">
    <value>Material</value>
  </data>
  <data name="montura" xml:space="preserve">
    <value>Armação</value>
  </data>
  <data name="nombre_producto" xml:space="preserve">
    <value>Nome de produto</value>
  </data>
  <data name="OjoDerecho" xml:space="preserve">
    <value>Olho direito</value>
  </data>
  <data name="OjoIzquierdo" xml:space="preserve">
    <value>Olho esquerdo</value>
  </data>
  <data name="oval" xml:space="preserve">
    <value>Oval</value>
  </data>
  <data name="pedido" xml:space="preserve">
    <value>Pedido</value>
  </data>
  <data name="peticiones_talla" xml:space="preserve">
    <value>Petições de fabrico</value>
  </data>
  <data name="precal" xml:space="preserve">
    <value>Précal</value>
  </data>
  <data name="PreguntaElegirOjo" xml:space="preserve">
    <value>A que olho corresponde a forma obtida?</value>
  </data>
  <data name="pregunta_borrar_pedido" xml:space="preserve">
    <value>De certeza que quer anular a encomenda com OT?</value>
  </data>
  <data name="pregunta_montura_y_lentes_biseladas" xml:space="preserve">
    <value>Armação e lentes biseladas</value>
  </data>
  <data name="pregunta_montura_y_lentes_montadas" xml:space="preserve">
    <value>Armação e lentes montadas</value>
  </data>
  <data name="pregunta_montura_y_lentes_sin_biselar" xml:space="preserve">
    <value>Armação e lentes sem biselar (lente associada à armação escolhida)</value>
  </data>
  <data name="pregunta_solo_lentes_biseladas" xml:space="preserve">
    <value>Apenas lentes biseladas</value>
  </data>
  <data name="pregunta_solo_lentes_sin_biselar" xml:space="preserve">
    <value>Apenas lentes sem biselar</value>
  </data>
  <data name="productos_y_credito" xml:space="preserve">
    <value>Produtos e crédito</value>
  </data>
  <data name="prod_desde" xml:space="preserve">
    <value>PROD de</value>
  </data>
  <data name="prod_hasta" xml:space="preserve">
    <value>PROD até</value>
  </data>
  <data name="segura1" xml:space="preserve">
    <value>Segura</value>
  </data>
  <data name="semitermin" xml:space="preserve">
    <value>SEMITERMIN</value>
  </data>
  <data name="semiterminados" xml:space="preserve">
    <value>Semiterminados</value>
  </data>
  <data name="sucursal" xml:space="preserve">
    <value>Sucursal</value>
  </data>
  <data name="titulo_reposicion_color" xml:space="preserve">
    <value>Aviso de reposição de cor</value>
  </data>
  <data name="toolTip_abrir_indoscan" xml:space="preserve">
    <value>Abris Indoscan</value>
  </data>
  <data name="valores_por_defecto" xml:space="preserve">
    <value>Valores por defeito</value>
  </data>
  <data name="ventana_espera_borrar_pedidos" xml:space="preserve">
    <value>A eliminar pedido</value>
  </data>
  <data name="ventana_espera_consulta_anulable" xml:space="preserve">
    <value>Consulte se o pedido é anulável</value>
  </data>
  <data name="ventana_espera_recuperar_datos" xml:space="preserve">
    <value>A recuperar dados</value>
  </data>
  <data name="fecha_llegada_real" xml:space="preserve">
    <value>Data de chegada:</value>
  </data>
  <data name="aviso_error_buscando_datos" xml:space="preserve">
    <value>Error buscando datos</value>
  </data>
  <data name="aviso_error_enviando_mensaje_SRI" xml:space="preserve">
    <value>Error enviando mensaje a SRI</value>
  </data>
  <data name="aviso_no_hay_incidencias" xml:space="preserve">
    <value>No se encuentran Incidencias en este formulario</value>
  </data>
  <data name="aviso_no_hay_tema" xml:space="preserve">
    <value>No se ha definido el Tema de la incidencia en este formulario</value>
  </data>
  <data name="aviso_tema_no_encontrado" xml:space="preserve">
    <value>No se encuentra el tema especificado</value>
  </data>
  <data name="email_contacto" xml:space="preserve">
    <value>Email de contacto</value>
  </data>
  <data name="errores_en_formulario" xml:space="preserve">
    <value>Se han encontrado errores en el formulario</value>
  </data>
  <data name="ficheros_adjuntos" xml:space="preserve">
    <value>Ficheros adjuntos</value>
  </data>
  <data name="fin_ficheros_adjuntos" xml:space="preserve">
    <value>Fin de ficheros adjuntos</value>
  </data>
  <data name="motivos" xml:space="preserve">
    <value>Motivos</value>
  </data>
  <data name="motivo_no_definido" xml:space="preserve">
    <value>No se ha definido el Motivo de la incidencia en este formulario</value>
  </data>
  <data name="no_hay_motivos" xml:space="preserve">
    <value>No hay motivos</value>
  </data>
  <data name="tema" xml:space="preserve">
    <value>Tema</value>
  </data>
  <data name="no_mostrar_aviso" xml:space="preserve">
    <value>No volver a mostrar este aviso</value>
  </data>
  <data name="error_usuario_o_clave_no_validos" xml:space="preserve">
    <value>Usuario o clave no válidos</value>
  </data>
  <data name="borrar_forma" xml:space="preserve">
    <value>Eliminar Forma</value>
    <comment>Borrado MiBiselado</comment>
  </data>
  <data name="confirmar_borrar_forma" xml:space="preserve">
    <value>Confirma que pretende eliminar a forma?</value>
    <comment>Borrado MiBiselado</comment>
  </data>
  <data name="montura_seleccionada" xml:space="preserve">
    <value>Forma seleccionada</value>
    <comment>FormaRemota</comment>
  </data>
  <data name="ventana_espera_borrar_forma" xml:space="preserve">
    <value>Eliminação de forma</value>
    <comment>Borrado MiBiselado</comment>
  </data>
  <data name="borrar_mi_biselado" xml:space="preserve">
    <value>Eliminar de os meus biselados</value>
    <comment>Borrado MiBiselado</comment>
  </data>
  <data name="pedir_montura" xml:space="preserve">
    <value>Pedir Montura</value>
    <comment>FormaRemota</comment>
  </data>
  <data name="base_automatica" xml:space="preserve">
    <value>Base Automatica</value>
  </data>
  <data name="otros_datos" xml:space="preserve">
    <value>OTROS DATOS</value>
  </data>
  <data name="anadir" xml:space="preserve">
    <value>Add</value>
  </data>
  <data name="eliminar_seleccionados" xml:space="preserve">
    <value>Delete selected</value>
  </data>
  <data name="exportar" xml:space="preserve">
    <value>Export</value>
  </data>
  <data name="importar" xml:space="preserve">
    <value>Import</value>
  </data>
  <data name="datos_opcionales" xml:space="preserve">
    <value>Mostrar Dados Adicionais opcionales</value>
  </data>
  <data name="setup_laboratorios" xml:space="preserve">
    <value>Lab Settings</value>
  </data>
  <data name="indoCenter_popup_titulo" xml:space="preserve">
    <value>Seleção de leituras IndoCenter</value>
  </data>
  <data name="nombre" xml:space="preserve">
    <value>Nome</value>
  </data>
  <data name="aviso_espera" xml:space="preserve">
    <value>Espera</value>
  </data>
  <data name="aviso_faltan_datos_en_prisma" xml:space="preserve">
    <value>Não está especificado o eixo nem a base do prisma</value>
  </data>
  <data name="aviso_desactivar_isotipo" xml:space="preserve">
    <value>Em pedidos sem forma ou com forma básica, não se pode solicitar o logotipo. Desactive o logotipo</value>
  </data>
  <data name="aviso_diferentes_alturas" xml:space="preserve">
    <value>Diferença altura de montagem entre os olhos superior ou igual a 3mm</value>
  </data>
  <data name="aviso_si_quiere_recogida_llamar_ATC" xml:space="preserve">
    <value>Se deseja solicitar uma recolha para efetuar uma montagem deve ligar á Atenção ao Cliente</value>
  </data>
  <data name="abono" xml:space="preserve">
    <value>crédito</value>
  </data>
  <data name="actualizar_cesta" xml:space="preserve">
    <value>Atualizar carrinho</value>
  </data>
  <data name="albaran" xml:space="preserve">
    <value>Guia</value>
  </data>
  <data name="anadir_pedido_a_cesta" xml:space="preserve">
    <value>Acrescentar pedido ao carrinho</value>
  </data>
  <data name="aviso_salir_indomanager" xml:space="preserve">
    <value>Por segurança, Deseja encerrar a sessão no Indomanager?</value>
  </data>
  <data name="bienvenido_a_indomanager" xml:space="preserve">
    <value>bem vindo a indomanager</value>
  </data>
  <data name="borrar_pedido_de_cesta" xml:space="preserve">
    <value>Eliminiar o pedido do carrinho</value>
  </data>
  <data name="cargo" xml:space="preserve">
    <value>Cargo</value>
  </data>
  <data name="cerrar_sesion" xml:space="preserve">
    <value>encerrar sessão</value>
  </data>
  <data name="codigo_cliente_facturacion" xml:space="preserve">
    <value>Código Cliente Faturação</value>
  </data>
  <data name="desea_añadir_pedido_a_cesta" xml:space="preserve">
    <value>Deseja juntar ao carrinho ?</value>
  </data>
  <data name="enviar_seleccionados" xml:space="preserve">
    <value>Enviar selecionados</value>
  </data>
  <data name="fecha_albaran" xml:space="preserve">
    <value>Data da Guia</value>
  </data>
  <data name="fecha_factura" xml:space="preserve">
    <value>data fatura</value>
  </data>
  <data name="importe" xml:space="preserve">
    <value>quantidade</value>
  </data>
  <data name="importe_bruto" xml:space="preserve">
    <value>quantidade bruto</value>
  </data>
  <data name="importe_neto" xml:space="preserve">
    <value>quantidade neto</value>
  </data>
  <data name="importe_total" xml:space="preserve">
    <value>quantidade total</value>
  </data>
  <data name="iniciar_sesion" xml:space="preserve">
    <value>abrir sessão</value>
  </data>
  <data name="iva" xml:space="preserve">
    <value>IVA</value>
  </data>
  <data name="mi_cesta" xml:space="preserve">
    <value>Meu carrinho</value>
  </data>
  <data name="no_albaranes_obtenidos" xml:space="preserve">
    <value>Guia(s) obtida(s)</value>
  </data>
  <data name="no_facturas_obtenidas" xml:space="preserve">
    <value>Fatura(s) obtida(s)</value>
  </data>
  <data name="ocultar_detalles" xml:space="preserve">
    <value>Ocultar detalhes</value>
  </data>
  <data name="ponte_contacto_comercial" xml:space="preserve">
    <value>coloque se contato comercial</value>
  </data>
  <data name="posible_pedido_repetido_en_cesta" xml:space="preserve">
    <value>Possivelmente este pedido já existe no carrinho</value>
  </data>
  <data name="recalcular" xml:space="preserve">
    <value>Recalcular</value>
  </data>
  <data name="todo" xml:space="preserve">
    <value>Tudo</value>
  </data>
  <data name="ventana_espera_busqueda_albaranes" xml:space="preserve">
    <value>Procurando Guias…</value>
  </data>
  <data name="ventana_espera_busqueda_facturas" xml:space="preserve">
    <value>Procurando Faturas…</value>
  </data>
  <data name="ver_cesta" xml:space="preserve">
    <value>Ver Carrinho</value>
  </data>
  <data name="ver_detalles" xml:space="preserve">
    <value>ver detalhes</value>
  </data>
  <data name="aviso_mas_365_dias" xml:space="preserve">
    <value>O intervalo de datas debe ser inferior a 365 dias</value>
  </data>
  <data name="aviso_comunicacion_indomanager" xml:space="preserve">
    <value>A comunicação dos dados da secção IndoManager serão enviados ao endereço de mail indicado para esse efeito. Assegure-se que o mesmo é correto.</value>
  </data>
  <data name="aviso_devolucion_anulada" xml:space="preserve">
    <value>Sua devolução foi anulada corretamente</value>
  </data>
  <data name="aviso_documento_devoluciones_1" xml:space="preserve">
    <value>(Deve juntar este documento ás lentes a devolver do respectivo pedido)</value>
  </data>
  <data name="aviso_documento_devoluciones_2" xml:space="preserve">
    <value>(Deve juntar este documento á encomenda com os pedidos mencionados na devolução)</value>
  </data>
  <data name="aviso_entrar_email_valido" xml:space="preserve">
    <value>É obrigatório colocar um endereço de mail válido</value>
  </data>
  <data name="aviso_instalacion_fuente_barcode" xml:space="preserve">
    <value>Instalação de uma fonte de códigos de barras necessária para o envio das devoluções. Carregue em aceitar para instalar. Na janela seguinte carregue em instalar e depois encerre a janela.</value>
  </data>
  <data name="aviso_montaje_gratuito" xml:space="preserve">
    <value>As recolhas pedidas via IndoNet são gratuitas.</value>
  </data>
  <data name="aviso_pedido_no_se_puede_devolver" xml:space="preserve">
    <value>O pedido deve estar enviado para ser devolvido</value>
  </data>
  <data name="aviso_pedir_devolucion" xml:space="preserve">
    <value>Pedir devolução do pedido</value>
  </data>
  <data name="aviso_recogida_indonet_gratuita" xml:space="preserve">
    <value>As recolhas de montagens não tem custo associado quando solicitadas via IndoNet</value>
  </data>
  <data name="aviso_saber_consumo_actual" xml:space="preserve">
    <value>Quer saber o consumo atual desde a última fatura?</value>
  </data>
  <data name="añadir_mas_devoluciones" xml:space="preserve">
    <value>Adicionar mais devoluções</value>
  </data>
  <data name="bono_agotandose" xml:space="preserve">
    <value>Bono a acabar</value>
  </data>
  <data name="borrar_devolucion" xml:space="preserve">
    <value>Eliminar_devolução</value>
  </data>
  <data name="busca_informacion_tracking" xml:space="preserve">
    <value>Procura na Web do transportista informação do estado do seu envio.</value>
  </data>
  <data name="caducidad" xml:space="preserve">
    <value>Caducidade</value>
  </data>
  <data name="calcular" xml:space="preserve">
    <value>Calcular</value>
  </data>
  <data name="calculo_aproximado_consumo" xml:space="preserve">
    <value>Cálculo aproximado com base nos pedidos realizados desde a última fatura</value>
  </data>
  <data name="cantidad_consumida" xml:space="preserve">
    <value>Unidades consumidas</value>
  </data>
  <data name="clase_de_devolucion" xml:space="preserve">
    <value>Tipo de devolução</value>
  </data>
  <data name="codigo" xml:space="preserve">
    <value>Código</value>
  </data>
  <data name="consulta" xml:space="preserve">
    <value>Consulta</value>
  </data>
  <data name="consumo_inferior_al_optimo" xml:space="preserve">
    <value>Consumo inferior ao óptimo</value>
  </data>
  <data name="contratados_en_vigor" xml:space="preserve">
    <value>Contratos em vigor</value>
  </data>
  <data name="con_demora" xml:space="preserve">
    <value>Com demora</value>
  </data>
  <data name="cumplimiento_de_servicio" xml:space="preserve">
    <value>Em conformidade com o serviço</value>
  </data>
  <data name="datos_generales" xml:space="preserve">
    <value>Dados Gerais</value>
  </data>
  <data name="debe_escoger_una_lente" xml:space="preserve">
    <value>Deve escolher no minimo uma lente</value>
  </data>
  <data name="debe_escoger_un_motivo_devolucion" xml:space="preserve">
    <value>Deve escolher um motivo de devolução</value>
  </data>
  <data name="denominacion" xml:space="preserve">
    <value>Designação</value>
  </data>
  <data name="desea_pedir_recogida" xml:space="preserve">
    <value>Deseja pedir uma recolha para as suas devoluções?</value>
  </data>
  <data name="devolucion" xml:space="preserve">
    <value>Devolução</value>
  </data>
  <data name="devoluciones" xml:space="preserve">
    <value>Devoluções</value>
  </data>
  <data name="devoluciones_tramitadas" xml:space="preserve">
    <value>Devoluções analisadas</value>
  </data>
  <data name="devolucion_correcta" xml:space="preserve">
    <value>Devolução correta</value>
  </data>
  <data name="devolucion_en_tramite" xml:space="preserve">
    <value>O pedido já existe nas devoluções pendentes de análise</value>
  </data>
  <data name="direccion_email" xml:space="preserve">
    <value>Endereço de mail*</value>
  </data>
  <data name="direccion_Maroc" xml:space="preserve">
    <value>Indo Optical. Dársena del Saladillo  sub área AN1A. 11201 ALGECIRAS</value>
  </data>
  <data name="direccion_Papiol" xml:space="preserve">
    <value>Indo Optical. Guttenberg nº 5 (P.I. Les Torrenteres). 08754 El Papiol (Barcelona)</value>
  </data>
  <data name="en_plazo" xml:space="preserve">
    <value>Dentro do prazo</value>
  </data>
  <data name="error_en_devolucion" xml:space="preserve">
    <value>Erro na devolução</value>
  </data>
  <data name="esta_lente_no_descuenta_de_bono" xml:space="preserve">
    <value>Esta lente não é descontada no Bono</value>
  </data>
  <data name="evolucion" xml:space="preserve">
    <value>Evolução</value>
  </data>
  <data name="fecha_devolucion" xml:space="preserve">
    <value>Data Devolução</value>
  </data>
  <data name="fecha_pedido" xml:space="preserve">
    <value>Data Pedido</value>
  </data>
  <data name="fecha_prevista_llegada" xml:space="preserve">
    <value>DATA PREV. CHEGADA</value>
  </data>
  <data name="informacion_bonos" xml:space="preserve">
    <value>Informação de Bonos</value>
  </data>
  <data name="ir_a_tramitar" xml:space="preserve">
    <value>Em análise</value>
  </data>
  <data name="lentes_incluidas_en_bono" xml:space="preserve">
    <value>Lentes incluídas no Bono</value>
  </data>
  <data name="monofocales" xml:space="preserve">
    <value>Monofocais</value>
  </data>
  <data name="motivo_devolucion" xml:space="preserve">
    <value>Motivo da Devolução</value>
  </data>
  <data name="motivo_de_devolucion" xml:space="preserve">
    <value>Motivo da devolução</value>
  </data>
  <data name="motivo_rechazo" xml:space="preserve">
    <value>Motivo da Rejeição </value>
  </data>
  <data name="no_albaran" xml:space="preserve">
    <value>Nº Guia</value>
  </data>
  <data name="no_factura" xml:space="preserve">
    <value>Nº Fatura</value>
  </data>
  <data name="numero_envio" xml:space="preserve">
    <value>Número do envio</value>
  </data>
  <data name="oferta_correcta" xml:space="preserve">
    <value>Pedido correto</value>
  </data>
  <data name="oferta_devolucion_creada" xml:space="preserve">
    <value>Pedido de devolução criado. Só será valida após receção por parte da Indo.</value>
  </data>
  <data name="ojo" xml:space="preserve">
    <value>Olho</value>
  </data>
  <data name="pedidos_laboratorio" xml:space="preserve">
    <value>Pedido Laboratório</value>
  </data>
  <data name="pedido_stock" xml:space="preserve">
    <value>Pedido Stock</value>
  </data>
  <data name="pendientes_tramitacion" xml:space="preserve">
    <value>Procedimento pendente</value>
  </data>
  <data name="periodo_maximo_es_un_año" xml:space="preserve">
    <value>O periodo máximo é um ano</value>
  </data>
  <data name="pregunta_borrar_devolucion" xml:space="preserve">
    <value>Tem a certeza que quer anular a devolução (0) da ordem com o número de Pedido (1) ?</value>
  </data>
  <data name="pregunta_desea_continuar" xml:space="preserve">
    <value>Deseja continuar?</value>
  </data>
  <data name="producto" xml:space="preserve">
    <value>Produto</value>
  </data>
  <data name="progresivos" xml:space="preserve">
    <value>Progressivos</value>
  </data>
  <data name="puntos" xml:space="preserve">
    <value>Pontos</value>
  </data>
  <data name="rechazada" xml:space="preserve">
    <value>Rejeitada</value>
  </data>
  <data name="recogida" xml:space="preserve">
    <value>Recolha</value>
  </data>
  <data name="referencia_devolucion" xml:space="preserve">
    <value>Referência devolução</value>
  </data>
  <data name="resto" xml:space="preserve">
    <value>resto</value>
  </data>
  <data name="resultado_tramitacion_devoluciones_1" xml:space="preserve">
    <value>Foram enviadas (0) devoluções e (1) estão corretas. Pode verificar as corretas na janela de consulta de devoluções.</value>
  </data>
  <data name="resultado_tramitacion_devoluciones_2" xml:space="preserve">
    <value>Agora serão criados os documentos das devoluções. Por favor Imprima e junte ás lentes correspondentes.</value>
  </data>
  <data name="saldo_despues_grabar_pedido" xml:space="preserve">
    <value>Saldo depois de gravado o pedido </value>
  </data>
  <data name="solicitante" xml:space="preserve">
    <value>Candidato</value>
  </data>
  <data name="solicitar_devolucion" xml:space="preserve">
    <value>Solicitar devolução</value>
  </data>
  <data name="tipologia" xml:space="preserve">
    <value>Tipologia</value>
  </data>
  <data name="tipos_de_pedido" xml:space="preserve">
    <value>Tipos de pedido</value>
  </data>
  <data name="tramitar_devoluciones" xml:space="preserve">
    <value>Processar devoluções</value>
  </data>
  <data name="tramitar_devoluciones_seleccionadas" xml:space="preserve">
    <value>Analisar devoluções selecionadas</value>
  </data>
  <data name="vaciar_campos" xml:space="preserve">
    <value>Limpar campos</value>
  </data>
  <data name="ventana_espera_busqueda_estadisticas" xml:space="preserve">
    <value>PROCURANDO ESTATISTICAS</value>
  </data>
  <data name="ventana_espera_envio_clips" xml:space="preserve">
    <value>ENVIANDO CLIPS</value>
  </data>
  <data name="aviso_1_crear_contrasenya_nueva" xml:space="preserve">
    <value>Por favor, indicar o seu número de cliente e carregue em enviar para solicitar uma nova password</value>
  </data>
  <data name="aviso_2_crear_contrasenya_nueva" xml:space="preserve">
    <value>Em poucos minutos vai receber um email na sua conta de correio registada com a nova password</value>
  </data>
  <data name="aviso_cambio_password" xml:space="preserve">
    <value>Criamos uma nova password temporal que irá receber no seu email (O). Com a mesma pode entrar no Indonet e criar uma nova. A password temporal tem a validade de um dia.</value>
  </data>
  <data name="aviso_error_crear_password" xml:space="preserve">
    <value>Houve um erro ao criar a Password temporal</value>
  </data>
  <data name="aviso_faltan_datos" xml:space="preserve">
    <value>Falta completar dados no motivo escolhido</value>
  </data>
  <data name="codigo_restablecer_contrasenya" xml:space="preserve">
    <value>Chave de acesso para recuperar password</value>
  </data>
  <data name="debe_introducir_codigo_cliente" xml:space="preserve">
    <value>Deve introduzir o seu código de cliente</value>
  </data>
  <data name="equipo_de_indonet" xml:space="preserve">
    <value>O equipamento de {AplicacionINDONET}</value>
  </data>
  <data name="error_clave_no_puede_ser_misma" xml:space="preserve">
    <value>A nova password não pode ser a mesma que a temporal</value>
  </data>
  <data name="error_numero_caracteres_minimo_en_clave" xml:space="preserve">
    <value>Password deve ter no minimo 6 caracteres</value>
  </data>
  <data name="este_es_tu_codigo" xml:space="preserve">
    <value>Seu código {codigoIndonet}.</value>
  </data>
  <data name="gracias" xml:space="preserve">
    <value>Obrigado</value>
  </data>
  <data name="has_recibido_este_correo_solicitud_contraseña" xml:space="preserve">
    <value>Recebeu esta notificação porque solicitou a lembrança da password de acesso de {AplicacionINDONET}</value>
  </data>
  <data name="he_olvidado_mi_clave" xml:space="preserve">
    <value>» Não me recordo da Password</value>
  </data>
  <data name="paswword_actualizado" xml:space="preserve">
    <value>Password de acesso atualizada</value>
  </data>
  <data name="pregunta_enviar_o_cancelar" xml:space="preserve">
    <value>Deseja enviar ou cancelar?</value>
  </data>
  <data name="si_no_lo_has_solicitado" xml:space="preserve">
    <value>Se não solicitou a alteração basta ignorar este email e pode continuar a usar a password de acesso habitual</value>
  </data>
  <data name="solicitud_nueva_contraseña" xml:space="preserve">
    <value>Solicito nova password de acesso a {AplicacionINDONET}</value>
  </data>
  <data name="todos_los_pedidos_para_mi_optica" xml:space="preserve">
    <value>Todos os pedidos para a minha Óptica</value>
  </data>
  <data name="Usa_este_codigo_temporal" xml:space="preserve">
    <value>Usa esta chave de acesso temporal para entrar a {AplicacionINDONET} e recuperar a password da sua conta</value>
  </data>
  <data name="aviso_cambios_sin_guardar" xml:space="preserve">
    <value>Tem alterações pendentes para salvar</value>
  </data>
  <data name="aviso_eliminacion_boton_igual_suplementos" xml:space="preserve">
    <value>Para evitar erros, removemos o botão para igualar os suplementos em ambos os olhos. Agora deverá inserir os valores desejados para cada olho.</value>
  </data>
  <data name="bono_tarifa_plana" xml:space="preserve">
    <value>Bono / Taxa fixa</value>
  </data>
  <data name="cambio_datos_fiscales" xml:space="preserve">
    <value>Alteração de dados fiscais</value>
  </data>
  <data name="clase_de_comprobacion" xml:space="preserve">
    <value>Classe de comprovação:</value>
  </data>
  <data name="configuracion_indonet" xml:space="preserve">
    <value>Configuração Indonet</value>
  </data>
  <data name="contraseña_indomanager" xml:space="preserve">
    <value>Senha Indomanager</value>
  </data>
  <data name="crear_devolucion" xml:space="preserve">
    <value>Criar devolução</value>
  </data>
  <data name="descripcion_calibracion" xml:space="preserve">
    <value>Insira em milímetros o diâmetro real da circunferência anterior</value>
  </data>
  <data name="detalle_modelo_347" xml:space="preserve">
    <value>Extrato modelo 347</value>
  </data>
  <data name="duplicado_albaran" xml:space="preserve">
    <value>Duplicado de Guía</value>
  </data>
  <data name="duplicado_carta_detalle_bono" xml:space="preserve">
    <value>Duplicado Carta resumem Bono</value>
  </data>
  <data name="equivalencia_puntos_bonos" xml:space="preserve">
    <value>Pontos de equivalência bono</value>
  </data>
  <data name="extracto_cuenta" xml:space="preserve">
    <value>Extrato de Conta</value>
  </data>
  <data name="fecha_o_importe" xml:space="preserve">
    <value>Data ou quantia/valor</value>
  </data>
  <data name="filtro_productos_y_descripcion" xml:space="preserve">
    <value>Filtro de produto e descrição</value>
  </data>
  <data name="formacion" xml:space="preserve">
    <value>Formação</value>
  </data>
  <data name="giro" xml:space="preserve">
    <value>Ordem de pagamento/Transferência </value>
  </data>
  <data name="ha_escogido_esta_comprobación" xml:space="preserve">
    <value>Você escolheu esta comprovação</value>
  </data>
  <data name="incidencia" xml:space="preserve">
    <value>Incidência</value>
  </data>
  <data name="indicar_mes" xml:space="preserve">
    <value>Indique o mês</value>
  </data>
  <data name="indicar_periodo" xml:space="preserve">
    <value>Indique o período</value>
  </data>
  <data name="indica_lugar_recogida" xml:space="preserve">
    <value>Indique onde deve ser feita a recolha:</value>
  </data>
  <data name="mostrar_tabla_equivalencia_en_euros" xml:space="preserve">
    <value>Mostrar tabela de equivalência de pontos em euros</value>
  </data>
  <data name="motivo_comprobacion" xml:space="preserve">
    <value>Motivo da comprovação:</value>
  </data>
  <data name="motivo_no_aceptacion" xml:space="preserve">
    <value>Motivo de não-aceitação</value>
  </data>
  <data name="no_volver_a_mostrar" xml:space="preserve">
    <value>Não mostrar novamente</value>
  </data>
  <data name="solicitar_llamada" xml:space="preserve">
    <value>Solicitar chamada</value>
  </data>
  <data name="solicitudes" xml:space="preserve">
    <value>Petições</value>
  </data>
  <data name="texto_soporte_online" xml:space="preserve">
    <value>Se o nosso ATC lhe indicar, aceda este link para obter suporte on-line:</value>
  </data>
  <data name="aviso_ha_generado_premio" xml:space="preserve">
    <value>Este pedido dá origem a uma promoção aplicável ao segundo par. Deseja prosseguir com o novo pedido?
Em caso afirmativo, ajudá-lo-emos na escolha dos produtos disponíveis ou, caso prefira, poderá retomar o processo desde a rubrica "Realizar Pedido" </value>
  </data>
  <data name="aviso_nuevos_botones_promociones" xml:space="preserve">
    <value>A partir de agora já pode activar a nova lista de promoções através da página de configuração. Caso deseje conhecer esta nova funcionalidade, está disponível um vídeo com as respectivas instruções na secção de Tutoriais. Caso necessite mais informação, não hesite em entrar em contacto com o seu gestor comercial ou com o nosso serviço de Apoio ao Cliente. </value>
  </data>
  <data name="debe_seleccionar_un_pedido" xml:space="preserve">
    <value>Deve seleccionar um pedido</value>
  </data>
  <data name="eliminar_filtros" xml:space="preserve">
    <value>Eliminar filtros</value>
  </data>
  <data name="hay_generadores_para_el_pedido" xml:space="preserve">
    <value>Tem em vigor uma promoção que se pode aplicar a este pedido. Deseja ver os produtos elegíveis?</value>
  </data>
  <data name="pulse_boton_enviar_y_revision" xml:space="preserve">
    <value>Por favor faça click no botão de "Enviar e Confirmar" para que um agente do serviço ao cliente reveja o pedido.</value>
  </data>
  <data name="receta_no_grabada" xml:space="preserve">
    <value>Esta receita não se gravou correctamente.</value>
  </data>
  <data name="selecciona_premio" xml:space="preserve">
    <value>Seleccione o segundo par</value>
  </data>
  <data name="selecciona_promocion" xml:space="preserve">
    <value>Seleccione a Promoção</value>
  </data>
  <data name="seleccion_primer_pedido" xml:space="preserve">
    <value>Selecção do primeiro pedido</value>
  </data>
  <data name="mensaje_ayuda_atc" xml:space="preserve">
    <value>Alguma dúvida? A nossa equipa de Apoio ao Cliente pode ajudá-lo através do número de telefone 219609030</value>
  </data>
  <data name="mensaje_ayuda_atc_PT" xml:space="preserve">
    <value>Alguma dúvida? A nossa equipa de Apoio ao Cliente pode ajudá-lo através do número de telefone 219 609 030</value>
  </data>
  <data name="mensaje_ayuda_gestora" xml:space="preserve">
    <value>Sou o {0}, o seu gestor pessoal. Posso ajudá-lo em qualquer pedido sobre facturação, bonos ou devoluções. O meu número de telefone é o {2}</value>
  </data>
  <data name="mensaje_ayuda_gestora_indomanager" xml:space="preserve">
    <value>Sou o {0}, o seu gestor pessoal. Posso ajudá-lo em qualquer pedido sobre facturação, bonos ou devoluções. O meu número de telefone é o {2} e o meu endereço de email é: {3}</value>
  </data>
  <data name="aviso_cambio_contraseña_indonet" xml:space="preserve">
    <value>De modo a garantirmos a segurança e confidencialidade dos nossos clientes, recomendamos que modifique e personalize a sua palavra-passe de acesso à plataforma. Para tal, basta que aceda à página de definições, que se abrirá em seguida.</value>
  </data>
  <data name="aviso_nueva_clave_no_es_indonet" xml:space="preserve">
    <value>A nova palavra-passe não poderá ser INDONET.</value>
  </data>
  <data name="aviso_debe_rellenar_todos_los_campos" xml:space="preserve">
    <value>Tem que preencher todos os campos.</value>
  </data>
  <data name="notificacion" xml:space="preserve">
    <value>Notificação</value>
  </data>
  <data name="condiciones_anulación_pedidos" xml:space="preserve">
    <value>Política de Devoluções e Anulações de Pedidos</value>
  </data>
  <data name="continuar_sin_verlo" xml:space="preserve">
    <value>Continuar sem ver</value>
  </data>
  <data name="importante" xml:space="preserve">
    <value>IMPORTANTE:</value>
  </data>
  <data name="invitacion_uso_devoluciones" xml:space="preserve">
    <value>Para realizar as suas devoluções de um modo mais ágil e claro, sugerimos que utilize o nosso módulo de devoluções, através do qual poderá igualmente tramitar as recolhas. </value>
  </data>
  <data name="ir_a_gestion_devoluciones" xml:space="preserve">
    <value>Ir a: gestão de devoluções</value>
  </data>
  <data name="recogida_garantia" xml:space="preserve">
    <value>Recolhas para Devoluções</value>
  </data>
  <data name="recogida_montaje" xml:space="preserve">
    <value>Recolha para montagens</value>
  </data>
  <data name="recogida_para_montaje_o_comprobacion" xml:space="preserve">
    <value>Montagens</value>
  </data>
  <data name="redireccion_pestaña_devoluciones" xml:space="preserve">
    <value>Está prester a ser redirigido ao apartado de Devoluções. Deseja assistir a um tutorial prévio?</value>
  </data>
  <data name="resumen_recogidas" xml:space="preserve">
    <value>Resumo de Recolhas</value>
  </data>
  <data name="solicitar" xml:space="preserve">
    <value>Solicitar</value>
  </data>
  <data name="solo_solicitar_recogida" xml:space="preserve">
    <value>Solicitar apenas a recolha</value>
  </data>
  <data name="texto_importante_devoluciones" xml:space="preserve">
    <value>Para evitar danos nas lentes e facilitar o processo de análise, as mesmas deverão ser enviadas numa embalagem apropriada e em separado, sem elementos que as possam danificar como agrafes, cartões, etc.</value>
  </data>
  <data name="aviso_recogidas_montaje_o_devolucion_1" xml:space="preserve">
    <value>Estas recolhas não têm custos adicionais</value>
  </data>
  <data name="aviso_recogidas_montaje_o_devolucion_2" xml:space="preserve">
    <value>Dispõe de {2} recolhas de devoluções gratuitas por mês ao solicitar pelo Indonet</value>
  </data>
  <data name="numero_recogidas_disponibles_este_mes" xml:space="preserve">
    <value>Recolhas gratuitas disponíveis este mês</value>
  </data>
  <data name="numero_recogidas_efectuadas_este_mes" xml:space="preserve">
    <value>Recolhas pedidas este mês</value>
  </data>
  <data name="quiero_solicitar_recogida" xml:space="preserve">
    <value>Desejo pedir uma recolha em: </value>
  </data>
  <data name="solicitud_recogida" xml:space="preserve">
    <value>Pedido de Recolha</value>
  </data>
  <data name="aviso_biselar_por_defecto" xml:space="preserve">
    <value>Biselagem por defeito</value>
  </data>
  <data name="aviso_comprobaciones" xml:space="preserve">
    <value>A partir de agora também pode solicitar a comprovação de lentes nesta mesma secção. 
Basta selecionar uma das opções em “Classe de comprovação” e indicar o motivo. 
Caso necessite informação adicional, não hesite em contactar-nos.</value>
  </data>
  <data name="añadir_archivos" xml:space="preserve">
    <value>Anexar ficheiros</value>
  </data>
  <data name="escribe_los_detalles_complementarios_que_creas_conveniente" xml:space="preserve">
    <value>Por favor inclua quaisquer detalhes adicionais que considere pertinentes</value>
  </data>
  <data name="tus_datos" xml:space="preserve">
    <value>Os seus dados</value>
  </data>
  <data name="tu_comercial" xml:space="preserve">
    <value>O/A seu/sua Gestor/a Comercial</value>
  </data>
  <data name="tu_gestor" xml:space="preserve">
    <value>O/A seu/sua Gestor/a de Cliente</value>
  </data>
  <data name="tu_peticion" xml:space="preserve">
    <value>A sua petição</value>
  </data>
  <data name="enviada" xml:space="preserve">
    <value>Enviada</value>
  </data>
  <data name="mensaje_advertencia_espesor_ranura" xml:space="preserve">
    <value>IMPORTANTE: Deverá verificar sempre o tipo de ranhura para serem evitados incidentes na montagem. Sublinhamos que: 
Espessura min. ranhura Nylon =1,5mm
Espessura min. ranhura Metal = 2,2mm
Caso tenha qualquer dúvida, por favor não hesite em entrar em contacto com o Apoio ao Cliente.</value>
  </data>
  <data name="String1" xml:space="preserve">
    <value />
  </data>
  <data name="domingo" xml:space="preserve">
    <value>Domingo</value>
  </data>
  <data name="jueves" xml:space="preserve">
    <value>Quinta-feira</value>
  </data>
  <data name="lunes" xml:space="preserve">
    <value>Segunda-feira</value>
  </data>
  <data name="martes" xml:space="preserve">
    <value>Terça-feira</value>
  </data>
  <data name="miercoles" xml:space="preserve">
    <value>Quarta-feira</value>
  </data>
  <data name="sabado" xml:space="preserve">
    <value>Sábado</value>
  </data>
  <data name="viernes" xml:space="preserve">
    <value>Sexta-feira</value>
  </data>
  <data name="alta_buscador" xml:space="preserve">
    <value>Ativação / modificações no motor de pesquisa</value>
  </data>
  <data name="anular_cambios" xml:space="preserve">
    <value>Anular as mudanças efetuadas</value>
  </data>
  <data name="audiologia" xml:space="preserve">
    <value>Audiologia</value>
  </data>
  <data name="aviso_eliminar_buscador_web" xml:space="preserve">
    <value>Está prestes a apagar os seus dados do nosso motor de pesquisas na web. 
Caso continue, deixará de surgir nas possíveis pesquisas que realizem os utilizadores através do mesmo. 
Deseja continuar?</value>
  </data>
  <data name="baja_buscador" xml:space="preserve">
    <value>Cancelar motor de pesquisa</value>
  </data>
  <data name="baja_vision" xml:space="preserve">
    <value>Baixa Visão</value>
  </data>
  <data name="buscador_web" xml:space="preserve">
    <value>Motor de Pequisa Web</value>
  </data>
  <data name="de" xml:space="preserve">
    <value>de</value>
  </data>
  <data name="horario_comercial" xml:space="preserve">
    <value>Horário comercial</value>
  </data>
  <data name="optometria" xml:space="preserve">
    <value>Optometria</value>
  </data>
  <data name="rellenar_con_datos_por_defecto" xml:space="preserve">
    <value>Preencher com os dados por defeito</value>
  </data>
  <data name="taller_de_montaje" xml:space="preserve">
    <value>Oficinas de Montagem</value>
  </data>
  <data name="terapia_visual" xml:space="preserve">
    <value>Terapiais Visuais</value>
  </data>
  <data name="texto_autorizo_buscar_web" xml:space="preserve">
    <value>Autorizo que a INDO OPTICAL SLU guarde os meus dados e os armazene em bases de dados cujos conteúdos são destinados exclusivamente para a utilização do motor de pesquisas de Ópticas, com o objetivo de manter comunicação com os clientes interessados. </value>
  </data>
  <data name="venta_online" xml:space="preserve">
    <value>Venda on-line</value>
  </data>
  <data name="y_de" xml:space="preserve">
    <value>e de</value>
  </data>
  <data name="informacion_optica" xml:space="preserve">
    <value>Informação da óptica</value>
  </data>
  <data name="información_contacto" xml:space="preserve">
    <value>Informação de contacto</value>
  </data>
  <data name="mensaje_entrada_buscador" xml:space="preserve">
    <value>Configure aqui os seus dados para aparecer nos resultados do motor de pesquisa de Ópticas de www.indo.es</value>
  </data>
  <data name="tooltip_boton_copiar" xml:space="preserve">
    <value>Copiar de segunda a sábado.</value>
  </data>
  <data name="ha_escogido_esta_devolucion" xml:space="preserve">
    <value>Você escolheu esta devolução</value>
  </data>
  <data name="configuracion_hojaPedidoIndoscan" xml:space="preserve">
    <value>Folha de Indoscan</value>
  </data>
  <data name="configuracion_link_hojaPedidosFax" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/Indonet/Enlaces/HojaPedidosFax_PT.pdf</value>
  </data>
  <data name="aviso_texto_no_copiar_pedido" xml:space="preserve">
    <value>IMPORTANTE 
Existe a possibilidade de que o produto solicitado se tenha atualizado a desde a data da realização do pedido original. Por favor reveja todos os campos e tratamentos para assegurar que as lentes selecionadas são efetivamente as corretas.</value>
  </data>
  <data name="selecciona_trazador" xml:space="preserve">
    <value>Selecione o traçador</value>
  </data>
  <data name="selecciona_trazador_1" xml:space="preserve">
    <value>Você tem mais de um Indobisel conectado ao INDONET. Por favor, selecione a traçador que você deseja conectar:</value>
  </data>
  <data name="facturacion" xml:space="preserve">
    <value>Faturas e notas de entrega</value>
  </data>
  <data name="url_ayuda_inset" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/ayudaIndonet/inset_PT.html</value>
  </data>
  <data name="ver_albaran" xml:space="preserve">
    <value>Ver Guia</value>
  </data>
  <data name="consulta_disponibilidad" xml:space="preserve">
    <value>Consulta disponibilidade</value>
  </data>
  <data name="marca_opcion_deseada" xml:space="preserve">
    <value>Marque la opción deseada</value>
  </data>
  <data name="url_condiciones_indomedcare_new" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/ayudaIndonet/condiciones_indomedcare_PT.txt</value>
  </data>
  <data name="url_condiciones_indonet_new" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/ayudaIndonet/condiciones_indonet_PT.txt</value>
  </data>
  <data name="bisel_solar" xml:space="preserve">
    <value>Moldura Envolvente Solar</value>
  </data>
  <data name="catalogo" xml:space="preserve">
    <value>Catálogo</value>
  </data>
  <data name="consultar_pedidos" xml:space="preserve">
    <value>Consultar pedidos</value>
  </data>
  <data name="gestion_devoluciones" xml:space="preserve">
    <value>Gestão de devoluções</value>
  </data>
  <data name="tienes_un aviso" xml:space="preserve">
    <value>Você tem um aviso</value>
  </data>
  <data name="texto_presentacion10" xml:space="preserve">
    <value>Realizar e controlar devoluções;</value>
  </data>
  <data name="texto_presentacion11" xml:space="preserve">
    <value>Controlar e imprimir faturas através do Indomanager.</value>
  </data>
  <data name="texto_presentacion8" xml:space="preserve">
    <value>Através do Indonet é possível:</value>
  </data>
  <data name="texto_presentacion9" xml:space="preserve">
    <value>Fazer um seguimento detalhado dos seus pedidos;</value>
  </data>
  <data name="url_ayuda_pedidorapidoclasico" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/ayudaIndonet/pedido_rapidoclasico_PT.txt</value>
  </data>
  <data name="grabado_clasico" xml:space="preserve">
    <value>Gravação classica</value>
  </data>
  <data name="grabado_rapido" xml:space="preserve">
    <value>Gravação rápida</value>
  </data>
  <data name="aviso_faltan_observaciones" xml:space="preserve">
    <value>Por favor indique-nos brevemente nas observações o motivo da revisão do pedido.</value>
  </data>
  <data name="pedido_urgente" xml:space="preserve">
    <value>Pedido urgente</value>
  </data>
  <data name="degradado_alto" xml:space="preserve">
    <value>Degradê alto - 5mm</value>
  </data>
  <data name="degradado_bajo" xml:space="preserve">
    <value>Degradê baixo + 5mm</value>
  </data>
  <data name="texto_ex_bono" xml:space="preserve">
    <value>O tratamento solicitado está excluído do Bono e tem um custo extra de {0} por lente.</value>
  </data>
  <data name="vi" xml:space="preserve">
    <value>VI</value>
  </data>
  <data name="vl" xml:space="preserve">
    <value>VD</value>
  </data>
  <data name="vp" xml:space="preserve">
    <value>VP</value>
  </data>
  <data name="limpiar" xml:space="preserve">
    <value>Limpar Formulário</value>
  </data>
  <data name="resolucion" xml:space="preserve">
    <value>Resolução:</value>
  </data>
  <data name="debes_seleccionar_una_resolucion" xml:space="preserve">
    <value>Deve selecionar pelo menos uma resolução.</value>
  </data>
  <data name="debes_seleccionar_un_motivo" xml:space="preserve">
    <value>Deve selecionar pelo menos um motivo.</value>
  </data>
  <data name="vas_a_borrar_los_datos_esas_seguro" xml:space="preserve">
    <value>Vai eliminar os dados do formulário. Deseja confirmar?</value>
  </data>
  <data name="calcular_espesores" xml:space="preserve">
    <value>Calcular espessuras</value>
  </data>
  <data name="opciones_de_pedido" xml:space="preserve">
    <value>Opções de pedido</value>
  </data>
  <data name="que_tipo_devolucion_quieres_realizar" xml:space="preserve">
    <value>Seleccione que tipo de devolução deseja realizar:</value>
  </data>
  <data name="comprobaciones" xml:space="preserve">
    <value>Comprovações</value>
  </data>
  <data name="escoge_tipo_mimetika" xml:space="preserve">
    <value>Escolha o tipo de Isótipo</value>
  </data>
  <data name="iniciales_en_mimetika" xml:space="preserve">
    <value>Iniciais</value>
  </data>
  <data name="isotipo_en_maxvita" xml:space="preserve">
    <value>Isótipo</value>
  </data>
  <data name="isotipo_en_mimetika" xml:space="preserve">
    <value>Isótipo</value>
  </data>
  <data name="isotipo_rodenstock" xml:space="preserve">
    <value>Isótipo RODENSTOCK</value>
  </data>
  <data name="isotipo_especial" xml:space="preserve">
    <value>Isótipo especial</value>
  </data>
  <data name="pulsa_para_recuperar_VMAP" xml:space="preserve">
    <value>Pulsa para recuperar el código VMAP</value>
  </data>
  <data name="sin_isotipo_en_mimetika" xml:space="preserve">
    <value>Sem Isótipo</value>
  </data>
  <data name="en_proceso" xml:space="preserve">
    <value>Em curso</value>
  </data>
  <data name="gama_de_lentes" xml:space="preserve">
    <value>Gama de lentes:</value>
  </data>
  <data name="aviso_grabado_rapido" xml:space="preserve">
    <value>O seu pedido foi enviado à Indo/Rodenstock. Se todos os dados estiverem corretos, nuns minutos o mesmo será gravado.
Por favor verifique no separador “Pedidos realizados” que tenha sido atribuído um Nº. De Pedido. Caso não tenha, por favor entre em contato com a nossa equipa de Apoio ao cliente.
Pedidos Indo: 900 110 557
Pedidos Rodenstock: 900 102 189</value>
  </data>
  <data name="gama" xml:space="preserve">
    <value>Gama:</value>
  </data>
  <data name="mensaje_ayuda_atc_rod" xml:space="preserve">
    <value>Alguma dúvida? A nossa equipa de Apoio ao Cliente pode ajudá-lo através do número de telefone 900 102 189</value>
  </data>
  <data name="solicitar_devolucion_rodenstock" xml:space="preserve">
    <value>Activar Garantía Especial Rodenstock</value>
  </data>
  <data name="aviso_pedir_devolucionRD" xml:space="preserve">
    <value>Solicitar Garantía Especial Rodenstock</value>
  </data>
  <data name="texto_activar_bonificacion_rodenstock_checkbox" xml:space="preserve">
    <value>Activar Garantía Especial Rodenstock</value>
  </data>
  <data name="aviso_devolucion_mas_de_un_pedido_seleccionado" xml:space="preserve">
    <value>Ha seleccionado más de un pedido para devolución. Solamente se tratará el primer pedido. ¿Desea continuar?</value>
  </data>
  <data name="aviso_no_se_han_seleccionado_pedidos_aptos_para_devolucion" xml:space="preserve">
    <value>No se han seleccionado pedidos aptos para devolución</value>
  </data>
  <data name="aviso_opcion_valida_solo_desde_pedidosrealizados_solicitarabono" xml:space="preserve">
    <value>Esta opción sólo es válida desde Pedidos Realizados o Solicitar Abono</value>
  </data>
  <data name="seguro_actualizar_pedido" xml:space="preserve">
    <value>Este cálculo não é necessário para enviar o pedido e pode demorar algum tempo.
Tem certeza de que deseja continuar?</value>
  </data>
  <data name="rodenstockmanager" xml:space="preserve">
    <value>RodenstockManager</value>
  </data>
  <data name="bienvenido_a_rodenstockmanager" xml:space="preserve">
    <value>bem vindo a Rodenstock Manager</value>
  </data>
  <data name="url_fb_rodenstock" xml:space="preserve">
    <value>https://www.facebook.com/RodenstockES/</value>
  </data>
  <data name="url_in_rodenstock" xml:space="preserve">
    <value>https://www.instagram.com/rodenstock_es/</value>
  </data>
  <data name="url_indoloyalty" xml:space="preserve">
    <value>https://indoloyalty.com</value>
  </data>
  <data name="wells_pedido_sonae_obligatorio" xml:space="preserve">
    <value>No campo Referência deverá ser indicada a encomenda da Sonae</value>
  </data>
</root>