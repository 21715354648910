import { XMLParser, XMLBuilder } from 'fast-xml-parser';
import requests from '@/services/requests';
import response from '@/services/response';

const estado = {
  lecturasMID: [],
};

const getters = {
  lecturasMID: state => state.lecturasMID,
};

const actions = {
  async GetLecturasMID({ commit }) {
    const data = await requests.mimetikaId.getLecturasMID();
    console.log('Tipo de data:', Array.isArray(data.data) ? 'Array' : typeof data.data);
    console.log('Data:', data.data);

    if (typeof data.data === 'string') {
      window.open(data.data, '_blank');
      return false;
    }

    if (data && data.status !== 200) {
      return false;
    }

    commit('setLecturasMID', data);

    const newData = data.data.map(item => ({
      ...item,
      ID_JOB: item.ID,
      VMAP: item.ID,
    }));

    return newData;
  },
  async GetLecturasSingleMeasure({ commit }, uuid) {
    const data = await requests.mimetikaId.getSingleMeasure(uuid);

    if (data && data.status !== 200) {
      return false;
    }

    const newdata = data.data[0];
    newdata.processed_data = JSON.parse(newdata.processed_data);

    return newdata;
  },
  async SaveMimetikaData({ rootState, state }, { mimetika, json }) {

    mimetika.datos = json;

    const data = await requests.mimetikaId.saveMimetikaData(rootState.auth.username, rootState.auth.token, state.profile, mimetika);

    if (data && data.status !== 200) {
      return false;
    }
    const p = new XMLParser({
        numberParseOptions: {
          leadingZeros: false,
        }
    });

    const newData = p.parse(data.data);
    if(newData['soap:Envelope']['soap:Body']['GuardarDatosMimetika2Response']['GuardarDatosMimetika2Result'] !== 0) {
      throw new Error(newData['soap:Envelope']['soap:Body']['GuardarDatosMimetika2Response']['ExportReturnMessage']);
    }
    return newData['soap:Envelope']['soap:Body']['GuardarDatosMimetika2Response']['ExportReturnMessage'];
  },
};

const mutations = {
  setLecturasMID(state, lecturasMID) {
    state.lecturasMID = lecturasMID;
  },
};

export default {
  state: estado,
  getters,
  actions,
  mutations,
};
