import { App } from "vue";
import Alert from "@/components/theme/ui/Alert.vue";
import { createApp, ref } from "vue";

export default {
  install(app: App) {
    const alertRef = ref(null);

    app.config.globalProperties.$alert = {
      show(options: any) {
        if (alertRef.value) {
          return alertRef.value.showAlert(options);
        } else {
          console.error("Alert component is not mounted");
        }
      },
    };

    app.mixin({
      mounted() {
        if (this.$refs.alert) {
          alertRef.value = this.$refs.alert;
        }
      },
    });
  },
};
