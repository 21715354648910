/* eslint-disable import/no-extraneous-dependencies */
import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import createIndexedDBPlugin from './plugins/indexeddb-storage';

import * as modules from './modules/index';

export default createStore({
  modules: {
    ...modules,
  },
  plugins: [
    // Plugin para auth usando localStorage
    createPersistedState({
      paths: ['auth', 'gama'], // Solo persistir lo que necesitamos en localStorage
      storage: window.localStorage,
    }),
    createIndexedDBPlugin([
      'indo',
      'notifications',
      'ofertas',
      'academy',
      'misBiselados',
      'pedidos'
    ], 'indo-store')
  ],
});
