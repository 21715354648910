<template>
  <div>
    <router-view/>
    <Toast />
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import sessionService from '@/services/session';
import { useRouter } from 'vue-router';
import Toast from '@/components/theme/ui/Toast.vue';

export default defineComponent({
  name: 'App',
  components: {
    Toast
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    // Lista de eventos que consideramos como actividad real del usuario
    const userActivityEvents = [
      'mousedown',
      'keydown',
      'mousemove',
      'touchstart',
      'click',
      'scroll'
    ];

    const resetTimer = (event: Event) => {
      // Solo resetear si el usuario está autenticado y en una ruta protegida
      if (store.getters.isAuthenticated && 
          router.currentRoute.value.path !== '/login' && 
          localStorage.getItem('savedCredentials')) {
        sessionService.resetTimer(store);
      }
    };

    onMounted(() => {
      // Solo iniciar timer si hay credenciales guardadas
      if (store.getters.isAuthenticated && localStorage.getItem('savedCredentials')) {
        sessionService.startSessionTimer(store);
      }

      userActivityEvents.forEach(event => {
        document.addEventListener(event, resetTimer, { passive: true });
      });
    });

    onBeforeUnmount(() => {
      userActivityEvents.forEach(event => {
        document.removeEventListener(event, resetTimer);
      });
      sessionService.clearSessionTimer();
    });

    // Observar cambios en la autenticación
    store.watch(
      (state, getters) => getters.isAuthenticated,
      (isAuthenticated) => {
        if (isAuthenticated) {
          sessionService.startSessionTimer(store);
        } else {
          sessionService.clearSessionTimer();
        }
      }
    );
  }
});
</script>

