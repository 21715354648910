import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import clickOutside from "./directives/clickOutside";
import { dropdown, dropdownTrigger, dropdownContent } from "./directives/dropdown";
import alertPlugin from "./plugins/alert";

import "./index.scss";

// console.log(import.meta.env);

window.addEventListener("beforeunload", async (event) => {
  try {
    // await clear();
  } catch (error) {
    console.error("Error al limpiar IndexedDB:", error);
  }
});

if (import.meta.env.MODE !== "development") {
  window.consoleHolder = window.console;
  window.console = {};
  Object.keys(window.consoleHolder).forEach((key) => {
    console[key] = () => {};
  });
}

const app = createApp(App);

app.directive("click-outside", clickOutside);
app.directive("dropdown", dropdown);
app.directive("dropdown-trigger", dropdownTrigger);
app.directive("dropdown-content", dropdownContent);

app.use(store).use(router).use(i18n).use(alertPlugin).mount("#app");

// https://medium.com/simform-engineering/globalize-your-vue-app-a-practical-guide-to-vue-3-internationalization-93f5c7da4494
// https://lokalise.com/blog/vue-i18n/
// https://stackoverflow.com/questions/68802337/vue-js-i18n-load-external-jsons-from-server-and-make-it-globally-accessible-i
