import { validarCondicionesIniciales, enviarARevisarPedido } from '@/helpers/validacionPedido';

const estado = {
  profile2: null,
  tratamiento: null,
  indice: null,
  caracteristicas: null,
  referencia: '',
  areaCliente: {},
};

const getters = {
  od: (state) => state.od,
  oi: (state) => state.oi,
  descentramiento: (state) => state.descentramiento,
  coloracion: (state) => state.coloracion,
  focomaterial: (state) => state.focomaterial,
  color: (state) => state.color,
  lente: (state) => state.lente,
  adicionales: (state) => state.adicionales,
  referencia: (state) => state.referencia,
  areaCliente: (state) => state.areaCliente,
  tratamiento: (state) => state.tratamiento,
};

const actions = {
  /**
   * Prepara el pedido para enviar a la API
   * @param {Object} context - Contexto de Vuex
   * @param {Object} pedido - Datos del pedido a preparar
   * @returns {Promise<void>}
   */
  async preparePedido({ commit, dispatch, rootState, state }) {
    const nuevoPedido = rootState.indo.nuevoPedido;
    const profile = rootState.indo.profile;
    const pedido = { ...nuevoPedido.oClaseBAPI };


    /**
     * Obtiene los datos del Ojo Izquierdo y Derecho
     */
    let ojoInIzq = { ...pedido.listOJOIN.clsZES_OJO_INPUT.filter((o) => o.ZOJO === 'I') }[0]
    let ojoInDch = { ...pedido.listOJOIN.clsZES_OJO_INPUT.filter((o) => o.ZOJO === 'D') }[0]

    // Procesar esfera OD
    if (state.od.esfera !== '') {
        const sign = Math.sign(parseFloat(state.od.esfera.replace(',', '.'))) > 0 ? '+' : '-';
        ojoInDch.ESFERA = `${parseFloat(state.od.esfera.replace(',', '.').replace('+', '').replace('-', ''))}`;
        ojoInDch.ESFERA_CON_SIGNO = `${sign}${parseFloat(state.od.esfera.replace(',', '.'))}`;
        ojoInDch.SIGNO_ESF = `${sign}`;
    } else {
        ojoInDch.ESFERA = '';
        ojoInDch.ESFERA_CON_SIGNO = '';
        ojoInDch.SIGNO_ESF = '';
    }

    // Procesar esfera OI
    if (state.oi.esfera !== '') {
        const sign = Math.sign(parseFloat(state.oi.esfera.replace(',', '.'))) > 0 ? '+' : '-';
        ojoInIzq.ESFERA = `${parseFloat(state.oi.esfera.replace(',', '.').replace('+', '').replace('-', ''))}`;
        ojoInIzq.ESFERA_CON_SIGNO = `${sign}${parseFloat(state.oi.esfera.replace(',', '.'))}`;
        ojoInIzq.SIGNO_ESF = `${sign}`;
    } else {
        ojoInIzq.ESFERA = '';
        ojoInIzq.ESFERA_CON_SIGNO = '';
        ojoInIzq.SIGNO_ESF = '';
    }

    // Procesar cilindro OD
    if (state.od.cilindro !== '') {
        const sign = Math.sign(parseFloat(state.od.cilindro.replace(',', '.'))) > 0 ? '+' : '-';
        ojoInDch.CILINDRO = `${parseFloat(state.od.cilindro.replace(',', '.').replace('+', '').replace('-', ''))}`;
        ojoInDch.CILINDRO_CON_SIGNO = `${sign}${parseFloat(state.od.cilindro.replace(',', '.'))}`;
        ojoInDch.SIGNO_CIL = `${sign}`;
    } else {
        ojoInDch.CILINDRO = '';
        ojoInDch.CILINDRO_CON_SIGNO = '';
        ojoInDch.SIGNO_CIL = '';
    }

    // Procesar cilindro OI
    if (state.oi.cilindro !== '') {
        const sign = Math.sign(parseFloat(state.oi.cilindro.replace(',', '.'))) > 0 ? '+' : '-';
        ojoInIzq.CILINDRO = `${parseFloat(state.oi.cilindro.replace(',', '.').replace('+', '').replace('-', ''))}`;
        ojoInIzq.CILINDRO_CON_SIGNO = `${sign}${parseFloat(state.oi.cilindro.replace(',', '.'))}`;
        ojoInIzq.SIGNO_CIL = `${sign}`;
    } else {
        ojoInIzq.CILINDRO = '';
        ojoInIzq.CILINDRO_CON_SIGNO = '';
        ojoInIzq.SIGNO_CIL = '';
    }

    ojoInDch.EJE = state.od.eje;
    ojoInIzq.EJE = state.oi.eje;

    ojoInDch.ADICION = state.od.adicion;
    ojoInIzq.ADICION = state.oi.adicion;

    if (state.od.diametro !== null) ojoInDch.DIAMETRO = state.od.diametro;
    if (state.oi.diametro !== null) ojoInIzq.DIAMETRO = state.oi.diametro;

    if (state.coloracion !== null) {
        ojoInDch.COLORACION = state.coloracion;
        ojoInIzq.COLORACION = state.coloracion;
    }

    if (state.color !== null) {
        ojoInDch.COLOR = state.color;
        ojoInIzq.COLOR = state.color;
    }

    if (state.focomaterial !== null) {
        ojoInDch.FOCO_MATERIAL = state.focomaterial;
        ojoInIzq.FOCO_MATERIAL = state.focomaterial;
    }

    if (rootState.ofertas.indice !== null) {
        ojoInDch.IND_REF = rootState.ofertas.indice;
        ojoInIzq.IND_REF = rootState.ofertas.indice;
    } else if (state.descentramiento.Indice) {
        ojoInDch.IND_REF = state.descentramiento.Indice;
        ojoInIzq.IND_REF = state.descentramiento.Indice;
    }

    if (state.lente !== null) {
        ojoInDch.JER_EDI_INET = state.lente;
        ojoInIzq.JER_EDI_INET = state.lente;
    }

    if (state.tratamiento !== null) {
        ojoInDch.TRATAM1 = state.tratamiento;
        ojoInIzq.TRATAM1 = state.tratamiento;
    } else if (state.descentramiento.Tratamiento) {
        ojoInDch.TRATAM1 = state.descentramiento.Tratamiento;
        ojoInIzq.TRATAM1 = state.descentramiento.Tratamiento;
    }

    if (state.descentramiento.CruzMontaje) {
        const pos = state.descentramiento.CruzMontaje.split(';');
        ojoInDch.Cruz_posX = pos[0];
        ojoInDch.Cruz_posY = pos[1];
        ojoInIzq.Cruz_posX = pos[0];
        ojoInIzq.Cruz_posY = pos[1];
    }

    // Adicionales
    if (state.oi.adicionales) {
        state.oi.adicionales.forEach(d => {
            if (d.valor !== undefined) {
                ojoInIzq[d.BAPI_CAMPO] = d.valor;
            }
        });
    }

    if (state.od.adicionales) {
        state.od.adicionales.forEach(d => {
            if (d.valor !== undefined) {
                ojoInDch[d.BAPI_CAMPO] = d.valor;
            }
        });
    }

    if (state.adicionales) {
        state.adicionales.forEach(d => {
            if (d.valor !== undefined) {
                ojoInDch[d.BAPI_CAMPO] = d.valor;
                ojoInIzq[d.BAPI_CAMPO] = d.valor;
            }
        });
    }

    // Suplementos
    if (state.od.prisma1.dioptrias !== null) ojoInDch.DIOP_PR1 = state.od.prisma1.dioptrias;
    if (state.od.prisma1.base !== null) ojoInDch.BASE_PR1 = state.od.prisma1.base;
    if (state.od.prisma1.grados !== null) ojoInDch.GRAD_PR1 = state.od.prisma1.grados;

    if (state.oi.prisma1.dioptrias !== null) ojoInIzq.DIOP_PR1 = state.oi.prisma1.dioptrias;
    if (state.oi.prisma1.base !== null) ojoInIzq.BASE_PR1 = state.oi.prisma1.base;
    if (state.oi.prisma1.grados !== null) ojoInIzq.GRAD_PR1 = state.oi.prisma1.grados;

    if (state.od.prisma2.dioptrias !== null) ojoInDch.DIOP_PR2 = state.od.prisma2.dioptrias;
    if (state.od.prisma2.base !== null) ojoInDch.BASE_PR2 = state.od.prisma2.base;
    if (state.od.prisma2.grados !== null) ojoInDch.GRAD_PR2 = state.od.prisma2.grados;

    if (state.oi.prisma2.dioptrias !== null) ojoInIzq.DIOP_PR2 = state.oi.prisma2.dioptrias;
    if (state.oi.prisma2.base !== null) ojoInIzq.BASE_PR2 = state.oi.prisma2.base;
    if (state.oi.prisma2.grados !== null) ojoInIzq.GRAD_PR2 = state.oi.prisma2.grados;

    if (state.od.descentramientoSelected !== null) {
        ojoInDch.COTA_DESN = state.od.descentramientoSelected;
    } else {
        ojoInDch.COTA_DESN = '';
    }

    if (state.oi.descentramientoSelected !== null) {
        ojoInIzq.COTA_DESN = state.oi.descentramientoSelected;
    } else {
        ojoInIzq.COTA_DESN = '';
    }

    ojoInDch.SOLO_STOCK = state.od.stock ? 'X' : '';
    ojoInIzq.SOLO_STOCK = state.oi.stock ? 'X' : '';

    ojoInDch.SOLO_INFOR = state.od.informativo ? 'X' : '';
    ojoInIzq.SOLO_INFOR = state.oi.informativo ? 'X' : '';

    pedido.listOJOIN.clsZES_OJO_INPUT = [
        { ...ojoInDch },
        { ...ojoInIzq },
    ];

    pedido.VKORG = rootState.gama.areaCliente && rootState.gama.areaCliente.ORG_VENTA ? rootState.gama.areaCliente.ORG_VENTA.padStart(4, '0') : profile.AreaVenta.ORG_VENTA.padStart(4, '0');
    pedido.REF_PEDIDO = state.referencia;

    if (pedido.listCABIN.clsZES_CAB_INPUT && pedido.listCABIN.clsZES_CAB_INPUT.length > 0) {
        pedido.listCABIN.clsZES_CAB_INPUT[0].REF_CLIENT = state.referencia;
        pedido.listCABIN.clsZES_CAB_INPUT[0].VKORG_DESCR = rootState.gama.areaCliente.texto_organizacion.toUpperCase();
    } else {
        pedido.listCABIN.clsZES_CAB_INPUT.REF_CLIENT = state.referencia;
        pedido.listCABIN.clsZES_CAB_INPUT.VKORG_DESCR = rootState.gama.areaCliente.texto_organizacion.toUpperCase();
    }

    // CHKB_POS_USO
    if (profile.DatosConfig.CONFIGURACION.TIPO_POS_USO === "" || profile.DatosConfig.CONFIGURACION.TIPO_POS_USO === "1") {
        pedido.listCABIN.clsZES_CAB_INPUT[0].CHKB_POSUSO = 'X';
    } else {
        pedido.listCABIN.clsZES_CAB_INPUT[0].CHKB_POSUSO = '';
    }

    // Almacenar el pedido en el store de indo
    await dispatch('GuardarPedido', { pedido }, { root: true });

    return pedido;
  },

  /**
   * Calcula el pedido y lo envía al servidor
   * @param {Object} context - Contexto de Vuex
   * @param {Object} payload - Datos adicionales
   * @param {Object} payload.pedido - El pedido a calcular
   * @param {Object} payload.areaCliente - Área de cliente seleccionada
   * @param {boolean} payload.review - Si requiere revisión
   * @param {Object} payload.descentramientos - Datos de descentramientos
   * @returns {Promise<Object>} El pedido calculado
   * @throws {Error} Si hay errores de validación o en el cálculo
   */
  async calcularPedido({ dispatch, rootState }, { pedido, areaCliente, review, descentramientos }) {
    // 1. Validar condiciones iniciales
    const isValid = validarCondicionesIniciales(
      rootState.indo.profile,
      pedido,
      areaCliente,
      review,
      descentramientos
    );

    if (Array.isArray(isValid) && isValid.length > 0) {
      throw new Error(JSON.stringify(isValid));
    }

    try {
      // 2. Enviar a cálculo
      const newOrder = await dispatch('PrepararPedido', { pedido }, { root: true });

      // 3. Verificar errores
      const errores = await dispatch('GetErroresPedido', null, { root: true });
      if (errores.length > 0) {
        throw new Error(JSON.stringify(errores));
      }

      return newOrder;
    } catch (error) {
      // Si el error ya es un array de errores (de la validación), lo lanzamos tal cual
      if (error.message.startsWith('[')) {
        throw error;
      }
      // Si es un error de la API, obtenemos los errores del pedido
      const errores = await dispatch('GetErroresPedido', null, { root: true }) || [];
      if (errores.length > 0) {
        throw new Error(JSON.stringify(errores));
      }
      // Si no hay errores específicos, lanzamos el error original
      throw error;
    }
  },

  /**
   * Envía el pedido al servidor
   * @param {Object} context - Contexto de Vuex
   * @param {Object} payload - Datos del envío
   * @param {Object} payload.data - Datos del pedido (modo, KUNWE, duplicado, etc)
   * @param {Object} payload.areaCliente - Área de cliente seleccionada
   * @returns {Promise<void>}
   */
  async enviarPedido({ dispatch, rootState }, { data, areaCliente }) {
    const pedido = rootState.indo.nuevoPedido.oClaseBAPI;
    const profile = rootState.indo.profile;

    // Asignar modo de funcionamiento y datos adicionales
    pedido.MODO_FUNCIONAM = data.modo;
    if (data.KUNWE) {
      pedido.listCABIN.clsZES_CAB_INPUT[0].KUNWE = data.KUNWE;
    }
    if (data.duplicado) {
      pedido.listCABIN.clsZES_CAB_INPUT[0].ACEPTA_DUPLI = 'X';
    }

    // Validar si es pedido a revisión
    if (data.review) {
      const isValid = enviarARevisarPedido(pedido, data.od, data.oi, data.lente);
      if (Array.isArray(isValid) && isValid.length > 0) {
        throw new Error(JSON.stringify(isValid));
      }
    }

    // Validar condiciones iniciales
    const isValid = validarCondicionesIniciales(profile, pedido, areaCliente, data.review, data.descentramientos);
    if (Array.isArray(isValid) && isValid.length > 0) {
      throw new Error(JSON.stringify(isValid));
    }

    try {
      // Guardar el pedido en el store
      await dispatch('GuardarPedido', { pedido }, { root: true });

      // Enviar al WebService
      const newOrder = await dispatch('RealizarPedido', { pedido }, { root: true });

      return newOrder;
    } catch (error) {
      // Si el error ya es un array de errores (de la validación), lo lanzamos tal cual
      if (error.message.startsWith('[')) {
        throw error;
      }
      // Si es un error de la API, obtenemos los errores del pedido
      const errores = await dispatch('GetErroresPedido', null, { root: true }) || [];
      if (errores.length > 0) {
        throw new Error(JSON.stringify(errores));
      }
      // Si no hay errores específicos, lanzamos el error original
      throw error;
    }
  },

  /**
   * Maneja los cambios en la forma de la lente
   * @param {Object} context - Contexto de Vuex
   * @param {Object} params - Parámetros del cambio
   * @returns {Promise<void|Error>}
   */
  cambiadoForma({ dispatch, rootState }, { forma = '0', montaje = null }) {

  }
};

const mutations = {
  setOd(state, data) {
    state.od = data;
  },
  setOi(state, data) {
    state.oi = data;
  },
  setDescentramiento(state, data) {
    state.descentramiento = data;
  },
  setColoracion(state, data) {
    state.coloracion = data;
  },
  setFocomaterial(state, data) {
    state.focomaterial = data;
  },
  setColor(state, data) {
    state.color = data;
  },
  setLente(state, data) {
    state.lente = data;
  },
  setAdicionales(state, data) {
    state.adicionales = data;
  },
  setReferencia(state, data) {
    state.referencia = data;
  },
  setTratamientoPedido(state, data) {
    state.tratamiento = data;
  },
};

export default {
  namespaced: true,
  state: estado,
  getters,
  actions,
  mutations,
};
