import axios from 'axios';
import axiosConfig from '@/config/axios';
import config from '@/config/indo';

export default {
  getLecturas(cliente, idioma) {
    const payload = config.mimetikaLecturas.xml
      .replace('{cliente}', cliente)
      .replace('{password}', 'INDONETVR')
      .replace('{idioma}', idioma);

    return axios.post(
      axiosConfig.indoboxURL,
      payload,
      {
        headers: {
          ...axiosConfig.headers,
          'SOAPAction': 'http://WSINDOBOX.es/DescargaLecturasDatosVR',
          // Authorization: `Bearer ${token}`,
        },
      },
    );
  },
  getIndocenter(cliente, idioma) {
    const payload = config.indocenterLecturas.xml
      .replace('{cliente}', cliente)
      .replace('{password}', 'INDONET')
      .replace('{idioma}', idioma);

    return axios.post(
      axiosConfig.indoboxURL,
      payload,
      {
        headers: {
          ...axiosConfig.headers,
          'SOAPAction': 'http://WSINDOBOX.es/DescargaLecturasDatosINDOCenter',
          // Authorization: `Bearer ${token}`,
        },
      },
    );
  },

};
