<?xml version="1.0" encoding="utf-8"?>
<root>
  <!-- 
    Microsoft ResX Schema 
    
    Version 2.0
    
    The primary goals of this format is to allow a simple XML format 
    that is mostly human readable. The generation and parsing of the 
    various data types are done through the TypeConverter classes 
    associated with the data types.
    
    Example:
    
    ... ado.net/XML headers & schema ...
    <resheader name="resmimetype">text/microsoft-resx</resheader>
    <resheader name="version">2.0</resheader>
    <resheader name="reader">System.Resources.ResXResourceReader, System.Windows.Forms, ...</resheader>
    <resheader name="writer">System.Resources.ResXResourceWriter, System.Windows.Forms, ...</resheader>
    <data name="Name1"><value>this is my long string</value><comment>this is a comment</comment></data>
    <data name="Color1" type="System.Drawing.Color, System.Drawing">Blue</data>
    <data name="Bitmap1" mimetype="application/x-microsoft.net.object.binary.base64">
        <value>[base64 mime encoded serialized .NET Framework object]</value>
    </data>
    <data name="Icon1" type="System.Drawing.Icon, System.Drawing" mimetype="application/x-microsoft.net.object.bytearray.base64">
        <value>[base64 mime encoded string representing a byte array form of the .NET Framework object]</value>
        <comment>This is a comment</comment>
    </data>
                
    There are any number of "resheader" rows that contain simple 
    name/value pairs.
    
    Each data row contains a name, and value. The row also contains a 
    type or mimetype. Type corresponds to a .NET class that support 
    text/value conversion through the TypeConverter architecture. 
    Classes that don't support this are serialized and stored with the 
    mimetype set.
    
    The mimetype is used for serialized objects, and tells the 
    ResXResourceReader how to depersist the object. This is currently not 
    extensible. For a given mimetype the value must be set accordingly:
    
    Note - application/x-microsoft.net.object.binary.base64 is the format 
    that the ResXResourceWriter will generate, however the reader can 
    read any of the formats listed below.
    
    mimetype: application/x-microsoft.net.object.binary.base64
    value   : The object must be serialized with 
            : System.Runtime.Serialization.Formatters.Binary.BinaryFormatter
            : and then encoded with base64 encoding.
    
    mimetype: application/x-microsoft.net.object.soap.base64
    value   : The object must be serialized with 
            : System.Runtime.Serialization.Formatters.Soap.SoapFormatter
            : and then encoded with base64 encoding.

    mimetype: application/x-microsoft.net.object.bytearray.base64
    value   : The object must be serialized into a byte array 
            : using a System.ComponentModel.TypeConverter
            : and then encoded with base64 encoding.
    -->
  <xsd:schema id="root" xmlns="" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:msdata="urn:schemas-microsoft-com:xml-msdata">
    <xsd:import namespace="http://www.w3.org/XML/1998/namespace" />
    <xsd:element name="root" msdata:IsDataSet="true">
      <xsd:complexType>
        <xsd:choice maxOccurs="unbounded">
          <xsd:element name="metadata">
            <xsd:complexType>
              <xsd:sequence>
                <xsd:element name="value" type="xsd:string" minOccurs="0" />
              </xsd:sequence>
              <xsd:attribute name="name" use="required" type="xsd:string" />
              <xsd:attribute name="type" type="xsd:string" />
              <xsd:attribute name="mimetype" type="xsd:string" />
              <xsd:attribute ref="xml:space" />
            </xsd:complexType>
          </xsd:element>
          <xsd:element name="assembly">
            <xsd:complexType>
              <xsd:attribute name="alias" type="xsd:string" />
              <xsd:attribute name="name" type="xsd:string" />
            </xsd:complexType>
          </xsd:element>
          <xsd:element name="data">
            <xsd:complexType>
              <xsd:sequence>
                <xsd:element name="value" type="xsd:string" minOccurs="0" msdata:Ordinal="1" />
                <xsd:element name="comment" type="xsd:string" minOccurs="0" msdata:Ordinal="2" />
              </xsd:sequence>
              <xsd:attribute name="name" type="xsd:string" use="required" msdata:Ordinal="1" />
              <xsd:attribute name="type" type="xsd:string" msdata:Ordinal="3" />
              <xsd:attribute name="mimetype" type="xsd:string" msdata:Ordinal="4" />
              <xsd:attribute ref="xml:space" />
            </xsd:complexType>
          </xsd:element>
          <xsd:element name="resheader">
            <xsd:complexType>
              <xsd:sequence>
                <xsd:element name="value" type="xsd:string" minOccurs="0" msdata:Ordinal="1" />
              </xsd:sequence>
              <xsd:attribute name="name" type="xsd:string" use="required" />
            </xsd:complexType>
          </xsd:element>
        </xsd:choice>
      </xsd:complexType>
    </xsd:element>
  </xsd:schema>
  <resheader name="resmimetype">
    <value>text/microsoft-resx</value>
  </resheader>
  <resheader name="version">
    <value>2.0</value>
  </resheader>
  <resheader name="reader">
    <value>System.Resources.ResXResourceReader, System.Windows.Forms, Version=2.0.0.0, Culture=neutral, PublicKeyToken=b77a5c561934e089</value>
  </resheader>
  <resheader name="writer">
    <value>System.Resources.ResXResourceWriter, System.Windows.Forms, Version=2.0.0.0, Culture=neutral, PublicKeyToken=b77a5c561934e089</value>
  </resheader>
  <data name="a" xml:space="preserve">
    <value>a</value>
  </data>
  <data name="abertura" xml:space="preserve">
    <value>Öffnung</value>
  </data>
  <data name="acceso_indonet" xml:space="preserve">
    <value>Zugriff</value>
  </data>
  <data name="aceptar" xml:space="preserve">
    <value>OK</value>
  </data>
  <data name="Aceptar_Cambio_Y_Enviar" xml:space="preserve">
    <value>Ändern und Absenden OK</value>
  </data>
  <data name="acetato" xml:space="preserve">
    <value>Azetat</value>
  </data>
  <data name="activar" xml:space="preserve">
    <value>Aktiviert</value>
  </data>
  <data name="actualizando_nueva_oferta" xml:space="preserve">
    <value>Neues Angebot wird aktualisiert...</value>
  </data>
  <data name="actualizar" xml:space="preserve">
    <value>Aktualisieren</value>
  </data>
  <data name="adicion" xml:space="preserve">
    <value>Zusatz</value>
  </data>
  <data name="adicion_abrv" xml:space="preserve">
    <value>ZUS</value>
  </data>
  <data name="advertencia" xml:space="preserve">
    <value>Warnung</value>
  </data>
  <data name="agente_trazador" xml:space="preserve">
    <value>Planzeichner</value>
  </data>
  <data name="agente_trazador_desconectado" xml:space="preserve">
    <value>INDOBISEL funktioniert scheinbar nicht. Bitte überprüfen Sie den Status</value>
  </data>
  <data name="altura" xml:space="preserve">
    <value>Höhe</value>
  </data>
  <data name="altura_boxing" xml:space="preserve">
    <value>Kastenhöhe</value>
  </data>
  <data name="altura_de_montaje" xml:space="preserve">
    <value>Montagehöhe</value>
  </data>
  <data name="altura_montaje" xml:space="preserve">
    <value>Montagehöhe</value>
  </data>
  <data name="altura_montaje_total" xml:space="preserve">
    <value>Endgültige Montagehöhe</value>
  </data>
  <data name="altura_pasillo" xml:space="preserve">
    <value>Ganghöhe</value>
  </data>
  <data name="alt_anilla" xml:space="preserve">
    <value>HÖHE RING</value>
  </data>
  <data name="anadir" xml:space="preserve">
    <value>Hinzufügen</value>
  </data>
  <data name="anadir_encargo" xml:space="preserve">
    <value>Auftrag hinzufügen</value>
  </data>
  <data name="anadir_encargo_pregunta_enviar_pedido" xml:space="preserve">
    <value>Bestellung jetzt absenden</value>
  </data>
  <data name="anadir_encargo_pregunta_enviar_revision" xml:space="preserve">
    <value>Bestellung zur Überprüfung senden.</value>
  </data>
  <data name="anadir_encargo_pregunta_graduacion_cerca" xml:space="preserve">
    <value>Nahsichteinstellung</value>
  </data>
  <data name="anadir_encargo_pregunta_graduacion_diferente" xml:space="preserve">
    <value>Sehstärke unterscheidet sich von der ersten Brille</value>
  </data>
  <data name="anadir_encargo_pregunta_graduacion_lejos" xml:space="preserve">
    <value>Weitsichteinstellung</value>
  </data>
  <data name="anadir_encargo_pregunta_guardar_receta" xml:space="preserve">
    <value>Speichern und Bestellung später aus Gespeicherte Rezepte durchführen</value>
  </data>
  <data name="anadir_encargo_pregunta_misma_graduacion" xml:space="preserve">
    <value>Gleiche Sehstärke wie die erste Brille</value>
  </data>
  <data name="AnalizandoImagen" xml:space="preserve">
    <value>Bild wird zur Formerlangung analysiert</value>
  </data>
  <data name="angulo" xml:space="preserve">
    <value>Winkel</value>
  </data>
  <data name="angulo_de_envolvencia" xml:space="preserve">
    <value>Umhüllungswinkel</value>
  </data>
  <data name="anillas" xml:space="preserve">
    <value>Ringe</value>
  </data>
  <data name="asunto" xml:space="preserve">
    <value>Betreff</value>
  </data>
  <data name="Atencion" xml:space="preserve">
    <value>Achtung</value>
  </data>
  <data name="atras" xml:space="preserve">
    <value>Zurück</value>
  </data>
  <data name="automatico13_23" xml:space="preserve">
    <value>Automatisch (1/3-2/3)</value>
  </data>
  <data name="avisos" xml:space="preserve">
    <value>Meldungen</value>
  </data>
  <data name="aviso_actualizacion" xml:space="preserve">
    <value>Das Programm wird aktualisiert auf Version:</value>
  </data>
  <data name="aviso_alternativa_producto" xml:space="preserve">
    <value>FÜR DIE SEHSTÄRKE UND DAS BEANTRAGTE PRODUKT IST KEIN LAGERBESTAND VORHANDEN. UM IHNEN EINEN GUTEN SERVICE ZU GEWÄHRLEISTEN, LIEFERN WIR IHNEN DAS NACHSTEHENDE PRODUKT ZUM GLEICHEN PREIS. WENN ES SICH UM DEN ERSATZ FÜR NUR EIN AUGE HANDELT, EMPFEHLEN WIR IHNEN, DEN KUNDENSERVICE ZU KONTAKTIEREN:</value>
  </data>
  <data name="aviso_anadir_favorito" xml:space="preserve">
    <value>Zum Hinzufügen zu Favoriten hier klicken</value>
  </data>
  <data name="aviso_apagado" xml:space="preserve">
    <value>Programm aktualisiert. Das Programm wird geschlossen. Sie können INDONET erneut über das Symbol auf dem Schreibtisch öffnen.</value>
  </data>
  <data name="aviso_atencion_urgencias" xml:space="preserve">
    <value>Achtung: Bei Notfällen, Reklamationen und Stornierungen rufen Sie bitte die Telefonnummer 93 748 68 00 an.</value>
  </data>
  <data name="aviso_biselar_por_defecto" xml:space="preserve">
    <value>Standardschleifen</value>
  </data>
  <data name="aviso_borrar_pedido" xml:space="preserve">
    <value>Bestellung löschen</value>
  </data>
  <data name="aviso_cancelar_pedido" xml:space="preserve">
    <value>Wollen Sie die Bestellung wirklich stornieren? Alle Daten gehen dabei verloren.</value>
  </data>
  <data name="aviso_caracter_no_valido" xml:space="preserve">
    <value>Ungültiges Zeichen. Bitte verwenden Sie das westliche Alphabet.</value>
  </data>
  <data name="aviso_cargando_oferta" xml:space="preserve">
    <value>Angebotsdaten werden geladen</value>
  </data>
  <data name="aviso_color_nd_diferentes" xml:space="preserve">
    <value>Die Färbung von Gläsern mit unterschiedlichem Index ist nicht gestattet.</value>
  </data>
  <data name="aviso_color_reposicion" xml:space="preserve">
    <value>Ihre Bestellung wurde gespeichert. Um sicherzustellen, dass die Farbe dieses Ersatzglases mit dem anderen übereinstimmt, werden wir uns in Kürze mit Ihnen in Verbindung setzen</value>
  </data>
  <data name="aviso_configuracion_favoritos" xml:space="preserve">
    <value>Konfigurieren Sie Ihre Favoriten und nimmt Bestellungen schnell und effektiv</value>
  </data>
  <data name="aviso_desactivar_solo_stock" xml:space="preserve">
    <value>Falls Sie diese Option wünschen, deaktivieren Sie die Option Nur Lagerbestand</value>
  </data>
  <data name="aviso_descarga_aplicacion" xml:space="preserve">
    <value>Neue Version wird heruntergeladen</value>
  </data>
  <data name="aviso_encargo_anulado" xml:space="preserve">
    <value>Ihr Auftrag wurde korrekt storniert</value>
  </data>
  <data name="aviso_encargo_no_anulable" xml:space="preserve">
    <value>Der Auftrag kann nicht mehr storniert werden</value>
  </data>
  <data name="aviso_error_buscando_datos" xml:space="preserve">
    <value>Fehler bei der Datensuche</value>
  </data>
  <data name="aviso_error_enviando_mensaje_SRI" xml:space="preserve">
    <value>Fehler beim Senden der Nachricht an SRI</value>
  </data>
  <data name="aviso_escoger_alto_indice" xml:space="preserve">
    <value>Für Gläser mit Bohrloch ist es empfehlenswert, Gläser mit einem Index über 1.6 zu verwenden, um die Montagesicherheit zu gewährleisten.</value>
  </data>
  <data name="aviso_fallo_co_proxy" xml:space="preserve">
    <value>Es gibt eine aktuellere Version als Ihre, die nicht installiert werden konnte. Bitte wenden Sie sich an INDO, um die Ursache herauszufinden</value>
  </data>
  <data name="aviso_faltan_datos_montaje" xml:space="preserve">
    <value>Montagedaten falsch. Bitte prüfen Sie die Angaben</value>
  </data>
  <data name="aviso_faltan_datos_para_montaje" xml:space="preserve">
    <value>Sie müssen alle Montagedaten eingeben.</value>
  </data>
  <data name="aviso_falta_diametro" xml:space="preserve">
    <value>Sie müssen einen Durchmesser auswählen.</value>
  </data>
  <data name="aviso_falta_montura" xml:space="preserve">
    <value>Es gibt keinen Lagerbestand der gewünschten Fassung. Löschen Sie die Fassung im Reiter Formen und Stärken aus der Bestellung oder tauschen Sie diese gegen eine andere Fassung mit Lagerbestand aus.</value>
  </data>
  <data name="aviso_falta_referencia" xml:space="preserve">
    <value>Nachricht_Auftragsnummer_fehlt</value>
  </data>
  <data name="aviso_falta_scaner" xml:space="preserve">
    <value>Der Scanner konnte nicht initialisiert werden</value>
  </data>
  <data name="aviso_falta_tipo_montura" xml:space="preserve">
    <value>Sie müssen den Fassungstyp angeben. Betätigen Sie die Schaltfläche Weiter innerhalb der Stärkenauswahl.</value>
  </data>
  <data name="aviso_falta_valor_en_motivo" xml:space="preserve">
    <value>Es wurde kein Wert für den Grund {0} angegeben</value>
  </data>
  <data name="aviso_instalando_version" xml:space="preserve">
    <value>Neue Version wird installiert</value>
  </data>
  <data name="aviso_introducir_montura_1" xml:space="preserve">
    <value>Achtung: Um die Bestellung korrekt durchzuführen,</value>
  </data>
  <data name="aviso_introducir_montura_2" xml:space="preserve">
    <value>müssen Sie die Form der Fassung eingeben.</value>
  </data>
  <data name="aviso_lecturas_no_cargadas" xml:space="preserve">
    <value>Es wurden keine Lesungen für diese Form importiert</value>
  </data>
  <data name="aviso_lectura_cargada" xml:space="preserve">
    <value>Form {0} erfolgreich geladen</value>
  </data>
  <data name="aviso_lectura_importada" xml:space="preserve">
    <value>Form {0} erfolgreich importiert</value>
  </data>
  <data name="aviso_mas_30_dias" xml:space="preserve">
    <value>Das Datumsintervall muss geringer als 60 Tage sein</value>
  </data>
  <data name="aviso_no_hay_incidencias" xml:space="preserve">
    <value>Keine Zwischenfälle in diesem Formular gefunden</value>
  </data>
  <data name="aviso_no_hay_tema" xml:space="preserve">
    <value>Das Thema des Zwischenfalls in diesem Formular wurde nicht definiert</value>
  </data>
  <data name="aviso_no_posible_anadir_favorito" xml:space="preserve">
    <value>Dieses Glas kann nicht zu den Favoriten hinzugefügt werden</value>
  </data>
  <data name="aviso_no_posible_quitar_favorito" xml:space="preserve">
    <value>Kann nicht aus den Favoriten gelöscht werden</value>
  </data>
  <data name="aviso_precalibrado_forma_cuadrada" xml:space="preserve">
    <value>Bei Vorkalibrierungen mit Basisform wird empfohlen, die Quadratische Form auszuwählen. Diese Form garantiert nicht die optimale Stärke. Um beide Parameter zu gewährleisten, nutzen Sie die echte Form der Brille.</value>
  </data>
  <data name="aviso_quitar_favorito" xml:space="preserve">
    <value>Zum Löschen aus den Favoriten bitte klicken.</value>
  </data>
  <data name="aviso_reiniciar_oferta" xml:space="preserve">
    <value>Wollen Sie das Angebot wirklich neu laden?</value>
  </data>
  <data name="aviso_solicitar_solo_monturas" xml:space="preserve">
    <value>Um nur Fassungen für Ihr Lager anzufordern, bestellen Sie bitte über recetas.lentes@indo.es</value>
  </data>
  <data name="aviso_tema_no_encontrado" xml:space="preserve">
    <value>Das angefragte Thema kann nicht gefunden werden</value>
  </data>
  <data name="base" xml:space="preserve">
    <value>Basis</value>
  </data>
  <data name="base_automatica" xml:space="preserve">
    <value>Automatische Basis</value>
  </data>
  <data name="base_de_datos_de_marcas" xml:space="preserve">
    <value>Markendatenbank</value>
  </data>
  <data name="base_de_datos_de_mis_biselados" xml:space="preserve">
    <value>Meine Schliffe</value>
  </data>
  <data name="base_especial" xml:space="preserve">
    <value>Spezialbasis</value>
  </data>
  <data name="base_interior" xml:space="preserve">
    <value>Innenbasis</value>
  </data>
  <data name="base_nominal" xml:space="preserve">
    <value>Nominalbasis</value>
  </data>
  <data name="base_optima" xml:space="preserve">
    <value>Optimale Basis</value>
  </data>
  <data name="basicas" xml:space="preserve">
    <value>Grundlegende</value>
  </data>
  <data name="bisel" xml:space="preserve">
    <value>Normaler Schliff</value>
  </data>
  <data name="biselador_remoto" xml:space="preserve">
    <value>Ferngesteuertes Schleifgerät</value>
  </data>
  <data name="biselador_remoto_guardar" xml:space="preserve">
    <value>Speichern</value>
  </data>
  <data name="biselador_remoto_importar" xml:space="preserve">
    <value>Importieren</value>
  </data>
  <data name="biselador_remoto_iniciar" xml:space="preserve">
    <value>Starten</value>
  </data>
  <data name="biselador_remoto_popup_titulo" xml:space="preserve">
    <value>Auswahl Lesungen Planzeichner</value>
  </data>
  <data name="biselador_remoto_ultima" xml:space="preserve">
    <value>Letzte</value>
  </data>
  <data name="biselado_remoto" xml:space="preserve">
    <value>Ferngesteuerter Schliff</value>
  </data>
  <data name="biselar_lente" xml:space="preserve">
    <value>Glas schleifen</value>
  </data>
  <data name="biselar_sin_pulir" xml:space="preserve">
    <value>Schleifen ohne Polieren</value>
  </data>
  <data name="BlankRange" xml:space="preserve">
    <value>Datenbankbereich</value>
  </data>
  <data name="bonos" xml:space="preserve">
    <value>BONUS</value>
  </data>
  <data name="bonus_clicks" xml:space="preserve">
    <value>Bonus Klicks</value>
  </data>
  <data name="bonus_clicks_local" xml:space="preserve">
    <value>Lokaler Bonus Klicks</value>
  </data>
  <data name="bonus_local" xml:space="preserve">
    <value>Lokaler Bonus  </value>
  </data>
  <data name="bonus_restantes" xml:space="preserve">
    <value>Restlicher Bonus</value>
  </data>
  <data name="borde_especial_abr" xml:space="preserve">
    <value>Spezialschliff</value>
  </data>
  <data name="borrar" xml:space="preserve">
    <value>Löschen</value>
  </data>
  <data name="borrar_mi_biselado" xml:space="preserve">
    <value>Aus Meine Schliffe löschen</value>
  </data>
  <data name="borrar_recetas" xml:space="preserve">
    <value>Löschen</value>
  </data>
  <data name="buscar" xml:space="preserve">
    <value>Suchen</value>
  </data>
  <data name="c1_desde" xml:space="preserve">
    <value>C1 AB</value>
  </data>
  <data name="c1_hasta" xml:space="preserve">
    <value>C1 BIS</value>
  </data>
  <data name="calibracion_correctamente" xml:space="preserve">
    <value>Die Kalibrierung wurde korrekt durchgeführt</value>
  </data>
  <data name="calibracion_monitor" xml:space="preserve">
    <value>Kalibrierung Monitor</value>
  </data>
  <data name="calibre" xml:space="preserve">
    <value>Kaliber</value>
  </data>
  <data name="calibre_completo" xml:space="preserve">
    <value>Vollständiges Kaliber</value>
  </data>
  <data name="calibre_marcas" xml:space="preserve">
    <value>Kaliber</value>
  </data>
  <data name="cancelar" xml:space="preserve">
    <value>Abbrechen</value>
  </data>
  <data name="cancelar_comparacion" xml:space="preserve">
    <value>Vergleich abbrechen</value>
  </data>
  <data name="cancelar_pedido" xml:space="preserve">
    <value>Bestellung stornieren</value>
  </data>
  <data name="cantidad_mayor_que_cero" xml:space="preserve">
    <value>Vorrätig</value>
  </data>
  <data name="caracter_hiperlink" xml:space="preserve">
    <value>»</value>
  </data>
  <data name="cargar_imagenes" xml:space="preserve">
    <value />
  </data>
  <data name="centrado12_12" xml:space="preserve">
    <value>Zentriert (1/2-1/2)</value>
  </data>
  <data name="chile" xml:space="preserve">
    <value>Chile</value>
  </data>
  <data name="cilindro" xml:space="preserve">
    <value>Zylinder</value>
  </data>
  <data name="cilindro_abrv" xml:space="preserve">
    <value>ZYL</value>
  </data>
  <data name="clave_acceso" xml:space="preserve">
    <value>Zugangscode</value>
  </data>
  <data name="cliente" xml:space="preserve">
    <value>Kunde</value>
  </data>
  <data name="cliente_no_autenticado" xml:space="preserve">
    <value>Kunde nicht authentifiziert. Indonet neu starten</value>
  </data>
  <data name="cliente_no_dispone_permisos_para_ejecutar_operacion_indicada" xml:space="preserve">
    <value>Ihr Kunde ist nicht zum Ausführen des angefragten Vorgangs befugt</value>
  </data>
  <data name="cliente_no_identificado" xml:space="preserve">
    <value>Kunde nicht authentifiziert</value>
  </data>
  <data name="cliente_no_tiene_configuracion_creada" xml:space="preserve">
    <value>Für den Kunden ist keine Einstellung angelegt</value>
  </data>
  <data name="cliente_no_tiene_historico_de_crédito" xml:space="preserve">
    <value>Der Kunde hat keinen Guthabenverlauf</value>
  </data>
  <data name="cliente_no_tiene_productos_asignados" xml:space="preserve">
    <value>Dem Kunden sind keine Produkte zugewiesen</value>
  </data>
  <data name="cliente_sin_codigo_sucursal_configurado" xml:space="preserve">
    <value>Für Ihren Kunden ist kein Filialcode (_BRANCH) eingestellt</value>
  </data>
  <data name="cliente_sin_credito" xml:space="preserve">
    <value>Der Kunde wurde nicht richtig angelegt. Guthaben existiert nicht</value>
  </data>
  <data name="codigo_cliente" xml:space="preserve">
    <value>Kundencode</value>
  </data>
  <data name="codigo_de_barras" xml:space="preserve">
    <value>Barcode</value>
  </data>
  <data name="codigo_de_retorno" xml:space="preserve">
    <value>Retourencode: </value>
  </data>
  <data name="codigo_paciente" xml:space="preserve">
    <value>Kundencode</value>
  </data>
  <data name="codigo_producto_incorrecto" xml:space="preserve">
    <value>Produktcode falsch. Er muss numerisch sein</value>
  </data>
  <data name="color" xml:space="preserve">
    <value>Farbe</value>
  </data>
  <data name="coloracion" xml:space="preserve">
    <value>Färbung</value>
  </data>
  <data name="comentarios" xml:space="preserve">
    <value>Anmerkungen</value>
  </data>
  <data name="comparar" xml:space="preserve">
    <value>Vergleichen</value>
  </data>
  <data name="comparar_lentes" xml:space="preserve">
    <value>Gläser vergleichen</value>
  </data>
  <data name="comparar_otra_lente" xml:space="preserve">
    <value>Anderes Glas vergleichen</value>
  </data>
  <data name="comprobando_si_BBDD" xml:space="preserve">
    <value>Existenz der Datenbank wird überprüft...</value>
  </data>
  <data name="comprobando_si_nueva_oferta" xml:space="preserve">
    <value>Es wird überprüft, ob ein neues Angebot vorliegt...</value>
  </data>
  <data name="comprobar_disponibilidad" xml:space="preserve">
    <value>Verfügbarkeit überprüfen</value>
  </data>
  <data name="condiciones" xml:space="preserve">
    <value>Bedingungen</value>
  </data>
  <data name="condiciones_1" xml:space="preserve">
    <value>INDO OPTICAL, S.L.U. mit Steuernummer B-66232976, mit Sitz in Avda. Alcalde Barnils, 72 - 08174 Sant Cugat del Vallés - Barcelona und eingetragen im Handelsregister von Barcelona in Band 44215, Blatt 212, Seite B-449860, als Verantwortliche für die Datenverarbeitung, Empfängerin der Informationen und Inhaberin der Datei namens „Kunden“ informiert den Abonnenten, dass die mitgeteilten Daten in die Datei mit der Bezeichnung „Kunden“ aufgenommen werden. Zweck der Dateneinholung ist der kommerzielle Austausch zwischen INDO und Ihrem Optikergeschäft über das Internet und somit die Erbringung des INDONET-Service.</value>
  </data>
  <data name="condiciones_2" xml:space="preserve">
    <value>Die Akzeptanz der Nutzungsbedingungen ist obligatorisch und fehlende Antworten auf die angeforderten Daten verhindern den Service über die Modalität INDONET.</value>
  </data>
  <data name="condiciones_3" xml:space="preserve">
    <value>Der Abonnent wird darauf hingewiesen, dass er alle gesetzlich gewährten Rechte, insbesondere das Recht auf Zugriff, Berichtigung, Löschung und Einspruch, bei dem Verantwortlichen für die Datenverarbeitung wahrnehmen kann. Dazu muss er seinen Wunsch über das Kontaktformular von IndoBox (www.indobox-indo.com) kundtun.</value>
  </data>
  <data name="condiciones_4" xml:space="preserve">
    <value>Der Abonnent genehmigt als Inhaber der mitgeteilten Daten ausdrücklich deren Verarbeitung in der beschriebenen Form.</value>
  </data>
  <data name="condicion_previa" xml:space="preserve">
    <value>Voraussetzung</value>
  </data>
  <data name="conexion_ssl" xml:space="preserve">
    <value>SSL-VERBINDUNG</value>
  </data>
  <data name="conexion_ssl_segura" xml:space="preserve">
    <value>SICHERE SSL-VERBINDUNG</value>
  </data>
  <data name="configuracion" xml:space="preserve">
    <value>Konfigurierung</value>
  </data>
  <data name="configuracion_activado" xml:space="preserve">
    <value>Aktiviert</value>
  </data>
  <data name="configuracion_altura_montaje" xml:space="preserve">
    <value>Montagehöhe</value>
  </data>
  <data name="configuracion_aro" xml:space="preserve">
    <value>Ring</value>
  </data>
  <data name="configuracion_boxing" xml:space="preserve">
    <value>Kastensystem</value>
  </data>
  <data name="configuracion_catalogo_lentes" xml:space="preserve">
    <value>Gläserkatalog Indo</value>
  </data>
  <data name="configuracion_clave_actual" xml:space="preserve">
    <value>Aktueller Code</value>
  </data>
  <data name="configuracion_confirmar_clave" xml:space="preserve">
    <value>Code bestätigen</value>
  </data>
  <data name="configuracion_desactivado" xml:space="preserve">
    <value>Deaktiviert</value>
  </data>
  <data name="configuracion_descargas_automaticas" xml:space="preserve">
    <value>Automatische Downloads</value>
  </data>
  <data name="configuracion_dias" xml:space="preserve">
    <value>Tage</value>
  </data>
  <data name="configuracion_enlaces_relacionados" xml:space="preserve">
    <value>Ähnliche Links</value>
  </data>
  <data name="configuracion_espesores" xml:space="preserve">
    <value>Stärkenkonfigurierung</value>
  </data>
  <data name="configuracion_espesor_borde" xml:space="preserve">
    <value>Randstärke</value>
  </data>
  <data name="configuracion_fecha_oferta" xml:space="preserve">
    <value>Angebotsdatum</value>
  </data>
  <data name="configuracion_guardada_correctamente" xml:space="preserve">
    <value>Konfigurierung korrekt gespeichert</value>
  </data>
  <data name="configuracion_hojaPedido" xml:space="preserve">
    <value>Blatt bestellung</value>
  </data>
  <data name="configuracion_jerga" xml:space="preserve">
    <value>Fachjargon</value>
  </data>
  <data name="configuracion_lentesMaxima" xml:space="preserve">
    <value>www.lentesmaxima.com</value>
  </data>
  <data name="configuracion_link_hojaRombos" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/Indonet/Enlaces/HojaPedidos_ES.pdf</value>
  </data>
  <data name="configuracion_link_lentesMaxima" xml:space="preserve">
    <value>http://www.lentesmaxima.com</value>
  </data>
  <data name="configuracion_link_manualFavoritas" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/Indonet/Enlaces/ManualIndonet_ES.pdf</value>
  </data>
  <data name="configuracion_link_manualIndoNet" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/Indonet/Enlaces/ManualIndoScanIntegrado_ES.pdf</value>
  </data>
  <data name="configuracion_link_manualIndoScanIntegrado" xml:space="preserve">
    <value />
  </data>
  <data name="configuracion_listado_pedidos" xml:space="preserve">
    <value>Liste „Status der Bestellungen“</value>
  </data>
  <data name="configuracion_manualFavoritas" xml:space="preserve">
    <value>Handbuch Favoriten</value>
  </data>
  <data name="configuracion_manualIndonet" xml:space="preserve">
    <value>Handbuch Indonet</value>
  </data>
  <data name="configuracion_manualIndoScanIntegrado" xml:space="preserve">
    <value>Integriertes Handbuch IndoScan</value>
  </data>
  <data name="configuracion_modificar_clave" xml:space="preserve">
    <value>Code ändern</value>
  </data>
  <data name="configuracion_mostrar_pedidos" xml:space="preserve">
    <value>Getätigte Bestellungen anzeigen der letzten</value>
  </data>
  <data name="configuracion_mostrar_precios" xml:space="preserve">
    <value>Preise anzeigen</value>
  </data>
  <data name="configuracion_nombre_comercial" xml:space="preserve">
    <value>Handelsname</value>
  </data>
  <data name="configuracion_nombre_lentes" xml:space="preserve">
    <value>Name der Gläser</value>
  </data>
  <data name="configuracion_nombre_lentes_help" xml:space="preserve">
    <value>Der neue Import wird bei der nächsten Authentifizierung angezeigt</value>
  </data>
  <data name="configuracion_nueva_clave" xml:space="preserve">
    <value>Neuer Code</value>
  </data>
  <data name="configuracion_precalibrado_cotas" xml:space="preserve">
    <value>Vorkalibrierung nach Maßzahlen</value>
  </data>
  <data name="configuracion_precios_no" xml:space="preserve">
    <value>Nein</value>
  </data>
  <data name="configuracion_precios_pvo" xml:space="preserve">
    <value>ZIELVERKAUFSPREIS</value>
  </data>
  <data name="configuracion_precios_pvp" xml:space="preserve">
    <value>EMPFOHLENER VERKAUFSPREIS</value>
  </data>
  <data name="configuracion_precios_si" xml:space="preserve">
    <value>Ja</value>
  </data>
  <data name="configuracion_pred_indo" xml:space="preserve">
    <value>Vorgabe Indo</value>
  </data>
  <data name="configuracion_propio" xml:space="preserve">
    <value>Eigene</value>
  </data>
  <data name="configuracion_punto_taladro" xml:space="preserve">
    <value>Bohrpunkt</value>
  </data>
  <data name="configuracion_ranura_metal" xml:space="preserve">
    <value>Rille Metall</value>
  </data>
  <data name="configuracion_ranura_nylon" xml:space="preserve">
    <value>Rille Nylon</value>
  </data>
  <data name="configuracion_restablecer_valores_predeterminados" xml:space="preserve">
    <value>Voreingestellte Werte wiederherstellen</value>
  </data>
  <data name="configuracion_taller" xml:space="preserve">
    <value>Konfigurierung Werkstatt</value>
  </data>
  <data name="configuracion_tooltip_espesores" xml:space="preserve">
    <value>Die Stärke liegt unter der empfohlenen Stärke. Die Gläser werden nicht geschliffen</value>
  </data>
  <data name="configuracion_tooltip_numstepper" xml:space="preserve">
    <value>Die Liste „Status der Bestellungen“ befindet sich auf dem Startbildschirm. Das zulässige Intervall beträgt zwischen 1 und 7 Tage.</value>
  </data>
  <data name="configuracion_URL_catalogo" xml:space="preserve">
    <value>http://indoweb3/indonet2010/descargas/Indonet/Indo PVP 2011.pdf</value>
  </data>
  <data name="confirmar_borrar_forma" xml:space="preserve">
    <value>Bestätigen Sie die Löschung dieser Form?</value>
  </data>
  <data name="confirmar_borrar_receta" xml:space="preserve">
    <value>Bestätigen Sie die Löschung des gespeicherten und auswählten Rezepts?</value>
  </data>
  <data name="confirmar_borrar_recetas" xml:space="preserve">
    <value>Bestätigen Sie die Löschung der {0}  gespeicherten  und auswählten Rezepte?</value>
  </data>
  <data name="confirmar_envio_pedidos_1" xml:space="preserve">
    <value>Bestätigen Sie die Sendung der folgenden Anzahl von Bestellungen? #</value>
  </data>
  <data name="confirmar_envio_pedidos_2" xml:space="preserve">
    <value>Bestellungen mit Fehlern verbleiben im Status „Überprüfung durch INDO“</value>
  </data>
  <data name="confirmar_envio_receta" xml:space="preserve">
    <value>Bestätigen Sie die Sendung des gespeicherten und auswählten Rezepts?</value>
  </data>
  <data name="confirmar_envio_recetas" xml:space="preserve">
    <value>Bestätigen Sie die Sendung der {0} gespeicherten und auswählten Rezepte?</value>
  </data>
  <data name="confirmar_envio_recetas_2" xml:space="preserve">
    <value>Rezepte mit Fehlern verbleiben im Status „Überprüfung durch INDO“</value>
  </data>
  <data name="confirma_biselado_espesor" xml:space="preserve">
    <value>Bestätigung von Schliff und Stärke</value>
  </data>
  <data name="confirma_destino_mercancia" xml:space="preserve">
    <value>Bestätigen Sie den Warenempfänger</value>
  </data>
  <data name="consulta_bases_con_error" xml:space="preserve">
    <value>Datenbankanfrage fehlerhaft</value>
  </data>
  <data name="contacta_con_indo" xml:space="preserve">
    <value>Kontaktieren</value>
  </data>
  <data name="contacto" xml:space="preserve">
    <value>Kontakt  </value>
  </data>
  <data name="continuar" xml:space="preserve">
    <value>Weiter</value>
  </data>
  <data name="con_prisma" xml:space="preserve">
    <value>Prismatisch</value>
  </data>
  <data name="correo_solicitud_acceso" xml:space="preserve">
    <value>E-Mail Zugriffsantrag</value>
  </data>
  <data name="cota_inferior" xml:space="preserve">
    <value>Untere Maßzahl</value>
  </data>
  <data name="cota_superior" xml:space="preserve">
    <value>Obere Maßzahl</value>
  </data>
  <data name="crear_caso" xml:space="preserve">
    <value>Fall anlegen</value>
  </data>
  <data name="datosoptica_aviso_logotipo" xml:space="preserve">
    <value>Für das Logo wurde keine Datei angegeben.{0} Es wird das vorgegebene Logo verwendet </value>
  </data>
  <data name="datosoptica_codigo_postal" xml:space="preserve">
    <value>Postleitzahl:</value>
  </data>
  <data name="datosoptica_comunicaciones" xml:space="preserve">
    <value>Ich möchte regelmäßige Mitteilungen von Indo erhalten</value>
  </data>
  <data name="datosoptica_corregir_datos" xml:space="preserve">
    <value>Falls Ihre Daten nicht korrekt sind, schicken Sie eine E-Mail-Nachricht mit den korrigierten Daten an</value>
  </data>
  <data name="datosoptica_direccion" xml:space="preserve">
    <value>Adresse:</value>
  </data>
  <data name="datosoptica_email" xml:space="preserve">
    <value>E-Mail:</value>
  </data>
  <data name="datosoptica_enlace_condiciones" xml:space="preserve">
    <value>Datenschutzbedingungen lesen</value>
  </data>
  <data name="datosoptica_fax" xml:space="preserve">
    <value>Fax:</value>
  </data>
  <data name="datosoptica_guardados_correctamente" xml:space="preserve">
    <value>Daten des Optikergeschäfts korrekt gespeichert</value>
  </data>
  <data name="datosoptica_logotipo_help" xml:space="preserve">
    <value>Das neue Logo wird bei der nächsten Authentifizierung angezeigt</value>
  </data>
  <data name="datosoptica_logotipo_optica" xml:space="preserve">
    <value>Logo des Optikergeschäfts</value>
  </data>
  <data name="datosoptica_nif" xml:space="preserve">
    <value>Steuernummer:</value>
  </data>
  <data name="datosoptica_nombre" xml:space="preserve">
    <value>Name:</value>
  </data>
  <data name="datosoptica_poblacion" xml:space="preserve">
    <value>Ortschaft:</value>
  </data>
  <data name="datosoptica_telefono" xml:space="preserve">
    <value>Telefon:</value>
  </data>
  <data name="datos_adicionales" xml:space="preserve">
    <value>Zusätzliche Daten</value>
  </data>
  <data name="datos_basicos" xml:space="preserve">
    <value>Grundlegende Daten</value>
  </data>
  <data name="datos_opcionales" xml:space="preserve">
    <value>Zusätzliche optionale Daten anzeigen</value>
  </data>
  <data name="datos_optica" xml:space="preserve">
    <value>Daten Optikergeschäft</value>
  </data>
  <data name="datos_tecnicos" xml:space="preserve">
    <value>Technische Daten</value>
  </data>
  <data name="densidad" xml:space="preserve">
    <value>Dichte</value>
  </data>
  <data name="descargando_oferta" xml:space="preserve">
    <value>Angebot wird heruntergeladen</value>
  </data>
  <data name="descarga_oferta_fallo_1" xml:space="preserve">
    <value>Das Angebot konnte derzeit nicht neu geladen werden.</value>
  </data>
  <data name="descarga_oferta_fallo_2" xml:space="preserve">
    <value>Versuchen Sie es später über die Schaltfläche ANGEBOT NEU LADEN im Reiter KONFIGURIERUNG in DATEN OPTIKERGESCHÄFT</value>
  </data>
  <data name="descentramiento" xml:space="preserve">
    <value>Nasenseitige Dezentrierung</value>
  </data>
  <data name="descentramiento_nasal" xml:space="preserve">
    <value>Nasenseitige Dezentrierung</value>
  </data>
  <data name="descripcion" xml:space="preserve">
    <value>Beschreibung</value>
  </data>
  <data name="descripcion_calibracion" xml:space="preserve">
    <value>Geben Sie den Durchmesser in der Koordinate X der vorherigen Kreislinie in Millimeter an </value>
  </data>
  <data name="desde_scanner" xml:space="preserve">
    <value>Ab Scanner</value>
  </data>
  <data name="desmarcar_todos" xml:space="preserve">
    <value>deaktivieren Sie alle</value>
  </data>
  <data name="destinatario" xml:space="preserve">
    <value>Empfänger</value>
  </data>
  <data name="detalle_estado_pedido" xml:space="preserve">
    <value>Detailansicht Bestellungsstatus</value>
  </data>
  <data name="detalle_OMA" xml:space="preserve">
    <value>Detail OMA</value>
  </data>
  <data name="diabetes" xml:space="preserve">
    <value>Diabetes</value>
  </data>
  <data name="diametro" xml:space="preserve">
    <value>Durchmesser</value>
  </data>
  <data name="diametro_resultante" xml:space="preserve">
    <value>Resultierender Durchmesser</value>
  </data>
  <data name="diametro_resultante_abrev" xml:space="preserve">
    <value>Result. Durchm.</value>
  </data>
  <data name="diam_anill" xml:space="preserve">
    <value>DURCHM RING</value>
  </data>
  <data name="diam_desde" xml:space="preserve">
    <value>DURCHM VON</value>
  </data>
  <data name="diam_hasta" xml:space="preserve">
    <value>DURCHM BIS</value>
  </data>
  <data name="diam_resul" xml:space="preserve">
    <value>RESULT DURCHM</value>
  </data>
  <data name="dioptrias" xml:space="preserve">
    <value>Dioptrien</value>
  </data>
  <data name="direccion_indo" xml:space="preserve">
    <value>Avda. Alcalde Barnils, 72 - 08174 Sant Cugat del Vallés - Barcelona</value>
  </data>
  <data name="Discrepancia_muestra_leida" xml:space="preserve">
    <value>F e h l e r: Abweichung zwischen erwarteter und erlangter Musterzahl.</value>
  </data>
  <data name="diseño_no_encontrado" xml:space="preserve">
    <value>Design nicht gefunden</value>
  </data>
  <data name="distancia" xml:space="preserve">
    <value>Abstand</value>
  </data>
  <data name="distancia_trabajo" xml:space="preserve">
    <value>Arbeitsabstand</value>
  </data>
  <data name="dnp" xml:space="preserve">
    <value>NPA</value>
  </data>
  <data name="dnp_de_cerca" xml:space="preserve">
    <value>NPA Nahsicht</value>
  </data>
  <data name="download" xml:space="preserve">
    <value>Download</value>
  </data>
  <data name="duplicar_receta" xml:space="preserve">
    <value>Rezept duplizieren</value>
  </data>
  <data name="d_esp" xml:space="preserve">
    <value>Spez.-Durchm.</value>
  </data>
  <data name="d_especial" xml:space="preserve">
    <value>Spezialdurchm.</value>
  </data>
  <data name="edad" xml:space="preserve">
    <value>Alter</value>
  </data>
  <data name="eje" xml:space="preserve">
    <value>Achse</value>
  </data>
  <data name="eje_abrv" xml:space="preserve">
    <value>ACHSE</value>
  </data>
  <data name="eliminar_seleccionados" xml:space="preserve">
    <value>Ausgewählte Einträge löschen</value>
  </data>
  <data name="el_valor_maximo_es" xml:space="preserve">
    <value>Der Höchstwert beträgt</value>
  </data>
  <data name="email" xml:space="preserve">
    <value>E-Mail</value>
  </data>
  <data name="email_contacto" xml:space="preserve">
    <value>Kontakt-E-Mail</value>
  </data>
  <data name="enfermedad_autoinmune" xml:space="preserve">
    <value>Immunkrankheit</value>
  </data>
  <data name="entrar" xml:space="preserve">
    <value>ANMELDEN</value>
  </data>
  <data name="enviar" xml:space="preserve">
    <value>Senden</value>
  </data>
  <data name="enviar_pedido" xml:space="preserve">
    <value>Bestellung absenden</value>
  </data>
  <data name="enviar_sugerencias" xml:space="preserve">
    <value>Vorschläge senden</value>
  </data>
  <data name="enviar_y_revision" xml:space="preserve">
    <value>Absenden und Überprüfung</value>
  </data>
  <data name="envio_recetas" xml:space="preserve">
    <value>Rezeptsendung</value>
  </data>
  <data name="envio_recetas_falta_destinatario" xml:space="preserve">
    <value>Es muss ein Empfänger gewählt werden</value>
  </data>
  <data name="envio_recetas_ningun_destinatario" xml:space="preserve">
    <value>Es wurde kein Empfänger gefunden</value>
  </data>
  <data name="errores_en_formulario" xml:space="preserve">
    <value>Es wurden Fehler im Formular gefunden</value>
  </data>
  <data name="error_abriendo_aplicacion" xml:space="preserve">
    <value>Fehler beim Öffnen des aufgerufenen Programms</value>
  </data>
  <data name="error_abriendo_base_de_datos" xml:space="preserve">
    <value>Beim Öffnen der Datenbank ist ein Fehler aufgetreten</value>
  </data>
  <data name="error_actualizacion_pedido" xml:space="preserve">
    <value>Fehler bei der Aktualisierung der Bestellung</value>
  </data>
  <data name="error_actualizando_fecha_oferta" xml:space="preserve">
    <value>Beim Aktualisieren des Angebotsdatums in der Datenbank ist ein Fehler aufgetreten</value>
  </data>
  <data name="error_actualizando_oferta" xml:space="preserve">
    <value>Beim Aktualisieren des Angebots ist ein Fehler aufgetreten</value>
  </data>
  <data name="error_actualizando_pedido" xml:space="preserve">
    <value>***&gt; Die Bestellung konnte nicht aktualisiert werden.</value>
  </data>
  <data name="error_agente_trazador" xml:space="preserve">
    <value>Fehler mit dem Planzeichner</value>
  </data>
  <data name="error_al_leer_fichero" xml:space="preserve">
    <value>Fehler beim Lesen der Datei</value>
  </data>
  <data name="error_al_obtener_numero_job" xml:space="preserve">
    <value>Fehler beim Abrufen der Auftragsnummer</value>
  </data>
  <data name="error_al_obtener_numero_sesion" xml:space="preserve">
    <value>Fehler beim Abrufen der Sitzungsnummer</value>
  </data>
  <data name="error_al_salvar_los_datos" xml:space="preserve">
    <value>Fehler beim Speichern der Daten  </value>
  </data>
  <data name="error_autenticación_servicio_solicitante_datos" xml:space="preserve">
    <value>Fehler bei der Authentifizierung des Daten anfragenden Dienstes</value>
  </data>
  <data name="error_autenticar_usuario" xml:space="preserve">
    <value>Der Benutzer konnte nicht authentifiziert werden</value>
  </data>
  <data name="error_base_de_datos" xml:space="preserve">
    <value>Es ist eine Ausnahmesituation mit der lokalen Datenbank aufgetreten</value>
  </data>
  <data name="error_base_de_datos_no_abierta" xml:space="preserve">
    <value>Die Datenbank muss geöffnet sein</value>
  </data>
  <data name="error_borrando_pedido" xml:space="preserve">
    <value>Beim Löschen der Bestellung ist ein Fehler aufgetreten</value>
  </data>
  <data name="error_borrando_recetas" xml:space="preserve">
    <value>Beim Löschen der Rezepte ist ein Fehler aufgetreten</value>
  </data>
  <data name="error_busqueda_inicial_pedidos" xml:space="preserve">
    <value>Während der anfänglichen Bestellungssuche ist ein Fehler aufgetreten</value>
  </data>
  <data name="error_busqueda_pedidos" xml:space="preserve">
    <value>Fehler bei der Suche nach Bestellungen</value>
  </data>
  <data name="error_busqueda_recetas" xml:space="preserve">
    <value>Fehler bei der Suche nach Rezepten</value>
  </data>
  <data name="error_calibracion" xml:space="preserve">
    <value>Die Kalibrierung konnte nicht durchgeführt werden</value>
  </data>
  <data name="error_cambiando_password" xml:space="preserve">
    <value>Das Passwort konnte nicht geändert werden</value>
  </data>
  <data name="error_caracteres_diferentes_clave_acceso" xml:space="preserve">
    <value>Unterschiedliche Zeichen im Zugangscode</value>
  </data>
  <data name="error_caracteres_no_permitidos_clave_acceso" xml:space="preserve">
    <value>Unzulässige Zeichen im Zugangscode</value>
  </data>
  <data name="error_cargando_destinatarios" xml:space="preserve">
    <value>Fehler beim Laden der Empfänger</value>
  </data>
  <data name="error_claves_no_coinciden" xml:space="preserve">
    <value>Die Zugangscodes stimmen nicht überein</value>
  </data>
  <data name="error_clave_acceso_no_permitida" xml:space="preserve">
    <value>Unzulässiger Zugangscode</value>
  </data>
  <data name="error_clave_incorrecta" xml:space="preserve">
    <value>Der angegebene Code ist nicht korrekt</value>
  </data>
  <data name="error_comprobar_disponibilidad_segunda_gafa" xml:space="preserve">
    <value>Fehler bei Überprüfung Verfügbarkeit zweite Brille</value>
  </data>
  <data name="error_conexion_agente_trazador" xml:space="preserve">
    <value>Keine Verbindung zum Planzeichner</value>
  </data>
  <data name="error_conexion_agente_trazador_elegido" xml:space="preserve">
    <value>Verbindungsfehler mit dem gewählten Planzeichner</value>
  </data>
  <data name="error_conexion_agente_trazador_insertado" xml:space="preserve">
    <value>Verbindungsfehler mit dem eingefügten Planzeichner</value>
  </data>
  <data name="error_conexion_BDD" xml:space="preserve">
    <value>Verbindungsfehler mit der DB</value>
  </data>
  <data name="error_conexion_con_SAP" xml:space="preserve">
    <value>Verbindungsfehler mit SAP</value>
  </data>
  <data name="error_conexion_servicio" xml:space="preserve">
    <value>Keine Verbindung zum Server</value>
  </data>
  <data name="error_consultar_tabla_ProductoCategoria" xml:space="preserve">
    <value>Fehler beim Abfragen der Produktkategorie-Tabelle</value>
  </data>
  <data name="error_consultar_tabla_productoParametros" xml:space="preserve">
    <value>Fehler beim Abfragen der Produktparameter-Tabelle</value>
  </data>
  <data name="error_desconocido" xml:space="preserve">
    <value>Unbekannter Fehler</value>
  </data>
  <data name="error_email_direccion_invalida" xml:space="preserve">
    <value>Die E-Mail-Adresse ist nicht korrekt</value>
  </data>
  <data name="error_email_falta_asunto" xml:space="preserve">
    <value>Der Betreff ist obligatorisch</value>
  </data>
  <data name="error_email_falta_cuerpo" xml:space="preserve">
    <value>Die Nachricht ist obligatorisch</value>
  </data>
  <data name="error_email_falta_direccion" xml:space="preserve">
    <value>Die Angabe der E-Mail-Adresse ist obligatorisch</value>
  </data>
  <data name="error_enviando_correo" xml:space="preserve">
    <value>Die E-Mail-Nachricht konnte nicht versendet werden</value>
  </data>
  <data name="error_enviando_lista_recetas" xml:space="preserve">
    <value>Fehler beim Versenden der Rezeptliste</value>
  </data>
  <data name="error_enviando_mail" xml:space="preserve">
    <value>Beim Versenden der E-Mail ist ein Fehler aufgetreten</value>
  </data>
  <data name="error_envio_pedido_paso2_1" xml:space="preserve">
    <value>Beim Aufzeichnen der Bestellung wurde ein Kommunikationsfehler mit unserem Server festgestellt. Überprüfen Sie im Reiter „Getätigte Bestellungen“, ob diese aufgezeichnet wurde und gehen Sie wie folgt vor:</value>
  </data>
  <data name="error_envio_pedido_paso2_2" xml:space="preserve">
    <value>Falls die Aufzeichnung erfolgt ist, löschen Sie den Reiter „„Bestellung durchführen““</value>
  </data>
  <data name="error_envio_pedido_paso2_3" xml:space="preserve">
    <value>Falls die Aufzeichnung nicht erfolgt ist, bitte erneut senden.</value>
  </data>
  <data name="error_excepcion_autenticando_usuario" xml:space="preserve">
    <value>Es ist eine Ausnahmesituation beim der Benutzerauthentifizierung aufgetreten</value>
  </data>
  <data name="error_excepcion_copando_clase_pedidos" xml:space="preserve">
    <value>Es ist eine Ausnahmesituation beim Kopieren der Klasse der Bestellungen aufgetreten</value>
  </data>
  <data name="error_excepcion_enviando_correo" xml:space="preserve">
    <value>Es ist eine Ausnahmesituation beim Versenden von Nachrichten aufgetreten</value>
  </data>
  <data name="error_excepcion_enviando_pedido" xml:space="preserve">
    <value>Es ist eine Ausnahmesituation beim Versenden der Bestellung aufgetreten</value>
  </data>
  <data name="error_excepcion_enviando_pedido_para_comparacion" xml:space="preserve">
    <value>Es ist eine Ausnahmesituation beim Versenden der Bestellung zum Vergleich aufgetreten</value>
  </data>
  <data name="error_excepcion_obtenido_el_area_venta" xml:space="preserve">
    <value>Es ist eine Ausnahmesituation bei der Abfrage des Vertriebsbereichs aufgetreten</value>
  </data>
  <data name="error_excepcion_obteniendo_pedidos_busqueda" xml:space="preserve">
    <value>Es ist eine Ausnahmesituation bei der Abfrage der Bestellungsliste (Suche) aufgetreten</value>
  </data>
  <data name="error_excepcion_obteniendo_pedidos_inicial" xml:space="preserve">
    <value>Es ist eine Ausnahmesituation bei der Abfrage der Bestellungsliste (anfänglich) aufgetreten</value>
  </data>
  <data name="error_excepcion_producida" xml:space="preserve">
    <value>Es ist eine Ausnahmesituation aufgetreten</value>
  </data>
  <data name="error_excepcion_recuperando_oferta" xml:space="preserve">
    <value>Es ist eine Ausnahmesituation bei der erneuten Anzeige des Angebots aufgetreten</value>
  </data>
  <data name="error_exceso_numero_intentos_permitidos" xml:space="preserve">
    <value>Die Anzahl der zulässigen Versuche wurde überschritten.</value>
  </data>
  <data name="error_grabacion_pedido" xml:space="preserve">
    <value>Beim Aufzeichnen Ihrer Bestellung ist ein Fehler aufgetreten. Bitte führen Sie diese erneut durch</value>
  </data>
  <data name="error_grabando_pedido" xml:space="preserve">
    <value>***&gt; Die Bestellung konnte nicht gespeichert werden</value>
  </data>
  <data name="error_guardando_datos_configuracion" xml:space="preserve">
    <value>Fehler beim Speichern der Konfigurierungsdaten</value>
  </data>
  <data name="error_guardando_datos_optica" xml:space="preserve">
    <value>Fehler beim Speichern der Daten des Optikergeschäfts</value>
  </data>
  <data name="error_guardando_forma" xml:space="preserve">
    <value>Beim Speichern der Form ist ein Fehler aufgetreten</value>
  </data>
  <data name="error_guardando_oferta" xml:space="preserve">
    <value>Beim Speichern des Angebots ist ein Fehler aufgetreten</value>
  </data>
  <data name="error_iniciando_aplicacion" xml:space="preserve">
    <value>Beim Programmstart ist ein Fehler aufgetreten</value>
  </data>
  <data name="error_introducir_nueva_clave" xml:space="preserve">
    <value>Neuen Zugangscode eingeben</value>
  </data>
  <data name="error_lecturas_agentes" xml:space="preserve">
    <value>Beim Zugriff auf die Lesungen von  {0} Planzeichner{1} ist ein Fehler aufgetreten</value>
  </data>
  <data name="error_leyendo_fichero_base_de_datos" xml:space="preserve">
    <value>Beim Lesen der Datenbankdatei ist ein Fehler aufgetreten</value>
  </data>
  <data name="error_llamada_servicio_de_SAP" xml:space="preserve">
    <value>Fehler beim Anrufen des SAP-Dienstes</value>
  </data>
  <data name="error_login" xml:space="preserve">
    <value>Beim Login ist ein Fehler aufgetreten</value>
  </data>
  <data name="error_modificando_noticia_emergente" xml:space="preserve">
    <value>Fehler beim Ändern einer aufkommenden Nachricht</value>
  </data>
  <data name="error_no_se_han_obtenido_datos_configuracion_cliente" xml:space="preserve">
    <value>Die Konfigurierungsdaten des Kunden konnten nicht erlangt werden</value>
  </data>
  <data name="error_no_se_ha_obtenido_el_area_venta" xml:space="preserve">
    <value>Der Verkaufsbereich konnte nicht aufgerufen werden</value>
  </data>
  <data name="error_no_se_ha_podido_recuperar_lista_pedidos" xml:space="preserve">
    <value>***&gt;Die Liste mit den Bestellungen konnte nicht erneut aufgerufen werden.</value>
  </data>
  <data name="error_no_se_ha_podido_recuperar_oferta" xml:space="preserve">
    <value>***&gt;Das Angebot konnte nicht erneut aufgerufen werden.</value>
  </data>
  <data name="error_obtener_forma_basica" xml:space="preserve">
    <value>Fehler beim Aufrufen der Basisform</value>
  </data>
  <data name="error_obteniendo_los_datos_configuracion_del_cliente" xml:space="preserve">
    <value>Es ist eine Ausnahmesituation bei der Abfrage der Konfigurierungsdaten aufgetreten</value>
  </data>
  <data name="error_proceso_login" xml:space="preserve">
    <value>Beim Login-Vorgang ist ein Fehler aufgetreten</value>
  </data>
  <data name="error_recuperando_monturas" xml:space="preserve">
    <value>Fehler beim erneuten Aufruf von Gestellen</value>
  </data>
  <data name="error_recuperando_pedido" xml:space="preserve">
    <value>***&gt; Die Bestellung konnte nicht erneut aufgerufen werden</value>
  </data>
  <data name="error_recuperando_receta" xml:space="preserve">
    <value>***&gt; Das Rezept konnte nicht erneut aufgerufen werden</value>
  </data>
  <data name="error_recuperando_recetas" xml:space="preserve">
    <value>Fehler beim erneuten Aufruf von Rezepten</value>
  </data>
  <data name="error_reintentos_excedidos" xml:space="preserve">
    <value>Versuche überschritten</value>
  </data>
  <data name="error_servicio_cambio_de_password" xml:space="preserve">
    <value>Der Dienst ‚Passwortänderung’ hat einen Fehler gemeldet</value>
  </data>
  <data name="error_servicio_envio_correo" xml:space="preserve">
    <value>Der Dienst ‚E-Mail-Sendung’ hat einen Fehler gemeldet</value>
  </data>
  <data name="error_servicio_login" xml:space="preserve">
    <value>Der Dienst ‚Login’ hat einen Fehler gemeldet</value>
  </data>
  <data name="error_signo_interrogacion_no_permitido_clave_acceso" xml:space="preserve">
    <value>Fragezeichen im Zugangscode nicht zulässig</value>
  </data>
  <data name="error_tipo_de_base_de_datos_desconocida" xml:space="preserve">
    <value>Unbekannter Datenbanktyp</value>
  </data>
  <data name="error_usuario_bloqueado" xml:space="preserve">
    <value>Der Benutzer ist gesperrt</value>
  </data>
  <data name="error_usuario_no_existe" xml:space="preserve">
    <value>Der Benutzer existiert nicht</value>
  </data>
  <data name="error_usuario_o_clave_no_validos" xml:space="preserve">
    <value>Benutzer oder Passwort ungültig</value>
  </data>
  <data name="error_usuario_ya_existe" xml:space="preserve">
    <value>Der Benutzer existiert bereits</value>
  </data>
  <data name="error_validacion_sesion" xml:space="preserve">
    <value>Fehler beim Bestätigen der Sitzung</value>
  </data>
  <data name="error_validación_sesion" xml:space="preserve">
    <value>Fehler beim Bestätigen der Sitzung</value>
  </data>
  <data name="esfera" xml:space="preserve">
    <value>SPHÄRE</value>
  </data>
  <data name="esfera_abrv" xml:space="preserve">
    <value>SPH</value>
  </data>
  <data name="espana" xml:space="preserve">
    <value>Spanien</value>
  </data>
  <data name="Esperando_respuesta_del_usuario_para_actualizar_la_oferta" xml:space="preserve">
    <value>Es wird eine Antwort des Benutzers erwartet, um das Angebot zu aktualisieren...</value>
  </data>
  <data name="espesor" xml:space="preserve">
    <value>Stärke</value>
  </data>
  <data name="espesores" xml:space="preserve">
    <value>Stärken</value>
  </data>
  <data name="espesor_borde" xml:space="preserve">
    <value>Randstärke</value>
  </data>
  <data name="espesor_borde_esp" xml:space="preserve">
    <value>Spezielle Randstärke</value>
  </data>
  <data name="espesor_borde_especial" xml:space="preserve">
    <value>Spezielle Randstärke</value>
  </data>
  <data name="espesor_centro" xml:space="preserve">
    <value>Stärke Zentrum</value>
  </data>
  <data name="espesor_centro_abr" xml:space="preserve">
    <value>Stärke Zentrum</value>
  </data>
  <data name="espesor_especial" xml:space="preserve">
    <value>Besondere Stärke</value>
  </data>
  <data name="espesor_max" xml:space="preserve">
    <value>Höchststärke</value>
  </data>
  <data name="espesor_maximo" xml:space="preserve">
    <value>Höchststärke</value>
  </data>
  <data name="espesor_min" xml:space="preserve">
    <value>Mindeststärke</value>
  </data>
  <data name="espesor_minimo" xml:space="preserve">
    <value>Mindeststärke</value>
  </data>
  <data name="espesor_taladro_minimo" xml:space="preserve">
    <value>Mindeststärke Bohrloch</value>
  </data>
  <data name="espesor_taladro_minimo_abrev" xml:space="preserve">
    <value>Mind.st. Bohrl.</value>
  </data>
  <data name="espesor_taladro_nasal" xml:space="preserve">
    <value>Stärke Bohrloch nasenseitig</value>
  </data>
  <data name="espesor_taladro_temporal" xml:space="preserve">
    <value>Stärke Bohrloch temporal</value>
  </data>
  <data name="establecer_favoritos_a_todos_clientes" xml:space="preserve">
    <value>Für alle Einrichtungen Favoriten einrichten</value>
  </data>
  <data name="estado" xml:space="preserve">
    <value>Status</value>
  </data>
  <data name="estadoOD" xml:space="preserve">
    <value>Status RA</value>
  </data>
  <data name="estadoOI" xml:space="preserve">
    <value>Status LA</value>
  </data>
  <data name="estado_pedido_grabados" xml:space="preserve">
    <value>Gravuren</value>
  </data>
  <data name="estado_pedido_pendientes" xml:space="preserve">
    <value>Reparatur steht aus</value>
  </data>
  <data name="estado_pedido_todos" xml:space="preserve">
    <value>Alle</value>
  </data>
  <data name="es_anterior_a" xml:space="preserve">
    <value>liegt vor</value>
  </data>
  <data name="es_necesario_cerrar_la_aplicacion" xml:space="preserve">
    <value>Das Programm wurde korrekt installiert.{0}Betätigen Sie anschließend die Schaltfläche OK, warten Sie einige Sekunden, bis sich diese schließt und führen Sie einen Doppelklick auf dem Indonet-Symbol aus, das sich auf Ihrem Schreibtisch befindet.</value>
  </data>
  <data name="expedido" xml:space="preserve">
    <value>Versendet</value>
  </data>
  <data name="explorar" xml:space="preserve">
    <value>Durchsuchen</value>
  </data>
  <data name="exportar" xml:space="preserve">
    <value>Exportieren</value>
  </data>
  <data name="fabricante" xml:space="preserve">
    <value>Hersteller</value>
  </data>
  <data name="familia" xml:space="preserve">
    <value>Familie</value>
  </data>
  <data name="favoritas" xml:space="preserve">
    <value>Favorit</value>
  </data>
  <data name="favoritos" xml:space="preserve">
    <value>Favoriten</value>
  </data>
  <data name="fecha" xml:space="preserve">
    <value>Datum</value>
  </data>
  <data name="fecha_envio" xml:space="preserve">
    <value>Versanddatum:</value>
  </data>
  <data name="fecha_hi_menor_low" xml:space="preserve">
    <value>Das Datum „bis“ darf nicht vor dem Datum „von“ liegen</value>
  </data>
  <data name="fecha_hi_nula" xml:space="preserve">
    <value>Das Datum „bis“ darf nicht ungültig sein</value>
  </data>
  <data name="fecha_hora_grabacion" xml:space="preserve">
    <value>Datum und Uhrzeit der Aufnahme</value>
  </data>
  <data name="fecha_llegada_real" xml:space="preserve">
    <value>Datum Ankunft:</value>
  </data>
  <data name="fecha_low_nula" xml:space="preserve">
    <value>Das Datum „von“ darf nicht ungültig sein</value>
  </data>
  <data name="fecha_prevista_envio" xml:space="preserve">
    <value>Voraussichtliches Ausgangsdatum</value>
  </data>
  <data name="fecha_prevista_recepcion" xml:space="preserve">
    <value>Voraussichtliches Empfangsdatum</value>
  </data>
  <data name="fecha_rango" xml:space="preserve">
    <value>Datum (7-Tage-Intervall)</value>
  </data>
  <data name="ffname" xml:space="preserve">
    <value>FFNAME</value>
  </data>
  <data name="FicherosFormas" xml:space="preserve">
    <value>Formbilderdateien</value>
  </data>
  <data name="ficheros_adjuntos" xml:space="preserve">
    <value>Anhänge</value>
  </data>
  <data name="filtro_descripcion" xml:space="preserve">
    <value>Filter Beschreibung</value>
  </data>
  <data name="filtro_fabricante" xml:space="preserve">
    <value>Filter Hersteller</value>
  </data>
  <data name="finalizado" xml:space="preserve">
    <value>Abgeschlossen</value>
  </data>
  <data name="finalizar_pedido" xml:space="preserve">
    <value>Bestellung abschließen</value>
  </data>
  <data name="fin_ficheros_adjuntos" xml:space="preserve">
    <value>Ende Anhänge</value>
  </data>
  <data name="foco" xml:space="preserve">
    <value>Fokus  </value>
  </data>
  <data name="foco_y_material" xml:space="preserve">
    <value>Fokus und Material</value>
  </data>
  <data name="forma" xml:space="preserve">
    <value>Form</value>
  </data>
  <data name="formas_fax_enviarFichero" xml:space="preserve">
    <value>Aus Datei abrufen</value>
  </data>
  <data name="formas_leidas" xml:space="preserve">
    <value>Gelesene Formen</value>
  </data>
  <data name="formas_y_espesores" xml:space="preserve">
    <value>Formen und Stärken</value>
  </data>
  <data name="forma_basica_indicada_no_disponible_en_este_momento" xml:space="preserve">
    <value>: Die angegebene Basisform ist zurzeit nicht verfügbar</value>
  </data>
  <data name="forma_escogida" xml:space="preserve">
    <value>Gewählte Form</value>
  </data>
  <data name="forma_guardada_OK" xml:space="preserve">
    <value>Form korrekt gespeichert</value>
  </data>
  <data name="fotocromatico" xml:space="preserve">
    <value>Photochromatisch</value>
  </data>
  <data name="francia" xml:space="preserve">
    <value>Frankreich</value>
  </data>
  <data name="frontal" xml:space="preserve">
    <value>Frontal</value>
  </data>
  <data name="ganancia_en_espesor" xml:space="preserve">
    <value>Zunahme an Stärke</value>
  </data>
  <data name="ganancia_en_espesor_abr" xml:space="preserve">
    <value>Zun. an Stärke</value>
  </data>
  <data name="ganancia_en_peso" xml:space="preserve">
    <value>Zunahme an Gewicht</value>
  </data>
  <data name="ganancia_en_peso_abr" xml:space="preserve">
    <value>Zun. an Gewicht</value>
  </data>
  <data name="ganancia_espesor" xml:space="preserve">
    <value>Zunahme an Stärke</value>
  </data>
  <data name="ganancia_peso" xml:space="preserve">
    <value>Zunahme an Gewicht</value>
  </data>
  <data name="genero" xml:space="preserve">
    <value>Art</value>
  </data>
  <data name="grabar_cambios" xml:space="preserve">
    <value>Änderungen speichern</value>
  </data>
  <data name="grados" xml:space="preserve">
    <value>Grad</value>
  </data>
  <data name="graduacion_resultante" xml:space="preserve">
    <value>Sich ergebende Sehstärke:</value>
  </data>
  <data name="guardar" xml:space="preserve">
    <value>Speichern</value>
  </data>
  <data name="guardar_biselado" xml:space="preserve">
    <value>Schliff speichern</value>
  </data>
  <data name="guardar_receta" xml:space="preserve">
    <value>Rezept speichern</value>
  </data>
  <data name="hipertension" xml:space="preserve">
    <value>Bluthochdruck</value>
  </data>
  <data name="hombre" xml:space="preserve">
    <value>Mann</value>
  </data>
  <data name="hora" xml:space="preserve">
    <value>Uhrzeit</value>
  </data>
  <data name="idioma" xml:space="preserve">
    <value>Sprache</value>
  </data>
  <data name="id_design" xml:space="preserve">
    <value>ID_DESIGN</value>
  </data>
  <data name="id_job" xml:space="preserve">
    <value>ID JOB</value>
  </data>
  <data name="id_producto" xml:space="preserve">
    <value>ID_PRODUCTO</value>
  </data>
  <data name="importar" xml:space="preserve">
    <value>Importieren</value>
  </data>
  <data name="imprimir" xml:space="preserve">
    <value>Drucken</value>
  </data>
  <data name="imprimir_lista" xml:space="preserve">
    <value>Liste drucken</value>
  </data>
  <data name="imprimir_pedidos_seleccionados" xml:space="preserve">
    <value>Ausgewählte Bestellungen drucken</value>
  </data>
  <data name="incongruencia_tablas_producto_categoria" xml:space="preserve">
    <value>Unstimmigkeit zwischen Produkt-/Kategorie-Tabellen</value>
  </data>
  <data name="incongruencia_tablas_producto_parametros" xml:space="preserve">
    <value>Unstimmigkeit zwischen Produkt-/Parameter-Tabellen</value>
  </data>
  <data name="indice" xml:space="preserve">
    <value>Index</value>
  </data>
  <data name="indice_desde" xml:space="preserve">
    <value>Index von</value>
  </data>
  <data name="indice_hasta" xml:space="preserve">
    <value>Index bis</value>
  </data>
  <data name="indice_material_no_valido" xml:space="preserve">
    <value>Der Index ist kein gültiger Materialindex für das aktuelle Angebot</value>
  </data>
  <data name="indobisel" xml:space="preserve">
    <value>» Indobisel</value>
  </data>
  <data name="indobox" xml:space="preserve">
    <value>Indobox</value>
  </data>
  <data name="indoCenter_popup_titulo" xml:space="preserve">
    <value>IndoCenter-Lesungen auswählen</value>
  </data>
  <data name="indonet" xml:space="preserve">
    <value>Indonet</value>
  </data>
  <data name="indo_en_el_mundo" xml:space="preserve">
    <value>» Indo in der Welt</value>
  </data>
  <data name="inferior" xml:space="preserve">
    <value>Untere/r</value>
  </data>
  <data name="infolog_actualizacion_oferta" xml:space="preserve">
    <value>***&gt; Start Angebotsaktualisierung</value>
  </data>
  <data name="infolog_cabecera_oferta_ok" xml:space="preserve">
    <value>***&gt; Kopfzeile Angebot OK</value>
  </data>
  <data name="infolog_creacion_bbdd_local" xml:space="preserve">
    <value>***&gt; Start lokale Datenbankerstellung</value>
  </data>
  <data name="infolog_creacion_bbdd_local_OK" xml:space="preserve">
    <value>***&gt; Erstellung lokale Datenbank OK</value>
  </data>
  <data name="infolog_grabar_oferta" xml:space="preserve">
    <value>***&gt; Start Angebotsspeicherung in lokaler Datenbank</value>
  </data>
  <data name="infolog_grabar_oferta_OK" xml:space="preserve">
    <value>***&gt; Angebot in lokaler Datenbank speichern OK</value>
  </data>
  <data name="infolog_oferta_descargada_ok" xml:space="preserve">
    <value>***&gt; Angebot heruntergeladen OK</value>
  </data>
  <data name="informacion" xml:space="preserve">
    <value>Informationen</value>
  </data>
  <data name="info_creacion_bbdd_local" xml:space="preserve">
    <value>***&gt;Start Datenbankerstellung</value>
  </data>
  <data name="info_creacion_bbdd_local_OK" xml:space="preserve">
    <value>***&gt;Erstellung Datenbank OK</value>
  </data>
  <data name="info_envio_pedido_revisar" xml:space="preserve">
    <value>Ihre Bestellung wurde aufgezeichnet. Indo wird diesen überprüfen und in Kürze starten</value>
  </data>
  <data name="info_grabar_oferta" xml:space="preserve">
    <value>***&gt;Start Angebotsspeicherung in Datenbank</value>
  </data>
  <data name="info_grabar_oferta_OK" xml:space="preserve">
    <value>***&gt;Angebot in Datenbank gespeichert  OK</value>
  </data>
  <data name="info_no_fabricara" xml:space="preserve">
    <value>Dieses Glas wird nicht hergestellt</value>
  </data>
  <data name="info_ojo_informativo" xml:space="preserve">
    <value>Wenn dieses Auge als Informationsauge markiert wird, erfolgt keine Herstellung</value>
  </data>
  <data name="info_pedido_procesado" xml:space="preserve">
    <value>Ihre Bestellung mit Nummer {0} wurde aufgezeichnet und korrekt abgewickelt</value>
  </data>
  <data name="inicio" xml:space="preserve">
    <value>Start</value>
  </data>
  <data name="insertar" xml:space="preserve">
    <value>Einfügen</value>
  </data>
  <data name="IntentarOtraVez" xml:space="preserve">
    <value>Bitte versuchen Sie es mit einem anderen Bild erneut oder benachrichtigen Sie Ihre Kontaktperson bei INDO.</value>
  </data>
  <data name="intervalo_de_fechas" xml:space="preserve">
    <value>Datumsintervall</value>
  </data>
  <data name="italia" xml:space="preserve">
    <value>Italien</value>
  </data>
  <data name="jerarquia_incorrecta" xml:space="preserve">
    <value>Falsche Hierarchie</value>
  </data>
  <data name="job" xml:space="preserve">
    <value>JOB</value>
  </data>
  <data name="jobs_omain" xml:space="preserve">
    <value>JOBS(OMAIN)</value>
  </data>
  <data name="job_no_se_encuentra" xml:space="preserve">
    <value>Der Job konnte nicht gefunden werden</value>
  </data>
  <data name="laboratorio" xml:space="preserve">
    <value>Labor</value>
  </data>
  <data name="lente" xml:space="preserve">
    <value>Glas</value>
  </data>
  <data name="login_verificar" xml:space="preserve">
    <value>ÜBERPRÜFEN</value>
  </data>
  <data name="logout" xml:space="preserve">
    <value>Abmelden</value>
  </data>
  <data name="log_actualizacion_oferta" xml:space="preserve">
    <value>***&gt;Start Angebotsaktualisierung</value>
  </data>
  <data name="log_cabecera_oferta_ok" xml:space="preserve">
    <value>***&gt;Kopfzeile Angebot OK</value>
  </data>
  <data name="log_oferta_descargada_ok" xml:space="preserve">
    <value>***&gt;Aktualisierung Angebot OK</value>
  </data>
  <data name="l_taladrada" xml:space="preserve">
    <value>Plan</value>
  </data>
  <data name="macro" xml:space="preserve">
    <value>Makro</value>
  </data>
  <data name="macros_talla" xml:space="preserve">
    <value>Schliffmakros</value>
  </data>
  <data name="mail_informacion_estado_pedidos" xml:space="preserve">
    <value>INFORMATIONSMAIL STATUS DER BESTELLUNGEN</value>
  </data>
  <data name="maquina" xml:space="preserve">
    <value>Maschine</value>
  </data>
  <data name="marca" xml:space="preserve">
    <value>Markierung</value>
  </data>
  <data name="marcar_todos" xml:space="preserve">
    <value>lassen Sie sich</value>
  </data>
  <data name="marcas_laser" xml:space="preserve">
    <value>LASER-Markierungen</value>
  </data>
  <data name="mas_informacion" xml:space="preserve">
    <value>Weitere Informationen</value>
  </data>
  <data name="material" xml:space="preserve">
    <value>Material</value>
  </data>
  <data name="mensaje" xml:space="preserve">
    <value>Nachricht</value>
  </data>
  <data name="mensaje_pedido_duplicado" xml:space="preserve">
    <value>ACHTUNG: Möglicherweise doppelte Bestellung. Aufgegeben am {0} mit der Nr. {1}: {2}</value>
  </data>
  <data name="mensaje_solicitud_clave" xml:space="preserve">
    <value>Um ein Passwort zu erhalten, müssen Sie nur eine E-Mail-Adresse sowie Ihren Kundencode von Indo eingeben.{0}In Kürze erhalten Sie Ihr Passwort für den Zugriff auf Indonet.{0}Vielen Dank</value>
  </data>
  <data name="metal" xml:space="preserve">
    <value>Metall</value>
  </data>
  <data name="mini_bisel" xml:space="preserve">
    <value>Minischleifgerät</value>
  </data>
  <data name="mis_biselados" xml:space="preserve">
    <value>Meine Schliffe</value>
  </data>
  <data name="mi_buzon" xml:space="preserve">
    <value>Mein Briefkasten</value>
  </data>
  <data name="modelo" xml:space="preserve">
    <value>Modell</value>
  </data>
  <data name="modificar" xml:space="preserve">
    <value>Ändern</value>
  </data>
  <data name="modificar_espesor" xml:space="preserve">
    <value>Stärke ändern</value>
  </data>
  <data name="montaje" xml:space="preserve">
    <value>Montage</value>
  </data>
  <data name="montura" xml:space="preserve">
    <value>Gestell</value>
  </data>
  <data name="monturas" xml:space="preserve">
    <value>Gestelle</value>
  </data>
  <data name="montura_seleccionada" xml:space="preserve">
    <value>Ausgewähltes Gestell</value>
  </data>
  <data name="mostrar_lentes_stock" xml:space="preserve">
    <value>Nur Gläser mit Lagerbestand anzeigen</value>
  </data>
  <data name="motivos" xml:space="preserve">
    <value>Gründe</value>
  </data>
  <data name="motivo_no_definido" xml:space="preserve">
    <value>Der Grund für den Zwischenfall in diesem Formular wurde nicht angegeben.</value>
  </data>
  <data name="msg_Prerequisitos" xml:space="preserve">
    <value>Im Anschluss startet automatisch die Installation der notwendigen Programme für die Erfassung und spätere Verarbeitung der Bilder, um die Gestellform zu erlangen.</value>
  </data>
  <data name="mujer" xml:space="preserve">
    <value>Frau</value>
  </data>
  <data name="nasal" xml:space="preserve">
    <value>Nasenseitig</value>
  </data>
  <data name="nd" xml:space="preserve">
    <value>Nd</value>
  </data>
  <data name="ninguna" xml:space="preserve">
    <value>Keine/r/s</value>
  </data>
  <data name="nombre" xml:space="preserve">
    <value>Name</value>
  </data>
  <data name="nombre_producto" xml:space="preserve">
    <value>PRODUKTNAME</value>
  </data>
  <data name="NoPosibleObtenerForma" xml:space="preserve">
    <value>Die Form konnte aus dem Bild nicht erlangt werden.</value>
  </data>
  <data name="normal" xml:space="preserve">
    <value>Normal (48 Std.)</value>
  </data>
  <data name="noticias" xml:space="preserve">
    <value>Nachrichten</value>
  </data>
  <data name="no_anillas_para_este_taller" xml:space="preserve">
    <value>Es gibt keine Ringe für diese Werkstatt</value>
  </data>
  <data name="no_esta_bien_formado" xml:space="preserve">
    <value>Nicht korrekt geformt</value>
  </data>
  <data name="no_es_multiplo_de" xml:space="preserve">
    <value>Kein Vielfaches von</value>
  </data>
  <data name="no_favoritas" xml:space="preserve">
    <value>keine Favoriten</value>
  </data>
  <data name="no_hay_familias_para_este_taller" xml:space="preserve">
    <value>Es gibt keine Familien für diese Werkstatt</value>
  </data>
  <data name="no_hay_macros_talla_para_este_taller" xml:space="preserve">
    <value>Es gibt keine Makros für diese Werkstatt</value>
  </data>
  <data name="no_hay_marcas_laser_para_este_taller" xml:space="preserve">
    <value>Es gibt keine Lasermarkierungen für diese Werkstatt</value>
  </data>
  <data name="no_hay_motivos" xml:space="preserve">
    <value>Es gibt keine Gründe</value>
  </data>
  <data name="no_hay_ningun_st_para_este_taller" xml:space="preserve">
    <value>Es gibt keinen Lag. für diese Werkstatt</value>
  </data>
  <data name="no_hay_peticiones_talla_en_periodo_indicado" xml:space="preserve">
    <value>Es gibt keine Größenanfragen in dem angegebenen Zeitraum</value>
  </data>
  <data name="no_hay_restricciones_de_espesores_borde_para_este_taller" xml:space="preserve">
    <value>Es sind keine Randstärkeneinschränkungen für diese Werkstatt definiert</value>
  </data>
  <data name="no_hay_restricciones_de_espesores_para_este_taller" xml:space="preserve">
    <value>Es sind keine Stärkeneinschränkungen für diese Werkstatt definiert</value>
  </data>
  <data name="no_mostrar_aviso" xml:space="preserve">
    <value>Diese Meldung nicht wieder anzeigen</value>
  </data>
  <data name="no_se_actualiza_la_oferta" xml:space="preserve">
    <value>Das Angebot wird nicht aktualisiert</value>
  </data>
  <data name="no_se_ha_podido_enviar_la_solucitud" xml:space="preserve">
    <value>Der Antrag konnte nicht versendet werden, bitte versuchen Sie es später</value>
  </data>
  <data name="nueva_oferta_actualizada_correctamente" xml:space="preserve">
    <value>Neues Angebot korrekt aktualisiert</value>
  </data>
  <data name="nueva_prev" xml:space="preserve">
    <value>Neue Prog.</value>
  </data>
  <data name="nueva_prevision" xml:space="preserve">
    <value>Neue Prognose</value>
  </data>
  <data name="nuevo" xml:space="preserve">
    <value>Neu</value>
  </data>
  <data name="numero_pedido" xml:space="preserve">
    <value>Bestellungsnummer</value>
  </data>
  <data name="n_abbe" xml:space="preserve">
    <value>Abbe-Nr. </value>
  </data>
  <data name="n_pedido" xml:space="preserve">
    <value>Bestellung Nr.</value>
  </data>
  <data name="obligatorio_seleccionar_destiantario" xml:space="preserve">
    <value>Es ist erforderlich, den Empfänger der Ware zu informieren</value>
  </data>
  <data name="observaciones" xml:space="preserve">
    <value>Anmerkungen</value>
  </data>
  <data name="OD" xml:space="preserve">
    <value>RA</value>
  </data>
  <data name="oferta" xml:space="preserve">
    <value>Angebot</value>
  </data>
  <data name="oferta_actualizada" xml:space="preserve">
    <value>Aktualisiertes Angebot...</value>
  </data>
  <data name="OI" xml:space="preserve">
    <value>LA</value>
  </data>
  <data name="OjoDerecho" xml:space="preserve">
    <value>Rechtes Auge</value>
  </data>
  <data name="OjoIzquierdo" xml:space="preserve">
    <value>Linkes Auge</value>
  </data>
  <data name="ojo_derecho" xml:space="preserve">
    <value>Rechtes Auge</value>
  </data>
  <data name="ojo_informativo" xml:space="preserve">
    <value>Informationsauge</value>
  </data>
  <data name="ojo_izquierdo" xml:space="preserve">
    <value>Linkes Auge</value>
  </data>
  <data name="optimizacion_freemax" xml:space="preserve">
    <value>Optimierung FreeMax</value>
  </data>
  <data name="orientacion_del_bisel" xml:space="preserve">
    <value>Ausrichtung Schleifgerät</value>
  </data>
  <data name="otros_datos" xml:space="preserve">
    <value>WEITERE DATEN</value>
  </data>
  <data name="oval" xml:space="preserve">
    <value>OVAL</value>
  </data>
  <data name="parametros_proxy" xml:space="preserve">
    <value>Anschlussparameter zum Proxy</value>
  </data>
  <data name="paso1" xml:space="preserve">
    <value>Schritt 1</value>
  </data>
  <data name="paso2" xml:space="preserve">
    <value>Schritt 2</value>
  </data>
  <data name="password" xml:space="preserve">
    <value>Passwort</value>
  </data>
  <data name="password_internet" xml:space="preserve">
    <value>Passwort Internet</value>
  </data>
  <data name="password_no_validado" xml:space="preserve">
    <value>Das aktuelle Passwort ist nicht bestätigt</value>
  </data>
  <data name="pedido" xml:space="preserve">
    <value>Bestellung</value>
  </data>
  <data name="pedidos_al_por_mayor" xml:space="preserve">
    <value>Großhandelsbestellungen</value>
  </data>
  <data name="pedidos_obtenidos" xml:space="preserve">
    <value>Erlangte Bestellung(en)</value>
  </data>
  <data name="pedidos_realizados" xml:space="preserve">
    <value>Getätigte Bestellungen</value>
  </data>
  <data name="pedido_con_errores" xml:space="preserve">
    <value>Die Bestellung konnte nicht abgesendet werden. Überprüfen Sie die Warnhinweise</value>
  </data>
  <data name="pedido_con_montura" xml:space="preserve">
    <value>Bestellung mit Fassung</value>
  </data>
  <data name="pedido_duplicado" xml:space="preserve">
    <value>BESTELLUNG DOPPELT</value>
  </data>
  <data name="pedir_montura" xml:space="preserve">
    <value>Fassung bestellen</value>
  </data>
  <data name="peso" xml:space="preserve">
    <value>Gewicht</value>
  </data>
  <data name="peticiones_talla" xml:space="preserve">
    <value>Schnittanforderungen</value>
  </data>
  <data name="plano" xml:space="preserve">
    <value>Plan</value>
  </data>
  <data name="portugal" xml:space="preserve">
    <value>Portugal</value>
  </data>
  <data name="por_favor_confirma_datos_pedido" xml:space="preserve">
    <value>Bitte bestätigen Sie die Daten Ihrer Bestellung:</value>
  </data>
  <data name="precal" xml:space="preserve">
    <value>VORKAL</value>
  </data>
  <data name="precalibrado" xml:space="preserve">
    <value>Vorkalibrierung</value>
  </data>
  <data name="precalibrado_a_cotas" xml:space="preserve">
    <value>Vorkalibrierung nach Maßzahlen</value>
  </data>
  <data name="precalibrado_digital" xml:space="preserve">
    <value>Digitale Vorkalibrierung</value>
  </data>
  <data name="precalibrado_digital_abr" xml:space="preserve">
    <value>Vorkal.</value>
  </data>
  <data name="precio" xml:space="preserve">
    <value>Preis</value>
  </data>
  <data name="PreguntaElegirOjo" xml:space="preserve">
    <value>Welchem Auge entspricht die erlangte Form?</value>
  </data>
  <data name="pregunta_borrar_pedido" xml:space="preserve">
    <value>Wollen Sie den Auftrag mit OT {0} wirklich stornieren?</value>
  </data>
  <data name="pregunta_montura_y_lentes_biseladas" xml:space="preserve">
    <value>Gestell und geschliffene Gläser</value>
  </data>
  <data name="pregunta_montura_y_lentes_montadas" xml:space="preserve">
    <value>Gestell und montierte Gläser</value>
  </data>
  <data name="pregunta_montura_y_lentes_sin_biselar" xml:space="preserve">
    <value>Gestell und ungeschliffene Gläser (Glas zugehörig zum bestellten Gestell)</value>
  </data>
  <data name="pregunta_solo_lentes_biseladas" xml:space="preserve">
    <value>Nur geschliffene Gläser</value>
  </data>
  <data name="pregunta_solo_lentes_sin_biselar" xml:space="preserve">
    <value>Nur ungeschliffene Gläser</value>
  </data>
  <data name="prevision" xml:space="preserve">
    <value>Prognose</value>
  </data>
  <data name="prisma" xml:space="preserve">
    <value>Prisma</value>
  </data>
  <data name="prisma_1" xml:space="preserve">
    <value>Prisma 1</value>
  </data>
  <data name="prisma_2" xml:space="preserve">
    <value>Prisma 2</value>
  </data>
  <data name="productos_y_credito" xml:space="preserve">
    <value>Produkte und Guthaben</value>
  </data>
  <data name="producto_lente" xml:space="preserve">
    <value>Produkt (Glas)</value>
  </data>
  <data name="prod_desde" xml:space="preserve">
    <value>PROD VON</value>
  </data>
  <data name="prod_hasta" xml:space="preserve">
    <value>PROD BIS</value>
  </data>
  <data name="puente" xml:space="preserve">
    <value>Steg</value>
  </data>
  <data name="puntual" xml:space="preserve">
    <value>Pünktlich</value>
  </data>
  <data name="quitar_seleccion" xml:space="preserve">
    <value>Auswahl entfernen</value>
  </data>
  <data name="radio" xml:space="preserve">
    <value>Radius</value>
  </data>
  <data name="ranurada" xml:space="preserve">
    <value>Gerillt</value>
  </data>
  <data name="ranurada_metal" xml:space="preserve">
    <value>Gerillt Metall</value>
  </data>
  <data name="ranurada_nylon" xml:space="preserve">
    <value>Gerillt Nylon</value>
  </data>
  <data name="realizar_pedido" xml:space="preserve">
    <value>Bestellung durchführen</value>
  </data>
  <data name="recargar" xml:space="preserve">
    <value>Neu laden</value>
  </data>
  <data name="receta" xml:space="preserve">
    <value>Rezept gespeichert</value>
  </data>
  <data name="recetas_borrado" xml:space="preserve">
    <value>Löschen von Rezepten</value>
  </data>
  <data name="recetas_borrar" xml:space="preserve">
    <value>Rezepte löschen</value>
  </data>
  <data name="recetas_enviar" xml:space="preserve">
    <value>Rezepte absenden</value>
  </data>
  <data name="recetas_envio" xml:space="preserve">
    <value>Rezeptsendung</value>
  </data>
  <data name="recetas_guardadas" xml:space="preserve">
    <value>Gespeicherte Rezepte</value>
  </data>
  <data name="recetas_obtenidas" xml:space="preserve">
    <value>Erlangte(s) Rezept(e)</value>
  </data>
  <data name="receta_envio_masivo" xml:space="preserve">
    <value>Bestellung generiert</value>
  </data>
  <data name="receta_guardada" xml:space="preserve">
    <value>Das Rezept wurde korrekt gespeichert</value>
  </data>
  <data name="recordar_mis_credenciales" xml:space="preserve">
    <value>Meine Zugangsdaten speichern</value>
  </data>
  <data name="redimensionar" xml:space="preserve">
    <value>Neu dimensionieren</value>
  </data>
  <data name="referencia" xml:space="preserve">
    <value>Bestellnummer </value>
  </data>
  <data name="ref_cliente" xml:space="preserve">
    <value>Kundennummer</value>
  </data>
  <data name="registros" xml:space="preserve">
    <value>Register</value>
  </data>
  <data name="reiniciar_fecha_oferta" xml:space="preserve">
    <value>Angebot neu laden</value>
  </data>
  <data name="reiniciar_fecha_oferta1" xml:space="preserve">
    <value>Angebot neu laden</value>
  </data>
  <data name="resto_de_paises" xml:space="preserve">
    <value>Übrige Länder</value>
  </data>
  <data name="rx" xml:space="preserve">
    <value>RX</value>
  </data>
  <data name="salir" xml:space="preserve">
    <value>Abmelden</value>
  </data>
  <data name="seguimiento" xml:space="preserve">
    <value>Nachbearbeitung</value>
  </data>
  <data name="segunda_gafa" xml:space="preserve">
    <value>ZWEITE BRILLE</value>
  </data>
  <data name="segura" xml:space="preserve">
    <value>SICHER</value>
  </data>
  <data name="seguro_que_quiere_desconectarse" xml:space="preserve">
    <value>Wollen Sie sich wirklich abmelden?</value>
  </data>
  <data name="seguro_que_quiere_salir" xml:space="preserve">
    <value>Wollen Sie das Programm wirklich verlassen?</value>
  </data>
  <data name="selecciona" xml:space="preserve">
    <value>Wählen Sie</value>
  </data>
  <data name="seleccionar_todos" xml:space="preserve">
    <value>Alle auswählen</value>
  </data>
  <data name="selecciona_abrv" xml:space="preserve">
    <value>Ausw.</value>
  </data>
  <data name="selecciona_cliente" xml:space="preserve">
    <value>Wählen Sie den Kunden</value>
  </data>
  <data name="selecciona_la_lente_deseada" xml:space="preserve">
    <value>Wählen Sie das gewünschte Glas</value>
  </data>
  <data name="seleccion_de_espesores" xml:space="preserve">
    <value>Stärkenauswahl</value>
  </data>
  <data name="seleccion_vacia_recetas" xml:space="preserve">
    <value>Sie haben kein Rezept ausgewählt</value>
  </data>
  <data name="SelEscaner" xml:space="preserve">
    <value>Scanner auswählen</value>
  </data>
  <data name="semitermin" xml:space="preserve">
    <value>HALBFERTIG</value>
  </data>
  <data name="semiterminados" xml:space="preserve">
    <value>Halbfertige Produkte</value>
  </data>
  <data name="sesion_calculo_no_valida" xml:space="preserve">
    <value>Berechnungssitzung ungültig</value>
  </data>
  <data name="sesion_ha_caducado" xml:space="preserve">
    <value>Sitzung abgelaufen. Verlassen Sie die Sitzung und starten Sie Indonet erneut.</value>
  </data>
  <data name="sesion_no_valida" xml:space="preserve">
    <value>Sitzung ungültig</value>
  </data>
  <data name="setup_laboratorios" xml:space="preserve">
    <value>Laboreinstellungen</value>
  </data>
  <data name="se_actualizo" xml:space="preserve">
    <value>Wurde aktualisiert</value>
  </data>
  <data name="siguiente" xml:space="preserve">
    <value>Nächste/r/s</value>
  </data>
  <data name="simulador_de_lentes" xml:space="preserve">
    <value>Glassimulator</value>
  </data>
  <data name="simulador_error" xml:space="preserve">
    <value>Fehler beim Starten des Glassimulators</value>
  </data>
  <data name="simulador_no_instalado" xml:space="preserve">
    <value>Der Glassimulator ist nicht installiert</value>
  </data>
  <data name="sin_coloracion" xml:space="preserve">
    <value>Ohne Tönung</value>
  </data>
  <data name="sin_ficheros_adjuntos" xml:space="preserve">
    <value>Ohne Anhänge</value>
  </data>
  <data name="sin_fotocromatico" xml:space="preserve">
    <value>Ohne photochrome Eigenschaften</value>
  </data>
  <data name="sin_observaciones" xml:space="preserve">
    <value>Ohne Anmerkungen</value>
  </data>
  <data name="sin_precalibrado" xml:space="preserve">
    <value>Ohne Vorkalibrierung</value>
  </data>
  <data name="sin_prisma" xml:space="preserve">
    <value>Nicht prismatisch</value>
  </data>
  <data name="sistema_pedidos_por_mayor" xml:space="preserve">
    <value>Großhandelsbestellungssystem</value>
  </data>
  <data name="situacion_de_los_pedidos" xml:space="preserve">
    <value>Status der Bestellungen</value>
  </data>
  <data name="solicitar_acceso" xml:space="preserve">
    <value>Zugriff beantragen</value>
  </data>
  <data name="solicitar_clave_acceso" xml:space="preserve">
    <value>» Zugangscode anfordern</value>
  </data>
  <data name="solicitud_acceso" xml:space="preserve">
    <value>Zugangsantrag</value>
  </data>
  <data name="solicitud_enviada_correctamente" xml:space="preserve">
    <value>Der Zugangsantrag wurde korrekt versendet</value>
  </data>
  <data name="solo_stock" xml:space="preserve">
    <value>Nur Bestand</value>
  </data>
  <data name="stock" xml:space="preserve">
    <value>Bestand</value>
  </data>
  <data name="subir_imagenes" xml:space="preserve">
    <value>Abbildungen laden</value>
  </data>
  <data name="sucursal" xml:space="preserve">
    <value>FILIALE</value>
  </data>
  <data name="sugerencias" xml:space="preserve">
    <value>Vorschläge</value>
  </data>
  <data name="superior" xml:space="preserve">
    <value>Obere/r</value>
  </data>
  <data name="suplementos" xml:space="preserve">
    <value>Ergänzungen</value>
  </data>
  <data name="taladrada" xml:space="preserve">
    <value>Mit Bohrloch</value>
  </data>
  <data name="talla" xml:space="preserve">
    <value>Schnitt</value>
  </data>
  <data name="tema" xml:space="preserve">
    <value>Betreff</value>
  </data>
  <data name="temporal" xml:space="preserve">
    <value>Temporal</value>
  </data>
  <data name="texto" xml:space="preserve">
    <value>Text</value>
  </data>
  <data name="texto_direcciones_email" xml:space="preserve">
    <value>Adressen, wo Sie diese Informationen empfangen wollen</value>
  </data>
  <data name="texto_pedidos_en_curso" xml:space="preserve">
    <value>Laufende Bestellungen (1 E-Mail pro Tag)</value>
  </data>
  <data name="texto_pedidos_en_envio" xml:space="preserve">
    <value>In jeder Sendung zu erhaltende Bestellungen (1 E-Mail pro Sendung)</value>
  </data>
  <data name="texto_presentacion1" xml:space="preserve">
    <value>Willkommen bei der neuen Generation von Online-Bestellungen</value>
  </data>
  <data name="texto_presentacion2" xml:space="preserve">
    <value>Hier startet der Weg der neuen Indo!</value>
  </data>
  <data name="texto_presentacion3" xml:space="preserve">
    <value>Lassen Sie sich von dem flinksten und intuitivsten Werkzeug auf dem Markt überraschen, um Bestellungen zu tätigen. Mit neuen Funktionen, die es Ihnen ermöglichen, den Mehrwert Ihrer Verkäufe zu erhöhen.</value>
  </data>
  <data name="texto_presentacion4" xml:space="preserve">
    <value>Individualisierung der Stärken und automatische Optimierung derselben bei Herstellungsgläsern.</value>
  </data>
  <data name="texto_presentacion5" xml:space="preserve">
    <value>Kompatibel mit den meisten erhältlichen Planzeichnern.</value>
  </data>
  <data name="texto_presentacion6" xml:space="preserve">
    <value>Detaillierte Verfolgung Ihrer Bestellungen</value>
  </data>
  <data name="texto_presentacion7" xml:space="preserve">
    <value>Fragen Sie Ihren Vertriebspartner nach der am besten geeigneten Option, um alle Ihre Bedürfnisse abzudecken</value>
  </data>
  <data name="texto_si_deseas_recibir_mails" xml:space="preserve">
    <value>Falls Sie E-Mail-Nachrichten mit Informationen zu Ihren Bestellungen wünschen, wählen Sie eine der folgenden Optionen:</value>
  </data>
  <data name="text_info_contacto" xml:space="preserve">
    <value>Falls Sie weitere Informationen benötigen, kontaktieren Sie uns unter:</value>
  </data>
  <data name="tipo_bisel" xml:space="preserve">
    <value>Schlifftyp</value>
  </data>
  <data name="tipo_de_bisel" xml:space="preserve">
    <value>Schlifftyp</value>
  </data>
  <data name="tipo_de_montura" xml:space="preserve">
    <value>Gestelltyp</value>
  </data>
  <data name="tipo_diagnostico" xml:space="preserve">
    <value>Diagnosetyp</value>
  </data>
  <data name="tipo_lente" xml:space="preserve">
    <value>Glastyp</value>
  </data>
  <data name="tipo_montura" xml:space="preserve">
    <value>Gestelltyp</value>
  </data>
  <data name="tipo_servicio" xml:space="preserve">
    <value>Servicetyp</value>
  </data>
  <data name="titulo_reposicion_color" xml:space="preserve">
    <value>MELDUNG FARBERSATZ</value>
  </data>
  <data name="toma_medicacion" xml:space="preserve">
    <value>Nimmt Medikamente</value>
  </data>
  <data name="tono" xml:space="preserve">
    <value>Farbton</value>
  </data>
  <data name="toolTip_abrir_indoscan" xml:space="preserve">
    <value>INDOScan öffnen</value>
  </data>
  <data name="tooltip_anadir_montura_en_tracer" xml:space="preserve">
    <value>In dieser Bestellung gewähltes Gestell</value>
  </data>
  <data name="tooltip_biselar_por_defecto" xml:space="preserve">
    <value>Mit dieser Auswahl wird bei einer Bestellung mit Form automatisch Glas schleifen markiert. Diese Option kann jederzeit rückgängig gemacht werden.</value>
  </data>
  <data name="tooltip_biselar_sin_pulir" xml:space="preserve">
    <value>Für Gestelle aus Acetat und Metall wird stets ohne Polieren geschliffen</value>
  </data>
  <data name="tooltip_boton_anadir_montura" xml:space="preserve">
    <value>Ein Gestell für diese Bestellung auswählen</value>
  </data>
  <data name="tooltip_boton_importar" xml:space="preserve">
    <value>Form aus einer Datei importieren</value>
  </data>
  <data name="tooltip_boton_iniciar" xml:space="preserve">
    <value>Zeigt alle Lesungen von INDOBISEL an</value>
  </data>
  <data name="tooltip_boton_ultima" xml:space="preserve">
    <value>Letzte in Indobisel gelesene Form wird geladen</value>
  </data>
  <data name="tratamientos" xml:space="preserve">
    <value>Behandlungen</value>
  </data>
  <data name="tratamiento_coloracion" xml:space="preserve">
    <value>Behandlung und Tönung</value>
  </data>
  <data name="trazador" xml:space="preserve">
    <value>Planzeichner</value>
  </data>
  <data name="trazadores" xml:space="preserve">
    <value>Planzeichner</value>
  </data>
  <data name="trazadores_anadir" xml:space="preserve">
    <value>Hinzufügen ...</value>
  </data>
  <data name="trazadores_borrar" xml:space="preserve">
    <value>Löschen</value>
  </data>
  <data name="trazadores_borrar_todos" xml:space="preserve">
    <value>Alle löschen</value>
  </data>
  <data name="trazadores_comprobacion" xml:space="preserve">
    <value>Überprüfung</value>
  </data>
  <data name="trazadores_conexion_agente_trazador_OK" xml:space="preserve">
    <value>DER PLANZEICHNER {0} IST KORREKT ANGESCHLOSSEN</value>
  </data>
  <data name="trazadores_direccion" xml:space="preserve">
    <value>Adresse</value>
  </data>
  <data name="trazadores_modificar" xml:space="preserve">
    <value>Ändern</value>
  </data>
  <data name="trazadores_nombre_agente" xml:space="preserve">
    <value>Name des Agenten:</value>
  </data>
  <data name="trazadores_popup_anular" xml:space="preserve">
    <value>Stornieren</value>
  </data>
  <data name="trazadores_popup_titulo" xml:space="preserve">
    <value>Auswahl / Änderung Planzeichner</value>
  </data>
  <data name="trazadores_puerto" xml:space="preserve">
    <value>Schnittstelle</value>
  </data>
  <data name="trazadores_trazador" xml:space="preserve">
    <value>Planzeichner</value>
  </data>
  <data name="trazador_busqueda_ordenadores_red" xml:space="preserve">
    <value>Gerät im Netzwerk auswählen</value>
  </data>
  <data name="tto_adicional" xml:space="preserve">
    <value>Zusatz behandlung</value>
  </data>
  <data name="tutoriales" xml:space="preserve">
    <value>Anleitungen</value>
  </data>
  <data name="t_por_ciento" xml:space="preserve">
    <value>T%</value>
  </data>
  <data name="ultimos_pedidos" xml:space="preserve">
    <value>Letzte Bestellungen</value>
  </data>
  <data name="upload" xml:space="preserve">
    <value>Upload</value>
  </data>
  <data name="urgente" xml:space="preserve">
    <value>Dringend (24 Std.)</value>
  </data>
  <data name="url_contacto" xml:space="preserve">
    <value>http://www.indobox-indo.com/front/incidencias</value>
  </data>
  <data name="url_contacto_indobox" xml:space="preserve">
    <value>www.indobox-indo.com</value>
  </data>
  <data name="url_indonet_direct" xml:space="preserve">
    <value>http://pedidos.direc.indo.es/</value>
  </data>
  <data name="url_indo_en_el_mundo" xml:space="preserve">
    <value>http://www.indo.es/indo-en-el-mundo/indo_enelmundo.asp</value>
  </data>
  <data name="url_mas_informacion" xml:space="preserve">
    <value>http://www.indo.es/lentes/lentes_lentesindo.asp</value>
  </data>
  <data name="url_tutoriales" xml:space="preserve">
    <value>http://www.indo.es/de/optics/tutorials</value>
  </data>
  <data name="usuario" xml:space="preserve">
    <value>Benutzer</value>
  </data>
  <data name="usuario_internet" xml:space="preserve">
    <value>Internetbenutzer</value>
  </data>
  <data name="usuario_o_clave_no_correctos" xml:space="preserve">
    <value>Benutzer oder Passwort falsch</value>
  </data>
  <data name="valores_por_defecto" xml:space="preserve">
    <value>Werkseinstellung</value>
  </data>
  <data name="valor_fuera_de_margenes" xml:space="preserve">
    <value>Wert außerhalb der Grenzen für {2}  Zulässige Werte zwischen {0} und {1}.</value>
  </data>
  <data name="ventana_espera_actualizar_pedido" xml:space="preserve">
    <value>BESTELLUNG WIRD AKTUALISIERT</value>
  </data>
  <data name="ventana_espera_areaventa" xml:space="preserve">
    <value>VERKAUFSBEREICH WIRD GELADEN</value>
  </data>
  <data name="ventana_espera_borrar_forma" xml:space="preserve">
    <value>FORM WIRD GELÖSCHT</value>
  </data>
  <data name="ventana_espera_borrar_pedidos" xml:space="preserve">
    <value>BESTELLUNG {0} WIRD GELÖSCHT</value>
  </data>
  <data name="ventana_espera_borrar_recetas" xml:space="preserve">
    <value>REZEPTE WERDEN GELÖSCHT</value>
  </data>
  <data name="ventana_espera_busqueda_pedidos" xml:space="preserve">
    <value>BESTELLUNGEN WERDEN GESUCHT</value>
  </data>
  <data name="ventana_espera_busqueda_recetas" xml:space="preserve">
    <value>REZEPTE WERDEN GESUCHT</value>
  </data>
  <data name="ventana_espera_cambio_clave" xml:space="preserve">
    <value>NEUER ZUGANGSCODE WIRD GESPEICHERT</value>
  </data>
  <data name="ventana_espera_cargando_clientes" xml:space="preserve">
    <value>KUNDEN WERDEN ERNEUT AUFGERUFEN</value>
  </data>
  <data name="ventana_espera_carga_pedido" xml:space="preserve">
    <value>BESTELLUNG {0} WIRD GELADEN</value>
  </data>
  <data name="ventana_espera_carga_pedidos_inicial" xml:space="preserve">
    <value>ANFÄNGLICHE BESTELLUNGSLISTE WIRD GELADEN</value>
  </data>
  <data name="ventana_espera_carga_receta" xml:space="preserve">
    <value>REZEPT {0} WIRD GELADEN</value>
  </data>
  <data name="ventana_espera_comparando_lentes" xml:space="preserve">
    <value>GLÄSER WERDEN VERGLICHEN</value>
  </data>
  <data name="ventana_espera_configuracion" xml:space="preserve">
    <value>KONFIGURIERUNG WIRD GELADEN</value>
  </data>
  <data name="ventana_espera_consulta_anulable" xml:space="preserve">
    <value>Anfrage, ob die Bestellung {0} stornierbar ist</value>
  </data>
  <data name="ventana_espera_destinatarios" xml:space="preserve">
    <value>EMPFÄNGER WERDEN GELADEN</value>
  </data>
  <data name="ventana_espera_envio_correo" xml:space="preserve">
    <value>E-MAIL WIRD GESENDET</value>
  </data>
  <data name="ventana_espera_envio_pedidos" xml:space="preserve">
    <value>BESTELLUNG WIRD GESENDET</value>
  </data>
  <data name="ventana_espera_envio_recetas" xml:space="preserve">
    <value>REZEPTE WERDEN GESENDET</value>
  </data>
  <data name="ventana_espera_esperar" xml:space="preserve">
    <value>Warten...</value>
  </data>
  <data name="ventana_espera_guardar_configuracion" xml:space="preserve">
    <value>KONFIGURIERUNG WIRD GESPEICHERT</value>
  </data>
  <data name="ventana_espera_guardar_datos" xml:space="preserve">
    <value>DATEN WERDEN GESPEICHERT</value>
  </data>
  <data name="ventana_espera_guardar_datos_optica" xml:space="preserve">
    <value>DATEN DES OPTIKERGESCHÄFTS WERDEN GESPEICHERT</value>
  </data>
  <data name="ventana_espera_guardar_receta" xml:space="preserve">
    <value>REZEPT {0} WIRD GESPEICHERT</value>
  </data>
  <data name="ventana_espera_lecturas_trazador" xml:space="preserve">
    <value>LESUNGEN PLANZEICHNER {0} {1} </value>
  </data>
  <data name="ventana_espera_lecturas_trazadores" xml:space="preserve">
    <value>LESUNG PLANZEICHNER {0} </value>
  </data>
  <data name="ventana_espera_lectura_trazador" xml:space="preserve">
    <value>LESUNG {0}</value>
  </data>
  <data name="ventana_espera_login" xml:space="preserve">
    <value>AUTHENTIFIZIERUNG KUNDE {0}</value>
  </data>
  <data name="ventana_espera_misbiselados" xml:space="preserve">
    <value>SCHLIFFE WERDEN GELADEN</value>
  </data>
  <data name="ventana_espera_monturas" xml:space="preserve">
    <value>GESTELLE WERDEN GELADEN</value>
  </data>
  <data name="ventana_espera_obteniendo_bases" xml:space="preserve">
    <value>DATENBANKEN WERDEN ERNEUT AUFGERUFEN</value>
  </data>
  <data name="ventana_espera_oferta" xml:space="preserve">
    <value>ANGEBOT WIRD AKTUALISIERT</value>
  </data>
  <data name="ventana_espera_recuperar_credito" xml:space="preserve">
    <value>GUTHABEN WIRD ERNEUT AUFGERUFEN</value>
  </data>
  <data name="ventana_espera_recuperar_datos" xml:space="preserve">
    <value>DATEN WERDEN ERNEUT AUFGERUFEN</value>
  </data>
  <data name="ventana_espera_recuperar_fabricantes" xml:space="preserve">
    <value>HERSTELLER WERDEN ERNEUT AUFGERUFEN</value>
  </data>
  <data name="ventana_espera_recuperar_familias" xml:space="preserve">
    <value>FAMILIEN WERDEN ERNEUT AUFGERUFEN</value>
  </data>
  <data name="ventana_espera_recuperar_semiterminados" xml:space="preserve">
    <value>HALBFERTIGE PRODUKTE WERDEN ERNEUT AUFGERUFEN</value>
  </data>
  <data name="ventana_espera_revision_pedidos" xml:space="preserve">
    <value>WIRD ZUR REVISION GESENDET</value>
  </data>
  <data name="ventana_espera_seleccion_lente" xml:space="preserve">
    <value>GLAS WIRD AUSGEWÄHLT</value>
  </data>
  <data name="ventana_espera_verifica_conexion_trazador" xml:space="preserve">
    <value>ÜBERPRÜFEN SIE DIE VERBINDUNG ZUM PLANZEICHNER {0}</value>
  </data>
  <data name="verifique_conexion" xml:space="preserve">
    <value>Überprüfen Sie den Internetzugang</value>
  </data>
  <data name="visualizar" xml:space="preserve">
    <value>Anzeigen</value>
  </data>
  <data name="VolverAObtenerDeEscaner" xml:space="preserve">
    <value>Erneut vom Scanner anfordern</value>
  </data>
  <data name="volver_al_listado" xml:space="preserve">
    <value>Zurück zur Liste</value>
  </data>
  <data name="zona_de_identificacion" xml:space="preserve">
    <value>Identifizierungsbereich</value>
  </data>
  <data name="aviso_espera" xml:space="preserve">
    <value>Wait</value>
  </data>
  <data name="aviso_desactivar_isotipo" xml:space="preserve">
    <value>Orders without shape or with a basic shape cannot ask the logo. Deactivate the logo</value>
  </data>
  <data name="aviso_si_quiere_recogida_llamar_ATC" xml:space="preserve">
    <value>Should you need a pick up please call our Customer Service</value>
  </data>
  <data name="aviso_mas_365_dias" xml:space="preserve">
    <value>Das Datumsintervall muss geringer als 365 Tage sein</value>
  </data>
  <data name="aviso_eliminacion_boton_igual_suplementos" xml:space="preserve">
    <value>In order to avoid mistakes we have removed the button to equalize the supplements in both eyes. Now you must enter the values you want for each eye.</value>
  </data>
  <data name="entendido" xml:space="preserve">
    <value>OK</value>
  </data>
  <data name="aviso_cambio_contraseña_indonet" xml:space="preserve">
    <value>To improve the security and confidentiality of our clients, we recommend to you to modify and to personalize your password access to this platform. You will be able to do it from the eyelash of configuration that will be opened later.</value>
  </data>
  <data name="aviso_debe_rellenar_todos_los_campos" xml:space="preserve">
    <value>You have to fill out all the fields</value>
  </data>
  <data name="aviso_nueva_clave_no_es_indonet" xml:space="preserve">
    <value>The new password cannot be « INDONET »</value>
  </data>
  <data name="notificacion" xml:space="preserve">
    <value>Notification</value>
  </data>
  <data name="alta_buscador" xml:space="preserve">
    <value>Search/Modification web browser</value>
  </data>
  <data name="anular_cambios" xml:space="preserve">
    <value>Cancel the changes made</value>
  </data>
  <data name="audiologia" xml:space="preserve">
    <value>Audiology</value>
  </data>
  <data name="aviso_eliminar_buscador_web" xml:space="preserve">
    <value>You are about to delete your data from our web search engine. By accepting it, you will stop appearing in the possible searches made by the users. Do you want to continue?</value>
  </data>
  <data name="baja_buscador" xml:space="preserve">
    <value>Unsubscribe on web browser</value>
  </data>
  <data name="baja_vision" xml:space="preserve">
    <value>Low vision</value>
  </data>
  <data name="buscador_web" xml:space="preserve">
    <value>Web browser</value>
  </data>
  <data name="de" xml:space="preserve">
    <value>to</value>
  </data>
  <data name="horario_comercial" xml:space="preserve">
    <value>Opening hours</value>
  </data>
  <data name="optometria" xml:space="preserve">
    <value>Optometry</value>
  </data>
  <data name="rellenar_con_datos_por_defecto" xml:space="preserve">
    <value>Fill with default database</value>
  </data>
  <data name="taller_de_montaje" xml:space="preserve">
    <value>Assembly shop</value>
  </data>
  <data name="terapia_visual" xml:space="preserve">
    <value>Visual therapy</value>
  </data>
  <data name="texto_autorizo_buscar_web" xml:space="preserve">
    <value>I authorize INDO OPTICAL SLU to store these data and keep them in its databases whose use is exclusively intended to appear in its optical search, in order to communicate with its customers</value>
  </data>
  <data name="venta_online" xml:space="preserve">
    <value>Online sales</value>
  </data>
  <data name="y_de" xml:space="preserve">
    <value>from</value>
  </data>
  <data name="informacion_contacto" xml:space="preserve">
    <value>Contact information</value>
  </data>
  <data name="informacion_optica" xml:space="preserve">
    <value>Optical shop information</value>
  </data>
  <data name="mensaje_entrada_buscador" xml:space="preserve">
    <value>Configure your data here to appear in the optical search of www.indo.es</value>
  </data>
  <data name="tooltip_boton_copiar" xml:space="preserve">
    <value>Copy from Monday to Saturday.</value>
  </data>
  <data name="ha_escogido_esta_devolucion" xml:space="preserve">
    <value>You have chosen this return</value>
  </data>
  <data name="configuracion_hojaPedidoIndoscan" xml:space="preserve">
    <value>Blatt IndoScan</value>
  </data>
  <data name="configuracion_link_hojaPedidosFax" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/Indonet/Enlaces/HojaPedidosFax_DE.pdf</value>
  </data>
  <data name="aviso_texto_no_copiar_pedido" xml:space="preserve">
    <value>IMPORTANT
The product you are requesting may have changed its offer from the date of the original order. Please check all fields and treatments to make sure the lenses you select are correct.</value>
  </data>
  <data name="texto_importante_devoluciones" xml:space="preserve">
    <value>IMPORTANT: To avoid damages to the lenses and facilitate their analysis, they should be received in an appropriate and separate packaging, without elements that could damage them such as staples, cards, etc.</value>
  </data>
  <data name="facturacion" xml:space="preserve">
    <value>Invoices and delivery notes</value>
  </data>
  <data name="no_albaran" xml:space="preserve">
    <value>Delivery Note</value>
  </data>
  <data name="no_factura" xml:space="preserve">
    <value>Invoice number</value>
  </data>
  <data name="url_ayuda_inset" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/ayudaIndonet/inset_DE.html</value>
  </data>
  <data name="ver_albaran" xml:space="preserve">
    <value>Download Delivery Note</value>
  </data>
  <data name="consulta_disponibilidad" xml:space="preserve">
    <value>Check availability</value>
  </data>
  <data name="marca_opcion_deseada" xml:space="preserve">
    <value>Check the desired option</value>
  </data>
  <data name="url_condiciones_indomedcare_new" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/ayudaIndonet/condiciones_indomedcare_DE.txt</value>
  </data>
  <data name="url_condiciones_indonet_new" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/ayudaIndonet/condiciones_indonet_DE.txt</value>
  </data>
  <data name="crear_devolucion" xml:space="preserve">
    <value>Create Return</value>
  </data>
  <data name="url_ayuda_pedidorapidoclasico" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/ayudaIndonet/pedido_rapidoclasico_EN.txt</value>
  </data>
  <data name="degradado_alto" xml:space="preserve">
    <value>High gradient - 5mm</value>
  </data>
  <data name="degradado_bajo" xml:space="preserve">
    <value>Low gradient + 5mm</value>
  </data>
  <data name="texto_ex_bono" xml:space="preserve">
    <value>The requested treatment is excluded from bonuses and has an added cost of {0} per lens</value>
  </data>
  <data name="pedido_urgente" xml:space="preserve">
    <value>Urgent order</value>
  </data>
  <data name="solicitar_devolucion_rodenstock" xml:space="preserve">
    <value>Activar Garantía Especial Rodenstock</value>
  </data>
  <data name="aviso_pedir_devolucionRD" xml:space="preserve">
    <value>Solicitar Garantía Especial Rodenstock</value>
  </data>
  <data name="texto_activar_bonificacion_rodenstock_checkbox" xml:space="preserve">
    <value>Activar Garantía Especial Rodenstock</value>
  </data>
  <data name="seguro_actualizar_pedido" xml:space="preserve">
    <value>Diese Berechnung ist für den Versand der Bestellung nicht erforderlich und kann einige Zeit in Anspruch nehmen.
Sind Sie sicher, dass Sie fortfahren?</value>
  </data>
  <data name="rodenstockmanager" xml:space="preserve">
    <value>RodenstockManager</value>
  </data>
  <data name="bienvenido_a_rodenstockmanager" xml:space="preserve">
    <value>Willkommen beim Rodenstock Manager</value>
  </data>
  <data name="url_fb_rodenstock" xml:space="preserve">
    <value>https://www.facebook.com/RodenstockES/</value>
  </data>
  <data name="url_in_rodenstock" xml:space="preserve">
    <value>https://www.instagram.com/rodenstock_es/</value>
  </data>
  <data name="url_indoloyalty" xml:space="preserve">
    <value>https://indoloyalty.com</value>
  </data>
</root>