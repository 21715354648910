import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

import LoginView from "../views/LoginView.vue";

import RememberPasswordView from "../views/password/RememberPasswordView.vue";
import ReviewPasswordView from "../views/password/ReviewPasswordView.vue";
import NewPasswordView from "../views/password/NewPasswordView.vue";

/**
 * TODO: Implementar 404 https://v3.router.vuejs.org/guide/essentials/history-mode.html#example-server-configurations
 *
 * TODO: Crear childrens: https://v3.router.vuejs.org/guide/essentials/nested-routes.html
 */

const routes = [
  {
    path: "/",
    name: "home",
    redirect: "/dashboard",
    meta: { guest: true },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: { guest: true },
  },
  {
    path: "/remember-password",
    name: "remember-password",
    component: RememberPasswordView,
    meta: { guest: true },
  },

  {
    path: "/new-password",
    name: "new-password",
    component: NewPasswordView,
    meta: { guest: true },
  },

  {
    path: "/review-password",
    name: "review-password",
    component: ReviewPasswordView,
    meta: { guest: true },
  },

  {
    path: "/dashboard",
    name: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/DashboardView.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/lentes",
    name: "lentes",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/LensesView.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/LogoutView.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/notification",
    name: "notification",
    component: () => import("../views/NotificationView.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/marketing/pedir-material",
    name: "marketing/pedir-material",
    component: () => import("../views/marketing/MarketingOrderView.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/marketing/checkout",
    name: "marketing/checkout",
    component: () => import("../views/marketing/CheckoutView.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/marketing",
    name: "marketing",
    // Redirect to the new themed marketing route
    redirect: "/theme/indo/marketing",
    meta: { requiresAuth: true },
  },

  {
    path: "/reparacion",
    name: "reparacion",
    component: () => import("../views/RepareView.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/consumibles",
    name: "consumibles",
    component: () => import("../views/ConsumablesView.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/bienes-de-equipo",
    name: "bienes-de-equipo",
    component: () => import("../views/CapitalGoods.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/notification/detail/:noticia",
    name: "notification-detail",
    props: true,
    component: () => import("../views/notification/NotificationDetailView.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/indomanager",
    name: "facturacion",
    // Redirect to the new themed billing route
    redirect: "/theme/indo/facturacion/facturas-y-albaranes",
    meta: { requiresAuth: true },
  },

  {
    path: "/faqs",
    name: "faqs",
    component: () => import("../views/FaqsView.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/pedir-lentes/datos-basicos",
    name: "pedir-lentes",
    component: () => import("../views/orderLenses/OrderLensesView.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/pedir-lentes/formas-espesores",
    name: "formas-espesores",
    component: () => import("../views/orderLenses/FormsThicknessesView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/pedir-lentes/enviar-pedido",
    name: "enviar-pedido",
    component: () => import("../views/orderLenses/SendOrderView.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/pedir-lentes/pedido-enviado",
    name: "pedido-enviado",
    component: () => import("../views/orderLenses/OrderSendedView.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/formacion",
    name: "formacion",
    // Redirect to the new themed formation route
    redirect: "/theme/indo/formacion",
    meta: { requiresAuth: true },
  },

  {
    path: "/lentes/pedidos",
    name: "pedidos",
    component: () => import("../views/lenses/OrdersView.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/lentes/devoluciones",
    name: "devoluciones",
    component: () => import("../views/lenses/ReturnsView.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/lentes/pedidos/analizar",
    name: "analizar",
    component: () => import("../views/lenses/LensesAnalize.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/lentes/recetas-guardadas",
    name: "recetas-guardadas",
    component: () => import("../views/lenses/RecipesView.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/indomanager/informacion-fiscal",
    name: "informacion-fiscal",
    component: () => import("../views/facturation/FiscalInformationView.vue"),
    meta: { requiresAuth: true, requiresManager: true },
  },

  {
    path: "/indomanager/stats",
    name: "informacion-servicio",
    component: () => import("../views/facturation/ServiceInformationView.vue"),
    meta: { requiresAuth: true, requiresManager: true },
  },

  {
    path: "/indomanager/bonos",
    name: "bonos",
    component: () => import("../views/facturation/BonosView.vue"),
    meta: { requiresAuth: true, requiresManager: true },
  },

  {
    path: "/indomanager/acuerdos-comerciales",
    name: "acuerdos-comerciales",
    component: () => import("../views/facturation/CommercialsAgreements.vue"),
    meta: { requiresAuth: true, requiresManager: true },
  },

  {
    path: "/indomanager/facturas-y-albaranes",
    name: "facturas-y-albaranes",
    component: () => import("../views/facturation/InvoicesDeliveryNotesView.vue"),
    meta: { requiresAuth: true, requiresManager: true },
  },

  {
    path: "/indomanager/facturas-y-albaranes/abrir-consulta/:item",
    name: "abrir-consulta",
    props: { item: true },
    component: () => import("../views/facturation/ConsultingFormView.vue"),
    meta: { requiresAuth: true, requiresManager: true },
  },
  {
    path: "/myprofile",
    name: "myprofile",
    component: () => import("../views/MyProfileView.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/settings/optic-data",
    name: "optic-data",
    component: () => import("../views/settings/OpticDataView.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/settings/websearch",
    name: "websearch",
    component: () => import("../views/settings/WebsearchView.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/settings/mailbox",
    name: "mailbox",
    component: () => import("../views/settings/MailboxView.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/settings/calibration-monitor",
    name: "calibration-monitor",
    component: () => import("../views/settings/CalibrationMonitorView.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/settings/plotters",
    name: "plotters",
    component: () => import("../views/settings/PlottersView.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/settings/favorites",
    name: "favorites",
    component: () => import("../views/settings/FavoritesView.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/settings/configuration-indonet",
    name: "configuration-indonet",
    component: () => import("../views/settings/ConfigurationIndonetView.vue"),
    meta: { requiresAuth: true },
  },

  // Dev pourpouses only
  // TODO: Remove this routes when the project is finished
  {
    path: "/sandbox",
    meta: { requiresAuth: true },
    children: [
      { path: "", component: () => import("../views/sandbox/IndexView.vue") },
      { path: "icons", component: () => import("../views/sandbox/IconsView.vue") },
      { path: "theming", component: () => import("../views/sandbox/ThemingView.vue") },
      { path: "forms", component: () => import("../views/sandbox/FormsView.vue") },
      { path: "tables", component: () => import("../views/sandbox/TablesView.vue") },
      { path: "ui", component: () => import("../views/sandbox/UiView.vue") },
      { path: "cards", component: () => import("../views/sandbox/CardsView.vue") },
      { path: "banners", component: () => import("../views/sandbox/BannersView.vue") },
      { path: "charts", component: () => import("../views/sandbox/ChartsView.vue") },
      { path: "modal", component: () => import("../views/sandbox/ModalView.vue") },
      { path: "flow", component: () => import("../views/sandbox/FlowView.vue") },
    ],
  },

  /// Pages tree

  {
    path: "/theme",
    component: () => import("../pages/ThemeView.vue"),
    meta: { requiresAuth: true },
    props: true,
    children: [
      {
        path: "dashboard",
        name: "theme-dashboard",
        component: () => import("../pages/10-dashboard/DashboardView.vue"),
      },
      {
        path: "estado-pedidos",
        name: "theme-order-status",
        redirect: "/estado-pedidos/pedidos",
        component: () => import("../pages/20-order-status/OrdersStatusView.vue"),
        children: [
          {
            path: "pedidos",
            name: "theme-orders",
            component: () => import("../pages/20-order-status/10-orders/OrdersView.vue"),
          },
          {
            path: "recetas-guardadas",
            name: "theme-recipes",
            component: () => import("../pages/20-order-status/20-recipes/RecipesView.vue"),
          },
        ],
      },
      {
        path: "devoluciones",
        name: "theme-returns",
        component: () => import("../pages/30-returns/ReturnsView.vue"),
      },
      {
        path: "bienes-de-equipo",
        name: "theme-capital-goods",
        component: () => import("../pages/40-capital-goods/CapitalGoodsView.vue"),
      },
      {
        path: "notificaciones",
        children: [
          {
            path: "",
            name: "theme-notifications",
            component: () => import("../pages/50-notifications/NotificationsListView.vue"),
          },
          {
            path: ":notificationId",
            component: () => import("../pages/50-notifications/NotificationDetailView.vue"),
          },
        ],
      },
      {
        path: ":brand",
        beforeEnter: (to, from, next) =>
          next(["indo", "rodenstock"].includes(to.params.brand) ? true : { path: "/dashboard" }),
        children: [
          {
            path: "facturacion",
            name: "theme-billing",
            meta: { requiresBrandAuth: true },
            redirect: (to) => ({ 
              name: "theme-invoices-delivery-notes",
              params: { brand: to.params.brand }
            }),
            component: () => {
              const brandLoggedUser = localStorage.getItem("brandUser");
              if (brandLoggedUser) return import("../pages/60-billing/BillingView.vue");
              else return import("../pages/60-billing/00-login/BrandLoginView.vue");
            },
            children: [
              {
                path: "facturas-y-albaranes",
                name: "theme-invoices-delivery-notes",
                component: () =>
                  import(
                    "../pages/60-billing/10-invoices-delivery-notes/InvoicesDeliveryNotesView.vue"
                  ),
              },
              {
                path: "bonos",
                children: [
                  {
                    path: "",
                    name: "theme-bonus",
                    component: () =>
                      import("../pages/60-billing/20-bonus/10-bonus-list/BonusListView.vue"),
                  },
                  {
                    path: ":bonusId",
                    name: "theme-bonus-detail",
                    component: () =>
                      import("../pages/60-billing/20-bonus/20-bonus-detail/BonusDetailView.vue"),
                  },
                ],
              },
              {
                path: "acuerdos-comerciales",
                name: "theme-commercials-agreements",
                component: () =>
                  import("../pages/60-billing/30-commercials-agreements/CommercialsAgreements.vue"),
              },
              {
                path: "estadisticas",
                name: "theme-stats",
                component: () => import("../pages/60-billing/40-stats/StatsView.vue"),
              },
              {
                path: "informacion-fiscal",
                name: "theme-fiscal-information",
                component: () =>
                  import("../pages/60-billing/50-fiscal-information/FiscalInformationView.vue"),
              },
            ],
          },
          {
            path: "formacion",
            name: "theme-formation",
            component: () => import("../pages/70-formation/FormationView.vue"),
          },
          {
            path: "marketing",
            name: "theme-marketing",
            component: () => import("../pages/80-marketing/MarketingView.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/theme/pedir-lentes",
    component: () => import("../pages/90-order-lenses/OrderLensesView.vue"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        name: "theme-order-lenses",
        component: () => import("../pages/90-order-lenses/10-order-data/OrderDataView.vue"),
      },
      {
        path: "pedido-enviado",
        name: "theme-order-sended",
        component: () => import("../pages/90-order-lenses/20-order-sended/OrderSendedView.vue"),
        props: { brand: "rodenstock" },
        // props: { brand: "indo" },
      },
    ],
  },

  // {
  //   path: "/theme",
  //   component: () => import("../pages/parent/ParentView.vue"),
  //   meta: { requiresAuth: true },
  //   children: [
  //     {
  //       // UserProfile will be rendered inside User's <router-view>
  //       // when /user/:id/profile is matched
  //       path: "sub1",
  //       component: () => import("../pages/parent/Sub1View.vue"),
  //     },
  //     {
  //       // UserProfile will be rendered inside User's <router-view>
  //       // when /user/:id/profile is matched
  //       path: "sub1",
  //       component: () => import("../pages/parent/Sub1View.vue"),
  //     },
  //   ],
  // },
  {
    path: "/:pathMatch(.*)*", // Catch all 404
    component: () => import("../pages/404.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresBrandAuth)) {
    const brandLoggedUser = localStorage.getItem("brandUser");
    if (brandLoggedUser) {
      next();
      return;
    }
    // Si no hay brandUser, permitir acceder a la ruta pero mostrará el componente de login
    next();
    return;
  }

  if (to.matched.some((record) => record.meta.requiresAuth && record.meta.requiresManager)) {
    if (store.getters.isAuthenticated && store.getters.isAuthManager) {
      next();
      return;
    }
    next("/indomanager");
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next("/login");
  } else if (to.matched.some((record) => !record.meta.guest) && store.getters.isAuthenticated) {
    next("/dashboard");
  } else {
    next();
  }
});

export default router;
