import axios from 'axios';
import axiosConfig from '@/config/axios';
import config from '@/config/indo';

export default {
  getLecturasMID() {
    return axios.get(
      `${axiosConfig.mimetikaIdURL}/Mimetika_Measurements`,
      {
        headers: {
          ...axiosConfig.headersOfertas,        // Authorization: `Bearer ${token}`,
        },
      },
    );
  },
  getSingleMeasure(uuid) {
    return axios.get(
      `${axiosConfig.mimetikaIdURL}/Mimetika_MeasurementDetail?uuid=${uuid}`,
      {
        headers: {
          ...axiosConfig.headersOfertas,        // Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  saveMimetikaData(username, token, profile, mId) {
    const payload = config.guardarDatosMimetika.xml
      .replace('{cliente}', username)
      .replace('{ref_pedido}', mId.Referencia)
      .replace('{mimetika_id}', mId.ID)
      .replace('{mimetika_fecha}', mId.Fecha)
      .replace('{datos_json}', JSON.stringify(mId.datos));

      return axios.post(
        axiosConfig.indonetURL,
        payload,
        {
          headers: {
            ...axiosConfig.headers,
            Authorization: `Bearer ${token}`,
          },
        },
      );
  }
};
