const estado = {
  lensesType: {
    type: undefined,
    calibre: undefined,
    puente: undefined,
    altura_boxing: undefined,
    od: {
      dnp: undefined,
      altura: undefined,
    },
    oi: {
      dnp: undefined,
      altura: undefined,
    },
    formaId: null,
  },
  indocenter: undefined,
};

const getters = {
  lensesType: (state) => state.lensesType,
  indocenter: (state) => state.indocenter,
};

const mutations = {
  setLensesType(state, data) {
    state.lensesType = data;
  },
  setIndocenter(state, data) {
    state.indocenter = data;
  },
};

export default {
  state: estado,
  getters,
  // actions,
  mutations,
};
