<?xml version="1.0" encoding="utf-8"?>
<root>
  <!-- 
    Microsoft ResX Schema 
    
    Version 2.0
    
    The primary goals of this format is to allow a simple XML format 
    that is mostly human readable. The generation and parsing of the 
    various data types are done through the TypeConverter classes 
    associated with the data types.
    
    Example:
    
    ... ado.net/XML headers & schema ...
    <resheader name="resmimetype">text/microsoft-resx</resheader>
    <resheader name="version">2.0</resheader>
    <resheader name="reader">System.Resources.ResXResourceReader, System.Windows.Forms, ...</resheader>
    <resheader name="writer">System.Resources.ResXResourceWriter, System.Windows.Forms, ...</resheader>
    <data name="Name1"><value>this is my long string</value><comment>this is a comment</comment></data>
    <data name="Color1" type="System.Drawing.Color, System.Drawing">Blue</data>
    <data name="Bitmap1" mimetype="application/x-microsoft.net.object.binary.base64">
        <value>[base64 mime encoded serialized .NET Framework object]</value>
    </data>
    <data name="Icon1" type="System.Drawing.Icon, System.Drawing" mimetype="application/x-microsoft.net.object.bytearray.base64">
        <value>[base64 mime encoded string representing a byte array form of the .NET Framework object]</value>
        <comment>This is a comment</comment>
    </data>
                
    There are any number of "resheader" rows that contain simple 
    name/value pairs.
    
    Each data row contains a name, and value. The row also contains a 
    type or mimetype. Type corresponds to a .NET class that support 
    text/value conversion through the TypeConverter architecture. 
    Classes that don't support this are serialized and stored with the 
    mimetype set.
    
    The mimetype is used for serialized objects, and tells the 
    ResXResourceReader how to depersist the object. This is currently not 
    extensible. For a given mimetype the value must be set accordingly:
    
    Note - application/x-microsoft.net.object.binary.base64 is the format 
    that the ResXResourceWriter will generate, however the reader can 
    read any of the formats listed below.
    
    mimetype: application/x-microsoft.net.object.binary.base64
    value   : The object must be serialized with 
            : System.Runtime.Serialization.Formatters.Binary.BinaryFormatter
            : and then encoded with base64 encoding.
    
    mimetype: application/x-microsoft.net.object.soap.base64
    value   : The object must be serialized with 
            : System.Runtime.Serialization.Formatters.Soap.SoapFormatter
            : and then encoded with base64 encoding.

    mimetype: application/x-microsoft.net.object.bytearray.base64
    value   : The object must be serialized into a byte array 
            : using a System.ComponentModel.TypeConverter
            : and then encoded with base64 encoding.
    -->
  <xsd:schema id="root" xmlns="" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:msdata="urn:schemas-microsoft-com:xml-msdata">
    <xsd:import namespace="http://www.w3.org/XML/1998/namespace" />
    <xsd:element name="root" msdata:IsDataSet="true">
      <xsd:complexType>
        <xsd:choice maxOccurs="unbounded">
          <xsd:element name="metadata">
            <xsd:complexType>
              <xsd:sequence>
                <xsd:element name="value" type="xsd:string" minOccurs="0" />
              </xsd:sequence>
              <xsd:attribute name="name" use="required" type="xsd:string" />
              <xsd:attribute name="type" type="xsd:string" />
              <xsd:attribute name="mimetype" type="xsd:string" />
              <xsd:attribute ref="xml:space" />
            </xsd:complexType>
          </xsd:element>
          <xsd:element name="assembly">
            <xsd:complexType>
              <xsd:attribute name="alias" type="xsd:string" />
              <xsd:attribute name="name" type="xsd:string" />
            </xsd:complexType>
          </xsd:element>
          <xsd:element name="data">
            <xsd:complexType>
              <xsd:sequence>
                <xsd:element name="value" type="xsd:string" minOccurs="0" msdata:Ordinal="1" />
                <xsd:element name="comment" type="xsd:string" minOccurs="0" msdata:Ordinal="2" />
              </xsd:sequence>
              <xsd:attribute name="name" type="xsd:string" use="required" msdata:Ordinal="1" />
              <xsd:attribute name="type" type="xsd:string" msdata:Ordinal="3" />
              <xsd:attribute name="mimetype" type="xsd:string" msdata:Ordinal="4" />
              <xsd:attribute ref="xml:space" />
            </xsd:complexType>
          </xsd:element>
          <xsd:element name="resheader">
            <xsd:complexType>
              <xsd:sequence>
                <xsd:element name="value" type="xsd:string" minOccurs="0" msdata:Ordinal="1" />
              </xsd:sequence>
              <xsd:attribute name="name" type="xsd:string" use="required" />
            </xsd:complexType>
          </xsd:element>
        </xsd:choice>
      </xsd:complexType>
    </xsd:element>
  </xsd:schema>
  <resheader name="resmimetype">
    <value>text/microsoft-resx</value>
  </resheader>
  <resheader name="version">
    <value>2.0</value>
  </resheader>
  <resheader name="reader">
    <value>System.Resources.ResXResourceReader, System.Windows.Forms, Version=2.0.0.0, Culture=neutral, PublicKeyToken=b77a5c561934e089</value>
  </resheader>
  <resheader name="writer">
    <value>System.Resources.ResXResourceWriter, System.Windows.Forms, Version=2.0.0.0, Culture=neutral, PublicKeyToken=b77a5c561934e089</value>
  </resheader>
  <data name="a" xml:space="preserve">
    <value>a</value>
  </data>
  <data name="abertura" xml:space="preserve">
    <value>Ouverture</value>
  </data>
  <data name="acceso_indonet" xml:space="preserve">
    <value>Accès</value>
    <comment>Fenêtre d’identification</comment>
  </data>
  <data name="aceptar" xml:space="preserve">
    <value>Accepter</value>
  </data>
  <data name="Aceptar_Cambio_Y_Enviar" xml:space="preserve">
    <value>Accepter change et envoyer</value>
    <comment>Produit alternatif</comment>
  </data>
  <data name="acetato" xml:space="preserve">
    <value>Acétate</value>
    <comment>type de monture</comment>
  </data>
  <data name="activar" xml:space="preserve">
    <value>Activé</value>
  </data>
  <data name="actualizando_nueva_oferta" xml:space="preserve">
    <value>Actualisant la nouvelle offre</value>
  </data>
  <data name="actualizar" xml:space="preserve">
    <value>Actualiser</value>
  </data>
  <data name="adicion" xml:space="preserve">
    <value>Addition</value>
    <comment>Écran d’accueil, liste des dernières commandes</comment>
  </data>
  <data name="adicion_abrv" xml:space="preserve">
    <value>AD</value>
    <comment>Entête liste commandes</comment>
  </data>
  <data name="advertencia" xml:space="preserve">
    <value>Avertissement</value>
  </data>
  <data name="agente_trazador" xml:space="preserve">
    <value>Agent traceur</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="agente_trazador_desconectado" xml:space="preserve">
    <value>INDOBISEL semble ne fonctionner pas. S'il vous plaît vérifier l'status.</value>
  </data>
  <data name="altura" xml:space="preserve">
    <value>Hauteur</value>
    <comment>Données techniques</comment>
  </data>
  <data name="altura_boxing" xml:space="preserve">
    <value>Hauteur de boxing</value>
  </data>
  <data name="altura_de_montaje" xml:space="preserve">
    <value>Hauteur pupillaire</value>
  </data>
  <data name="altura_montaje" xml:space="preserve">
    <value>Hauteur pupillaire</value>
    <comment>Données supplémentaires</comment>
  </data>
  <data name="altura_montaje_total" xml:space="preserve">
    <value>Hauteur pupillaire final</value>
  </data>
  <data name="altura_pasillo" xml:space="preserve">
    <value>Hauteur de corridor</value>
  </data>
  <data name="anadir" xml:space="preserve">
    <value>Ajouter</value>
  </data>
  <data name="anadir_encargo" xml:space="preserve">
    <value>Ajouter commande</value>
    <comment>Deuxième Lunette</comment>
  </data>
  <data name="anadir_encargo_pregunta_enviar_pedido" xml:space="preserve">
    <value>Envoyer la commande maintenant</value>
    <comment>Deuxième Lunette</comment>
  </data>
  <data name="anadir_encargo_pregunta_enviar_revision" xml:space="preserve">
    <value>Envoyer la commande à révision</value>
    <comment>Deuxième Lunette</comment>
  </data>
  <data name="anadir_encargo_pregunta_graduacion_cerca" xml:space="preserve">
    <value>Correction de près </value>
    <comment>Deuxième Lunette</comment>
  </data>
  <data name="anadir_encargo_pregunta_graduacion_diferente" xml:space="preserve">
    <value>Correction different à la première lunettes</value>
    <comment>Deuxième Lunette</comment>
  </data>
  <data name="anadir_encargo_pregunta_graduacion_lejos" xml:space="preserve">
    <value>Correction de loin</value>
    <comment>Deuxième Lunette</comment>
  </data>
  <data name="anadir_encargo_pregunta_guardar_receta" xml:space="preserve">
    <value>Enregistrer et réaliser la commande plus tard desde Commandes Enregistrées</value>
    <comment>Deuxième Lunette</comment>
  </data>
  <data name="anadir_encargo_pregunta_misma_graduacion" xml:space="preserve">
    <value>Même correction que la première lunettes</value>
    <comment>Deuxième Lunette</comment>
  </data>
  <data name="AnalizandoImagen" xml:space="preserve">
    <value>En cours d’analyse pour obtenir la forme</value>
    <comment>IndoScan</comment>
  </data>
  <data name="angulo" xml:space="preserve">
    <value>Angle</value>
    <comment>Données techniques</comment>
  </data>
  <data name="angulo_de_envolvencia" xml:space="preserve">
    <value>Angle panoramique</value>
    <comment>Données supplémentaires</comment>
  </data>
  <data name="anulado_optico" xml:space="preserve">
    <value>Annulé pour l'opticien</value>
  </data>
  <data name="asunto" xml:space="preserve">
    <value>Objet </value>
  </data>
  <data name="Atencion" xml:space="preserve">
    <value>Attention</value>
    <comment>Produit alternatif</comment>
  </data>
  <data name="atras" xml:space="preserve">
    <value>Retour</value>
  </data>
  <data name="automatico13_23" xml:space="preserve">
    <value>Automatique (1/3-2/3)</value>
    <comment>Orientation du biseau</comment>
  </data>
  <data name="avisos" xml:space="preserve">
    <value>Avertissements</value>
    <comment>Écran de commande</comment>
  </data>
  <data name="aviso_actualizacion" xml:space="preserve">
    <value>L'application est mis à jour à la version:</value>
    <comment>clickonce</comment>
  </data>
  <data name="aviso_alternativa_producto" xml:space="preserve">
    <value>Nous avons détecté une rupture de stock de la graduation et/ou produit sollicité. Afin de garantir un service optimum, nous vous proposons le produit suivant au même prix. S’il s’agit d’échange d’un seul verre, nous vous conseillons de contacter notre service aux clients:</value>
    <comment>Produit alternatif</comment>
  </data>
  <data name="aviso_anadir_favorito" xml:space="preserve">
    <value>Clicker pour ajouter a favoris</value>
  </data>
  <data name="aviso_apagado" xml:space="preserve">
    <value>L'application à été mise à jour. L'application sera fermée. Vous pouvez démarrer INDONET une autre fois avec l'icône du bureau</value>
    <comment>clickonce</comment>
  </data>
  <data name="aviso_atencion_urgencias" xml:space="preserve">
    <value>Avis attention urgences</value>
  </data>
  <data name="aviso_biselar_por_defecto" xml:space="preserve">
    <value>Détourage par défaut</value>
  </data>
  <data name="aviso_borrar_pedido" xml:space="preserve">
    <value>Effacer commande</value>
  </data>
  <data name="aviso_cancelar_pedido" xml:space="preserve">
    <value>Êtes-vous sûr(e) de vouloir annuler la commande ? Toutes les données seront perdues.</value>
    <comment>Avis</comment>
  </data>
  <data name="aviso_caracter_no_valido" xml:space="preserve">
    <value>Ce caractère est invalide. S'il vous plaît utiliser l'alphabet occidental.</value>
  </data>
  <data name="aviso_cargando_oferta" xml:space="preserve">
    <value>Chargeant des données de l'offre</value>
  </data>
  <data name="aviso_color_nd_diferentes" xml:space="preserve">
    <value>La coloration n’est autorisée pas sur des verres d’indexes différents</value>
  </data>
  <data name="aviso_configuracion_favoritos" xml:space="preserve">
    <value>Configurez vôtre liste des verres favoris et passez toutes les commandes plus facilement et efficacement.</value>
  </data>
  <data name="aviso_desactivar_solo_stock" xml:space="preserve">
    <value>Si vous souhaitez cette option, désactivez l’option Stock seul</value>
  </data>
  <data name="aviso_descarga_aplicacion" xml:space="preserve">
    <value>Téléchargeant la nouvelle version</value>
    <comment>ClickOnce</comment>
  </data>
  <data name="aviso_encargo_anulado" xml:space="preserve">
    <value>La vôtre commande à été annulée avec succès.</value>
  </data>
  <data name="aviso_encargo_no_anulable" xml:space="preserve">
    <value>N'est pas possible d'annuler la commande.</value>
  </data>
  <data name="aviso_error_buscando_datos" xml:space="preserve">
    <value>Erreur cherchant données</value>
  </data>
  <data name="aviso_error_enviando_mensaje_SRI" xml:space="preserve">
    <value>Erreur envoyant message au SRI</value>
  </data>
  <data name="aviso_escoger_alto_indice" xml:space="preserve">
    <value>Pour verres percè est recommandé des verres à partir du index 1.6 pour assurer le montage.</value>
  </data>
  <data name="aviso_espera" xml:space="preserve">
    <value>Attendez</value>
  </data>
  <data name="aviso_fallo_co_proxy" xml:space="preserve">
    <value>Il existe une version plus récente que celui vous étes utilisant, mais elle n'a pas réussi à installer. Contactez INDO pour analyser la raison.</value>
  </data>
  <data name="aviso_faltan_datos_montaje" xml:space="preserve">
    <value>Données de montage incorrectes. Vérifiez</value>
  </data>
  <data name="aviso_faltan_datos_para_montaje" xml:space="preserve">
    <value>Devez completer tous les données de montage</value>
  </data>
  <data name="aviso_falta_diametro" xml:space="preserve">
    <value>Vous devez sélectionner un diamètre</value>
  </data>
  <data name="aviso_falta_montura" xml:space="preserve">
    <value> Annulez la monture de la commande en la languette formes et épaisseurs ou changez pour une autre monture avec stock.</value>
    <comment>Deuxième Lunette</comment>
  </data>
  <data name="aviso_falta_referencia" xml:space="preserve">
    <value>Avis manque de référence</value>
  </data>
  <data name="aviso_falta_scaner" xml:space="preserve">
    <value>Impossible d'initialiser le scanner</value>
    <comment>IndoScan</comment>
  </data>
  <data name="aviso_falta_tipo_montura" xml:space="preserve">
    <value>Vous devez sélectionner le type de monture et le type de biseau. Clicker dans le bouton de Suivant dans la selection d'épaisseurs.</value>
  </data>
  <data name="aviso_falta_valor_en_motivo" xml:space="preserve">
    <value>Manque des données pour le motif {0}</value>
  </data>
  <data name="aviso_indocenter_altura_boxing" xml:space="preserve">
    <value>Pour cette commande nous avons changé la configuration à BOXING. Tenez compte de cela si vous allez changer des données importés desde Indocenter.</value>
  </data>
  <data name="aviso_instalando_version" xml:space="preserve">
    <value>Mise à jour de l'application</value>
    <comment>ClickOnce</comment>
  </data>
  <data name="aviso_introducir_montura_1" xml:space="preserve">
    <value>Attention: Pour réaliser correctement la commande</value>
  </data>
  <data name="aviso_introducir_montura_2" xml:space="preserve">
    <value>vous devez entrer la forme de la monture.</value>
  </data>
  <data name="aviso_lecturas_no_cargadas" xml:space="preserve">
    <value>On n'a pas importé lectures pour cette forme</value>
  </data>
  <data name="aviso_lectura_cargada" xml:space="preserve">
    <value>Forme {0} chargée correctement</value>
  </data>
  <data name="aviso_lectura_importada" xml:space="preserve">
    <value>Forme {0} importée correctement</value>
  </data>
  <data name="aviso_mas_30_dias" xml:space="preserve">
    <value>L'intervalle de dates doit être inférieure à 60 jours.</value>
  </data>
  <data name="aviso_no_hay_incidencias" xml:space="preserve">
    <value>On n'a pas trouvé des incidences dans ce formulaire</value>
  </data>
  <data name="aviso_no_hay_tema" xml:space="preserve">
    <value>Le sujet de l'incidence n'est définé pas sur ce formulaire</value>
  </data>
  <data name="aviso_no_posible_anadir_favorito" xml:space="preserve">
    <value>Cet verre ne peut être pas ajouter à favoris</value>
  </data>
  <data name="aviso_no_posible_quitar_favorito" xml:space="preserve">
    <value>Impossible d'éliminer de favoris</value>
  </data>
  <data name="aviso_precalibrado_forma_cuadrada" xml:space="preserve">
    <value>Avec precalibrage, nous recommendons de choisir la forme basique carrée afin de garantir que le verre calculé couvre la forme réelle de la monture.</value>
  </data>
  <data name="aviso_quitar_favorito" xml:space="preserve">
    <value>Clicker pour éliminer de favoris</value>
  </data>
  <data name="aviso_reiniciar_oferta" xml:space="preserve">
    <value>Etes-vous sûr que vous voulez recharger l'offre?</value>
  </data>
  <data name="aviso_solicitar_solo_monturas" xml:space="preserve">
    <value>Avis demander seulement montures</value>
  </data>
  <data name="aviso_tema_no_encontrado" xml:space="preserve">
    <value>On ne trouve pas le sujet</value>
  </data>
  <data name="base" xml:space="preserve">
    <value>Base</value>
    <comment>Données techniques</comment>
  </data>
  <data name="base_automatica" xml:space="preserve">
    <value>Base Automatique</value>
  </data>
  <data name="base_de_datos_de_marcas" xml:space="preserve">
    <value>Base de données de marquages</value>
    <comment>ComboBox de formes</comment>
  </data>
  <data name="base_de_datos_de_mis_biselados" xml:space="preserve">
    <value>Mes biseautés</value>
    <comment>ComboBox de formes</comment>
  </data>
  <data name="base_especial" xml:space="preserve">
    <value>Base spéciale</value>
    <comment>Suppléments</comment>
  </data>
  <data name="base_gafa" xml:space="preserve">
    <value>Base lunettes</value>
  </data>
  <data name="base_interior" xml:space="preserve">
    <value>Base intérieure</value>
  </data>
  <data name="base_nominal" xml:space="preserve">
    <value>Base nominale</value>
    <comment>FFS</comment>
  </data>
  <data name="base_optima" xml:space="preserve">
    <value>Base optimale</value>
  </data>
  <data name="basicas" xml:space="preserve">
    <value>Essentielles</value>
    <comment>ComboBox de formes</comment>
  </data>
  <data name="bisel" xml:space="preserve">
    <value>Biseau normal</value>
    <comment>type de biseau</comment>
  </data>
  <data name="biselador_remoto" xml:space="preserve">
    <value>Biseauter à distance</value>
    <comment>ComboBox de formes</comment>
  </data>
  <data name="biselador_remoto_guardar" xml:space="preserve">
    <value>Enregistrer</value>
  </data>
  <data name="biselador_remoto_importar" xml:space="preserve">
    <value>Importer</value>
  </data>
  <data name="biselador_remoto_iniciar" xml:space="preserve">
    <value>Démarrer</value>
  </data>
  <data name="biselador_remoto_popup_titulo" xml:space="preserve">
    <value>Sélection des lectures agent traceur</value>
  </data>
  <data name="biselador_remoto_ultima" xml:space="preserve">
    <value>Dernière</value>
  </data>
  <data name="biselado_remoto" xml:space="preserve">
    <value>Biseauté à distance</value>
  </data>
  <data name="biselar_lente" xml:space="preserve">
    <value>Biseauter verre</value>
  </data>
  <data name="biselar_sin_pulir" xml:space="preserve">
    <value>Biseauter sans polir</value>
  </data>
  <data name="BlankRange" xml:space="preserve">
    <value>Rang sans valeur</value>
    <comment>BlankRange</comment>
  </data>
  <data name="borde_especial_abr" xml:space="preserve">
    <value>Bord spécial</value>
  </data>
  <data name="borrar" xml:space="preserve">
    <value>Annuler</value>
  </data>
  <data name="borrar_mi_biselado" xml:space="preserve">
    <value>Supprimer de Mes Biseautés</value>
    <comment>Supprimé Mes Biseautés</comment>
  </data>
  <data name="borrar_recetas" xml:space="preserve">
    <value>Supprimer</value>
  </data>
  <data name="buscar" xml:space="preserve">
    <value>Rechercher</value>
  </data>
  <data name="calibracion_correctamente" xml:space="preserve">
    <value>Le calibrage a été fait correctament</value>
  </data>
  <data name="calibracion_monitor" xml:space="preserve">
    <value>Calibrage moniteur</value>
  </data>
  <data name="calibre" xml:space="preserve">
    <value>Calibre</value>
    <comment>Données supplémentaires</comment>
  </data>
  <data name="calibre_completo" xml:space="preserve">
    <value>Calibre complet</value>
  </data>
  <data name="calibre_marcas" xml:space="preserve">
    <value>Calibre</value>
  </data>
  <data name="cancelar" xml:space="preserve">
    <value>Annuler</value>
  </data>
  <data name="cancelar_comparacion" xml:space="preserve">
    <value>Annuler comparaison</value>
  </data>
  <data name="cancelar_pedido" xml:space="preserve">
    <value>Annuler commande</value>
    <comment>Menu Actions de la commande</comment>
  </data>
  <data name="cantidad_mayor_que_cero" xml:space="preserve">
    <value>Quantité plus grande que zéro</value>
  </data>
  <data name="caracter_hiperlink" xml:space="preserve">
    <value>»</value>
    <comment>Login</comment>
  </data>
  <data name="cargar_fichero" xml:space="preserve">
    <value>Charger fichier</value>
  </data>
  <data name="cargar_imagenes" xml:space="preserve">
    <value>Charger images</value>
    <comment>IndoMedCare</comment>
  </data>
  <data name="centrado12_12" xml:space="preserve">
    <value>Centré (1/2-1/2)</value>
    <comment>Orientation du biseau</comment>
  </data>
  <data name="chile" xml:space="preserve">
    <value>Chili</value>
  </data>
  <data name="cilindro" xml:space="preserve">
    <value>Cylindre</value>
    <comment>Écran d’accueil, liste des dernières commandes</comment>
  </data>
  <data name="cilindro_abrv" xml:space="preserve">
    <value>CYL</value>
    <comment>Entête liste commandes</comment>
  </data>
  <data name="clave_acceso" xml:space="preserve">
    <value>Mot de passe</value>
    <comment>Fenêtre d’identification</comment>
  </data>
  <data name="cliente" xml:space="preserve">
    <value>Client</value>
  </data>
  <data name="codigo_cliente" xml:space="preserve">
    <value>Code de client</value>
    <comment>Fenêtre d’identification</comment>
  </data>
  <data name="codigo_de_barras" xml:space="preserve">
    <value>Code-barres</value>
  </data>
  <data name="codigo_de_retorno" xml:space="preserve">
    <value>Code du retour :  </value>
  </data>
  <data name="color" xml:space="preserve">
    <value>Couleur </value>
    <comment>Écran de commande</comment>
  </data>
  <data name="coloracion" xml:space="preserve">
    <value>Coloration</value>
    <comment>Écran de commande</comment>
  </data>
  <data name="comparar" xml:space="preserve">
    <value>Comparer</value>
  </data>
  <data name="comparar_lentes" xml:space="preserve">
    <value>Comparer verres</value>
  </data>
  <data name="comparar_otra_lente" xml:space="preserve">
    <value>Comparer un autre verre</value>
  </data>
  <data name="comprobando_si_BBDD" xml:space="preserve">
    <value>Vérifiant s'il y a une base de données...</value>
  </data>
  <data name="comprobando_si_nueva_oferta" xml:space="preserve">
    <value>Vérifiant s'il y a une nouvelle offre...</value>
  </data>
  <data name="comprobar_disponibilidad" xml:space="preserve">
    <value>Vérifier la disponibilité</value>
  </data>
  <data name="condiciones" xml:space="preserve">
    <value>Conditions</value>
  </data>
  <data name="condiciones_1" xml:space="preserve">
    <value>INDO OPTICAL, S.L.U. avec numéro d’identification fiscale CIF B-66232976, siège social à l’adresse Avda. Alcalde Barnils, 72 - 08174 Sant Cugat del Vallés - Barcelona et inscrite au registre du commerce de Barcelone au tome 44215, Folio 212, feuille B-449860, en tant que responsable du traitement des données, destinataire de l’information et titulaire du fichier dénommé « clientes »,  communique au souscripteur que les données fournies seront introduites dans le fichier dénommé « clientes ». L’objectif de la collecte de données est de permettre l’échange commercial entre INDO et son Opticien à travers Internet et pouvoir ainsi prêter le service INDONET.</value>
  </data>
  <data name="condiciones_2" xml:space="preserve">
    <value>L’acceptation des conditions d’utilisation est obligatoire et l’absence de réponse à la sollicitation des données empêchera le service à travers la modalité INDONET.</value>
  </data>
  <data name="condiciones_3" xml:space="preserve">
    <value>Le souscripteur est informé qu’il peut exercer envers le responsable du traitement tous les droits qui lui sont concédés par la loi, en particulier, les droits d’accès, rectification, annulation et opposition. À cet effet, il devra exprimer ses souhaits à travers le formulaire de contact IndoBox www.indobox-indo.com.</value>
  </data>
  <data name="condiciones_4" xml:space="preserve">
    <value>Le souscripteur, en tant que titulaire des données fournies, consent expressément à leur traitement sous la forme décrite.</value>
  </data>
  <data name="conexion_ssl" xml:space="preserve">
    <value>CONNEXION SSL</value>
  </data>
  <data name="conexion_ssl_segura" xml:space="preserve">
    <value>CONNEXION SÛRE SSL</value>
  </data>
  <data name="configuracion" xml:space="preserve">
    <value>Configuration</value>
    <comment>Barre d’outils</comment>
  </data>
  <data name="configuracion_activado" xml:space="preserve">
    <value>Activé</value>
  </data>
  <data name="configuracion_altura_montaje" xml:space="preserve">
    <value>Hauteur pupillaire</value>
  </data>
  <data name="configuracion_aro" xml:space="preserve">
    <value>Datum</value>
  </data>
  <data name="configuracion_boxing" xml:space="preserve">
    <value>Boxing</value>
  </data>
  <data name="configuracion_catalogo_lentes" xml:space="preserve">
    <value>Catalogue des verres d'Indo</value>
  </data>
  <data name="configuracion_clave_actual" xml:space="preserve">
    <value>Mot de passe actuelle</value>
  </data>
  <data name="configuracion_confirmar_clave" xml:space="preserve">
    <value>Confirmer Mot de passe</value>
  </data>
  <data name="configuracion_desactivado" xml:space="preserve">
    <value>Désactivé</value>
  </data>
  <data name="configuracion_descargas_automaticas" xml:space="preserve">
    <value>Téléchargements automatiques</value>
  </data>
  <data name="configuracion_dias" xml:space="preserve">
    <value>Jours</value>
  </data>
  <data name="configuracion_enlaces_relacionados" xml:space="preserve">
    <value>Liens sur le sujet</value>
  </data>
  <data name="configuracion_espesores" xml:space="preserve">
    <value>Configuration d’épaisseurs</value>
  </data>
  <data name="configuracion_espesor_borde" xml:space="preserve">
    <value>Épaisseur bord</value>
  </data>
  <data name="configuracion_fecha_oferta" xml:space="preserve">
    <value>Date de l'offre</value>
    <comment>Lecture de la date de l'offre dans le BBDD</comment>
  </data>
  <data name="configuracion_guardada_correctamente" xml:space="preserve">
    <value>Configuration correctement enregistrée</value>
  </data>
  <data name="configuracion_hojaPedido" xml:space="preserve">
    <value>Formulaire de commande</value>
  </data>
  <data name="configuracion_jerga" xml:space="preserve">
    <value>Jargon</value>
  </data>
  <data name="configuracion_lentesMaxima" xml:space="preserve">
    <value>www.lentesmaxima.com</value>
  </data>
  <data name="configuracion_link_hojaRombos" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/Indonet/Enlaces/HojaPedidos_FR.pdf</value>
  </data>
  <data name="configuracion_link_lentesMaxima" xml:space="preserve">
    <value>http://www.lentesmaxima.com</value>
  </data>
  <data name="configuracion_link_manualFavoritas" xml:space="preserve">
    <value>Configuration link  manuelle favoris</value>
  </data>
  <data name="configuracion_link_manualIndoNet" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/Indonet/Enlaces/ManualIndonet_FR.pdf</value>
  </data>
  <data name="configuracion_link_manualIndoScanIntegrado" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/Indonet/Enlaces/ManualIndoScanIntegrado_FR.pdf</value>
  </data>
  <data name="configuracion_listado_pedidos" xml:space="preserve">
    <value>Liste « situation des commandes »</value>
  </data>
  <data name="configuracion_manualFavoritas" xml:space="preserve">
    <value>Manuelle Favoris</value>
  </data>
  <data name="configuracion_manualIndonet" xml:space="preserve">
    <value>Manuel Indonet</value>
  </data>
  <data name="configuracion_manualIndoScanIntegrado" xml:space="preserve">
    <value>Manuel IndoScan integré</value>
  </data>
  <data name="configuracion_modificar_clave" xml:space="preserve">
    <value>Modifier Mot de passe</value>
  </data>
  <data name="configuracion_mostrar_pedidos" xml:space="preserve">
    <value>Afficher les dernières commandes passées </value>
  </data>
  <data name="configuracion_mostrar_precios" xml:space="preserve">
    <value>Afficher prix</value>
  </data>
  <data name="configuracion_nombre_comercial" xml:space="preserve">
    <value>Nom commercial</value>
  </data>
  <data name="configuracion_nombre_lentes" xml:space="preserve">
    <value>Nom des verres</value>
  </data>
  <data name="configuracion_nombre_lentes_help" xml:space="preserve">
    <value>La nouvelle présentation sera montrée à la prochaine authentification</value>
  </data>
  <data name="configuracion_nueva_clave" xml:space="preserve">
    <value>Nouvelle Mot de passe</value>
  </data>
  <data name="configuracion_precalibrado_cotas" xml:space="preserve">
    <value>Précalibrage aux cotes</value>
  </data>
  <data name="configuracion_precios_no" xml:space="preserve">
    <value>Non</value>
  </data>
  <data name="configuracion_precios_pvo" xml:space="preserve">
    <value>TVO</value>
  </data>
  <data name="configuracion_precios_pvp" xml:space="preserve">
    <value>PPC</value>
  </data>
  <data name="configuracion_precios_si" xml:space="preserve">
    <value>Oui</value>
  </data>
  <data name="configuracion_pred_indo" xml:space="preserve">
    <value>Préd.Indo</value>
  </data>
  <data name="configuracion_propio" xml:space="preserve">
    <value>Propre</value>
  </data>
  <data name="configuracion_punto_taladro" xml:space="preserve">
    <value>Point perforation</value>
  </data>
  <data name="configuracion_ranura_metal" xml:space="preserve">
    <value>Rainure métal</value>
  </data>
  <data name="configuracion_ranura_nylon" xml:space="preserve">
    <value>Rainure nylon</value>
  </data>
  <data name="configuracion_restablecer_valores_predeterminados" xml:space="preserve">
    <value>Rétablir les valeurs prédéterminées</value>
  </data>
  <data name="configuracion_taller" xml:space="preserve">
    <value>Configuration atelier</value>
  </data>
  <data name="configuracion_tooltip_espesores" xml:space="preserve">
    <value>L’épaisseur est inférieure à la valeur recommandée. Les verres ne seront pas biseautés</value>
  </data>
  <data name="configuracion_tooltip_numstepper" xml:space="preserve">
    <value>La liste « Situation de commandes » se trouve à l’écran « Accueil ». La plage permise est de 1 à 7 jours.</value>
  </data>
  <data name="configuracion_URL_catalogo" xml:space="preserve">
    <value>http://www.indo.es/lentes/catalogo_eng/</value>
  </data>
  <data name="confirmar_borrar_forma" xml:space="preserve">
    <value>Êtes-vous sûr(e) de supprimer cette forme?</value>
    <comment>Supprimé Mes Biseautés</comment>
  </data>
  <data name="confirmar_borrar_receta" xml:space="preserve">
    <value>Étes-vous sûr(e) d'annuler la commande sauvegardée et sélectionnée ?</value>
  </data>
  <data name="confirmar_borrar_recetas" xml:space="preserve">
    <value>Étes-vous sûr(e) d'annuler des {0} commandes sauvegardées et sélectionnées ?</value>
  </data>
  <data name="confirmar_envio_pedidos_1" xml:space="preserve">
    <value>Confirmez-vous l’envoi de la quantité de commandes suivante ? #</value>
  </data>
  <data name="confirmar_envio_pedidos_2" xml:space="preserve">
    <value>Les commandes incorrectes seront en état « En révision par INDO »</value>
  </data>
  <data name="confirmar_envio_receta" xml:space="preserve">
    <value>Confirmez-vous l’envoi de la commande sauvegardée et sélectionnée ?</value>
  </data>
  <data name="confirmar_envio_recetas" xml:space="preserve">
    <value>Confirmez-vous l’envoi des {0} commandes sauvegardées et sélectionnées ?</value>
  </data>
  <data name="confirmar_envio_recetas_2" xml:space="preserve">
    <value>Les commandes incorrectes seront en état « En révision par INDO »</value>
  </data>
  <data name="confirma_biselado_espesor" xml:space="preserve">
    <value>Confirm. de biseau et d’épaisseur</value>
  </data>
  <data name="confirma_destino_mercancia" xml:space="preserve">
    <value>Confirmez le destinataire de la marchandise</value>
  </data>
  <data name="contacta_con_indo" xml:space="preserve">
    <value>Contacter</value>
    <comment>Fenêtre d’identification</comment>
  </data>
  <data name="contacto" xml:space="preserve">
    <value>Contact</value>
  </data>
  <data name="continuar" xml:space="preserve">
    <value>Continuer</value>
  </data>
  <data name="copiar_carpeta" xml:space="preserve">
    <value>Copier le dossier au PC</value>
  </data>
  <data name="correo_solicitud_acceso" xml:space="preserve">
    <value>Courrier demande accès</value>
  </data>
  <data name="cota_inferior" xml:space="preserve">
    <value>Cote inférieure</value>
  </data>
  <data name="cota_superior" xml:space="preserve">
    <value>Cote supérieure</value>
  </data>
  <data name="crear_caso" xml:space="preserve">
    <value>Créer un cas</value>
  </data>
  <data name="datosoptica_aviso_logotipo" xml:space="preserve">
    <value>Aucun fichier spécifié pour le logotype.{0}Le logo par défaut sera adopté </value>
  </data>
  <data name="datosoptica_codigo_postal" xml:space="preserve">
    <value>Code postal :</value>
  </data>
  <data name="datosoptica_comunicaciones" xml:space="preserve">
    <value>Je souhaite recevoir des communications périodiques d’Indo</value>
  </data>
  <data name="datosoptica_corregir_datos" xml:space="preserve">
    <value>Si vos données ne sont pas correctes, entrez dans le formulaire de contact dans le menu du haut du INDONET.</value>
  </data>
  <data name="datosoptica_direccion" xml:space="preserve">
    <value>Adresse :</value>
  </data>
  <data name="datosoptica_email" xml:space="preserve">
    <value>Courrier électronique</value>
  </data>
  <data name="datosoptica_enlace_condiciones" xml:space="preserve">
    <value>Lire des conditions de confidentialité et de protection des données</value>
  </data>
  <data name="datosoptica_fax" xml:space="preserve">
    <value>Télécopie :</value>
  </data>
  <data name="datosoptica_guardados_correctamente" xml:space="preserve">
    <value>Données de l’opticien correctement enregistrées</value>
  </data>
  <data name="datosoptica_logotipo_help" xml:space="preserve">
    <value>Le nouveau logo sera montré à la prochaine authentification</value>
  </data>
  <data name="datosoptica_logotipo_optica" xml:space="preserve">
    <value>Logotype opticien</value>
  </data>
  <data name="datosoptica_nif" xml:space="preserve">
    <value>N° d’identité fiscale :</value>
  </data>
  <data name="datosoptica_nombre" xml:space="preserve">
    <value>Nom :</value>
  </data>
  <data name="datosoptica_poblacion" xml:space="preserve">
    <value>Ville :</value>
  </data>
  <data name="datosoptica_telefono" xml:space="preserve">
    <value>Téléphone :</value>
  </data>
  <data name="datos_adicionales" xml:space="preserve">
    <value>Données Obligatoires</value>
    <comment>Données fondamentales</comment>
  </data>
  <data name="datos_basicos" xml:space="preserve">
    <value>Données essentielles</value>
    <comment>Écran de commande</comment>
  </data>
  <data name="datos_opcionales" xml:space="preserve">
    <value>Voir données supplémentaires en option</value>
  </data>
  <data name="datos_optica" xml:space="preserve">
    <value>Données Opticien</value>
    <comment>Barre de navigation principale</comment>
  </data>
  <data name="datos_tecnicos" xml:space="preserve">
    <value>Données Techniques</value>
  </data>
  <data name="densidad" xml:space="preserve">
    <value>Densité</value>
  </data>
  <data name="descargando_oferta" xml:space="preserve">
    <value>Déchargement de l'offre</value>
  </data>
  <data name="descargar_diagnostico" xml:space="preserve">
    <value>Décharger  diagnostic</value>
  </data>
  <data name="descarga_oferta_fallo_1" xml:space="preserve">
    <value>Impossible de recharger l'offre en ce moment.</value>
  </data>
  <data name="descarga_oferta_fallo_2" xml:space="preserve">
    <value>S'il vous plaît, réessayer plus tard à partir du bouton RECHARGER L'OFFRE DANS LES DONNÉES OPTICIEN</value>
  </data>
  <data name="descentramiento" xml:space="preserve">
    <value>Décentrement</value>
  </data>
  <data name="descentramiento_nasal" xml:space="preserve">
    <value>Décentrement nasal</value>
    <comment>Suppléments</comment>
  </data>
  <data name="descripcion" xml:space="preserve">
    <value>Description</value>
  </data>
  <data name="descripcion_calibracion_old" xml:space="preserve">
    <value>Introduisez en millimètres le nouveau diamètre mesuré</value>
  </data>
  <data name="desde_scanner" xml:space="preserve">
    <value>De scanner</value>
    <comment>ComboBox formes</comment>
  </data>
  <data name="desmarcar_todos" xml:space="preserve">
    <value>Démarquer tous</value>
  </data>
  <data name="destinatario" xml:space="preserve">
    <value>Destinataire</value>
  </data>
  <data name="detalle_estado_pedido" xml:space="preserve">
    <value>Détail de l’état de commande</value>
  </data>
  <data name="diagnosticado" xml:space="preserve">
    <value>Diagnostiquée</value>
  </data>
  <data name="diagnosticar" xml:space="preserve">
    <value>Diagnostiquer</value>
  </data>
  <data name="diagnostico" xml:space="preserve">
    <value>Diagnostique</value>
  </data>
  <data name="diametro" xml:space="preserve">
    <value>Diamètre</value>
    <comment>Vue données fondamentales œil</comment>
  </data>
  <data name="diametro_resultante" xml:space="preserve">
    <value>Diamètre resultant</value>
  </data>
  <data name="diametro_resultante_abrev" xml:space="preserve">
    <value>Dia. result.</value>
  </data>
  <data name="dioptrias" xml:space="preserve">
    <value>Dioptries</value>
    <comment>Suppléments</comment>
  </data>
  <data name="direccion_indo" xml:space="preserve">
    <value>Avda. Alcalde Barnils, 72 - 08174 Sant Cugat del Vallés - Barcelona</value>
    <comment>Login</comment>
  </data>
  <data name="Discrepancia_muestra_leida" xml:space="preserve">
    <value>Erreur : différence entre le nombre de points théoriques et réels</value>
    <comment>IndoScan</comment>
  </data>
  <data name="distancia" xml:space="preserve">
    <value>Distance</value>
    <comment>Données supplémentaires</comment>
  </data>
  <data name="distancia_trabajo" xml:space="preserve">
    <value>Distance de travail </value>
    <comment>Données supplémentaires</comment>
  </data>
  <data name="dnp" xml:space="preserve">
    <value>Ecart Pupillaire</value>
    <comment>Données supplémentaires</comment>
  </data>
  <data name="dnp_de_cerca" xml:space="preserve">
    <value>Ecart Pupillaire de près</value>
  </data>
  <data name="duplicar_receta" xml:space="preserve">
    <value>Dupliquer commande</value>
  </data>
  <data name="d_esp" xml:space="preserve">
    <value>D.Spé.</value>
  </data>
  <data name="d_especial" xml:space="preserve">
    <value>D. spécial</value>
    <comment>Vue données fondamentales œil</comment>
  </data>
  <data name="eje" xml:space="preserve">
    <value>Axe</value>
    <comment>Écran d’accueil, liste des dernières commandes</comment>
  </data>
  <data name="eje_abrv" xml:space="preserve">
    <value>AXE</value>
    <comment>Entête liste commandes</comment>
  </data>
  <data name="eliminar_seleccionados" xml:space="preserve">
    <value>Supprimer sélectionnés</value>
  </data>
  <data name="el_valor_maximo_es" xml:space="preserve">
    <value>La valeur maximale est</value>
    <comment>Validations</comment>
  </data>
  <data name="email" xml:space="preserve">
    <value>Courrier électronique</value>
  </data>
  <data name="email_contacto" xml:space="preserve">
    <value>Courrier électronique de contact</value>
  </data>
  <data name="entrar" xml:space="preserve">
    <value>ENTRER</value>
    <comment>Fenêtre d’identification</comment>
  </data>
  <data name="enviado_a_oftalmologo" xml:space="preserve">
    <value>Envoyé à l'ophtalmologue</value>
  </data>
  <data name="enviado_a_optico" xml:space="preserve">
    <value>Envoyé à l'opticien</value>
  </data>
  <data name="enviar" xml:space="preserve">
    <value>Envoyer</value>
    <comment>Menu Actions de la commande</comment>
  </data>
  <data name="enviar_pedido" xml:space="preserve">
    <value>Envoyer commande</value>
  </data>
  <data name="enviar_sugerencias" xml:space="preserve">
    <value>Envoyer suggestions</value>
  </data>
  <data name="enviar_y_revision" xml:space="preserve">
    <value>Envoyer et réviser</value>
    <comment>Menu Actions de la commande</comment>
  </data>
  <data name="envio_recetas" xml:space="preserve">
    <value>Envoi commande</value>
  </data>
  <data name="envio_recetas_falta_destinatario" xml:space="preserve">
    <value>Vous devez sélectionner un destinataire</value>
  </data>
  <data name="envio_recetas_ningun_destinatario" xml:space="preserve">
    <value>Aucun destinataire trouvé</value>
  </data>
  <data name="errores_en_formulario" xml:space="preserve">
    <value>On a trouvé erreurs dans le formulaire.</value>
  </data>
  <data name="error_abriendo_aplicacion" xml:space="preserve">
    <value>Erreur à l’ouverture de l’application demandée</value>
  </data>
  <data name="error_abriendo_base_de_datos" xml:space="preserve">
    <value>Une erreur s’est produite à l’ouverture de la base de données</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_actualizacion_pedido" xml:space="preserve">
    <value>Erreur de mise à jour de la commande</value>
  </data>
  <data name="error_actualizando_fecha_oferta" xml:space="preserve">
    <value>Une erreur est survenue lors de la mise à jour de la date de l'offre dans la base de données</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_actualizando_oferta" xml:space="preserve">
    <value>Une erreur est survenue dans la mise à jour de l’offre</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_actualizando_pedido" xml:space="preserve">
    <value>***&gt;Impossible de mettre à jour la commande.</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_agente_trazador" xml:space="preserve">
    <value>Erreur avec l’Agent Traceur</value>
  </data>
  <data name="error_autenticar_usuario" xml:space="preserve">
    <value>Impossible d’authentifier l’utilisateur</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_base_de_datos" xml:space="preserve">
    <value>Une exception s’est produite avec la base de données locale</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_base_de_datos_no_abierta" xml:space="preserve">
    <value>La base de données doit être ouverte</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_borrando_pedido" xml:space="preserve">
    <value>Erreur annulant la commande</value>
  </data>
  <data name="error_borrando_recetas" xml:space="preserve">
    <value>Une erreur est survenue dans la suppression des commandes</value>
  </data>
  <data name="error_busqueda_expedientes" xml:space="preserve">
    <value>Erreur cherchant dossiers</value>
  </data>
  <data name="error_busqueda_inicial_pedidos" xml:space="preserve">
    <value>Une erreur est survenue pendant la recherche initiale de commandes</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_busqueda_pedidos" xml:space="preserve">
    <value>Erreur dans la recherche de commandes</value>
  </data>
  <data name="error_busqueda_recetas" xml:space="preserve">
    <value>Erreur dans la recherche de commandes</value>
  </data>
  <data name="error_calibracion" xml:space="preserve">
    <value>Impossible de réaliser le calibrage</value>
  </data>
  <data name="error_cambiando_password" xml:space="preserve">
    <value>Impossible de modifier le mot de passe</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_caracteres_diferentes_clave_acceso" xml:space="preserve">
    <value>Caractères différents dans le mot de passe</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_caracteres_no_permitidos_clave_acceso" xml:space="preserve">
    <value>Caractères non autorisés dans la mot de passe</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_cargando_destinatarios" xml:space="preserve">
    <value>Erreur chargeant des destinataires</value>
  </data>
  <data name="error_claves_no_coinciden" xml:space="preserve">
    <value>Les mots de passe sont différentes</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_clave_acceso_no_permitida" xml:space="preserve">
    <value>Mot de passe non autorisée</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_clave_incorrecta" xml:space="preserve">
    <value>La mot de passe indiquée est erronée</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_comprobar_disponibilidad_segunda_gafa" xml:space="preserve">
    <value>Erreur vérifiant disponibilité de la deuxième monture</value>
  </data>
  <data name="error_conexion_agente_trazador" xml:space="preserve">
    <value>Aucune connexion avec l’agent traceur</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_conexion_agente_trazador_elegido" xml:space="preserve">
    <value>Erreur de connexion avec l’agent traceur sélectionné</value>
  </data>
  <data name="error_conexion_agente_trazador_insertado" xml:space="preserve">
    <value>Erreur de connexion avec l’agent traceur inséré</value>
  </data>
  <data name="error_conexion_servicio" xml:space="preserve">
    <value>Aucune connexion avec le serveur</value>
  </data>
  <data name="error_debe_seleccionar_lectura" xml:space="preserve">
    <value>Vous devez sélectionner une lecture</value>
  </data>
  <data name="error_desconocido" xml:space="preserve">
    <value>Erreur inconnue</value>
  </data>
  <data name="error_email_direccion_invalida" xml:space="preserve">
    <value>Le courrier électronique n'est pas correcte</value>
    <comment>Erreurs</comment>
  </data>
  <data name="error_email_falta_asunto" xml:space="preserve">
    <value>L’objet est obligatoire</value>
    <comment>Erreurs</comment>
  </data>
  <data name="error_email_falta_cuerpo" xml:space="preserve">
    <value>Le message est obligatoire</value>
    <comment>Erreurs</comment>
  </data>
  <data name="error_email_falta_direccion" xml:space="preserve">
    <value>Le courrier électronique est obligatoire</value>
    <comment>Erreurs</comment>
  </data>
  <data name="error_enviando_correo" xml:space="preserve">
    <value>Impossible d’envoyer le courrier</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_enviando_lista_recetas" xml:space="preserve">
    <value>Erreur dans l’envoi de la liste de commandes</value>
  </data>
  <data name="error_enviando_mail" xml:space="preserve">
    <value>Une erreur est survenue dans l’envoi du message électronique</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_envio_pedido_paso2_1" xml:space="preserve">
    <value>Erreur envoi commande démarche 2 1</value>
  </data>
  <data name="error_envio_pedido_paso2_2" xml:space="preserve">
    <value>Erreur envoi commande démarche 2 2</value>
  </data>
  <data name="error_envio_pedido_paso2_3" xml:space="preserve">
    <value>Erreur envoi commnade démarche 2 3</value>
  </data>
  <data name="error_excepcion_autenticando_usuario" xml:space="preserve">
    <value>Une exception s’est produite copiant l’authentification de l’utilisateur</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_excepcion_copando_clase_pedidos" xml:space="preserve">
    <value>Une exception s’est produite copiant la classe des commandes</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_excepcion_enviando_correo" xml:space="preserve">
    <value>Une exception s’est produite envoyant le courrier électronique</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_excepcion_enviando_pedido" xml:space="preserve">
    <value>Une exception s’est produite envoyant la commande</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_excepcion_enviando_pedido_para_comparacion" xml:space="preserve">
    <value>Une exception s’est produite envoyant la commande pour comparaison</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_excepcion_obtenido_el_area_venta" xml:space="preserve">
    <value>Une exception s’est produite obtenant la zone de vente</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_excepcion_obteniendo_pedidos_busqueda" xml:space="preserve">
    <value>Une exception s’est produite obtenant la liste de commandes (recherche)</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_excepcion_obteniendo_pedidos_inicial" xml:space="preserve">
    <value>Une exception s’est produite dans obtenant la liste de commandes (initiale)</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_excepcion_producida" xml:space="preserve">
    <value>Une exception s’est produite</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_excepcion_recuperando_oferta" xml:space="preserve">
    <value>Une exception s’est produite récupérant l’offre</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_exceso_numero_intentos_permitidos" xml:space="preserve">
    <value>Le nombre de tentatives autorisées est dépassé</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_grabacion_pedido" xml:space="preserve">
    <value>Une erreur s'est produite en enregistrant votre commande. Merci de bien vouloir réenregistrer la commande.</value>
  </data>
  <data name="error_grabando_pedido" xml:space="preserve">
    <value>***&gt;Impossible d’enregistrer la commande</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_guardando_datos_configuracion" xml:space="preserve">
    <value>Erreur en sauvegardant les données de configuration</value>
  </data>
  <data name="error_guardando_datos_optica" xml:space="preserve">
    <value>Erreur en sauvegardant les données de l'opticien</value>
  </data>
  <data name="error_guardando_forma" xml:space="preserve">
    <value>Erreur en sauvegardant la forme</value>
  </data>
  <data name="error_guardando_oferta" xml:space="preserve">
    <value>Une erreur est survenue enregistrant l’offre</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_iniciando_aplicacion" xml:space="preserve">
    <value>Une erreur est survenue démarrant l’application</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_introducir_nueva_clave" xml:space="preserve">
    <value>Introduire la nouvelle mot de passe</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_lecturas_agentes" xml:space="preserve">
    <value>Une erreur s’est produite en accédant aux lectures de {0} agent{1}</value>
  </data>
  <data name="error_leyendo_fichero_base_de_datos" xml:space="preserve">
    <value>Une erreur s’est produite en lisant le fichier de la base de données</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_login" xml:space="preserve">
    <value>Une erreur est survenue au cours de l’identification</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_modificando_noticia_emergente" xml:space="preserve">
    <value>Errer en modifiant la nouvelle émergente</value>
  </data>
  <data name="error_no_se_han_obtenido_datos_configuracion_cliente" xml:space="preserve">
    <value>Impossible d'obtenir las données de configuration du client</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_no_se_ha_obtenido_el_area_venta" xml:space="preserve">
    <value>Impossible d'obtenir la zone de vente</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_no_se_ha_podido_recuperar_lista_pedidos" xml:space="preserve">
    <value>***&gt;Impossible de récupérer la liste avec les commandes</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_no_se_ha_podido_recuperar_oferta" xml:space="preserve">
    <value>***&gt;Impossible de récupérer l'offre</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_obteniendo_los_datos_configuracion_del_cliente" xml:space="preserve">
    <value>Une exception obtenant las données de configuration</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_proceso_login" xml:space="preserve">
    <value>Une erreur s’est produite lors du processus d'identification</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_recuperando_monturas" xml:space="preserve">
    <value>Erreur en récupérant montures</value>
  </data>
  <data name="error_recuperando_pedido" xml:space="preserve">
    <value>***&gt;Impossible de récupérer la commande</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_recuperando_receta" xml:space="preserve">
    <value>***&gt;Impossible de récupérer la commande</value>
  </data>
  <data name="error_recuperando_recetas" xml:space="preserve">
    <value>Erreur en récupérant les commandes</value>
  </data>
  <data name="error_reintentos_excedidos" xml:space="preserve">
    <value>Tentatives dépassées</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_servicio_cambio_de_password" xml:space="preserve">
    <value>Le service 'Modification de la Mot de passe' a donné une erreur</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_servicio_envio_correo" xml:space="preserve">
    <value>Le service 'Envoi courrier électronique' a donné une erreur</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_servicio_login" xml:space="preserve">
    <value>Le service 'Identification' a donné une erreur</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_signo_interrogacion_no_permitido_clave_acceso" xml:space="preserve">
    <value>Point d'interrogation non autorisé dans la mot de passe</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_tipo_de_base_de_datos_desconocida" xml:space="preserve">
    <value>Type de base de données inconnue</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="error_usuario_bloqueado" xml:space="preserve">
    <value>L’utilisateur est bloqué</value>
  </data>
  <data name="error_usuario_no_existe" xml:space="preserve">
    <value>L’utilisateur n’existe pas</value>
  </data>
  <data name="error_usuario_o_clave_no_validos" xml:space="preserve">
    <value>L’utilisateur ou la mot de passe n'est pas valide</value>
    <comment>FFS</comment>
  </data>
  <data name="error_usuario_ya_existe" xml:space="preserve">
    <value>L’utilisateur existe déjà</value>
  </data>
  <data name="esfera" xml:space="preserve">
    <value>Sphère</value>
    <comment>Écran d’accueil, liste des dernières commandes</comment>
  </data>
  <data name="esfera_abrv" xml:space="preserve">
    <value>SPH</value>
    <comment>Entête liste commandes</comment>
  </data>
  <data name="espana" xml:space="preserve">
    <value>Espagne</value>
  </data>
  <data name="Esperando_respuesta_del_usuario_para_actualizar_la_oferta" xml:space="preserve">
    <value>Attendant la réponse de l'utilisateur pour misse à jour l'offre ...</value>
  </data>
  <data name="espesor" xml:space="preserve">
    <value>Épaisseur</value>
    <comment>Données techniques</comment>
  </data>
  <data name="espesores" xml:space="preserve">
    <value>Épaisseurs</value>
  </data>
  <data name="espesor_borde" xml:space="preserve">
    <value>Épaisseur bord</value>
  </data>
  <data name="espesor_borde_esp" xml:space="preserve">
    <value>Épaisseur bord spé.</value>
  </data>
  <data name="espesor_borde_especial" xml:space="preserve">
    <value>Épaisseur bord spécial</value>
  </data>
  <data name="espesor_centro" xml:space="preserve">
    <value>Épaisseur centre</value>
    <comment>Données techniques</comment>
  </data>
  <data name="espesor_centro_abr" xml:space="preserve">
    <value>Épaiss. Cent.</value>
  </data>
  <data name="espesor_especial" xml:space="preserve">
    <value>Épaisseur spécial</value>
  </data>
  <data name="espesor_max" xml:space="preserve">
    <value>Épaisseur max.</value>
  </data>
  <data name="espesor_maximo" xml:space="preserve">
    <value>Épaisseur maximum</value>
    <comment>Données techniques</comment>
  </data>
  <data name="espesor_min" xml:space="preserve">
    <value>Épaisseur min.</value>
  </data>
  <data name="espesor_minimo" xml:space="preserve">
    <value>Épaisseur minimum</value>
    <comment>Données techniques</comment>
  </data>
  <data name="espesor_taladro_minimo" xml:space="preserve">
    <value>Épaisseur perforation minime</value>
  </data>
  <data name="espesor_taladro_minimo_abrev" xml:space="preserve">
    <value>Ép. Perf. Min.</value>
  </data>
  <data name="espesor_taladro_nasal" xml:space="preserve">
    <value>Épaisseur perforation nasale</value>
    <comment>Données techniques</comment>
  </data>
  <data name="espesor_taladro_temporal" xml:space="preserve">
    <value>Épaisseur perforation temporale</value>
    <comment>Données techniques</comment>
  </data>
  <data name="establecer_favoritos_a_todos_clientes" xml:space="preserve">
    <value>Établir favoris pour tous les clients</value>
  </data>
  <data name="estado" xml:space="preserve">
    <value>État</value>
    <comment>Écran d’accueil, liste des dernières commandes</comment>
  </data>
  <data name="estadoOD" xml:space="preserve">
    <value>état OD</value>
  </data>
  <data name="estadoOI" xml:space="preserve">
    <value>état OG</value>
  </data>
  <data name="estado_pedido_grabados" xml:space="preserve">
    <value>Enregistrements</value>
  </data>
  <data name="estado_pedido_pendientes" xml:space="preserve">
    <value>En attente de réparation</value>
  </data>
  <data name="estado_pedido_todos" xml:space="preserve">
    <value>Tous</value>
  </data>
  <data name="es_anterior_a" xml:space="preserve">
    <value>est antérieur à</value>
  </data>
  <data name="es_necesario_cerrar_la_aplicacion" xml:space="preserve">
    <value>L’application est correctement installée.{0}Ensuite, cliquez sur Accepter, attendez quelques secondes sa fermeture, puis double-cliquez sur l’icône Indonet qui se trouve sur votre bureau</value>
  </data>
  <data name="expedido" xml:space="preserve">
    <value>Expédié</value>
  </data>
  <data name="expediente" xml:space="preserve">
    <value>Dossier</value>
  </data>
  <data name="explorar" xml:space="preserve">
    <value>Explorer</value>
  </data>
  <data name="exportar" xml:space="preserve">
    <value>Exporter</value>
  </data>
  <data name="favoritas" xml:space="preserve">
    <value>Favoris</value>
  </data>
  <data name="favoritos" xml:space="preserve">
    <value>Favoris</value>
  </data>
  <data name="fecha" xml:space="preserve">
    <value>Date</value>
    <comment>Écran d’accueil, liste des dernières commandes</comment>
  </data>
  <data name="fecha_envio" xml:space="preserve">
    <value>Date d'envoi</value>
  </data>
  <data name="fecha_fin" xml:space="preserve">
    <value>Date fin</value>
  </data>
  <data name="fecha_hi_menor_low" xml:space="preserve">
    <value>La date « jusqu’à » ne peut pas être antérieure à la date « à partir de »</value>
  </data>
  <data name="fecha_hi_nula" xml:space="preserve">
    <value>La date « jusqu’à » ne peut pas être nulle</value>
  </data>
  <data name="fecha_hora_grabacion" xml:space="preserve">
    <value>Date et heure d’enregistrement </value>
  </data>
  <data name="fecha_llegada_real" xml:space="preserve">
    <value>Date d'arrivée:</value>
  </data>
  <data name="fecha_low_nula" xml:space="preserve">
    <value>La date « à partir de » ne peut pas être nulle</value>
  </data>
  <data name="fecha_prevista_envio" xml:space="preserve">
    <value>Date prévue d’envoi</value>
  </data>
  <data name="fecha_prevista_recepcion" xml:space="preserve">
    <value>Date prevue de réception</value>
  </data>
  <data name="fecha_rango" xml:space="preserve">
    <value>Date (plage de 7 jours)</value>
  </data>
  <data name="FicherosFormas" xml:space="preserve">
    <value>Fichiers d’images des formes</value>
    <comment>IndoScan</comment>
  </data>
  <data name="ficheros_adjuntos" xml:space="preserve">
    <value>Fichiers ci-joints</value>
  </data>
  <data name="filtro_descripcion" xml:space="preserve">
    <value>Filtre description</value>
  </data>
  <data name="filtro_fabricante" xml:space="preserve">
    <value>Filtre fabricant</value>
  </data>
  <data name="finalizado" xml:space="preserve">
    <value>Terminé</value>
  </data>
  <data name="finalizar_pedido" xml:space="preserve">
    <value>Terminer commande</value>
    <comment>Envoyer deuxième lunettes</comment>
  </data>
  <data name="fin_ficheros_adjuntos" xml:space="preserve">
    <value>Fin des fichiers ci-joints</value>
  </data>
  <data name="firmar" xml:space="preserve">
    <value>Signer</value>
  </data>
  <data name="foco" xml:space="preserve">
    <value>Foyer</value>
  </data>
  <data name="foco_y_material" xml:space="preserve">
    <value>Foyer et matière</value>
    <comment>Vue données fondamentales œil</comment>
  </data>
  <data name="forma" xml:space="preserve">
    <value>Forme</value>
  </data>
  <data name="formas_fax_enviarFichero" xml:space="preserve">
    <value>Obtenir fichier</value>
  </data>
  <data name="formas_leidas" xml:space="preserve">
    <value>Formes lues</value>
  </data>
  <data name="formas_y_espesores" xml:space="preserve">
    <value>Formes et épaisseurs</value>
    <comment>Écran de commande</comment>
  </data>
  <data name="forma_basica_indicada_no_disponible_en_este_momento" xml:space="preserve">
    <value>La Forme basique indiquée n'est disponible pas en ce moment</value>
    <comment>FFS</comment>
  </data>
  <data name="forma_escogida" xml:space="preserve">
    <value>Forme sélectionnée</value>
  </data>
  <data name="forma_guardada_OK" xml:space="preserve">
    <value>Forme correctement enregistrée</value>
  </data>
  <data name="fotocromatico" xml:space="preserve">
    <value>Photochromique</value>
    <comment>Écran de commande</comment>
  </data>
  <data name="francia" xml:space="preserve">
    <value>France</value>
  </data>
  <data name="frontal" xml:space="preserve">
    <value>Frontal</value>
    <comment>Orientation du biseau</comment>
  </data>
  <data name="ganancia_en_espesor" xml:space="preserve">
    <value>Gain d'épaisseur</value>
  </data>
  <data name="ganancia_en_espesor_abr" xml:space="preserve">
    <value>G. d'épaiss.</value>
  </data>
  <data name="ganancia_en_peso" xml:space="preserve">
    <value>Gain de poid</value>
  </data>
  <data name="ganancia_en_peso_abr" xml:space="preserve">
    <value>G. de poids</value>
  </data>
  <data name="ganancia_espesor" xml:space="preserve">
    <value>Gain d'épaisseur</value>
    <comment>Données techniques</comment>
  </data>
  <data name="ganancia_peso" xml:space="preserve">
    <value>Gain de poids</value>
    <comment>Données techniques</comment>
  </data>
  <data name="generar_informe" xml:space="preserve">
    <value>Signer et générer dossier</value>
  </data>
  <data name="genero" xml:space="preserve">
    <value>Genre</value>
    <comment>IndoMediCare</comment>
  </data>
  <data name="grabar_cambios" xml:space="preserve">
    <value>Sauvegarder changes</value>
  </data>
  <data name="grados" xml:space="preserve">
    <value>Degrés</value>
    <comment>Suppléments</comment>
  </data>
  <data name="graduacion_resultante" xml:space="preserve">
    <value>Correction résultante</value>
  </data>
  <data name="guardar" xml:space="preserve">
    <value>Enregistrer</value>
  </data>
  <data name="guardar_biselado" xml:space="preserve">
    <value>Enregistrer biseau</value>
  </data>
  <data name="guardar_receta" xml:space="preserve">
    <value>Enregistrer commande</value>
    <comment>Menu Actions de la commande</comment>
  </data>
  <data name="hora" xml:space="preserve">
    <value>Heure</value>
  </data>
  <data name="idioma" xml:space="preserve">
    <value>Langue</value>
    <comment>Fenêtre d’identification</comment>
  </data>
  <data name="importar" xml:space="preserve">
    <value>Importer</value>
  </data>
  <data name="imprimir" xml:space="preserve">
    <value>Imprimer</value>
    <comment>Menu Actions de la commande</comment>
  </data>
  <data name="imprimir_lista" xml:space="preserve">
    <value>Imprimer liste</value>
  </data>
  <data name="imprimir_pedidos_seleccionados" xml:space="preserve">
    <value>Imprimer commandes sélectionnées</value>
  </data>
  <data name="indice" xml:space="preserve">
    <value>Indice</value>
    <comment>Vue données fondamentales œil</comment>
  </data>
  <data name="indobisel" xml:space="preserve">
    <value>» Inbobisel</value>
  </data>
  <data name="indobox" xml:space="preserve">
    <value>Indobox</value>
  </data>
  <data name="indoCenter_popup_titulo" xml:space="preserve">
    <value>Sélectionner lectures d'IndoCenter</value>
  </data>
  <data name="indonet" xml:space="preserve">
    <value>Indonet</value>
  </data>
  <data name="indo_en_el_mundo" xml:space="preserve">
    <value>» Indo dans le monde</value>
  </data>
  <data name="inferior" xml:space="preserve">
    <value>Inférieur </value>
  </data>
  <data name="infolog_actualizacion_oferta" xml:space="preserve">
    <value>Infolog actualisation offre</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="infolog_cabecera_oferta_ok" xml:space="preserve">
    <value>Infolog de tête offre ok</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="infolog_creacion_bbdd_local" xml:space="preserve">
    <value>Infolog creation base de données locale</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="infolog_creacion_bbdd_local_OK" xml:space="preserve">
    <value>Infolog creation base de données locale OK</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="infolog_grabar_oferta" xml:space="preserve">
    <value>Infolog enregistrer offre</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="infolog_grabar_oferta_OK" xml:space="preserve">
    <value>Infolog enregistrer offre OK</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="infolog_oferta_descargada_ok" xml:space="preserve">
    <value>Infolog offre déchargée OK </value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="informacion" xml:space="preserve">
    <value>Information </value>
  </data>
  <data name="info_creacion_bbdd_local" xml:space="preserve">
    <value>***&gt;Début création de BBDD locale</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="info_creacion_bbdd_local_OK" xml:space="preserve">
    <value>***&gt;Création BBDD locale OK</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="info_envio_pedido_revisar" xml:space="preserve">
    <value>Vôtre commande à été enregistrée. Indo la verifierá et la produirá en bref</value>
  </data>
  <data name="info_grabar_oferta" xml:space="preserve">
    <value>***&gt;Début enregistrement offre dans BBDD locale</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="info_grabar_oferta_OK" xml:space="preserve">
    <value>***&gt;Enregistrer offre dans BBDD locale OK</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="info_no_fabricara" xml:space="preserve">
    <value>Cet verre ne sera pas fabriqué</value>
  </data>
  <data name="info_ojo_informativo" xml:space="preserve">
    <value>L’oeil informatif ne sera pas fabriqué</value>
  </data>
  <data name="info_pedido_procesado" xml:space="preserve">
    <value>Votre commande avec référence {0} a été enregistrée et traitée correctement</value>
  </data>
  <data name="inicio" xml:space="preserve">
    <value>Accueil</value>
    <comment>Barre de navigation principale</comment>
  </data>
  <data name="insertar" xml:space="preserve">
    <value>Insérer</value>
  </data>
  <data name="IntentarOtraVez" xml:space="preserve">
    <value>Merci de bien vouloir essayer à nouveau en utilisant une autre image ou contacter avec INDO</value>
    <comment>IndoScan</comment>
  </data>
  <data name="intervalo_de_fechas" xml:space="preserve">
    <value>Intervalle de dates</value>
  </data>
  <data name="isotipo_en_maxvita" xml:space="preserve">
    <value>ISOTYPE</value>
  </data>
  <data name="isotipo_rodenstock" xml:space="preserve">
    <value>ISOTYPE RODENSTOCK</value>
  </data>
  <data name="italia" xml:space="preserve">
    <value>Italie</value>
  </data>
  <data name="jobs_omain" xml:space="preserve">
    <value>COMMANDES (OMAIN)</value>
  </data>
  <data name="laboratorio" xml:space="preserve">
    <value>Laboratoire</value>
  </data>
  <data name="lente" xml:space="preserve">
    <value>Verre</value>
    <comment>Écran d’accueil, liste des dernières commandes</comment>
  </data>
  <data name="login_verificar" xml:space="preserve">
    <value>VÉRIFIER</value>
  </data>
  <data name="logout" xml:space="preserve">
    <value>Se déconnecter</value>
    <comment>Barre d’outils</comment>
  </data>
  <data name="log_actualizacion_oferta" xml:space="preserve">
    <value>***&gt;Début mise à jour offre</value>
  </data>
  <data name="log_cabecera_oferta_ok" xml:space="preserve">
    <value>***&gt;Entête offre OK</value>
  </data>
  <data name="log_oferta_descargada_ok" xml:space="preserve">
    <value>***&gt;Offre téléchargée OK</value>
  </data>
  <data name="l_taladrada" xml:space="preserve">
    <value>Plan</value>
    <comment>Type de biseau</comment>
  </data>
  <data name="macros_talla" xml:space="preserve">
    <value>Macros de taille</value>
  </data>
  <data name="mail_informacion_estado_pedidos" xml:space="preserve">
    <value>Courrier électronique d'était des commandes</value>
    <comment>Ma boîte aux lettres</comment>
  </data>
  <data name="marca" xml:space="preserve">
    <value>Marque</value>
  </data>
  <data name="marcar_todos" xml:space="preserve">
    <value>Marquer tout</value>
  </data>
  <data name="mas_informacion" xml:space="preserve">
    <value>Plus d'information</value>
  </data>
  <data name="mensaje" xml:space="preserve">
    <value>Message</value>
  </data>
  <data name="mensaje_pedido_duplicado" xml:space="preserve">
    <value>ATTENTION. Possible Commande Dupliqué. Réalisée en date de: {0}. Avec le nº de {1}: {2}</value>
  </data>
  <data name="mensaje_solicitud_clave" xml:space="preserve">
    <value>Pour disposer d’un mot de passe, il suffit d'introduire une adresse électronique correcte et votre code de client Indo.{0}Vous recevrez rapidement votre mot de passe pour entrer dans Indonet.{0} Merci beaucoup</value>
  </data>
  <data name="metal" xml:space="preserve">
    <value>Métal</value>
    <comment>type de monture</comment>
  </data>
  <data name="mini_bisel" xml:space="preserve">
    <value>Minibiseau</value>
    <comment>Type de biseau</comment>
  </data>
  <data name="mis_biselados" xml:space="preserve">
    <value>Mes montages</value>
  </data>
  <data name="mi_buzon" xml:space="preserve">
    <value>Boîte aux lettres</value>
    <comment>Ma boîte aux lettres</comment>
  </data>
  <data name="modelo" xml:space="preserve">
    <value>Modèle</value>
  </data>
  <data name="modificar" xml:space="preserve">
    <value>Modifier</value>
  </data>
  <data name="modificar_espesor" xml:space="preserve">
    <value>Modifier épaisseur</value>
  </data>
  <data name="montaje" xml:space="preserve">
    <value>Montage</value>
  </data>
  <data name="montura" xml:space="preserve">
    <value>Monture</value>
  </data>
  <data name="monturas" xml:space="preserve">
    <value>Montures</value>
    <comment>Écran de commande</comment>
  </data>
  <data name="montura_seleccionada" xml:space="preserve">
    <value>Forme sélectionné</value>
    <comment>Forme lointaine</comment>
  </data>
  <data name="mostrar_lentes_stock" xml:space="preserve">
    <value>Afficher verres de stock uniquement</value>
    <comment>Vue données fondamentales œil</comment>
  </data>
  <data name="motivos" xml:space="preserve">
    <value>Motives</value>
  </data>
  <data name="motivo_no_definido" xml:space="preserve">
    <value>Le motif n'est pas définé en le formulaire</value>
  </data>
  <data name="msg_Prerequisitos" xml:space="preserve">
    <value>L'installation des programmes pour capturer et de traiter les images pour obtenir la forme de la lentille démarrera automatiquement.</value>
    <comment>IndoScan</comment>
  </data>
  <data name="nasal" xml:space="preserve">
    <value>Nasal</value>
  </data>
  <data name="nd" xml:space="preserve">
    <value>Nd</value>
    <comment>Données techniques</comment>
  </data>
  <data name="ninguna" xml:space="preserve">
    <value>Aucun</value>
  </data>
  <data name="no" xml:space="preserve">
    <value>NO</value>
  </data>
  <data name="nombre" xml:space="preserve">
    <value>Nom</value>
  </data>
  <data name="NoPosibleObtenerForma" xml:space="preserve">
    <value>Impossible d’obtenir la forme à partir de cette image</value>
    <comment>IndoScan</comment>
  </data>
  <data name="noticias" xml:space="preserve">
    <value>Actualités</value>
    <comment>Écran d’accueil</comment>
  </data>
  <data name="no_esta_bien_formado" xml:space="preserve">
    <value>N’est pas bien formé</value>
    <comment>Validations</comment>
  </data>
  <data name="no_es_multiplo_de" xml:space="preserve">
    <value>N’est pas multiple de</value>
    <comment>Validations</comment>
  </data>
  <data name="no_favoritas" xml:space="preserve">
    <value>NO FAVORIS</value>
  </data>
  <data name="no_hay_motivos" xml:space="preserve">
    <value>Ne pas de motives</value>
  </data>
  <data name="no_mostrar_aviso" xml:space="preserve">
    <value>Ne pas montrer cette annonce</value>
  </data>
  <data name="no_se_actualiza_la_oferta" xml:space="preserve">
    <value>L’offre ne se met pas à jour</value>
    <comment>Journaux &amp; erreurs</comment>
  </data>
  <data name="no_se_ha_podido_enviar_la_solucitud" xml:space="preserve">
    <value>Impossible d’envoyer la demande, veuillez réessayer plus tard</value>
    <comment>popupMail</comment>
  </data>
  <data name="nueva_oferta_actualizada_correctamente" xml:space="preserve">
    <value>Offre mise à jour correctement</value>
  </data>
  <data name="nueva_prev" xml:space="preserve">
    <value>Nouvelle prév.</value>
    <comment>Entête liste commandes</comment>
  </data>
  <data name="nueva_prevision" xml:space="preserve">
    <value>Nouvelle prévision</value>
    <comment>Écran d’accueil, liste des dernières commandes</comment>
  </data>
  <data name="nuevo" xml:space="preserve">
    <value>Nouveau</value>
  </data>
  <data name="numero_pedido" xml:space="preserve">
    <value>Numéro commande </value>
  </data>
  <data name="n_abbe" xml:space="preserve">
    <value>Nº Abbe</value>
    <comment>Données techniques</comment>
  </data>
  <data name="n_pedido" xml:space="preserve">
    <value>Nº commande</value>
    <comment>Écran d’accueil, liste des dernières commandes</comment>
  </data>
  <data name="obligatorio_seleccionar_destiantario" xml:space="preserve">
    <value>Veuillez informer le destinataire de la marchandise</value>
  </data>
  <data name="observaciones" xml:space="preserve">
    <value>Remarques</value>
  </data>
  <data name="OD" xml:space="preserve">
    <value>OD</value>
    <comment>Œil droit</comment>
  </data>
  <data name="oferta" xml:space="preserve">
    <value>Offre</value>
  </data>
  <data name="oferta_actualizada" xml:space="preserve">
    <value>Offre mise à jour...</value>
  </data>
  <data name="OI" xml:space="preserve">
    <value>OG</value>
    <comment>Œil Gauche</comment>
  </data>
  <data name="OjoDerecho" xml:space="preserve">
    <value>Œil droit</value>
    <comment>Choisir oeil</comment>
  </data>
  <data name="OjoIzquierdo" xml:space="preserve">
    <value>Œil gauche</value>
    <comment>Choisir oeil</comment>
  </data>
  <data name="ojo_derecho" xml:space="preserve">
    <value>Œil droit</value>
    <comment>Envoyer commande</comment>
  </data>
  <data name="ojo_informativo" xml:space="preserve">
    <value>Œil d’information</value>
    <comment>Vue données fondamentales œil</comment>
  </data>
  <data name="ojo_izquierdo" xml:space="preserve">
    <value>Œil Gauche</value>
    <comment>Envoyer commande</comment>
  </data>
  <data name="optimizacion_freemax" xml:space="preserve">
    <value>Optimisation FreeMax</value>
    <comment>Légende du precalibré digital pour MAXIMA</comment>
  </data>
  <data name="orientacion_del_bisel" xml:space="preserve">
    <value>Orientation du biseau</value>
  </data>
  <data name="otros_datos" xml:space="preserve">
    <value>AUTRES DONNÉES</value>
  </data>
  <data name="parametros_proxy" xml:space="preserve">
    <value>Paramètres de connexion au Proxy</value>
  </data>
  <data name="paso1" xml:space="preserve">
    <value>Étape 1</value>
  </data>
  <data name="paso2" xml:space="preserve">
    <value>Étape 2</value>
  </data>
  <data name="password" xml:space="preserve">
    <value>Mot de passe</value>
  </data>
  <data name="password_internet" xml:space="preserve">
    <value>Mot de passe d'Internet</value>
    <comment>proxy</comment>
  </data>
  <data name="pedido" xml:space="preserve">
    <value>Commande</value>
  </data>
  <data name="pedidos_al_por_mayor" xml:space="preserve">
    <value>Commandes en gros</value>
  </data>
  <data name="pedidos_obtenidos" xml:space="preserve">
    <value>Commande(s) obtenue(s)</value>
  </data>
  <data name="pedidos_realizados" xml:space="preserve">
    <value>Commandes réalisées</value>
    <comment>Barre de navigation principale</comment>
  </data>
  <data name="pedido_con_errores" xml:space="preserve">
    <value>Impossible d’envoyer la commande. Révisez les alertes</value>
    <comment>Alertes</comment>
  </data>
  <data name="pedido_con_montura" xml:space="preserve">
    <value>Commande avec monture</value>
  </data>
  <data name="pedido_duplicado" xml:space="preserve">
    <value>COMMANDE DUPLIQUÉ</value>
  </data>
  <data name="pedir_montura" xml:space="preserve">
    <value>Demander Monture</value>
    <comment>Forme lointaine</comment>
  </data>
  <data name="pendiente_diagnostico" xml:space="preserve">
    <value>En suspens de diagnostique</value>
  </data>
  <data name="peso" xml:space="preserve">
    <value>Poids</value>
    <comment>Données techniques</comment>
  </data>
  <data name="peticiones_talla" xml:space="preserve">
    <value>Demandes de détourage</value>
  </data>
  <data name="plano" xml:space="preserve">
    <value>Plan</value>
    <comment>Type de biseau</comment>
  </data>
  <data name="portugal" xml:space="preserve">
    <value>Portugal</value>
  </data>
  <data name="por_favor_confirma_datos_pedido" xml:space="preserve">
    <value>Veuillez confirmer les données de votre commande :</value>
    <comment>Envoyer commande</comment>
  </data>
  <data name="precalibrado" xml:space="preserve">
    <value>Précalibrage</value>
  </data>
  <data name="precalibrado_a_cotas" xml:space="preserve">
    <value>Précalibrage aux cotes</value>
  </data>
  <data name="precalibrado_digital" xml:space="preserve">
    <value>Précalibrage numérique</value>
  </data>
  <data name="precalibrado_digital_abr" xml:space="preserve">
    <value>Precal.</value>
  </data>
  <data name="precio" xml:space="preserve">
    <value>Prix</value>
  </data>
  <data name="PreguntaElegirOjo" xml:space="preserve">
    <value>Sélectionner œil droit ou gauche</value>
    <comment>Elegir ojo</comment>
  </data>
  <data name="pregunta_borrar_pedido" xml:space="preserve">
    <value>Êtes-vous sûr(e) de supprimer la commande avec numéro {0}?</value>
  </data>
  <data name="pregunta_montura_y_lentes_biseladas" xml:space="preserve">
    <value>Monture et verres biseautés</value>
  </data>
  <data name="pregunta_montura_y_lentes_montadas" xml:space="preserve">
    <value>Montures et verres  montés</value>
  </data>
  <data name="pregunta_montura_y_lentes_sin_biselar" xml:space="preserve">
    <value>Monture et verres  non biseautés (verre associé a la monture demandé)</value>
  </data>
  <data name="pregunta_solo_lentes_biseladas" xml:space="preserve">
    <value>Seulement verres biseautés</value>
  </data>
  <data name="pregunta_solo_lentes_sin_biselar" xml:space="preserve">
    <value>Seulement verres non biseautés</value>
  </data>
  <data name="prevision" xml:space="preserve">
    <value>Prévision</value>
    <comment>Écran d’accueil, liste des dernières commandes</comment>
  </data>
  <data name="prioridad" xml:space="preserve">
    <value>Priorité</value>
  </data>
  <data name="prisma" xml:space="preserve">
    <value>Prisme</value>
  </data>
  <data name="prisma_1" xml:space="preserve">
    <value>Prisme 1</value>
    <comment>Suppléments</comment>
  </data>
  <data name="prisma_2" xml:space="preserve">
    <value>Prisme 2</value>
    <comment>Suppléments</comment>
  </data>
  <data name="producto_lente" xml:space="preserve">
    <value>Produit (verre)</value>
  </data>
  <data name="puente" xml:space="preserve">
    <value>Pont</value>
    <comment>Données supplémentaires</comment>
  </data>
  <data name="quitar_seleccion" xml:space="preserve">
    <value>Enlever la sélection</value>
  </data>
  <data name="radio" xml:space="preserve">
    <value>Rayon</value>
    <comment>Données techniques</comment>
  </data>
  <data name="ranurada" xml:space="preserve">
    <value>Rainurée</value>
    <comment>type de monture</comment>
  </data>
  <data name="ranurada_metal" xml:space="preserve">
    <value>Rainurée Métal</value>
    <comment>Type de biseau</comment>
  </data>
  <data name="ranurada_nylon" xml:space="preserve">
    <value>Rainurée Nylon</value>
    <comment>Type de biseau</comment>
  </data>
  <data name="realizados_pedidos" xml:space="preserve">
    <value>Commandes realisées</value>
  </data>
  <data name="realizar_pedido" xml:space="preserve">
    <value>Réaliser commande</value>
    <comment>Barre de navigation principale</comment>
  </data>
  <data name="receta" xml:space="preserve">
    <value>Commande Enregistrée</value>
  </data>
  <data name="recetas_borrado" xml:space="preserve">
    <value>Suppression de commandes</value>
  </data>
  <data name="recetas_borrar" xml:space="preserve">
    <value>Supprimer commandes</value>
  </data>
  <data name="recetas_enviar" xml:space="preserve">
    <value>Envoyer commandes</value>
  </data>
  <data name="recetas_envio" xml:space="preserve">
    <value>Envoi de commandes</value>
  </data>
  <data name="recetas_guardadas" xml:space="preserve">
    <value>Commandes enregistrées</value>
    <comment>Barre de navigation principale</comment>
  </data>
  <data name="recetas_obtenidas" xml:space="preserve">
    <value>Commande(s) obtenue(s)</value>
  </data>
  <data name="receta_envio_masivo" xml:space="preserve">
    <value>Commande générée massivement</value>
  </data>
  <data name="receta_guardada" xml:space="preserve">
    <value>La commande est correctement enregistrée</value>
  </data>
  <data name="rechazado" xml:space="preserve">
    <value>Rejeté</value>
  </data>
  <data name="rechazado_oftalmologo" xml:space="preserve">
    <value>Rejeté ophtalmologue</value>
  </data>
  <data name="rechazar" xml:space="preserve">
    <value>Rejeter</value>
  </data>
  <data name="rechazar_caso" xml:space="preserve">
    <value>Retourner case au opticien</value>
  </data>
  <data name="recibido_de_oftalmologo" xml:space="preserve">
    <value>Reçú de l'ophtalmologue</value>
  </data>
  <data name="recibido_de_optico" xml:space="preserve">
    <value>Reçu de l'opticien</value>
  </data>
  <data name="recordar_mis_credenciales" xml:space="preserve">
    <value>Mémoriser mes informations d’identification</value>
  </data>
  <data name="recuperar_imagenes" xml:space="preserve">
    <value>Récupérer</value>
  </data>
  <data name="redimensionar" xml:space="preserve">
    <value>Redimensionner</value>
  </data>
  <data name="referencia" xml:space="preserve">
    <value>Référence</value>
    <comment>Écran de commande</comment>
  </data>
  <data name="ref_cliente" xml:space="preserve">
    <value>Réf.Client</value>
    <comment>Écran d’accueil, liste des dernières commandes</comment>
  </data>
  <data name="registros" xml:space="preserve">
    <value>Registres</value>
  </data>
  <data name="reiniciar_fecha_oferta" xml:space="preserve">
    <value>Recharger l'offre</value>
  </data>
  <data name="reiniciar_fecha_oferta1" xml:space="preserve">
    <value>Recharger l'offre</value>
  </data>
  <data name="resto_de_paises" xml:space="preserve">
    <value>Autres pays</value>
    <comment>Login</comment>
  </data>
  <data name="resultados" xml:space="preserve">
    <value>Résultats</value>
  </data>
  <data name="rx" xml:space="preserve">
    <value>RX</value>
    <comment>Écran d’accueil, liste des dernières commandes</comment>
  </data>
  <data name="salir" xml:space="preserve">
    <value>Quitter</value>
  </data>
  <data name="segunda_gafa" xml:space="preserve">
    <value>Deuxième lunette</value>
    <comment>Configuration opticien</comment>
  </data>
  <data name="segura" xml:space="preserve">
    <value>SÉCURISÉE</value>
  </data>
  <data name="seguro_que_quiere_desconectarse" xml:space="preserve">
    <value>Êtes-vous sûr(e) de vouloir quitter ?</value>
    <comment>Alertes</comment>
  </data>
  <data name="seguro_que_quiere_salir" xml:space="preserve">
    <value>Êtes-vous sûr(e) de vouloir quitter l’application ?</value>
    <comment>Alertes</comment>
  </data>
  <data name="selecciona" xml:space="preserve">
    <value>Sélectionnez</value>
  </data>
  <data name="seleccionar_todos" xml:space="preserve">
    <value>Sélectionner toutes</value>
  </data>
  <data name="selecciona_abrv" xml:space="preserve">
    <value>Sélec.</value>
  </data>
  <data name="selecciona_cliente" xml:space="preserve">
    <value>Sélectionnez le client</value>
  </data>
  <data name="selecciona_la_lente_deseada" xml:space="preserve">
    <value>Sélectionnez la verre désiré</value>
  </data>
  <data name="seleccion_de_espesores" xml:space="preserve">
    <value>Sélection d’épaisseurs</value>
  </data>
  <data name="seleccion_vacia_recetas" xml:space="preserve">
    <value>Aucune commande n’a été sélectionnée</value>
  </data>
  <data name="SelEscaner" xml:space="preserve">
    <value>Sélectionner scanner</value>
    <comment>IndoScan</comment>
  </data>
  <data name="semiterminados" xml:space="preserve">
    <value>Semifinis</value>
  </data>
  <data name="setup_laboratorios" xml:space="preserve">
    <value>Lab paramètres</value>
  </data>
  <data name="se_actualizo" xml:space="preserve">
    <value>mise à jour</value>
  </data>
  <data name="si" xml:space="preserve">
    <value>OUI</value>
  </data>
  <data name="siguiente" xml:space="preserve">
    <value>Suivant</value>
  </data>
  <data name="simulador_de_lentes" xml:space="preserve">
    <value>Simulateur des verres</value>
  </data>
  <data name="simulador_error" xml:space="preserve">
    <value>Erreur en lançant le simulateur des verres</value>
  </data>
  <data name="simulador_no_instalado" xml:space="preserve">
    <value>Le simulateur des verres n’est pas installé</value>
  </data>
  <data name="sin_coloracion" xml:space="preserve">
    <value>Sans coloration</value>
  </data>
  <data name="sin_ficheros_adjuntos" xml:space="preserve">
    <value>sans fichiers joints</value>
  </data>
  <data name="sin_fotocromatico" xml:space="preserve">
    <value>Non photochromique</value>
  </data>
  <data name="sin_observaciones" xml:space="preserve">
    <value>sans observations</value>
  </data>
  <data name="sin_precalibrado" xml:space="preserve">
    <value>Sans précalibrage</value>
  </data>
  <data name="sistema_pedidos_por_mayor" xml:space="preserve">
    <value>Sistème de commandes en gros</value>
  </data>
  <data name="situacion_de_los_pedidos" xml:space="preserve">
    <value>Situation des commandes</value>
  </data>
  <data name="solicitar_acceso" xml:space="preserve">
    <value>Demander accès</value>
  </data>
  <data name="solicitar_clave_acceso" xml:space="preserve">
    <value>» Demander mot de passe d’accès</value>
  </data>
  <data name="solicitud_acceso" xml:space="preserve">
    <value>Demande d'Accès</value>
  </data>
  <data name="solicitud_enviada_correctamente" xml:space="preserve">
    <value>La demande d’accès a été correctement envoyée</value>
    <comment>popupMail</comment>
  </data>
  <data name="solo_stock" xml:space="preserve">
    <value>Seul Stock</value>
  </data>
  <data name="stock" xml:space="preserve">
    <value>Stock</value>
  </data>
  <data name="subir_imagenes" xml:space="preserve">
    <value>Télécharger images</value>
  </data>
  <data name="sugerencias" xml:space="preserve">
    <value>Suggestions</value>
    <comment>Barre d’outils</comment>
  </data>
  <data name="superior" xml:space="preserve">
    <value>Supérieur</value>
  </data>
  <data name="suplementos" xml:space="preserve">
    <value>Suppléments</value>
    <comment>Données fondamentales</comment>
  </data>
  <data name="taladrada" xml:space="preserve">
    <value>Perforée</value>
    <comment>type de monture</comment>
  </data>
  <data name="talla" xml:space="preserve">
    <value>Taille</value>
  </data>
  <data name="tema" xml:space="preserve">
    <value>Sujet</value>
  </data>
  <data name="temporal" xml:space="preserve">
    <value>Temporal</value>
  </data>
  <data name="texto" xml:space="preserve">
    <value>Texte</value>
  </data>
  <data name="texto_direcciones_email" xml:space="preserve">
    <value>Adresses où vous voulez recevoir cette information</value>
    <comment>Ma boîte aux lettres</comment>
  </data>
  <data name="texto_medCare_1" xml:space="preserve">
    <value>Bienvenus a la nouvelle plateforme de télémédecine d'IndoMedCare</value>
  </data>
  <data name="texto_pedidos_en_curso" xml:space="preserve">
    <value>Commandes en cours (1 courrier électronique par jour)</value>
    <comment>Ma boîte aux lettres</comment>
  </data>
  <data name="texto_pedidos_en_envio" xml:space="preserve">
    <value>Commandes à recevoir à chaque envoi (1 courrier électronique par envoi)</value>
    <comment>Ma boîte aux lettres</comment>
  </data>
  <data name="texto_presentacion1" xml:space="preserve">
    <value>Bienvenue à la nouvelle génération de commandes en ligne.</value>
  </data>
  <data name="texto_presentacion2" xml:space="preserve">
    <value>C’est ici que commence le chemin de la nouvelle Indo !</value>
  </data>
  <data name="texto_presentacion3" xml:space="preserve">
    <value>Vous serez étonnés par l'outil le plus agile et intuitif du marché pour réaliser vos commandes. Avec des fonctions qui vous permettront d'augmenter la valeur ajoutée de vos ventes.</value>
  </data>
  <data name="texto_presentacion4" xml:space="preserve">
    <value>Personnaliser et optimiser l'épaisseur des verres de lab.</value>
  </data>
  <data name="texto_presentacion5" xml:space="preserve">
    <value>Utiliser la majorité des traceurs du marché.</value>
  </data>
  <data name="texto_presentacion6" xml:space="preserve">
    <value>Faire un suivi plus détaillé de vos commandes.</value>
  </data>
  <data name="texto_presentacion7" xml:space="preserve">
    <value>Consultez votre délégué commercial pour l’option la plus adaptée à tous vos besoins.</value>
  </data>
  <data name="texto_si_deseas_recibir_mails" xml:space="preserve">
    <value>Si vous voulez recevoir des informations de vos commandes, choissisez une des options suivantes :</value>
    <comment>Ma boîte aux lettres</comment>
  </data>
  <data name="text_info_contacto" xml:space="preserve">
    <value>Si vous avez besoin de plus d’informations, contactez-nous à :</value>
    <comment>Fenêtre d’identification</comment>
  </data>
  <data name="tipo_bisel" xml:space="preserve">
    <value>Type biseau</value>
  </data>
  <data name="tipo_de_bisel" xml:space="preserve">
    <value>Type de biseau</value>
  </data>
  <data name="tipo_de_montura" xml:space="preserve">
    <value>Type de monture</value>
  </data>
  <data name="tipo_lente" xml:space="preserve">
    <value>Type de verre</value>
  </data>
  <data name="tipo_montura" xml:space="preserve">
    <value>Type monture</value>
  </data>
  <data name="titulo_reposicion_color" xml:space="preserve">
    <value>AVIS REPOSITION DE COULEUR</value>
  </data>
  <data name="tono" xml:space="preserve">
    <value>Teinte</value>
    <comment>Écran de commande</comment>
  </data>
  <data name="toolTip_abrir_indoscan" xml:space="preserve">
    <value>Ouvrir INDOScan</value>
  </data>
  <data name="tooltip_anadir_montura_en_tracer" xml:space="preserve">
    <value>Monture sélectioné en cette commande</value>
    <comment>Forme lointaine</comment>
  </data>
  <data name="tooltip_biselar_por_defecto" xml:space="preserve">
    <value>Lorsqu'il est sélectionné, quand nous faisons une commande avec forme le biseauté sera marquée automatiquement. Vous la pouvez toujours désactiver.</value>
  </data>
  <data name="tooltip_biselar_sin_pulir" xml:space="preserve">
    <value>Pour montures d'acétate et métal le biseau sera toujours sans polir</value>
  </data>
  <data name="tooltip_boton_anadir_montura" xml:space="preserve">
    <value>Sélectionner une monture pour cette commande</value>
    <comment>Forme lointaine</comment>
  </data>
  <data name="tooltip_boton_importar" xml:space="preserve">
    <value>Importer une forme à partir d'un fichier</value>
  </data>
  <data name="tooltip_boton_iniciar" xml:space="preserve">
    <value>Montrer tous les lectures de INDOBISEL</value>
  </data>
  <data name="tooltip_boton_ultima" xml:space="preserve">
    <value>Charger la dernière forme lue dans Indobisel</value>
  </data>
  <data name="tratamientos" xml:space="preserve">
    <value>Traitements</value>
    <comment>Vue données fondamentales œil</comment>
  </data>
  <data name="tratamiento_coloracion" xml:space="preserve">
    <value>Traitement et coloration</value>
  </data>
  <data name="trazador" xml:space="preserve">
    <value>Traceur</value>
  </data>
  <data name="trazadores" xml:space="preserve">
    <value>Traceurs</value>
  </data>
  <data name="trazadores_anadir" xml:space="preserve">
    <value>Ajouter…</value>
  </data>
  <data name="trazadores_borrar" xml:space="preserve">
    <value>Supprimer</value>
  </data>
  <data name="trazadores_borrar_todos" xml:space="preserve">
    <value>Supprimer tous</value>
  </data>
  <data name="trazadores_comprobacion" xml:space="preserve">
    <value>Vérification</value>
  </data>
  <data name="trazadores_conexion_agente_trazador_OK" xml:space="preserve">
    <value>L’AGENT TRACEUR \ {0 } EST CORRECTEMENT CONNECTÉ</value>
  </data>
  <data name="trazadores_direccion" xml:space="preserve">
    <value>Adresse</value>
  </data>
  <data name="trazadores_modificar" xml:space="preserve">
    <value>Modifier</value>
  </data>
  <data name="trazadores_nombre_agente" xml:space="preserve">
    <value>Nom agent</value>
  </data>
  <data name="trazadores_popup_anular" xml:space="preserve">
    <value>Annuler</value>
  </data>
  <data name="trazadores_popup_titulo" xml:space="preserve">
    <value>Sélection / Modification agent traceur</value>
  </data>
  <data name="trazadores_puerto" xml:space="preserve">
    <value>Port</value>
  </data>
  <data name="trazadores_trazador" xml:space="preserve">
    <value>Traceur</value>
  </data>
  <data name="trazador_busqueda_ordenadores_red" xml:space="preserve">
    <value>Sélectionner l’équipement sur le réseau</value>
  </data>
  <data name="tto_adicional" xml:space="preserve">
    <value>Trait. Supplémentaire</value>
    <comment>Vue données fondamentales œil</comment>
  </data>
  <data name="tutoriales" xml:space="preserve">
    <value>Tutoriaux</value>
    <comment>Barre d’outils</comment>
  </data>
  <data name="t_por_ciento" xml:space="preserve">
    <value>T%</value>
    <comment>Données techniques</comment>
  </data>
  <data name="ultimos_pedidos" xml:space="preserve">
    <value>Dernières commandes</value>
    <comment>Écran d’accueil</comment>
  </data>
  <data name="url_contacto_indobox" xml:space="preserve">
    <value>www.indobox-indo.com</value>
  </data>
  <data name="url_indonet_direct" xml:space="preserve">
    <value>http://pedidos.direc.indo.es/</value>
    <comment>Liens </comment>
  </data>
  <data name="url_indo_en_el_mundo" xml:space="preserve">
    <value>http://www.indo.es/indo-en-el-mundo/indo_enelmundo.asp</value>
    <comment>Liens</comment>
  </data>
  <data name="url_mas_informacion" xml:space="preserve">
    <value>http://www.indo.es/lentes/lentes_lentesindo.asp</value>
    <comment>Liens</comment>
  </data>
  <data name="url_tutoriales" xml:space="preserve">
    <value>http://www.indo.es/fr/optics/tutorials</value>
    <comment>Liens</comment>
  </data>
  <data name="usuario" xml:space="preserve">
    <value>Utilisateur</value>
  </data>
  <data name="usuario_internet" xml:space="preserve">
    <value>Utilisateur d'Internet</value>
    <comment>proxy</comment>
  </data>
  <data name="valores_por_defecto" xml:space="preserve">
    <value>Valeurs par défaut</value>
  </data>
  <data name="valor_fuera_de_margenes" xml:space="preserve">
    <value>Valeurs en dehors des marges pour {2}. Valeurs authorisées entre {0} et {1}.</value>
  </data>
  <data name="ventana_espera_actualizar_pedido" xml:space="preserve">
    <value>MISE À JOUR COMMANDE EN COURS</value>
  </data>
  <data name="ventana_espera_areaventa" xml:space="preserve">
    <value>CHARGEMENT ZONE DE VENTE EN COURS</value>
  </data>
  <data name="ventana_espera_borrar_forma" xml:space="preserve">
    <value>SUPPRESSION DE LA FORME</value>
    <comment>Supprimé MaBiseauté</comment>
  </data>
  <data name="ventana_espera_borrar_pedidos" xml:space="preserve">
    <value>SUPPRESSION DE LA COMMANDE {0}</value>
  </data>
  <data name="ventana_espera_borrar_recetas" xml:space="preserve">
    <value>SUPPRESSION ORDONNANCES EN COURS</value>
  </data>
  <data name="ventana_espera_busqueda_pedidos" xml:space="preserve">
    <value>RECHERCHE COMMANDES EN COURS</value>
  </data>
  <data name="ventana_espera_busqueda_recetas" xml:space="preserve">
    <value>RECHERCHE ORDONNANCES EN COURS</value>
  </data>
  <data name="ventana_espera_cambio_clave" xml:space="preserve">
    <value>SAUVEGARDE NOUVELLE MOT DE PASSE EN COURS</value>
  </data>
  <data name="ventana_espera_cargando_clientes" xml:space="preserve">
    <value>RÉCUPERANT CLIENTS</value>
  </data>
  <data name="ventana_espera_carga_pedido" xml:space="preserve">
    <value>CHARGEMENT EN COURS COMMANDE {0}</value>
  </data>
  <data name="ventana_espera_carga_pedidos_inicial" xml:space="preserve">
    <value>CHARGEMENT LISTE DE COMMANDES INITIALE EN COURS</value>
  </data>
  <data name="ventana_espera_carga_receta" xml:space="preserve">
    <value>CHARGEMENT EN COURS ORDONNANCE {0}</value>
  </data>
  <data name="ventana_espera_comparando_lentes" xml:space="preserve">
    <value>COMPARANT VERRES</value>
  </data>
  <data name="ventana_espera_configuracion" xml:space="preserve">
    <value>CHARGEMENT CONFIGURATION EN COURS</value>
  </data>
  <data name="ventana_espera_consulta_anulable" xml:space="preserve">
    <value>Consultez si la commande {0} est annulable</value>
  </data>
  <data name="ventana_espera_destinatarios" xml:space="preserve">
    <value>CHARGEMENT DESTINATAIRES EN COURS</value>
  </data>
  <data name="ventana_espera_envio_correo" xml:space="preserve">
    <value>ENVOI COURRIER EN COURS</value>
  </data>
  <data name="ventana_espera_envio_pedidos" xml:space="preserve">
    <value>ENVOI COMMANDE EN COURS</value>
  </data>
  <data name="ventana_espera_envio_recetas" xml:space="preserve">
    <value>ENVOI COMMANDES EN COURS</value>
  </data>
  <data name="ventana_espera_esperar" xml:space="preserve">
    <value>Patienter…</value>
  </data>
  <data name="ventana_espera_guardar_configuracion" xml:space="preserve">
    <value>SAUVEGARDE CONFIGURATION EN COURS</value>
  </data>
  <data name="ventana_espera_guardar_datos" xml:space="preserve">
    <value>SAUVEGARDE DONNÉES EN COURS</value>
    <comment>FFS</comment>
  </data>
  <data name="ventana_espera_guardar_datos_optica" xml:space="preserve">
    <value>SAUVEGARDE DONNÉES OPTICIEN EN COURS</value>
  </data>
  <data name="ventana_espera_guardar_receta" xml:space="preserve">
    <value>SAUVEGARDE EN COURS COMMANDE {0}</value>
  </data>
  <data name="ventana_espera_lecturas_trazador" xml:space="preserve">
    <value>LECTURES TRACEUR {0}{1}</value>
  </data>
  <data name="ventana_espera_lecturas_trazadores" xml:space="preserve">
    <value>LECTURES AGENT TRACEUR {0}</value>
  </data>
  <data name="ventana_espera_lectura_trazador" xml:space="preserve">
    <value>LECTURE {0}</value>
  </data>
  <data name="ventana_espera_login" xml:space="preserve">
    <value>AUTHENTIFICATION CLIENT {0}</value>
  </data>
  <data name="ventana_espera_misbiselados" xml:space="preserve">
    <value>CHARGEMENT BISEAUX EN COURS</value>
  </data>
  <data name="ventana_espera_monturas" xml:space="preserve">
    <value>CHARGEMENT MONTURES EN COURS</value>
  </data>
  <data name="ventana_espera_obteniendo_bases" xml:space="preserve">
    <value>RÉCUPERANT BASES</value>
  </data>
  <data name="ventana_espera_oferta" xml:space="preserve">
    <value>MISE À JOUR OFFRE EN COURS</value>
  </data>
  <data name="ventana_espera_revision_pedidos" xml:space="preserve">
    <value>ENVOI À RÉVISION EN COURS</value>
  </data>
  <data name="ventana_espera_seleccion_lente" xml:space="preserve">
    <value>SELECTIONNANT VERRE</value>
  </data>
  <data name="ventana_espera_verifica_conexion_trazador" xml:space="preserve">
    <value>VÉRIFIEZ CONNEXION AGENT TRACEUR {0}</value>
  </data>
  <data name="verifique_conexion" xml:space="preserve">
    <value>Vérifiez l’accès d'Internet</value>
  </data>
  <data name="ver_certificado" xml:space="preserve">
    <value>Voir  certificat</value>
  </data>
  <data name="VolverAObtenerDeEscaner" xml:space="preserve">
    <value>Regagner une autre fois de l'scanner</value>
    <comment>IndoScan</comment>
  </data>
  <data name="volver_al_listado" xml:space="preserve">
    <value>Retour à la liste</value>
  </data>
  <data name="zona_de_identificacion" xml:space="preserve">
    <value>Zone d’identification</value>
    <comment>Barre d’outils</comment>
  </data>
  <data name="aviso_faltan_datos_en_prisma" xml:space="preserve">
    <value>La base ou l’axe du prisme n’ont pas été spécifiés.</value>
  </data>
  <data name="aviso_desactivar_isotipo" xml:space="preserve">
    <value>Commandes sans forme ou avec une forme basique ne peuvent pas solliciter le logo. Désactivez le logo.</value>
  </data>
  <data name="aviso_diferentes_alturas" xml:space="preserve">
    <value>Différence de hauteur pupillaire entre yeux supérieure ou égale à 3mm</value>
  </data>
  <data name="aviso_si_quiere_recogida_llamar_ATC" xml:space="preserve">
    <value>Si vous voulez un pick-up, s'il vous plaît appelez le service à la clientèle</value>
  </data>
  <data name="actualizar_cesta" xml:space="preserve">
    <value>Actualiser le panier</value>
  </data>
  <data name="albaran" xml:space="preserve">
    <value>Bon de livraison</value>
  </data>
  <data name="anadir_pedido_a_cesta" xml:space="preserve">
    <value>Ajouter commande au panier</value>
  </data>
  <data name="aviso_salir_indomanager" xml:space="preserve">
    <value>Par mesure de sécurité, souhaitez-vous fermer la session sur IndoManager?</value>
  </data>
  <data name="borrar_pedido_de_cesta" xml:space="preserve">
    <value>Éliminer la commande du panier</value>
  </data>
  <data name="cargo" xml:space="preserve">
    <value>Débit</value>
  </data>
  <data name="codigo_cliente_facturacion" xml:space="preserve">
    <value>Code client facturation</value>
  </data>
  <data name="desea_añadir_pedido_a_cesta" xml:space="preserve">
    <value>Souhaitez vous le rajouter à votre panier?</value>
  </data>
  <data name="enviar_seleccionados" xml:space="preserve">
    <value>Envoyer sélectionnés</value>
  </data>
  <data name="fecha_albaran" xml:space="preserve">
    <value>Date du Bon de livraison</value>
  </data>
  <data name="importe" xml:space="preserve">
    <value>Montant</value>
  </data>
  <data name="mi_cesta" xml:space="preserve">
    <value>Mon Panier</value>
  </data>
  <data name="no_albaranes_obtenidos" xml:space="preserve">
    <value>Bon(s) de livraison obtenus</value>
  </data>
  <data name="no_facturas_obtenidas" xml:space="preserve">
    <value>Facture(s) obtenue(s)</value>
  </data>
  <data name="ocultar_detalles" xml:space="preserve">
    <value>Cacher les détails</value>
  </data>
  <data name="posible_pedido_repetido_en_cesta" xml:space="preserve">
    <value>Cette commande existe déjà probablement dans votre panier</value>
  </data>
  <data name="recalcular" xml:space="preserve">
    <value>Recalculer</value>
  </data>
  <data name="todo" xml:space="preserve">
    <value>Tout</value>
  </data>
  <data name="ventana_espera_busqueda_albaranes" xml:space="preserve">
    <value>Recherche Bons de livraison…</value>
  </data>
  <data name="ventana_espera_busqueda_facturas" xml:space="preserve">
    <value>Recherche Factures...</value>
  </data>
  <data name="ver_cesta" xml:space="preserve">
    <value>Voir le Panier</value>
  </data>
  <data name="aviso_mas_365_dias" xml:space="preserve">
    <value>L'intervalle de dates doit être inférieure à 365 jours.</value>
  </data>
  <data name="abono" xml:space="preserve">
    <value>Avoir</value>
  </data>
  <data name="aviso_comunicacion_indomanager" xml:space="preserve">
    <value>La communication des données de la section IndoManager sera envoyée à la direction e-mail que vous indiquerez par la suite. Assurez-vous qu'elle soit correcte.</value>
  </data>
  <data name="aviso_devolucion_anulada" xml:space="preserve">
    <value>Votre retour de verres s'est annulé correctement</value>
  </data>
  <data name="aviso_documento_devoluciones_1" xml:space="preserve">
    <value>(Ce document doit se joindre avec les verres à retourner de cette commande)</value>
  </data>
  <data name="aviso_documento_devoluciones_2" xml:space="preserve">
    <value>(Ce document doit se joindre avec le paquet accompagné des retours à renvoyer)</value>
  </data>
  <data name="aviso_entrar_email_valido" xml:space="preserve">
    <value>Il est obligatoire d'introduire une adresse email valide</value>
  </data>
  <data name="aviso_instalacion_fuente_barcode" xml:space="preserve">
    <value>Un source de code-barre, nécessaire pour l'envoi du retour, va s'installer. Appuyer sur "accepter" pour l'installer. Dans la fenêtre qui apparaîtra appuyez sur Installer et ensuite fermez la fenêtre.</value>
  </data>
  <data name="aviso_montaje_gratuito" xml:space="preserve">
    <value>Les recouvrements enregistrés sur IndoNet sont gratuits</value>
  </data>
  <data name="aviso_pedido_no_se_puede_devolver" xml:space="preserve">
    <value>Les verres doivent être renvoyés pour être remboursés</value>
  </data>
  <data name="aviso_pedir_devolucion" xml:space="preserve">
    <value>Demander remboursement de la commande</value>
  </data>
  <data name="aviso_recogida_indonet_gratuita" xml:space="preserve">
    <value>Les ramassages pour les montages n'ont pas de cout s'ils sont sollicités depuis IndoNet</value>
  </data>
  <data name="aviso_saber_consumo_actual" xml:space="preserve">
    <value>Vous souhaitez connaître votre consommation actuelle despuis votre dernière facture?</value>
  </data>
  <data name="añadir_mas_devoluciones" xml:space="preserve">
    <value>Ajouter plus de retours</value>
  </data>
  <data name="bienvenido_a_indomanager" xml:space="preserve">
    <value>Bienvenue chez IndoManager, le nouveau service d'Indobox grâce auquel vous pourrez gérer votre activité d'une manière plus autonome et avec une plus grande information à votre portée.</value>
  </data>
  <data name="bono_agotandose" xml:space="preserve">
    <value>Bon qui s'épuise</value>
  </data>
  <data name="borrar_devolucion" xml:space="preserve">
    <value>Effacer retour</value>
  </data>
  <data name="busca_informacion_tracking" xml:space="preserve">
    <value>Recherchez sur la web du transporteur une information relatif à l'état de l'envoi</value>
  </data>
  <data name="caducidad" xml:space="preserve">
    <value>Expiration</value>
  </data>
  <data name="calcular" xml:space="preserve">
    <value>Calculer</value>
  </data>
  <data name="calculo_aproximado_consumo" xml:space="preserve">
    <value>Calcul approximatif basé sur les commandes réalisées depuis la dernière facture.</value>
  </data>
  <data name="cantidad_consumida" xml:space="preserve">
    <value>Unités consommées</value>
  </data>
  <data name="cerrar_sesion" xml:space="preserve">
    <value>Fermer cession</value>
  </data>
  <data name="clase_de_devolucion" xml:space="preserve">
    <value>Type de retour</value>
  </data>
  <data name="codigo" xml:space="preserve">
    <value>Code</value>
  </data>
  <data name="consulta" xml:space="preserve">
    <value>Consultation</value>
  </data>
  <data name="consumo_inferior_al_optimo" xml:space="preserve">
    <value>Consommation inférieure à celle espérée</value>
  </data>
  <data name="contratados_en_vigor" xml:space="preserve">
    <value>Engagés</value>
  </data>
  <data name="con_demora" xml:space="preserve">
    <value>Avec retard</value>
  </data>
  <data name="cumplimiento_de_servicio" xml:space="preserve">
    <value>Respect du service</value>
  </data>
  <data name="datos_generales" xml:space="preserve">
    <value>Données générales</value>
  </data>
  <data name="debe_escoger_una_lente" xml:space="preserve">
    <value>Vous devez choisir au minimum un verre</value>
  </data>
  <data name="debe_escoger_un_motivo_devolucion" xml:space="preserve">
    <value>Vous devez choisir un motif de retour</value>
  </data>
  <data name="denominacion" xml:space="preserve">
    <value>Dénomination</value>
  </data>
  <data name="desea_pedir_recogida" xml:space="preserve">
    <value>Souhaitez-vous demander un ramassage pour ces retours?</value>
  </data>
  <data name="devolucion" xml:space="preserve">
    <value>Retour</value>
  </data>
  <data name="devoluciones" xml:space="preserve">
    <value>Retours Produits</value>
  </data>
  <data name="devoluciones_tramitadas" xml:space="preserve">
    <value>Retours paramétrisés</value>
  </data>
  <data name="devolucion_correcta" xml:space="preserve">
    <value>Retour de verres</value>
  </data>
  <data name="devolucion_en_tramite" xml:space="preserve">
    <value>La commande existe déjà dans les retours en cours de traitement</value>
  </data>
  <data name="direccion_email" xml:space="preserve">
    <value>Adresse email</value>
  </data>
  <data name="direccion_Maroc" xml:space="preserve">
    <value>Indo Optical. Dársena del Saladillo  sub área AN1A. 11201 ALGECIRAS</value>
  </data>
  <data name="direccion_Papiol" xml:space="preserve">
    <value>Indo Optical. Guttenberg nº 5 (P.I. Les Torrenteres). 08754 El Papiol (Barcelona)</value>
  </data>
  <data name="en_plazo" xml:space="preserve">
    <value>Dans le délai</value>
  </data>
  <data name="error_en_devolucion" xml:space="preserve">
    <value>Erreur sur le retour</value>
  </data>
  <data name="esta_lente_no_descuenta_de_bono" xml:space="preserve">
    <value>Ce verre</value>
  </data>
  <data name="evolucion" xml:space="preserve">
    <value>Evolution des ventes</value>
  </data>
  <data name="fecha_devolucion" xml:space="preserve">
    <value>Date retour</value>
  </data>
  <data name="fecha_factura" xml:space="preserve">
    <value>Date Facture</value>
  </data>
  <data name="fecha_pedido" xml:space="preserve">
    <value>Date Commande</value>
  </data>
  <data name="importe_bruto" xml:space="preserve">
    <value>Montant Brut</value>
  </data>
  <data name="importe_neto" xml:space="preserve">
    <value>Montant Net</value>
  </data>
  <data name="importe_total" xml:space="preserve">
    <value>Montant Total</value>
  </data>
  <data name="informacion_bonos" xml:space="preserve">
    <value>Information de Bons</value>
  </data>
  <data name="ir_a_tramitar" xml:space="preserve">
    <value>A traiter</value>
  </data>
  <data name="iva" xml:space="preserve">
    <value>TVA</value>
  </data>
  <data name="lentes_incluidas_en_bono" xml:space="preserve">
    <value>Verres inclus dans le bon</value>
  </data>
  <data name="monofocales" xml:space="preserve">
    <value>Unifocal</value>
  </data>
  <data name="motivo_devolucion" xml:space="preserve">
    <value>Motif Retour</value>
  </data>
  <data name="motivo_de_devolucion" xml:space="preserve">
    <value>Motif du retour</value>
  </data>
  <data name="motivo_rechazo" xml:space="preserve">
    <value>Motif du refus</value>
  </data>
  <data name="no_albaran" xml:space="preserve">
    <value>Nº Bon de Livraison</value>
  </data>
  <data name="no_factura" xml:space="preserve">
    <value>Nº Facture</value>
  </data>
  <data name="numero_envio" xml:space="preserve">
    <value>Numéro d'envoi</value>
  </data>
  <data name="oferta_correcta" xml:space="preserve">
    <value>Offre correcte</value>
  </data>
  <data name="oferta_devolucion_creada" xml:space="preserve">
    <value>Offre de retour créée. </value>
  </data>
  <data name="ojo" xml:space="preserve">
    <value>Attention</value>
  </data>
  <data name="pedidos_laboratorio" xml:space="preserve">
    <value>Commande Lab</value>
  </data>
  <data name="pedido_stock" xml:space="preserve">
    <value>Commande Stock</value>
  </data>
  <data name="pendientes_tramitacion" xml:space="preserve">
    <value>En cours de traitement</value>
  </data>
  <data name="periodo_maximo_es_un_año" xml:space="preserve">
    <value>La période maxinale est d'un an</value>
  </data>
  <data name="ponte_contacto_comercial" xml:space="preserve">
    <value>Mettez-vous en contact avec le commercial afin qu'il vous communique le nº d'utilisateur et mot de passe</value>
  </data>
  <data name="pregunta_borrar_devolucion" xml:space="preserve">
    <value>Êtes-vous sûr de vouloir annuler ce retour de verres {0}  correspondant au numéro de Commande {1} ?</value>
  </data>
  <data name="pregunta_desea_continuar" xml:space="preserve">
    <value>Vous souhaitez continuer?</value>
  </data>
  <data name="producto" xml:space="preserve">
    <value>Produit</value>
  </data>
  <data name="progresivos" xml:space="preserve">
    <value>Progressif</value>
  </data>
  <data name="puntos" xml:space="preserve">
    <value>Points</value>
  </data>
  <data name="rechazada" xml:space="preserve">
    <value>Refusée</value>
  </data>
  <data name="recogida" xml:space="preserve">
    <value>Enlèvement</value>
  </data>
  <data name="referencia_devolucion" xml:space="preserve">
    <value>Référence Retour</value>
  </data>
  <data name="resto" xml:space="preserve">
    <value>Reste</value>
  </data>
  <data name="resultado_tramitacion_devoluciones_1" xml:space="preserve">
    <value>Nous avons reçu {0} retours et {1} sont autorisés. Vous pouvez voir les retours autorisés dans l'onglet de consultation des retours.</value>
  </data>
  <data name="resultado_tramitacion_devoluciones_2" xml:space="preserve">
    <value>Maintenant les documents de retours seront créés. Imprimez-les avec votre imprimante et rajoutez-les avec les verres.</value>
  </data>
  <data name="saldo_despues_grabar_pedido" xml:space="preserve">
    <value>Solde des points après avoir enregistré la commande</value>
  </data>
  <data name="solicitante" xml:space="preserve">
    <value>Demandeur</value>
  </data>
  <data name="solicitar_devolucion" xml:space="preserve">
    <value>Demander retour</value>
  </data>
  <data name="tipologia" xml:space="preserve">
    <value>Typologie</value>
  </data>
  <data name="tipos_de_pedido" xml:space="preserve">
    <value>Type de commandes</value>
  </data>
  <data name="tramitar_devoluciones" xml:space="preserve">
    <value>Traiter les retours</value>
  </data>
  <data name="tramitar_devoluciones_seleccionadas" xml:space="preserve">
    <value>Traiter les retours sélectionnés</value>
  </data>
  <data name="vaciar_campos" xml:space="preserve">
    <value>Vider champs</value>
  </data>
  <data name="ventana_espera_busqueda_estadisticas" xml:space="preserve">
    <value>Statistiques en cours</value>
  </data>
  <data name="ventana_espera_envio_clips" xml:space="preserve">
    <value>CLIPS EN COURS D'ENVOI</value>
  </data>
  <data name="ver_detalles" xml:space="preserve">
    <value>Voir détails</value>
  </data>
  <data name="crear_devolucion" xml:space="preserve">
    <value>Créer retour</value>
  </data>
  <data name="abonos" xml:space="preserve">
    <value>Avoirs</value>
  </data>
  <data name="añadir_archivos" xml:space="preserve">
    <value>Ajouter des fichiers</value>
  </data>
  <data name="bono" xml:space="preserve">
    <value>Bon</value>
  </data>
  <data name="bonos" xml:space="preserve">
    <value>BONS</value>
  </data>
  <data name="comercial" xml:space="preserve">
    <value>Commercial</value>
  </data>
  <data name="condiciones_anulación_pedidos" xml:space="preserve">
    <value>Conditions d'annulation de commandes</value>
  </data>
  <data name="condiciones_comerciales" xml:space="preserve">
    <value>Conditions commerciales</value>
  </data>
  <data name="consulta_factura" xml:space="preserve">
    <value>Consultez la facture</value>
  </data>
  <data name="consulta_indocenter" xml:space="preserve">
    <value>Consulter Indocenter</value>
  </data>
  <data name="consulta_indonet" xml:space="preserve">
    <value>Consultez Indonet</value>
  </data>
  <data name="consulta_indoshow" xml:space="preserve">
    <value>Comsulter IndoShow</value>
  </data>
  <data name="consulta_pedido" xml:space="preserve">
    <value>Consultez la commande</value>
  </data>
  <data name="define_el_motivo" xml:space="preserve">
    <value>Definir le motif</value>
  </data>
  <data name="deseo_recibir_informacion_bonos" xml:space="preserve">
    <value>Je désire recevoir des informations sur mon mail quand un des bons s'épuise ou que ma consommation est inférieure à celle escomptée</value>
  </data>
  <data name="duplicado_factura" xml:space="preserve">
    <value>Duplicata de facture</value>
  </data>
  <data name="escribe_los_detalles_complementarios_que_creas_conveniente" xml:space="preserve">
    <value>Mentionner les détails supplémentaires si besoin</value>
  </data>
  <data name="estadisticas" xml:space="preserve">
    <value>Statistiques</value>
  </data>
  <data name="estado_de_los_bonos" xml:space="preserve">
    <value>Statut des bons</value>
  </data>
  <data name="este_contacto" xml:space="preserve">
    <value>Ce contact</value>
  </data>
  <data name="facturacion" xml:space="preserve">
    <value>Factures et Bons de livraison</value>
  </data>
  <data name="familia" xml:space="preserve">
    <value>Famille</value>
  </data>
  <data name="formacion_visualmap" xml:space="preserve">
    <value>Formation VisualMap</value>
  </data>
  <data name="gama" xml:space="preserve">
    <value>Gamme:</value>
  </data>
  <data name="gestion" xml:space="preserve">
    <value>Gestion</value>
  </data>
  <data name="incidencias_visualmap" xml:space="preserve">
    <value>Incidences VisualMap</value>
  </data>
  <data name="incidencia_indonet" xml:space="preserve">
    <value>Incidence Indonet</value>
  </data>
  <data name="indicar_producto_o_tratamiento" xml:space="preserve">
    <value>Indiquer produit ou traitement</value>
  </data>
  <data name="iniciar_sesion" xml:space="preserve">
    <value>Démarrer session</value>
  </data>
  <data name="instalacion_indonet" xml:space="preserve">
    <value>Installation Indonet</value>
  </data>
  <data name="material" xml:space="preserve">
    <value>Matériel</value>
  </data>
  <data name="mes_o_numero_factura" xml:space="preserve">
    <value>Mois ou numéro de facture</value>
  </data>
  <data name="mi_cuenta" xml:space="preserve">
    <value>Mon compte</value>
  </data>
  <data name="numero_de_albaran" xml:space="preserve">
    <value>Numéro de bon de livraison</value>
  </data>
  <data name="numero_de_bono" xml:space="preserve">
    <value>Numéro de bon</value>
  </data>
  <data name="oferta_lentes_bono" xml:space="preserve">
    <value>Offre verres bon</value>
  </data>
  <data name="otros" xml:space="preserve">
    <value>Autres</value>
  </data>
  <data name="piezas_pendientes_bono" xml:space="preserve">
    <value>Pièces en cours bon</value>
  </data>
  <data name="promociones" xml:space="preserve">
    <value>Promotions</value>
  </data>
  <data name="quiero_solicitar_recogida" xml:space="preserve">
    <value>Je réclame l'enlèvement suivant:</value>
  </data>
  <data name="referencia_o_numero_de_albaran" xml:space="preserve">
    <value>Référence ou numéro de bon de livraison</value>
  </data>
  <data name="renovar_bono" xml:space="preserve">
    <value>Renouveler bon</value>
  </data>
  <data name="saldo_bonos" xml:space="preserve">
    <value>Solde d'Unités et d'Unités consommées</value>
  </data>
  <data name="servicios" xml:space="preserve">
    <value>Services</value>
  </data>
  <data name="solicitar_informacion" xml:space="preserve">
    <value>Obtenir des informations</value>
  </data>
  <data name="solicitar_tarifa" xml:space="preserve">
    <value>Obtenir Tarif</value>
  </data>
  <data name="solicitar_visita" xml:space="preserve">
    <value>Demander une visite</value>
  </data>
  <data name="solicitud_recogida" xml:space="preserve">
    <value>Demande d'enlèvement</value>
  </data>
  <data name="texto_soporte_online" xml:space="preserve">
    <value>Si notre service clinetèle vous l'indique, merci d'accéder à ce lien pour obtenir un support online</value>
  </data>
  <data name="tiene_recogida_solicitada" xml:space="preserve">
    <value>Vous avez un enlèvement demandé:</value>
  </data>
  <data name="tus_datos" xml:space="preserve">
    <value>Vos données</value>
  </data>
  <data name="tu_comercial" xml:space="preserve">
    <value>Votre commercial</value>
  </data>
  <data name="tu_gestor" xml:space="preserve">
    <value>Votre administrateur de ventes</value>
  </data>
  <data name="tu_peticion" xml:space="preserve">
    <value>Votre demande</value>
  </data>
  <data name="año" xml:space="preserve">
    <value>Année</value>
  </data>
  <data name="mes" xml:space="preserve">
    <value>Mois</value>
  </data>
  <data name="modificar_email" xml:space="preserve">
    <value>Modifier email</value>
  </data>
  <data name="motivo_no_aceptacion" xml:space="preserve">
    <value>Motif de non-acceptation</value>
  </data>
  <data name="todos_los_pedidos_para_mi" xml:space="preserve">
    <value>Toutes les commandes de mon magasin</value>
  </data>
  <data name="he_olvidado_mi_clave" xml:space="preserve">
    <value>» J'ai oublié mon mot de passe</value>
  </data>
  <data name="aaa" xml:space="preserve">
    <value />
  </data>
  <data name="aviso_1_crear_contrasenya_nueva" xml:space="preserve">
    <value>SVP, indiquez votre numéro de client et appuyez sur "envoyer" pour obtenir un nouveau mot de passe.</value>
  </data>
  <data name="aviso_2_crear_contrasenya_nueva" xml:space="preserve">
    <value>Dans quelques minutes, vous recevrez un mail sur votre courrier électronique enregistré avec un mot de passe temporaire</value>
  </data>
  <data name="aviso_cambio_password" xml:space="preserve">
    <value>Un nouveau mot de passe temporaire reçu sur votre mail ({0}) s'est créé. Vous pourrez accéder grâce à cette clef d'accès à Indonet et créer un nouveau mot de passe.</value>
  </data>
  <data name="aviso_error_crear_password" xml:space="preserve">
    <value>Il y a eu une erreur en créant le mot de passe temporaire.</value>
  </data>
  <data name="aviso_faltan_datos" xml:space="preserve">
    <value>Vous devez compléter les données sur le motif sélectionné</value>
  </data>
  <data name="codigo_restablecer_contrasenya" xml:space="preserve">
    <value>Clef d'accès pour rétablir le mot de passe.</value>
  </data>
  <data name="debe_introducir_codigo_cliente" xml:space="preserve">
    <value>Vous devez introduire votre code client</value>
  </data>
  <data name="equipo_de_indonet" xml:space="preserve">
    <value>L'équipement {AplicacionINDONET}</value>
  </data>
  <data name="error_clave_no_puede_ser_misma" xml:space="preserve">
    <value>Le nouveau mot de passe ne peut être le mème que le mot de passe temporaire</value>
  </data>
  <data name="error_numero_caracteres_minimo_en_clave" xml:space="preserve">
    <value>Le mot de passe doit contenir au minimum 6 caractères</value>
  </data>
  <data name="este_es_tu_codigo" xml:space="preserve">
    <value>Votre code {codigoIndonet}.</value>
  </data>
  <data name="gracias" xml:space="preserve">
    <value>Merci</value>
  </data>
  <data name="has_recibido_este_correo_solicitud_contraseña" xml:space="preserve">
    <value>Vous avez reçu cette notification car vous avez demandé un rappel de la clef d'accès de {AplicacionINDONET}.</value>
  </data>
  <data name="paswword_actualizado" xml:space="preserve">
    <value>Mot de passe actualisé</value>
  </data>
  <data name="pregunta_enviar_o_cancelar" xml:space="preserve">
    <value>Souhaitez vous envoyer ou annuler?</value>
  </data>
  <data name="si_no_lo_has_solicitado" xml:space="preserve">
    <value>Si vous ne l'avez pas demandé, merci d'ignorer ce mail et vous pourrez continuer d'utiliser votre mot de passe habituel.</value>
  </data>
  <data name="solicitud_nueva_contraseña" xml:space="preserve">
    <value>Demande d'une nouvelle clef d'accès à  {AplicacionINDONET}</value>
  </data>
  <data name="todos_los_pedidos_para_mi_optica" xml:space="preserve">
    <value>Toutes les commandes de mon magasin</value>
  </data>
  <data name="Usa_este_codigo_temporal" xml:space="preserve">
    <value>Utilisez cette clef d'accès temporaire pour entrer dans {AplicacionINDONET} et rétablir le mot de passe de votre compte</value>
  </data>
  <data name="aviso_eliminacion_boton_igual_suplementos" xml:space="preserve">
    <value>Afin d'éviter les erreurs, nous avons supprimé le bouton pour égaliser les suppléments dans les deux yeux. Vous devez maintenant entrer les valeurs que vous voulez pour chaque œil.</value>
  </data>
  <data name="aviso_cambios_sin_guardar" xml:space="preserve">
    <value>Vouz avez des modifications en attente pour enregistrer</value>
  </data>
  <data name="aviso_comprobaciones" xml:space="preserve">
    <value>A partir de maintenant, vous pouvez également demander vos vérifications de verres dans ce même onglet. Vous n'avez qu'à sélectionner cette option dans la classe de vérification et indiquer la raison. Si vous avez besoin de plus d'informations, contactez-nous.</value>
  </data>
  <data name="aviso_eliminacion_boton_igual_suplementos1" xml:space="preserve">
    <value>Afin d'éviter des erreurs, nous avons supprimé le bouton pour égaliser les suppléments dans les deux yeux. Vous devez maintenant rentrer les valeurs que vous voulez pour chaque œil.</value>
  </data>
  <data name="bono_tarifa_plana" xml:space="preserve">
    <value>Bonus / Forfait</value>
  </data>
  <data name="cambio_datos_fiscales" xml:space="preserve">
    <value>Changement de données fiscales</value>
  </data>
  <data name="clase_de_comprobacion" xml:space="preserve">
    <value>Classe de vérification:</value>
  </data>
  <data name="configuracion_indonet" xml:space="preserve">
    <value>Configuration d'Indonet</value>
  </data>
  <data name="contraseña_indomanager" xml:space="preserve">
    <value>Mot de passe Indomanager</value>
  </data>
  <data name="descripcion_calibracion" xml:space="preserve">
    <value>Entrer en millimètres le diamètre réel de la circonférence précédente</value>
  </data>
  <data name="detalle_modelo_347" xml:space="preserve">
    <value>Détail du modèle 347</value>
  </data>
  <data name="duplicado_albaran" xml:space="preserve">
    <value>Duplicata Bon de Livraison</value>
  </data>
  <data name="duplicado_carta_detalle_bono" xml:space="preserve">
    <value>Duplicata Lettre Détail Bonus</value>
  </data>
  <data name="equivalencia_puntos_bonos" xml:space="preserve">
    <value>Équivalence de points bonus</value>
  </data>
  <data name="extracto_cuenta" xml:space="preserve">
    <value>Extrait de Compte</value>
  </data>
  <data name="fecha_o_importe" xml:space="preserve">
    <value>Date ou montant</value>
  </data>
  <data name="filtro_productos_y_descripcion" xml:space="preserve">
    <value>Filtre de produit et description</value>
  </data>
  <data name="formacion" xml:space="preserve">
    <value>Formation</value>
  </data>
  <data name="giro" xml:space="preserve">
    <value>Virement bancaire</value>
  </data>
  <data name="ha_escogido_esta_comprobación" xml:space="preserve">
    <value>Vous avez choisi cette vérification</value>
  </data>
  <data name="incidencia" xml:space="preserve">
    <value>Incidence</value>
  </data>
  <data name="indicar_mes" xml:space="preserve">
    <value>Indiquez le mois</value>
  </data>
  <data name="indicar_periodo" xml:space="preserve">
    <value>Indiquer la période</value>
  </data>
  <data name="indica_lugar_recogida" xml:space="preserve">
    <value>Indiquer l'adresse oú doit être fait l'enlèvement:</value>
  </data>
  <data name="mostrar_tabla_equivalencia_en_euros" xml:space="preserve">
    <value>Montrer la table d'équivalence des points en euros</value>
  </data>
  <data name="motivo_comprobacion" xml:space="preserve">
    <value>Raison de vérification:</value>
  </data>
  <data name="solicitar_llamada" xml:space="preserve">
    <value>Demande d'appel téléphonique</value>
  </data>
  <data name="solicitudes" xml:space="preserve">
    <value>Demandes/Requêtes</value>
  </data>
  <data name="aviso_ha_generado_premio" xml:space="preserve">
    <value>Cette commande a généré une promotion applicable à une 2ème paire. Désirez-vous passer une autre commande? Si oui, nous vous aiderons à filtrer les produits disponibles ou, si vous le préférez, vous pouvez le faire plus tard depuis l'onglet "Réaliser une commande".</value>
  </data>
  <data name="aviso_nuevos_botones_promociones" xml:space="preserve">
    <value>Vous pouvez désormais activer le nouveau guide de promotions sur l'onglet configuration. Si vous voulez savoir comment cela fonctionne, une vidéo est à votre disposition avec les instructions dans la section de Tutoriaux. Pour plus d'information, merci de vous contacter votre commercial ou notre service clientèle. </value>
  </data>
  <data name="debe_seleccionar_un_pedido" xml:space="preserve">
    <value>Vous devez sélectionner une commande</value>
  </data>
  <data name="eliminar_filtros" xml:space="preserve">
    <value>Eliminer les filtres</value>
  </data>
  <data name="hay_generadores_para_el_pedido" xml:space="preserve">
    <value>Vous avez une promotion active qui peut être appliquée sur cette commande. Voulez-vous connaître les produits qui le permettent?</value>
  </data>
  <data name="no_volver_a_mostrar" xml:space="preserve">
    <value>Ne plus montrer</value>
  </data>
  <data name="pulse_boton_enviar_y_revision" xml:space="preserve">
    <value>Appuyez sur le bouton "Envoyer et révision" pour qu'un agent du service clientèle la révise.</value>
  </data>
  <data name="receta_no_grabada" xml:space="preserve">
    <value>Cette prescription n'a pas pu être enregistrée</value>
  </data>
  <data name="selecciona_premio" xml:space="preserve">
    <value>Sélectionnez votre 2ème monture:</value>
  </data>
  <data name="selecciona_promocion" xml:space="preserve">
    <value>Sélectionnez la promotion:</value>
  </data>
  <data name="seleccion_primer_pedido" xml:space="preserve">
    <value>Sélection de la la première commande</value>
  </data>
  <data name="entendido" xml:space="preserve">
    <value>Compris</value>
  </data>
  <data name="aviso_cambio_contraseña_indonet" xml:space="preserve">
    <value>Pour améliorer la sécurité et la confidentialité de nos clients, nous vous recommandons de modifier et personnaliser le mot de passe de cette plateforme. Vous pourrez le faire depuis l’onglet « Configuration » qui s’ouvrira ensuite.</value>
  </data>
  <data name="aviso_debe_rellenar_todos_los_campos" xml:space="preserve">
    <value>Vous devez remplir tous les champs</value>
  </data>
  <data name="aviso_nueva_clave_no_es_indonet" xml:space="preserve">
    <value>Le nouveau mot de passe ne peut pas être « INDONET »</value>
  </data>
  <data name="notificacion" xml:space="preserve">
    <value>Notification</value>
  </data>
  <data name="altura_pasillo_mayor_altura_aro" xml:space="preserve">
    <value>La hauteur du couloir ({1}) ne peut pas être supérieure à la hauteur de montage ({2})</value>
  </data>
  <data name="enviada" xml:space="preserve">
    <value>Envoyée</value>
  </data>
  <data name="aviso_color_reposicion" xml:space="preserve">
    <value>Nous avons reçu votre commande.
Pour nous assurer que la couleur des 2 verres soit la même, nous nous mettrons rapidement en contact avec vous.</value>
  </data>
  <data name="mensaje_advertencia_espesor_ranura" xml:space="preserve">
    <value>IMPORTANT : il faut toujours vérifier le type de rainure pour éviter des incidents le montage. Rappel : 
Épaisseur min. rainure Nylon = 1.5 mm 
Épaisseur min. rainure Métal = 2.2 mm
Si vous avez des doutes, n’hésitez pas à consulter notre département de Service clientèle.</value>
  </data>
  <data name="domingo" xml:space="preserve">
    <value>Dimanche</value>
  </data>
  <data name="jueves" xml:space="preserve">
    <value>Jeudi</value>
  </data>
  <data name="lunes" xml:space="preserve">
    <value>Lundi</value>
  </data>
  <data name="martes" xml:space="preserve">
    <value>Mardi</value>
  </data>
  <data name="miercoles" xml:space="preserve">
    <value>Mercredi</value>
  </data>
  <data name="sabado" xml:space="preserve">
    <value>Samedi</value>
  </data>
  <data name="viernes" xml:space="preserve">
    <value>Vendredi</value>
  </data>
  <data name="alta_buscador" xml:space="preserve">
    <value>Inscription/Modification dans moteur de recherche</value>
  </data>
  <data name="anular_cambios" xml:space="preserve">
    <value>Annuler les changements effectués</value>
  </data>
  <data name="audiologia" xml:space="preserve">
    <value>Audiologie</value>
  </data>
  <data name="aviso_eliminar_buscador_web" xml:space="preserve">
    <value>Vous êtes en train d'effacer vos données de notre moteur de recherche.
Si vous acceptez, vous cesserez d'apparaître dans les possibles recherches que réalisent les utilisateurs sur celui-ci.
Désirez-vous continuer?</value>
  </data>
  <data name="baja_buscador" xml:space="preserve">
    <value>Désinscription dans le moteur de recherche</value>
  </data>
  <data name="baja_vision" xml:space="preserve">
    <value>Malvoyance (Basse vision)</value>
  </data>
  <data name="buscador_web" xml:space="preserve">
    <value>Moteur de recherche</value>
  </data>
  <data name="de" xml:space="preserve">
    <value>de</value>
  </data>
  <data name="horario_comercial" xml:space="preserve">
    <value>Heures d'ouverture</value>
  </data>
  <data name="optometria" xml:space="preserve">
    <value>Optométrie</value>
  </data>
  <data name="rellenar_con_datos_por_defecto" xml:space="preserve">
    <value>Remplir avec les données par défaut</value>
  </data>
  <data name="taller_de_montaje" xml:space="preserve">
    <value>Atelier de montage</value>
  </data>
  <data name="terapia_visual" xml:space="preserve">
    <value>Thérapie visuelle</value>
  </data>
  <data name="texto_autorizo_buscar_web" xml:space="preserve">
    <value>J'autorise INDO OPTICAL SLU à garder ces données et les conserver dans sa base de données, dont l'usage sera exclusivement destiné à les faire apparaître dans son moteur de recherche d'opticiens, de manière à avoir une communication avec ses clients.</value>
  </data>
  <data name="venta_online" xml:space="preserve">
    <value>Vente online</value>
  </data>
  <data name="y_de" xml:space="preserve">
    <value>et de </value>
  </data>
  <data name="informacion_contacto" xml:space="preserve">
    <value>Informations de contact</value>
  </data>
  <data name="informacion_optica" xml:space="preserve">
    <value>Information optique</value>
  </data>
  <data name="mensaje_entrada_buscador" xml:space="preserve">
    <value>Configurez ici vos données afin d’apparaître dans le moteur de recherche des opticiens de www.indo.es</value>
  </data>
  <data name="tooltip_boton_copiar" xml:space="preserve">
    <value>Copier de lundi à samedi.</value>
  </data>
  <data name="ha_escogido_esta_devolucion" xml:space="preserve">
    <value>Vous avez choisi ce Retour</value>
  </data>
  <data name="configuracion_hojaPedidoIndoscan" xml:space="preserve">
    <value>Formulaire de Indoscan</value>
  </data>
  <data name="configuracion_link_hojaPedidosFax" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/Indonet/Enlaces/HojaPedidosFax_FR.pdf</value>
  </data>
  <data name="aviso_texto_no_copiar_pedido" xml:space="preserve">
    <value>IMPORTANT
Il se peut que le produit demandé ait changé d’offre depuis la date de la commande initiale. Merci de bien vouloir vérifier tous les champs et traitements pour s’assurer que les verres sélectionnés sont corrects.</value>
  </data>
  <data name="selecciona_trazador" xml:space="preserve">
    <value>Sélectionnez vôtre traceur</value>
  </data>
  <data name="selecciona_trazador_1" xml:space="preserve">
    <value>Vous avez plusieurs Indobisel connectés à INDONET. Veuillez sélectionner le traceur auquel vous souhaitez vous connecter:</value>
  </data>
  <data name="aceptar_cambio_y_recalcular" xml:space="preserve">
    <value>Accepter les changements et recalculer</value>
  </data>
  <data name="cancelar_cambios" xml:space="preserve">
    <value>Annuler les changements</value>
  </data>
  <data name="propuesta_cambio_producto" xml:space="preserve">
    <value>Proposition de changement de produit</value>
  </data>
  <data name="ver_mas" xml:space="preserve">
    <value>Voir plus</value>
  </data>
  <data name="texto_importante_devoluciones" xml:space="preserve">
    <value>IMPORTANT : Pour éviter des détériorations sur les verres et pour faciliter leur révision, les verres devront être reçus dans un emballage approprié et séparément, sans des éléments qui puissent les abîmer comme des agrafes, des cartes, etc..</value>
  </data>
  <data name="url_ayuda_inset" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/ayudaIndonet/inset_FR.html</value>
  </data>
  <data name="ver_albaran" xml:space="preserve">
    <value>Voir Bon de Livraison</value>
  </data>
  <data name="consulta_disponibilidad" xml:space="preserve">
    <value>Consultez disponibilité</value>
  </data>
  <data name="marca_opcion_deseada" xml:space="preserve">
    <value>Cochez l’option souhaitée:</value>
  </data>
  <data name="url_condiciones_indomedcare_new" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/ayudaIndonet/condiciones_indomedcare_FR.txt</value>
  </data>
  <data name="url_condiciones_indonet_new" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/ayudaIndonet/condiciones_indonet_FR.txt</value>
  </data>
  <data name="catalogo" xml:space="preserve">
    <value>Catalogue</value>
  </data>
  <data name="consultar_pedidos" xml:space="preserve">
    <value>Consulter les commandes</value>
  </data>
  <data name="gestion_devoluciones" xml:space="preserve">
    <value>Gestion des retours</value>
  </data>
  <data name="tienes_un_aviso" xml:space="preserve">
    <value>Avis</value>
  </data>
  <data name="texto_presentacion10" xml:space="preserve">
    <value>Réaliser et contrôler vos retours de verres.</value>
  </data>
  <data name="texto_presentacion11" xml:space="preserve">
    <value>Contrôler et imprimer vos factures avec Indomanager.</value>
  </data>
  <data name="texto_presentacion8" xml:space="preserve">
    <value>Grâce à Indonet GO, vous pourrez:</value>
  </data>
  <data name="texto_presentacion9" xml:space="preserve">
    <value>Faire un suivi plus détaillé de vos commandes.</value>
  </data>
  <data name="url_ayuda_pedidorapidoclasico" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/ayudaIndonet/pedido_rapidoclasico_FR.txt</value>
  </data>
  <data name="grabado_clasico" xml:space="preserve">
    <value>Enregistrement classique</value>
  </data>
  <data name="grabado_rapido" xml:space="preserve">
    <value>Enregistrement rapide</value>
  </data>
  <data name="aviso_faltan_observaciones" xml:space="preserve">
    <value>Veuillez nous indiquer brièvement dans le champ “Observations” la raison de la révision de votre commande.</value>
  </data>
  <data name="pedido_urgente" xml:space="preserve">
    <value>Commande urgente</value>
  </data>
  <data name="degradado_alto" xml:space="preserve">
    <value>Dégradé moins foncé de 5mm</value>
  </data>
  <data name="degradado_bajo" xml:space="preserve">
    <value>Dégradé plus foncé de 5mm</value>
  </data>
  <data name="texto_ex_bono" xml:space="preserve">
    <value>Le traitement demandé est exclu des bons et a un coût supplémentaire de {0} par verre.</value>
  </data>
  <data name="crear_abono" xml:space="preserve">
    <value>Créer avoir</value>
  </data>
  <data name="crear_analisis" xml:space="preserve">
    <value>Créer analyse</value>
  </data>
  <data name="selecciona_una_opcion" xml:space="preserve">
    <value>Sélectionnez une option</value>
  </data>
  <data name="selecciona_un_motivo" xml:space="preserve">
    <value>Sélectionnez un motif</value>
  </data>
  <data name="solicitar_abono" xml:space="preserve">
    <value>Demander un avoir</value>
  </data>
  <data name="solicitar_analisis_lentes" xml:space="preserve">
    <value>Demander une analyse des verres</value>
  </data>
  <data name="solicito_abono_por" xml:space="preserve">
    <value>Je demmande l'avoir de la commande pour...</value>
  </data>
  <data name="solicito_analisis_por" xml:space="preserve">
    <value>Je demande l’analyse des verres pour...</value>
  </data>
  <data name="vi" xml:space="preserve">
    <value>VI</value>
  </data>
  <data name="vl" xml:space="preserve">
    <value>VdL</value>
  </data>
  <data name="vp" xml:space="preserve">
    <value>VdP</value>
  </data>
  <data name="limpiar" xml:space="preserve">
    <value>Effacer formulaire</value>
  </data>
  <data name="debes_seleccionar_una_resolucion" xml:space="preserve">
    <value>Vous devez sélecctioner une résolution.</value>
  </data>
  <data name="debes_seleccionar_un_motivo" xml:space="preserve">
    <value>Vous devez sélecctioner un motif de retour.</value>
  </data>
  <data name="vas_a_borrar_los_datos_esas_seguro" xml:space="preserve">
    <value>On va effacer les donées du formulaire. Êtes-vous sûr(e)?</value>
  </data>
  <data name="calcular_espesores" xml:space="preserve">
    <value>Calculer les épaisseurs</value>
  </data>
  <data name="enviar_a_revision" xml:space="preserve">
    <value>Envoyer à revision</value>
  </data>
  <data name="opciones_de_pedido" xml:space="preserve">
    <value>Options de la commande</value>
  </data>
  <data name="comprobaciones" xml:space="preserve">
    <value>Analyses</value>
  </data>
  <data name="que_tipo_devolucion_quieres_realizar" xml:space="preserve">
    <value>Quel type de retour souhaitez-vous effectuer?</value>
  </data>
  <data name="escoge_tipo_mimetika" xml:space="preserve">
    <value>Choisissez l'Isotype</value>
  </data>
  <data name="iniciales_en_mimetika" xml:space="preserve">
    <value>Initiales</value>
  </data>
  <data name="isotipo_en_mimetika" xml:space="preserve">
    <value>Isotype</value>
  </data>
  <data name="isotipo_especial" xml:space="preserve">
    <value>SPÉCIAL ISOTYPE</value>
  </data>
  <data name="pulsa_para_recuperar_VMAP" xml:space="preserve">
    <value>Appuyez pour récuperer le code VMAP</value>
  </data>
  <data name="sin_isotipo_en_mimetika" xml:space="preserve">
    <value>Sans Isotype</value>
  </data>
  <data name="en_proceso" xml:space="preserve">
    <value>En cours</value>
  </data>
  <data name="gama_de_lentes" xml:space="preserve">
    <value>Gamme de verres:</value>
  </data>
  <data name="aviso_grabado_rapido" xml:space="preserve">
    <value>Votre commande a été envoyée à Indo/Rodenstock. Si toutes les données sont correctes, elle sera enregistrée en quelques minutes.
Merci de bien vouloir vérifier dans l'onglet "commandes réalisées" qu’un numéro de commande a été attribué; si ce n’est pas le cas, contactez notre service clientèle.
Commandes Indo: 900 110 557
Commandes Rodenstock: 900 102 189</value>
  </data>
  <data name="solicitar_devolucion_rodenstock" xml:space="preserve">
    <value>Activar Garantía Especial Rodenstock</value>
  </data>
  <data name="aviso_pedir_devolucionRD" xml:space="preserve">
    <value>Solicitar Garantía Especial Rodenstock</value>
  </data>
  <data name="texto_activar_bonificacion_rodenstock_checkbox" xml:space="preserve">
    <value>Activar Garantía Especial Rodenstock</value>
  </data>
  <data name="aviso_devolucion_mas_de_un_pedido_seleccionado" xml:space="preserve">
    <value>Ha seleccionado más de un pedido para devolución. Solamente se tratará el primer pedido. ¿Desea continuar?</value>
  </data>
  <data name="aviso_no_se_han_seleccionado_pedidos_aptos_para_devolucion" xml:space="preserve">
    <value>No se han seleccionado pedidos aptos para devolución</value>
  </data>
  <data name="aviso_opcion_valida_solo_desde_pedidosrealizados_solicitarabono" xml:space="preserve">
    <value>Esta opción sólo es válida desde Pedidos Realizados o Solicitar Abono</value>
  </data>
  <data name="seguro_actualizar_pedido" xml:space="preserve">
    <value>Ce calcul n'est pas obligatoire pour envoyer la commande et peut prendre un certain temps.
Êtes-vous sûr de continuer ?</value>
  </data>
  <data name="rodenstockmanager" xml:space="preserve">
    <value>RodenstockManager</value>
  </data>
  <data name="bienvenido_a_rodenstockmanager" xml:space="preserve">
    <value>Bienvenue chez Rodenstock Manager, le service grâce auquel vous pourrez gérer votre activité d'une manière plus autonome et avec une plus grande information à votre portée.</value>
  </data>
  <data name="url_fb_rodenstock" xml:space="preserve">
    <value>https://www.facebook.com/RodenstockES/</value>
  </data>
  <data name="url_in_rodenstock" xml:space="preserve">
    <value>https://www.instagram.com/rodenstock_es/</value>
  </data>
  <data name="url_indoloyalty" xml:space="preserve">
    <value>https://indoloyalty.com</value>
  </data>
  <data name="wells_pedido_sonae_obligatorio" xml:space="preserve">
    <value>Dans le champ Référence, la commande Sonae doit être indiquée</value>
  </data>
</root>