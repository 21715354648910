<?xml version="1.0" encoding="utf-8"?>
<root>
  <!-- 
    Microsoft ResX Schema 
    
    Version 2.0
    
    The primary goals of this format is to allow a simple XML format 
    that is mostly human readable. The generation and parsing of the 
    various data types are done through the TypeConverter classes 
    associated with the data types.
    
    Example:
    
    ... ado.net/XML headers & schema ...
    <resheader name="resmimetype">text/microsoft-resx</resheader>
    <resheader name="version">2.0</resheader>
    <resheader name="reader">System.Resources.ResXResourceReader, System.Windows.Forms, ...</resheader>
    <resheader name="writer">System.Resources.ResXResourceWriter, System.Windows.Forms, ...</resheader>
    <data name="Name1"><value>this is my long string</value><comment>this is a comment</comment></data>
    <data name="Color1" type="System.Drawing.Color, System.Drawing">Blue</data>
    <data name="Bitmap1" mimetype="application/x-microsoft.net.object.binary.base64">
        <value>[base64 mime encoded serialized .NET Framework object]</value>
    </data>
    <data name="Icon1" type="System.Drawing.Icon, System.Drawing" mimetype="application/x-microsoft.net.object.bytearray.base64">
        <value>[base64 mime encoded string representing a byte array form of the .NET Framework object]</value>
        <comment>This is a comment</comment>
    </data>
                
    There are any number of "resheader" rows that contain simple 
    name/value pairs.
    
    Each data row contains a name, and value. The row also contains a 
    type or mimetype. Type corresponds to a .NET class that support 
    text/value conversion through the TypeConverter architecture. 
    Classes that don't support this are serialized and stored with the 
    mimetype set.
    
    The mimetype is used for serialized objects, and tells the 
    ResXResourceReader how to depersist the object. This is currently not 
    extensible. For a given mimetype the value must be set accordingly:
    
    Note - application/x-microsoft.net.object.binary.base64 is the format 
    that the ResXResourceWriter will generate, however the reader can 
    read any of the formats listed below.
    
    mimetype: application/x-microsoft.net.object.binary.base64
    value   : The object must be serialized with 
            : System.Runtime.Serialization.Formatters.Binary.BinaryFormatter
            : and then encoded with base64 encoding.
    
    mimetype: application/x-microsoft.net.object.soap.base64
    value   : The object must be serialized with 
            : System.Runtime.Serialization.Formatters.Soap.SoapFormatter
            : and then encoded with base64 encoding.

    mimetype: application/x-microsoft.net.object.bytearray.base64
    value   : The object must be serialized into a byte array 
            : using a System.ComponentModel.TypeConverter
            : and then encoded with base64 encoding.
    -->
  <xsd:schema id="root" xmlns="" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:msdata="urn:schemas-microsoft-com:xml-msdata">
    <xsd:import namespace="http://www.w3.org/XML/1998/namespace" />
    <xsd:element name="root" msdata:IsDataSet="true">
      <xsd:complexType>
        <xsd:choice maxOccurs="unbounded">
          <xsd:element name="metadata">
            <xsd:complexType>
              <xsd:sequence>
                <xsd:element name="value" type="xsd:string" minOccurs="0" />
              </xsd:sequence>
              <xsd:attribute name="name" use="required" type="xsd:string" />
              <xsd:attribute name="type" type="xsd:string" />
              <xsd:attribute name="mimetype" type="xsd:string" />
              <xsd:attribute ref="xml:space" />
            </xsd:complexType>
          </xsd:element>
          <xsd:element name="assembly">
            <xsd:complexType>
              <xsd:attribute name="alias" type="xsd:string" />
              <xsd:attribute name="name" type="xsd:string" />
            </xsd:complexType>
          </xsd:element>
          <xsd:element name="data">
            <xsd:complexType>
              <xsd:sequence>
                <xsd:element name="value" type="xsd:string" minOccurs="0" msdata:Ordinal="1" />
                <xsd:element name="comment" type="xsd:string" minOccurs="0" msdata:Ordinal="2" />
              </xsd:sequence>
              <xsd:attribute name="name" type="xsd:string" use="required" msdata:Ordinal="1" />
              <xsd:attribute name="type" type="xsd:string" msdata:Ordinal="3" />
              <xsd:attribute name="mimetype" type="xsd:string" msdata:Ordinal="4" />
              <xsd:attribute ref="xml:space" />
            </xsd:complexType>
          </xsd:element>
          <xsd:element name="resheader">
            <xsd:complexType>
              <xsd:sequence>
                <xsd:element name="value" type="xsd:string" minOccurs="0" msdata:Ordinal="1" />
              </xsd:sequence>
              <xsd:attribute name="name" type="xsd:string" use="required" />
            </xsd:complexType>
          </xsd:element>
        </xsd:choice>
      </xsd:complexType>
    </xsd:element>
  </xsd:schema>
  <resheader name="resmimetype">
    <value>text/microsoft-resx</value>
  </resheader>
  <resheader name="version">
    <value>2.0</value>
  </resheader>
  <resheader name="reader">
    <value>System.Resources.ResXResourceReader, System.Windows.Forms, Version=2.0.0.0, Culture=neutral, PublicKeyToken=b77a5c561934e089</value>
  </resheader>
  <resheader name="writer">
    <value>System.Resources.ResXResourceWriter, System.Windows.Forms, Version=2.0.0.0, Culture=neutral, PublicKeyToken=b77a5c561934e089</value>
  </resheader>
  <data name="a" xml:space="preserve">
    <value>a</value>
  </data>
  <data name="abertura" xml:space="preserve">
    <value>Abertura</value>
  </data>
  <data name="acceso_indonet" xml:space="preserve">
    <value>Acceso</value>
    <comment>Pantalla de login</comment>
  </data>
  <data name="aceptar" xml:space="preserve">
    <value>Aceptar</value>
  </data>
  <data name="acetato" xml:space="preserve">
    <value>Acetato</value>
    <comment>tipo de montura</comment>
  </data>
  <data name="actualizar" xml:space="preserve">
    <value>Actualizar</value>
  </data>
  <data name="adicion" xml:space="preserve">
    <value>Adición</value>
    <comment>Pantalla de inicio, listado últimos pedidos</comment>
  </data>
  <data name="adicion_abrv" xml:space="preserve">
    <value>AD</value>
    <comment>Cabecera listado pedidos</comment>
  </data>
  <data name="advertencia" xml:space="preserve">
    <value>Advertencia</value>
  </data>
  <data name="agente_trazador" xml:space="preserve">
    <value>Agente trazador</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="agente_trazador_desconectado" xml:space="preserve">
    <value>INDOBISEL parece que no esté funcionando. Por favor verifique su estado</value>
  </data>
  <data name="altura" xml:space="preserve">
    <value>Altura</value>
    <comment>Datos técnicos</comment>
  </data>
  <data name="altura_boxing" xml:space="preserve">
    <value>Altura boxing</value>
  </data>
  <data name="altura_de_montaje" xml:space="preserve">
    <value>Altura de montaje</value>
  </data>
  <data name="altura_montaje" xml:space="preserve">
    <value>Altura montaje</value>
    <comment>Datos Adicionales</comment>
  </data>
  <data name="angulo" xml:space="preserve">
    <value>Ángulo</value>
    <comment>Datos técnicos</comment>
  </data>
  <data name="angulo_de_envolvencia" xml:space="preserve">
    <value>Ángulo de envolvencia</value>
    <comment>Datos Adicionales</comment>
  </data>
  <data name="asunto" xml:space="preserve">
    <value>Asunto</value>
  </data>
  <data name="atras" xml:space="preserve">
    <value>Atrás</value>
  </data>
  <data name="automatico13_23" xml:space="preserve">
    <value>Automático (1/3-2/3)</value>
    <comment>Orientacion del bisel</comment>
  </data>
  <data name="avisos" xml:space="preserve">
    <value>Avisos</value>
    <comment>Pantalla de Pedido</comment>
  </data>
  <data name="aviso_cancelar_pedido" xml:space="preserve">
    <value>¿Estás seguro que quieres cancelar el pedido? se perderan todos los datos</value>
    <comment>Avisos</comment>
  </data>
  <data name="aviso_desactivar_solo_stock" xml:space="preserve">
    <value>Si desea esta opción desactive la opción Sólo Stock</value>
  </data>
  <data name="base" xml:space="preserve">
    <value>Base</value>
    <comment>Datos técnicos</comment>
  </data>
  <data name="base_de_datos_de_marcas" xml:space="preserve">
    <value>Base de datos de marcas</value>
    <comment>ComboBox de formas</comment>
  </data>
  <data name="base_de_datos_de_mis_biselados" xml:space="preserve">
    <value>Mis biselados</value>
    <comment>ComboBox de formas</comment>
  </data>
  <data name="base_especial" xml:space="preserve">
    <value>Base especial</value>
    <comment>Suplementos</comment>
  </data>
  <data name="base_interior" xml:space="preserve">
    <value>Base interior</value>
  </data>
  <data name="basicas" xml:space="preserve">
    <value>Básicas</value>
    <comment>ComboBox de formas</comment>
  </data>
  <data name="bisel" xml:space="preserve">
    <value>Bisel normal</value>
    <comment>tipo de bisel</comment>
  </data>
  <data name="biselador_remoto" xml:space="preserve">
    <value>Biselador remoto</value>
    <comment>ComboBox de formas</comment>
  </data>
  <data name="biselador_remoto_guardar" xml:space="preserve">
    <value>Guardar</value>
  </data>
  <data name="biselador_remoto_iniciar" xml:space="preserve">
    <value>Iniciar</value>
  </data>
  <data name="biselador_remoto_popup_titulo" xml:space="preserve">
    <value>Seleción lecturas agente trazador</value>
  </data>
  <data name="biselar_lente" xml:space="preserve">
    <value>Biselar lente</value>
  </data>
  <data name="biselar_sin_pulir" xml:space="preserve">
    <value>Biselar sin pulir</value>
  </data>
  <data name="borrar_recetas" xml:space="preserve">
    <value>Borrar</value>
  </data>
  <data name="buscar" xml:space="preserve">
    <value>Buscar</value>
  </data>
  <data name="calibracion_correctamente" xml:space="preserve">
    <value>La calibración se ha realizado correctamente</value>
  </data>
  <data name="calibracion_monitor" xml:space="preserve">
    <value>Calibración monitor</value>
  </data>
  <data name="calibre" xml:space="preserve">
    <value>Calibre</value>
    <comment>Datos Adicionales</comment>
  </data>
  <data name="calibre_completo" xml:space="preserve">
    <value>Calibre completo</value>
  </data>
  <data name="cancelar" xml:space="preserve">
    <value>Cancelar</value>
  </data>
  <data name="cancelar_comparacion" xml:space="preserve">
    <value>Cancelar comparación</value>
  </data>
  <data name="cancelar_pedido" xml:space="preserve">
    <value>Cancelar pedido</value>
    <comment>Botonera Acciones del pedido</comment>
  </data>
  <data name="centrado12_12" xml:space="preserve">
    <value>Centrado (1/2-1/2)</value>
    <comment>Orientacion del bisel</comment>
  </data>
  <data name="chile" xml:space="preserve">
    <value>Chile</value>
  </data>
  <data name="cilindro" xml:space="preserve">
    <value>Cilindro</value>
    <comment>Pantalla de inicio, listado últimos pedidos</comment>
  </data>
  <data name="cilindro_abrv" xml:space="preserve">
    <value>CIL</value>
    <comment>Cabecera listado pedidos</comment>
  </data>
  <data name="clave_acceso" xml:space="preserve">
    <value>Clave de acceso</value>
    <comment>Pantalla de login</comment>
  </data>
  <data name="cliente" xml:space="preserve">
    <value>Cliente</value>
  </data>
  <data name="codigo_cliente" xml:space="preserve">
    <value>Código de cliente</value>
    <comment>Pantalla de login</comment>
  </data>
  <data name="codigo_de_retorno" xml:space="preserve">
    <value>Código de retorno: </value>
  </data>
  <data name="color" xml:space="preserve">
    <value>Color</value>
    <comment>Pantalla de Pedido</comment>
  </data>
  <data name="coloracion" xml:space="preserve">
    <value>Coloración</value>
    <comment>Pantalla de Pedido</comment>
  </data>
  <data name="comparar" xml:space="preserve">
    <value>Comparar</value>
  </data>
  <data name="comparar_lentes" xml:space="preserve">
    <value>Comparar lentes</value>
  </data>
  <data name="comparar_otra_lente" xml:space="preserve">
    <value>Comparar otra lente</value>
  </data>
  <data name="condiciones" xml:space="preserve">
    <value>Condiciones</value>
  </data>
  <data name="condiciones_1" xml:space="preserve">
    <value>INDO OPTICAL, S.L.U. con CIF B-66232976, domicilio en Avda. Alcalde Barnils, 72 - 08174 Sant Cugat del Vallés - Barcelona e inscrita en el Registro Mercantil de Barcelona en el Tomo 44215, Folio 212, Hoja B-449860, como responsable del tratamiento de los datos, destinataria de la información y titular del fichero denominado “clientes”,  notifica al suscriptor que los datos proporcionados serán introducidos en el fichero denominado “clientes”. La finalidad de la recogida de datos es permitir el intercambio comercial entre INDO y su Óptica a través de Internet y poder así prestar el servicio INDONET.</value>
  </data>
  <data name="condiciones_2" xml:space="preserve">
    <value>La aceptación de las condiciones de uso es obligatoria y la falta de respuesta a los datos solicitados impedirá el servicio a través de la modalidad INDONET.</value>
  </data>
  <data name="condiciones_3" xml:space="preserve">
    <value>Se notifica al suscriptor que puede ejercitar ante el responsable del tratamiento cuantos derechos le otorga la ley y, especialmente, los derechos de acceso, rectificación, cancelación y oposición. Para ello deberá remitir su deseo a través del formulario de contacto de IndoBox: www.indobox-indo.com.</value>
  </data>
  <data name="condiciones_4" xml:space="preserve">
    <value>El suscriptor, como titular de los datos proporcionados, consiente expresamente su tratamiento en la forma descrita.</value>
  </data>
  <data name="conexion_ssl" xml:space="preserve">
    <value>CONEXIÓN SSL</value>
  </data>
  <data name="configuracion" xml:space="preserve">
    <value>Configuración</value>
    <comment>Barra de Herramientas</comment>
  </data>
  <data name="configuracion_activado" xml:space="preserve">
    <value>Activado</value>
  </data>
  <data name="configuracion_altura_montaje" xml:space="preserve">
    <value>Altura de montaje</value>
  </data>
  <data name="configuracion_aro" xml:space="preserve">
    <value>Aro</value>
  </data>
  <data name="configuracion_boxing" xml:space="preserve">
    <value>Boxing</value>
  </data>
  <data name="configuracion_catalogo_lentes" xml:space="preserve">
    <value>Catálogo de lentes Indo</value>
  </data>
  <data name="configuracion_clave_actual" xml:space="preserve">
    <value>Clave actual</value>
  </data>
  <data name="configuracion_confirmar_clave" xml:space="preserve">
    <value>Confirmar clave</value>
  </data>
  <data name="configuracion_desactivado" xml:space="preserve">
    <value>Desactivado</value>
  </data>
  <data name="configuracion_descargas_automaticas" xml:space="preserve">
    <value>Descargas automáticas</value>
  </data>
  <data name="configuracion_dias" xml:space="preserve">
    <value>días</value>
  </data>
  <data name="configuracion_enlaces_relacionados" xml:space="preserve">
    <value>Enlaces relacionados</value>
  </data>
  <data name="configuracion_espesores" xml:space="preserve">
    <value>Configuración de espesores</value>
  </data>
  <data name="configuracion_espesor_borde" xml:space="preserve">
    <value>Espesor borde</value>
  </data>
  <data name="configuracion_guardada_correctamente" xml:space="preserve">
    <value>Configuración guardada correctamente</value>
  </data>
  <data name="configuracion_jerga" xml:space="preserve">
    <value>Jerga</value>
  </data>
  <data name="configuracion_listado_pedidos" xml:space="preserve">
    <value>Listado "Situación de pedidos"</value>
  </data>
  <data name="configuracion_modificar_clave" xml:space="preserve">
    <value>Modificar clave</value>
  </data>
  <data name="configuracion_mostrar_pedidos" xml:space="preserve">
    <value>Mostrar pedidos realizados en los últimos</value>
  </data>
  <data name="configuracion_mostrar_precios" xml:space="preserve">
    <value>Mostrar precios</value>
  </data>
  <data name="configuracion_nombre_comercial" xml:space="preserve">
    <value>Nombre comercial</value>
  </data>
  <data name="configuracion_nombre_lentes" xml:space="preserve">
    <value>Nombre de las lentes</value>
  </data>
  <data name="configuracion_nombre_lentes_help" xml:space="preserve">
    <value>La nueva impostación se enseñara a la proxima autenticación</value>
  </data>
  <data name="configuracion_nueva_clave" xml:space="preserve">
    <value>Nueva clave</value>
  </data>
  <data name="configuracion_precalibrado_cotas" xml:space="preserve">
    <value>Precalibrado a cotas</value>
  </data>
  <data name="configuracion_precios_no" xml:space="preserve">
    <value>No</value>
  </data>
  <data name="configuracion_precios_pvo" xml:space="preserve">
    <value>PVO</value>
  </data>
  <data name="configuracion_precios_pvp" xml:space="preserve">
    <value>PVP</value>
  </data>
  <data name="configuracion_precios_si" xml:space="preserve">
    <value>Sí</value>
  </data>
  <data name="configuracion_pred_indo" xml:space="preserve">
    <value>Pred.Indo</value>
  </data>
  <data name="configuracion_propio" xml:space="preserve">
    <value>Propio</value>
  </data>
  <data name="configuracion_punto_taladro" xml:space="preserve">
    <value>Punto taladro</value>
  </data>
  <data name="configuracion_ranura_metal" xml:space="preserve">
    <value>Ranura metal</value>
  </data>
  <data name="configuracion_ranura_nylon" xml:space="preserve">
    <value>Ranura nylon</value>
  </data>
  <data name="configuracion_restablecer_valores_predeterminados" xml:space="preserve">
    <value>Restablecer valores predeterminados</value>
  </data>
  <data name="configuracion_tooltip_espesores" xml:space="preserve">
    <value>El espesor es inferior al recomendado. Las lentes no serán biseladas</value>
  </data>
  <data name="configuracion_tooltip_numstepper" xml:space="preserve">
    <value>El listado "Situación de pedidos se encuentra en la pantalla "Inicio". El rango permitido es de 1 a 7 días.</value>
  </data>
  <data name="configuracion_URL_catalogo" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/Indonet/Enlaces/TARIFA_INDO_2018_CAS.pdf</value>
  </data>
  <data name="confirmar_borrar_receta" xml:space="preserve">
    <value>¿ Confirma el borrado de la receta guardada y selecionada ?</value>
  </data>
  <data name="confirmar_borrar_recetas" xml:space="preserve">
    <value>¿ Confirma el borrado de las {0} recetas guardadas y selecionadas ?</value>
  </data>
  <data name="confirmar_envio_pedidos_1" xml:space="preserve">
    <value>¿Confirma el envío de la siguiente cantidad de pedidos? #</value>
  </data>
  <data name="confirmar_envio_pedidos_2" xml:space="preserve">
    <value>Los pedidos con errores quedarán en estado "En revisión por INDO"</value>
  </data>
  <data name="confirmar_envio_receta" xml:space="preserve">
    <value>¿ Confirma el envío de la receta guardada y selecionada ?</value>
  </data>
  <data name="confirmar_envio_recetas" xml:space="preserve">
    <value>¿ Confirma el envío de las {0} recetas guardadas y selecionadas ?</value>
  </data>
  <data name="confirmar_envio_recetas_2" xml:space="preserve">
    <value>Las recetas con errores quedarán en estado "En revisión por INDO"</value>
  </data>
  <data name="confirma_biselado_espesor" xml:space="preserve">
    <value>Confirmación de biselado y espesor</value>
  </data>
  <data name="confirma_destino_mercancia" xml:space="preserve">
    <value>Confirma el destinatario de la mercancía</value>
  </data>
  <data name="contacta_con_indo" xml:space="preserve">
    <value>Contacta</value>
    <comment>Pantalla de login</comment>
  </data>
  <data name="continuar" xml:space="preserve">
    <value>Continuar</value>
  </data>
  <data name="correo_solicitud_acceso" xml:space="preserve">
    <value>Correo solicitud acceso</value>
  </data>
  <data name="cota_inferior" xml:space="preserve">
    <value>Cota inferior</value>
  </data>
  <data name="cota_superior" xml:space="preserve">
    <value>Cota superior</value>
  </data>
  <data name="datosoptica_aviso_logotipo" xml:space="preserve">
    <value>No se ha especificado ningun fichero para el logotipo.{0}Se adoptará el logo por defecto </value>
  </data>
  <data name="datosoptica_codigo_postal" xml:space="preserve">
    <value>Código postal :</value>
  </data>
  <data name="datosoptica_comunicaciones" xml:space="preserve">
    <value>Quiero recibir comunicaciones periódicas de Indo</value>
  </data>
  <data name="datosoptica_corregir_datos" xml:space="preserve">
    <value>Si tus datos no son correctos entra en el formulario de contacto del menu superior de INDONET</value>
  </data>
  <data name="datosoptica_direccion" xml:space="preserve">
    <value>Dirección :</value>
  </data>
  <data name="datosoptica_email" xml:space="preserve">
    <value>E-mail :</value>
  </data>
  <data name="datosoptica_enlace_condiciones" xml:space="preserve">
    <value>Leer condiciones de privacidad y protección de datos</value>
  </data>
  <data name="datosoptica_fax" xml:space="preserve">
    <value>Fax :</value>
  </data>
  <data name="datosoptica_guardados_correctamente" xml:space="preserve">
    <value>Datos de optica guardados correctamente</value>
  </data>
  <data name="datosoptica_logotipo_help" xml:space="preserve">
    <value>El nuevo logo se enseñara a la proxima autenticación</value>
  </data>
  <data name="datosoptica_logotipo_optica" xml:space="preserve">
    <value>Logotipo óptica</value>
  </data>
  <data name="datosoptica_nif" xml:space="preserve">
    <value>NIF :</value>
  </data>
  <data name="datosoptica_nombre" xml:space="preserve">
    <value>Nombre :</value>
  </data>
  <data name="datosoptica_poblacion" xml:space="preserve">
    <value>Población :</value>
  </data>
  <data name="datosoptica_telefono" xml:space="preserve">
    <value>Teléfono :</value>
  </data>
  <data name="datos_adicionales" xml:space="preserve">
    <value>Datos adicionales</value>
    <comment>Datos Básicos</comment>
  </data>
  <data name="datos_basicos" xml:space="preserve">
    <value>Datos Básicos</value>
    <comment>Pantalla de Pedido</comment>
  </data>
  <data name="datos_optica" xml:space="preserve">
    <value>Datos Optica</value>
    <comment>Barra de navegación principal</comment>
  </data>
  <data name="datos_tecnicos" xml:space="preserve">
    <value>Datos técnicos</value>
  </data>
  <data name="descentramiento_nasal" xml:space="preserve">
    <value>Descentramiento nasal</value>
    <comment>Suplementos</comment>
  </data>
  <data name="descripcion_calibracion" xml:space="preserve">
    <value>Introduce en milímetros el diámetro real de la circunferencia anterior</value>
  </data>
  <data name="destinatario" xml:space="preserve">
    <value>Destinatario</value>
  </data>
  <data name="detalle_estado_pedido" xml:space="preserve">
    <value>Detalle del estado de pedido</value>
  </data>
  <data name="diametro" xml:space="preserve">
    <value>Diámetro</value>
    <comment>Vista datos básicos ojo</comment>
  </data>
  <data name="dioptrias" xml:space="preserve">
    <value>Dioptrías</value>
    <comment>Suplementos</comment>
  </data>
  <data name="distancia" xml:space="preserve">
    <value>Distancia</value>
    <comment>Datos Adicionales</comment>
  </data>
  <data name="distancia_trabajo" xml:space="preserve">
    <value>Distancia de trabajo</value>
    <comment>Datos Adicionales</comment>
  </data>
  <data name="dnp" xml:space="preserve">
    <value>DNP</value>
    <comment>Datos Adicionales</comment>
  </data>
  <data name="dnp_de_cerca" xml:space="preserve">
    <value>DNP de cerca</value>
  </data>
  <data name="duplicar_receta" xml:space="preserve">
    <value>Duplicar receta</value>
  </data>
  <data name="d_esp" xml:space="preserve">
    <value>D.Esp</value>
  </data>
  <data name="d_especial" xml:space="preserve">
    <value>D. especial</value>
    <comment>Vista datos básicos ojo</comment>
  </data>
  <data name="eje" xml:space="preserve">
    <value>Eje</value>
    <comment>Pantalla de inicio, listado últimos pedidos</comment>
  </data>
  <data name="eje_abrv" xml:space="preserve">
    <value>EJE</value>
    <comment>Cabecera listado pedidos</comment>
  </data>
  <data name="el_valor_maximo_es" xml:space="preserve">
    <value>El valor máximo es</value>
    <comment>Validaciones</comment>
  </data>
  <data name="email" xml:space="preserve">
    <value>Email</value>
  </data>
  <data name="entrar" xml:space="preserve">
    <value>Entrar</value>
    <comment>Pantalla de login</comment>
  </data>
  <data name="enviar" xml:space="preserve">
    <value>Enviar</value>
    <comment>Botonera Acciones del pedido</comment>
  </data>
  <data name="enviar_pedido" xml:space="preserve">
    <value>Enviar pedido</value>
  </data>
  <data name="enviar_sugerencias" xml:space="preserve">
    <value>Enviar sugerencias</value>
  </data>
  <data name="enviar_y_revision" xml:space="preserve">
    <value>Enviar y revisión</value>
    <comment>Botonera Acciones del pedido</comment>
  </data>
  <data name="envio_recetas" xml:space="preserve">
    <value>Envio recetas</value>
  </data>
  <data name="envio_recetas_falta_destinatario" xml:space="preserve">
    <value>Hay que elegir un destinatario</value>
  </data>
  <data name="envio_recetas_ningun_destinatario" xml:space="preserve">
    <value>No se encontró ningun destinatario</value>
  </data>
  <data name="error_abriendo_aplicacion" xml:space="preserve">
    <value>Error abriendo la aplicacion solicitada</value>
  </data>
  <data name="error_abriendo_base_de_datos" xml:space="preserve">
    <value>Se ha producido un error abriendo la base de datos</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_actualizacion_pedido" xml:space="preserve">
    <value>Error en la actualización del pedido</value>
  </data>
  <data name="error_actualizando_fecha_oferta" xml:space="preserve">
    <value>Se ha producido un error actualizando la fecha de la oferta en la base de datos</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_actualizando_oferta" xml:space="preserve">
    <value>Se ha producido un error actualizando la oferta</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_actualizando_pedido" xml:space="preserve">
    <value>***&gt; No se ha podido actualizar el pedido.</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_agente_trazador" xml:space="preserve">
    <value>Error con el Agente Trazador</value>
  </data>
  <data name="error_autenticar_usuario" xml:space="preserve">
    <value>No se ha podido autenticar el usuario</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_base_de_datos" xml:space="preserve">
    <value>Se ha producido una excepcion con la base de datos local</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_base_de_datos_no_abierta" xml:space="preserve">
    <value>La base de datos debe estar abierta</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_borrando_recetas" xml:space="preserve">
    <value>Se ha producido un error borrando las recetas</value>
  </data>
  <data name="error_busqueda_inicial_pedidos" xml:space="preserve">
    <value>Se ha producido un error durante la busqueda inicial de pedidos</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_busqueda_pedidos" xml:space="preserve">
    <value>Error en la búsqueda de pedidos</value>
  </data>
  <data name="error_busqueda_recetas" xml:space="preserve">
    <value>Error en la búsqueda de recetas</value>
  </data>
  <data name="error_calibracion" xml:space="preserve">
    <value>No se ha podido realizar la calibración</value>
  </data>
  <data name="error_cambiando_password" xml:space="preserve">
    <value>No se ha podido cambiar la password</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_caracteres_diferentes_clave_acceso" xml:space="preserve">
    <value>Caracteres diferentes en la clave de acceso</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_caracteres_no_permitidos_clave_acceso" xml:space="preserve">
    <value>Caracteres no permitidos en la clave de acceso</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_cargando_destinatarios" xml:space="preserve">
    <value>Error cargando destinatarios</value>
  </data>
  <data name="error_claves_no_coinciden" xml:space="preserve">
    <value>Las claves de acceso no coinciden</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_clave_acceso_no_permitida" xml:space="preserve">
    <value>Clave de acceso no permitida</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_clave_incorrecta" xml:space="preserve">
    <value>La clave indicada es incorrecta</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_conexion_agente_trazador" xml:space="preserve">
    <value>No hay conexión con el agente del trazador</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_conexion_agente_trazador_elegido" xml:space="preserve">
    <value>Error de conexión con el agente trazador elegido</value>
  </data>
  <data name="error_conexion_agente_trazador_insertado" xml:space="preserve">
    <value>Error de conexión con el agente trazador insertado</value>
  </data>
  <data name="error_conexion_servicio" xml:space="preserve">
    <value>No hay conexión con el servidor</value>
  </data>
  <data name="error_desconocido" xml:space="preserve">
    <value>Error desconocido</value>
  </data>
  <data name="error_email_direccion_invalida" xml:space="preserve">
    <value>La dirección de email no esta bien</value>
    <comment>Errores</comment>
  </data>
  <data name="error_email_falta_asunto" xml:space="preserve">
    <value>El asunto es obligatorio</value>
    <comment>Errores</comment>
  </data>
  <data name="error_email_falta_cuerpo" xml:space="preserve">
    <value>El mensaje es olbigatorio</value>
    <comment>Errores</comment>
  </data>
  <data name="error_email_falta_direccion" xml:space="preserve">
    <value>La dirección de email es obligatoría</value>
    <comment>Errores</comment>
  </data>
  <data name="error_enviando_correo" xml:space="preserve">
    <value>No se ha podido enviar el correo</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_enviando_lista_recetas" xml:space="preserve">
    <value>Error enviando lista de recetas</value>
  </data>
  <data name="error_enviando_mail" xml:space="preserve">
    <value>Se ha producido un error enviando email</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_excepcion_autenticando_usuario" xml:space="preserve">
    <value>Se ha producido una excepción autenticando el usuario</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_excepcion_copando_clase_pedidos" xml:space="preserve">
    <value>Se ha producido una excepción copiando la clase pedidos</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_excepcion_enviando_correo" xml:space="preserve">
    <value>Se ha producido una excepción enviando correo</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_excepcion_enviando_pedido" xml:space="preserve">
    <value>Se ha producido una excepción enviando el pedido</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_excepcion_enviando_pedido_para_comparacion" xml:space="preserve">
    <value>Se ha producido una excepción enviando el pedido para comparación</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_excepcion_obtenido_el_area_venta" xml:space="preserve">
    <value>Se ha producido una excepción obteniendo el área de venta</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_excepcion_obteniendo_pedidos_busqueda" xml:space="preserve">
    <value>Se ha producido una excepción obteniendo lista de pedidos (busqueda)</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_excepcion_obteniendo_pedidos_inicial" xml:space="preserve">
    <value>Se ha producido una excepción obteniendo lista de pedidos (inicial)</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_excepcion_producida" xml:space="preserve">
    <value>Se ha producido una excepción</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_excepcion_recuperando_oferta" xml:space="preserve">
    <value>Se ha producido una excepción recuperando la oferta</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_exceso_numero_intentos_permitidos" xml:space="preserve">
    <value>Se ha excedido el número de intentos permitidos</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_grabando_pedido" xml:space="preserve">
    <value>***&gt; No se ha podido grabar el pedido</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_guardando_datos_configuracion" xml:space="preserve">
    <value>Error guardando datos configuración</value>
  </data>
  <data name="error_guardando_datos_optica" xml:space="preserve">
    <value>Error guardando datos optica</value>
  </data>
  <data name="error_guardando_forma" xml:space="preserve">
    <value>Se ha producido un error guardando la forma</value>
  </data>
  <data name="error_guardando_oferta" xml:space="preserve">
    <value>Se ha producido un error guardando la oferta</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_iniciando_aplicacion" xml:space="preserve">
    <value>Se ha producido un error iniciando la aplicación</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_introducir_nueva_clave" xml:space="preserve">
    <value>Introducir nueva clave de acceso</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_lecturas_agentes" xml:space="preserve">
    <value>Se ha producido un error accediendo a las lecturas de {0} agente{1}</value>
  </data>
  <data name="error_leyendo_fichero_base_de_datos" xml:space="preserve">
    <value>Se ha producido un error leyendo el fichero de la base de datos</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_login" xml:space="preserve">
    <value>Se ha producido un error durante el login</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_no_se_han_obtenido_datos_configuracion_cliente" xml:space="preserve">
    <value>No se han podido obtener los datos de configuración del cliente</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_no_se_ha_obtenido_el_area_venta" xml:space="preserve">
    <value>No se ha podido obtener el area de venta</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_no_se_ha_podido_recuperar_lista_pedidos" xml:space="preserve">
    <value>***&gt;No se ha podido recuperar la lista con los pedidos.</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_no_se_ha_podido_recuperar_oferta" xml:space="preserve">
    <value>***&gt;No se ha podido recuperar la oferta.</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_obteniendo_los_datos_configuracion_del_cliente" xml:space="preserve">
    <value>Se ha producido una excepción obteniendo los datos de configuración</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_proceso_login" xml:space="preserve">
    <value>Se ha producido un error en el proceso de login</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_recuperando_monturas" xml:space="preserve">
    <value>Error recuperando monturas</value>
  </data>
  <data name="error_recuperando_pedido" xml:space="preserve">
    <value>***&gt; No se ha podido recuperar el pedido</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_recuperando_receta" xml:space="preserve">
    <value>***&gt; No se ha podido recuperar la receta</value>
  </data>
  <data name="error_recuperando_recetas" xml:space="preserve">
    <value>Error recuperando recetas</value>
  </data>
  <data name="error_reintentos_excedidos" xml:space="preserve">
    <value>Reintentos excedidos</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_servicio_cambio_de_password" xml:space="preserve">
    <value>El servicio 'Cambio de Password' ha dado un error</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_servicio_envio_correo" xml:space="preserve">
    <value>El servicio 'Envio Correo' ha dado un error</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_servicio_login" xml:space="preserve">
    <value>El servicio 'Login' ha dado un error</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_signo_interrogacion_no_permitido_clave_acceso" xml:space="preserve">
    <value>Signo de interrogación no permitido en la clave de acceso</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_tipo_de_base_de_datos_desconocida" xml:space="preserve">
    <value>Tipo de base de datos desconocido</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="error_usuario_bloqueado" xml:space="preserve">
    <value>El usuario está bloqueado</value>
  </data>
  <data name="error_usuario_no_existe" xml:space="preserve">
    <value>El usuario no existe</value>
  </data>
  <data name="error_usuario_ya_existe" xml:space="preserve">
    <value>El usuario ya existe</value>
  </data>
  <data name="esfera" xml:space="preserve">
    <value>Esfera</value>
    <comment>Pantalla de inicio, listado últimos pedidos</comment>
  </data>
  <data name="esfera_abrv" xml:space="preserve">
    <value>ESF</value>
    <comment>Cabecera listado pedidos</comment>
  </data>
  <data name="espana" xml:space="preserve">
    <value>España</value>
  </data>
  <data name="espesor" xml:space="preserve">
    <value>Espesor</value>
    <comment>Datos técnicos</comment>
  </data>
  <data name="espesores" xml:space="preserve">
    <value>Espesores</value>
  </data>
  <data name="espesor_borde_esp" xml:space="preserve">
    <value>Espesor borde esp.</value>
  </data>
  <data name="espesor_borde_especial" xml:space="preserve">
    <value>Espesor borde especial</value>
  </data>
  <data name="espesor_centro" xml:space="preserve">
    <value>Espesor centro</value>
    <comment>Datos técnicos</comment>
  </data>
  <data name="espesor_centro_abr" xml:space="preserve">
    <value>Espesor cen.</value>
  </data>
  <data name="espesor_max" xml:space="preserve">
    <value>Espesor máx.</value>
  </data>
  <data name="espesor_maximo" xml:space="preserve">
    <value>Espesor máximo</value>
    <comment>Datos técnicos</comment>
  </data>
  <data name="espesor_min" xml:space="preserve">
    <value>Espesor mín.</value>
  </data>
  <data name="espesor_minimo" xml:space="preserve">
    <value>Espesor mínimo</value>
    <comment>Datos técnicos</comment>
  </data>
  <data name="espesor_taladro_nasal" xml:space="preserve">
    <value>Espesor taladro nasal</value>
    <comment>Datos técnicos</comment>
  </data>
  <data name="espesor_taladro_temporal" xml:space="preserve">
    <value>Espesor taladro temporal</value>
    <comment>Datos técnicos</comment>
  </data>
  <data name="estado" xml:space="preserve">
    <value>Estado</value>
    <comment>Pantalla de inicio, listado últimos pedidos</comment>
  </data>
  <data name="estadoOD" xml:space="preserve">
    <value>estado OD</value>
  </data>
  <data name="estadoOI" xml:space="preserve">
    <value>estado OI</value>
  </data>
  <data name="estado_pedido_grabados" xml:space="preserve">
    <value>Grabados</value>
  </data>
  <data name="estado_pedido_pendientes" xml:space="preserve">
    <value>Pendientes de arreglo</value>
  </data>
  <data name="estado_pedido_todos" xml:space="preserve">
    <value>Todos</value>
  </data>
  <data name="es_anterior_a" xml:space="preserve">
    <value>es anterior a</value>
  </data>
  <data name="es_necesario_cerrar_la_aplicacion" xml:space="preserve">
    <value>La aplicación se ha instalado correctamente.{0}A continuación pulse Aceptar, espere unos segundos a que se cierre y haga doble click sobre el icono Indonet que está en su escritorio</value>
  </data>
  <data name="expedido" xml:space="preserve">
    <value>Expedido</value>
  </data>
  <data name="explorar" xml:space="preserve">
    <value>Explorar</value>
  </data>
  <data name="fecha" xml:space="preserve">
    <value>Fecha</value>
    <comment>Pantalla de inicio, listado últimos pedidos</comment>
  </data>
  <data name="fecha_envio" xml:space="preserve">
    <value>Fecha de envio:</value>
  </data>
  <data name="fecha_hi_menor_low" xml:space="preserve">
    <value>La fecha "hasta" no puede ser anterior a "desde"</value>
  </data>
  <data name="fecha_hi_nula" xml:space="preserve">
    <value>La fecha "hasta" no puede ser nula</value>
  </data>
  <data name="fecha_hora_grabacion" xml:space="preserve">
    <value>Fecha y hora de grabación</value>
  </data>
  <data name="fecha_low_nula" xml:space="preserve">
    <value>La fecha "desde" no puede ser nula</value>
  </data>
  <data name="fecha_prevista_envio" xml:space="preserve">
    <value>Fecha prevista de salida</value>
  </data>
  <data name="fecha_rango" xml:space="preserve">
    <value>Fecha (rango de 7 días)</value>
  </data>
  <data name="finalizado" xml:space="preserve">
    <value>Finalizado</value>
  </data>
  <data name="foco_y_material" xml:space="preserve">
    <value>Foco y Material</value>
    <comment>Vista datos básicos ojo</comment>
  </data>
  <data name="forma" xml:space="preserve">
    <value>Forma</value>
  </data>
  <data name="formas_leidas" xml:space="preserve">
    <value>Formas leidas</value>
  </data>
  <data name="formas_y_espesores" xml:space="preserve">
    <value>Formas y Espesores</value>
    <comment>Pantalla de Pedido</comment>
  </data>
  <data name="forma_escogida" xml:space="preserve">
    <value>Forma escogida</value>
  </data>
  <data name="forma_guardada_OK" xml:space="preserve">
    <value>Forma guardada correctamente</value>
  </data>
  <data name="fotocromatico" xml:space="preserve">
    <value>Fotocromático</value>
    <comment>Pantalla de Pedido</comment>
  </data>
  <data name="francia" xml:space="preserve">
    <value>Francia</value>
  </data>
  <data name="frontal" xml:space="preserve">
    <value>Frontal</value>
    <comment>Orientacion del bisel</comment>
  </data>
  <data name="ganancia_en_espesor" xml:space="preserve">
    <value>Ganancia en espesor</value>
  </data>
  <data name="ganancia_en_espesor_abr" xml:space="preserve">
    <value>Gan. en esp.</value>
  </data>
  <data name="ganancia_en_peso" xml:space="preserve">
    <value>Ganancia en peso</value>
  </data>
  <data name="ganancia_en_peso_abr" xml:space="preserve">
    <value>Gan. en peso</value>
  </data>
  <data name="ganancia_espesor" xml:space="preserve">
    <value>Ganancia en espesor</value>
    <comment>Datos técnicos</comment>
  </data>
  <data name="ganancia_peso" xml:space="preserve">
    <value>Ganancia en peso</value>
    <comment>Datos técnicos</comment>
  </data>
  <data name="grados" xml:space="preserve">
    <value>Grados</value>
    <comment>Suplementos</comment>
  </data>
  <data name="guardar_biselado" xml:space="preserve">
    <value>Guardar biselado</value>
  </data>
  <data name="guardar_receta" xml:space="preserve">
    <value>Guardar receta</value>
    <comment>Botonera Acciones del pedido</comment>
  </data>
  <data name="hora" xml:space="preserve">
    <value>Hora</value>
  </data>
  <data name="idioma" xml:space="preserve">
    <value>Idioma</value>
    <comment>Pantalla de login</comment>
  </data>
  <data name="imprimir" xml:space="preserve">
    <value>Imprimir</value>
    <comment>Botonera Acciones del pedido</comment>
  </data>
  <data name="imprimir_lista" xml:space="preserve">
    <value>Imprimir lista</value>
  </data>
  <data name="indice" xml:space="preserve">
    <value>Indice</value>
    <comment>Vista datos básicos ojo</comment>
  </data>
  <data name="indonet" xml:space="preserve">
    <value>Indonet</value>
  </data>
  <data name="indo_en_el_mundo" xml:space="preserve">
    <value>» Indo en el mundo</value>
  </data>
  <data name="inferior" xml:space="preserve">
    <value>Inferior</value>
  </data>
  <data name="infolog_actualizacion_oferta" xml:space="preserve">
    <value>***&gt; Comienzo actualización oferta</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="infolog_cabecera_oferta_ok" xml:space="preserve">
    <value>***&gt; Cabecera oferta OK</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="infolog_creacion_bbdd_local" xml:space="preserve">
    <value>***&gt; Comienzo creación bbdd local</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="infolog_creacion_bbdd_local_OK" xml:space="preserve">
    <value>***&gt; Creación bbdd local OK</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="infolog_grabar_oferta" xml:space="preserve">
    <value>***&gt; Comienzo grabar oferta en bbdd local</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="infolog_grabar_oferta_OK" xml:space="preserve">
    <value>***&gt; Grabar oferta en bbdd local OK</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="infolog_oferta_descargada_ok" xml:space="preserve">
    <value>***&gt; Oferta descargada OK</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="informacion" xml:space="preserve">
    <value>Información</value>
  </data>
  <data name="info_pedido_procesado" xml:space="preserve">
    <value>Tu pedido con referencia {0} ha sido grabado y se ha procesado correctamente</value>
  </data>
  <data name="inicio" xml:space="preserve">
    <value>Inicio</value>
    <comment>Barra de navegación principal</comment>
  </data>
  <data name="intervalo_de_fechas" xml:space="preserve">
    <value>Intervalo de fechas</value>
  </data>
  <data name="italia" xml:space="preserve">
    <value>Italia</value>
  </data>
  <data name="laboratorio" xml:space="preserve">
    <value>Laboratorio</value>
  </data>
  <data name="lente" xml:space="preserve">
    <value>Lente</value>
    <comment>Pantalla de inicio, listado últimos pedidos</comment>
  </data>
  <data name="login_verificar" xml:space="preserve">
    <value>VERIFICAR</value>
  </data>
  <data name="logout" xml:space="preserve">
    <value>Log Out</value>
    <comment>Barra de Herramientas</comment>
  </data>
  <data name="l_taladrada" xml:space="preserve">
    <value>Plano</value>
    <comment>Tipo de bisel</comment>
  </data>
  <data name="marca" xml:space="preserve">
    <value>Marca</value>
  </data>
  <data name="mensaje" xml:space="preserve">
    <value>Mensaje</value>
  </data>
  <data name="mensaje_solicitud_clave" xml:space="preserve">
    <value>Para disponer de una contraseña sólo debe introducir una dirección de email correcta y su código de cliente Indo.{0}En breve recibirá su contraseña para entrar en Indonet.{0}Muchas Gracias</value>
  </data>
  <data name="metal" xml:space="preserve">
    <value>Metal</value>
    <comment>tipo de montura</comment>
  </data>
  <data name="mini_bisel" xml:space="preserve">
    <value>Minibisel</value>
    <comment>Tipo de bisel</comment>
  </data>
  <data name="mis_biselados" xml:space="preserve">
    <value>Mis biselados</value>
  </data>
  <data name="modelo" xml:space="preserve">
    <value>Modelo</value>
  </data>
  <data name="modificar_espesor" xml:space="preserve">
    <value>Modificar espesor</value>
  </data>
  <data name="montaje" xml:space="preserve">
    <value>Montaje</value>
  </data>
  <data name="monturas" xml:space="preserve">
    <value>Monturas</value>
    <comment>Pantalla de Pedido</comment>
  </data>
  <data name="mostrar_lentes_stock" xml:space="preserve">
    <value>Mostrar sólo lentes de stock</value>
    <comment>Vista datos básicos ojo</comment>
  </data>
  <data name="nasal" xml:space="preserve">
    <value>Nasal</value>
  </data>
  <data name="nd" xml:space="preserve">
    <value>Nd</value>
    <comment>Datos técnicos</comment>
  </data>
  <data name="ninguna" xml:space="preserve">
    <value>Ninguna</value>
  </data>
  <data name="noticias" xml:space="preserve">
    <value>Noticias</value>
    <comment>Pantalla de incio</comment>
  </data>
  <data name="no_esta_bien_formado" xml:space="preserve">
    <value>No esta bien formado</value>
    <comment>Validaciones</comment>
  </data>
  <data name="no_es_multiplo_de" xml:space="preserve">
    <value>No es múltiplo de</value>
    <comment>Validaciones</comment>
  </data>
  <data name="no_se_actualiza_la_oferta" xml:space="preserve">
    <value>No se actualiza la oferta</value>
    <comment>Logs &amp; errores</comment>
  </data>
  <data name="no_se_ha_podido_enviar_la_solucitud" xml:space="preserve">
    <value>No se ha podido enviar la solicitud, intentelo más tarde</value>
    <comment>popupMail</comment>
  </data>
  <data name="nueva_prev" xml:space="preserve">
    <value>Nueva Prev.</value>
    <comment>Cabecera listado pedidos</comment>
  </data>
  <data name="nueva_prevision" xml:space="preserve">
    <value>Nueva previsión</value>
    <comment>Pantalla de inicio, listado últimos pedidos</comment>
  </data>
  <data name="numero_pedido" xml:space="preserve">
    <value>Nº pedido</value>
  </data>
  <data name="n_abbe" xml:space="preserve">
    <value>Nº Abbe</value>
    <comment>Datos técnicos</comment>
  </data>
  <data name="n_pedido" xml:space="preserve">
    <value>Nº pedido</value>
    <comment>Pantalla de inicio, listado últimos pedidos</comment>
  </data>
  <data name="observaciones" xml:space="preserve">
    <value>Observaciones</value>
  </data>
  <data name="OD" xml:space="preserve">
    <value>OD</value>
    <comment>Ojo derecho</comment>
  </data>
  <data name="oferta" xml:space="preserve">
    <value>Oferta</value>
  </data>
  <data name="OI" xml:space="preserve">
    <value>OI</value>
    <comment>Ojo Izquierdo</comment>
  </data>
  <data name="ojo_derecho" xml:space="preserve">
    <value>Ojo derecho</value>
    <comment>Enviar pedido</comment>
  </data>
  <data name="ojo_informativo" xml:space="preserve">
    <value>Ojo informativo</value>
    <comment>Vista datos básicos ojo</comment>
  </data>
  <data name="ojo_izquierdo" xml:space="preserve">
    <value>Ojo izquierdo</value>
    <comment>Enviar pedido</comment>
  </data>
  <data name="orientacion_del_bisel" xml:space="preserve">
    <value>Orientacion del bisel</value>
  </data>
  <data name="paso1" xml:space="preserve">
    <value>Paso 1</value>
  </data>
  <data name="paso2" xml:space="preserve">
    <value>Paso 2</value>
  </data>
  <data name="pedidos_al_por_mayor" xml:space="preserve">
    <value>Pedidos al por mayor</value>
  </data>
  <data name="pedidos_obtenidos" xml:space="preserve">
    <value>Pedido(s) obtenido(s)</value>
  </data>
  <data name="pedidos_realizados" xml:space="preserve">
    <value>Pedidos Realizados</value>
    <comment>Barra de navegación principal</comment>
  </data>
  <data name="pedido_con_errores" xml:space="preserve">
    <value>No se puede enviar el pedido. Revise las alertas</value>
    <comment>Alerts</comment>
  </data>
  <data name="peso" xml:space="preserve">
    <value>Peso</value>
    <comment>Datos técnicos</comment>
  </data>
  <data name="plano" xml:space="preserve">
    <value>Plano</value>
    <comment>Tipo de bisel</comment>
  </data>
  <data name="portugal" xml:space="preserve">
    <value>Portugal</value>
  </data>
  <data name="por_favor_confirma_datos_pedido" xml:space="preserve">
    <value>Por favor, confirma los datos de tu pedido:</value>
    <comment>Enviar pedido</comment>
  </data>
  <data name="precalibrado_a_cotas" xml:space="preserve">
    <value>Precalibrado a cotas</value>
  </data>
  <data name="precalibrado_digital" xml:space="preserve">
    <value>Precalibrado digital</value>
  </data>
  <data name="precio" xml:space="preserve">
    <value>Precio</value>
  </data>
  <data name="prevision" xml:space="preserve">
    <value>Previsión</value>
    <comment>Pantalla de inicio, listado últimos pedidos</comment>
  </data>
  <data name="prisma_1" xml:space="preserve">
    <value>Prisma 1</value>
    <comment>Suplementos</comment>
  </data>
  <data name="prisma_2" xml:space="preserve">
    <value>Prisma 2</value>
    <comment>Suplementos</comment>
  </data>
  <data name="producto_lente" xml:space="preserve">
    <value>Producto (lente)</value>
  </data>
  <data name="puente" xml:space="preserve">
    <value>Puente</value>
    <comment>Datos Adicionales</comment>
  </data>
  <data name="radio" xml:space="preserve">
    <value>Radio</value>
    <comment>Datos técnicos</comment>
  </data>
  <data name="ranurada" xml:space="preserve">
    <value>Ranurada</value>
    <comment>tipo de montura</comment>
  </data>
  <data name="ranurada_metal" xml:space="preserve">
    <value>Ranurada Metal</value>
    <comment>Tipo de bisel</comment>
  </data>
  <data name="ranurada_nylon" xml:space="preserve">
    <value>Ranurada Nylon</value>
    <comment>Tipo de bisel</comment>
  </data>
  <data name="realizar_pedido" xml:space="preserve">
    <value>Realizar Pedidos</value>
    <comment>Barra de navegación principal</comment>
  </data>
  <data name="recetas_borrado" xml:space="preserve">
    <value>Borrado de recetas</value>
  </data>
  <data name="recetas_borrar" xml:space="preserve">
    <value>Borrar recetas</value>
  </data>
  <data name="recetas_enviar" xml:space="preserve">
    <value>Enviar recetas</value>
  </data>
  <data name="recetas_envio" xml:space="preserve">
    <value>Envío de recetas</value>
  </data>
  <data name="recetas_guardadas" xml:space="preserve">
    <value>Recetas guardadas</value>
    <comment>Barra de navegación principal</comment>
  </data>
  <data name="recetas_obtenidas" xml:space="preserve">
    <value>Receta(s) obtenida(s)</value>
  </data>
  <data name="receta_envio_masivo" xml:space="preserve">
    <value>Pedido generado masivamente</value>
  </data>
  <data name="receta_guardada" xml:space="preserve">
    <value>La receta se ha guardado correctamente</value>
  </data>
  <data name="redimensionar" xml:space="preserve">
    <value>Redimensionar</value>
  </data>
  <data name="referencia" xml:space="preserve">
    <value>Referencia</value>
    <comment>Pantalla de Pedido</comment>
  </data>
  <data name="ref_cliente" xml:space="preserve">
    <value>Ref.Cliente</value>
    <comment>Pantalla de inicio, listado últimos pedidos</comment>
  </data>
  <data name="registros" xml:space="preserve">
    <value>Registros</value>
  </data>
  <data name="resto_de_paises" xml:space="preserve">
    <value>Resto de países</value>
    <comment>Login</comment>
  </data>
  <data name="rx" xml:space="preserve">
    <value>RX</value>
    <comment>Pantalla de inicio, listado últimos pedidos</comment>
  </data>
  <data name="salir" xml:space="preserve">
    <value>Salir</value>
  </data>
  <data name="segura" xml:space="preserve">
    <value>SEGURA</value>
  </data>
  <data name="seguro_que_quiere_desconectarse" xml:space="preserve">
    <value>Está seguro que quiere desconectarse?</value>
    <comment>Alerts</comment>
  </data>
  <data name="seguro_que_quiere_salir" xml:space="preserve">
    <value>Está seguro que quiere salir de la aplicación?</value>
    <comment>Alerts</comment>
  </data>
  <data name="selecciona" xml:space="preserve">
    <value>Selecciona</value>
  </data>
  <data name="selecciona_abrv" xml:space="preserve">
    <value>Selec.</value>
  </data>
  <data name="seleccion_de_espesores" xml:space="preserve">
    <value>Selección de espesores</value>
  </data>
  <data name="seleccion_vacia_recetas" xml:space="preserve">
    <value>No se ha seleccionado ninguna receta</value>
  </data>
  <data name="se_actualizo" xml:space="preserve">
    <value>Se actualizó</value>
  </data>
  <data name="siguiente" xml:space="preserve">
    <value>Siguiente</value>
  </data>
  <data name="simulador_de_lentes" xml:space="preserve">
    <value>Simulador de lentes</value>
  </data>
  <data name="simulador_error" xml:space="preserve">
    <value>Error al lanzar el simulador de lentes</value>
  </data>
  <data name="simulador_no_instalado" xml:space="preserve">
    <value>El simulador de lentes no esta instalado</value>
  </data>
  <data name="sin_coloracion" xml:space="preserve">
    <value>Sin coloración</value>
  </data>
  <data name="sin_fotocromatico" xml:space="preserve">
    <value>Sin fotocromático</value>
  </data>
  <data name="sin_precalibrado" xml:space="preserve">
    <value>Sin precalibrado</value>
  </data>
  <data name="situacion_de_los_pedidos" xml:space="preserve">
    <value>Situación de los pedidos</value>
  </data>
  <data name="solicitar_acceso" xml:space="preserve">
    <value>Solicitar acceso</value>
  </data>
  <data name="solicitar_clave_acceso" xml:space="preserve">
    <value>» Solicitar clave de acceso</value>
  </data>
  <data name="solicitud_acceso" xml:space="preserve">
    <value>Solicitud Acceso</value>
  </data>
  <data name="solicitud_enviada_correctamente" xml:space="preserve">
    <value>La solicitud de acceso ha sido enviada correctamente</value>
    <comment>popupMail</comment>
  </data>
  <data name="solo_stock" xml:space="preserve">
    <value>Sólo stock</value>
  </data>
  <data name="stock" xml:space="preserve">
    <value>Stock</value>
  </data>
  <data name="sugerencias" xml:space="preserve">
    <value>Sugerencias</value>
    <comment>Barra de Herramientas</comment>
  </data>
  <data name="superior" xml:space="preserve">
    <value>Superior</value>
  </data>
  <data name="suplementos" xml:space="preserve">
    <value>Suplementos</value>
    <comment>Datos Básicos</comment>
  </data>
  <data name="taladrada" xml:space="preserve">
    <value>Taladrada</value>
    <comment>tipo de montura</comment>
  </data>
  <data name="talla" xml:space="preserve">
    <value>Talla</value>
  </data>
  <data name="temporal" xml:space="preserve">
    <value>Temporal</value>
  </data>
  <data name="texto" xml:space="preserve">
    <value>Texto</value>
  </data>
  <data name="texto_presentacion1" xml:space="preserve">
    <value>Bienvenido a la nueva generación de pedidos on line.</value>
  </data>
  <data name="texto_presentacion2" xml:space="preserve">
    <value>Aquí comienza el camino de la nueva Indo!</value>
  </data>
  <data name="texto_presentacion3" xml:space="preserve">
    <value>Sorpréndete con la herramienta más ágil e intuitiva del mercado para realizar tus pedidos. Con funciones que te permitirán aumentar el valor añadido de tus ventas.</value>
  </data>
  <data name="texto_presentacion4" xml:space="preserve">
    <value>Personalizar y optimizar los espesores en lentes de laboratorio.</value>
  </data>
  <data name="texto_presentacion5" xml:space="preserve">
    <value>Usar la mayoría de trazadores del mercado.</value>
  </data>
  <data name="texto_presentacion6" xml:space="preserve">
    <value>Hacer un seguimiento más detallado de tus pedidos.</value>
  </data>
  <data name="texto_presentacion7" xml:space="preserve">
    <value>Consulta con tu delegado comercial, o tu gestor personalizado, la opción más adecuada para cubrir todas tus necesidades.</value>
  </data>
  <data name="text_info_contacto" xml:space="preserve">
    <value>Si necesitas más información, contacta con nosotros en :</value>
    <comment>Pantalla de login</comment>
  </data>
  <data name="tipo_bisel" xml:space="preserve">
    <value>Tipo bisel</value>
  </data>
  <data name="tipo_de_bisel" xml:space="preserve">
    <value>Tipo de bisel</value>
  </data>
  <data name="tipo_de_montura" xml:space="preserve">
    <value>Tipo de montura</value>
  </data>
  <data name="tipo_lente" xml:space="preserve">
    <value>Tipo de lente</value>
  </data>
  <data name="tipo_montura" xml:space="preserve">
    <value>Tipo montura</value>
  </data>
  <data name="tono" xml:space="preserve">
    <value>Tono</value>
    <comment>Pantalla de Pedido</comment>
  </data>
  <data name="tratamientos" xml:space="preserve">
    <value>Tratamientos</value>
    <comment>Vista datos básicos ojo</comment>
  </data>
  <data name="tratamiento_coloracion" xml:space="preserve">
    <value>Tratamiento y coloración</value>
  </data>
  <data name="trazador" xml:space="preserve">
    <value>Trazador</value>
  </data>
  <data name="trazadores" xml:space="preserve">
    <value>Trazadores</value>
  </data>
  <data name="trazadores_anadir" xml:space="preserve">
    <value>Añadir ...</value>
  </data>
  <data name="trazadores_borrar" xml:space="preserve">
    <value>Borrar</value>
  </data>
  <data name="trazadores_borrar_todos" xml:space="preserve">
    <value>Borrar todos</value>
  </data>
  <data name="trazadores_comprobacion" xml:space="preserve">
    <value>Comprobación</value>
  </data>
  <data name="trazadores_conexion_agente_trazador_OK" xml:space="preserve">
    <value>EL AGENTE TRAZADOR {0} ESTÁ CONECTADO CORRECTAMENTE</value>
  </data>
  <data name="trazadores_direccion" xml:space="preserve">
    <value>Dirección</value>
  </data>
  <data name="trazadores_modificar" xml:space="preserve">
    <value>Modificar</value>
  </data>
  <data name="trazadores_nombre_agente" xml:space="preserve">
    <value>Nombre agente</value>
  </data>
  <data name="trazadores_popup_anular" xml:space="preserve">
    <value>Anular</value>
  </data>
  <data name="trazadores_popup_titulo" xml:space="preserve">
    <value>Seleción / Modificación agente trazador</value>
  </data>
  <data name="trazadores_puerto" xml:space="preserve">
    <value>Puerto</value>
  </data>
  <data name="trazadores_trazador" xml:space="preserve">
    <value>Trazador</value>
  </data>
  <data name="trazador_busqueda_ordenadores_red" xml:space="preserve">
    <value>Seleccionar el equipo en la red</value>
  </data>
  <data name="tto_adicional" xml:space="preserve">
    <value>Tto. Adicional</value>
    <comment>Vista datos básicos ojo</comment>
  </data>
  <data name="tutoriales" xml:space="preserve">
    <value>Tutoriales</value>
    <comment>Barra de Herramientas</comment>
  </data>
  <data name="t_por_ciento" xml:space="preserve">
    <value>T%</value>
    <comment>Datos técnicos</comment>
  </data>
  <data name="ultimos_pedidos" xml:space="preserve">
    <value>Últimos pedidos</value>
    <comment>Pantalla de inicio</comment>
  </data>
  <data name="url_indonet_direct" xml:space="preserve">
    <value>http://pedidos.direc.indo.es/</value>
    <comment>Links </comment>
  </data>
  <data name="url_indo_en_el_mundo" xml:space="preserve">
    <value>http://www.indo.es/indo-en-el-mundo/indo_enelmundo.asp</value>
    <comment>Links</comment>
  </data>
  <data name="url_mas_informacion" xml:space="preserve">
    <value>http://www.indo.es/lentes/lentes_lentesindo.asp</value>
    <comment>Links</comment>
  </data>
  <data name="url_tutoriales" xml:space="preserve">
    <value>http://www.indo.es/es/optics/tutorials</value>
    <comment>Links</comment>
  </data>
  <data name="ventana_espera_actualizar_pedido" xml:space="preserve">
    <value>Actualizando pedido</value>
  </data>
  <data name="ventana_espera_areaventa" xml:space="preserve">
    <value>Cargando area venta</value>
  </data>
  <data name="ventana_espera_borrar_recetas" xml:space="preserve">
    <value>Borrando recetas</value>
  </data>
  <data name="ventana_espera_busqueda_pedidos" xml:space="preserve">
    <value>Buscando pedidos</value>
  </data>
  <data name="ventana_espera_busqueda_recetas" xml:space="preserve">
    <value>Buscando recetas</value>
  </data>
  <data name="ventana_espera_cambio_clave" xml:space="preserve">
    <value>Guardando nueva clave de acceso</value>
  </data>
  <data name="ventana_espera_carga_pedido" xml:space="preserve">
    <value>Cargando pedido {0}</value>
  </data>
  <data name="ventana_espera_carga_pedidos_inicial" xml:space="preserve">
    <value>Cargando lista de pedidos inicial</value>
  </data>
  <data name="ventana_espera_carga_receta" xml:space="preserve">
    <value>Cargando receta {0}</value>
  </data>
  <data name="ventana_espera_configuracion" xml:space="preserve">
    <value>Cargando configuración</value>
  </data>
  <data name="ventana_espera_destinatarios" xml:space="preserve">
    <value>Cargando destinatarios</value>
  </data>
  <data name="ventana_espera_envio_correo" xml:space="preserve">
    <value>Enviando correo</value>
  </data>
  <data name="ventana_espera_envio_pedidos" xml:space="preserve">
    <value>Enviando pedido</value>
  </data>
  <data name="ventana_espera_envio_recetas" xml:space="preserve">
    <value>Enviando recetas</value>
  </data>
  <data name="ventana_espera_esperar" xml:space="preserve">
    <value>Espera</value>
  </data>
  <data name="ventana_espera_guardar_configuracion" xml:space="preserve">
    <value>Guardando configuración</value>
  </data>
  <data name="ventana_espera_guardar_datos_optica" xml:space="preserve">
    <value>Guardando datos óptica</value>
  </data>
  <data name="ventana_espera_guardar_receta" xml:space="preserve">
    <value>Guardando receta {0}</value>
  </data>
  <data name="ventana_espera_lecturas_trazador" xml:space="preserve">
    <value>Lecturas trazador {0}{1}</value>
  </data>
  <data name="ventana_espera_lecturas_trazadores" xml:space="preserve">
    <value>Lecturas agente trazador {0}</value>
  </data>
  <data name="ventana_espera_lectura_trazador" xml:space="preserve">
    <value>Lectura {0}</value>
  </data>
  <data name="ventana_espera_login" xml:space="preserve">
    <value>Autenticación cliente {0}</value>
  </data>
  <data name="ventana_espera_misbiselados" xml:space="preserve">
    <value>Cargando biselados</value>
  </data>
  <data name="ventana_espera_monturas" xml:space="preserve">
    <value>Cargando monturas</value>
  </data>
  <data name="ventana_espera_oferta" xml:space="preserve">
    <value>Actualizado oferta</value>
  </data>
  <data name="ventana_espera_revision_pedidos" xml:space="preserve">
    <value>Envío a revisión</value>
  </data>
  <data name="ventana_espera_verifica_conexion_trazador" xml:space="preserve">
    <value>Verifica conexión agente trazador {0}</value>
  </data>
  <data name="verifique_conexion" xml:space="preserve">
    <value>Verifique la conexión con Internet</value>
  </data>
  <data name="volver_al_listado" xml:space="preserve">
    <value>Volver al listado</value>
  </data>
  <data name="zona_de_identificacion" xml:space="preserve">
    <value>Zona de identificación</value>
    <comment>Barra de Herramientas</comment>
  </data>
  <data name="ventana_espera_cargando_clientes" xml:space="preserve">
    <value>Recuperando clientes</value>
  </data>
  <data name="selecciona_la_lente_deseada" xml:space="preserve">
    <value>Selecciona la lente deseada</value>
  </data>
  <data name="obligatorio_seleccionar_destiantario" xml:space="preserve">
    <value>Es necesario informar el destinatário de la mercancía</value>
  </data>
  <data name="selecciona_cliente" xml:space="preserve">
    <value>Selecciona el cliente</value>
  </data>
  <data name="altura_montaje_total" xml:space="preserve">
    <value>Altura de montaje final</value>
  </data>
  <data name="error_grabacion_pedido" xml:space="preserve">
    <value>Se ha producido un error en la grabación de su pedido. Por favor, vuelva a realizarlo</value>
  </data>
  <data name="ventana_espera_comparando_lentes" xml:space="preserve">
    <value>Comparando lentes</value>
  </data>
  <data name="borde_especial_abr" xml:space="preserve">
    <value>B. Esp</value>
  </data>
  <data name="precalibrado_digital_abr" xml:space="preserve">
    <value>Precal.</value>
  </data>
  <data name="ventana_espera_obteniendo_bases" xml:space="preserve">
    <value>Recuperando bases</value>
  </data>
  <data name="info_envio_pedido_revisar" xml:space="preserve">
    <value>Su pedido ha sido grabado. Indo lo revisará y lo pondrá en marcha en breve</value>
  </data>
  <data name="info_ojo_informativo" xml:space="preserve">
    <value>Al marcar este ojo como informativo no se fabricará</value>
  </data>
  <data name="ventana_espera_seleccion_lente" xml:space="preserve">
    <value>Seleccionando lente</value>
  </data>
  <data name="mas_informacion" xml:space="preserve">
    <value>Más información</value>
  </data>
  <data name="sistema_pedidos_por_mayor" xml:space="preserve">
    <value>Sistema de pedidos al por mayor</value>
  </data>
  <data name="configuracion_fecha_oferta" xml:space="preserve">
    <value>Fecha Oferta</value>
    <comment>Lectura de la fecha de la oferta en la BBDD</comment>
  </data>
  <data name="optimizacion_freemax" xml:space="preserve">
    <value>Optimización FreeMax</value>
    <comment>Leyenda del precalibrado digital para MAXIMA</comment>
  </data>
  <data name="aviso_reiniciar_oferta" xml:space="preserve">
    <value>¿Está seguro de que quiere recargar la oferta?</value>
  </data>
  <data name="reiniciar_fecha_oferta" xml:space="preserve">
    <value>Recargar oferta</value>
  </data>
  <data name="fecha_prevista_recepcion" xml:space="preserve">
    <value>Fecha prevista de recepción</value>
  </data>
  <data name="toolTip_abrir_indoscan" xml:space="preserve">
    <value>Abrir INDOScan</value>
  </data>
  <data name="aviso_falta_scaner" xml:space="preserve">
    <value>No se pudo inicializar el scaner</value>
    <comment>IndoScan</comment>
  </data>
  <data name="activar" xml:space="preserve">
    <value>Activado</value>
  </data>
  <data name="finalizar_pedido" xml:space="preserve">
    <value>Finalizar pedido</value>
    <comment>Enviar en Segunda gafa</comment>
  </data>
  <data name="segunda_gafa" xml:space="preserve">
    <value>SEGUNDA GAFA</value>
    <comment>Configuración óptica</comment>
  </data>
  <data name="anadir_encargo" xml:space="preserve">
    <value>Añadir encargo</value>
    <comment>segunda gafa</comment>
  </data>
  <data name="anadir_encargo_pregunta_enviar_pedido" xml:space="preserve">
    <value>Enviar ahora el pedido</value>
    <comment>segunda gafa</comment>
  </data>
  <data name="anadir_encargo_pregunta_guardar_receta" xml:space="preserve">
    <value>Guardar y realizar el pedido más tarde desde Recetas Guardadas</value>
    <comment>segunda gafa</comment>
  </data>
  <data name="anadir_encargo_pregunta_graduacion_cerca" xml:space="preserve">
    <value>Graduación de cerca</value>
    <comment>segunda gafa</comment>
  </data>
  <data name="anadir_encargo_pregunta_graduacion_diferente" xml:space="preserve">
    <value>Graduación diferente a la primera gafa</value>
    <comment>segunda gafa</comment>
  </data>
  <data name="anadir_encargo_pregunta_graduacion_lejos" xml:space="preserve">
    <value>Graduación de lejos</value>
    <comment>segunda gafa</comment>
  </data>
  <data name="anadir_encargo_pregunta_misma_graduacion" xml:space="preserve">
    <value>Misma graduación que la primera gafa</value>
    <comment>segunda gafa</comment>
  </data>
  <data name="Aceptar_Cambio_Y_Enviar" xml:space="preserve">
    <value>Aceptar Cambio Y Enviar</value>
    <comment>Alternativa de producto</comment>
  </data>
  <data name="Atencion" xml:space="preserve">
    <value>Atención</value>
    <comment>Alternativa de producto</comment>
  </data>
  <data name="desde_scanner" xml:space="preserve">
    <value>IndoScan</value>
    <comment>ComboBox formas</comment>
  </data>
  <data name="aviso_alternativa_producto" xml:space="preserve">
    <value>SE HA DETECTADO UNA ROTURA DE STOCK PARA LA GRADUACIÓN Y PRODUCTO SOLICITADO. PARA GARANTIZARLE UN BUEN SERVICIO, LE SERVIMOS AL MISMO PRECIO EL SIGUIENTE PRODUCTO. SI SE TRATA DE LA REPOSICIÓN DE UN SOLO OJO LE ACONSEJAMOS QUE LLAME A ATENCIÓN AL CLIENTE:</value>
    <comment>Alternativa producto</comment>
  </data>
  <data name="codigo_de_barras" xml:space="preserve">
    <value>Código de barras</value>
  </data>
  <data name="aviso_atencion_urgencias" xml:space="preserve">
    <value>Atención: Para urgencias, reclamaciones y anulaciones llama al 93.748.68.00</value>
  </data>
  <data name="pedido_con_montura" xml:space="preserve">
    <value>Pedido con montura</value>
  </data>
  <data name="aviso_falta_tipo_montura" xml:space="preserve">
    <value>Debe especificar el tipo de montura y el tipo de Bisel. Pinche en el botón Siguiente, dentro de selección de espesores.</value>
  </data>
  <data name="valor_fuera_de_margenes" xml:space="preserve">
    <value>Valor fuera de márgenes para {2}. Valores permitidos entre {0} y {1}.</value>
  </data>
  <data name="aviso_escoger_alto_indice" xml:space="preserve">
    <value>Para lentes con taladro es recomendable usar lentes a partir de índice 1.6 para garantizar la seguridad del montaje.</value>
  </data>
  <data name="formas_fax_enviarFichero" xml:space="preserve">
    <value>Obtener desde fichero</value>
  </data>
  <data name="anadir_encargo_pregunta_enviar_revision" xml:space="preserve">
    <value>Enviar a revisión el pedido.</value>
    <comment>segunda gafa</comment>
  </data>
  <data name="OjoDerecho" xml:space="preserve">
    <value>Ojo Derecho</value>
  </data>
  <data name="OjoIzquierdo" xml:space="preserve">
    <value>Ojo Izquierdo</value>
  </data>
  <data name="PreguntaElegirOjo" xml:space="preserve">
    <value>¿A qué ojo corresponde la forma obtenida?</value>
  </data>
  <data name="aviso_caracter_no_valido" xml:space="preserve">
    <value>Este carácter no es válido. Por favor use el alfabeto occidental.</value>
  </data>
  <data name="actualizando_nueva_oferta" xml:space="preserve">
    <value>Actualizando la nueva oferta...</value>
  </data>
  <data name="comprobando_si_BBDD" xml:space="preserve">
    <value>Comprobando si existe BBDD...</value>
  </data>
  <data name="comprobando_si_nueva_oferta" xml:space="preserve">
    <value>Comprobando si hay nueva oferta...</value>
  </data>
  <data name="descarga_oferta_fallo_1" xml:space="preserve">
    <value>No se ha podido recargar la oferta en este momento.</value>
  </data>
  <data name="descarga_oferta_fallo_2" xml:space="preserve">
    <value>Inténtelo más tarde desde el botón RECARGAR OFERTA en la pestaña de CONFIGURACIÓN de DATOS ÓPTICA</value>
  </data>
  <data name="Esperando_respuesta_del_usuario_para_actualizar_la_oferta" xml:space="preserve">
    <value>Esperando respuesta del usuario para actualizar la oferta...</value>
  </data>
  <data name="nueva_oferta_actualizada_correctamente" xml:space="preserve">
    <value>Nueva oferta actualizada correctamente</value>
  </data>
  <data name="oferta_actualizada" xml:space="preserve">
    <value>Oferta actualizada...</value>
  </data>
  <data name="aviso_cargando_oferta" xml:space="preserve">
    <value>cargando datos oferta</value>
  </data>
  <data name="configuracion_taller" xml:space="preserve">
    <value>Configuracion taller</value>
  </data>
  <data name="jobs_omain" xml:space="preserve">
    <value>JOBS(OMAIN)</value>
  </data>
  <data name="macros_talla" xml:space="preserve">
    <value>Macros de talla</value>
  </data>
  <data name="peticiones_talla" xml:space="preserve">
    <value>Peticiones talla</value>
  </data>
  <data name="productos_y_credito" xml:space="preserve">
    <value>Productos y credito</value>
  </data>
  <data name="semiterminados" xml:space="preserve">
    <value>Semiterminados</value>
  </data>
  <data name="aviso_lecturas_no_cargadas" xml:space="preserve">
    <value>No se han importado lecturas para esta forma</value>
  </data>
  <data name="aviso_lectura_importada" xml:space="preserve">
    <value>Forma {0} importada con éxito</value>
  </data>
  <data name="biselador_remoto_importar" xml:space="preserve">
    <value>Importar</value>
  </data>
  <data name="biselador_remoto_ultima" xml:space="preserve">
    <value>Última</value>
  </data>
  <data name="aviso_lectura_cargada" xml:space="preserve">
    <value>Forma {0} cargada con éxito</value>
  </data>
  <data name="tooltip_boton_importar" xml:space="preserve">
    <value>Importar una forma desde fichero</value>
  </data>
  <data name="tooltip_boton_iniciar" xml:space="preserve">
    <value>Muestra todas las lecturas de INDOBISEL</value>
  </data>
  <data name="tooltip_boton_ultima" xml:space="preserve">
    <value>Cargar la última forma leída en Indobisel</value>
  </data>
  <data name="aviso_introducir_montura_1" xml:space="preserve">
    <value>Atención: Para realizar correctamente el pedido</value>
  </data>
  <data name="aviso_introducir_montura_2" xml:space="preserve">
    <value>debe introducir la forma de la montura.</value>
  </data>
  <data name="espesor_borde" xml:space="preserve">
    <value>Espesor borde</value>
  </data>
  <data name="Discrepancia_muestra_leida" xml:space="preserve">
    <value>E r r o r : Discrepancia entre número de muestra esperada y obtenida.</value>
  </data>
  <data name="FicherosFormas" xml:space="preserve">
    <value>Ficheros de imágenes de formas</value>
    <comment>IndoScan</comment>
  </data>
  <data name="IntentarOtraVez" xml:space="preserve">
    <value>Por favor, inténtelo de nuevo con otra imágen o avise a su contacto en INDO.</value>
  </data>
  <data name="NoPosibleObtenerForma" xml:space="preserve">
    <value>No se ha podido obtener la forma a partir de la imágen.</value>
    <comment>IndoScan</comment>
  </data>
  <data name="SelEscaner" xml:space="preserve">
    <value>Seleccionar escáner</value>
    <comment>IndoScan</comment>
  </data>
  <data name="VolverAObtenerDeEscaner" xml:space="preserve">
    <value>Volver a obtener de escáner</value>
    <comment>IndoScan</comment>
  </data>
  <data name="AnalizandoImagen" xml:space="preserve">
    <value>Analizando imágen para obtener forma</value>
    <comment>IndoScan</comment>
  </data>
  <data name="msg_Prerequisitos" xml:space="preserve">
    <value>A continuación se iniciará de forma automática la instalación de los programas necesarios para la captura y posterior proceso de las imágenes para obtener la forma de la montura.</value>
    <comment>IndoScan</comment>
  </data>
  <data name="calibre_marcas" xml:space="preserve">
    <value>Calibre</value>
  </data>
  <data name="descargando_oferta" xml:space="preserve">
    <value>Descargando oferta</value>
  </data>
  <data name="imprimir_pedidos_seleccionados" xml:space="preserve">
    <value>Imprimir pedidos seleccionados</value>
  </data>
  <data name="quitar_seleccion" xml:space="preserve">
    <value>Quitar selección</value>
  </data>
  <data name="seleccionar_todos" xml:space="preserve">
    <value>Seleccionar todos</value>
  </data>
  <data name="configuracion_hojaPedido" xml:space="preserve">
    <value>Hoja para pedido</value>
  </data>
  <data name="configuracion_link_hojaRombos" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/Indonet/Enlaces/HojaPedidos_ES.pdf</value>
  </data>
  <data name="configuracion_link_manualIndoScanIntegrado" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/Indonet/Enlaces/ManualIndoScanIntegrado_ES.pdf</value>
  </data>
  <data name="configuracion_manualIndoScanIntegrado" xml:space="preserve">
    <value>Manual IndoScan integrado</value>
  </data>
  <data name="configuracion_link_manualIndoNet" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/Indonet/Enlaces/ManualIndonet_ES.pdf</value>
  </data>
  <data name="configuracion_manualIndonet" xml:space="preserve">
    <value>Manual Indonet</value>
  </data>
  <data name="aviso_precalibrado_forma_cuadrada" xml:space="preserve">
    <value>En Precalibrados con Forma Básica se recomienda seleccionar la Forma Cuadrada para cubrir la forma de la gafa. Esta forma no garantiza el espesor más óptimo. Para asegurar ambos parámetros utiliza la forma real de la gafa.</value>
  </data>
  <data name="parametros_proxy" xml:space="preserve">
    <value>Parámetros conexión al Proxy</value>
  </data>
  <data name="password" xml:space="preserve">
    <value>Password</value>
  </data>
  <data name="usuario" xml:space="preserve">
    <value>Usuario</value>
  </data>
  <data name="aviso_falta_diametro" xml:space="preserve">
    <value>Debe escoger un diámetro.</value>
  </data>
  <data name="recordar_mis_credenciales" xml:space="preserve">
    <value>Recordar mis credenciales</value>
  </data>
  <data name="mail_informacion_estado_pedidos" xml:space="preserve">
    <value>MAIL INFORMACION ESTADO DE LOS PEDIDOS</value>
    <comment>Mi buzon</comment>
  </data>
  <data name="mi_buzon" xml:space="preserve">
    <value>Mi Buzón</value>
    <comment>Mi buzon</comment>
  </data>
  <data name="texto_direcciones_email" xml:space="preserve">
    <value>Direcciones donde quieres recibir esta información</value>
    <comment>Mi buzon</comment>
  </data>
  <data name="texto_pedidos_en_curso" xml:space="preserve">
    <value>Pedidos en curso (1 mail diario)</value>
    <comment>Mi buzon</comment>
  </data>
  <data name="texto_pedidos_en_envio" xml:space="preserve">
    <value>Pedidos a recibir en cada envío (1 mail por cada envío)</value>
    <comment>Mi buzon</comment>
  </data>
  <data name="texto_si_deseas_recibir_mails" xml:space="preserve">
    <value>Si deseas recibir mails con información de tus pedidos selecciona una de las siguientes opciones:</value>
    <comment>Mi buzon</comment>
  </data>
  <data name="aviso_color_nd_diferentes" xml:space="preserve">
    <value>No se permite coloración en lentes de distinto índice.</value>
  </data>
  <data name="aviso_actualizacion" xml:space="preserve">
    <value>La aplicación se actualizará a la versión:</value>
    <comment>clickonce</comment>
  </data>
  <data name="aviso_apagado" xml:space="preserve">
    <value>Aplicacion Actualizada. La aplicación se cerrará. Puede iniciar otra vez INDONET desde el icono del escritorio.</value>
    <comment>clickonce</comment>
  </data>
  <data name="aviso_descarga_aplicacion" xml:space="preserve">
    <value>Descargando nueva versión</value>
    <comment>ClickOnce</comment>
  </data>
  <data name="aviso_instalando_version" xml:space="preserve">
    <value>Instalando nueva version</value>
    <comment>ClickOnce</comment>
  </data>
  <data name="password_internet" xml:space="preserve">
    <value>Password Internet</value>
    <comment>proxy</comment>
  </data>
  <data name="usuario_internet" xml:space="preserve">
    <value>Usuario Internet</value>
    <comment>proxy</comment>
  </data>
  <data name="error_envio_pedido_paso2_1" xml:space="preserve">
    <value>Se ha detectado un error en la comunicación con nuestro servidor al grabar el pedido. Verifica en la pestaña de ""Pedidos Realizados"" si se ha grabado y actúa de la siguiente manera:</value>
  </data>
  <data name="error_envio_pedido_paso2_2" xml:space="preserve">
    <value>Si se ha grabado borra la pestaña de ""Realizar Pedido""</value>
  </data>
  <data name="error_envio_pedido_paso2_3" xml:space="preserve">
    <value>Si no se ha grabado reenvíalo de nuevo.</value>
  </data>
  <data name="alt_anilla" xml:space="preserve">
    <value>ALT ANILL</value>
  </data>
  <data name="anillas" xml:space="preserve">
    <value>Anillas</value>
  </data>
  <data name="aviso_encargo_anulado" xml:space="preserve">
    <value>Su encargo se ha anulado correctamente</value>
  </data>
  <data name="aviso_encargo_no_anulable" xml:space="preserve">
    <value>Ya no es posible anular el encargo</value>
  </data>
  <data name="aviso_falta_montura" xml:space="preserve">
    <value>No hay stock de la montura solicitada. Elimina la montura del pedido en la pestaña formas y espesores o cámbiala por otra montura con existencias.</value>
  </data>
  <data name="aviso_mas_30_dias" xml:space="preserve">
    <value>El intervalo de fechas debe ser inferior a 60 días</value>
  </data>
  <data name="aviso_solicitar_solo_monturas" xml:space="preserve">
    <value>Para solicitar sólo monturas para tu stock hazlo a través de recetas.lentes@indo.es</value>
  </data>
  <data name="c1_desde" xml:space="preserve">
    <value>C1 DESDE</value>
  </data>
  <data name="c1_hasta" xml:space="preserve">
    <value>C1 HASTA</value>
  </data>
  <data name="caracter_hiperlink" xml:space="preserve">
    <value>»</value>
  </data>
  <data name="comprobar_disponibilidad" xml:space="preserve">
    <value>comprobar disponibilidad</value>
  </data>
  <data name="conexion_ssl_segura" xml:space="preserve">
    <value>Conexión SSL segura</value>
  </data>
  <data name="configuracion_lentesMaxima" xml:space="preserve">
    <value>www.lentesmaxima.com</value>
  </data>
  <data name="configuracion_link_lentesMaxima" xml:space="preserve">
    <value>http://www.lentesmaxima.com</value>
  </data>
  <data name="diametro1" xml:space="preserve">
    <value>Diámetro</value>
  </data>
  <data name="diam_anill" xml:space="preserve">
    <value>DIAM ANILL</value>
  </data>
  <data name="diam_desde" xml:space="preserve">
    <value>DIAM DESDE</value>
  </data>
  <data name="diam_hasta" xml:space="preserve">
    <value>DIAM HASTA</value>
  </data>
  <data name="diam_resul" xml:space="preserve">
    <value>DIAM RESUL</value>
  </data>
  <data name="direccion_indo" xml:space="preserve">
    <value>Avda. Alcalde Barnils, 72 - 08174 Sant Cugat del Vallés - Barcelona</value>
  </data>
  <data name="error_borrando_pedido" xml:space="preserve">
    <value>Se ha producido un error borrando el pedido</value>
  </data>
  <data name="error_comprobar_disponibilidad_segunda_gafa" xml:space="preserve">
    <value>Error comprobando disponibilidad segunda gafa</value>
  </data>
  <data name="ffname" xml:space="preserve">
    <value>FFNAME</value>
  </data>
  <data name="grabar_cambios" xml:space="preserve">
    <value>Guardar Cambios</value>
  </data>
  <data name="id_design" xml:space="preserve">
    <value>ID_DESIGN</value>
  </data>
  <data name="id_job" xml:space="preserve">
    <value>ID JOB</value>
  </data>
  <data name="id_producto" xml:space="preserve">
    <value>ID_PRODUCTO</value>
  </data>
  <data name="indice_desde" xml:space="preserve">
    <value>Índice desde</value>
  </data>
  <data name="indice_hasta" xml:space="preserve">
    <value>Índice hasta</value>
  </data>
  <data name="info_creacion_bbdd_local" xml:space="preserve">
    <value>***&gt;Inicio creación BBDD</value>
  </data>
  <data name="info_creacion_bbdd_local_OK" xml:space="preserve">
    <value>***&gt;Creación BBDD OK</value>
  </data>
  <data name="info_grabar_oferta" xml:space="preserve">
    <value>***&gt;Inicio guardar oferta en BBDD</value>
  </data>
  <data name="info_grabar_oferta_OK" xml:space="preserve">
    <value>***&gt;Oferta guardada en BBDD OK</value>
  </data>
  <data name="insertar" xml:space="preserve">
    <value>Insertar</value>
  </data>
  <data name="job" xml:space="preserve">
    <value>JOB</value>
  </data>
  <data name="macro" xml:space="preserve">
    <value>Macro</value>
  </data>
  <data name="maquina" xml:space="preserve">
    <value>Máquina</value>
  </data>
  <data name="mas_informacion1" xml:space="preserve">
    <value>Más información</value>
  </data>
  <data name="material" xml:space="preserve">
    <value>Material</value>
  </data>
  <data name="montura" xml:space="preserve">
    <value>Montura</value>
  </data>
  <data name="nombre_producto" xml:space="preserve">
    <value>NOMBRE PRODUCTO</value>
  </data>
  <data name="oval" xml:space="preserve">
    <value>OVAL</value>
  </data>
  <data name="pedido" xml:space="preserve">
    <value>Pedido</value>
  </data>
  <data name="precal" xml:space="preserve">
    <value>PRECAL</value>
  </data>
  <data name="pregunta_montura_y_lentes_biseladas" xml:space="preserve">
    <value>Montura y lentes biseladas</value>
  </data>
  <data name="pregunta_montura_y_lentes_montadas" xml:space="preserve">
    <value>Montura y lentes montadas</value>
  </data>
  <data name="pregunta_montura_y_lentes_sin_biselar" xml:space="preserve">
    <value>Montura y lentes sin biselar (lente asociada a la montura solicitada)</value>
  </data>
  <data name="pregunta_solo_lentes_biseladas" xml:space="preserve">
    <value>Sólo lentes biseladas</value>
  </data>
  <data name="pregunta_solo_lentes_sin_biselar" xml:space="preserve">
    <value>Sólo lentes sin biselar</value>
  </data>
  <data name="prod_desde" xml:space="preserve">
    <value>PROD DESDE</value>
  </data>
  <data name="prod_hasta" xml:space="preserve">
    <value>PROD HASTA</value>
  </data>
  <data name="semitermin" xml:space="preserve">
    <value>SEMITERMIN</value>
  </data>
  <data name="sucursal" xml:space="preserve">
    <value>SUCURSAL</value>
  </data>
  <data name="valores_por_defecto" xml:space="preserve">
    <value>Valores por defecto</value>
  </data>
  <data name="ventana_espera_borrar_pedidos" xml:space="preserve">
    <value>Borrando pedido {0}</value>
  </data>
  <data name="ventana_espera_consulta_anulable" xml:space="preserve">
    <value>Consulta si pedido {0} es anulable</value>
  </data>
  <data name="ventana_espera_guardar_datos" xml:space="preserve">
    <value>Guardando datos</value>
  </data>
  <data name="ventana_espera_recuperar_datos" xml:space="preserve">
    <value>Recuperando datos</value>
  </data>
  <data name="graduacion_resultante" xml:space="preserve">
    <value>Graduación Resultante:</value>
  </data>
  <data name="BlankRange" xml:space="preserve">
    <value>Blank range</value>
    <comment>BlankRange</comment>
  </data>
  <data name="info_no_fabricara" xml:space="preserve">
    <value>Esta lente no se fabricará</value>
  </data>
  <data name="aviso_fallo_co_proxy" xml:space="preserve">
    <value>Hay una versión más actualizada que la que está utilizando que no se ha podido instalar. Póngase en contacto con INDO para analizar el motivo</value>
  </data>
  <data name="tooltip_biselar_sin_pulir" xml:space="preserve">
    <value>Para monturas de acetato y metal el bisel siempre será sin pulir</value>
  </data>
  <data name="aviso_faltan_datos_para_montaje" xml:space="preserve">
    <value>Debes completar todos los datos de montaje</value>
  </data>
  <data name="aviso_biselar_por_defecto" xml:space="preserve">
    <value>Biselar por defecto</value>
  </data>
  <data name="fecha_llegada_real" xml:space="preserve">
    <value>Fecha Llegada:</value>
  </data>
  <data name="tooltip_biselar_por_defecto" xml:space="preserve">
    <value>Al seleccionarlo, al hacer un pedido con forma se marcará de manera automática Biselar Lente. Siempre lo podrás deseleccionar.</value>
  </data>
  <data name="favoritos" xml:space="preserve">
    <value>favoritos</value>
  </data>
  <data name="aviso_borrar_pedido" xml:space="preserve">
    <value>Borrar pedido</value>
  </data>
  <data name="pregunta_borrar_pedido" xml:space="preserve">
    <value>¿Estás seguro que quieres anular el encargo con Número de Pedido {0}?</value>
  </data>
  <data name="aviso_error_buscando_datos" xml:space="preserve">
    <value>Error buscando datos</value>
  </data>
  <data name="aviso_error_enviando_mensaje_SRI" xml:space="preserve">
    <value>Error enviando mensaje a SRI</value>
  </data>
  <data name="aviso_no_hay_incidencias" xml:space="preserve">
    <value>No se encuentran Incidencias en este formulario</value>
  </data>
  <data name="aviso_no_hay_tema" xml:space="preserve">
    <value>No se ha definido el Tema de la incidencia en este formulario</value>
  </data>
  <data name="aviso_tema_no_encontrado" xml:space="preserve">
    <value>No se encuentra el tema especificado</value>
  </data>
  <data name="email_contacto" xml:space="preserve">
    <value>Email de contacto</value>
  </data>
  <data name="errores_en_formulario" xml:space="preserve">
    <value>Se han encontrado errores en el formulario</value>
  </data>
  <data name="ficheros_adjuntos" xml:space="preserve">
    <value>Ficheros adjuntos</value>
  </data>
  <data name="fin_ficheros_adjuntos" xml:space="preserve">
    <value>Fin de ficheros adjuntos</value>
  </data>
  <data name="motivos" xml:space="preserve">
    <value>Motivos</value>
  </data>
  <data name="motivo_no_definido" xml:space="preserve">
    <value>No se ha definido el Motivo de la incidencia en este formulario</value>
  </data>
  <data name="no_hay_motivos" xml:space="preserve">
    <value>No hay motivos</value>
  </data>
  <data name="sin_ficheros_adjuntos" xml:space="preserve">
    <value>sin ficheros adjuntos</value>
  </data>
  <data name="sin_observaciones" xml:space="preserve">
    <value>sin observaciones</value>
  </data>
  <data name="tema" xml:space="preserve">
    <value>Tema</value>
  </data>
  <data name="aviso_falta_valor_en_motivo" xml:space="preserve">
    <value>No se ha definido un valor para el motivo {0}</value>
  </data>
  <data name="mensaje_pedido_duplicado" xml:space="preserve">
    <value>ATENCIÓN. Posible Pedido Duplicado. Realizado en Fecha: {0}. Con Nº de {1}: {2}</value>
  </data>
  <data name="pedido_duplicado" xml:space="preserve">
    <value>PEDIDO DUPLICADO</value>
  </data>
  <data name="receta" xml:space="preserve">
    <value>Receta Guardada</value>
  </data>
  <data name="no_mostrar_aviso" xml:space="preserve">
    <value>No volver a mostrar este aviso</value>
  </data>
  <data name="error_usuario_o_clave_no_validos" xml:space="preserve">
    <value>Usuario o clave no válidos</value>
  </data>
  <data name="borrar_forma" xml:space="preserve">
    <value>Eliminar Forma</value>
    <comment>Borrado MiBiselado</comment>
  </data>
  <data name="confirmar_borrar_forma" xml:space="preserve">
    <value>¿Confirma el borrado de esta forma ?</value>
    <comment>Borrado MiBiselado</comment>
  </data>
  <data name="montura_seleccionada" xml:space="preserve">
    <value>Montura seleccionada</value>
    <comment>FormaRemota</comment>
  </data>
  <data name="ventana_espera_borrar_forma" xml:space="preserve">
    <value>Borrando forma</value>
    <comment>Borrado MiBiselado</comment>
  </data>
  <data name="borrar_mi_biselado" xml:space="preserve">
    <value>Eliminar de Mis Biselados</value>
    <comment>Borrado MiBiselado</comment>
  </data>
  <data name="pedir_montura" xml:space="preserve">
    <value>Pedir Montura</value>
    <comment>FormaRemota</comment>
  </data>
  <data name="base_automatica" xml:space="preserve">
    <value>Base Automática</value>
  </data>
  <data name="biselado_remoto" xml:space="preserve">
    <value>Biselado Remoto</value>
  </data>
  <data name="descentramiento" xml:space="preserve">
    <value>Descentramiento</value>
  </data>
  <data name="espesor_especial" xml:space="preserve">
    <value>Espesor Especial</value>
  </data>
  <data name="otros_datos" xml:space="preserve">
    <value>OTROS DATOS</value>
  </data>
  <data name="precalibrado" xml:space="preserve">
    <value>Precalibrado</value>
  </data>
  <data name="precalibrado1" xml:space="preserve">
    <value>Precalibrado</value>
  </data>
  <data name="prisma" xml:space="preserve">
    <value>Prisma</value>
  </data>
  <data name="anadir" xml:space="preserve">
    <value>Añadir</value>
  </data>
  <data name="eliminar_seleccionados" xml:space="preserve">
    <value>Eliminar seleccionados</value>
  </data>
  <data name="exportar" xml:space="preserve">
    <value>Exportar</value>
  </data>
  <data name="importar" xml:space="preserve">
    <value>Importar</value>
  </data>
  <data name="datos_opcionales" xml:space="preserve">
    <value>Ver datos adicionales opcionales</value>
  </data>
  <data name="setup_laboratorios" xml:space="preserve">
    <value>Lab Settings</value>
  </data>
  <data name="diametro_resultante_abrev" xml:space="preserve">
    <value>Dia. Result.</value>
  </data>
  <data name="espesor_taladro_minimo_abrev" xml:space="preserve">
    <value>Esp.Tal.Mín</value>
  </data>
  <data name="indoCenter_popup_titulo" xml:space="preserve">
    <value>Seleccionar Lecturas de IndoCenter</value>
  </data>
  <data name="nombre" xml:space="preserve">
    <value>Nombre</value>
  </data>
  <data name="aviso_espera" xml:space="preserve">
    <value>Espera</value>
  </data>
  <data name="error_debe_seleccionar_lectura" xml:space="preserve">
    <value>Debe seleccionar una lectura</value>
  </data>
  <data name="aviso_indocenter_altura_boxing" xml:space="preserve">
    <value>Para este pedido hemos cambiado la configuración a BOXING. Tenlo en cuenta si ahora y en este pedido vas a modificar algún valor de los importados por IndoCenter.</value>
  </data>
  <data name="base_gafa" xml:space="preserve">
    <value>BASE GAFA</value>
  </data>
  <data name="aviso_faltan_datos_en_prisma" xml:space="preserve">
    <value>No se ha especificado el eje o la base del prisma</value>
  </data>
  <data name="aviso_desactivar_isotipo" xml:space="preserve">
    <value>En pedidos sin forma o con forma básica no se puede solicitar isotipo. Desactive isotipo</value>
  </data>
  <data name="aviso_diferentes_alturas" xml:space="preserve">
    <value>Diferencia entre DNP’s o ALTURAS MONT superior a la recomendada</value>
  </data>
  <data name="configuracion_FAP" xml:space="preserve">
    <value>Formulario Adaptación Progresivos (FAP)</value>
  </data>
  <data name="configuracion_link_FAP" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/Indonet/Enlaces/FAP_ES.pdf</value>
  </data>
  <data name="configurar" xml:space="preserve">
    <value>Configurar</value>
  </data>
  <data name="aviso_si_quiere_recogida_llamar_ATC" xml:space="preserve">
    <value>Si deseas solicitar una recogida para el montaje solicitado debes llamar a Atención al Cliente</value>
  </data>
  <data name="aviso_mas_365_dias" xml:space="preserve">
    <value>El intervalo de fechas debe ser inferior a 365 días</value>
  </data>
  <data name="valorar_sin_firmar" xml:space="preserve">
    <value>Valorar sin firmar</value>
  </data>
  <assembly alias="System.Windows.Forms" name="System.Windows.Forms, Version=2.0.0.0, Culture=neutral, PublicKeyToken=b77a5c561934e089" />
  <data name="AyudaAlturaPasillo_ES" type="System.Resources.ResXFileRef, System.Windows.Forms">
    <value>Assets\Recortes\AyudaAlturaPasillo_ES.png;System.Drawing.Bitmap, System.Drawing, Version=2.0.0.0, Culture=neutral, PublicKeyToken=b03f5f7f11d50a3a</value>
  </data>
  <data name="mensaje_advertencia_espesor_ranura" xml:space="preserve">
    <value>IMPORTANTE:  Se debe verificar siempre el tipo de ranura para evitar incidencias en el montaje. Recuerda: 
Espesor mín. ranura Nylon= 1.5 mm 
Espesor mín. ranura Metal= 2.2 mm 
Si tienes dudas consulta con nuestro departamento de Atención al cliente.</value>
  </data>
  <data name="alta_buscador" xml:space="preserve">
    <value>Alta/modificación en buscador web</value>
  </data>
  <data name="anular_cambios" xml:space="preserve">
    <value>Anular los cambios efectuados</value>
  </data>
  <data name="audiologia" xml:space="preserve">
    <value>Audiología</value>
  </data>
  <data name="aviso_eliminar_buscador_web" xml:space="preserve">
    <value>Estás a punto de borrar tus datos de nuestro buscador web. 
Al aceptar dejarás de aparecer en las posibles búsquedas que 
realicen los usuarios en el mismo.  
¿Deseas continuar?</value>
  </data>
  <data name="baja_buscador" xml:space="preserve">
    <value>Baja en buscador web</value>
  </data>
  <data name="baja_vision" xml:space="preserve">
    <value>Baja visión</value>
  </data>
  <data name="buscador_web" xml:space="preserve">
    <value>Buscador Web</value>
  </data>
  <data name="de" xml:space="preserve">
    <value>de</value>
  </data>
  <data name="domingo" xml:space="preserve">
    <value>Domingo</value>
  </data>
  <data name="horario_comercial" xml:space="preserve">
    <value>Horario comercial</value>
  </data>
  <data name="informacion_contacto" xml:space="preserve">
    <value>Información contacto</value>
  </data>
  <data name="informacion_optica" xml:space="preserve">
    <value>Información óptica</value>
  </data>
  <data name="jueves" xml:space="preserve">
    <value>Jueves</value>
  </data>
  <data name="lunes" xml:space="preserve">
    <value>Lunes</value>
  </data>
  <data name="martes" xml:space="preserve">
    <value>Martes</value>
  </data>
  <data name="miercoles" xml:space="preserve">
    <value>Miércoles</value>
  </data>
  <data name="optometria" xml:space="preserve">
    <value>Optometría</value>
  </data>
  <data name="rellenar_con_datos_por_defecto" xml:space="preserve">
    <value>Rellenar con los datos por defecto</value>
  </data>
  <data name="sabado" xml:space="preserve">
    <value>Sábado</value>
  </data>
  <data name="taller_de_montaje" xml:space="preserve">
    <value>Taller de montaje</value>
  </data>
  <data name="terapia_visual" xml:space="preserve">
    <value>Terapia visual</value>
  </data>
  <data name="texto_autorizo_buscar_web" xml:space="preserve">
    <value>Autorizo a INDO OPTICAL SLU a que guarde estos datos y los conserve dentro de sus bases de datos cuyo uso se encuentra destinado exclusivamente a aparecer en su buscador de ópticas, a fin de tener comunicación con sus clientes.</value>
  </data>
  <data name="venta_online" xml:space="preserve">
    <value>Venta online</value>
  </data>
  <data name="viernes" xml:space="preserve">
    <value>Viernes</value>
  </data>
  <data name="visualizar_datos_en_buscador" xml:space="preserve">
    <value>Visualizar datos en el buscador</value>
  </data>
  <data name="y_de" xml:space="preserve">
    <value>y de</value>
  </data>
  <data name="visualizar_datos_buscador" xml:space="preserve">
    <value>Visualizar en buscador</value>
  </data>
  <data name="mensaje_entrada_buscador" xml:space="preserve">
    <value>Configura aquí tus datos para aparecer en el buscador de ópticas de www.indo.es</value>
  </data>
  <data name="tooltip_boton_copiar" xml:space="preserve">
    <value>Copiar de lunes a sábado</value>
  </data>
  <data name="ha_escogido_esta_comprobación" xml:space="preserve">
    <value>Has escogido esta comprobación</value>
  </data>
  <data name="ha_escogido_esta_devolucion" xml:space="preserve">
    <value>Has escogido esta devolución</value>
  </data>
  <data name="configuracion_hojaPedidoIndoscan" xml:space="preserve">
    <value>Hoja para IndoScan</value>
  </data>
  <data name="configuracion_link_hojaPedidosFax" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/Indonet/Enlaces/HojaPedidosFax_ES.pdf</value>
  </data>
  <data name="aviso_texto_no_copiar_pedido" xml:space="preserve">
    <value>IMPORTANTE 
Puede que el producto que estás solicitando haya cambiado su oferta desde la fecha de realización del pedido original. Por favor, verifica todos los campos y tratamientos para asegurarte de que las lentes que seleccionas son las correctas.</value>
  </data>
  <data name="selecciona_trazador" xml:space="preserve">
    <value>Selecciona el trazador</value>
  </data>
  <data name="selecciona_trazador_1" xml:space="preserve">
    <value>Tienes más de un Indobisel conectado a INDONET. Por favor, selecciona el trazador al que quieres conectar:</value>
  </data>
  <data name="aceptar_cambio_y_recalcular" xml:space="preserve">
    <value>Aceptar cambio y recalcular</value>
  </data>
  <data name="cancelar_cambios" xml:space="preserve">
    <value>Cancelar cambios</value>
  </data>
  <data name="propuesta_cambio_producto" xml:space="preserve">
    <value>PROPUESTA DE CAMBIO DE PRODUCTO</value>
  </data>
  <data name="ver_mas" xml:space="preserve">
    <value>Ver más</value>
  </data>
  <data name="texto_importante_devoluciones" xml:space="preserve">
    <value>Para evitar deterioros en las lentes y facilitar su análisis estas deberán recibirse en un embalaje apropiado y por separado, sin elementos que puedan dañarlas como grapas, tarjetas, etc</value>
  </data>
  <data name="facturacion" xml:space="preserve">
    <value>Facturas y albaranes</value>
  </data>
  <data name="no_albaran" xml:space="preserve">
    <value>Nº Albarán</value>
  </data>
  <data name="no_factura" xml:space="preserve">
    <value>Nº Factura</value>
  </data>
  <data name="url_ayuda_inset" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/ayudaIndonet/inset.html</value>
  </data>
  <data name="ver_albaran" xml:space="preserve">
    <value>Ver albarán</value>
  </data>
  <data name="consulta_disponibilidad" xml:space="preserve">
    <value>Consulta disponibilidad</value>
  </data>
  <data name="marca_opcion_deseada" xml:space="preserve">
    <value>Marque la opción deseada</value>
  </data>
  <data name="url_condiciones_indomedcare_new" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/ayudaIndonet/condiciones_indomedcare_ES.txt</value>
  </data>
  <data name="url_condiciones_indonet_new" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/ayudaIndonet/condiciones_indonet_ES.txt</value>
  </data>
  <data name="bisel_solar" xml:space="preserve">
    <value>Bisel Envolvente Solar</value>
  </data>
  <data name="crear_devolucion" xml:space="preserve">
    <value>Crear devolución</value>
  </data>
  <data name="favoritas" xml:space="preserve">
    <value>Favoritas</value>
  </data>
  <data name="catalogo" xml:space="preserve">
    <value>Catálogo</value>
  </data>
  <data name="consultar_pedidos" xml:space="preserve">
    <value>Consultar pedidos</value>
  </data>
  <data name="gestion_devoluciones" xml:space="preserve">
    <value>Gestión de devoluciones</value>
  </data>
  <data name="tienes_un_aviso" xml:space="preserve">
    <value>Tienes un aviso</value>
  </data>
  <data name="texto_presentacion10" xml:space="preserve">
    <value>Realizar y controlar tus devoluciones.</value>
  </data>
  <data name="texto_presentacion11" xml:space="preserve">
    <value>Controlar e imprimir tus facturas con Indomanager.</value>
  </data>
  <data name="texto_presentacion8" xml:space="preserve">
    <value>En ella podrás:</value>
  </data>
  <data name="url_ayuda_pedidorapidoclasico" xml:space="preserve">
    <value>https://www.indonet.es/indonet2010/descargas/ayudaIndonet/pedido_rapidoclasico_ES.txt</value>
  </data>
  <data name="grabado_clasico" xml:space="preserve">
    <value>Grabado clásico</value>
  </data>
  <data name="grabado_rapido" xml:space="preserve">
    <value>Grabado rápido</value>
  </data>
  <data name="aviso_cuenta_email_valida" xml:space="preserve">
    <value>Para poder usar Indonet debe introducir una cuenta de mail válida</value>
  </data>
  <data name="aviso_faltan_observaciones" xml:space="preserve">
    <value>Por favor, indícanos brevemente en observaciones el motivo de la revisión de tu pedido.</value>
  </data>
  <data name="degradado_alto" xml:space="preserve">
    <value>Degradado alto - 5mm</value>
  </data>
  <data name="degradado_bajo" xml:space="preserve">
    <value>Degradado bajo + 5mm</value>
  </data>
  <data name="crear_abono" xml:space="preserve">
    <value>Crear abono</value>
  </data>
  <data name="crear_analisis" xml:space="preserve">
    <value>Crear análisis</value>
  </data>
  <data name="selecciona_una_opcion" xml:space="preserve">
    <value>Selecciona una opción</value>
  </data>
  <data name="selecciona_un_motivo" xml:space="preserve">
    <value>Selecciona un motivo</value>
  </data>
  <data name="solicitar_abono" xml:space="preserve">
    <value>Solicitar abono</value>
  </data>
  <data name="solicitar_analisis_lentes" xml:space="preserve">
    <value>Solicitar análisis lentes</value>
  </data>
  <data name="solicito_abono_por" xml:space="preserve">
    <value>Solicito el análisis de las lentes por…</value>
  </data>
  <data name="solicito_analisis_por" xml:space="preserve">
    <value>Solicito el análisis de las lentes por...</value>
  </data>
  <data name="vi" xml:space="preserve">
    <value>VI</value>
  </data>
  <data name="vl" xml:space="preserve">
    <value>VL</value>
  </data>
  <data name="vp" xml:space="preserve">
    <value>VP</value>
  </data>
  <data name="zona_de_identificacion1" xml:space="preserve">
    <value>Zona de identificación</value>
    <comment>Barra de Herramientas</comment>
  </data>
  <data name="limpiar" xml:space="preserve">
    <value>Limpiar campos</value>
  </data>
  <data name="debes_seleccionar_una_resolucion" xml:space="preserve">
    <value>Debes seleccionar al menos una resolución.</value>
  </data>
  <data name="debes_seleccionar_un_motivo" xml:space="preserve">
    <value>Debes seleccionar al menos un motivo.</value>
  </data>
  <data name="vas_a_borrar_los_datos_esas_seguro" xml:space="preserve">
    <value>Vas a borrar los datos del formulario ¿Estás seguro?</value>
  </data>
  <data name="calcular_espesores" xml:space="preserve">
    <value>Calcular espesores</value>
  </data>
  <data name="opciones_de_pedido" xml:space="preserve">
    <value>Opciones de pedido</value>
  </data>
  <data name="pedido_urgente" xml:space="preserve">
    <value>Pedido urgente</value>
  </data>
  <data name="comprobaciones" xml:space="preserve">
    <value>Análisis</value>
  </data>
  <data name="devoluciones" xml:space="preserve">
    <value>Devoluciones</value>
  </data>
  <data name="producto" xml:space="preserve">
    <value>Product</value>
  </data>
  <data name="que_tipo_devolucion_quieres_realizar" xml:space="preserve">
    <value>¿Qué tipo de devolución quieres realizar?</value>
  </data>
  <data name="escoge_tipo_mimetika" xml:space="preserve">
    <value>Escoge el tipo de isotipo</value>
  </data>
  <data name="iniciales_en_mimetika" xml:space="preserve">
    <value>Iniciales</value>
  </data>
  <data name="isotipo_en_maxvita" xml:space="preserve">
    <value>ISOTIPO</value>
  </data>
  <data name="isotipo_en_mimetika" xml:space="preserve">
    <value>Isotipo</value>
  </data>
  <data name="isotipo_rodenstock" xml:space="preserve">
    <value>ISOTIPO RODENSTOCK</value>
  </data>
  <data name="isotipo_especial" xml:space="preserve">
    <value>ISOTIPO ESPECIAL</value>
  </data>
  <data name="pulsa_para_recuperar_VMAP" xml:space="preserve">
    <value>Pulsa para recuperar el código VMAP</value>
  </data>
  <data name="sin_isotipo_en_mimetika" xml:space="preserve">
    <value>Sin Isotipo</value>
  </data>
  <data name="activa_filtro_codigo" xml:space="preserve">
    <value>Selecciona para buscar por CODIGO</value>
  </data>
  <data name="activa_filtro_marca" xml:space="preserve">
    <value>Selecciona para buscar por MARCA</value>
  </data>
  <data name="activa_filtro_modelo" xml:space="preserve">
    <value>Selecciona para buscar por MODELO</value>
  </data>
  <data name="filtros_mis_biselados" xml:space="preserve">
    <value>Pon los caracteres que quieres buscar en los datos de MARCA, MODELO o CODIGO</value>
  </data>
  <data name="en_proceso" xml:space="preserve">
    <value>En proceso</value>
  </data>
  <data name="gama_de_lentes" xml:space="preserve">
    <value>Gama de lentes:</value>
  </data>
  <data name="aviso_grabado_rapido" xml:space="preserve">
    <value>Tu pedido ha sido enviado a Indo/Rodenstock. Si todos los datos son correctos en unos minutos quedará grabado.
Por favor, comprueba en la pestaña de "pedidos realizados" que se le ha asignado un Nº de pedido, de no ser así, contacta con nuestro equipo de Atención al Cliente.
Pedidos Indo: 900 110 557
Pedidos Rodenstock: 900 102 189</value>
  </data>
  <data name="gama" xml:space="preserve">
    <value>Gama:</value>
  </data>
  <data name="solicitar_devolucion_rodenstock" xml:space="preserve">
    <value>Activar Garantía Especial Rodenstock</value>
  </data>
  <data name="aviso_pedir_devolucionRD" xml:space="preserve">
    <value>Solicitar Garantía Especial Rodenstock</value>
  </data>
  <data name="texto_activar_bonificacion_rodenstock_checkbox" xml:space="preserve">
    <value>Activar Garantía Especial Rodenstock</value>
  </data>
  <data name="aviso_devolucion_mas_de_un_pedido_seleccionado" xml:space="preserve">
    <value>Ha seleccionado más de un pedido para devolución. Solamente se tratará el primer pedido. ¿Desea continuar?</value>
  </data>
  <data name="aviso_no_se_han_seleccionado_pedidos_aptos_para_devolucion" xml:space="preserve">
    <value>No se han seleccionado pedidos aptos para devolución</value>
  </data>
  <data name="aviso_opcion_valida_solo_desde_pedidosrealizados_solicitarabono" xml:space="preserve">
    <value>Esta opción sólo es válida desde Pedidos Realizados o Solicitar Abono</value>
  </data>
  <data name="seguro_actualizar_pedido" xml:space="preserve">
    <value>Este cálculo no es obligatorio para enviar el pedido y podría tomar algún tiempo.
¿Está seguro de continuar?</value>
  </data>
  <data name="rodenstockmanager" xml:space="preserve">
    <value>RodenstockManager</value>
  </data>
  <data name="bienvenido_a_rodenstockmanager" xml:space="preserve">
    <value>Bienvenido a Rodenstock Manager el servicio con el que podrás gestionar tu negocio de una manera más autónoma y con una mayor información a tu alcance cuando lo desees.</value>
  </data>
  <data name="url_fb_rodenstock" xml:space="preserve">
    <value>https://www.facebook.com/RodenstockES/</value>
  </data>
  <data name="url_in_rodenstock" xml:space="preserve">
    <value>https://www.instagram.com/rodenstock_es/</value>
  </data>
  <data name="url_indoloyalty" xml:space="preserve">
    <value>https://indoloyalty.com</value>
  </data>
  <data name="wells_pedido_sonae_obligatorio" xml:space="preserve">
    <value>En el campo Referencia se debe indicar el pedido de Sonae</value>
  </data>
</root>